import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';
import { ItemInfo } from 'src/app/models/models';
import { Order, Payment, Store, User } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface CustomItem extends ItemInfo
{
	delivered_qty:number;
	refunded_qty:number;
	refunded_amount:number;
	delivered_amount:number;
}

interface CustomClientOrder
{
	client:User | null;
	total_sales:number;
	custom_item_list:CustomItem[];
	total_paid:number;
	total_refund:number;
	total_discount:number;
}

@Component({
	selector: 'app-report-client-refunds',
	templateUrl: './report-client-refunds.component.html',
	styleUrls: ['./report-client-refunds.component.css']
})
export class ReportClientRefundsComponent extends BaseComponent implements OnInit
{
	custom_client_order_list:CustomClientOrder[] = [];
	store_list: Store[] = [];
	date:string = '';
	start:string = '';
	end:string = '';

	order_search:SearchObject<Order> = this.getEmptySearch();

	ngOnInit(): void
	{
		this.path = '/report-client-refunds';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response:ParamMap[])=>
			{

				let fields=['user_id','store_id','created','system_activated'];
				let extra_fields:string[] = ['date','start','end'];

				let payment_search:SearchObject<Payment> = this.getEmptySearch();
				let order_search:SearchObject<Order> = this.getSearch(response[0],fields,extra_fields);
				//order_search.nn = ['client_user_id'];

				if( !order_search.eq.store_id )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
					payment_search.eq.store_id = this.rest.current_user.store_id;
				}

				let start_date = new Date();
				let end_date = new Date();

				start_date.setHours(0,0,0,0);

				if( order_search.search_extra.start )
				{
					this.start = order_search.search_extra.start as string;
				}
				else
				{
					order_search.search_extra.start= Utils.getLocalMysqlStringFromDate( start_date ).substring( 0, 10 );
					this.start = order_search.search_extra.start;
				}

				if( order_search.search_extra.end )
				{
					this.end = order_search.search_extra.end as string;
				}
				else
				{
					this.end = this.start;
					order_search.search_extra.end = Utils.getLocalMysqlStringFromDate( start_date ).substring( 0, 10 );
				}

				start_date = Utils.getDateFromLocalMysqlString( this.start );
				start_date.setHours( 0, 0 ,0 ,0 );

				end_date = Utils.getDateFromLocalMysqlString( this.end );
				end_date.setHours( 23, 59, 59,999);

				//XXX estos dos estan mal no confundir this.order_search con order_search
				this.order_search.search_extra.start = this.start;
				this.order_search.search_extra.end = this.end;

				order_search.limit = 999999;
				order_search.ge.created = start_date;
				order_search.le.created = end_date;
				order_search.eq.status = 'CLOSED';

				console.log('Dates', start_date, end_date  )


				payment_search.ge.created = start_date;
				payment_search.le.created = end_date;
				payment_search.eq.store_id = order_search.eq.store_id;
				payment_search.limit = 99999;

				this.order_search = order_search;

				return forkJoin
				({
					orders: this.rest.order_info.search( order_search ),
					payment: this.rest.payment_info.search( payment_search ),
					store: this.rest.getStores(true)
				});
			})
		)
		.subscribe
		(
			(response)=>
			{
				this.store_list = response.store.data;

				let clients:CustomClientOrder[] = [];

				for(let oi of response.orders.data )
				{
					let client = clients.find(c=>{
						if( c.client == null && oi.client == null )
							return true;

						if( c.client == null )
							return false;

						return oi.order.client_user_id == c.client.id
					});

					if( client == undefined )
					{
						client =
						{
							client: oi.client || null,
							total_sales: 0,
							custom_item_list: [],
							total_paid: 0,
							total_refund: 0,
							total_discount:0,
						};

						clients.push( client );
					}

					for(let oii of oi.items)
					{
						let ci = client.custom_item_list.find((i)=>i.item.id==oii.item.id);
						if( ci == undefined )
						{
							console.log('Not found', oii );
							ci = { ...oii, delivered_qty: 0, refunded_qty:0, refunded_amount: 0, delivered_amount:0 };
							client.custom_item_list.push( ci );
						}
						else
						{
							console.log('Found');
						}
						ci.delivered_qty	+= oii.order_item.type == 'NORMAL' ? oii.order_item.qty : 0;
						ci.refunded_qty		+= oii.order_item.type == 'REFUND' ? oii.order_item.qty : 0;

						ci.delivered_amount		+= oii.order_item.type == 'NORMAL' ? oii.order_item.total : 0;
						ci.refunded_amount	+= oii.order_item.type == 'REFUND' ? oii.order_item.total : 0;
						client.total_refund += oii.order_item.type == 'REFUND' ? oii.order_item.total : 0;
					}

					client.total_sales += oi.order.total;
					client.total_discount += oi.order.discount;
					client.total_paid += oi.order.amount_paid;
				}

				this.custom_client_order_list = clients;
			},
			(error)=>
			{
				this.showError( error );
			}
		);
	}

	export()
	{


		let headers = [
			'Cliente',
			'Artículo',
			'Entregado',
			'Devoluciones',
			'Subtotal Regresado',
			'Subtotal Entregado',
			'Total Descuentos',
			'Total Devolucion',
			'Total Pagado',
			];

		let foo:Object[] = [];
		for(let cco of this.custom_client_order_list)
		{
			let r = [];
			let i=0;
			for(let ci of cco.custom_item_list)
			{
				let name  = '-';


				if( this.rest.local_preferences.display_categories_on_items == 'YES' && cco.custom_item_list[0].category )
				{
					name = ci.category.name+' - '+ci.item.name;
				}
				else
				{
					name = ci.item.name;
				}

				if( i == 0 )
				{
					foo.push({
						'Cliente': cco?.client?.name || 'PUBLICO GENERAL',
						'Artículo': name,
						'Entregado': ci.delivered_qty,
						'Devoluciones': ci.refunded_qty,
						'Subtotal Regresado': ci.refunded_amount,
						'Subtotal Entregado': ci.delivered_amount - ci.refunded_amount,
						'Total Descuentos': cco.total_discount,
						'Total Devolucion': cco.total_refund,
						'Total Pagado': cco.total_paid,
					});
				}
				else
				{
					foo.push({
						'Cliente': cco?.client?.name || 'PUBLICO GENERAL',
						'Artículo': name,
						'Entregado': ci.delivered_qty,
						'Devoluciones': ci.refunded_qty,
						'Subtotal Regresado': ci.refunded_amount,
						'Subtotal Entregado': ci.delivered_amount - ci.refunded_amount,
						'Total Descuentos': '',
						'Total Devolucion': '',
						'Total Pagado': '',
					});
				}
				i++;
			}
		}
		ExcelUtils.array2xlsx(foo,'reporte.xlsx',  headers );
	}

	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>{
			window.print();
		},300);
	}
}
