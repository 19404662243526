<div class="container-fluid">
	<h1 class="my-3">Videos de Ayuda</h1>
	<h4 class="mt-2">Punto de venta</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/QKD_XRDspis?si=2uApnVJzJZBgzKYT" target="_blank">Hacer una venta y uso general del PV</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/rEf0e4Fze1A" target="_blank">Cancelar ventas cerradas</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/gdDCfPo3kJY" target="_blank">Cancelar orden abierta</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/VY8ZLCrQzUA?si=Yv356QlpkH_Goe81" target="_blank">Aplicar descuentos y cambiar precios en PV</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/otr-Tz-tWOQ" target="_blank">Agregar excepciones</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/FT7KeRkn1a0?si=7vwEPIpYNa92aApe" target="_blank">Corte de caja individual y corte por sucursal</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/YwpuE60ohmI" target="_blank">Generar una factura desde el punto de venta</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/iKgTiquaD84" target="_blank">Configuración del ticket</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/xf-Sr4nCJAM" target="_blank">Ver ventas</a>
		</li>
	</ul>
	<h4 class="mt-2">Artículos</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://www.youtube.com/watch?v=4Fqar2Dxt8o" target="_blank">Categorías</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/rMTTt-ncCWY" target="_blank">Configuración de apariencia de articulos</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/hdzYLsm9--8" target="_blank">Edicion de precios</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
	</ul>
	<h4 class="mt-2">Inventarios</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/nW3AqhYwhK0?si=KpwPXo_csiKmN1q2" target="_blank">Agregar/remover y visualizar inventario</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/4hCzs4OlOAA?si=yQBxfhw_A6_hlg_w" target="_blank">Agregar ordenes de compra</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/ycnXVMrKAi8" target="_blank">Crear una requisición</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/AlpK4XZFetE" target="_blank">Generar y recibir envíos</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/lcYSNG-6WvI" target="_blank">Toma de inventario fisico</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
	</ul>
	<h4 class="mt-2">Clientes</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/eU4jzVQaeq4?si=TVV99TUx_dWHKBoR" target="_blank">Dar credito a un cliente</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/tTxUugp1kNM" target="_blank">Liquidación de deudas</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/XV0orKLF4PA" target="_blank">Registrar abonos</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/re7A--WvzJ0" target="_blank">Crear una cotización</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
	</ul>
	<h4 class="mt-2">Usuarios</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/C6SIVBclgOE" target="_blank">Agregar usuario</a>
		</li>
	</ul>
	<h4 class="mt-2">Sucursales</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/7tbCS1xYfNM" target="_blank">Agregar una sucursal</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/xPNR957XFlo" target="_blank">Modificar preferencias</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/gnTsSvbLV_E" target="_blank">Cambio de IVA</a>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/PaDXl0swEkQ" target="_blank">Listas de precios y tipos de consumo</a>
		</li>
	</ul>
	<h4 class="mt-2">Facturación</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/F6ACzVE60YE?si=FA_6QRgZeu8qwHs4" target="_blank">Generar una factura</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/2-7CDByRWMA" target="_blank">Facturación por período</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
	</ul>
	<h4 class="mt-2">Reportes</h4>
	<ul class="list-group">
		<li class="list-group-item">
			<a href="https://youtu.be/eazSMgN4hNU?si=FnSqmGtgsj-ZVe5c" target="_blank">Reporte global de utilidad</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
		<li class="list-group-item">
			<a href="https://youtu.be/kpYfHfJ-72s" target="_blank">Reporte de utilidad por orden</a>
			<span class="badge bg-danger float-end">NUEVO</span>
		</li>
	</ul>
</div>
