import { Component, OnInit } from '@angular/core';
import { Billing_Data } from 'src/app/models/RestModels';
import { CustomOrderInfo, ListOrderComponent } from '../list-order/list-order.component';

@Component
({
	selector: 'app-list-client-orders',
	templateUrl: './list-client-orders.component.html',
	styleUrls: ['./list-client-orders.component.css']
})

export class ListClientOrdersComponent extends ListOrderComponent
{
	select_all:boolean = false;
    billing_data_list: Billing_Data[] = [];
    show_facturar_modal: boolean;

	onSelectAllCheckbox(event:any)
	{
		this.select_all = event.target.checked;

		for(let i=0;i<this.order_info_list.length;i++)
		{
			this.order_info_list[i].selected = this.select_all;
		}
	}

	onSelectOrder(evt:any,order_info:CustomOrderInfo)
	{
		order_info.selected = !order_info.selected;
		this.select_all = this.order_info_list.every(oi=>oi.selected);

	}

	facturar():void
	{
		this.is_loading = true;

		let f_request = {
			order_ids: this.order_info_list.filter(oi=>oi.selected).map(oi=>oi.order.id).join(','),
		};

		this.subs.sink = this.rest.update('facturarMultipleOrders', f_request)
		.subscribe
		({
			next:(response)=>
			{
				this.showSuccess('Facturado correctamente');
			},
			error:(error)=>
			{
				this.showError(error);
			}
		})
	}
}
