import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';

import {Currency_Rate, Order, User} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import {Price_Type} from '../../models/RestModels';
import { OrderInfo } from '../../models/models';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';


interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	show_date:boolean;
	selected: boolean;
	total:number;
	pending: number;
}

@Component({
	selector: 'app-list-user-order',
	templateUrl: './list-user-order.component.html',
	styleUrls: ['./list-user-order.component.css']
})
export class ListUserOrderComponent extends BaseComponent implements OnInit {

	order_search:SearchObject<Order> = this.getEmptySearch();
	order_info_list:CustomOrderInfo[] = [];
	User:User = GetEmpty.user();

	store_list:Store[] = [];
	price_type_list:Price_Type[] = [];
	store_dictionary:Record<number,Store> = {};
	price_type_dic:Record<number,Price_Type> = {};
	order_total:number = 0;
	show_asign_delivery_user:boolean = false;
	selected_order_info:OrderInfo | null = null;
	show_returns:boolean = false;
	cashier_user_list:User[] = [];
	show_create_shipping:boolean = false;

	fecha_inicial:string = '';
	fecha_final:string = '';
	shipping_guide: any;
	shipping_company: any;
	shipping_date: any;
	mark_as_sent:boolean = false;
	show_advanced_search:boolean = false;
	show_offline_options:boolean = false;
	show_cancel_order: boolean = false;
	cancellation_reason:string = '';
	user: User = null;
	agent_user:User | null = null;
	client:User | null = null;
	pending:number = 0;
	selected_pending:number = 0;
	toggle_all_status:boolean = false;
	show_receive_payment:boolean = false;

	selected_order_info_list:OrderInfo[] = [];
	currency_rate_list: Currency_Rate[] = [];

	payment_currency_id:string = '';
	default_currency_id: string = 'MXN';

	ngOnInit()
	{
		this.path = '/list-order';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((query_params)=>
			{
				let fields = [ "id","client_user_id","cashier_user_id","facturado","store_id","shipping_address_id",
					"price_type_id","status","type","client_name","total","subtotal","tax","amount_paid",
					"paid_status", "address","suburb","city","state","zipcode","name","created_by_user_id",
					"delivery_status", "updated_by_user_id","created","updated","store_consecutive" ];

				let extra_keys:Array<string> = ['transaction_type','publico_general','advanced_search','with_discounts','agent_user_id']; //['search_param1','project_id','some_id'];
				let order_search:SearchObject<Order> = this.getSearch(query_params, fields, extra_keys);
				console.log( order_search );

				this.user = null;
				this.setTitle('Ventas por cliente');
				this.is_loading = true;
				order_search.limit = this.page_size;
				this.current_page = this.order_search.page;
				order_search.sort_order = ['id_DESC'];

				console.log('advanced_search,',order_search.search_extra.advanced_search );
				this.show_advanced_search = !!order_search.search_extra.advanced_search;

				if( !(order_search.eq.price_type_id ) )
					order_search.eq.price_type_id = null;

				order_search.is_null = [];

				order_search.csv.paid_status = ['PARTIALLY_PAID','PENDING'];
				//order_search.eq.facturado = 'NO';
				this.order_search = order_search;

				return forkJoin
				({
					orders: this.rest.order_info.search(this.order_search),
					user:	this.rest.user.get( this.order_search.eq.client_user_id ),
					currency_rate: this.rest.getCurrencyRates()
				})
			}),
			mergeMap((response)=>
			{
				console.log( response );
				let tmp_total = response.orders.data.reduce((p,c)=>p+c.order.total,0);
				this.currency_rate_list = response.currency_rate.data;

				return forkJoin
				({
					order : of( response.orders ),
					store : this.rest.getStores(true),
					price_type : this.rest.getPriceTypes(true),
					users: this.rest.user.search({limit:9999, eq:{type:'USER'},sort_order:['name_ASC']}),
					user: of( response.user ),
				})
			})
		)
		.subscribe((responses)=>
		{
			this.is_loading = false;

			this.toggle_all_status = false;
			this.user = responses.user;
			responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);
			responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
			this.store_list = responses.store.data;
			this.cashier_user_list = responses.users.data;
			this.price_type_list	= responses.price_type.data;
			this.order_info_list = responses.order.data.map(oi=> this.getCustomOrderInfo(oi));

			this.default_currency_id = this.store_list.find(s=>s.id == this.rest.current_user.store_id).default_currency_id;
			this.payment_currency_id = this.store_list.find(s=>s.id == this.rest.current_user.store_id).default_currency_id;

			this.pending = this.order_info_list.reduce((p,c)=>
			{
				if( c.order.status == 'CLOSED' )
				{
					return p+ ( c.pending / this.rest.getExchangeRate(c.order.currency_id,this.payment_currency_id, this.payment_currency_id, this.currency_rate_list) );
				}
				return p;
			},0);

			this.order_total = this.order_info_list.reduce((p,c)=>p+ (c.pending / this.rest.getExchangeRate(c.order.currency_id, this.payment_currency_id, this.payment_currency_id, this.currency_rate_list) ),0);

			this.setPages( this.order_search.page, responses.order.total );
		},(error)=>this.showError(error));
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			//Discount must be with the tax included

			let diff = oi.order_item.unitary_price_meta - oi.order_item.original_unitary_price;
			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let d =new Date();
		d.setHours(0,0,0,0);

		let show_date = order_info.order.created < d;

		return {
			...order_info,
			article_discount,
			show_date,
			selected: false,
			total: order_info.order.total-order_info.order.discount,
			pending: order_info.order.total - (order_info.order.discount + order_info.order.amount_paid)
		}
	}

	toggleAdvancedSearch(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		this.show_advanced_search = input.checked
		this.updateSelectedPending();
	}

	toggleCheck(oi:CustomOrderInfo)
	{
		oi.selected = !oi.selected;

		this.toggle_all_status = !this.order_info_list.some(i=> !i.selected);
		this.updateSelectedPending();
	}

	onToggleAll(f:boolean)
	{
		this.toggle_all_status = f;

		for(let oi of this.order_info_list )
		{
			if( oi.order.status != 'CLOSED' )
			{
				oi.selected = false;
				continue;
			}

			oi.selected = f;
		}

		this.updateSelectedPending();
	}

	updateSelectedPending()
	{
		let reduce_currencies = (p,oi) =>
		{
			if( !p.includes(oi.order.currency_id) )
			{
				p.push(oi.order.currency_id);
			}

			return p;
		};

		let currencies_ids = this.order_info_list
			.filter(oi=>oi.selected)
			.reduce( reduce_currencies, [] );

		this.payment_currency_id = currencies_ids.length == 1 ? currencies_ids[0] : this.default_currency_id;

		let reduce_total = (p,c)=>
		{
			return p+(c.selected? (c.pending / this.rest.getExchangeRate(c.order.currency_id, this.payment_currency_id, this.payment_currency_id, this.currency_rate_list)) : 0 );
		};

		this.selected_pending = this.order_info_list.reduce( reduce_total, 0 );

	}

	paySelected(evt:Event)
	{
		this.selected_order_info_list = this.order_info_list.filter(oi=>oi.selected);

		if( this.selected_order_info_list.length == 0)
		{
			this.showError('No hay ninguna orden seleccionada');
			return;
		}

		let first_currency_id = this.selected_order_info_list[0].order.currency_id;

		for(let oi of this.selected_order_info_list)
		{
			if( oi.order.currency_id != first_currency_id )
			{
				this.showError('Las ordenes seleccionadas deben ser del mismo tipo de moneda');
				return;
			}
		}

		this.updateSelectedPending();
		this.show_receive_payment = true;
	}
	onClosePayment(close:boolean)
	{
		this.show_receive_payment = close;
		//if( close === false )
		//{
		//	//if( this.order_info.order.id )
		//	//{
		//	//	this.router.navigate(['/pos',this.order_info.order.id],{replaceUrl:true});
		//	//}
		//	//else //Offline
		//	//{
		//	//	this.router.navigate(['/pos/offline',this.order_info.order.sync_id],{replaceUrl:true});
		//	//}
		//}
	}
}
