import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';

import {Address, Currency, Currency_Rate, Price, Price_Type, Quote_Item, Store, User} from '../../models/RestModels';
import {ItemInfo, QuoteInfo, QuoteItemInfo} from 'src/app/models/models';
import {mergeMap} from 'rxjs/operators';
import {SearchObject} from 'src/app/services/Rest';
import { GetEmpty } from 'src/app/classes/Empties';


@Component({
	selector: 'app-save-quote',
	templateUrl: './save-quote.component.html',
	styleUrls: ['./save-quote.component.css']
})
export class SaveQuoteComponent extends BaseComponent implements OnInit {

	quote_info:QuoteInfo;
	store_list:Store[] = [];
	input_search:string = '';
	item_info_list:ItemInfo[] = [];
	show_add_new: boolean;
	total:number = 0;
	store_id:number | string = '';
	search_client_str:string = '';
	client_list:User[] = [];
	user_client: User | null = null;
	show_client_list:boolean = false;
	client_search_index: number;
	address_list: Address[];
	currency_rate_list: Currency_Rate[] = [];
	price_type_list: Price_Type[] = [];
	currency_list: Currency [] = [];


	ngOnInit()
	{
		this.quote_info = GetEmpty.quoteInfo(this.rest);

		this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				this.is_loading = true;
				let title = 'Agregar Nueva cotización';
				if( param_map.has('id') )
				{
					title = 'Editar cotización #'+param_map.get('id');
				}
				this.setTitle( title );

				let obs = param_map.has('id')
					? this.rest.quote_info.get( param_map.get('id') )
					: of( GetEmpty.quoteInfo(this.rest) )

				return forkJoin
				({
					quote_info: obs,
					stores: this.rest.store.search({limit:9999999}),
					currency_rates: this.rest.getCurrencyRates(),
					price_type: this.rest.getPriceTypes(),
					currency: this.rest.currency.search({limit:9999999})
				});
			})
		)
		.subscribe((response)=>
		{
			this.store_list = response.stores.data;
			this.quote_info = response.quote_info;
			this.search_client_str = this.quote_info.quote.name;
			this.price_type_list = response.price_type.data;
			this.quote_info.quote.price_type_id = this.price_type_list[0].id;

			this.is_loading = false;
			this.quote_info.items.forEach((i)=>this.rest.updateOrderItemPrice(i.quote_item,this.quote_info.quote.tax_percent,0));
			this.calculateTotal();
			this.currency_rate_list = response.currency_rates.data;
			this.currency_list = response.currency.data;

			if( !this.quote_info.quote.store_id )
			{
				if( this.rest.current_user.store_id )
				{
					this.onSucursalChange(this.rest.current_user.store_id );
				}
				else
				{
					this.onSucursalChange( this.store_list[0].id );
				}
			}
			else
			{
				this.store_id = this.quote_info.quote.store_id;
				this.onSucursalChange( this.store_id );
			}


		},(error)=>this.showError(error));
	}

	noRegistrarUsuario()
	{
		this.client_search_index	= 0;
		this.show_client_list	= false;
		this.user_client		= null;
		this.quote_info.client_user = null;
		this.quote_info.quote.name = this.search_client_str;
		this.quote_info.quote.client_user_id = null;
		this.quote_info.client_user = null;
		this.client_list = [];
	}

	onSelectClient(client_user:User )
	{
		this.client_search_index = 0;
		this.user_client = client_user;
		this.search_client_str = client_user.name;
		this.quote_info.quote.name = client_user.name;
		this.quote_info.quote.client_user_id = client_user.id;
		this.quote_info.quote.phone = client_user.phone;
		this.quote_info.quote.price_type_id = client_user.price_type_id;
		this.quote_info.quote.email = client_user.email || '';
		this.show_client_list = false;
		//this.order_info.order.price_type_id = client_user.price_type_id;
		this.client_list = [];
		this.quote_info.client_user = client_user;

		let search:SearchObject<Address> = this.getEmptySearch();
		search.limit = 99999;
		search.eq.user_id = this.user_client.id;
		search.sort_order = ['address_ASC'];

		//this.subs.sink = this.rest.address
		//.search(search)
		//.subscribe((response)=>
		//{
		//	this.address_list = response.data;

		//	if( response.data.length )
		//		this.quote_info.order.shipping_address_id = this.address_list[0].id;
		//});
	}

	onClientSearch(evt:any)
	{
		let html_input:HTMLInputElement = evt.target as HTMLInputElement;
		this.user_client = null;
		let search_term:string = html_input.value.trim();
		this.quote_info.quote.name = search_term;
		if( !search_term )
		{
			this.client_list = [];
			return;
		}

		let search:SearchObject<User> = this.getEmptySearch();
		search.limit = 20;
		search.eq.type = 'CLIENT';
		search.lk.name = search_term;
		search.lk.phone = search_term;

		this.subs.sink = this.rest.user
		.search(search)
		.subscribe((response)=>
		{
			this.show_client_list = true;
			this.client_list= response.data;
		},(error)=>{
			console.log(error);
		});
	}

	save( $event )
	{

		if( this.is_loading )
			return

		this.is_loading = true;


		if( this.quote_info?.quote?.id	)
		{
			this.subs.sink	= this.rest.quote_info.update( this.quote_info )
			.subscribe((quote)=>
			{
				this.is_loading = false;
				this.showSuccess('La cotización se actualizo exitosamente');
				this.router.navigate(['/view-quote',this.quote_info.quote.id],{replaceUrl:true});
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.quote_info.create( this.quote_info )
			.subscribe((quote)=>
			{
				this.is_loading = false;
				this.showSuccess('La cotización se guardo exitosamente');
				this.router.navigate(['/view-quote',quote.quote.id],{replaceUrl:true});
			},(error)=>this.showError(error));
		}
	}

	onInput(evt:Event)
	{
		if( evt.target['value'] == '' )
		{
			this.item_info_list = [];
			return;
		}

		let input = evt.target as HTMLInputElement;
		this.subs.sink = this.rest.item_info.search({
			eq:{status:'ACTIVE'},
			search_extra:{category_name:input.value}
		})
		.subscribe((response)=>
		{
			for(let ii of response.data)
			{
				ii.stock_record = ii.records.find((sr)=>sr.store_id == this.store_id);
			}

			this.item_info_list = response.data;
		});
	}

	onItemInfoSelect(item_info:ItemInfo)
	{
		if( item_info.options.length > 0 )
		{
			this.showError('No se puede agregar un item con opciones');
			return;
		}

		this.show_add_new = false;

		let index = this.quote_info.items.findIndex(i=>{ return i.item.id == item_info.item.id && i.quote_item.status == 'ACTIVE' });

		if( index !== -1 )
		{
			this.showError('El artículo ya se encuentra en la lista');
			return;
		}

		this.item_info_list = [];

		let price:Price | null = item_info.prices.find(p=>
		{
			let same_list = p.price_list_id == this.quote_info.store.price_list_id;

			if( this.quote_info.quote.price_type_id )
			{
				return same_list && p.price_type_id == this.quote_info.quote.price_type_id && this.quote_info.quote.currency_id == p.currency_id;
			}

			return same_list
		}) || null;


		let default_price:Price | null = item_info.prices.find(p=>
		{
			let same_list = p.price_list_id == this.quote_info.store.price_list_id;

			if( this.quote_info.quote.price_type_id )
			{
				return same_list && p.price_type_id == this.quote_info.quote.price_type_id && this.quote_info.store.default_currency_id == p.currency_id
			}

			return same_list
		}) || null;


		if( price == null )
			price = default_price;

		let same_list_price:Price | null = item_info.prices.find(p=>
		{
			let same_list = p.price_list_id == this.quote_info.store.price_list_id;
			let same_price_type = p.price_type_id == this.quote_info.quote.price_type_id;

			if( this.quote_info.quote.price_type_id )
			{
				return same_list && same_price_type;
			}
			return same_list
		}) || null;

		if( price == null )
			price = same_list_price;


		let quote_item:Quote_Item= {
			ieps_calculated: 0,
			ieps_value: 0,
			ieps_type: 'RATE',
			quote_id: this.quote_info.quote.id || 0,
			item_id: item_info.item.id,
			discount: 0,
			provider_price: 0,
			subtotal: 0,
			total: 0,
			qty: 1,
			tax: 0,
			status: 'ACTIVE',
			unitary_price_meta: price?.price ||item_info.item.reference_price || 0,
			original_unitary_price: price?.price || item_info.item.reference_price || null,
			unitary_price: price?.price || item_info.item.reference_price || null,
			tax_included: price?.tax_included || 'NO',
		};

		let store:Store | null = this.store_list.find(s=>s.id == this.store_id ) || null;

		let price_type = this.price_type_list.find(price_type=>price_type.id == this.quote_info.quote.price_type_id);

		if( price && store )
		{
			//XXX posible error no esta tomando en cuenta si no pertenece al mismo tipo de moneda

			if( price_type.model == 'PERCENT' )
			{
				price.price == item_info.item.reference_price * (1+price.percent );
			}

			this.rest.setQuoteItemPrice
			(
				quote_item,
				price,
				this.quote_info.store.default_currency_id,
				this.quote_info.quote.currency_id ,
				this.currency_rate_list
			);
		}

		let quote_item_info:QuoteItemInfo = {
			quote_item: quote_item,
			item: item_info.item,
			category: item_info.category
		};

		this.quote_info.items.push( quote_item_info );

		let tax_percent = quote_item_info.item.applicable_tax == "EXEMPT"
			? 0
			: this.quote_info.quote.tax_percent;

		this.rest.updateOrderItemPrice(quote_item_info.quote_item, tax_percent, 0);


		this.input_search = '';
		this.calculateTotal();
	}

	onTaxIncludedChange(quote_item:QuoteItemInfo,value:'YES'|'NO')
	{
		quote_item.quote_item.tax_included = value;

		let tax_percent = quote_item.item.applicable_tax == "EXEMPT"
			? 0
			: this.quote_info.quote.tax_percent;

		this.rest.updateOrderItemPrice(quote_item.quote_item, tax_percent, 0);


		this.calculateTotal();
	}
	onQtyChange(quote_item:QuoteItemInfo, value:number)
	{
		quote_item.quote_item.qty = value;
		let tax_percent = quote_item.item.applicable_tax == "EXEMPT"
			? 0
			: this.quote_info.quote.tax_percent;

		this.rest.updateOrderItemPrice(quote_item.quote_item, tax_percent, 0);

		this.calculateTotal();
	}
	onPriceChange(quote_item:QuoteItemInfo, value:number)
	{
		quote_item.quote_item.original_unitary_price = value;

		let tax_percent = quote_item.item.applicable_tax == "EXEMPT"
			? 0
			: this.quote_info.quote.tax_percent;

		this.rest.updateOrderItemPrice(quote_item.quote_item, tax_percent, 0);

		this.calculateTotal();
	}

	calculateTotal()
	{
		let total = 0;
		this.quote_info.items.forEach((i)=>
		{
			if( i.quote_item.status == 'ACTIVE' )
				total += i.quote_item.total;
		});
		this.total = Math.round( total*100 )/100;
	}

	onSucursalChange(store_id:number)
	{
		let store:Store = this.store_list.find(s=>s.id == store_id) || null;

		if( !store )
		{
			this.quote_info.quote.store_id = null;
		}
		else
		{
			this.quote_info.quote.tax_percent = store.tax_percent;
			this.quote_info.quote.store_id = store_id;
			this.quote_info.store = store;
			this.quote_info.items.forEach((quote_item_info)=>
			{
				let tax_percent = quote_item_info.item.applicable_tax == "EXEMPT"
					? 0
					: this.quote_info.quote.tax_percent;

				this.rest.updateOrderItemPrice(quote_item_info.quote_item, tax_percent, 0);
			});

			this.calculateTotal();
		}
		this.store_id = store_id;
	}
	onTaxPercentChange(quote_tax_percent:number)
	{
		this.quote_info.quote.tax_percent = quote_tax_percent;
		this.quote_info.items.forEach((quote_item_info)=>
		{
			let tax_percent = quote_item_info.item.applicable_tax == "EXEMPT"
					? 0
					: this.quote_info.quote.tax_percent;

			this.rest.updateOrderItemPrice(quote_item_info.quote_item, tax_percent, 0);

		})
		this.calculateTotal();
	}

	removeItem(item:QuoteItemInfo)
	{
		if(item.quote_item.id )
		{
			item.quote_item.status = 'DELETED';
		}
		else
		{
			let index = this.quote_info.items.findIndex(i=>i === item );
			if( index > -1 )
			{
				this.quote_info.items.splice(index,1);
			}
		}
		this.calculateTotal();
	}
}
