<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="text-capitalize my-3">Pagos - Ingresos</h1>
		</div>
		<div class="col-2 text-end">
			<button type="button" (click)="exportExcel()" class="btn btn-secondary">Exportar</button>
		</div>
	</div>
	<div class="card p-3">
		<div class="row">
			<div class="col-6 col-md-4 form-group">
				<label class="">Moneda</label>
				<input type="text" name="currency_id" [(ngModel)]="payment_search.lk.currency_id" class="form-control">
			</div>
			<div class="col-6 col-md-4 form-group">
				<label class="">Received by user</label>
				<select name="received_by_user_id" [(ngModel)]="payment_search.eq.created_by_user_id" class="form-control">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let c of user_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-4 form-group">
				<label class="">Fecha Inicio</label>
				<input type="datetime-local" name="inicial_created" [ngModel]="fecha_inicial" (ngModelChange)="fechaIncialChange($event)" class="form-control">
			</div>
			<div class="col-6 col-md-4 form-group">
				<label class="">Fecha Final</label>
				<input type="datetime-local" name="final_created" [ngModel]="fecha_final" (ngModelChange)="fechaFinalChange($event)" class="form-control">
			</div>
			<div class="col-6 col-md-4 form-group">
				<label>&nbsp;</label>
				<button type="button" class="w-100 btn btn-primary" (click)="search(payment_search)">Buscar</button>
			</div>
		</div>
	</div>
	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="row border-bottom pb-3 d-none d-md-flex">
			<div class="col-1 col-md-1 fw-bold text-black">Id</div>
			<div class="col-2 col-md-2 fw-bold text-black">Cantidad</div>
			<div class="col-3 col-md-3 fw-bold text-black">Recibido por</div>
			<div class="col-3 fw-bold text-black">Pagado Por</div>
			<div class="col-1 fw-bold text-black">Monedas</div>
			<div class="col-2 fw-bold text-black text-end">Fecha</div>
		</div>
		<div class="row border-bottom py-3 py-md-1 items-align-center" *ngFor="let pi of payment_info_list">
			<div class="col-12 col-md-1">
				<b class="d-md-none">Id:</b>{{pi.payment.id}}
			</div>
			<div class="col-12 col-md-2">
				<b class="d-md-none">Cantidad:</b>
				<a [routerLink]="['/view-payment',pi.payment.id]">
					{{pi.payment.payment_amount | currency: '$'}} {{pi.payment.currency_id}}
				</a>
				<!--a [routerLink]="['/print-payment-receipt',pi.payment.id]">
					{{pi.payment.payment_amount | currency: '$'}} {{pi.payment.currency_id}}
				</a-->
			</div>
			<div class="col-12 col-md-3">
				<b class="d-md-none">Recibio:</b>
				{{pi?.created_by_user?.name | titlecase}}
			</div>
			<div class="col-12 col-md-3">
				<b class="d-md-none">Pagado por:</b>
				{{pi?.paid_by_user?.name | titlecase}}
			</div>
			<div class="col-12 col-md-1">
				<b class="d-md-none">Monedas:</b>
				{{pi.currencies}}
			</div>
			<div class="col-12 col-md-2 text-md-end">
				<b class="d-md-none">Fecha:</b>
				{{pi.payment.created | shortDate: 'full' }}
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-payment'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
