<div class="container-fluid">
	<h1 class="my-3 text-capitalize">Recibir Envío</h1>
	<div class="row align-items-start" *ngIf="shipping_info">
		<div class="col-12 col-lg-5">
			<h2>Datos del Envío</h2>
			<div class="card my-3 p-3">
				<form id="save-shipping" (submit)="save($event)" ngNativeValidate>
					<div class="row mx-0">
						<div class="col-6 px-1 py-2">
							<label class="">Número Económico ó Guia</label>
							<div>
								<b>{{shipping_info.shipping?.shipping_guide}}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2">
							<label class="">Condutor o Paquetería</label>
							<div>
								<b>{{shipping_info.shipping?.shipping_company}}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2" *ngIf="shipping_info.shipping.from_store_id">
							<label class="">Almacén Origen</label>
							<div>
								<b>{{store_dictionary[ shipping_info.shipping.from_store_id ]?.name }}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2" *ngIf="shipping_info.shipping.purchase_id">
							<label class="">Origen</label>
							<div>
								<a [routerLink]="['/purchase/edit', shipping_info.shipping.purchase_id]" *ngIf="rest.current_permission.add_purchases">Orden de compra #{{shipping_info.shipping.purchase_id}}</a>
								<b *ngIf="!rest.current_permission.add_purchases">Orden de compra #{{shipping_info.shipping.purchase_id}}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2">
							<label class="">Almacén Destino</label>
							<div>
								<b>{{store_dictionary[ shipping_info.shipping.to_store_id ]?.name }}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2">
							<label class="">Fecha de envío</label>
							<div>
								<b>{{ shipping_info.shipping?.date | shortDate: 'full'}}</b>
							</div>
						</div>
						<div class="col-6 px-1 py-2">
							<label class="">Recibido</label>
							<div>
								<b>{{ shipping_info.shipping.delivery_timestamp | shortDate: 'full'}}</b>
							</div>
						</div>
						<div class="col-12">
							<label class="">Nota</label>
							<div>
								<b>{{ shipping_info.shipping.note }}</b>
							</div>
						</div>
					</div>
				</form>
			</div>
			<ng-container *ngIf="shipping_info?.shipping.status != 'SENT'">
				<h2>Resumen</h2>
				<div class="card px-1 px-md-3 py-3 my-3">
					<div *ngIf="total_pallets" class="row">
						<div class="col-8">Total Tarimas</div>
						<div class="col-4 text-end">{{total_pallets}}</div>
					</div>
					<div class="row" *ngIf="total_individual_boxes">
						<div class="col-8">Total Cajas Sueltas</div>
						<div class="col-4 text-end">{{total_individual_boxes}}</div>
					</div>
					<div class="row" *ngIf="total_boxes">
						<div class="col-8">Total Cajas</div>
						<div class="col-4 text-end">{{total_boxes}}</div>
					</div>
					<div class="row" *ngIf="total_items && shipping_info.shipping.status != 'SENT'">
						<div class="col-8">Total Elementos</div>
						<div class="col-4 text-end">{{total_items}}</div>
					</div>
					<div class="row">
						<div class="col-8">Total Elementos Recibidos</div>
						<div class="col-4 text-end">{{total_received_items}}</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="col-12 col-lg-7">
			<h2>Artículos</h2>
			<div class="card my-3 px-3 px-lg-3" *ngIf="shipping_info">
				<div class="row d-none d-md-flex fw-bold border-bottom py-3">
					<div class="col-6">Artículos</div>
					<div class="col-2 text-end" *ngIf="shipping_info.shipping.status != 'SENT'">Enviados</div>
					<div class="col-2 text-end" [ngClass]="{'col-md-2':shipping_info.shipping.status !='SENT','col-md-3':shipping_info.shipping.status=='SENT'}">
						Recibido (funcional)
					</div>
					<div class="col-2 text-end" [ngClass]="{'col-md-2':shipping_info.shipping.status !='SENT','col-md-3':shipping_info.shipping.status=='SENT'}">
						Merma
					</div>
				</div>
				<ng-container *ngFor="let sii of shipping_info.items;let i=index">
					<div *ngIf="sii.shipping_item.item_id" class="row border-bottom pb-2 mt-2 align-items-center" [ngClass]="{'text-danger':shipping_info.shipping.status != 'SENT' && sii.shipping_item.received_qty != sii.shipping_item.qty}">
						<div class="col-12 col-md-6 align-self-center fw-bold">
							<b>{{ i+1 }} - </b>
							<img [src]="rest.getImagePath(sii.item?.image_id,sii?.category?.image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
							<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES'" class="text-secondary">{{sii?.category?.name}} - </span>
							<b>{{sii.item.name |titlecase }}</b>
							<span *ngIf="sii.shipping_item.serial_number">({{sii.shipping_item.serial_number |titlecase }})</span>
							<a [routerLink]="['/list-item']" [queryParams]="{'csv.id':''+sii.item.id}" *ngIf="sii.item.code"> {{sii.item.code|titlecase | uppercase }} </a>
						</div>

						<div class="col-12 col-md-2 align-self-center text-end" *ngIf="shipping_info.shipping.status == 'DELIVERED'">
							<span class="d-md-none fw-bold">Enviado</span>
							{{sii.shipping_item.qty | number }}
						</div>

						<div class="col-12 text-end" [ngClass]="{'col-md-2':shipping_info.shipping.status !='SENT','col-md-3':shipping_info.shipping.status=='SENT'}">
							<label class="d-md-none fw-bold">Recibido (funcional)</label>
							<ng-container *ngIf="sii.shipping_item.serial_number">
								<div class="form-check form-switch" *ngIf="sii.shipping_item.serial_number">
									<label class="form-check-label">
										<input type="checkbox" *ngIf="shipping_info.shipping.status	!= 'DELIVERED'"  [name]="'shippint_item-b'+sii.shipping_item.id" (change)="onClickedCheckBox($event, sii.shipping_item)" class="form-check-input" value="1">
									</label>
								</div>
							</ng-container>
							<ng-container *ngIf="!sii.shipping_item.serial_number">
								<ng-container *ngIf="!sii.shipping_item.serial_number">
									<input *ngIf="shipping_info.shipping.status	!= 'DELIVERED'" type="number" [name]="'shippint_item'+sii.shipping_item.item_id" min="0" [(ngModel)]="sii.shipping_item.received_qty" (change)="calculateReceivedItems()" class="form-control text-end" required>
								</ng-container>
								<ng-container *ngIf="shipping_info.shipping.status	== 'DELIVERED'">
									{{sii.shipping_item.received_qty | number }}
								</ng-container>
							</ng-container>
						</div>

						<div class="col-12 text-end" [ngClass]="{'col-md-2':shipping_info.shipping.status !='SENT','col-md-3':shipping_info.shipping.status=='SENT'}">
							<label class="d-md-none fw-bold">Merma</label>
								<ng-container *ngIf="!sii.shipping_item.serial_number">
								<input *ngIf="shipping_info.shipping.status	!= 'DELIVERED'" type="number" [name]="'shippint_item_merma'+sii.shipping_item.item_id" min="0" [(ngModel)]="sii.shipping_item.shrinkage_qty" (change)="calculateReceivedItems()" class="form-control text-end" required>
								<ng-container *ngIf="shipping_info.shipping.status	== 'DELIVERED'">
									{{sii.shipping_item.shrinkage_qty| number }}
								</ng-container>
							</ng-container>
						</div>

					</div>
				</ng-container>
				<div class="row py-3"  *ngIf="shipping_info?.shipping?.status == 'SENT'">
					<div class="col-12 text-end">
						<button form="save-shipping" type="submit"	[disabled]="is_loading" class="btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
							Guardar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
