import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { PaymentInfo } from 'src/app/models/models';
import { Store } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

interface SuperFolio{
	order_id:number;
	discount: number;
	default_currency_id:string;
	total_default_currency:number;
	status: string;
	received_currencies:Received_Currency[];
	total_received_default_currency:number;
	change_default_currency:number;
}

interface Received_Currency
{
	received_other_currency: number;
	total_received_default_currency:number;
	total_other_currency: number;
	currency_id:string;
	rate: number;
}

@Component({
	selector: 'app-corte-x-folio',
	templateUrl: './corte-x-folio.component.html',
	styleUrls: ['./corte-x-folio.component.css']
})
export class CorteXFolioComponent extends BaseComponent implements OnInit
{
	records:SuperFolio[] = [];
	cancelled:SuperFolio[] = [];

	descuento:SuperFolio[] = [];
	currencies:string[] = [];

	default_currency_id:string = 'MXN';
	date:string = Utils.getMysqlStringFromDate(new Date()).substring(0,10);
	store_id:number = this.rest.current_user.store_id || 0;
	total:SuperFolio = this.getEmptySuperFolio();
	total_cancelled:SuperFolio = this.getEmptySuperFolio();
    store_list: Store[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params)=>
			{
				this.is_loading = true;
				this.records = [];
				this.cancelled = [];
				let date = new Date();
				//date.setDate(date.getDate()-3);
				date.setHours(0,0,0,0);

				if( params.has('date'))
				{
					date = Utils.getLocalDateFromMysqlString( params.get('date') ) as Date;
					this.date = params.get('date');
				}
				else
				{
					this.date = Utils.getMysqlStringFromLocalDate(date).substring(0,10);
				}

				let date2 = new Date();
				date2.setTime( date.getTime() );
				date2.setDate( date.getDate()+1 );

				this.store_id = this.rest.current_user.store_id as number;

				if( params.has('store_id' ) )
				{
					this.store_id == parseInt( params.get('store_id') as string ) as number;
				}

				return forkJoin
				({
					store: this.rest.getStores(true),
					orders: this.rest.order.search
					({
						eq:{ store_id:this.store_id },
						ge:{
							system_activated: date,
						},
						lt:{
							system_activated: date2,
						},
						csv:{ status:[ 'CANCELLED','CLOSED'] },
						limit: 99999
					})
				})
			})
			,mergeMap((response)=>
			{
				let ids = response.orders.data.map(o=>o.id);
				let ids_string = ids.join(',');
				this.store_list = response.store.data;

				return forkJoin
				({
					orders: of( response.orders ),
					payments: this.rest.payment_info.search
					({
						search_extra:{ 'order_id':ids_string },
						csv: { status: ['ACTIVE','DELETED'] },
						limit: 999999
					})
				})
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			let store = this.store_list.find(x=>x.id == this.store_id);
			this.default_currency_id = store?.default_currency_id || 'MXN';

			this.currencies = this.getAllCurrencies( response.payments.data );

			for( let oi of response.orders.data )
			{
				let pi_array = response.payments.data.filter((pi)=>
				{
					return pi.movements.some((bmi)=>{
						return bmi.bank_movement_orders.some((bmo)=>{
							console.log('Filter', bmo.order_id, oi.id );
							return bmo.order_id == oi.id;
						});
					});
				});

				console.log( oi );
				console.log('Pi',pi_array);

				let super_folio:SuperFolio = {
					order_id: oi.id,
					discount: oi.discount,
					default_currency_id: store.default_currency_id,
					total_default_currency: oi.total,
					status: oi.status,
					received_currencies:[],
					change_default_currency:0,
					total_received_default_currency:0
				};

				for(let c of this.currencies)
				{
					super_folio.received_currencies.push({
						total_other_currency : 0,
						received_other_currency:0,
						total_received_default_currency:0,
						currency_id: c,
						rate: 1,
					});
					console.log('Adding', c );
				}

				let total_received = 0;

				for(let pi of pi_array)
				{
					for(let bmi of pi.movements)
					{
						let currency_id = bmi.bank_movement.currency_id;
						let rate		= bmi.bank_movement_orders[0].exchange_rate as number;

						let rc = super_folio.received_currencies.find((r_c)=>{
							return r_c.currency_id == bmi.bank_movement.currency_id
						});
						console.log("Found ",rc, currency_id);
						rc.total_received_default_currency += bmi.bank_movement.total*rate;
						rc.total_other_currency += bmi.bank_movement.total;
						rc.received_other_currency += bmi.bank_movement.amount_received;
						rc.rate = rate;

						total_received += bmi.bank_movement.amount_received*rate;
					}
				}

				super_folio.total_received_default_currency =  total_received;
				if( super_folio.status != 'CANCELLED' )
					super_folio.change_default_currency = super_folio.total_received_default_currency-(super_folio.total_default_currency - super_folio.discount );

				if( super_folio.status == 'CANCELLED' )
				{
					this.cancelled.push( super_folio );
				}
				else
				{
					this.records.push( super_folio );
				}
			}
			this.total = this.getTotal(this.records);
			this.total_cancelled = this.getTotal( this.cancelled );
		});
	}

	getTotal(records:SuperFolio[]):SuperFolio
	{
		let total = this.getEmptySuperFolio();
		total.received_currencies = this.currencies.map((i)=>{
			return {
				total_other_currency : 0,
				received_other_currency:0,
				total_received_default_currency:0,
				currency_id: i,
				rate: 1,
			}
		});

		for(let sf of records)
		{
			total.total_default_currency += sf.total_default_currency;
			total.total_received_default_currency += sf.total_received_default_currency;
			total.discount += sf.discount;
			total.change_default_currency += sf.change_default_currency;

			sf.received_currencies.forEach((i,index)=>{
				total.received_currencies[index].total_received_default_currency += i.total_received_default_currency;
				total.received_currencies[index].received_other_currency += i.received_other_currency;
				total.received_currencies[index].rate = i.rate;
				total.received_currencies[index].currency_id = i.currency_id;
			});
		}
		return total;
	}

	getEmptySuperFolio():SuperFolio
	{
		return {
			order_id: 0,
			discount: 0,
			default_currency_id: this.default_currency_id,
			total_default_currency: 0,
			status: 'ACTIVE',
			received_currencies:[],
			change_default_currency:0,
			total_received_default_currency:0
		};
	}

	dosearch(evt)
	{
		this.router.navigate(['/corte-x-folio',this.date]);
	}

	getAllCurrencies(pi:PaymentInfo[]):string[]
	{
		let m:Map<string,boolean> = new Map();
		for(let p of pi)
		{
			for(let bm of p.movements )
			{
				m.set(bm.bank_movement.currency_id,true );
			}
		}
		return Array.from( m.keys() );
	}
}

