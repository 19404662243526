import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Category, Item } from 'src/app/models/RestModels';
import { RequisitionInfo, RequisitionItemInfo } from 'src/app/models/models';

@Component({
  selector: 'app-view-requisition',
  templateUrl: './view-requisition.component.html',
  styleUrls: ['./view-requisition.component.css']
})
export class ViewRequisitionComponent extends BaseComponent implements OnInit {

	requisition_info:RequisitionInfo = null;
	category_list:Category[] = [];
	item_list:Item[] = [];
	category_dictionary: Record<number,Category> = {};
	print_date:Date;


 	ngOnInit(): void 
  	{
		this.print_date = new Date()
		this.route.paramMap.pipe
		(
			mergeMap( (paramMap) =>
			{
				this.is_loading = true;

				return forkJoin
				({
					requisition_info : this.rest.requisition_info.get(paramMap.get('id')),
				})
			}),
			mergeMap((response)=>
			{
				let tmp_category_ids:number[] = response.requisition_info.items
				.map((sii:RequisitionItemInfo)=>
				{
					return sii?.item?.category_id || null;
				});

				let	category_ids:number[] = tmp_category_ids.filter(i=>i!==null);

				return forkJoin
				({
					requisition_info: of( response.requisition_info ),
					category: this.rest.category.search({limit:99999,csv:{id:category_ids},sort_order:['name_ASC']})
				})
			}),
		)
		.subscribe((responses)=>
		{
			this.is_loading = false;
			responses.category.data.forEach(c=>this.category_dictionary[c.id]=c);
			this.category_list = responses.category.data;
			this.requisition_info = responses.requisition_info;
			console.log(this.requisition_info);
		},(error)=>this.showError(error));
  	}

	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
	}
}
