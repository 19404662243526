import { Component } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import {ItemInfo, ItemOptionInfo, ItemOptionValueInfo, OrderItemInfo} from 'src/app/models/models';
import {Order_Item, Price, Store, User} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

interface Qty {
	item_option_value_id:number;
	qty:number;
}

@Component({
	selector: 'app-client-configure-selected-item',
	templateUrl: './client-configure-selected-item.component.html',
	styleUrls: ['./client-configure-selected-item.component.css']
})
export class ClientConfigureSelectedItemComponent extends BaseComponent
{
	item_info:ItemInfo | null = null;
	qty:Record<number,number> = {};
	order_item_list:Order_Item[] = [];
	//main_order_item:Order_Item = this.getEmptyOrderItem();
	item_option_values_queue_by_item_option_id:Record<number,Qty[]> = {}
	option_dictionary:Record<number,number> = {};
	store:Store | null = null;
	user:User | null = null;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap( (paramMap:ParamMap)=>
			{
				return forkJoin
				({
					item_info: this.rest.item_info.get( paramMap.get('item_id') ),
					store: this.rest.getStore( this.rest.getClientStoreId() as number ),
					user: this.rest.user.get('me')
				})
			})
		)
		.subscribe((response)=>
		{

			this.option_dictionary = {};
			this.order_item_list = [];
			this.store = response.store;
			this.user = response.user;
			response.item_info.options.forEach((iov)=>
			{
				iov.values.forEach((iovi)=>this.qty[ iovi.item_option_value.id ] = 0);
			})
			this.item_info = response.item_info;
		});
	}

	/*
	selectItemOptionInfoValue(ioi:ItemOptionInfo, item_option_value_info:ItemOptionValueInfo)
	{
		this.qty[item_option_value_info.item_option_value.id]++;
		console.log( item_option_value_info );

		let order_item = this.order_item_list.find((oi)=>
		{
			return oi.item_id == item_option_value_info.item_option_value.item_id && oi.item_option_id == ioi.item_option.id;
		});

		if( order_item === undefined )
		{
			order_item = this.getEmptyOrderItem();
			order_item.qty				= 1;
			order_item.item_group		= this.main_order_item.item_group;
			order_item.unitary_price	= item_option_value_info.item_option_value.price;
			order_item.tax_included		= this.main_order_item.tax_included;
			order_item.order_id			= this.main_order_item.order_id;
			order_item.is_item_extra	= 'YES';
			order_item.item_id			= item_option_value_info.item_option_value.item_id;
			order_item.item_option_id	= ioi.item_option.id;
			order_item.item_extra_id	= item_option_value_info.item_option_value.id;
			this.order_item_list.push( order_item );
		}

		let total_selected:number = 0;
		let max_options:number = 0;

		ioi.values.forEach((iovi2:ItemOptionValueInfo)=>
		{
			let found:boolean = false;
			this.order_item_list.forEach((order_item)=>
			{
				if( order_item.item_option_id == ioi.item_option.id )
				{
					total_selected+= order_item.qty;
					found = true;
				}
			});
			if( found )
				max_options++;
		});

		if( max_options > ioi.item_option.max_options )
		{

		}

		ioi.values.forEach((iovi2:ItemOptionValueInfo)=>
		{
			this.order_item_list.forEach((order_item)=>
			{
				if( order_item.item_option_id == ioi.item_option.id )
				{
					total_selected	+= order_item.qty;
				}
			});
		});

		if( total_selected > ioi.item_option.max_extra_qty )
		{

		}
	}

	getEmptyOrderItem():Order_Item
	{
		let order_item:Order_Item = {
			item_id: null,
			delivery_status:'PENDING',
			discount: 0,
			exceptions:null,
			id:null,
			id_payment:null,
			is_free_of_charge:'NO',
			is_item_extra:'NO',
			item_extra_id: null,
			item_group: Date.now(),
			item_option_id:0,
			item_option_qty:0,
			note:null,
			order_id:null,
			original_unitary_price:0,
			paid_qty:0,
			preparation_status:'PENDING',
			price_id:null,
			qty:1,
			return_required:'NO',
			status:'ACTIVE',
			tax_included:'NO',
			subtotal:0,
			system_preparation_ended:new Date(),
			system_preparation_started:new Date(),
			tax:null,
			total: 0,
			unitary_price:0,
			unitary_price_meta: 0,
			updated_by_user_id:null
		};
		return order_item;
	}
	*/

	optionClicked(item_option:ItemOptionInfo, iovi:ItemOptionValueInfo)
	{

		let item_option_id:number = iovi.item_option_value.item_option_id;

		if( !(iovi.item_option_value.id in this.option_dictionary) )
		{
			this.addOneItemOptionValueInfo(iovi);
		}
		else if( iovi.item_option_value.max_extra_qty <= this.option_dictionary[ iovi.item_option_value.id ] )
		{
			this.removeItemOptionValueInfo(iovi);
			return;
		}
		else
		{
			this.addOneItemOptionValueInfo(iovi);

		}

		//Checamos si se pasa y le quitamos
		let total_selected:number =0;
		let max_options:number = 0;

		item_option.values.forEach((iovi2:ItemOptionValueInfo)=>
		{
			if( this.option_dictionary[ iovi2.item_option_value.id ] )
			{
				console.log('WHAYYYYY', this.option_dictionary[ iovi2.item_option_value.id ]);
				total_selected+= this.option_dictionary[ iovi2.item_option_value.id ];
				max_options++;
			}
		});

		console.log( this.option_dictionary )
		console.log( item_option.item_option.max_options);
		console.log( 'Max options', max_options, item_option.item_option.max_options );

		if( max_options > item_option.item_option.max_options )
		{
			if( this.item_option_values_queue_by_item_option_id[ item_option_id ] && this.item_option_values_queue_by_item_option_id[ item_option_id ].length )
			{
				let id = this.item_option_values_queue_by_item_option_id[ item_option_id ][0].item_option_value_id;
				let iovi2	= item_option.values.find(i=>i.item_option_value.id==id);
				this.removeItemOptionValueInfo( iovi2 );
				return;
			}
			else
			{
				console.log('Fallo en el sistema');
			}
		}

		console.log('total selected', total_selected, item_option.item_option.max_extra_qty );

		if( total_selected > item_option.item_option.max_extra_qty )
		{
			if( this.item_option_values_queue_by_item_option_id[ item_option_id ] && this.item_option_values_queue_by_item_option_id[ item_option_id ].length )
			{
				let qty = this.item_option_values_queue_by_item_option_id[ item_option_id ][0];

				if( qty.qty > 0 )
				{
					let id = this.item_option_values_queue_by_item_option_id[ item_option_id ][0].item_option_value_id;
					let iovi2	= item_option.values.find(i=>i.item_option_value.id==id);
					this.removeOneItemOptionValueInfo( iovi2 );
				}
				else
				{
					console.log('Posible error en el sistema xddkdas');
				}
			}
			else
			{
				console.log('Posible error en el sistema codigo2 ffffff');
			}
		}
	}

	addOneItemOptionValueInfo(iovi:ItemOptionValueInfo)
	{
		let item_option_id = iovi.item_option_value.item_option_id;

		if( !(iovi.item_option_value.id in this.option_dictionary) )
		{
			this.option_dictionary[ iovi.item_option_value.id ] = 0;
		}

		this.option_dictionary[ iovi.item_option_value.id ]++;

		if( !(item_option_id in this.item_option_values_queue_by_item_option_id ) )
		{
			this.item_option_values_queue_by_item_option_id[ item_option_id ] = [];
		}

		let qty:Qty = {
			item_option_value_id: iovi.item_option_value.id,
			qty: 0
		}

		let index = this.item_option_values_queue_by_item_option_id[ item_option_id ].findIndex((i:Qty)=>i.item_option_value_id == iovi.item_option_value.id);

		if( index == -1 )
		{
			this.item_option_values_queue_by_item_option_id[ item_option_id ].push( qty );
		}
		else
		{
			//qty = this.item_option_values_queue_by_item_option_id[ item_option_id ][index];
			let qty_array:Qty[] = this.item_option_values_queue_by_item_option_id[ item_option_id ].splice(index,1);
			qty = qty_array[0];
			this.item_option_values_queue_by_item_option_id[item_option_id].push( qty );
		}
		qty.qty++;
	}

	removeOneItemOptionValueInfo(iovi:ItemOptionValueInfo):void
	{
		let item_option_id = iovi.item_option_value.item_option_id;

		if( !(iovi.item_option_value.id in this.option_dictionary) )
		{
			this.option_dictionary[ iovi.item_option_value.id ] = 1;
		}

		this.option_dictionary[ iovi.item_option_value.id ]--;

		if( this.item_option_values_queue_by_item_option_id[ item_option_id ] && this.item_option_values_queue_by_item_option_id[ item_option_id ].length )
		{
			let index = this.item_option_values_queue_by_item_option_id[ item_option_id ].findIndex((i:Qty)=>i.item_option_value_id == iovi.item_option_value.id);

			if( index> -1 )
			{
				this.item_option_values_queue_by_item_option_id[ item_option_id ][index].qty--;
			}

			if( this.item_option_values_queue_by_item_option_id[ item_option_id ][index].qty == 0 )
			{
				this.item_option_values_queue_by_item_option_id[ item_option_id ].splice(index,1);
			}
		}
		else
		{
			console.error( 'Posible error del sistema no se encontrol el indice' );
		}
	}

	removeItemOptionValueInfo(iovi:ItemOptionValueInfo):void
	{
		let item_option_id = iovi.item_option_value.item_option_id;

		if( iovi?.item_option_value?.id && !(iovi?.item_option_value?.id in this.option_dictionary) )
		{
			this.option_dictionary[ iovi.item_option_value.id ] = 1;
		}

		this.option_dictionary[ iovi.item_option_value.id ] = 0;

		if( this.item_option_values_queue_by_item_option_id[ item_option_id ] && this.item_option_values_queue_by_item_option_id[ item_option_id ].length )
		{
			let index = this.item_option_values_queue_by_item_option_id[ item_option_id ].findIndex((i:Qty)=>i.item_option_value_id == iovi.item_option_value.id);

			if( index> -1 )
			{
				this.item_option_values_queue_by_item_option_id[ item_option_id ].splice(index,1);
			}
		}
		else
		{
			console.error( 'Posible error del sistema no se encontrol el indice' );
		}
	}
	agregar()
	{
		let items:OrderItemInfo[] =[];

		let price:Price = this.item_info.prices.find((i:Price)=>
		{
			return i.price_list_id == this.store.price_list_id && this.user.price_type_id == i.price_type_id
		});

		let created = new Date();
		let order_item = GetEmpty.order_item(this.item_info.item);
		order_item.tax_included = price.tax_included;
		order_item.original_unitary_price = price.price;
		order_item.unitary_price_meta = price.price;
		order_item.unitary_price = price.price;
		order_item.total = price.price;
		order_item.is_free_of_charge = 'NO';

		this.rest.updateOrderItemPrice( order_item, this.store.tax_percent, 0);

		items.push
		({
			order_item,
			item: this.item_info.item,
			category: this.item_info.category,
			exceptions: this.item_info.exceptions,
			records: [],
			prices: this.item_info.prices,
			options: [],
			order_item_exceptions: [],
			serials: [],
			serials_string:'',
			created,
			commanda_type_id: this.item_info.item.commanda_type_id
		})

		let item_ids = [ this.item_info.item.id ];
		let index = 0;

		this.item_info.options.forEach((ioi:ItemOptionInfo)=>
		{
			index++;
			let ioi_id = ioi.item_option.id;
			if( ioi_id in this.item_option_values_queue_by_item_option_id )
			{
				let qtities = this.item_option_values_queue_by_item_option_id[ ioi_id ];
				qtities.forEach((qty)=>
				{
					if( qty.qty > 0 )
					{
						let iovi = ioi.values.find(i=>i.item_option_value.id == qty.item_option_value_id );
						if( iovi )
						{
							item_ids.push( iovi.item.id );

							let unitary_price:number = iovi.item_option_value.extra_price;
							let tmp:Order_Item =
							{
								item_id: iovi.item.id,
								item_group: order_item.item_group,
								item_option_id: iovi.item_option_value.item_option_id,
								reservation_item_id: null,
								has_separator: 'NO',
								ieps_calculated: 0,
								ieps_type: 'RATE',
								ieps_value: 0,
								id: 0,
								discount_percent: 0,
								exceptions: '',
								original_unitary_price: unitary_price,
								discount: 0,
								unitary_price_meta: unitary_price,
								unitary_price: 0,
								offer_id: null,
								tax_included: order_item.tax_included,
								is_free_of_charge: unitary_price > 0 ? 'NO' : 'YES',
								type: 'NORMAL',
								stock_status:'IN_STOCK',
								status: 'ACTIVE',
								tax: 0,
								total: 0,
								subtotal: 0,
								qty:order_item.qty*qty.qty,
								item_option_qty: qty.qty,
								note: '',
							};

							let prices:Price[] = [];
							let options:ItemOptionInfo[] = [];
							let d = new Date();
							d.setTime(created.getTime()+(index+1));

							items.push
							({
								prices,
								options,
								order_item:tmp,
								item: iovi.item,
								category: iovi.category,
								records: [],
								exceptions: this.item_info.exceptions,
								order_item_exceptions: [],
								created: d,
								serials_string: '',
								serials: [],
								commanda_type_id: iovi.item.commanda_type_id
							});
							index++;
						}
						else
						{
							console.warn('Error no se encontro el indice');
						}
					}
				});
			}
		});

		items.forEach(i=>console.log('Agregando',i.created,i.created.getTime()));
		this.rest.addOrderItemsToCart( items ).then(()=>
		{
			this.location.back();
			//this.router.navigate(['/c-cart'],{replaceUrl:true});
		},(error)=>
		{
			console.log(error);
		});
	}
}
