<!--ngx-charts-line-chart
	[scheme]="avg_order_graph.scheme"
	[legend]="avg_order_graph.legend"
	[showXAxisLabel]="avg_order_graph.showXAxisLabel"
	[showYAxisLabel]="avg_order_graph.showYAxisLabel"
	[xAxis]="avg_order_graph.xAxis"
	[yAxis]="avg_order_graph.yAxis"
	[xAxisLabel]="avg_order_graph.xAxisLabel"
	[yAxisLabel]="avg_order_graph.yAxisLabel"
	[timeline]="avg_order_graph.timeline"
	[results]="avg_order_graph.results">
</ngx-charts-line-chart -->
<div class="container-fluid">
	<h1>Tickt Promedio</h1>
	<div class="card px-lg-3 px-1 py-3">
		<div class="row">
			<div class="col-5 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,isearch.ge,'created')" class="form-control">
			</div>
			<div class="col-5 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx"  (ngModelChange)="onDateChange($event,isearch.le,'created')"class="form-control">
			</div>
			<div class="col-2 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary w-100" (click)="searchReport()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card p-3">
		<!-- ngx-charts-area-chart-stacked
			[scheme]="avg_order_graph.scheme"
			[legend]="avg_order_graph.legend"
			[showXAxisLabel]="avg_order_graph.showXAxisLabel"
			[showYAxisLabel]="avg_order_graph.showYAxisLabel"
			[xAxis]="avg_order_graph.xAxis"
			[yAxis]="avg_order_graph.yAxis"
			[xAxisLabel]="avg_order_graph.xAxisLabel"
			[yAxisLabel]="avg_order_graph.yAxisLabel"
			[timeline]="avg_order_graph.timeline"
			[results]="avg_order_graph.results">
		</ngx-charts-area-chart-stacked>
      [view]="view"
    -->
    <ngx-charts-line-chart
			[scheme]="avg_order_graph.scheme"
			[legend]="avg_order_graph.legend"
			[showXAxisLabel]="avg_order_graph.showXAxisLabel"
			[showYAxisLabel]="avg_order_graph.showYAxisLabel"
			[xAxis]="avg_order_graph.xAxis"
			[yAxis]="avg_order_graph.yAxis"
			[xAxisLabel]="avg_order_graph.xAxisLabel"
			[yAxisLabel]="avg_order_graph.yAxisLabel"
			[timeline]="avg_order_graph.timeline"
      [results]="avg_order_graph.results"
      >
    </ngx-charts-line-chart>
	</div>
	<div class="card p-3 my-3">
		<table class="w-100">
			<thead>
				<tr>
					<th>Sucursal</th>
					<th class="text-end" *ngFor="let d of days ">{{d.substring(8)}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of avg_order_graph.results">
					<td class="border">{{r.name}}</td>
					<td class="border text-end" *ngFor="let s of r.series">
						<span *ngIf="s.value>=1">{{s.value | number:'1.0-0'}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
