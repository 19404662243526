<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{table.id ? 'Actualizar':'Agregar'}} Mesa</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-6 col-md-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="table.name" class="form-control" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Capacidad</label>
					<input type="number" name="capacity" [(ngModel)]="table.capacity" class="form-control" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Seleccionar</label>
					<select name="store_id" [(ngModel)]="table.store_id" class="form-control" required>
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name | titlecase}}</option>
					</select>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-right">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
