<div class="container-fluid">
	<div class="row mt-3">
		<div class="col-8">
			<h1>Mis Direcciones</h1>
		</div>
		<div class="col-4 text-end">
			<a routerLink="/c-add-address" class="btn btn-secondary">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 22s8.029-5.56 8-12c0-4.411-3.589-8-8-8S4 5.589 4 9.995C3.971 16.44 11.696 21.784 12 22zM8 9h3V6h2v3h3v2h-3v3h-2v-3H8V9z" fill="currentColor"/></svg>
				<span class="d-none d-md-inline ms-2">Agregar</span>
			</a>
		</div>
	</div>
	<div class="card p-3 text-center" *ngIf="address_list.length==0">
		<div>
			No se encontraron direcciones deseas <a routerLink="/c-add-address" class="d-inline">agregar</a> una.
		</div>
	</div>
	<div class="addresses">
		<div *ngFor="let address of address_list" class="address-item">
			<div  class="card p-0 mt-1 overflow-hidden">
				<div >
					<img [src]="'https://maps.googleapis.com/maps/api/staticmap?center='+address.lat+','+address.lng+'&zoom=17&size=600x300&maptype=roadmap
					&markers=color:red|'+address.lat+','+address.lng+'&key='+app_settings.geolocation_key" class="w-100">
				</div>
				<div class="row text-section mx-0 align-items-center">
					<div class="col-10 px-1 px-md-3">
						<div class="title-text my-1">{{address.address}}</div>
						<!--div class="address-text">{{address.address }}</div-->
					</div>
					<div class="col-2">
						<button class="btn btn-primary btn-sm text-end" (click)="confirmRemoveAddress(address)">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-200 0H360v-72h304v72z" fill="currentColor"/></svg>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
