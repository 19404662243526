import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../pages/base/base.component';
import {forkJoin} from 'rxjs';
import {ItemInfo} from '../models/models';
import {Price,Price_Type, Price_List, Currency} from '../models/RestModels';
import {mergeMap} from 'rxjs/operators';
import {ParamMap} from '@angular/router';


interface SuperPrice
{
	price_list:Price_List;
	price_types:Price_Type[];
	prices:Price[];
}

@Component({
	selector: 'app-edit-prices',
	templateUrl: './edit-prices.component.html',
	styleUrls: ['./edit-prices.component.css']
})
export class EditPricesComponent extends BaseComponent implements OnInit {

	item_info:ItemInfo = null;
	price_dict:Record<string,Price> = {};
	price_list:Price[] = [];
	price_list_list:Price_List[] = [];
    super_price_list: SuperPrice[] = [];
	currency_list:Currency[] = [];

	ngOnInit(): void {

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap:ParamMap)=>
			{
				this.item_info = null;
				this.super_price_list = [];
				let id = parseInt(paramMap.get('item_id'));

				return forkJoin
				({
					item_info: this.rest.item_info.get( id ),
          //Aqui no debe ser offline por que es para editar precios
          //No tiene sentido que se pueda editar precios sin conexion
					price_list: this.rest.price_list.search({limit:99999}),//this.rest.getPriceLists(false),
					price_types: this.rest.getPriceTypes(false),
					prices: this.rest.price.search({eq:{item_id:id},limit:999999}),
					currency_list: this.rest.currency.search({limit:999999}),
				})
			})
		)
		.subscribe((responses)=>
		{
			for(let price_list of responses.price_list.data )
			{
				let sp:SuperPrice = {
					price_list,
					price_types: responses.price_types.data.map(i=>i),
					prices: []
				};
				for(let pt of responses.price_types.data)
				{
					let price = responses.prices.data.find((p)=>{
						return p.price_type_id ==  pt.id && p.price_list_id == price_list.id;
					});

					if( price === undefined )
					{
						price = {
							item_id: responses.item_info.item.id,
							price_list_id: price_list.id,
							price_type_id: pt.id,
							currency_id: 'MXN',
							price: 0,
							updated: new Date(),
							percent: 0
						};
					}
					sp.prices.push( price );
				}
				this.super_price_list.push( sp );
			}

			this.item_info = responses.item_info;
			this.currency_list = responses.currency_list.data;

		},(error)=>this.showError(error));
	}

	updatePrice(price:Price)
	{
		this.subs.sink = this.rest.price.create( price ).subscribe(()=>
		{
			this.showSuccess('El precio se actualizo correctamente');
		},(error)=>this.showError(error));
	}

	percentChange(value:number, price:Price,item_info:ItemInfo)
	{
		let oprice  = item_info.item.reference_price || 1;
		price.percent = value;
		price.price = Math.round(oprice*(1+value/100)*100)/100;
		this.updatePrice(price)
	}
	priceChange(value:number,price:Price)
	{
		price.price = value;
		this.updatePrice(price)
	}

	currencyChange(value:string, price:Price)
	{
		price.currency_id = value;
		this.updatePrice(price);
	}

	taxModelChange(value:'YES'|'NO',price:Price)
	{
		price.tax_included = value;
		this.updatePrice( price )
	}
}
