import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent
{
	is_test:boolean  = false;
	ngOnInit()
	{
		if( window.location.hostname.indexOf('127.0.0') == 0 )
		{
			this.is_test = true;
		}
	}
}
