<div class="container-fluid">
	<h2>{{item_info?.item?.name}}</h2>
	<ng-container *ngIf="item_info">
		<ng-container  *ngFor="let ioi of item_info.options">
			<h2>{{ioi.item_option.name |titlecase}} - Máximo {{ioi.item_option.max_extra_qty}} </h2>
			<ul class="list-group">
				<ng-container *ngFor="let ioiv of ioi.values">
					<div class="list-group-item d-flex justify-content-between align-items-center pointer" (click)="optionClicked(ioi,ioiv)">
						{{ioiv.item.name | titlecase}}
						<span class="badge bg-primary rounded-pill" *ngIf="option_dictionary[ioiv.item_option_value.id]">
							<ng-container *ngIf="ioiv.item_option_value.max_extra_qty>1">
								{{option_dictionary[ ioiv.item_option_value.id ] || null | number}}
							</ng-container>
							<ng-container *ngIf="ioiv.item_option_value.max_extra_qty<=1">✓</ng-container>
						</span>
					</div>
				</ng-container>
			</ul>
		</ng-container>
	</ng-container>
	<div class="text-end">
		<button type="button" class="btn btn-secondary my-3" (click)="agregar()">Agregar</button>
	</div>
</div>
