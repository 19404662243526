import { Component, OnInit } from '@angular/core';
import {Preferences} from '../../models/RestModels';
import {BaseComponent} from '../base/base.component';


@Component({
	selector: 'app-save-preferences',
	templateUrl: './save-preferences.component.html',
	styleUrls: ['./save-preferences.component.css']
})

export class SavePreferencesComponent extends BaseComponent implements OnInit {

	preferences:Partial<Preferences>	= {
		button_style: 'transparent'
	};

	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			this.setTitle('Preferencias');
			this.subs.sink = this.rest.preferences.search({ csv:{ id:[ 1 ] }, limit: 1})
			.subscribe((response)=>
			{
				this.is_loading = false;
				if( response.data.length == 1 )
				{
					this.preferences =  response.data[0];
					this.rest.local_preferences = response.data[0];
					this.charts_colors = this.preferences.charts_colors;

					this.menuBackgroundTypeChange(this.preferences.menu_background_type);
				}
				else
				{
					this.preferences = { id: 1 }
				}
			},(error)=>this.showError(error));
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.preferences.id	)
		{
			this.subs.sink	= this.rest.preferences.update( this.preferences ).subscribe((preferences)=>{
				this.is_loading = false;
				this.rest.local_preferences = preferences;
				this.rest.applyTheme();
				localStorage.setItem('preferences', JSON.stringify( preferences ) );
				this.showSuccess('Las preferencias se actualizaron exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.preferences.create( this.preferences ).subscribe((preferences)=>{
				this.is_loading = false;
				this.showSuccess('Las Preferencias se guardaron exitosamente');
			},(error)=>this.showError(error));
		}
	}

	updateNone(prop:string,evt:Event,default_color:string)
	{
		let input = evt.target as HTMLInputElement;

		if( input.checked )
		{
			this.preferences[ prop ] = 'none';
		}
		else
		{
			this.preferences[ prop ] = default_color;
		}
	}

	menuBackgroundTypeChange(value:'IMAGE' | 'COLOR' )
	{
		this.preferences.menu_background_type = value;
		if( value == 'IMAGE' )
		{
			this.preferences.menu_background_color  = 'none';
			this.preferences.menu_color_opacity = 0;
		}
		else
		{
			this.preferences.menu_color_opacity = 100;
			this.preferences.menu_background_image_id = null;
		}
	}

	charts_colors:string = '';

	onChangeChartsColor(colors:string)
	{
		this.preferences.charts_colors = colors;
		this.charts_colors = colors;
	}
}
