import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Subscription} from 'rxjs';
import { GetEmpty } from 'src/app/classes/Empties';
import {KeyboardShortcutEvent} from 'src/app/classes/Utils';
import {BaseComponent} from 'src/app/pages/base/base.component';
import {ShortcutsService} from 'src/app/services/shortcuts.service';
import { ItemInfo, OrderItemInfo, SerialInfo } from 'src/app/models/models';


@Component({
	selector: 'app-select-item-serial',
	templateUrl: './select-item-serial.component.html',
	styleUrls: ['./select-item-serial.component.css']
})

export class SelectItemSerialComponent extends BaseComponent implements OnInit, OnChanges
{
	@Input() item_info:ItemInfo| null = null;
	@Output() orderItemInfoList = new EventEmitter<OrderItemInfo[]>();

	@Input() price_list_id:number | null = null;
	@Input() price_type_id:number = 1;
	@Input() tax_percent:number = 0;
	@Input() store_id:number = 0;

	serials_list:SerialInfo[] = [];

	search_str:string = "";

	//lleva el contador de la cantidad de opciones seleccionadas

	//Lleva el contador de
	//option_qty_dictionary:Record<number,number> = {};

	row_index: number = 0;
	keyboard_subscription: Subscription | null = null;
	item_focus_index:number = 0;
	selected_serial_infos:SerialInfo[] = [];

	@ViewChild(CdkVirtualScrollViewport) list_viewport: CdkVirtualScrollViewport;

	ngOnInit(): void
	{
		this.row_index	= 0;
	}

	filterItems(str:string)
	{
		if(str == "")
		{
			this.serials_list = this.item_info.serials;
			return;
		}
		this.serials_list = this.item_info.serials.filter((si)=>
		{
			return si.serial.serial_number.toLowerCase().includes(str.toLowerCase());
		});
	}

	handleShortcuts(kse:KeyboardShortcutEvent)
	{
		if( !this.item_info )
			return;

		if( kse.shortcut.name == ShortcutsService.SPACE )
		{
			//If list_index in the the array range of list_info.serials
			if(this.serials_list.length <= 0  || this.row_index >= this.serials_list.length)
				return;

			let s = this.serials_list[ this.row_index ];
			s.selected = !s.selected;

			kse.stopPropagation();
			return;
		}

		if( kse.shortcut.name == ShortcutsService.ESCAPE )
		{
			this.orderItemInfoList.emit([]);
			kse.stopPropagation();
			return;
		}

		if( kse.shortcut.name == ShortcutsService.ENTER )
		{
			this.done();
			kse.stopPropagation();
			return;
		}

		if( kse.shortcut.name == ShortcutsService.ARROW_UP )
		{
			if( this.row_index > 0 )
				this.row_index--;

			this.list_viewport.scrollToIndex(this.row_index, "smooth");
			kse.stopPropagation();
			return;
		}
		else if( kse.shortcut.name == ShortcutsService.ARROW_DOWN )
		{

			if( this.row_index < this.serials_list.length-1 )
			{
				this.row_index++;
				this.list_viewport.scrollToIndex(this.row_index, "smooth");
				kse.stopPropagation();
				return;
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		if('item_info' in changes )
		{
			if( changes['item_info'] )
			{
				this.row_index = 0;
				this.subs.unsubscribe();
				this.subs.sink = this.keyboard_subscription = this.shortcuts.shortcuts.subscribe((evt)=>this.handleShortcuts(evt));
				let item_info:ItemInfo = changes['item_info'].currentValue;
				this.serials_list =  item_info.serials.filter((si)=>
				{
					if( this.store_id )
					{
						return si.serial.store_id == this.store_id;
					}
					return true;
				});
			}
			else
			{
				this.subs.unsubscribe();
			}
		}
	}

	optionClicked(serial_info:SerialInfo)
	{
		this.row_index = this.serials_list.findIndex(i=>i===serial_info);
		serial_info.selected  = !serial_info.selected;
	}

	done()
	{
		let items:OrderItemInfo[] =[];

		let order_item_info = GetEmpty.orderItemInfo
		(
			this.item_info.item,
			this.item_info.category,
			this.item_info.price,
			this.item_info.exceptions,
			this.item_info.prices
		);

		let serials = this.item_info.serials.filter((s)=>s.selected);

		if( serials.length )
		{
			order_item_info.serials = serials;
		}
		else
		{
			order_item_info.serials = this.item_info.serials.filter((_s,i)=>i == this.row_index );

		}

		order_item_info.serials.forEach((si)=>{
			si.order_item_serial = {id:0, item_id: si.serial.item_id, order_item_id: order_item_info.order_item.id, serial_id: si.serial.id };
		});

		order_item_info.serials_string = order_item_info.serials
			.map((si)=>si.serial.serial_number)
			.join(',');

		order_item_info.order_item.qty = order_item_info.serials.length;
		this.rest.updateOrderItemPrice( order_item_info.order_item, this.tax_percent, 0);
		items.push( order_item_info );
		this.orderItemInfoList.emit(items);
	}

	cancelar()
	{
		this.orderItemInfoList.emit([]);
		this.item_info = null;
	}
}
