import { Component, OnInit } from '@angular/core';
import {Preferences} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-c-login',
	templateUrl: './c-login.component.html',
	styleUrls: ['./c-login.component.css']
})
export class CLoginComponent extends BaseComponent
{
	preferences:Preferences;
    return_url: string = '';

	ngOnInit()
	{
		this.preferences = this.rest.getPreferencesFromSession();

		this.getQueryParamObservable().subscribe((response)=>
		{
			let query_params = response[0];

			this.return_url = query_params.has('return_url') ? query_params.get('return_url') : '';

			if ( this.rest.getClientPlatformFromSession() != null || this.rest.getUserFromSession() != null )
			{
				if( this.return_url )
				{
					this.router.navigate([this.return_url]);
				}
				else
				{
					this.router.navigate(['/dashboard']);
				}
			}
		});
	}

	username: string = '';
	password: string = '';

	doLoginKeyboard(evt:KeyboardEvent)
	{
		if( evt.code == 'Enter' || evt.code == 'NumpadEnter')
			this.doLogin();
	}

	doLogin() {
		this.is_loading = true;

		this.subs.sink = this.rest.doLoginPlatform(this.username, this.password)
		.subscribe
		(
			()=> {
				this.is_loading = false;
				if( this.return_url )
				{
					this.router.navigate([this.return_url]);
				}
				else
				{
					this.router.navigate(['/dashboard']);
				}

			}, error=>this.showError(error )
		);
	}
}
