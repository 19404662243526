<div class="container-fluid">
	<h1 class="my-3">Inventario {{store.name }} - {{stocktake.created | shortDate:'full'}}</h1>
	<div class="card my-3">
		<div class="row mb-3 py-3 mx-0">
			<div class="col-4 fw-bold border-bottom">Articulos registrados</div>
			<div class="col-8 text-end text-md-start border-bottom">{{cresume.item_count | number}}</div>
			<div class="col-8 col-md-4 fw-bold border-bottom">Articulos Inventariados</div>
			<div class="col-4 col-md-2 text-end text-md-start border-bottom">{{cresume.stocktake_item_count | number}}</div>

			<div class="col-8 col-md-2 fw-bold border-bottom">Total Sistema</div>
			<div class="col-4 col-md-1 text-end text-md-start border-bottom">{{cresume.db_qty_sum | number}}</div>

			<div class="col-8 col-md-2 fw-bold border-bottom">Total inventario</div>
			<div class="col-4 col-md-1 text-end border-bottom">{{cresume.real_qty_sum | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Articulos con Sobrante</div>
			<div class="col-4 col-md-2  text-end text-md-start border-bottom">{{cresume.surplus_item_count | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Total Sobrantes</div>
			<div class="col-4 col-md-2  text-end border-bottom">{{cresume.surplus_qty | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Articulos con faltantes</div>
			<div class="col-4 col-md-2  text-end text-md-start border-bottom">{{cresume.missing_item_count | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Total faltantes</div>
			<div class="col-4 col-md-2  text-end border-bottom">{{cresume.missing_qty | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Articulos con inventario correcto</div>
			<div class="col-4 col-md-2  text-end text-md-start border-bottom">{{ cresume.ok_item_count | number}}</div>
			<div class="col-8 col-md-4  fw-bold border-bottom">Total inventario correcto</div>
			<div class="col-4 col-md-2  text-end border-bottom">{{cresume.ok_item_sum | number}}</div>
		</div>
		<!-- div class="row">
			<div class="col-6" *ngIf="selected_stocktake">
				<button type="button" class="btn btn-primary w-100" *ngIf="'ACTIVE' == selected_stocktake.status" (click)="applyStockDifferences()">Aplicar diferencias</button>
			</div>
			<div class="col-6" *ngIf="selected_stocktake">
				<button type="button" class="btn btn-primary w-100" *ngIf="'ACTIVE' == selected_stocktake.status" (click)="adjustStockValues()">Ajustar Inventarios</button>
			</div>
			<div class="col-12">
				<button type="button" class="btn btn-primary w-100 mt-3" (click)="show_resume=false">Cerrar</button>
			</div>
		</div -->
	</div>

	<div class="card">
		<div class="d-none d-md-flex row border-bottom fw-bold mx-0">
			<div class="col-3">Artículo</div>
			<div class="col-3">Usuario</div>
			<div class="col-6 px-0">
				<div class="row mx-0">
					<div class="col-4 text-end">Cant. Sistema</div>
					<div class="col-4 text-end">Cant. Inventariado</div>
					<div class="col-4 text-end">Fecha</div>
				</div>
			</div>
		</div>
		<div *ngFor="let sti of stocktake_item_info_list" class="row border-bottom mx-0 blast">
			<div class="col-2 col-md-3 blast fw-bold">
				{{(sti.category && rest.local_preferences.display_categories_on_items == 'YES') ? sti.category.name+'-' : ''}}
				{{sti.item.name}}
			</div>
			<div class="col-12 col-md-3 blast">
				{{sti.user.name}}
			</div>
			<div class="col-12 col-md-6 px-0">
				<div class="row mx-0">
					<div class="col-12 col-md-4 text-md-end blast">{{sti.stocktake_item.db_qty | number}}</div>
					<div class="col-12 col-md-4 text-md-end blast">{{sti.stocktake_item.real_qty | number}}</div>
					<div class="col-12 col-md-4 text-md-end">{{sti.stocktake_item.updated | shortDate:'full'}}</div>
				</div>
			</div>
		</div>
	</div>
</div>
