<div class="container-fluid">
	<h1 class="my-3">Error en la geolocalización</h1>
	<div class="card py-3">
		<ul>
			<li>Asegúrese de que el servicio de geolocalización esté habilitado en su dispositivo.</li>
			<li>Asegúrese de que la aplicación tenga permisos para acceder a la ubicación del dispositivo.</li>
			<li>Intente reiniciar su dispositivo y la aplicación.</li>
			<li>Intente acceder a una ubicación diferente con una conexión a Internet más fuerte.</li>
			<li>Si desea ordenar para consumir en una mesa de la sucursal, es necesario que se encuentre en la sucursal</li>
		</ul>
	</div>
</div>
