import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {BankMovementInfo, OrderInfo, PaymentInfo} from 'src/app/models/models';
import {Bank_Movement, Bank_Movement_Order, Currency_Rate} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';
import { Utils } from 'src/app/classes/Utils';
import { GetEmpty } from 'src/app/classes/Empties';

@Component({
	selector: 'app-add-client-payment',
	templateUrl: './add-client-payment.component.html',
	styleUrls: ['./add-client-payment.component.css']
})

export class AddClientPaymentComponent extends BaseComponent implements OnInit
{
	@Input() order_item_qty_dictionary:Record<number,number> = {};
	@Input() partial_amount:number = 0;
	@Input() tag:string = 'SALE';
	@Input() order_info_list:OrderInfo[] = [];
	@Input() currency_rate_list:Currency_Rate[] = [];
	@Output() onPayment = new EventEmitter<PaymentInfo | null>();

	cash:number = 0;
	dolares:number = 0;
	points:number = 0;
	card_amount:number=0;
	transfer:number = 0;
	check:number = 0;
	is_credit_card:boolean = false;
	card_ending:string = null;
	reference:string = null;
	factura_enabled:boolean = false;
	transfer_reference:string = '';
	origin_bank_name:string = '';
	check_reference:string = '';
	is_partial_payment:boolean = false;
	order_info:OrderInfo | null= null;

	payment_info:Partial<PaymentInfo> =
	{
		movements:[],
		payment:
		{
			change_amount:	0,
			currency_id: 'MXN',
			facturado: 'NO',
			payment_amount: 0,
			received_amount: 0,
			sat_pdf_attachment_id: null,
			sat_uuid: null,
			sat_xml_attachment_id: null,
			sync_id: this.rest.getSyncId(),
			tag:'PAYMENT',
			type:'income',
		},
	};

	updatePaymentData()
	{
		console.log('order_info', this.order_info);
		let movements:BankMovementInfo[] = [];

		let bank_movement:Bank_Movement = GetEmpty.bank_movement();

		//El orden es importante
		if( this.card_amount )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					amount_received	: this.card_amount,
					transaction_type: this.is_credit_card ? 'CREDIT_CARD' : 'DEBIT_CARD',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.card_amount,
					origin_bank_name: null,
					currency_id		: 'MXN',
					exchange_rate   : this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					type			: 'income',
					card_ending	: this.card_ending,
				},
				bank_movement_orders:[{
					currency_amount: this.card_amount,
					amount: Utils.truncate(this.card_amount,2)*this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					status: 'ACTIVE',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.transfer )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'TRANSFER',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.transfer,
					amount_received	: this.transfer,
					origin_bank_name: this.origin_bank_name,
					status			: 'ACTIVE',
					currency_id		: 'MXN',
					exchange_rate   : this.getExchangeRate('MXN'),
					type			: 'income',
					reference		: this.reference
				},
				bank_movement_orders:[{
					currency_amount: this.transfer,
					amount: this.transfer*this.getExchangeRate('MXN'),
					status: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.check )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CHECK',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.check,
					exchange_rate: this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					origin_bank_name: this.origin_bank_name,
					amount_received	: this.check,
					currency_id		: 'MXN',
					type			: 'income',
				},
				bank_movement_orders:
				[{
					currency_amount : this.check,
					amount			: this.check*this.getExchangeRate('MXN'),
					currency_id		: 'MXN',
					status			: 'ACTIVE',
					exchange_rate	: this.getExchangeRate('MXN'),
					order_id		: this.order_info.order.id
				}]
			});
		}

		if( this.cash )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CASH',
					client_user_id: this.order_info.order.client_user_id,
					total: this.cash,
					status			: 'ACTIVE',
					amount_received	: this.cash,
					origin_bank_name: null,
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					type: 'income',
				},
				bank_movement_orders:
				[{
					...bank_movement,
					currency_amount: this.cash,
					amount: Utils.truncate(this.cash)*this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					status			: 'ACTIVE',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.dolares )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type	: 'CASH',
					client_user_id		: this.order_info.order.client_user_id,
					total				: this.dolares,
					amount_received		: this.dolares,
					origin_bank_name	: null,
					exchange_rate		: this.getExchangeRate('USD'),
					status				: 'ACTIVE',
					currency_id			: 'USD',
					type				: 'income',
				},
				bank_movement_orders:
				[{
					currency_id		: 'USD',
					currency_amount	: this.dolares,
					amount			: Utils.truncate(this.dolares*this.getExchangeRate('USD')),
					status			: 'ACTIVE',
					exchange_rate	: this.getExchangeRate('USD'),
					order_id		: this.order_info.order.id
				}]
			});
		}

		let received_amount:number = 0;
		let payment_amount:number = 0;
		let total_to_pay:number = (this.order_info.order.total - this.order_info.order.discount ) - this.order_info.order.amount_paid;

		movements.forEach((bmi:BankMovementInfo)=>
		{
			let total = 0;
			bmi.bank_movement_orders.forEach((bmo:Bank_Movement_Order)=>
			{
				received_amount+= bmo.amount;

				if( payment_amount >= total_to_pay )
					return;

				//Si sobra dinero
				if( total_to_pay < (payment_amount+bmo.amount ) )
				{
					let new_amount = total_to_pay - payment_amount;
					bmo.amount = new_amount;
					bmo.currency_amount	= new_amount/bmo.exchange_rate;
					payment_amount	= total_to_pay;
				}
				else
				{
					payment_amount += bmo.amount;
				}
				total += bmo.currency_amount;
			})
			bmi.bank_movement.total = total;
		});


		this.payment_info.payment.payment_amount = payment_amount;
		this.payment_info.payment.received_amount = received_amount;
		this.payment_info.payment.change_amount	= received_amount > payment_amount ? received_amount-payment_amount : 0;
		this.payment_info.movements = movements;
	}

	getExchangeRate(currency_id:string):number
	{
		if( this.order_info.order.currency_id == currency_id )
			return 1;

		let default_currency_id = this.order_info.store.default_currency_id;

		let currency_rate = this.currency_rate_list.find((r:Currency_Rate)=>
		{
			console.log('COnsole',r);
			if( r.store_id != this.order_info.order.store_id )
				return false;

			if( this.order_info.order.currency_id == default_currency_id )
			{
				return r.currency_id == currency_id;
			}

			return r.currency_id == this.order_info.order.currency_id;
		});

		//console.log('FOOOOOOOOOOOOOOOOO is undefined', default_currency_id);

		if( !currency_rate )
		{
			//console.log('FOOOOOOOOOOOOOOOOO is undefined');
		}

		return default_currency_id == this.order_info.order.currency_id
			? currency_rate.rate
			: 1/currency_rate.rate;
	}

	makePayment(_evt:any):void
	{
		this.is_loading = true;
		this.updatePaymentData();
		this.payment_info.payment.paid_by_user_id = this.order_info.order.client_user_id;

		if( this.payment_info.payment.payment_amount > 0 )
		{
			this.subs.sink = this.rest.payment_info
			.create( this.payment_info )
			.subscribe((response:PaymentInfo)=>
			{
				this.showSuccess('El pago se registro correctamente');
				this.onPayment.emit( response );
				this.rest.updateCommandas( this.order_info.order.store_id );

				//this.router.navigate(['/print-payment-receipt',response.payment.id,'1']);
				let x	= window.open('/#/print-payment-receipt/'+response.payment.id + '/' + this.order_info.order.id + '/1');
					x.addEventListener('afterprint',()=>{
				});
			},(error)=>
			{
				this.showError(error);
			})
		}
	}

	addEfectivo(amount:number)
	{
		this.cash += amount;
		this.updatePaymentData();
	}

	efectivoExacto()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		//get the total amount of the order minus the already paid amount from the bank movements order
		let paid_amount = this.order_info.order.amount_paid;
		this.cash = Math.ceil((((this.order_info.order.total - this.order_info.order.discount )- paid_amount) * 100)/rate )/100;
		this.updatePaymentData();
	}

	dolarsExacto()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('USD');
		let paid_amount = this.order_info.order.amount_paid;
		this.dolares = Math.ceil((((this.order_info.order.total - this.order_info.order.discount ) - paid_amount) /rate*100 ) )/100;
		this.updatePaymentData();
	}

	cleanAmounts()
	{
		this.cash = 0;
		this.transfer = 0;
		this.check = 0;
		this.card_amount = 0;
		this.dolares	= 0;
		this.updatePaymentData();
	}

	transferenciaExcta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		let paid_amount = this.order_info.order.amount_paid;
		this.transfer = Math.ceil( (((this.order_info.order.total - this.order_info.order.discount ) - paid_amount)*100)/rate )/100;
		this.updatePaymentData();
	}

	tarjetaExacta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		let paid_amount = this.order_info.order.amount_paid;
		this.card_amount= Math.ceil( (((this.order_info.order.total - this.order_info.order.discount ) - paid_amount)*100)/rate )/100;
		this.updatePaymentData();
	}
	toggle_targeta_credito(evt:any)
	{
		this.is_credit_card = evt.target.checked;
	}
	toggleFactura(evt:any)
	{
		this.factura_enabled = evt.target.checked;
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.partial_amount )
		{
			this.is_partial_payment = true;
		}
	}

	emitClose()
	{
		if( this.is_loading )
			return;

		this.onPayment.emit(null);
	}
}
