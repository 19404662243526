
import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import {Store, User} from '../../models/RestModels';
import { forkJoin } from 'rxjs';

interface CUser{
	user: User;
	store:Store | undefined;
}

@Component({
	selector: 'app-list-user',
	templateUrl: './list-user.component.html',
	styleUrls: ['./list-user.component.css']
})

export class ListUserComponent extends BaseComponent implements OnInit {
	file:File = null;
	show_import:boolean = false;
	user_search:SearchObject<User> = this.getEmptySearch();
	user_list:CUser[] = [];
	store_list:Store[] = [];
	list_user_title:string = '';

	ngOnInit()
	{
		this.path = '/list-user';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","default_billing_address_id",
				"organization_id","default_shipping_address_id","price_type_id",
				"store_id","credit_limit","name","username","contact","business_name",
				"email","phone","type","password","image_id","created_by_user_id",
				"updated_by_user_id", "created", "updated"
			];

			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.user_search = this.getSearch(queryParamMap, fields, extra_keys );
			this.setTitle('Usuarios');

			this.user_search.eq.type = 'USER';
			this.user_search.search_extra['user_permission.is_provider'] = 0;

			this.current_page = this.user_search.page;

			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				user: this.rest.user.search(this.user_search),
				stores: this.rest.getStores()
			})
			.subscribe((responses)=>
			{
				if( this.user_search.lk?.name?.trim() )
				{
					this.list_user_title = 'Búsqueda de usuario: '+this.user_search.lk.name;
				}
				else
				{
					this.list_user_title = 'Usuarios';
				}


				this.store_list = responses.stores.data;
				this.user_list = responses.user.data.map((user)=>{

					let store = this.store_list.find((store)=>user.store_id == store.id );

					return {
						user: user,
						store: store
					}
				});

				this.setPages( this.user_search.page, responses.user.total );
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}
}
