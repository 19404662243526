<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col-8">
			<h1>Editar Precios Categorias
				<span class="fw-bold" *ngIf="price_list">{{price_list.name | uppercase}}</span>
			</h1>
		</div>
		<div class="col-4 text-end">
			<a class="btn btn-primary" *ngIf="price_list" [routerLink]="['/list-price',price_list.id]" >Por Artículo</a>
		</div>
	</div>

	<div class="card px-1 px-lg-3 py-3 my-3">
		<div class="row">
			<div class="col-8 col-md-3 form-group">
				<label>Nombre</label>
				<input type="text" [ngModel]="category_info_search.lk.name" class="form-control" (input)="onSearch($event)">
			</div>
			<div class="col-4 col-md-2 form-group">
				<label>&nbsp;</label>
				<div>
					<button class="btn btn-primary" (click)="search(this.category_info_search)">Buscar</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card px-1 px-lg-3 py-3 my-3">
		<div>
			<div *ngFor="let category_info of category_info_list" class="row border-bottom align-items-center py-3 py-md-1">
				<div class="col-12 col-md-4 fw-bold fw-md-normal">
					<div class="d-md-none">Artículo</div>
					<span>
						<div *ngIf="category_info.category.image_id" class="d-inline-block ratio ratio-1x1" [style]="{'background-image':'url('+rest.getImagePath(category_info.category.image_id)+')','background-size':'contain',width:'40px','vertical-align':'middle','background-repeat':'no-repeat','background-position':'center center'}">
							<img src="/assets/2px_transparent.png" width=40 height="40">
						</div>
						{{category_info.category.name | titlecase}}
					</span>
				</div>
				<div class="col-12 col-md-8 col-lg-px-1">
					<div class="w-100 d-flex justify-content-between flex-wrap">
					 	<div class="mx-1 input-container" *ngFor="let pt of price_type_list">
							<div class="row mx-0">
								<div class="col-12 text-center">
									<label class="d-block text-truncate">{{pt.name | uppercase}}</label>
								</div>
								<div class="col-5 px-0 mx-0">
									<input type="number" [name]="price_list.id+'-'+category_info.category.id+'-'+pt.id"
			   							class="form-control"
	  									[(ngModel)]="price_of[this.price_list.id+'-'+category_info.category.id+'-'+pt.id]"
			 							(ngModelChange)="priceChange(this.price_list,category_info,pt,$event)">
								</div>
								<div class="col-5 ps-1 pe-0 mx-0">
									<select *ngIf="pt.tax_model=='ALL'" class="form-control"
										[(ngModel)]="model_of[this.price_list.id+'-'+category_info.category.id+'-'+pt.id]"
										[name]="'model-'+price_list.id+'-'+category_info.category.id+'-'+pt.id"
				 						(ngModelChange)="modelChange(this.price_list,category_info,pt,$event)"
										>
										<option value="YES">Con Iva</option>
										<option value="NO">Sin Iva</option>
									</select>
									<span *ngIf="pt.tax_model != 'ALL'">
										{{pt.tax_model=='PLUS_TAX'?'Mas IVA':'IVA Incluido'}}
									</span>
								</div>
								<!--div class="col-2 ps-1 pe-0 mx-0 text-center">
									<button type="button" class="btn btn-primary" (click)="saveCategoryPrice(category_info,pt)">
										<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
									</button>
								</div-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
