import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GetEmpty } from 'src/app/classes/Empties';
import { FacturaMetaData } from 'src/app/models/RestModels';
import { BaseComponent } from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-satxmlviewer',
	templateUrl: './satxmlviewer.component.html',
	styleUrls: ['./satxmlviewer.component.css']
})
export class SatxmlviewerComponent extends BaseComponent implements OnInit, OnChanges
{
	@Input() xml_attachment_id:number | null = null;
    factura_metadata: FacturaMetaData = GetEmpty.emptyFacturaMetaData();
    error: string = '';

	ngOnInit(): void
	{

	}
	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.xml_attachment_id.currentValue && changes.xml_attachment_id.previousValue != changes.xml_attachment_id.currentValue )
		{
			let url = this.rest.getFilePath(changes.xml_attachment_id.currentValue,false);
			this.is_loading = true;

			fetch(url)
			.then(response => response.text())
			.then(data =>
			{
				this.factura_metadata = this.getXmlData(data);
				this.is_loading = false;
			})
			.catch(error=>
			{
				console.error('Error al cargar el archivo xml',error);
				this.error = 'Ocurrio un error al cargar el archivo xml';
			});

		}
	}

	getXmlData(data:string):FacturaMetaData
	{
		const parser = new DOMParser();
		const dom:XMLDocument = parser.parseFromString(data, "application/xml");

		let dict:FacturaMetaData = GetEmpty.emptyFacturaMetaData();

		dom.childNodes.forEach((item:Node)=>
		{
			if( item.nodeType == Node.ELEMENT_NODE )
			{
				let zz:Element = item as Element;
				if( zz.tagName == 'cfdi:Comprobante' )
				{
					dict.Fecha = zz.getAttribute('Fecha');
					dict.total = zz.getAttribute('Total')
					dict.noCertificado = zz.getAttribute('NoCertificado');
					dict.moneda = zz.getAttribute('Moneda');
					dict.lugarExpedicion = zz.getAttribute('LugarExpedicion');
					dict.folio	= zz.getAttribute('Folio');
					dict.sello= zz.getAttribute('Sello');
					dict.verificador= dict.sello.substring(dict.sello.length-8);
				}
			}
		});

		dom.firstChild.childNodes.forEach((item:Node)=>
		{
			if( item.nodeType == Node.ELEMENT_NODE )
			{
				let zz:Element = item as Element;
				if( zz.tagName == 'cfdi:Receptor' )
				{
					dict.rfcReceptor = zz.getAttribute('Rfc');
					dict.receptor	= zz.getAttribute('Nombre');
					dict.domicilioReceptor = zz.getAttribute('DomicilioFiscalReceptor');
				}
				else if( zz.tagName == 'cfdi:Emisor' )
				{
					dict.rfcEmisor = zz.getAttribute('Rfc');
					dict.emisor = zz.getAttribute('Nombre');
				}
			}
		});

		let elements = dom.getElementsByTagName("tfd:TimbreFiscalDigital");

		if( elements.length )
		{
			let item = elements.item(0) as Element;
			dict.UUID	= item.getAttribute('UUID');
			dict.fechaTimbrado = item.getAttribute('FechaTimbrado');
		}

		return dict;
	}
}
