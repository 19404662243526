import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import {Utils} from 'src/app/classes/Utils';
import {BankMovementInfo, FacturacionRequest, OrderInfo, OrderItemInfo, PaymentInfo, ReturnsInfo} from 'src/app/models/models';
import {Bank_Movement, Bank_Movement_Order, Billing_Data, Currency_Rate, Order, Payment, Store} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';
import { SearchObject } from 'src/app/services/Rest';
import * as marked from 'marked';

declare function txt2html(str:string):string;
declare function printHtml(html_body:string,title:string):any;


type PAYMENT_TYPE = 'MXN' | 'USD'|'CARD'|'TRANSFER'|'CHECK';
type TRANSACTION_TYPE = Bank_Movement['transaction_type'];
/*
* La primera orden dentro de order_infos sera la que lleve la informacion para facturar
* debera ser la que tenga el id menor deben de estar ordenadas por id de mayor a menor para que no haya problema.
*/

@Component({
	selector: 'app-make-payment-orders',
	templateUrl: './make-payment-orders.component.html',
	styleUrls: ['./make-payment-orders.component.css']
})

export class MakePaymentOrdersComponent extends BaseComponent implements OnInit,OnChanges,AfterViewInit
{
	order_info:OrderInfo | null = null;

	@Input() order_info_list:OrderInfo[] = []; //Ver commentario
	@Input() order_item_qty_dictionary:Record<number,number> = {};
	@Input() partial_amount:number = 0;
	@Input() tag:string = 'SALE';
	@Input() currency_rate_list:Currency_Rate[] = [];
	@Input() store_list:Store[] = [];
	@ViewChild('efectivo') efectivoElement:ElementRef;

	discount_code:string = '';
	copies:number = 1;
	total_to_pay:number;
	payment_type: PAYMENT_TYPE	= 'MXN';

	payment_button_disabled:boolean = true;
	total_received:number = 0;

	cash:number = 0;
	dolares:number = 0;
	points:number = 0;
	card_amount:number=0;
	transfer:number = 0;
	check:number = 0;
	fecha_de_pago:string = this.getDefaultFechaPago();

	is_credit_card:boolean = false;
	card_ending:string = '0000';
	reference:string = null;
	exchange_rate:number = 19.5;
	factura_enabled:boolean = false;
	transfer_reference:string = '';
	origin_bank_name:string = '';
	check_reference:string = '';
	is_partial_payment:boolean = false;
	returns_info_list: ReturnsInfo[] = [];
	items_total:number = 0;
	cash_string:string = '';
	show_other_payment_methods:boolean =false;
	show_discounts:boolean =false;
	currency_rate: any;
	dolares_display:number = 0;

	display_coupon_amount:number = 0;

	getDefaultFechaPago()
	{
		let d = new Date();
		d.setSeconds(0);
		return Utils.getLocalMysqlStringFromDate(new Date()).replace(' ','T');
	}
	ngOnInit()
	{
		this.tag = 'SALE';
		this.subs.sink = this.route.params.subscribe(_params =>
		{
			this.restartVars();
		});
	}

	focusCash()
	{
		let interval_id = setInterval(()=>{
			let x = document.getElementById('efectivo') as HTMLInputElement;
			if( x )
			{
				clearInterval( interval_id );
				try{
					x.focus();
					//x.setSelectionRange(0,x.value.length,'forward');
				}
				catch(e){
					console.log('FOOOOO',e);
				}
			}
		},100);
	}

	ngAfterViewInit()
	{
		//this.efectivoElement.nativeElement.focus();
		//let length = this.efectivoElement.nativeElement.value.length;
		//this.efectivoElement.nativeElement.setSelectionRange(0,length+1);
	}

	restartVars()
	{
		//this.fecha_de_pago = this.getDefaultFechaPago();
		this.returns_info_list = [];

		this.order_info_list.sort((a,b)=>{
			return a.order.id > b.order.id ? 1:-1;
		});

		this.order_info = this.order_info_list[0];

		this.total_to_pay = this.order_info_list.reduce((p,oi)=>{
			return p+(oi.order.total-(oi.order.amount_paid+oi.order.discount));
		},0);

		this.cash = 0;
		this.dolares = 0;
		this.points = 0;
		this.card_amount =0;
		this.transfer = 0;
		this.check = 0;
		this.is_credit_card = false;
		this.card_ending = '0000';
		this.reference = '';
		this.exchange_rate = 21;
		this.factura_enabled = false;
		this.transfer_reference = '';
		this.origin_bank_name = '';
		this.check_reference = '';
		this.is_partial_payment = false;
		this.returns_info_list = [];
		this.payment_button_disabled = true;
	}


	validatePaymentAmounts()
	{
		if( this.cash )
		{
			if( this.dolares || this.points || this.check || this.transfer || this.card_amount )
			{
				this.showError('Solo se debe utilizar una sola forma de pago');
				return false;
			}
		}
		else if( this.dolares )
		{
			if( this.points || this.check || this.transfer || this.card_amount )
			{
				this.showError('Solo se debe utilizar una sola forma de pago');
				return false;
			}
		}
		else if( this.points )
		{
			if( this.check || this.transfer || this.card_amount )
			{
				this.showError('Solo se debe utilizar una sola forma de pago');
				return false;
			}
		}
		else if( this.check )
		{
			if( this.transfer || this.card_amount )
			{
				this.showError('Solo se debe utilizar una sola forma de pago');
				return false;
			}
		}
		else if( this.transfer )
		{
			if( this.card_amount )
			{
				this.showError('Solo se debe utilizar una sola forma de pago');
				return false;
			}
		}


		return true;
	}

	roundTo2(value:number)
	{
		return Math.floor(value*100)/100;
	}

	roundTo4(value:number)
	{
		return Math.floor(value*1000)/1000;
	}

	payment_info:Partial<PaymentInfo> =
	{
		movements:[],
		payment:
		{
			type:'income',
			tag:'SALE',
			payment_amount: 0,
			received_amount: 0,
			facturado: 'NO',
			change_amount: 0,
			currency_id: 'MXN',
			sat_pdf_attachment_id: null,
			sat_uuid: null,
			sat_xml_attachment_id: null,
			sync_id: this.rest.getSyncId()
		},
	};

	getTransactionType(payment_type:PAYMENT_TYPE):TRANSACTION_TYPE
	{
		if( payment_type == 'MXN' )
			return 'CASH';

		if( payment_type == 'USD' )
			return 'CASH';

		if( payment_type == 'CARD' )
		{
			return this.is_credit_card ? 'CREDIT_CARD' :'DEBIT_CARD';
		}

		return payment_type;
	}

  getPaymentCurrencyId(payment_type: PAYMENT_TYPE): string
  {
      if (payment_type == 'USD')
        return 'USD';
      if( payment_type == 'MXN' )
        return 'MXN';

      if( this.order_info_list.length == 0 )
      {
        return 'MXN';
      }

      let order_info = this.order_info_list[0];

      return order_info.order.currency_id;
  }

	resetPaymentInfoFromOrders()
	{
		let total_received = 0;

		let remaining = this.getTotalReceived();

		let reference = '';

		if( this.payment_type == 'CHECK' )
			reference = this.check_reference ;

		if( this.payment_type == 'TRANSFER' )
			reference = this.transfer_reference;

		let transaction_type = this.getTransactionType( this.payment_type );

		let payment_currency_id = this.getPaymentCurrencyId(this.payment_type);

		let amount_received = this.getTotalReceived();

		let total_to_pay_in_payment_currency = this.total_to_pay * this.getExchangeRate(payment_currency_id);

		let total = amount_received > total_to_pay_in_payment_currency ? (total_to_pay_in_payment_currency) : amount_received;

		let bank_movement = GetEmpty.bank_movement();

		let bm:BankMovementInfo= {
			bank_movement: {
				...bank_movement,
				amount_received,
				bank_account_id: null,
				card_ending: this.card_ending,
				client_user_id: this.order_info.order.client_user_id || null,
				created:new Date(),
				currency_id: payment_currency_id,
				exchange_rate: this.getExchangeRate(payment_currency_id),
				id: null,
				invoice_attachment_id: null,
				note: '',
				origin_bank_name: this.origin_bank_name,
				paid_date: Utils.getMysqlStringFromLocalDate( new Date() ).substring(0,10),
				payment_id: null,
				reference: reference,
				status: 'ACTIVE',
				total,
				transaction_type: transaction_type,
				type:'income',
				updated:new Date()
			},
			bank_movement_orders:[]
		};

		for(let oi of this.order_info_list)
		{
			if( remaining <= 0.003)
				break;

			let order_ramaining = oi.order.total-(oi.order.discount+oi.order.amount_paid);
			let bmo_amount = remaining < order_ramaining ? remaining : order_ramaining;

			let bmo:Bank_Movement_Order = {
				status:'ACTIVE',
				order_id: oi.order.id,
				amount: bmo_amount,
				currency_amount: bmo_amount * this.getExchangeRate(payment_currency_id),
				currency_id: payment_currency_id,
				exchange_rate:this.getExchangeRate(payment_currency_id),
			};

			remaining -= bmo_amount;
			bm.bank_movement_orders.push( bmo );
		}
		this.payment_info.movements = [ bm ];
	}

	updatePaymentData()
	{
		if( this.hasElectronicPayment() )
		{

		}

		let movements:BankMovementInfo[] = [];

		//let total = (this.card_amount || 0)
		//			+ (this.cash || 0 )
		//			+ (this.check || 0 )
		//			+ (this.dolares || 0 )
		//			+ (this.transfer || 0 );



		let total_returns_discount:number = 0;

		let bank_movement:Bank_Movement = GetEmpty.bank_movement();

		this.returns_info_list.forEach((returns_info)=>
		{
			let amount = Utils.truncate( returns_info.returns.total-returns_info.returns.amount_paid, 2 );

			if( (total_returns_discount + amount) > this.order_info.order.total )
			{
				amount = Utils.truncate(this.order_info.order.total - total_returns_discount,2);
			}

			total_returns_discount += amount;

			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'RETURN_DISCOUNT',
					client_user_id	: this.order_info.order.client_user_id,
					total			: Utils.truncate(amount,2),
					status			: 'ACTIVE',
					amount_received	: Utils.truncate(amount,2),
					origin_bank_name: null,
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
					reference		: returns_info.returns.code,
				},
				bank_movement_orders:[{
					currency_amount: amount,
					amount: amount * this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			})
		});
		this.display_coupon_amount = total_returns_discount;


		//El orden es importante
		if( this.card_amount )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: this.is_credit_card ? 'CREDIT_CARD' : 'DEBIT_CARD',
					client_user_id	: this.order_info.order.client_user_id,
					total			: Utils.truncate(this.card_amount,2),
					amount_received	: Utils.truncate(this.card_amount,2),
					origin_bank_name: null,
					status			: 'ACTIVE',
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
					card_ending		: this.card_ending,
				},
				bank_movement_orders:[{
					currency_amount: Utils.truncate(this.card_amount,2),
					amount: Utils.truncate(this.card_amount,2) * this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.transfer )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'TRANSFER',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.transfer,
					amount_received	: this.transfer,
					origin_bank_name: this.origin_bank_name,
					status			: 'ACTIVE',
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
					reference		: this.reference
				},
				bank_movement_orders:[{
					currency_amount: this.transfer,
					amount: this.transfer * this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.check )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CHECK',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.check,
					status			: 'ACTIVE',
					amount_received	: this.check,
					exchange_rate	: this.getExchangeRate('MXN'),
					origin_bank_name: this.origin_bank_name,
					currency_id		: 'MXN',
					type			: 'income',
				},
				bank_movement_orders:
				[{
					currency_amount: this.check,
					amount: this.check * this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					status			: 'ACTIVE',
					exchange_rate: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.cash )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CASH',
					client_user_id	: this.order_info.order.client_user_id,
					total			: Utils.truncate(this.cash,2),
					amount_received	: this.cash,
					origin_bank_name: null,
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					type			: 'income',
				},
				bank_movement_orders:
				[{
					currency_amount: Utils.truncate(this.cash),
					amount: Utils.truncate(this.cash) * this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					exchange_rate: this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.dolares )
		{
			let currency_rate = this.currency_rate_list.find((i:Currency_Rate)=>i.currency_id == 'USD' && this.order_info.order.store_id == i.store_id) as Currency_Rate;

			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CASH',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.dolares,
					amount_received	: this.dolares,
					exchange_rate	: this.getExchangeRate('USD'),
					origin_bank_name: null,
					currency_id		: 'USD',
					status			: 'ACTIVE',
					type			: 'income',
				},
				bank_movement_orders:[{
					currency_id		: 'USD',
					status			: 'ACTIVE',
					currency_amount	: this.dolares,
					amount			: Utils.truncate(this.dolares*this.getExchangeRate('USD')),
					exchange_rate	: this.getExchangeRate('USD'),
					order_id		: this.order_info.order.id
				}]
			});
		}

		let received_amount:number = 0;
		let payment_amount:number = 0;

		let order_total = this.getTotalReceived();

		// Este codigo no quede contento no le entendi cuando
		// Lo estaba editando, No esta claro o no me fue claro  a la hora
		// de revisar, se tiene que auditar el codigo cuando se necesite poder
		// hacer pagos parciales

		movements.forEach((bmi:BankMovementInfo)=>
		{
			let total = 0;
			bmi.bank_movement_orders.forEach((bmo:Bank_Movement_Order)=>
			{
				received_amount += bmo.amount;

				if( payment_amount >= order_total )
					return;

				//Si sobra dinero
				if( order_total < (payment_amount+bmo.amount ) )
				{
					let new_amount = order_total-payment_amount;
					bmo.amount = new_amount;
					bmo.currency_amount	= new_amount/bmo.exchange_rate;

					// Originalmente estaba asi
					// payment_amount	= order_total; Codigo posiblmente malo

					payment_amount	= order_total;
				}
				else
				{
					payment_amount += bmo.amount;
				}
				total += bmo.currency_amount;
			})
			bmi.bank_movement.total = total;
		});


		//console.log({payment_amount:payment_amount,received_amount:received_amount});
		// El total del pago se tiene que auditar cuando se necesite agregar pagos parciales

		this.payment_info.payment.payment_amount = received_amount;
		this.payment_info.payment.received_amount = received_amount;
		this.payment_info.payment.change_amount	= received_amount > this.total_to_pay ? received_amount-this.total_to_pay: 0;
		//console.log('change_amount',this.payment_info.payment.change_amount);
		this.payment_info.movements = movements;


		//console.log("Disabled because",!(this?.order_info.client?.id),
		this.payment_button_disabled = ( this.total_to_pay < 0.5 )
		//console.log('Updateed data',this.payment_button_disabled);
		//
		//
		//
		if( this.currency_rate_list.length )
		{
			let currency_rate = this.currency_rate_list.find((i)=>i.currency_id == 'USD' && this.order_info.order.store_id == i.store_id) as Currency_Rate;
			this.dolares_display= Math.ceil( (this.total_to_pay/currency_rate.rate*100 ) )/100;
		}

	}

	hasElectronicPayment():boolean
	{
		return !!(this.card_amount || this.transfer || this.check);
	}

	getExchangeRate(currency_id:string):number
	{
		if( this.order_info.order.currency_id == currency_id )
			return 1;

		let default_currency_id = this.order_info.store.default_currency_id;

		let currency_rate = this.currency_rate_list.find((r:Currency_Rate)=>
		{
			if( r.store_id != this.order_info.order.store_id )
				return false;

			if( this.order_info.order.currency_id == default_currency_id )
			{
				return r.currency_id == currency_id;
			}

			return r.currency_id == this.order_info.order.currency_id;
		});

		//console.log('FOOOOOOOOOOOOOOOOO is undefined', default_currency_id);

		if( !currency_rate )
		{
			//console.log('FOOOOOOOOOOOOOOOOO is undefined');
		}

		return default_currency_id == this.order_info.order.currency_id
			? currency_rate.rate
			: 1/currency_rate.rate;
	}

	makePayment(evt:any)
	{
		//console.log('Procesar pago hay va ');
		this.payment_info.payment.paid_by_user_id = this.order_info.client.id;
		this.is_loading = true;

		if( !this.validatePaymentAmounts() )
		{
			return;
		}

		this.setStoreBillingData();
		this.resetPaymentInfoFromOrders();
		// Registrar Pago

		if( this.rest.is_offline )
		{
			this.showError('Opcion no soportada todavia');
			return;
		}

		console.log('El pago', this.payment_info );
		this.subs.sink = this.rest.makePayment(this.payment_info,null)
		.subscribe
		({
			next:(response)=>
			{
				this.showSuccess('El pago se realizo correctamente');
				this.is_loading = false;
				this.router.navigate(['/view-payment/',response.payment.id]);
			},
			error:(error)=>
			{
				this.showError(error);
			}
		});
	}

	setStoreBillingData()
	{
		if( this.order_info.order.store_id )
		{
			this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;

			if( !this.order_info.order.sat_codigo_postal )
				this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
		}
	}

	addEfectivo(amount:number)
	{
		this.cash += amount;
		this.cash_string = ''+this.cash;
		this.updatePaymentData();
	}

	efectivoExacto()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.cash = Math.ceil(((this.total_to_pay) * 100)/rate )/100;
		this.payment_type = 'MXN';
		this.cash_string = ''+this.cash;
		this.updatePaymentData();
	}

	dolarsExacto()
	{
		this.cleanAmounts();
		let currency_rate = this.getExchangeRate('USD');
		this.payment_type = 'USD';
		this.dolares = Math.ceil( (this.total_to_pay/currency_rate*100 ) )/100;
		this.updatePaymentData();
	}

	cleanAmounts()
	{
		this.cash = 0;
		this.cash_string = '';
		this.transfer = 0;
		this.check = 0;
		this.card_amount = 0;
		this.dolares	= 0;
		//this.total_discount = 0;
		//this.percentage_discount = 0;

		this.updatePaymentData();
		this.returns_info_list = [];
	}

	getTotalReceived()
	{
		switch( this.payment_type )
		{
			case 'USD': return this.dolares;
			case 'CARD': return this.card_amount;
			case 'CHECK': return this.check;
			case 'TRANSFER': return this.transfer;
		}

		return this.cash;
	}

	transferenciaExcta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.transfer = Math.ceil(((this.total_to_pay) * 100)/rate )/100;
		this.payment_type = 'TRANSFER';
		this.updatePaymentData();
	}

	tarjetaExacta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.card_amount = Math.ceil(((this.total_to_pay) * 100)/rate )/100;;
		this.payment_type = 'CARD';
		this.updatePaymentData();
	}
	toggle_targeta_credito(evt:any)
	{
		this.is_credit_card = evt.target.checked;
	}
	toggleFactura(evt:any)
	{
		this.factura_enabled = evt.target.checked;
	}
	ngOnChanges(changes: SimpleChanges): void
	{
		//console.log('On changes update payment data');
		if( changes.order_infos )
		{
			let oi:OrderInfo[] = changes.order_infos.currentValue;

			this.order_info = oi.reduce((p,c)=>{
				return p.order.id < c.order.id ? p : c;
			});
			this.restartVars();
			this.updatePaymentData();
			this.setSatClientRfc();
			this.focusCash();
		}

		if( changes.partial_amount )
		{
			this.is_partial_payment = true;
		}

		this.payment_info.payment.tag = this.tag;
		if ( this.order_info_list.length > 0 )
		{
			this.payment_info.payment.currency_id = this.order_info_list[0].order.currency_id;
		}
	}

	addReturnDiscount(code:string)
	{
		if( !code )
			return;

		let find= this.returns_info_list.find(rs=>rs.returns.code == code.trim())

		if( find !== undefined )
		{
			this.showError('El código ya fue ingresado');
			return;
		}

		this.subs.sink = this.rest.returns_info
		.search({eq:{code:code}})
		.subscribe((response)=>
		{
			if( response.total == 0 )
			{
				this.showError('No se encontro ningún cupon');
				return;
			}

			let return_info = response.data[0];
			if( return_info.returns.total - return_info.returns.amount_paid < 0.01 )
			{
				this.showError('El cupon no puede ser aplicado a esta orden');
				return;
			}

			this.returns_info_list.push( return_info );
			this.discount_code = '';
			this.updatePaymentData();
		},
		(error)=>
		{
			this.showError(error);
		})
	}

	setSatClientRfc()
	{
		if( this.order_info?.order?.sat_receptor_rfc )
			return;

		if( this.order_info?.client?.default_billing_address_id )
		{
			this.subs.sink = this.rest.address
			.get(this.order_info.client.default_billing_address_id)
			.subscribe((address)=>
			{
				this.order_info.order.sat_razon_social = address.name;
				this.order_info.order.sat_receptor_rfc = address.rfc;
				this.order_info.order.sat_receptor_email = address.email;
				this.order_info.order.sat_uso_cfdi = 'G01';
				this.order_info.order.sat_serie = this.order_info.store.default_sat_serie;
				this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
				this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;
				this.updateDatosFacturacionOrder(this.order_info.order);
			});
		}
		else
		{
			let client_user_id = this.order_info.order.client_user_id;

			if( client_user_id )
			{
				let search_info = {eq:{user_id:client_user_id},nn:['rfc'],limit:1};
				this.subs.sink = this.rest.address
				.search( search_info )
				.subscribe((response)=>
				{
					if( response.data.length )
					{
						let address = response.data[0];
						this.order_info.order.sat_receptor_rfc = address.rfc;
						this.order_info.order.sat_razon_social = address.name;
						this.order_info.order.sat_receptor_email = address.email;
						this.order_info.order.sat_uso_cfdi = 'G01';
						this.order_info.order.sat_serie = this.order_info.store.default_sat_serie;
						this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
						this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;
						this.updateDatosFacturacionOrder(this.order_info.order);
					}
				});
			}
		}
	}

	gotoPrintLocation()
	{

	}

	updateDatosFacturacionOrder(order:Order)
	{
		this.subs.sink = this.rest.updateDatosFacturacion(this.order_info.order)
		.subscribe(()=>{

		},(error)=>{

		});
	}

	printPreReceipt(evt:Event)
	{
		this.is_loading = true;
		this.updatePaymentData();

		this.setStoreBillingData();
		// Registrar Pago

		this.subs.sink = ( this.rest.is_offline ? of( this.order_info ) : this.rest.order_info.update(this.order_info) )
		.subscribe(()=>{
			let url = '/index.html#/print-receipt/'+this.rest.local_preferences.default_print_receipt+'/'+this.order_info.order.id+'/print/'+this.copies;
			console.log('Redireccionando');

			//XXX NO BORRAR el log Es indispensable para que siga existiendo el contexto del evento y no bloque la
			//el abrir la ventana
			console.log( evt );
			//XXX NO BORRAR

			this.is_loading = false;

			window.open( url );
		})
	}

	cashChange(amount:number)
	{
		this.cash = amount;
	}
	toggleShowOther(evt:Event)
	{
		let t = evt.target as HTMLInputElement;
		this.show_other_payment_methods = t.checked;
	}

	billing_data = GetEmpty.billing_data();

	currency_formater = new Intl.NumberFormat('us-EN', { style: 'currency', currency: 'USD', currencyDisplay:"narrowSymbol" });
	number_formater = new Intl.NumberFormat('us-EN', { maximumSignificantDigits: 2,} ); // useGrouping:"always"

	formatCurrency(amount:number):string
	{
		return this.currency_formater.format(amount);
	}
	formatNumber(amount:number):string
	{
		return this.number_formater.format(amount);
	}

	service_type_dictionary:Record<string,string> = {
		'TOGO':'PARA LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'A DOMICILIO',
		'QUICK_SALE':'',
	}

	printReceipt2(order_info:OrderInfo, payment_info:PaymentInfo,billing_data:Billing_Data | null, store:Store)
	{
		let currency_rate:Currency_Rate | null = null;
		if( payment_info?.movements )
		{
			dance:
			for(let bmi of payment_info.movements)
			{
				for(let bmo of bmi.bank_movement_orders )
				{
					if( bmo.currency_id != order_info.store.default_currency_id )
					{
						currency_rate = {
							store_id: order_info.store.id,
							currency_id: bmo.currency_id,
							rate: bmo.exchange_rate
						};
						break dance;
					}
				}
			}
		}

		let html:string = '';
		let address = store.address;


		address += (address && store.city ? (','+store.city.toUpperCase()):'');
		address += (address && store.state ? (','+store.city.toUpperCase()):'');
		address += (address && store.state ? (','+store.state.toUpperCase()):'');
		address += (address && store.zipcode ? (','+store.zipcode):'');

		let zipcode = store.zipcode ? ('CP:'+store.zipcode || ''):'';

		let rfc = store.rfc;


		if( this.billing_data )
		{
			address = this.billing_data.address || '';
			address += (address && this.billing_data.city ? (','+this.billing_data.city.toUpperCase()):'');
			address += (address && this.billing_data.state ? (','+this.billing_data.city.toUpperCase()):'');
			address += (address && this.billing_data.state ? (','+this.billing_data.state.toUpperCase()):'');
			address += (address && this.billing_data.zipcode ? (','+this.billing_data.zipcode):'');
			zipcode = this.billing_data.zipcode ? ('CP:'+txt2html(this.billing_data.zipcode) || ''):'';

			rfc = this.billing_data.rfc;
		}

		zipcode += store.phone ? ' TEL:'+txt2html(store.phone) : '';

		let store_bussiness_name = store.business_name || '';
		let phone = store.phone? ('&nbsp;TEL: '+txt2html(store.phone )|| ''):'';

		let image_path = this.rest.getImagePath(order_info.store?.ticket_image_id, this.rest.local_preferences.default_ticket_image_id);

		let tipo = this.service_type_dictionary[order_info.order.service_type];

		let order_title_html = tipo?`<h2 class="text-center text-black">${tipo}</h2>` : '';

		if( order_info.order.status == 'CANCELLED' )
		{
			order_title_html = `<h1 class="text-center">ORDEN CANCELLADA</h1>`
		}


		let order_id_string = order_info.order.id
				? ('<b>ID:</b>'+order_info.order.id)
				: ('<b>IDOF</b>'+order_info.order.sync_id);

		let folio = order_info.order.store_consecutive ? ('<b>FOLIO</b>:'+order_info.order.store_consecutive ) : '';


		let items_html = '';

		for(let order_item_info of order_info.items)
		{
			let item_name = '';

			if( this.rest.local_preferences.display_categories_on_items == 'YES'&&order_item_info.category )
				item_name = order_item_info.category.name+' - ';

			item_name+= order_item_info.item.name;
			let border_top = !(order_item_info.order_item.item_option_id) ? 'border-top' : '';

			let discount = order_item_info.order_item.discount_percent ? '('+order_item_info.order_item.discount_percent+'%)': '';

			items_html += `
				<tr class="${border_top}">
					<td class="truncate td_first">
						${txt2html(item_name)}
					</td>
					<td class="text-end monospace td_other">
						${order_item_info.order_item.type == 'REFUND'?'-':''}
						${this.formatNumber( order_item_info.order_item.qty )}×${this.formatCurrency((order_item_info.order_item.total || 0 )/(order_item_info.order_item.qty || 1))}
						${discount}
					</td>
					<td class="text-end monospace td_other">
						${order_item_info.order_item.type == 'REFUND'?'-':''}
						${this.formatCurrency(order_item_info.order_item.total)}
					</td>
				</tr>
				`
			if( order_item_info.order_item.type == 'REFUND' || order_item_info.order_item.note || order_item_info.order_item.exceptions )
			{
				items_html += `
					<tr>
						<td colspan=3 class="text-start monospace">
							${order_item_info.order_item.type == 'REFUND'?'<div>DEVOLUCIÓN</div>':''}
							${order_item_info.order_item.note?`<div>${txt2html(order_item_info.order_item.note)}</div>`:''}
							${order_item_info.order_item.exceptions?`<div>${txt2html(order_item_info.order_item.exceptions)}</div>`:''}
						</td>
					</tr>`;
			}
		}

		if( order_info.order.discount )
		{
			items_html += `
				<tr class="border-top">
					<td class="text-end monospace td_first">Descuento</td>
					<td class="td_other"></td>
					<td class="text-end monospace td_other">${this.formatCurrency(order_info.order.discount)}</td>
				</tr>`;
		}

		items_html += `
				<tr class="border-top">
					<td class="text-end fw-bold td_first">Total</td>
					<td class="text-center td_other"></td>
					<td class="text-end fw-bold monospace td_other">
						${this.formatCurrency(order_info.order.total-order_info.order.discount)}
					</td>
				</tr>`;

		if(payment_info?.movements)
		{
			items_html+= `
				<tr class="border-top">
					<td class="text-end">Total Recibido</td>
					<td class="text-center"></td>
					<td class="text-end monospace">${this.formatCurrency(payment_info.payment.received_amount)}</td>
				</tr>
				<tr class="mx-0">
					<td class="text-end fw-bold">Cambio</td>
					<td class="text-end fw-bold monospace" colspan=2>${this.formatCurrency(payment_info.payment.change_amount)}MXN</td>
				</tr>`

		}

		if( currency_rate )
		{
			items_html =`
			<tr class="mx-0">
				<td class="text-end fw-bold">Cambio en ${currency_rate.currency_id}}</td>
				<td class="text-end fw-bold monospace" colspan=2>${this.formatCurrency( payment_info.payment.change_amount/currency_rate.rate)} USD</td>
			</tr>
			`;
		}

		if( order_info.order.status == 'CLOSED' && order_info.order.paid_status != 'PAID' && (order_info.order.total-order_info.order.amount_paid >0.01) )
		{
			items_html += `
			<div>
				A través de este pagaré yo
				<b>${txt2html( order_info?.client?.name || '')}</b>, me comprometo a pagar incondicionalmente la cantidad de
				<b>${this.formatCurrency( order_info.order.total-(order_info.order.amount_paid+order_info.order.discount))}</b>
				de la venta con el folio No. ${order_info.order.id}} a la orden
				de
				<b>${txt2html(order_info?.store?.rfc)}</b>. Dicha cantidad será pagada el día ______ de _________ del ______, de la
				forma
				en la que lo solicite el beneficiario de este documento, y en caso de que no se realice tal precisión, en el
				domicilio
				del suscriptor.
				<br>
				<b>${txt2html(order_info.store.city+' '+order_info.store.state)}</b>, a
				<b>${Utils.getDateString( order_info.order.created, true )}</b>
				<br>
				<br>
			</div>
			`
		}

		html += `
			<style>
				.px-0{
					padding-left: 0;
					padding-right: 0;
					text-center:
				}
				.text-center{
					text-align: center;
				}
				.text-end{
					text-align: right;
				}
				.w-100
				{
					width:100%;
				}
				.border-bottom
				{
					border-bottom: 1px solid #AAAAAA;
				}

				.fw-bold
				{
					font-weight: bold;
				}
				.border-top
				{
					border-top: 1px solid #AAAAAA;
				}
				.break_pages
				{
					filter: grayscale(100%);
					break-after: page;
				}

				.td_first
				{
					width:33%;
					overflow: hidden;
				}

				.td_other
				{
					width:33%;
				}

				@media screen{
					.image_logo_ticket
					{
						width: 25%;
						padding-bottom: 15px;
					}
				}

				@media print {
					.image_logo_ticket
					{
						width: 80%;
						padding-bottom: 15px;
					}
				}

				a {
					color: #000;
					text-decoration: none;
				}

				#markdown a:visited,
				#markdown a:link,
				#markdown a:hover,
				#markdown a:focus
				{
					color: #000 !important;
					text-decoration: none !important;
				}

				.truncate
				{
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

			</style>
			<div class="print_ticket_container">
		`;

		let footer = '';
		if(order_info.store.autofacturacion_settings != 'DISABLED' && order_info.order.paid_status == 'PAID' && order_info.store.default_billing_data_id )
		{
			footer += `
			<div class="text-center">
				Código:
				<code style="font-size:20px;color:#000000;text-transform:uppercase">${order_info.order.facturacion_code}</code>
			</div>
			`
		}
		if( order_info.store.ticket_footer_text )
		{
			footer+=`<div id="markdown" class="text-center">${marked( order_info.store.ticket_footer_text )}</div>`;
		}

		for(let i=0;i<this.copies;i++)
		{
			html += `
				<div class="break_pages px-0">
					<div class="text-center">
						<img src="${image_path}" class="image_logo_ticket">
					</div>
					<div style="text-transform:uppercase">
						<div class="fw-bold text-center">
							Sucursal ${txt2html(store.name)}
						</div>
						<div class="text-center pb-2">
							<div>${txt2html(store_bussiness_name ||'')}</div>
							<div>${rfc}</div>
							<span>${txt2html(address)}</span>
							<div>
								<span>${zipcode}</span>
							</div>
						</div>
					</div>
					${order_title_html}
					<table>
						<tr>
							<td>
								<div>${order_id_string}</div>
								<div>${folio}</div>
							</td>
							<td class="text-end">
								Fecha:<br>
								<b>${Utils.getDateString(order_info.order.created, true)}</b>
							</td>
						</tr>
					</table>

					<table class="mx-0" style="table-layout: fixed; width: 100%;">
						<tr class="border-bottom">
							<td class="fw-bold border-bottom td_first truncate">Artículo</td>
							<td class="fw-bold text-center border-bottom td_other truncate">Cantidad</td>
							<td class="fw-bold text-end border-bottom td_other truncate">Total</td>
						</tr>
						${items_html}
					</table>
					${footer}
				</div>
			`
		}

		html += `</div>`
		//console.log("big html is", html);

		console.log('%c Se mando a imprimir ventana flotante', 'background: #FF0000; color: #bada55');
		printHtml( html, 'Orden-'+( order_info.order.id || order_info.order.sync_id ) );

	}

	printFloatWindowReceipt(order_info:OrderInfo):Observable<OrderInfo>
	{
		if( this.rest.local_preferences.default_print_receipt != 3 )
		{
			return of( order_info );
		}

		let search:SearchObject<Payment> = this.getEmptySearch();
		search.search_extra = {order_id: order_info.order.id};
		search.limit = 1;
		search.sort_order=['id_ASC'];


		//console.log('Ultima vez que se ve por aqui');
		return forkJoin
		({
			order: of( order_info ),
			store: this.rest.getStore( order_info.order.store_id ),
			payment_info: this.rest.is_offline
				? this.rest.getPaymentInfoByOrderSyncId( order_info.order.sync_id )
				: this.rest.payment_info.search( search )
		})
		.pipe
		(
			mergeMap((response)=>
			{
				//console.log('Todos regresaron el problema es billing_data posiblemente');
				return forkJoin({
					order: of(response.order),
					store: of( response.store ),
					payment_info: of( response.payment_info ),
					billing_data: this.rest.getBillingData()
				});
			}),
			mergeMap((responses)=>
			{
				this.rest.printer_ticket_config = responses.store.printer_ticket_config;
				this.rest.createStructuredItems( responses.order );
				let store = responses.store;

				let billing_data = responses.billing_data.data.find(bd=>bd.id == responses.store.id) || null;

				let payment_info:PaymentInfo = {} as PaymentInfo;

				if( responses.payment_info.total )
				{
					payment_info= responses.payment_info.data[0];
				}

				this.printReceipt2(responses.order, payment_info, billing_data, store);
				return of( order_info );
			}
		));
	}
	onElementFocus(evt:Event)
	{
		let input = evt.currentTarget as HTMLInputElement;
		//input.setSelectionRange(0,input.value.length);
		if( this.card_ending == '0000' )
			this.card_ending = '';
	}

	isMobile()
	{
		const userAgent = navigator.userAgent.toLowerCase();
		const isMobile = /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
		const isTablet = /tablet|ipad/i.test(userAgent);
		return isMobile || isTablet;
	}
}
