<div>
	<label class="">
		<div *ngIf="image; else defaultImage" class="" style="max-width:250px;max-height:250px;position:relative;">
			<!-- avatar avatar-4by3 -->
			<div [ngClass]="container_classes"> <!-- ngStyle={'width':width,'height':height,'background-image': 'url('+rest.urlBase+'/image.php?id='+image+')'} -->
				<img [src]="rest.domain_configuration.domain+'/'+rest.urlBase+'/image.php?id='+image" [ngClass]="image_classes" style="max-width:250px;max-height:250px;width:100%"> <!-- class="avatar-img rounded" -->
			</div>
			<button *ngIf="can_be_deleted" type="button" class="" style="position:absolute;z-index:9999;top:0;right:0;" (click)="onDelete($event)">
				<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 384 512"><path fill="currentColor" d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206L56.6 43.5c-11.3-13.6-31.5-15.4-45.1-4.1S-3.9 70.9 7.4 84.5L150.3 256L7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306l135.4 162.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256L376.6 84.5z"/></svg>
			</button>
		</div>
		<ng-template #defaultImage>
			<div class="logo_container svg-icon" style="width:width;height:height;">
				<svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0, 0, 400,400"><g id="svgg"><path id="path0" d="M54.349 74.629 C 50.008 79.249,50.000 79.444,50.000 175.280 L 50.000 271.303 54.629 275.651 C 59.241 279.984,59.594 280.000,149.692 280.000 C 220.323 280.000,240.282 280.547,240.842 282.500 C 256.910 338.538,320.707 349.024,349.448 300.350 C 366.842 270.894,347.955 226.128,314.574 217.692 L 306.148 215.562 305.574 146.603 L 305.000 77.643 300.278 73.821 C 295.749 70.156,290.731 70.000,177.127 70.000 L 58.697 70.000 54.349 74.629 M292.000 140.206 L 292.000 196.412 277.600 183.706 C 269.680 176.718,257.340 165.825,250.178 159.500 C 231.572 143.070,238.906 138.963,167.475 205.818 L 159.949 212.861 143.433 196.431 C 123.375 176.477,129.062 175.944,94.674 201.000 C 78.823 212.550,65.437 222.000,64.927 222.000 C 64.417 222.000,64.000 190.950,64.000 153.000 L 64.000 84.000 178.000 84.000 L 292.000 84.000 292.000 140.206 M107.444 104.271 C 90.141 117.177,92.624 142.980,112.139 153.072 C 135.235 165.015,161.099 137.396,148.903 113.812 C 142.016 100.494,119.465 95.304,107.444 104.271 M317.580 233.817 C 354.163 252.634,348.301 305.475,308.470 315.931 C 268.722 326.366,239.218 278.624,264.739 245.165 C 275.662 230.843,301.156 225.369,317.580 233.817 M283.243 253.754 C 275.618 261.460,271.858 266.798,272.326 269.254 C 273.387 274.815,281.546 275.124,287.196 269.816 L 292.000 265.303 292.000 283.714 C 292.000 314.269,304.568 313.624,305.582 283.017 L 306.164 265.456 310.903 269.909 C 316.341 275.017,322.621 274.684,323.671 269.231 C 324.509 264.881,302.939 242.000,298.000 242.000 C 296.281 242.000,289.641 247.289,283.243 253.754 " stroke="none" fill="currentColor" fill-rule="evenodd"></path></g></svg>
			</div>
		</ng-template>
		<input style="display:none" type="file" (change)="uploadImage($event)" accept="image/*">
	</label>
</div>
