<div class="container-fluid">
	<div class="row align-items-end mx-0">
		<div class="col">
			<h4 class="text-capitalize">returns</h4>
		</div>
	</div>

	<!-- div>
		<div class="row">
				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="returns_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Store</label>
					<input type="number" name="store_id" [(ngModel)]="returns_search.eq.store_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Client user</label>
					<input type="number" name="client_user_id" [(ngModel)]="returns_search.eq.client_user_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Order</label>
					<input type="number" name="order_id" [(ngModel)]="returns_search.eq.order_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Total</label>
					<input type="number" name="total" [(ngModel)]="returns_search.eq.total" step="any" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Note</label>
					<input type="text" name="note" [(ngModel)]="returns_search.lk.note" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Cashier user</label>
					<input type="number" name="cashier_user_id" [(ngModel)]="returns_search.eq.cashier_user_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Amount paid</label>
					<input type="number" name="amount_paid" [(ngModel)]="returns_search.eq.amount_paid" step="any" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Code</label>
					<input type="text" name="code" [(ngModel)]="returns_search.lk.code" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Tax</label>
					<input type="number" name="tax" [(ngModel)]="returns_search.eq.tax" step="any" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="returns_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="returns_search.eq.updated" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Store</th>
						<th>Client user</th>
						<th>Order</th>
						<th>Total</th>
						<th>Note</th>
						<th>Cashier user</th>
						<th>Amount paid</th>
						<th>Code</th>
						<th>Created</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let returns of returns_info_list">
						<td>
							<a [routerLink]="['/print-return-ticket',returns.returns.id]">
								{{returns.returns.id}}
							</a>
						</td>
						<td>{{returns.store.name}}</td>
						<td>{{returns?.client_user?.name}}</td>
						<td>{{returns.returns.order_id}}</td>
						<td>{{returns.returns.total}}</td>
						<td>{{returns.returns.note}}</td>
						<td>{{returns?.cashier_user?.name}}</td>
						<td>{{returns.returns.amount_paid}}</td>
						<td>{{returns.returns.code}}</td>
						<td>{{returns.returns.created | date: 'MMM d, y' }}</td>
					</tr>
					<!--tr *ngIf="returns_list == null || returns_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr-0-->
				</tbody>
			</table>
		</div>
	</div>
	<!--app-pagination [path]="'/list-returns'" [pages]="pages" [totalPages]="totalPages" [currentPage]="currentPage"></app-pagination-->
</div>
