import { Component } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { BankMovementInfo, OrderInfo, PaymentInfo} from 'src/app/models/models';
import { Bank_Movement_Order, Payment } from 'src/app/models/RestModels';
import { SearchObject,RestResponse } from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

interface Bank_Movement_Order_Custom extends Bank_Movement_Order
{
	sum: number;
	balance: number;
	payment_type: string;
}

@Component({
	selector: 'app-print-payment-receipt',
	templateUrl: './print-payment-receipt.component.html',
	styleUrls: ['./print-payment-receipt.component.css']
})
export class PrintPaymentReceiptComponent extends BaseComponent
{
	payment_info:PaymentInfo | null = null;
	orders_by_id:Record<number,OrderInfo> = {};
	payment_info_list:PaymentInfo[] = [];
	order_info:OrderInfo | null = null;
	sums:Bank_Movement_Order_Custom[] = [];
	balance:number = 0;
	all_partial_payments:number = 0;

	legend:Record<string,string> = {
		'CASH-MXN':'Efect.',
		'CASH-USD':'Dlls.',
		'CREDIT_CARD-MXN':'T. Déb.',
		'DEBIT_CARD-MXN':'T. Créd.',
		'CHECK-MXN':'Cheq.',
		'TRANSFER-MXN':'Transf.',
	};

	print: boolean = false;

	order_id:number | null = null;

	total_received:number = 0;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				this.payment_info = null;
				this.orders_by_id = {};

				this.print = param_map.get('print') != null;
				this.order_id = param_map.get('order_id') ? parseInt(param_map.get('order_id')) : null;
				return this.rest.payment_info.get( param_map.get('payment_id') )
			}),
			mergeMap((payment_info)=>
			{
				this.payment_info = payment_info;
				this.all_partial_payments = 0;

				if( !this.order_id)
				{
					this.order_id = payment_info.movements.reduce((prev:null|number,bm)=>{
						if( prev )
							return prev;
	
						return bm.bank_movement_orders.reduce((prev:null|number,bo:Bank_Movement_Order)=>{
							if( prev )
								return prev;
	
							return bo.order_id;
						},null);
					}, null);
	
				}

				let	payment_search:SearchObject<Payment> = this.getEmptySearch();
				payment_search.limit = 99999;
				payment_search.le.id = payment_info.payment.id;
				payment_search.sort_order = ['id_ASC'];
				payment_search.search_extra= { order_id: this.order_id };

				return forkJoin
				({
					payments: this.rest.payment_info.search( payment_search ) as Observable<RestResponse<PaymentInfo>>,
					order_info: this.rest.order_info.get( this.order_id )
				})
			})
		)
		.subscribe((response)=>
		{
			this.payment_info_list = response.payments.data as PaymentInfo[];
			console.log('Payment info list', this.payment_info_list);
			this.order_info = response.order_info;

			let descripcion:Bank_Movement_Order_Custom[] = [];

			let sum = 0;

			this.payment_info_list.forEach((payment_info)=>
			{
				if( payment_info.payment.id > this.payment_info.payment.id )
					return;

				payment_info.movements.forEach((movement)=>
				{
					movement.bank_movement_orders.forEach((bmo)=>
					{
						//filtrar los que correspondan a la orden
						if( bmo.order_id != this.order_info.order.id )
							return;

						sum += bmo.amount;
						let balance = this.order_info.order.total -(this.order_info.order.discount + sum);

						let payment_type:string = movement.bank_movement.transaction_type;

						let bmoc:Bank_Movement_Order_Custom = {
							...bmo,sum,balance, payment_type
						};

						descripcion.push(bmoc);
					});
				});
			});

			this.sums = descripcion;
			this.all_partial_payments = sum;
			if( this.print )
			{
				setTimeout(()=>{
					window.print();
					window.close();
				},200);
			}
		},(error)=>this.showError(error));
	}

	justPrint()
	{
		window.print();
	}

	back()
	{
		this.location.back();
	}
}
