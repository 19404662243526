<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col-12 col-md-4 col-lg-6">
			<h1 class="text-capitalize">Corte Por Sucursal</h1>
		</div>
		<div class="col-2 col-lg-2 text-end">
			&nbsp;
			<ng-container *ngIf="rest.has_hades">
				<b>Seleccionado</b>:<br>
				<span>{{total_facturacion |number: '1.2-2'}}</span>
			</ng-container>
		</div>
		<div class="text-end" [ngClass]="headers_classes">
			<b>#Ordenes</b>
			<br>
			<span>{{order_info_list.length|number}}</span>
		</div>
		<div class="text-end" [ngClass]="headers_classes">
			<b>total</b>:<br>
			<span>{{order_total |number: '1.2-2'}}</span>
		</div>
	</div>

	<div class="card my-3 py-3 px-1 px-lg-3" *ngIf="rest.has_hades">
		<div class="row align-items-center">
			<div class="col-12 col-md-5 py-1">Porcentaje a aplicar</div>
			<div class="col-12 col-lg-4 py-1">
				<input type="number" name="percentage_to_select" class="form-control" [(ngModel)]="percentage_to_select" min="0" max="100" step="1">
			</div>
			<div class="col-12 col-lg-3 py-1">
				<button type="button" class="btn btn-primary w-100" (click)="selectPercentage()">
					Seleccionar por porcentaje
				</button>
			</div>
		</div>
	</div>


	<div class="card my-3 p-3">
		<table class="table w-100">
			<thead>
				<tr>
					<th *ngFor="let pr of payment_resume_list" class="text-center">{{pr.name}}</th>
				</tr>
			</thead>
			<tbody>
				<td *ngFor="let pr of payment_resume_list" class="text-center">{{pr.amount | currency}}</td>
			</tbody>
		</table>
	</div>
	<form name="form_search">
		<div class="card my-3 p-3">
			<div class="row">
				<div class="col-6 col-md-3 form-group" ngNativeValidate>
					<label class="">Sucursal</label>
					<div class="fw-bold" *ngIf="!rest.current_permission.global_pos">{{user_store.name | titlecase}}</div>
					<select name="store_id" [(ngModel)]="order_search.eq.store_id" class="form-control" *ngIf="rest.current_permission.global_pos" required>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<!--div class="col-6 col-md-3 form-group">
					<label class="">Precio</label>
					<select name="price_type_id" [(ngModel)]="order_search.eq.price_type_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of price_type_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div-->
				<!--div class="col-6 col-md-3 form-group">
					<label class="">Metodo de pago</label>
					<select name="transaction_type" [(ngModel)]="order_search.search_extra.transaction_type" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option value="CASH">Efectivo</option>
						<option value="CREDIT_CARD">Tarjeta Credito</option>
						<option value="DEBIT_CARD">Tarjeta Debito</option>
						<option value="CHECK">Cheque</option>
						<option value="COUPON">Coupon</option>
						<option value="TRANSFER">Transferencia</option>
						<option value="DISCOUNT">Descuento</option>
					</select>
				</div-->
				<!--div class="col-6 col-md-3 form-group">
					<label class="">IVA</label>
					<input type="number" name="tax_percent" [(ngModel)]="order_search.eq.tax_percent" step="any" class="form-control">
				</div-->
				<!--div class="col-6 col-md-3 form-group">
					<label class="">Moneda</label>
					<input type="text" name="currency_id" [(ngModel)]="order_search.eq.currency_id" list="currency_list" class="form-control">
					<datalist id="currency_list">
						<option value="MXN">Pesos MXN</option>
						<option value="USD">Dolares</option>
					</datalist>
				</div-->
				<!--div class="col-6 col-md-3 form-group">
					<label class="">Cliente</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.lk.client_name" class="form-control">
				</div-->

				<div class="col-6 col-md-3 form-group">
					<label class="">Fecha</label>
					<input type="date" name="date" [(ngModel)]="order_search.search_extra.date" (ngModelChange)="fechaPricipalChanges($event)" class="form-control" required>
				</div>
				<div class="col-3 form-check form-switch pt-2">
					<label class="form-check-label">
						<input type="checkbox" name="custom_range" class="form-check-input" (click)="onCustomRangeClick($event)" [checked]="custom_range">
						Rango de horas personalizado
					</label>
				</div>

				<!-- XXX esto funciona pero se simplifico la funcionalida -->

				<div class="col-6 col-md-3 form-group" *ngIf="custom_range">
					<label class="">Fecha Inicial</label>
					<input type="datetime-local" name="inicial_created" [disabled]="!custom_range" [ngModel]="fecha_inicial" (ngModelChange)="fechaIncialChange($event)" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group" *ngIf="custom_range">
					<label class="">Fecha Final</label>
					<input type="datetime-local" name="final_created" [min]="fecha_inicial" [disabled]="!custom_range" [ngModel]="fecha_final" (ngModelChange)="fechaFinalChange($event)" class="form-control">
				</div>
				<!--div class="col-6 col-md-3 form-group">
					<label class="">Cajero:</label>
					<select name="cashier_id" [(ngModel)]="order_search.eq.cashier_user_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of cashier_user_list" [value]="c.id">{{c.name | titlecase}}</option>
					</select>
				</div-->
				<div class="col-6 col-md-3">
					<label>&nbsp;</label>
					<div>
						<button type="button" class="w-100 btn btn-primary" (click)="search(order_search)">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
							&nbsp;
							<span>Buscar</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
	<form (submit)="save($event)" ngNativeValidate>
		<div class="my-3 text-end">
			<button type="submit" class="btn btn-secondary">Guardar</button>
		</div>

		<div class="card p-3 my-3">
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="table-responsive d-none d-lg-block">
				<table class="table mb-0">
					<thead class="thead-light">
						<tr>
							<th style="width:30px" (click)="sortBy('Id')">Id</th>
							<th (click)="sortBy('Client')">Cliente</th>
							<th (click)="sortBy('Cashier')">Atendio</th>
							<th (click)="sortBy('Price_type')">Precio</th>
							<th (click)="sortBy('Date')">Fecha</th>
							<!-- <th class="text-center" (click)="sortBy('Payment')">Pago</th> -->
							<th class="text-end" (click)="sortBy('Total')">Total</th>
							<th class="text-end text-end" [ngClass]="{'d-none':!rest.has_hades}">
								<div class="row mx-0 w-100">
									<div class="col-8 px-0">
										<span (click)="sortBy('Selected')">Ordenar</span>
									</div>
									<div class="col-4 text-end ps-2 pe-0">
										<div class="form-check form-switch">
											<label class="form-check-label">
												<input type="checkbox" name="all_checked" class="form-check-input" [checked]="all_checked" (click)="toggleAll()">
											</label>
										</div>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let oi of order_info_list">
							<td>
								<a [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
							</td>
							<td>
								<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
								<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
								</span>
								<a [routerLink]="['/view-order',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
							</td>
							<td>{{oi?.cashier.name | titlecase }}</td>
							<td>{{oi.price_type_name | titlecase}}</td>
							<td>{{oi.order.closed_timestamp | shortDate: 'full' }}</td>
							<!-- <td class="text-center">
								<span *ngIf="oi.paid_cash" class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path fill="currentColor" d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"/></svg>
								</span>
								<span *ngIf="oi.paid_check" class="text-warning">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"><path fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128v256c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm208 128h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zm-16 112c0-8.8 7.2-16 16-16h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zm-91.9-144v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7.1 5c.3.5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8.2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6v5.5c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7.7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z"/></svg>
								</span>
								<span *ngIf="oi.paid_card" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"/></svg>
								</span>

								<span *ngIf="oi.paid_transfer" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0z"/><path fill="currentColor" d="M8.56 11.9a1.5 1.5 0 0 1 0 2.12l-.974.976H16a1.5 1.5 0 0 1 0 3H7.586l.975.974a1.5 1.5 0 1 1-2.122 2.122l-3.535-3.536a1.5 1.5 0 0 1 0-2.121l3.535-3.536a1.5 1.5 0 0 1 2.122 0Zm6.88-9a1.5 1.5 0 0 1 2.007-.104l.114.103l3.535 3.536a1.5 1.5 0 0 1 .103 2.007l-.103.114l-3.535 3.536a1.5 1.5 0 0 1-2.225-2.008l.103-.114l.975-.974H8a1.5 1.5 0 0 1-.144-2.994L8 5.996h8.414l-.975-.975a1.5 1.5 0 0 1 0-2.122Z"/></g></svg>
								</span>
							</td> -->
							<td class="text-end">{{(oi.order.total -oi.order.discount) | currency:'$' }}</td>
							<td class="text-end" [ngClass]="{'d-none':!rest.has_hades}">
								<div class="form-check form-switch">
									<label class="form-check-label">
										<input type="checkbox" [name]="'selected_order_'+oi.order.id" class="form-check-input" [checked]="oi.checked" (click)="clickedOnOrder(oi)" [disabled]="oi.disabled">
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
			<div class="d-block d-lg-none">
				<div class="row border-bottom" *ngFor="let oi of order_info_list">
					<div class="col-9">
						<div class="row mb-2">
							<div class="col-3 px-1">
								<div>
									<div>
										<a [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
									</div>
									<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
									<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
									<div>{{oi.order.closed_timestamp | shortDate: 'full' }}</div>
								</span>

								</div>
							</div>
							<div class="col-9 px-0">
								<a [routerLink]="['/view-order',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
								<div class="">{{oi?.cashier.name | titlecase }}</div>
								<div>{{oi.price_type_name | titlecase}}</div>
							</div>
						</div>
					</div>
					<div class="col-3 text-end">
						<div class="">
							<span *ngIf="oi.paid_cash" class="text-success">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path fill="currentColor" d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"/></svg>
							</span>
							<span *ngIf="oi.paid_check" class="text-warning">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"><path fill="currentColor" d="M64 64C28.7 64 0 92.7 0 128v256c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm208 128h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16s7.2-16 16-16zm-16 112c0-8.8 7.2-16 16-16h224c8.8 0 16 7.2 16 16s-7.2 16-16 16H272c-8.8 0-16-7.2-16-16zm-91.9-144v6.3c6.6 1.2 16.6 3.2 21 4.4c10.7 2.8 17 13.8 14.2 24.5s-13.8 17-24.5 14.2c-3.8-1-17.4-3.7-21.7-4.3c-12.2-1.9-22.2-.3-28.6 2.6c-6.3 2.9-7.9 6.2-8.2 8.1c-.6 3.4 0 4.7.1 5c.3.5 1 1.8 3.6 3.5c6.1 4.2 15.7 7.2 29.9 11.4l.8.2c12.1 3.7 28.3 8.5 40.4 17.4c6.7 4.9 13 11.4 16.9 20.5c4 9.1 4.8 19.1 3 29.4c-3.3 19-15.9 32-31.6 38.7c-4.9 2.1-10 3.6-15.4 4.6v5.5c0 11.1-9 20.1-20.1 20.1s-20.1-9-20.1-20.1v-6.4c-9.5-2.2-21.9-6.4-29.8-9.1c-1.7-.6-3.2-1.1-4.4-1.5c-10.5-3.5-16.1-14.8-12.7-25.3s14.8-16.1 25.3-12.7c2 .7 4.1 1.4 6.4 2.1c9.5 3.2 20.2 6.9 26.2 7.9c12.8 2 22.7.7 28.8-1.9c5.5-2.3 7.4-5.3 8-8.8c.7-4 .1-5.9-.2-6.7c-.4-.9-1.3-2.2-3.7-4c-5.9-4.3-15.3-7.5-29.3-11.7l-2.2-.7c-11.7-3.5-27-8.1-38.6-16c-6.6-4.5-13.2-10.7-17.3-19.5c-4.2-9-5.2-18.8-3.4-29c3.2-18.3 16.2-30.9 31.1-37.7c5-2.3 10.3-4 15.9-5.1v-6c0-11.1 9-20.1 20.1-20.1s20.1 9 20.1 20.1z"/></svg>
							</span>
							<span *ngIf="oi.paid_card" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"/></svg>
							</span>

							<span *ngIf="oi.paid_transfer" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M0 0h24v24H0z"/><path fill="currentColor" d="M8.56 11.9a1.5 1.5 0 0 1 0 2.12l-.974.976H16a1.5 1.5 0 0 1 0 3H7.586l.975.974a1.5 1.5 0 1 1-2.122 2.122l-3.535-3.536a1.5 1.5 0 0 1 0-2.121l3.535-3.536a1.5 1.5 0 0 1 2.122 0Zm6.88-9a1.5 1.5 0 0 1 2.007-.104l.114.103l3.535 3.536a1.5 1.5 0 0 1 .103 2.007l-.103.114l-3.535 3.536a1.5 1.5 0 0 1-2.225-2.008l.103-.114l.975-.974H8a1.5 1.5 0 0 1-.144-2.994L8 5.996h8.414l-.975-.975a1.5 1.5 0 0 1 0-2.122Z"/></g></svg>
							</span>
						</div>
						<div class="text-end">{{(oi.order.total -oi.order.discount) | currency:'$' }}</div>
						<div class="text-end" [ngClass]="{'d-none':!rest.has_hades}">
							<div class="form-check form-switch">
								<label class="form-check-label">
									<input type="checkbox" [name]="'selected_order_'+oi.order.id" class="form-check-input" [checked]="oi.checked" (click)="clickedOnOrder(oi)" [disabled]="oi.disabled">
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</form>
</div>
