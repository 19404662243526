import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';

import {Merma} from '../../models/RestModels';
import {Item} from '../../models/RestModels';
import {Store,User} from '../../models/RestModels';
import {forkJoin} from 'rxjs';
import {MermaInfo} from 'src/app/models/models';
import {Utils} from 'src/app/classes/Utils';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';


@Component({
	selector: 'app-list-merma',
	templateUrl: './list-merma.component.html',
	styleUrls: ['./list-merma.component.css']
})

export class ListMermaComponent extends BaseComponent implements OnInit
{
	file:File = null;
	show_import:boolean = false;
	merma_search:SearchObject<Merma> = this.getEmptySearch();
	merma_info_list:MermaInfo[] = [];
	store_dictionary:Record<number,Store> = {};
	user_list:User[] = [];


	item_list:Item[] = [];
	store_list:Store[] = [];

	fecha_inicial:string = '';
	fecha_final:string = '';

	ngOnInit()
	{
		this.path = '/list-merma';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","store_id","item_id","qty","created","created_by_user_id","updated","box_id","shipping_id","note" ];
			let extra_keys:Array<string> = ['category_name'];

			this.merma_search = this.getSearch(queryParamMap, fields, extra_keys );
			this.merma_search.sort_order = ['id_DESC'];

			let finicial = queryParamMap.get('ge.created');
			let ffinal = queryParamMap.get('lt.created');

			this.fecha_inicial = finicial ? finicial.substring(0,10) :'';
			this.fecha_final = ffinal ? ffinal.substring(0,10):'';

			this.setTitle('Merma');
			this.current_page = this.merma_search.page;
			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				merma : this.rest.merma_info.search(this.merma_search),
				item : this.rest.item.search({limit:9999}),
				store : this.rest.getStores(true),
				user: this.rest.user.search({eq:{type:'USER'},limit:99999,sort_order:['name_ASC']})
			})
			.subscribe((responses)=>
			{
				responses.store.data.forEach((i) =>{ this.store_dictionary[i.id] = i });
				this.merma_info_list = responses.merma.data;
				this.setPages( this.merma_search.page, responses.merma.total );
				this.item_list = responses.item.data;
				this.store_list = responses.store.data;
				this.user_list = responses.user.data;
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}

	exportXls()
	{
		let new_search:SearchObject<Merma> = { ...this.merma_search};
		new_search.limit = 999999;
		this.subs.sink = this.rest.merma_info.search( new_search )
		.subscribe((response)=>{
			let rows:any[] = response.data.map(i=> {
				let store:string = this.store_dictionary[ i.merma.store_id ].name;

				return {
					Id: i.merma.id,
					Categoría: i?.category?.name,
					Sucursal: store,
					Usuario: i?.user?.name || '',
					Artículo: i.item.name,
					Cantidad: i.merma.qty,
					Nota: i.merma.note
				};
			});

			//	let Referencia = '';

			let str = Utils.getMysqlStringFromLocalDate(new Date() ).substring(0,10)+'.xlsx';
			let headers:string[] = [
				'Id','Categoría','Artículo',
				'Sucursal','Usuario', 'Cantidad',
				'Nota'
			];
			ExcelUtils.array2xlsx( rows, str, headers );
		});
	}

	onFechaFinalChange(str:string)
	{
		this.fecha_final = str;
		this.merma_search.lt.created = Utils.getDateFromLocalMysqlString( str );
	}

	onFechaInicialChange(str:string)
	{
		this.fecha_inicial = str;
		this.merma_search.ge.created = Utils.getDateFromLocalMysqlString(str);
	}
}
