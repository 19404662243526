<div class="container-fluid">
	<h1 class="my-3">Ventas por Folio</h1>
	<div class="card px-1 px-md-3 mt-3">
		<div class="row my-3">
			<div class="col-6 col-md-6 form-group">
				<label>Sucursal</label>
				<select name="store_id" [(ngModel)]="store_id" class="form-control">
					<option *ngFor="let store of store_list" value="store.id">{{store.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group" >
				<label>Fecha</label>
				<input type="date" name="date" [(ngModel)]="date" class="form-control">
			</div>
			<div class="col-12 col-md-3 form-group" >
				<label>&nbsp;</label>
				<div>
					<button type="button" (click)="dosearch($event)" class="btn btn-primary w-100">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<app-loading [is_loading]="is_loading"></app-loading>

	<div class="card p-3 my-3">
		<table class="w-100">
			<thead>
				<tr>
					<th>Orden</th>
					<th class="text-end">Total {{default_currency_id}}</th>
					<th class="text-end">Descuento</th>
					<th class="text-center">Estado</th>
					<th *ngFor="let c of currencies" class="text-end">
						Recibido {{c}}
					</th>
					<th class="text-end">Total Recibido en {{default_currency_id}}</th>
					<th class="text-end">Cambio {{default_currency_id}}</th>
			</thead>
			<tbody>
				<tr *ngFor="let sf of records">
					<td>
						<a [routerLink]="['/view-order',sf.order_id]">{{sf.order_id}}</a>
					</td>
					<td class="text-end">{{sf.total_default_currency|currency}}</td>
					<td class="text-end">{{sf.discount | currency}}</td>
					<td class="text-center">
						<ng-container *ngIf="sf.status=='CANCELLED'">CANCELADA</ng-container>
					</td>
					<td *ngFor="let rc of sf.received_currencies" class="text-end">
						<ng-container *ngIf="rc.received_other_currency != 0">
							{{rc.received_other_currency | currency}} {{rc.currency_id}}
						</ng-container>
					</td>
					<td class="text-end">{{sf.total_received_default_currency | currency }}</td>
					<td class="text-end">
							{{sf.change_default_currency | currency }} {{default_currency_id}}
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th>Total</th>
					<th class="text-end">{{total.total_default_currency|currency}}</th>
					<th class="text-end">{{total.discount | currency}}</th>
					<th class="text-center">
						<!--ng-container *ngIf="sf.status=='CANCELLED'">{{sf.status}}</ng-container-->
					</th>
					<th *ngFor="let rc of total.received_currencies" class="text-end">
						<ng-container *ngIf="rc.received_other_currency != 0">
							{{rc.received_other_currency | currency}} {{rc.currency_id}}
						</ng-container>
					</th>
					<th class="text-end">{{total.total_received_default_currency | currency }}</th>
					<th class="text-end">
						{{total.change_default_currency | currency }} {{default_currency_id}}
					</th>
				</tr>
			</tfoot>
		</table>
	</div>

	<div class="card p-3 my-3" *ngIf="cancelled.length">
		<table class="w-100 mt-3">
			<thead>
				<tr>
					<th>Orden</th>
					<th class="text-end">Total {{default_currency_id}}</th>
					<th class="text-end">Descuento</th>
					<th class="text-center">Estado</th>
					<th *ngFor="let c of currencies" class="text-end">
						Recibido {{c}}
					</th>
					<th class="text-end">Total Recibido en {{default_currency_id}}</th>
					<th class="text-end">Cambio {{default_currency_id}}</th>
			</thead>
			<tbody>
				<tr *ngFor="let sf of cancelled">
					<td>
						<a [routerLink]="['/view-order',sf.order_id]">{{sf.order_id}}</a>
					</td>
					<td class="text-end">{{sf.total_default_currency|currency}}</td>
					<td class="text-end">{{sf.discount | currency}}</td>
					<td class="text-center">
						<ng-container *ngIf="sf.status=='CANCELLED'">CANCELADA</ng-container>
					</td>
					<td *ngFor="let rc of sf.received_currencies" class="text-end">
						<ng-container *ngIf="rc.received_other_currency != 0">
							{{rc.received_other_currency | currency}} {{rc.currency_id}}
						</ng-container>
					</td>
					<td class="text-end">{{sf.total_received_default_currency | currency }}</td>
					<td class="text-end">
							{{sf.change_default_currency | currency }} {{default_currency_id}}
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th>Total</th>
					<th class="text-end">{{total_cancelled.total_default_currency|currency}}</th>
					<th class="text-end">{{total_cancelled.discount | currency}}</th>
					<th class="text-center">
						<!--ng-container *ngIf="sf.status=='CANCELLED'">{{sf.status}}</ng-container-->
					</th>
					<th *ngFor="let rc of total_cancelled.received_currencies" class="text-end">
						<ng-container *ngIf="rc.received_other_currency != 0">
							{{rc.received_other_currency | currency}} {{rc.currency_id}}
						</ng-container>
					</th>
					<th class="text-end">{{total_cancelled.total_received_default_currency | currency }}</th>
					<th class="text-end">
						{{total_cancelled.change_default_currency | currency }} {{default_currency_id}}
					</th>
				</tr>
			</tfoot>
		</table>
	</div>
				<!--
		<div class="row fw-bold">
			<div class="col-2">Orden</div>
			<div class="col-2 text-end">Total {{default_currency_id}}</div>
			<div class="col"
			<div class="col-2 text-end">Total</div>
			<div class="col-2 text-end">Recibido</div>
			<div class="col-2 text-end">Cambio</div>
			<div class="col-2 text-end">Estatus</div>
		</div>
		<div class="row" *ngFor="let cc of sf.received_currencies" [ngClass]="{'strike':sf.status == 'CANCELLED'}">
			<div class="col-2">{{cc.order_id}}</div>
			<div class="col-2 text-end">{{cc.total | currency}} {{default_currency_id}}</div>
			<div class="col-2 text-end">
				<span *ngIf="cc.currency_id != default_currency_id">
					{{cc.total_currency | currency}} {{cc.currency_id}}
				</span>
			</div>
			<div class="col-2 text-end">{{cc.received | currency}} {{cc.currency_id}}</div>
			<div class="col-2 text-end">{{cc.change | currency}} {{default_currency_id}}</div>
			<div class="col-2 text-end">{{cc.status == 'CANCELLED' ? 'Cancelada' : ''  }}</div>
		</div>
		<div class="row fw-bold">
			<div class="col-2">Cancelado</div>
			<div class="col-2 text-end">{{sf.descuento.total | currency }} {{default_currency_id}}</div>
			<div class="col-2 text-end"></div>
			<div class="col-2 text-end">{{sf.descuento.received | currency}} {{sf.total.currency_id}}</div>
			<div class="col-2 text-end">{{sf.descuento.change | currency}} {{default_currency_id}}</div>
			<div class="col-2 text-end"></div>
		</div>
	</div>
			-->
</div>
