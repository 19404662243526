import { Component, OnInit } from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import {PaymentInfo} from 'src/app/models/models';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-print-expense-payment',
	templateUrl: './print-expense-payment.component.html',
	styleUrls: ['./print-expense-payment.component.css']
})
export class PrintExpensePaymentComponent extends BaseComponent implements OnInit {
	payment_info: PaymentInfo;
	is_print:boolean = false;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((param_map)=>
			{
				let id =	param_map.get('payment_id');
				this.setTitle('ReciboGasto-'+id);
				this.is_print = param_map.has('print');
				return this.rest.payment_info.get( id )
			})
		)
		.subscribe((payment_info) =>
		{
			this.payment_info = payment_info;
			if( this.is_print )
			{
				window.addEventListener('afterprint', ()=>{
					window.close();
				})
				setTimeout(()=>{ window.print(); }, 300);
			}
		},(error)=>this.showError(error));
	}

	print()
	{
		window.print();
	}
}
