<div class="container-fluid">
	<h1 class="text-capitalize my-3">{{preferences.id ? 'Actualizar':'Agregar'}} Preferencias</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="row">
			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Imagen Principal</h4>
				<div class="card p-3">
					<div class="row p-3">
						<div class="col-6 col-md-4">
							<label class="">Nombre</label>
							<input type="text" name="name" [(ngModel)]="preferences.name" class="form-control">
						</div>
						<!--div class="col-6 col-md-4">
							<label class="">Imagen De Usuario</label>
							<app-image-uploader [(image)]="preferences.default_user_logo_image_id"></app-image-uploader>
						</div-->
						<div class="col-6 col-md-4">
							<label class="">Logo</label>
							<app-image-uploader [(image)]="preferences.logo_image_id" [max_width]="500"></app-image-uploader>
						</div>
						<div class="col-6 col-md-4">
							<label class="">Imagen de Login</label>
							<app-image-uploader [(image)]="preferences.login_image_id" [max_width]="500"></app-image-uploader>
						</div>
						<div class="col-6 col-md-4">
							<label class="">Imagen default de Ticket</label>
							<app-image-uploader [(image)]="preferences.default_ticket_image_id"></app-image-uploader>
						</div>
						<!--div class="col-6 col-md-4">
							<label class="">Cliente imagen</label>
							<app-image-uploader [(image)]="preferences.default_user_logo_image_id"></app-image-uploader>
						</div-->
						<div class="col-6 col-md-4">
							<label class="">Color de Links</label>
							<input type="color" name="link_color" [(ngModel)]="preferences.link_color" class="form-control">
						</div>
						<div class="col-6 col-md-4">
							<label class="">Sobre El Link</label>
							<input type="color" name="link_hover" [(ngModel)]="preferences.link_hover" class="form-control">
						</div>

					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Fondo</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 col-md-6">
							<div>
								<label class="">Imagen de Fondo Principal</label>
								<app-image-uploader [(image)]="preferences.background_image_id" [max_width]="1920"></app-image-uploader>
							</div>
							<div>
								<select class="form-control" name="background_image_size" [(ngModel)]="preferences.background_image_size">
									<option value="cover">Completa</option>
									<option value="repeat">Repetir</option>
								</select>
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div>
								<label class="">Imagen Fondo Login</label>
								<app-image-uploader [(image)]="preferences.login_background_image_id"  [max_width]="1920"></app-image-uploader>
							</div>
							<div>
								<select class="form-control" name="login_background_image_size" [(ngModel)]="preferences.login_background_image_size">
									<option value="cover">Completa</option>
									<option value="repeat">Repetir</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Color de Gráficas</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-6">
							<input type="color" name="graphics_color" class="form-control" [ngModel]="charts_colors" (ngModelChange)="onChangeChartsColor($event)">
						</div>
						<div class="col-6">
							<input type="text" name="charts_colors" class="form-control" [(ngModel)]="preferences.charts_colors">
						</div>
					</div>
				</div>
				<h4 class="my-3">Títulos y Texto</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 col-md-6 form-group">
							<label>Color de Titulos</label>
							<input type="color" name="titles_color" class="form-control" [(ngModel)]="preferences.titles_color">
						</div>
						<div class="col-6 col-md-6 form-group">
							<label>Color de textos</label>
							<input type="color" name="text_color" class="form-control" [(ngModel)]="preferences.text_color">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-4">

				<h4 class="my-3">Cajas de contenido</h4>

				<div class="card p-3">
					<div class="row">
						<div class="col-12 form-group">
							<label>Color Fondo</label>
							<input type="color" name="card_background_color" class="form-control" [(ngModel)]="preferences.card_background_color">
						</div>

						<div class="col-12 form-group">
							<label>
								Opacidad color de fondo
								<b>{{preferences.card_background_opacity}}</b>
							</label>
							<input type="range" min="1" max="100" name="card_background_opacity" class="form-control" [(ngModel)]="preferences.card_background_opacity">
						</div>


						<div class="col-6 form-group">
							<label>Redondeo de cajas</label>
							<input type="text" name="card_border_radius" class="form-control" [(ngModel)]="preferences.card_border_radius">
						</div>

						<div class="col-6 form-group">
							<label>Color borde</label>
							<input type="color" name="card_border_color" class="form-control" [(ngModel)]="preferences.card_border_color">
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Menú</h4>
				<div class="card mt-3 p-3">
					<div class="row">
						<div class="col-6 ">
							<label class="">Tipo de Fondo de Menú</label>
							<select class="form-control" name="menu_background_type" [ngModel]="preferences.menu_background_type" (ngModelChange)="menuBackgroundTypeChange($event)">
								<option value="IMAGE">Imagen de fondo</option>
								<option value="COLOR">Color de fondo</option>
							</select>
						</div>
						<div class="col-6" *ngIf="preferences?.menu_background_type == 'IMAGE'">
							<label class="">Imagen de fondo</label>
							<app-image-uploader [(image)]="preferences.menu_background_image_id"></app-image-uploader>
						</div>
						<div class="col-6" *ngIf="preferences?.menu_background_type == 'IMAGE'">
							<label>Tamaño imagen Fondo</label>
							<select class="form-control" name="menu_background_image_size" [(ngModel)]="preferences.menu_background_image_size">
								<option value="cover">Completa</option>
								<option value="repeat">Repetir</option>
							</select>
						</div>


						<div class="col-6"  *ngIf="preferences?.menu_background_type == 'COLOR'">
							<label class="">Color de Fondo</label>
							<input type="color" name="menu_background_color" [(ngModel)]="preferences.menu_background_color" class="form-control">
						</div>
						<div class="col-12"  *ngIf="preferences?.menu_background_type == 'COLOR'">
							<label class="">Opacidad Color Fondo</label>
							<input type="range" min="1" max="100" name="menu_color_opacity" class="form-control" [(ngModel)]="preferences.menu_color_opacity">
						</div>

					</div>

					<div class="row">
						<div class="col-6">
							<label class="">Color de texto</label>
							<input type="color" name="menu_text_color" [(ngModel)]="preferences.menu_text_color" class="form-control">
						</div>
						<div class="col-6">
							<label class="">Color de iconos</label>
							<input type="color" name="menu_icon_color" [(ngModel)]="preferences.menu_icon_color" class="form-control">
						</div>
						<div class="col-6">
							<label class="">Color de texto del titulo</label>
							<input type="color" name="menu_title_color" [(ngModel)]="preferences.menu_title_color" class="form-control">
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Submenú</h4>
				<div class="card mt-3 p-3">
					<div class="row">
						<div class="col-12">
							<label class="">Color de fondo</label>
							<input type="color" name="submenu_background_color" [(ngModel)]="preferences.submenu_background_color" class="form-control">
						</div>
						<div class="col-12">
							<label class="">Opacidad Color Fondo Menú <b>{{preferences.submenu_color_opacity}}</b></label>
							<input type="range" min="1" max="100" name="submenu_color_opacity" class="form-control" [(ngModel)]="preferences.submenu_color_opacity">
						</div>
						<div class="col-6">
							<label class="">Color de Texto</label>
							<input type="color" name="submenu_text_color" [(ngModel)]="preferences.submenu_text_color" class="form-control">
						</div>
						<div class="col-6">
							<label class="">Color de Iconos</label>
							<input type="color" name="submenu_icon_color" [(ngModel)]="preferences.submenu_icon_color" class="form-control">
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Encabezado</h4>
				<div class="card mt-3 p-3">
					<div class="row">
						<div class="col-6 col-md-4" *ngIf="preferences.header_background_color!='none'">
							<label class="">Color de Fondo Encabezado</label>
							<input type="color" name="header_background_color" [(ngModel)]="preferences.header_background_color" class="form-control">
						</div>

						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Encabezado Sin Color de fondo
								<input type="checkbox" name="bg_color_transparente" (click)="updateNone('header_background_color',$event,'#8f6653')" class="form-check-input" [checked]="preferences.header_background_color=='none'">
							</label>
						</div>
						<div class="col-6 col-md-4" *ngIf="preferences.header_background_color!='none'">
							<label class="">Color Texto</label>
							<input type="color" name="header_text_color" [(ngModel)]="preferences.header_text_color" class="form-control">
						</div>
					</div>
				</div>
				<h4 class="my-3">Estilo de Botones</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-6 form-group">
							<label>Fondo</label>
							<select name="button_style" class="form-control" [(ngModel)]="preferences.button_style">
								<option value="transparent">Plano</option>

								<option value="linear-gradient(180deg,rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.40), rgba(255, 255, 255, 0))" selected>
									Tipo 1 Negro
								</option>

								<option value="linear-gradient(180deg,rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.40), rgba(255, 255, 255, 0))">
									Tipo 1 Blanco
								</option>

								<option value="linear-gradient(180deg,rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4))">
									Tipo 2 Negro
								</option>

								<option value="linear-gradient(180deg,rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.4))">
									Tipo 2 Blanco
								</option>

								<option value="linear-gradient(180deg,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0))">
									Tipo 3 Negro
								</option>

								<option value="linear-gradient(180deg,rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.0))">
									Tipo 3 Blanco
								</option>

								<option value="linear-gradient(180deg,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))">
									Tipo 4 Negro
								</option>
								<option value="linear-gradient(180deg, rgba(255, 255, 255, 0.0),rgba(255, 255, 255, 0.5))">
									Tipo 4 Blanco
								</option>
							</select>
						</div>
						<div class="col-6">
							<label>Radio de borde</label>
							<input type="text" name="card_border_color" class="form-control" [(ngModel)]="preferences.button_border_radius">
						</div>
						<div class="col-6">
							<label>Ejemplo estilo</label>
							<div>
								<button type="button" class="btn btn-primary" [ngStyle]="{'background-image':preferences.button_style}">EJEMPLO</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Botón Primario</h4>
				<div class="card p-3 my-3">
					<div class="row my-3">
						<div class="col-6 form-group">
							<label>Color texto</label>
							<input type="color" name="btn_primary_text_color" class="form-control" [(ngModel)]="preferences.btn_primary_text_color">
						</div>
						<div class="col-6 form-group">
							<label>Color texto mouse arriba</label>
							<input type="color" name="btn_primary_text_color_hover" class="form-control" [(ngModel)]="preferences.btn_primary_text_color_hover">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de fondo
								<input type="checkbox" (click)="updateNone('btn_primary_bg_color',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_primary_bg_color=='none'">
							</label>
						</div>

						<div class="col-6 form-group" *ngIf="preferences.btn_primary_bg_color != 'none'">
							<label>Color fondo</label>
							<input type="color" name="btn_primary_bg_color" class="form-control" [(ngModel)]="preferences.btn_primary_bg_color">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de fondo mouse arriba
								<input type="checkbox" (click)="updateNone('btn_primary_bg_color_hover',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_primary_bg_color_hover=='none'">
							</label>
						</div>

						<div class="col-6 form-group" *ngIf="preferences.btn_primary_bg_color_hover != 'none'">
							<label>Color fondo mouse arriba</label>
							<input type="color" name="btn_primary_bg_color_hover" class="form-control" [(ngModel)]="preferences.btn_primary_bg_color_hover">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de borde
								<input type="checkbox" name="btn_primary_border_color" (click)="updateNone('btn_primary_border_color',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_primary_border_color=='none'">
							</label>
						</div>
						<div class="col-6 form-group" *ngIf="preferences.btn_primary_border_color != 'none'">
							<label>Color de borde</label>
							<input type="color" name="btn_primary_border_color" class="form-control" [(ngModel)]="preferences.btn_primary_border_color">
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de borde mouse arriba
								<input type="checkbox" name="btn_primary_border_color_hover" (click)="updateNone('btn_primary_border_color_hover',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_primary_border_color_hover=='none'">
							</label>
						</div>
						<div class="col-6 form-group" *ngIf="preferences.btn_primary_border_color_hover != 'none'">
							<label>Color fondo borde mouse arriba</label>
							<input type="color" name="btn_primary_border_color_hover" class="form-control" [(ngModel)]="preferences.btn_primary_border_color_hover">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-4">
				<h4 class="my-3">Botón Secundario</h4>
				<div class="card p-3 my-3">
					<div class="row my-3">
						<div class="col-6 form-group">
							<label>Color texto</label>
							<input type="color" name="btn_secondary_text_color" class="form-control" [(ngModel)]="preferences.btn_secondary_text_color">
						</div>

						<div class="col-6 form-group">
							<label>Color texto mouse arriba</label>
							<input type="color" name="btn_secondary_text_color_hover" class="form-control" [(ngModel)]="preferences.btn_secondary_text_color_hover">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de fondo
								<input type="checkbox" (click)="updateNone('btn_secondary_bg_color',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_secondary_bg_color=='none'">
							</label>
						</div>

						<div class="col-6 form-group" *ngIf="preferences.btn_secondary_bg_color != 'none'">
							<label>Color fondo</label>
							<input type="color" name="btn_secondary_bg_color" class="form-control" [(ngModel)]="preferences.btn_secondary_bg_color">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de fondo mouse arriba
								<input type="checkbox" (click)="updateNone('btn_secondary_bg_color_hover',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_secondary_bg_color_hover=='none'">
							</label>
						</div>

						<div class="col-6 form-group" *ngIf="preferences.btn_secondary_bg_color_hover != 'none'">
							<label>Color fondo mouse arriba</label>
							<input type="color" name="btn_secondary_bg_color_hover" class="form-control" [(ngModel)]="preferences.btn_secondary_bg_color_hover">
						</div>
					</div>

					<div class="row">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de borde
								<input type="checkbox" name="btn_secondary_border_color" (click)="updateNone('btn_secondary_border_color',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_secondary_border_color=='none'">
							</label>
						</div>
						<div class="col-6 form-group" *ngIf="preferences.btn_secondary_border_color != 'none'">
							<label>Color de borde</label>
							<input type="color" name="btn_secondary_border_color" class="form-control" [(ngModel)]="preferences.btn_secondary_border_color">
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-6 form-check form-switch">
							<label class="form-check-label">
								Sin Color de borde mouse arriba
								<input type="checkbox" name="btn_secondary_border_color_hover" (click)="updateNone('btn_secondary_border_color_hover',$event,'#8f6653')" class="form-check-input" [checked]="preferences.btn_secondary_border_color_hover=='none'">
							</label>
						</div>
						<div class="col-6 form-group" *ngIf="preferences.btn_secondary_border_color_hover != 'none'">
							<label>Color fondo borde mouse arriba</label>
							<input type="color" name="btn_secondary_border_color_hover" class="form-control" [(ngModel)]="preferences.btn_secondary_border_color_hover">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-4 mb-3">
				<h4 class="my-3">Punto de Venta</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 col-md-6 form-group">
							<label>Tipo de Interfaz</label>
							<select name="default_input_type" [(ngModel)]="preferences.default_input_type" class="form-control" required>
								<option value="TACTILE">Touch</option>
								<option value="KEYBOARD">Teclado</option>
							</select>
						</div>

						<div class="col-12 col-md-6 form-group">
							<label>Cantidad de Categorias en touch</label>
							<select name="pv_show_all_categories" [(ngModel)]="preferences.pv_show_all_categories" class="form-control" required>
								<option value="NO">Solo Seleccionadas</option>
								<option value="YES">Todas</option>
							</select>
						</div>
						<div class="col-12 col-md-6 form-group">
							<label>Ticket de Corte</label>
							<select name="default_cash_close_receipt" [(ngModel)]="preferences.default_cash_close_receipt" class="form-control" required>
								<option value="1">Detalle Ventas + totales</option>
								<option value="2">Solo Totales</option>
								<option value="3">Detalle Ventas + totales 57mm</option>
							</select>
						</div>

						<div class="col-12 col-md-6 form-group">
							<label>Ticket</label>
							<select name="default_print_receipt" [(ngModel)]="preferences.default_print_receipt" class="form-control" required>
								<option value="1">Normal</option>
								<option value="2">Compacto</option>
								<option value="3">Compacto(flotante)</option>
								<option value="4">Con Total,Porcentaje y descuento total</option>
								<option value="5">Simple 57mm</option>
								<option value="6">Normal 80mm</option>
								<option value="7">Normal Redirección</option>
							</select>
						</div>
						<div class="col-12 col-md-6 form-group">
							<label>Nombre Artículo</label>
							<select name="display_categories_on_items" [(ngModel)]="preferences.display_categories_on_items" class="form-control" required>
								<option value="YES">Categoria + Artículo</option>
								<option value="NO">Solo Artículo</option>
							</select>
						</div>
						<div class="col-12 col-md-6 form-group">
							<label>Mostrar Ordenes Abiertas</label>
							<select name="pv_show_orders" [(ngModel)]="preferences.pv_show_orders" class="form-control" required>
								<option value="OPEN_SAME_DAY">Ordenes abiertas Mismo Dia</option>
								<option value="ALL_OPEN">Todas las Ordenes Abiertas</option>
							</select>
						</div>
						<div class="col-6 form-group">
							<label>Color fondo barra</label>
							<input type="color" name="pv_bar_background_color" class="form-control" [(ngModel)]="preferences.pv_bar_background_color">
						</div>
						<div class="col-6 form-group">
							<label>Color texto</label>
							<input type="color" name="pv_bar_text_color" class="form-control" [(ngModel)]="preferences.pv_bar_text_color">
						</div>
						<div class="col-6 form-group">
							<label>Color texto total</label>
							<input type="color" name="pv_bar_total_color" class="form-control" [(ngModel)]="preferences.pv_bar_total_color">
						</div>
						<div class="col-6 form-group">
							<label>Color de fondo de selección</label>
							<input type="color" name="item_selected_background_color" class="form-control" [(ngModel)]="preferences.item_selected_background_color">
						</div>
						<div class="col-6 form-group">
							<label>Color texto de selección</label>
							<input type="color" name="item_selected_text_color" class="form-control" [(ngModel)]="preferences.item_selected_text_color">
						</div>
						<!--div class="col-6 form-group">
							<label>Touch Tamaño artículos</label>
							<select name="touch_size_button" class="form-control" [(ngModel)]="preferences.touch_size_button">
								<option value="50px">Mediano</option>
								<option value="80px">Mediano</option>
								<option value="100px">Grande</option>
							</select>
						</div-->
						<!--div class="col-6 form-group">
							<label>Touch Color Texto Artículos</label>
							<input type="color" name="touch_text_color" class="form-control" [(ngModel)]="preferences.touch_text_color">
						</div-->

						<!--div class="col-6 form-group">
							<label>Touche Texto Sombra</label>
							<input type="color" name="touch_text_shadow_color" class="form-control" [(ngModel)]="preferences.touch_text_shadow_color">
						</div-->
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-4 mb-3">
				<h4 class="my-3">Sistema</h4>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 form-group">
							<label>Disponibilidad por defecto nuevo artículo</label>
							<select name="default_pos_availability_type" [(ngModel)]="preferences.default_pos_availability_type" class="form-control" required>
								<option value="ALWAYS">Siempre</option>
								<option value="ON_STOCK">En Inventario</option>
							</select>
						</div>
						<div class="col-12 form-group">
							<label>Acción a realizar en devoluciones</label>
							<select name="default_return_action" [(ngModel)]="preferences.default_return_action" class="form-control" required>
								<option value="RETURN_TO_STOCK">Devolver a inventario</option>
								<option value="ADD_TO_MERMA">Enviar a merma</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card p3 mb-3">
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit" [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
