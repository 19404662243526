<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3">Reporte máximos y mínimos</h1>
		</div>
		<div class="col-2 text-end">
			<button type="submit" class="btn btn-primary" form="form-save">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21 7v12q0 .825-.588 1.413T19 21H5q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h12l4 4Zm-9 11q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-6-8h9V6H6v4Z"/></svg>
				<span class="d-none d-md-inline">&nbsp;Guardar</span>
			</button>
		</div>
	</div>
	<form name="search_stock_maxmin" (submit)="doASearch($event)">
		<div class="card p-3 my-3">
			<div class="row">
				<div class="col-8">
					<label>&nbsp;</label>
					<input name="search_str" class="form-control" [(ngModel)]="search_str" type="text">
				</div>
				<div class="col-2">
					<label>Sucursal</label>
					<select name="store_id" [(ngModel)]="store_id" *ngIf="rest.current_permission.global_add_stock" class="form-control">
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name}}</option>
					</select>
					<div *ngIf="rest.current_permission.global_add_stock">{{store_name}}</div>
				</div>
				<div class="col-2">
					<label>&nbsp;</label>
					<div>
						<button type="submit" [disabled]="is_loading" class="btn btn-primary w-100">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14z"/></svg>
							<span class="d-none d-md-inline">Buscar</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="card p-3">
		<form id="form-save" (submit)="saveAlerts($event)">
			<div class="d-none d-md-flex row align-items-center border-bottom py-1">
				<div class="col-md-2">Artículo</div>
				<div class="col-md-2">Inventario</div>
				<div class="col-md-2">Min</div>
				<div class="col-md-2">Max</div>
				<div class="col-md-2">Mínimo</div>
				<div class="col-md-2">Máximo</div>
			</div>
			<div *ngFor="let sa of stock_alert_list" class="row align-items-center border-bottom py-1">
				<div class="col-6 col-md-2">{{sa.item.name}} {{sa?.category?.name}}</div>
				<div class="col-6 col-md-2">
					<span class="text-success" *ngIf="sa.item.minmax_diff<=0">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"/></svg>
					</span>
					<span class="text-danger" *ngIf="sa.item.minmax_diff>0">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z"/></svg>
					</span>
					<label class="d-inline d-md-none">Inventario</label>
					{{sa.stock_record.qty | number}}
				</div>
				<div class="d-none d-md-block col-2">{{sa?.stock_alert?.min || 'N/A'}}</div>
				<div class="d-none d-md-block col-2">{{sa?.stock_alert?.max || 'N/A'}}</div>
				<div class="col-6 col-md-2">
					<label class="d-block d-md-none">Mínimo</label>
					<input type="number" [step]="sa.item.partial_sale == 'NO'?'1':'any'" [name]="'min-alert'+sa.item.id" [(ngModel)]="sa.min_new_qty"  class="form-control" [min]="0">
				</div>
				<div class="col-6 col-md-2">
					<label class="d-block d-md-none">Máximo</label>
					<input type="number" [step]="sa.item.partial_sale == 'NO'?'1':'any'" [name]="'max-alert'+sa.item.id" [(ngModel)]="sa.max_new_qty"  class="form-control" [min]="0">
				</div>
				<!--div class="col-12">
					{{sa.item.min_diff}},{{sa.item.max_diff}},{{sa.item.minmax_diff}}
				</div-->
			</div>
			<div class="pt-1 text-end">
				<button type="submit" class="btn btn-primary">Guardar</button>
			</div>
		</form>
	</div>
</div>
