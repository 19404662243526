import { Component, OnInit } from '@angular/core';
import { Subject, of, forkJoin } from 'rxjs';
import { debounceTime, mergeMap} from 'rxjs/operators';
import {ItemInfo, StockRecordInfo} from 'src/app/models/models';
import { Item, Stock_Record, Store} from 'src/app/models/RestModels';
import { SearchObject} from 'src/app/services/Rest';
import { BaseComponent} from '../base/base.component';
import { Utils} from 'src/app/classes/Utils';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';

interface CustomItemInfo extends ItemInfo
{
	on_stock:number;
	current:number;
}

interface ReportData
{
	id: number;
	Categoría: string | undefined;
	Artículo: string,
	Sucursal: string,
	'Cantidad Inicial': number,
	'Ajuste': number,
	Diferencia: number;
}

declare function txt2html(str:string):string;
declare function printHtml(html_body:string,title:string):any;

@Component({
	selector: 'app-save-stock-record',
	templateUrl: './save-stock-record.component.html',
	styleUrls: ['./save-stock-record.component.css']
})

export class SaveStockRecordComponent extends BaseComponent implements OnInit
{
	store_list:Store[] = [];
	item_info_list:CustomItemInfo[] = [];
	store_id:number = 0;
	item_name:string = '';
	is_saving:boolean = false;
	report_store_id:number;
	search_subject = new Subject<string>();
	item_search:SearchObject<Item> = this.getEmptySearch();
	show_modal_report: boolean = false;
	start_time: string = '';
	end_time: string = '';
	last_search:string = '';

	ngOnInit(): void
	{
		this.path = '/edit-stock-record'
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let fields= ['availability_type'];
				let extra_keys = ['category_name'];

				this.item_search = this.getSearch(response[0],fields,extra_keys);
				this.item_search.eq.has_serial_number = 'NO';
				console.log('Eq has ',this.item_search.eq );

				if( !this.item_search.eq.availability_type )
					this.item_search.eq.availability_type = undefined;

				return forkJoin
				({
					stores: this.rest.getStores()
				})
			})
		)
		.subscribe((response)=>
		{
			if( this.store_list.length == 0 )
				this.store_list = response.stores.data;

			if( !this.store_id || !this.rest.current_permission.global_add_stock )
			{
				this.report_store_id = this.rest.current_user.store_id;
				this.store_id = this.rest.current_user.store_id;
			}
			this.updateItemInfoList(this.item_info_list);
			this.search_subject.next(''+(this.item_search.search_extra.category_name || ''));
		},(error)=>{this.showError(error)});


		this.subs.sink = this.search_subject.pipe
		(
			debounceTime(250),
			mergeMap((search:string) =>
			{
				//return this.search(search);
				this.last_search = search;
				this.item_search.search_extra.category_name = this.last_search;
				return this.rest.item_info.search( this.item_search );
			})
		)
		.subscribe((response)=>
		{
			let l = response.data.map((i)=>{
				return {...i,on_stock:0,current:0}
			});

			this.setPages( this.item_search.page, response.total );
			this.updateItemInfoList(l);
			this.item_info_list = l;
		},(error)=>
		{
			this.showError( error );
		});
	}

	onAvailabilityChange(value:undefined | 'ON_STOCK' | 'ALWAYS')
	{
		this.item_search.eq.availability_type = value;
		this.search_subject.next( this.last_search );
	}


	onSearch(evt:any)
	{
		let search:string = evt.target.value.trim();

		if( !search )
		{
			//this.item_list.splice(0,this.item_list.length);
			return;
		}

		this.search_subject.next(search);

		this.item_search.search_extra.category_name = search;
		this.searchNoForceReload( this.item_search );

		/*
		//this.subs.sink = this.rest.item_info.search({ limit: 20,eq:{status:'ACTIVE'} },{category_name:search, category_type: this.type}).subscribe((response)=>{
		this.subs.sink = this.rest.item_info
		.search({ eq:{ on_sale:'YES',status:'ACTIVE'},start:{code:search},limit: 20,search_extra: { category_name:search}})
		.subscribe((response)=>
		{
			this.updatePrices(response.data);
			this.item_list = response.data;
			this.order_item_search_index = 0;
			this.item_search_index = 0;
			this.active_list = 'search_list';
		},(error)=>
		{
			console.log(error);
		});
		*/
	}

	updateItemInfoList(item_info_list:CustomItemInfo[])
	{
		item_info_list.forEach((ii:CustomItemInfo)=>
		{
			let stock_record:Stock_Record | undefined = ii.records.find((stock_record:Stock_Record)=>
			{
				return stock_record.store_id == this.store_id;
			});

			if( stock_record == undefined )
			{
				stock_record = {
					created: new Date(),
					created_by_user_id: this.rest.current_user.id,
					description: '',
					id: 0,
					is_current:null,
					item_id: ii.item.id,
					movement_qty: 0,
					movement_type:'ADJUSTMENT',
					order_item_id:null,
					previous_qty:0,
					production_item_id:null,
					purchase_detail_id:null,
					qty:0,
					serial_number_record_id:null,
					shipping_item_id: null,
					store_id: this.store_id,
					updated: new Date(),
					updated_by_user_id: this.rest.current_user.id,

				};
			}

			ii.stock_record = stock_record;
			ii.on_stock = ii.stock_record.qty;
			ii.current = ii.on_stock;
		});
	}

	updateStock(item_info:ItemInfo)
	{
		this.subs.sink = this.rest.adjustStock([item_info.stock_record])
		.subscribe((_response)=>
		{
			this.showSuccess('El inventario se ajustó exitosamente');
		},(error)=>this.showError(error));
	}

	searchTerm()
	{
		this.search({search_extra: {category_name: this.item_name}} as unknown as SearchObject<any>);
	}

	cambioStockRecord(store_id:number)
	{
		//this.item_search.eq.store_id = store_id;
		this.store_id = store_id;
		this.report_store_id = store_id;
		this.searchNoForceReload( this.item_search );
		this.search_subject.next(''+(this.item_search.search_extra.category_name || ''));
		//this.search( this.item_search );
		//this.updateItemInfoList(this.item_info_list);
	}

	saveAll(_evt:Event)
	{
		this.is_saving = true;
		this.is_loading = true;

		let tmp = this.item_info_list
					.filter(i=>i.current != i.stock_record.qty )
					.map(i=>i.stock_record);

		this.subs.sink = this.rest.adjustStock( tmp )
		.subscribe((_response)=>
		{
			this.is_saving = false;
			this.is_loading = false;
			for(let csr of this.item_info_list)
			{
				csr.current = csr.stock_record.qty;
			}

			this.showSuccess('Se actualizaron los inventarios');
		},(error)=>
		{
			this.is_saving = false;
			this.is_loading = false;
			this.showError(error);
		});
	}

	showModalReport()
	{
		let end = new Date();
		end.setMinutes( end.getMinutes()+61 );
		end.setMinutes( 0 );
		end.setSeconds(0);
		end.setMilliseconds( 0 );

		let start	= new Date();
		start.setHours(end.getHours()-1, 0,0,0);

		this.start_time = Utils.getMysqlStringFromLocalDate( start ).replace(' ','T');
		this.end_time = Utils.getMysqlStringFromLocalDate( end ).replace(' ','T');;
		this.show_modal_report = true;
	}

	searchReportData()
	{
		let start = Utils.getLocalDateFromMysqlString( this.start_time );
		let end = Utils.getLocalDateFromMysqlString( this.end_time );


		let ss = this.getEmptySearch<Stock_Record>();
		ss.ge.created = start;
		ss.le.created = end;
		ss.limit = 999999;
		ss.eq.movement_type = 'ADJUSTMENT';
		ss.sort_order = ['id_ASC'];
		ss.eq.store_id = this.report_store_id;

		return this.rest.stock_record_info.search( ss )
		.pipe
		(
			mergeMap((response)=>
			{
				let	m = new Map<number,StockRecordInfo>();

				for(let i of response.data)
				{
					if( i.stock_record.qty == i.stock_record.previous_qty )
							continue;

					if( m.has( i.stock_record.item_id	) )
					{
						let sri = m.get( i.item.id );
						sri.stock_record.qty = i.stock_record.qty;
					}
					else
					{
						m.set(i.item.id, i );
					}
				}

				let a:Array<StockRecordInfo> = Array.from( m.values());

				a.sort((a,b)=>{
					return a.stock_record.id > b.stock_record.id ? 1 : -1;
				});


				let rows:ReportData[] = a.map(i=> {
					return {
						id: i.stock_record.id,
						Categoría: i?.category?.name,
						Artículo: i.item.name,
						Sucursal: i.store.name,
						'Cantidad Inicial': i.stock_record.previous_qty,
						'Ajuste': i.stock_record.qty,
						Diferencia: i.stock_record.qty - i.stock_record.previous_qty
					}
				});
				return of( rows );
			})
		)
	}

	createReport(evt:any)
	{
		this.subs.sink = this
			.searchReportData()
			.subscribe
			(
				(response)=>
				{
					if( evt.submitter.value == 'export' )
					{
						let str = 'ReporteAjustes'+Utils.getMysqlStringFromLocalDate( new Date() ).substring(0,10)+'.xlsx';
						let headers:string[] = [
							'Categoría','Artículo', 'Sucursal',
							'Cantidad Inicial', 'Ajuste', 'Diferencia'
						];
						ExcelUtils.array2xlsx( response, str, headers );
					}
					else
					{
						this.printReport( response,evt );
					}
				},
				(error)=>
				{
					this.showError(error)
				}
			);
	}

	round(n:number):string | number
	{
		if( (n %1) != 0 )
			return ''+Math.round(n*100)/100;
		return n;
	}

	printReport(data:ReportData[], evt:Event)
	{
		//Just to exist do not remove the next log, is to not trigger the garbage collector yet
		console.log('Printint event', evt);
		//Just to exist do not remove the previous log, is to not trigger the garbage collector yet

		let store = this.store_list.find(i=>this.report_store_id == i.id);
		let store_name = txt2html( store.name );

		let start = Utils.getLocalDateFromMysqlString( this.start_time );
		let end = Utils.getLocalDateFromMysqlString( this.end_time );

		let html = `
			<style>${store.printer_ticket_config}</style>
			<div class="print_ticket_container">
				<h1>Ajuste de inventario</h1>
				<div>
					Sucursal:<b>${store_name}</b>
				</div>
				<div>
					Fecha Inicio:<b>${Utils.getDateString(start, true)}</b>
				</div>
				<div>
					Fecha Fin:<b>${Utils.getDateString(end, true)}</b>
				</div>
				<table style="width:100%">
					<thead>
						<tr>
							<th style="text-align:left;">Articulo</th>
							<th style="text-align:right;">Inicio</th>
							<th style="text-align:right;">Cant</th>
							<th style="text-align:right;">Dif</th>
						</tr>
					</thead>
					<tbody>
		`;

		for(let rd of data)
		{
			let cname = '';//data['Categoría']

			if( rd['Categoría'] && this.rest.local_preferences.display_categories_on_items =='YES')
				cname = rd['Categoría']+' - ';

			let ciname = txt2html( cname+' '+rd['Artículo'] );

			html += `
				<tr>
					<td style="text-align:left;">${ciname}</td>
					<td style="text-align:right;">${this.round( rd['Cantidad Inicial'])}</td>
					<td style="text-align:right;">${this.round( rd['Ajuste'])}</td>
					<td style="text-align:right;">${this.round( rd['Diferencia'])}</td>
				</tr>`
		}

		html += '</tbody></table></div>';

		console.log( 'html is ', html);

		printHtml( html, 'AjusteInventario' );
	}
}
