<div class="container-fluid">
	<h1 class="my-3">{{form.id ? 'Editar' : 'Agregar'}} Formulario</h1>
	<form (submit)="save($event)" ngNativeValidate>
		<div class="card p-3 my-3">
			<div class="row">
				<div class="col-12 col-md-6">
					<label>Titulo</label>
					<input type="text" name="title" class="form-control" [(ngModel)]="form.title">
				</div>
				<div class="col-12 col-md-6">
					<label>Titulo para respuesta obligatorio</label>
					<select name="category" [(ngModel)]="form.is_response_title_required" class="form-control">
						<option value="0">No</option>
						<option value="1">Si</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row mx-0 align-items-center">
			<div class="col-8 col-lg-8">
				<h2>Preguntas</h2>
			</div>
			<div class="col-4 col-lg-4">
				<div class="row align-items-center">
					<div class="col-6 form-check form-switch mt-2">
						<label class="form-chech-label" for="mark_all_required">
							Marcar como obligatorias
						</label>
						<input (click)="markAllRequired($event)" [checked]="someChecked()" class="form-check-input" type="checkbox" role="switch" id="mark_all_required">
					</div>
					<div class="col-6">
						<button type="button" (click)="addQuestion()" class="btn btn-primary w-100">
							<svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"/></svg>
							<span class="d-none d-md-inline">Agregar</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="card p-3 my-3">
			<ng-container *ngFor="let question of question_list;let i = index">
				<div *ngIf="question.status == 'ACTIVE'" class="row border-bottom border-4 pb-3" >
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>Pregunta</label>
							<input name="question" [name]="'question'+question.cid" class="form-control" [(ngModel)]="question.question" type="text" required>
						</div>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label>Tipo</label>
									<select name="type" [name]="'type'+question.cid" [(ngModel)]="question.type" class="form-control">
										<option value="text">Texto corto</option>
										<option value="textarea">Texto Largo</option>
										<!-- <option value="multiple_choice">Opcion Multiple</option> -->
										<!-- <option value="rating">Calificación</option> -->
										<option value="date">Fecha</option>
										<option value="number">Numero</option>
										<option value="tel">Teléfono</option>
									</select>
								</div>
							</div>
							<div class="col-3">
								<div class="form-group">
									<label>Prioridad</label>
									<input name="priority" type="number" step="1" min="1" [name]="'priority'+question.cid" class="form-control" [(ngModel)]="question.priority" type="text" required>
								</div>	
							</div>
							<div class="col-3 form-check form-switch text-center">
								<label class="form-check-label mt-4" [for]="'required_switch'+question.cid">
									<input (click)="toggleRequired(question)" class="form-check-input" type="checkbox" role="switch" [id]="'required_switch'+question.cid" [checked]="question.required ==1">
									Obligatorio
								</label>
							</div>	
						</div>
					</div>
					<div class="col-12 col-md-6">
						<label>Descripción</label>
						<div class="row">
							<div class="col-11">
								<textarea name="help" rows=2 [name]="'help'+question.cid" class="form-control" [(ngModel)]="question.help"></textarea>
							</div>
							<div class="col-1">
								<button type="button" class="btn btn-danger py-1 px-2" (click)="removeQuestion(question)">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M1.707.293A1 1 0 0 0 .293 1.707L5.586 7L.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414-1.414L8.414 7l5.293-5.293A1 1 0 0 0 12.293.293L7 5.586z" clip-rule="evenodd"/></svg>
								</button>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="row">
				<div class="col-12 text-end mt-3">
					<button type="submit" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
