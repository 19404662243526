<div class="fluid-container px-1 px-lg-3">
	<h1 class="my-3">Lista de ordenes</h1>
	<div class="px-1 px-lg-3 py-3 d-flex align-items-evenly justify-content-center flex-wrap position relative" style="padding-bottom:60px">
		<div *ngFor="let oi of order_info_list" class="order card p-1" [ngClass]="[min_preparation_status[oi.order.id]]">
			<div class="last-button">
				<button type="button"
		 			class="btn btn-primary w-100 mt-3"
					*ngIf="min_preparation_status[oi.order.id] == 'READY'"
					(click)="confirmMarkItemsAsDelivered(oi)">
					Entregar
				</button>
			</div>

			<a *ngIf="oi.order.status == 'PENDING' || oi.order.status=='ACTIVE'" [routerLink]="['/pos',oi.order.id]"
				class="pb-3 d-block fw-bold order_name"
				[ngClass]="[oi.order.delivery_status]">
				#{{oi.order.id}} - {{oi.order?.client_name | titlecase}}
			</a>
			<a *ngIf="!(oi.order.status == 'PENDING' || oi.order.status=='ACTIVE')" [routerLink]="['/view-order',oi.order.id]"
				class="pb-3 d-block fw-bold order_name"
				[ngClass]="{'PENDING':oi.order.delivery_status}">
				#{{oi.order.id}} - {{oi.order?.client_name | titlecase}}
			</a>

			<div *ngFor="let oii of oi.items;let i =index" [ngClass]="['order_item',oii.order_item.preparation_status]">
				<div class="row align-items-center w-100" [ngClass]="{'border-top':!oii.order_item.item_option_id && i>0}">
					<div class="col-10">
						<span *ngIf="oii.category && rest.local_preferences.display_categories_on_items == 'YES'">{{oii.category.name}} - </span>
						{{oii.item.name}}
						<span class="float-end">{{oii.order_item.qty}}</span>
					</div>
					<div class="col-1">
						<span *ngIf="oii.order_item.preparation_status == 'READY'">
							<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093l3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>
						</span>
						<span *ngIf="oii.order_item.preparation_status == 'DELIVERED' || !(oii.order_item.commanda_id)">
							<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486l-.943 1.179z"/></svg>
						</span>
					</div>
				</div>
				<div *ngFor="let e of oii.exceptions" class="p-3"><pre>{{oii.order_item.exceptions}}</pre></div>
			</div>
		</div>
	</div>
</div>
