import { Component } from '@angular/core';
import { ParamMap } from '@angular/router';
import { mergeMap, window } from 'rxjs/operators';
import { Order } from 'src/app/models/RestModels';
import { OrderInfo, OrderItemInfo } from 'src/app/models/models';
import { BaseComponent } from '../base/base.component';
import { GetEmpty } from 'src/app/classes/Empties';

/*
* WARNING: es para impresion de comandas solamente veas imprimir-ticke
* para otros usos
* Este documento solo sirve para imprimir la comanda,
* XXX NO VALE PARA PRECIOS CANTIDADES, NI PARA IMPRIMIR EL TICKET DE UNA ORDEN
* la información que imprime puede que no sea la misma a la orden final
* y pudiera multiples impresiones cada una con informacion diferente.
*/

type CommandaPrintType = 'ALL_ITEMS' | 'ALL_COMMANDA_ITEMS' | 'COMMANDA_DIFFERENCE';

@Component({
	selector: 'app-print-commanda',
	templateUrl: './print-commanda.component.html',
	styleUrls: ['./print-commanda.component.css']
})

export class PrintCommandaComponent extends BaseComponent
{

	order_info:OrderInfo = this.getEmptyOrderInfo();
	print_type:CommandaPrintType = 'COMMANDA_DIFFERENCE';

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((params:ParamMap) =>
			{
				this.print_type = params.get('comanda_print_type') as CommandaPrintType;
				return this.rest.order_info.get(params.get('id'));
			})
		)
		.subscribe( order_info =>
		{
			this.order_info = order_info;
			setTimeout(() => this.print(), 800);

		},(error)=>
		{
			this.showError(error);
		});
	}

	ngAfterViewInit(): void
	{

	}

	ngOnDestroy(): void
	{

	}

	print()
	{
		//window.print();
	}

	getEmptyOrderInfo():OrderInfo
	{
		let store_id = this.rest.current_user.store_id;
		let empty= GetEmpty.orderInfo(this.rest);
		empty.cashier = this.rest.current_user;
		empty.store = GetEmpty.store();
		empty.order.store_id = store_id;

		return empty;
	}
}
