<div class="container-fluid">
	<div class="row">
		<div class="col-10"	>
			<h1 class="my-3">Cortes de Sucursal</h1>
		</div>
		<div class="col-2 text-end">
			<a routerLink="/create-sales-report" class="btn btn-secondary my-3">Agregar</a>
		</div>
	</div>

	<div class="card p-3">
		<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
		<table class="">
			<thead>
				<tr class="border-bottom">
					<th>Id</th>
					<th>Sucursal</th>
					<th>Fecha</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let ssr of sales_store_report_list">
					<td>{{ssr.id}}</td>
					<td>
						<a [routerLink]="['/view-sales-report',ssr.id]">{{ssr.store.name}}</a>
					</td>
					<td>{{ssr.date}}</td>
				</tr>
			</tbody>
		</table>
		<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	</div>
</div>
