<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3">Reporte ventas por categoría</h1>
		</div>
		<div class="col-2 d-print-none">
		<button type="button" class="btn btn-secondary w-100" (click)="print()">
			<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 7H6V3h12Zm0 5.5q.425 0 .712-.288q.288-.287.288-.712t-.288-.713Q18.425 10.5 18 10.5t-.712.287Q17 11.075 17 11.5t.288.712q.287.288.712.288ZM16 19v-4H8v4Zm2 2H6v-4H2v-6q0-1.275.875-2.137Q3.75 8 5 8h14q1.275 0 2.138.863Q22 9.725 22 11v6h-4Z"/></svg>
			<span class="d-none d-lg-inline">Imprimir</span>
		</button>
		</div>
	</div>
	<div class="card px-lg-3 px-1 py-3">
		<div class="row">
			<div class="col-6 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.ge,'created')" class="form-control d-print-none">
				<div class="d-none d-print-block font-weight-bold">{{startx|date}}</div>
			</div>
			<div class="col-6 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx"	(ngModelChange)="onDateChange($event,report_search.le,'created')"class="form-control d-print-none">
				<div class="d-none d-print-block font-weight-bold">{{endx|date}}</div>
			</div>
			<div class="col-6 form-group d-print-none">
				<label>Sucursal</label>
				<select [(ngModel)]="report_search.search_extra.store_id" class="form-control d-print-none">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id" [selected]="report_search.search_extra.store_id==store.id">{{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 form-group d-print-none">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary" (click)="searchReport()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card my-3 py-3 px-1 px-lg-3">
		<div class="row fw-bold">
			<div class="col-3" (click)="sortByCategory()">Categoría</div>
			<div class="col-3">Sucursal</div>
			<div class="col-3 text-end" (click)="sortByQty()">Total Vendidos</div>
			<div class="col-3 text-end" (click)="sortByTotal()">Total</div>
		</div>
		<div class="row" *ngFor="let r of report_list">
			<div class="col-3">
				{{r.category_name}}
			</div>
      <div class="col-3">
				{{r.store_name}}
      </div>
			<div class="col-3 text-end">
				{{r.order_item_qty | number}}
			</div>
			<div class="col-3 text-end">
				{{r.order_item_total | currency}}
			</div>
		</div>
		<div class="row fw-bold">
			<div class="col-3">
        Todos
			</div>
			<div class="col-3 text-end">
      </div>
			<div class="col-3 text-end">
				{{total_qty | number}}
			</div>
			<div class="col-3 text-end">
				{{total | currency}}
			</div>
		</div>

	</div>
</div>
