import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/classes/Utils';
import {FacturacionRequest} from 'src/app/models/models';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-facturar',
	templateUrl: './client-facturar.component.html',
	styleUrls: ['./client-facturar.component.css']
})

export class ClientFacturarComponent extends BaseComponent implements OnInit
{
	facturacion_request:FacturacionRequest = this.getEmptyFacturacionRequest();
	version:number = 3;
	show_domicilio_fiscal:boolean = false;
	show_codigo_ticket:boolean = false;
	show_rfc:boolean	= false;
	show_razon_social:boolean = false;
	show_regimen_fiscal:boolean = false;
	code:string = '';
	error_string: string = '';

	ngOnInit(): void
	{
		this.facturacion_request = this.getEmptyFacturacionRequest();
		this.subs.sink = this.route.paramMap.subscribe(()=>
		{
			this.facturacion_request = this.getEmptyFacturacionRequest();
		});
	}

	getEmptyFacturacionRequest(): FacturacionRequest
	{
		return {
			facturacion_code: '',
			razon_social:'',
			email:'',
			rfc:'',
			domicilio_fiscal:'',
			regimen_fiscal:'',
			regimen_capital: '',
			uso_cfdi:'',
		note:'',
			version: '4.0'
		};
	}

	save(evt:Event)
	{
		this.is_loading = true;
		this.showWarning('Facturando por favor espere un momento');
		this.error_string = '';

		this.subs.sink = this.rest.facturacion_request
		.create( this.facturacion_request )
		.subscribe
		(
			(response:any)=>
			{
				this.showSuccess("Se facturó exitosamente, por favor revisar su correo electrónico");
				if( response?.sat_xml_attachment_id && response?.sat_pdf_attachment_id )
				{
					this.code = this.facturacion_request.facturacion_code;
				}
				this.error_string = '';
			}
			,(error:any)=>
			{
				this.error_string = Utils.getErrorString( error );
				this.showError(error)
			}
		);
	}
}
