<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{commanda.id ? 'Actualizar':'Agregar'}} comanda</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-12 col-md-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="commanda.name" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">Tipo de comanda</label>
					<select name="commanda_type" [(ngModel)]="commanda.commanda_type_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let ct of commanda_type_list" [ngValue]="ct.id">
							{{ct.name | titlecase}}
						</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Preferencias de Impresión</label>
					<select name="print_preferences" [(ngModel)]="commanda.print_preferences" class="form-control">
						<option value="ONLY_DISPLAY">No Imprimir</option>
						<option value="PRINT_ONLY_NEW_ITEMS">Artículos nuevos de comanda</option>
						<option value="PRINT_PARTIAL">Solo artículos de comanda</option>
						<option value="FULL_PRINT">Todos los artículos</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Preferencias Pantalla</label>
					<select name="order_display_preferences" [(ngModel)]="commanda.order_display_preferences" class="form-control">
						<option value="COMMANDA_TYPE_ORDERS">Solo ordenes de comanda</option>
						<option value="ALL_ORDERS">Todas las ordenes</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Sucursal</label>
					<select name="store_id" [(ngModel)]="commanda.store_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let store of store_list" [ngValue]="store.id">{{store.name | titlecase}}</option>
					</select>
				</div>

			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
