<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col-12 col-sm-6 col-lg-6 px-1">
			<h1 class="text-capitalize" *ngIf="user">Ordenes del Cliente: {{user.name}}</h1>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
			<b>Total</b>:<br>
			<span>{{order_total |number: '1.2-2'}} {{payment_currency_id}}</span>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
			<b>Pendiente</b>:<br>
			<span>{{pending |number: '1.2-2'}} {{payment_currency_id}}</span>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
				<button type="button" class="btn btn-secondary" (click)="paySelected($event)" *ngIf="selected_pending>0">Pagar {{selected_pending|number:'1.2-2'}} {{payment_currency_id}}</button>
		</div>
	</div>
	<div  class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive d-none d-lg-block">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<td></td>
					</tr>
					<tr>
						<th>Id</th>
						<th>Cliente</th>
						<th class="d-none d-xxl-table text-center">Sucursal</th>
						<th>Atendió</th>
						<th class="text-center">Tipo</th>
						<th>Fecha</th>
						<th class="text-center">Estatus</th>
						<th class="text-end">Total</th>
						<th class="text-end">Pendiente</th>
						<th class="text-end float-end">
							<div class="form-check form-switch text-end">
								<input name="toggle_all" class="form-check-input" type="checkbox" id="to" [(ngModel)]="toggle_all_status" (ngModelChange)="onToggleAll($event)">
							</div>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="price_type_list.length">
					<tr *ngFor="let oi of order_info_list" class="align-middle">
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<div>
								<a *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING' )" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="!oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.sync_id,'offline']">{{oi.order.sync_id}}</a>
							</div>
						</td>
						<td>
							<div>
								<span *ngIf="rest.is_offline && oi.order.status == 'CLOSED'">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</span>
								<a *ngIf="oi.order.id && (oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="!oi.order.id && (oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos/offline',oi.order.sync_id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="!rest.is_offline && (oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
							</div>
							<div class="d-xxl-none">{{oi.store.name}}</div>
						</td>
						<td class="d-none d-xxl-table-cell">{{oi.store.name + 'S'| titlecase }}</td>
						<td>{{oi?.cashier?.name | titlecase }}</td>
						<td>
							<span *ngIf="oi.order.service_type == 'TOGO'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 4a2 2 0 0 0-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4h-3V4m-7 2l4 4l-4 4v-3H4V9h6m7 .5h2.5l1.97 2.5H17M6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'PICK_UP'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z"/></g></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'IN_PLACE'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z" fill="currentColor"/></svg>
							</span>
							{{price_type_dic[ oi?.order.price_type_id ].name | titlecase}}
						</td>
						<td>
							<abbr title="{{oi.order.created | date: 'MMM d, y HH:mm' }}" *ngIf="oi.show_date">{{oi.order.created | date: 'MMM d'}}</abbr>
							<abbr title="{{oi.order.created | date: 'MMM d, y HH:mm' }}" *ngIf="!oi.show_date">{{oi.order.created | date: ' HH:mma'}}</abbr>
						</td>
						<td class="text-center">
							<span [ngClass]="{'text-danger':oi.order.paid_status!='PENDING','text-warning':oi.order.paid_status == 'PENDING'}" *ngIf="oi.order.status == 'CANCELLED'">
								Cancelada
							</span>
							<ng-container *ngIf="oi.order.status != 'CANCELLED'">
								<span *ngIf="oi.order.status == 'PENDING' && oi.order.delivery_status == 'PENDING' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M28 30H6V8h13.22l2-2H6a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V15l-2 2z" class="clr-i-outline clr-i-outline-path-1" fill="currentColor"/><path d="M33.53 5.84l-3.37-3.37a1.61 1.61 0 0 0-2.28 0L14.17 16.26l-1.11 4.81A1.61 1.61 0 0 0 14.63 23a1.69 1.69 0 0 0 .37 0l4.85-1.07L33.53 8.12a1.61 1.61 0 0 0 0-2.28zM18.81 20.08l-3.66.81l.85-3.63L26.32 6.87l2.82 2.82zM30.27 8.56l-2.82-2.82L29 4.16L31.84 7z" class="clr-i-outline clr-i-outline-path-2" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.status != 'PENDING' || oi.order.delivery_status != 'PENDING' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M33 6.4l-3.7-3.7a1.71 1.71 0 0 0-2.36 0L23.65 6H6a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V11.76l3-3a1.67 1.67 0 0 0 0-2.36zM18.83 20.13l-4.19.93l1-4.15l9.55-9.57l3.23 3.23zM29.5 9.43L26.27 6.2l1.85-1.85l3.23 3.23z" class="clr-i-solid clr-i-solid-path-1" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status == 'READY_TO_PICKUP' || oi.order.delivery_status =='PENDING'" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M27.71 4.29a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l9.6 3.84l3.84 9.6a1 1 0 0 0 .9.63a1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05zM19 24.2l-2.79-7L21 12.41L19.59 11l-4.83 4.83L7.8 13l17.53-6.33z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status=='SENT' || oi.order.delivery_status=='DELIVERED'" class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M27.71 4.29a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l8.59 3.43L19.59 11L21 12.41l-6.37 6.37l3.44 8.59A1 1 0 0 0 19 28a1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status != 'DELIVERED' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/></g></svg>
								</span>
								<span *ngIf="oi.order.delivery_status == 'DELIVERED' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793L6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/></g></svg>
								</span>
								<span *ngIf="oi.order.paid_status != 'PAID' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.89-8.9c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.44-.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6.56-2.62 2.85-2.62 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 3.02 2.96V19h1.75v-1.24c.52-.09 3.02-.59 3.02-3.22c.01-1.39-.6-2.61-3-3.44z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.paid_status == 'PAID' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
								</span>
							</ng-container>
						</td>
						<td class="text-end border-right d-none d-xxl-table-cell">{{oi.total | currency}}</td>
						<td class="text-end">{{oi.pending | currency:'$' }} {{oi.order.currency_id}}</td>
						<td class="text-end nowrap">
							<div class="col-12 form-check form-switch pt-2" *ngIf="oi.order.status == 'CLOSED'">
								<label class="form-check-label">
									<input type="checkbox" [name]="'oi'+oi.order.id" (click)="toggleCheck(oi)" class="form-check-input" [checked]="oi.selected">
								</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row mb-3 border-bottom pb-2 d-lg-none" *ngFor="let oi of order_info_list">
			<div class="col-9">
				<a [routerLink]="['/view-order',oi.order.id]" *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a *ngIf="!oi.order.id && oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order-offline',oi.order.id]" >
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a [routerLink]="['/pos',oi.order.id]" *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{oi.order.client_name}}
				</a>
			</div>
			<div class="col-3 px-0 text-end align-self-center">{{oi?.order?.total | currency:'$'}}</div>
			<div class="col-6 col-sm-4 align-self-center">
				<span *ngIf="oi?.order?.delivery_status == 'PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/></g></svg>
				</span>
				{{oi?.store?.name | titlecase}} {{(oi?.order?.delivery_status == 'DELIVERED') ? '✓':'' | titlecase}}
			</div>
			<div class="col-6 col-sm-2 col-md-2 align-self-center">
				{{oi.order.created | shortDate: 'MMM d' }}
			</div>
			<div class="col-12 col-sm-6 col-md-6 text-end px-0">
			</div>
		</div>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
			</div>
		</div>
	</div>
</div>
<app-modal [show]="show_receive_payment" (showChange)="onClosePayment($event)"  [biggest_posible]="true">
	<div class="" *ngIf="show_receive_payment">
		<app-make-payment-orders [order_info_list]="selected_order_info_list" [currency_rate_list]="currency_rate_list" [store_list]="store_list"></app-make-payment-orders>
	</div>
</app-modal>

