import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ShippingInfo, ShippingItemInfo } from 'src/app/models/models';
import { Category, Item, Store } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-print-shipping',
	templateUrl: './print-shipping.component.html',
	styleUrls: ['./print-shipping.component.css']
})
export class PrintShippingComponent extends BaseComponent implements OnInit {
	total_boxes: number = 0;
	total_received_items: number = 0;
	total_individual_boxes: number = 0;
	total_pallets: number = 0;
	item_list: Item[];
	category_list: Category[];
	shipping_info: ShippingInfo;
	category_dictionary: Record<number,Category> = {};
	store_dictionary: Record<number,Store> = {};

	ngOnInit(): void
	{
		this.route.paramMap
		.pipe
		(
			mergeMap((param_map)=>
			{
				this.is_loading = true;

				return forkJoin
				({
					shipping_info : this.rest.shipping_info.get(param_map.get('shipping_id')),
					store : this.rest.store.search({limit:9999,sort_order:['name_ASC']})
				})
			}),
			mergeMap((response)=>
			{
				response.store.data.forEach((store)=>this.store_dictionary[store.id]=store);

				let tmp_category_ids:number[] = response.shipping_info.items
				.map((sii:ShippingItemInfo)=>
				{
					return sii.item?.category_id || null;
				});

				let	category_ids:number[] = tmp_category_ids.filter(i=>i!==null);

				return forkJoin
				({
					shipping_info: of( response.shipping_info ),
					category: this.rest.category.search({limit:99999,csv:{id:category_ids},sort_order:['name_ASC']})
				})
			})
		)
		.subscribe((responses)=>
		{
			this.is_loading = false;
			responses.category.data.forEach(c=>this.category_dictionary[c.id]=c);
			this.total_pallets = 0;
			this.total_boxes = 0;
			this.total_items = 0;
			this.total_received_items = 0;
			this.total_individual_boxes= 0;

			responses.shipping_info.items.forEach((sii:ShippingItemInfo)=>
			{
				this.total_items	+= sii.shipping_item.qty;
				this.total_received_items += sii.shipping_item.received_qty;
			});

			this.calculateReceivedItems();

			this.shipping_info = responses.shipping_info;
			this.item_list = [];
			this.category_list = responses.category.data;
			this.shipping_info = responses.shipping_info;
		},(error)=>this.showError(error));
	}

	calculateReceivedItems()
	{
		if( !this.shipping_info )
			return;

		this.total_received_items = 0;
		this.shipping_info.items.forEach((sii:ShippingItemInfo)=>
		{
			console.log( sii );
			this.total_received_items += sii.shipping_item.received_qty;
		});
	}

	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
	}
}
