import { Component, OnInit } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';
import { OrderInfo, PaymentInfo, ReturnsInfo } from 'src/app/models/models';
import { Order, Payment, Price_Type, Store, User } from 'src/app/models/RestModels';
import { RestResponse, SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';


interface Totales
{
	order_info_list:CustomOrderInfo[];
	total:number;
	pending: number;
	currency_id:string;
}

interface CustomOrderDetails extends OrderInfo
{
	payments:Array<PaymentInfo>;
}

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	uuid:string | '';
}

interface COrder extends Order
{
	uuid:string | '';
}

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
}

@Component
({
	selector: 'app-saldo-cliente',
	templateUrl: './saldo-cliente.component.html',
	styleUrls: ['./saldo-cliente.component.css']
})

export class SaldoClienteComponent extends BaseComponent implements OnInit
{
	file:File | null= null;
	show_import:boolean = false;
	order_search:SearchObject<COrder> = this.getEmptySearch();
	order_info_list:CustomOrderInfo[] = [];
	User:User | null = null;

	store_list:Store[] = [];
	price_type_list:Price_Type[] = [];
	store_dictionary:Record<number,Store> = {};
	price_type_dic:Record<number,Price_Type> = {};
	show_asign_delivery_user:boolean = false;
	selected_order_info:OrderInfo | null = null;
	show_returns:boolean = false;
	cashier_user_list:User[] = [];
	show_create_shipping:boolean = false;

	fecha_inicial:string = '';
	fecha_final:string = '';
	shipping_guide: any;
	shipping_company: any;
	shipping_date: any;
	mark_as_sent:boolean = false;
	show_advanced_search:boolean = false;
	show_offline_options:boolean = false;
	show_cancel_order: boolean = false;
	cancellation_reason:string = '';
	user: User = null;
	agent_user:User | null = null;
	client:User | null = null;
	totales: Totales[] = [];

	ngOnInit()
	{
		this.path = '/list-order-facturas';
		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((query_params)=>
			{
				let fields = [ "id","client_user_id","cashier_user_id","facturado","store_id","shipping_address_id",
					"price_type_id","status","type","client_name","total","subtotal","tax","amount_paid",
					"paid_status", "address","suburb","city","state","zipcode","name","total",
					"delivery_status", "updated_by_user_id","created","updated","store_consecutive","facturacion_code",
					"sat_razon_social","uuid"
				];

				let extra_keys:Array<string> =
				[
					'transaction_type','advanced_search',
					'with_discounts','agent_user_id','start_timestamp','end_timestamp',
					'total_final'
				];

				let order_search:SearchObject<Order> = this.getSearch( query_params, fields, extra_keys );

				console.log('Sat razon social', order_search.eq);

				this.user = null;
				this.setTitle('Ordenes');
				this.is_loading = true;
				order_search.limit = this.page_size;
				this.current_page = this.order_search.page;
				order_search.sort_order = ['id_DESC'];
				order_search.search_extra['for_listing'] = 1;
				order_search.search_extra['include_uuid'] = 1;
				console.log('order_search', order_search );

				this.show_advanced_search = !!order_search.search_extra.advanced_search;

				order_search.eq.facturado = 'YES';
				order_search.eq.status = 'CLOSED';

				order_search.csv.paid_status = ['PENDING','PARTIALLY_PAID'];
				order_search.search_extra['ares'] = this.rest.has_hades ? 1: null;

				this.order_search = order_search;

				return forkJoin
				({
					orders: this.rest.order_info.search(this.order_search),
					user: this.order_search.eq.client_user_id
						? this.rest.user.get( this.order_search.eq.client_user_id )
						: of( null ),
					agent: this.order_search.search_extra.agent_user_id
						? this.rest.user.get( this.order_search.search_extra.agent_user_id )
						: of( null )
				})
			}),
			mergeMap((response)=>
			{
				console.log( response );
				let tmp_total = response.orders.data.reduce((p,c)=>p+c.order.total,0);

				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, eq:{sales_enabled:1}, sort_order:['name_ASC']});


				let cashier_user_obs = this.cashier_user_list.length > 0
					? of({ data:this.cashier_user_list, length: this.cashier_user_list.length })
					: this.rest.user.search
					({
						limit:9999, eq:{type:'USER'},
						sort_order:['name_ASC'],
						search_extra:{ 'user_permission.pos': 1 }
					});

				return forkJoin
				({
					order : of( response.orders ),
					store : store_obs,
					price_type : this.rest.getPriceTypes(true),
					users: cashier_user_obs,
					user: of( response.user ),
					agent: of( response.agent )
				})
			})
		)
		.subscribe((responses)=>
		{
			this.totales = this.getTotales( responses.order.data);
			this.is_loading = false;
			this.user	= responses.user;
			responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);
			responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
			this.store_list	= responses.store.data;
			this.cashier_user_list	= responses.users.data;
			this.price_type_list	= responses.price_type.data;
			this.order_info_list	= responses.order.data.map(oi=> this.getCustomOrderInfo(oi));
			this.agent_user	= responses.agent;

			this.setPages( this.order_search.page, responses.order.total );
		},(error)=>this.showError(error));
	}

	getTotales(data: OrderInfo[]): Totales[]
	{
		let totales:Totales[] = [];
		for(let order_info of data)
		{
			let total:Totales | undefined = totales.find(t=>t.currency_id == order_info.order.currency_id);

			if(! total )
			{
				total = {total:0, currency_id:order_info.order.currency_id, pending: 0, order_info_list:[]};
				totales.push( total );
			}

			total.total += order_info.order.total-order_info.order.discount;
			total.pending += (order_info.order.total-order_info.order.discount) - order_info.order.amount_paid ;
			total.order_info_list.push( this.getCustomOrderInfo(order_info) );
		}

		return totales;
	}

	confirmReadyToPickup(order_info:OrderInfo)
	{
		this.subs.sink = this.confirmation.showConfirmAlert
		(
			order_info
			,'Marcar Como Listo para recoger'
			,'Esta seguro de querer marcar la orden como preparada y lista para recoger'
		)
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest
				.updateOrderDeliveryStatus( order_info.order.id, 'READY_TO_PICKUP')
				.subscribe(()=>
				{
					order_info.order.delivery_status = 'READY_TO_PICKUP';
					this.rest.sendNotification('order', order_info.order.id );
					this.showSuccess('La orden se marco como lista para recoger');
				},(error)=>this.showError(error));
			}
		});
	}

	confirmSend(order_info:OrderInfo)
	{
		this.subs.sink = this.confirmation.showConfirmAlert
		(
			order_info,
			'Marcar Como Enviado',
			'Esta seguro de querer marcar la orden como enviada'
		)
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest
				.updateOrderDeliveryStatus(order_info.order.id, 'SENT')
				.subscribe(()=>
				{
					order_info.order.delivery_status = 'SENT';
					this.showSuccess('La orden se marco como enviada');
					this.rest.sendNotification('order', order_info.order.id);
				},(error)=>this.showError(error));
			}
		});
	}
	showSelectDeliveryUser(selected_order_info:OrderInfo)
	{
		this.selected_order_info = selected_order_info;
		this.show_asign_delivery_user = true;
	}
	onDeliveryUserSelected(user:User | null)
	{
		this.show_asign_delivery_user = false;
		if( user != null )
		{
			this.selected_order_info.order.delivery_user_id = user.id;
		}
	}

	confirmDelivered(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.subs.sink = this.confirmation.showConfirmAlert
		(
			order_info,
			'Marcar Como Entregado',
			'Esta seguro de querer marcar la orden como Entregada'
		)
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.is_loading = true;
				this.subs.sink = this.rest
				.updateOrderDeliveryStatus( this.selected_order_info.order.id, 'DELIVERED' )
				.subscribe(()=>
				{
					this.is_loading = false;
					this.rest.sendNotification('order', order_info.order.id);
					this.selected_order_info.order.delivery_status = 'DELIVERED';
					this.rest.sendNotification('order', order_info.order.id);
				},(error)=>{this.showError(error)});
			}
		});
	}
	togglePublicoGeneral(evt:Event)
	{
		let mouse_event:MouseEvent = evt as MouseEvent;
		let checkbox:HTMLInputElement = evt.target as HTMLInputElement;
		//checkbox.checked = !checkbox.checked;

		let eq = this.order_search.eq as any;
		if( !checkbox.checked )
		{
			eq.price_type_id = null;//Solo personas avanzadas no lo intenteis en casa
		}
		else
		{
			//eq.price_type_id = 1;
		}

		this.order_search.search_extra.publico_general = checkbox.checked ? '1': null;
	}

	search(search_object:SearchObject<COrder>)
	{
		if( !this.show_advanced_search )
		{
			console.log('FOOO NOT advanced_search');
			super.search({
				eq:{ store_id : search_object.eq.store_id, client_user_id:search_object.eq.client_user_id || null, uuid: search_object.eq.uuid },
				ge:{ created: search_object.ge.created },
				le:{ created: search_object.le.created },
				search_extra:{
					agent_user_id: search_object.search_extra.agent_user_id || null,
					advanced_search: (this.show_advanced_search ? '1' : ''),
					start_timestamp: search_object.search_extra.start_timestamp,
					end_timestamp: search_object.search_extra.end_timestamp
				}
			});
			return;
		};

		search_object.csv.status = [];
		//search_object.eq.client_user_id = null;
		if( this.show_advanced_search )
			search_object.search_extra.advanced_search = 1;

		console.log('not advanced_search');
		super.search( search_object )
	}

	showOrderReturnModal(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.show_returns = true;
	}

	onOrderReturned(returns_info:ReturnsInfo)
	{
		this.show_returns = false;
		console.log('returned', returns_info);
	}

	confirmCancel(order_info:OrderInfo)
	{
		this.show_cancel_order = true;
		this.selected_order_info = order_info;
	}

	exportOrders(evt:Event)
	{
		let search:SearchObject<Order> = {...this.order_search};
		search.limit = 100;

		let status_dict:Record<string,string> = {
			'PENDING':'Pendiente',
			'ACTIVE':'Activa',
			'CANCELLED':'Cancelada',
			'CLOSED':'Cerrada',
		}


		this.subs.sink = this.rest.order_info
		.search(search).pipe
		(

			mergeMap((response)=>{
				let observables:Observable<RestResponse<OrderInfo>>[] = [ of(response) ];
				let pages= response.total/100;

				for(let i=1;i<pages;i++)
				{
					let s:SearchObject<Order> = {...this.order_search};
					s.limit = 100;
					s.page = i;
					observables.push( this.rest.order_info.search( s ) );
				}

				return forkJoin( observables );
			}),
			mergeMap((response)=>{
				let all:RestResponse<OrderInfo> = { total: 0, data: []};

				response.forEach((i)=>{
					all.total = i.total;
					all.data.push(...i.data);
				})

				return of( all );
			})
		)
		.subscribe((response)=>
		{

			let payment_method = {
				'01': 'Efectivo',
				'02': 'Cheque',
				'04': 'T. Credito',
				'28': 'T. Debito',
				'03': 'Transferencia',
				'99': 'Credito',
			};

			let cdate = Utils.getLocalMysqlStringFromDate(new Date()).substring(0,10);

			let array = response.data.map((order_info:OrderInfo)=>{

				let s = order_info.order.sat_forma_pago in payment_method ? payment_method[ order_info.order.sat_forma_pago ] : 'Por Definir';
				return {
					'Id':	order_info.order.id,
					'Cliente':	order_info?.client?.name || order_info.order.client_name,
					'Total':	order_info.order.total,
					'Pagado':	order_info.order.amount_paid,
					'Sucursal':	order_info.store.name,
					'Folio':	order_info.order.store_consecutive,
					'Fecha':	Utils.getLocalMysqlStringFromDate(order_info.order?.system_activated ? order_info.order.system_activated : order_info.order.created ).substring(0,10),
					'Estatus':	status_dict[ order_info.order.status ],
					'Facturado':	order_info.order.facturado,
					'Pago': s
				}
			});
			ExcelUtils.array2xlsx(array,'orders-'+cdate+'.xlsx', ['Id','Cliente','Total','Pagado','Sucursal','Folio','Facturado','Fecha','Pago','Estatus']);
		},(error)=>{this.showError(error)});
	}

	exportOrdersDetails(evt:Event)
	{
		let search:SearchObject<Order> = {...this.order_search};
		search.limit = 100;

		let status_dict:Record<string,string> = {
			'PENDING':'Pendiente',
			'ACTIVE':'Activa',
			'CANCELLED':'Cancelada',
			'CLOSED':'Cerrada',
		}

		this.subs.sink = this.rest.order_info
		.search(search).pipe
		(
			mergeMap((response)=>{
				let observables:Observable<RestResponse<OrderInfo>>[] = [ of(response) ];
				let pages= response.total/100;

				for(let i=1;i<pages;i++)
				{
					let s:SearchObject<Order> = {...this.order_search};
					s.limit = 100;
					s.page = i;
					observables.push( this.rest.order_info.search( s ) );
				}

				return forkJoin( observables );
			}),
			mergeMap((response)=>
			{
				let all:RestResponse<OrderInfo> = { total: 0, data: []};

				response.forEach((i)=>{
					all.total = i.total;
					all.data.push(...i.data);
				})

				let order_ids = all.data.map(oi=>oi.order.id);
				let payment_observables:Observable<RestResponse<PaymentInfo>>[] = [];

				while( order_ids.length )
				{
					let ids = order_ids.splice(0,100);

					let	search:SearchObject<Payment>	=	this.getEmptySearch();
					search.search_extra	=	{order_id:ids.join(',')};
					search.limit	=	100;
					payment_observables.push( this.rest.payment_info.search( search ) );
				}


				return forkJoin
				({
					payments: forkJoin( payment_observables ),
					orders: of( all )
				})
			})
		)
		.subscribe((response)=>
		{
			let payment_maps = this.getPaymentByOrderIdMap( response.payments );
			console.log( payment_maps );
			let cdate = Utils.getLocalMysqlStringFromDate(new Date()).substring(0,10);
			let rows:Object[] = [];
			let a = '';
			let empty_obj = {
				'Id': a,
				'Cliente':	a,
				'Cliente Id' : a,
				'Total':	a,
				'Pagado':	a,
				'Sucursal':	a,
				'Creacion': a,
				'Activación': a,
				'Cerrada': a,
				'Estatus':	a,
				'Facturado': a
			}

			for(let order_info of response.orders.data )
			{
				let id	= order_info.order.id;
				let client	= order_info?.client?.name || order_info.order.client_name;
				let client_id = order_info?.client?.id || '';
				let total	=	order_info.order.total;
				let pagado	=	order_info.order.amount_paid;
				let sucursal	=	order_info.store.name;
				// let folio	=	order_info.order.store_consecutive;
				let fecha		=	Utils.getLocalMysqlStringFromDate(order_info.order.created);
				let activacion	= order_info.order.system_activated ? Utils.getLocalMysqlStringFromDate(order_info.order.system_activated) : '';
				let cerrada		= order_info.order.closed_timestamp ? Utils.getLocalMysqlStringFromDate( order_info.order.closed_timestamp ) : '';
				let estatus		=	status_dict[ order_info.order.status ];
				let facturado	=	order_info.order.facturado;
				let payments	= payment_maps.get( id );

				let order_obj = {
					'Id': id,
					'Cliente':	client,
					'Cliente Id' : client_id,
					'Total':	total,
					'Pagado':	pagado,
					'Sucursal':	sucursal,
					'Creacion': fecha,
					'Activación': activacion,
					'Cerrada': cerrada,
					'Estatus':	status_dict[ order_info.order.status ],
					'Facturado':	order_info.order.facturado,
				};

				let payment_objs = payments ? this.getPaymentObject( payments, order_info): [];

				console.log( payment_objs );

				let i = 0;

				for(let po of payment_objs )
				{
					if( i == 0 )
					{
						rows.push({...order_obj, ...po });
						i++;
					}
					else
					{
						rows.push({...empty_obj, ...po });
					}
				}
			}

			ExcelUtils.array2xlsx(rows,'orders-'+cdate+'.xlsx', ['Id','Cliente','Total','Pagado','Sucursal','Folio','Facturado','Fecha','Pago','Estatus']);
		},(error)=>{this.showError(error)});
	}

	getPaymentObject(pi_array:PaymentInfo[],order_info:OrderInfo):Object[]
	{
		console.log( pi_array );
		let rows = [];

		type FOO = string | number | null;

		for(let paymen_info of pi_array)
		{
			for(let bm of paymen_info.movements)
			{
				let bmo_array = bm.bank_movement_orders.filter(bmo=>bmo.order_id == order_info.order.id);

				let ingreso_currency:FOO	= bm.bank_movement.currency_id;
				let ingreso_amount:FOO		= bm.bank_movement.amount_received;
				let referencia_bancaria:FOO	= bm.bank_movement.reference;
				let tipo_transaccion		= this.getTransactionType( bm.bank_movement.transaction_type );


				for( let bmo of bmo_array)
				{
					rows.push
					({
						'ID Pago'			:	paymen_info.payment.id,
						'Total Ingreso'		:	ingreso_amount+' '+ingreso_currency,
						'Cantidad Pago'		:	bmo.currency_amount+' '+ingreso_currency,
						'Fecha Pago'		:	bm.bank_movement.paid_date || '',
						'Tipo de Cambio'	:	bmo.exchange_rate,
						'Tipo Transaccion'	:	tipo_transaccion,
						'Cantidad Saldada'	:	bmo.amount+' '+order_info.order.currency_id,
						'Referencia Bancaria':	referencia_bancaria,
						'Registro Pago UTC'	:	Utils.getUTCMysqlStringFromDate( bm.bank_movement.created ),
						'Registro Pago Local':	Utils.getLocalMysqlStringFromDate( bm.bank_movement.created ),
					})
				}
			}
		}

		if( rows.length == 0 )
		{
			let a = '';
			rows.push
			({
				'ID Pago'			:	a,
				'Total Ingreso'		:	a,
				'Cantidad Pago'		:	a,
				'Fecha Pago'		:	a,
				'Tipo de Cambio'	:	a,
				'Tipo Transaccion'	:	a,
				'Cantidad Saldada'	:	a,
				'Referencia Bancaria':	a,
				'Registro Pago UTC'	:	a,
				'Registro Pago Local':	a
			});
		}

		return rows;
	}

	getTransactionType(str:string):string
	{
		switch( str )
		{
			case 'CASH':	return 'Efectivo';
			case 'CREDIT_CARD':	return 'Tarjeta Crédito';
			case 'DEBIT_CARD':	return 'Tarjeta Débito';
			case 'CHECK':		return 'Cheque';
			case 'COUPON':		return 'Cupón';
			case 'TRANSFER':		return 'Transferencia';
			case 'DISCOUNT':		return '';
			case 'RETURN_DISCOUNT':	return 'RETORNO';
			case 'PAYPAL': return 'PAYPAL';
		}

		return 'OTRO';
	}

	getPaymentByOrderIdMap(payment_responses:RestResponse<PaymentInfo>[]):Map<number,PaymentInfo[]>
	{
		let payment_maps =new	Map<number,PaymentInfo[]>();

		for(let pr of payment_responses )
		{
			for(let payment_info of pr.data)
			{
				let o_ids = new Map<number,number>();

				for(let bmi of payment_info.movements )
				{
					bmi.bank_movement_orders.forEach( bmo => o_ids.set( bmo.order_id, bmo.order_id) );
				}

				let order_ids = Array.from( o_ids.keys() );

				for(let id of order_ids )
				{
					if( !payment_maps.has( id ) )
					{
						payment_maps.set( id, [] );
					}

					payment_maps.get(id).push( payment_info );
				}
			}
		}
		return payment_maps;
	}

	fechaIncialChange(fecha:string)
	{
		this.fecha_inicial = fecha;
		if( fecha )
		{
			this.order_search.search_extra.start_timestamp = Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.order_search.search_extra.start_timestamp = null;
		}
	}

	fechaFinalChange(fecha:string)
	{
		this.fecha_final = fecha;
		if( fecha )
		{
			this.order_search.search_extra.end_timestamp= Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.order_search.search_extra.end_timestamp = null;
		}
	}
	markAsSentChange(evt:Event)
	{
		let i = evt.target as HTMLInputElement;
		this.mark_as_sent = i.checked;
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price >= oi.order_item.unitary_price_meta )
				return prev;

			let diff = oi.order_item.unitary_price_meta - oi.order_item.original_unitary_price;
			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let uuid = order_info?.sat_facturas?.length ? order_info.sat_facturas[0].uuid: '';

		return {
			...order_info,
			article_discount,
			uuid
		}
	}

	toggleAdvancedSearch(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		this.show_advanced_search = input.checked
	}

	print()
	{
		window.print();
	}
}
