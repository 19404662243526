<div class="container-fluid">

	<div class="row align-items-end mx-0 my-3">
		<div class="col">
			<h1 class="text-capitalize">Seccion De Almancen</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-storage']">Agregar</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="storage_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Store</label>
					<input type="number" name="store_id" [(ngModel)]="storage_search.eq.store_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Shelf</label>
					<input type="text" name="shelf" [(ngModel)]="storage_search.lk.shelf" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Section</label>
					<input type="text" name="section" [(ngModel)]="storage_search.lk.section" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Sort order</label>
					<input type="number" name="sort_order" [(ngModel)]="storage_search.eq.sort_order" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created by user</label>
					<input type="number" name="created_by_user_id" [(ngModel)]="storage_search.eq.created_by_user_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated by user</label>
					<input type="number" name="updated_by_user_id" [(ngModel)]="storage_search.eq.updated_by_user_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="storage_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="storage_search.eq.updated" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="row border-bottom d-none d-md-flex fw-bold">
			<div class="col-3">Seccion- Compartimiento</div>
			<div class="col-3">Sucursal</div>
			<div class="col-3">Actualizacion</div>
			<div class="col-3">Acciones</div>
		</div>

		<div class="row border-bottom my-1 pb-1" *ngFor="let storage of storage_list">
			<div class="col-12 col-md-3 align-self-center">
				<span class="d-md-none fw-bold">Seccion: &nbsp;</span>
				<a [routerLink]="['/edit-storage',storage.id]">{{storage.name}} - {{storage.id}}</a>
			</div>
			<div class="col-12 col-md-3 align-self-center">
				<span class="d-md-none fw-bold">Sucursal: &nbsp;</span>
				{{store_dictionary[ storage.store_id ]?.name | titlecase}}
			</div>
			<div class="col-12 col-md-3 align-self-center">
				<span class="d-md-none fw-bold">Actualización: &nbsp;</span>
				{{storage.updated | date: 'MMM d, y,  h:mm a' }}
			</div>
			<div class="col-12 col-md-3">
				<a [routerLink]="['/save-storage-item',storage.id]" class="btn btn-primary my-1 w-100">Asignar Artículos</a>
			</div>
		</div>
		<div class="my-3" *ngIf="storage_list == null || storage_list.length == 0 && !is_loading">
			<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
		</div>
	</div>
	<app-pagination [path]="'/list-storage'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
