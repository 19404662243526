import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-report-item-bom-price',
	templateUrl: './report-item-bom-price.component.html',
	styleUrls: ['./report-item-bom-price.component.css']
})

export class ReportItemBomPriceComponent extends BaseComponent implements OnInit
{
	data:Record<string,any>[] = [];
	dummy_search:SearchObject<any> = this.getEmptySearch();

	ngOnInit(): void
	{
		this.path = '/report-item-bom-price';
		this.setTitle('Costo y rango por articulo')

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let extra_fields=[];
				let fields:string[] =
				[
					'item_id','item_name','category_id',
					'category_name','item_reference_price',
					'options_max_price', 'options_min_price',
					'item_per_stock','ingredients_price'
				];

				this.dummy_search = this.getCombinedSearch(response[0],response[1], fields, extra_fields );
				this.setPages(this.dummy_search.page, this.page_size );

				let params = {
					_sort: this.dummy_search.sort_order.join(','), 
					page: this.dummy_search.page, 
					limit: this.dummy_search.limit
				};
				return this.rest.getReporte( 'getItemMarginPrice', params);
			})
		)
		.subscribe((response)=>
		{
			this.data = response;
		},(error)=>this.showError(error));
	}

	clickOnHeader(header:string)
	{
		this.sort(header,this.dummy_search);
	}
}
