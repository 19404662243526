<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col-12 col-sm-6 col-lg-6 px-1">
			<h1 class="text-capitalize" *ngIf="provider">Recibos del proveedor <b>{{provider.name}}</b></h1>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
			<b>Total</b>:<br>
			<span>{{total |number: '1.2-2'}} {{user_store.default_currency_id}}</span>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
			<b>Pendiente</b>:<br>
			<span>{{pending |number: '1.2-2'}} {{user_store.default_currency_id}}</span>
		</div>
		<div class="col-4 col-sm-2 col-lg-2 px-1 text-end">
			<button *ngIf="total_to_pay > 0" class="btn btn-primary" (click)="onPay()">Pagar {{total_to_pay | number: '1.2-2'}} {{total_to_pay_currency_id}}</button>
		</div>
	</div>
	<div class="card p-3">
		<div *ngIf="cbill_info_list.length == 0">
			No hay recibos pendientes
		</div>
		<div class="table-responsive" *ngIf="cbill_info_list.length > 0">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>#</th>
						<th>Folio</th>
						<th>Sucursal</th>
						<th>Fecha</th>
						<th>Fecha limite</th>
						<th class="text-end">
							Total
						</th>
						<th class="text-end">Pendiente</th>
						<th class="text-end float-end">
							<div class="form-check form-switch text-end">
								<input name="toggle_all" class="form-check-input" type="checkbox" id="to" [checked]="someChecked()" (click)="onToggleAll($event)">
							</div>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="cbill_info_list.length > 0">
					<tr *ngFor="let bi of cbill_info_list" class="align-middle">
						<td>
							{{bi.bill.id}}
						</td>
						<td>
							{{bi.bill.folio ? bi.bill.folio : '---'}}
							-
							<a *ngIf="bi.bill.purchase_id" [routerLink]="['/purchase/edit',bi.bill.purchase_id]" class="btn btn-link">Purchase</a>
						</td>
						<td>
							{{bi.store ? bi.store.name : 'N/A'}}
						</td>
						<td>
							{{bi.bill.created| date: 'dd/MM/yyyy'}}
						</td>
						<td>
							{{bi.bill.due_date | date: 'dd/MM/yyyy'}}
						</td>
						<td class="text-end">
							{{bi.bill.total | number: '1.2-2'}} {{bi.bill.currency_id}}
						</td>
						<td class="text-end">
							{{bi.bill.total  - bi.bill.amount_paid| number: '1.2-2'}} {{bi.bill.currency_id}}
						</td>
						<td class="text-end nowrap">
							<div class="col-12 form-check form-switch pt-2">
								<label class="form-check-label">
									<input type="checkbox" [name]="'bi'+bi.bill.id" (click)="onToggleCheck(bi)" class="form-check-input" [checked]="bi.selected">
								</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [(show)]="show_make_payment" [biggest_posible]="true">
	<div class="card p-3">
		<div class="row">
			<div class="col-12 col-sm-4">
				<h2 class="text-capitalize">Abonar recibos</h2>
			</div>
			<div class="col-12 col-sm-8 text-end">
				<h2>{{total_to_pay | currency: '$'}} {{total_to_pay_currency_id}}</h2>
			</div>
			<div class="col-12 col-sm-6">
				<label for="payment_amount">
					<b>Monto a abonar</b>
				</label>
				<input type="number" class="form-control" id="payment_amount" [(ngModel)]="payment_amount">
			</div>
			<div class="col-12 col-sm-6">
				<label>&nbsp;</label>
				<button class="btn btn-primary w-100" [disabled]="is_loading" (click)="makePayment()">Pagar</button>
			</div>
		</div>
	</div>
</app-modal>

<!-- <app-modal [(show)]="show_make_payment" [biggest_posible]="true">
<app-make-payment-bills [currency_rate_list]="currency_rate_list" [bill_info_list]="selected_bill_info_list" [partial_amount]="total_to_pay"></app-make-payment-bills>
</app-modal> -->
