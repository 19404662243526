import {Component } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo, OrderItemInfo} from 'src/app/models/models';
import { Order, Price_Type, Store } from 'src/app/models/RestModels';
import { ConfirmationResult } from 'src/app/services/confirmation.service';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-list-order-waiter',
  templateUrl: './list-order-waiter.component.html',
  styleUrls: ['./list-order-waiter.component.css']
})
export class ListOrderWaiterComponent extends BaseComponent
{
	is_order_ready:Record<number,boolean> = {};
	min_preparation_status:Record<number,'PENDING'|'READY'|'DELIVERED'> = {};

	store_list:Store[] = [];
	order_search:SearchObject<Order> = this.getEmptySearch();
	order_info_list:OrderInfo[] = [];
    price_type_list: Price_Type[] = [];
    price_type_dic: Record<number, Price_Type> = {};
    store_dictionary: Record<number, Store> = {};

	ngOnInit()
	{
		this.path = '/list-order-waiter';
		this.subs.sink = this.rest.updates.subscribe((message:any)=>
		{
			this.router.navigateByUrl('/',{skipLocationChange: true}).then(()=>{
				this.router.navigate([this.path],{queryParams: this.order_search});
			});
		});

		this.subs.sink = this.rest.notification.subscribe((something)=>{
			console.log('something');
		});

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap:ParamMap) =>
		{
			let fields = [ "id","client_user_id","cashier_user_id","service_type","store_id","created","updated" ];

			let extra_keys:Array<string> = ['transaction_type','publico_general']; //['search_param1','project_id','some_id'];
			this.order_search = this.getSearch(queryParamMap, fields, extra_keys);
			this.setTitle('Mi Lista Ordenes');
			//this.order_search.eq.cashier_user_id = this.rest.current_user.id;
			this.order_search.csv.status = ['PENDING','ACTIVE','CLOSED'];
			this.order_search.eq.service_type = 'QUICK_SALE';
			this.order_search.eq.store_id = this.rest.current_user.store_id;



			let today = new Date();
			today.setDate( today.getDate() -1  );
			today.setHours( today.getHours() -12 );
			this.order_search.ge.created = today;

			if( !queryParamMap.has('ares') )
			{
				this.order_search.eq.ares = 0;
			}
			else if( queryParamMap.get('ares')== '1' )
			{
				this.order_search.eq.ares = 1;
			}

			this.is_loading = true;
			this.order_search.limit = 9999;
			this.current_page = this.order_search.page;
			this.order_search.sort_order = ['id_DESC'];
			this.is_loading = true;

			this.subs.sink = this.rest.order_info.search(this.order_search).pipe
			(
				mergeMap((order_data)=>
				{
					return forkJoin
					({
						order : of( order_data ),
						store : this.rest.getStores(),
						price_type : this.rest.getPriceTypes(true),
						order_total: order_data.data.length == 0
							? of({total:0,data:[]})
							: this.rest.getOrderTotal(this.order_search)
					})
				})
			)
			.subscribe((responses)=>
			{
				responses.order.data.forEach((order_info:OrderInfo)=>
				{
					this.is_order_ready[order_info.order.id]= this.isOrderReady(order_info);
					this.min_preparation_status[order_info.order.id] = this.getMinimunPreparationStatus(order_info);
				});

				responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);
				responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
				this.price_type_list	= responses.price_type.data;
				responses.order.data.sort((a,b)=>this.compareOrderInfo(a, b));

				this.order_info_list = responses.order.data;
				this.setPages( this.order_search.page, responses.order.total );
				this.store_list = responses.store.data;
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}

	compareOrderInfo(oi1:OrderInfo,oi2):number
	{
		if( this.isOrderReady(oi1) && !this.isOrderReady(oi2) )
		{
			return 1;
		}

		if( !this.isOrderReady(oi1) && this.isOrderReady(oi2) )
			return -1;

		if( this.isOrderReady(oi1) )
		{
			return oi1.order.id < oi2.order.id ? 1 : -1;
		}

		return oi1.order.id < oi2.order.id ? 1 : -1;
	}

	getMinimunPreparationStatus(order_info:OrderInfo):'PENDING'|'READY'|'DELIVERED'
	{
		return order_info.items.reduce((prev,oii:OrderItemInfo)=>
		{
			if( !oii.item.commanda_type_id )
				return prev;

			if( oii.order_item.preparation_status == 'READY' )
				return 'READY';

			if( oii.order_item.preparation_status == 'PENDING' )
				return 'PENDING';

			if( oii.order_item.preparation_status == 'IN_PREPARATION' )
				return 'PENDING';

			return prev;
		},'DELIVERED');
	}

	isOrderReady(order_info:OrderInfo):boolean
	{
		return !order_info.items.some((oii:OrderItemInfo)=>
		{
			if( oii.order_item.item_option_id )
				return false;

			return oii.order_item.preparation_status == 'PENDING' ||
					oii.order_item.preparation_status == 'IN_PREPARATION' ||
					oii.order_item.preparation_status == 'READY';
		});
	}

	confirmMarkItemsAsDelivered(order_info:OrderInfo)
	{

		this.subs.sink = this.confirmation.showConfirmAlert
		(
			order_info,
			'Marcar artículos como entregados',
			'¿Está seguro de marcar los artículos como entregados?',
		)
		.subscribe((result:ConfirmationResult)=>
		{
			if( result.accepted )
			{
				let order_item_ids:number[] = order_info.items
				.filter((oii:OrderItemInfo)=>oii.order_item.preparation_status == 'READY')
				.map((oii:OrderItemInfo)=>oii.order_item.id);

				this.subs.sink =
					this.rest.updateOrderItemsPreparationStatus(order_item_ids,'DELIVERED').pipe
					(
						mergeMap(()=>
						{
							return this.rest.order_info.get(order_info.order.id);
						})
					)
					.subscribe((response)=>
					{
						let index = this.order_info_list.findIndex((oi:OrderInfo)=>oi.order.id == response.order.id);
						this.is_order_ready[response.order.id] = this.isOrderReady(response);
						this.min_preparation_status[response.order.id] = this.getMinimunPreparationStatus(response);

						if( index > -1 )
							this.order_info_list[index] = response;

						this.order_info_list.sort((a,b)=>this.compareOrderInfo(a, b));

					},(error:any)=>this.showError(error));
			}
		})
	}
	/*
markOrderItemAsDelivered
	*/
}
