import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CategoryStoreInfo } from 'src/app/models/models';
import { Category_Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../../base/base.component';

@Component({
	selector: 'app-list-category-store',
	templateUrl: './list-category-store.component.html',
	styleUrls: ['./list-category-store.component.css']
})
export class ListCategoryStoreComponent extends BaseComponent implements OnInit
{
	category_search:SearchObject<Category_Store> = this.getEmptySearch();
	category_store_info_list:CategoryStoreInfo[] = [];

	ngOnInit(): void
	{
		this.path = '/list-category-store';

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((response)=>
			{
				this.category_search = this.getSearch( response, ['store_id','category_id'] );

				return forkJoin
				({
					store: this.rest.getStores(),
					category_store_info: this.rest.category_store_info.search( this.category_search )
				})
			})
		)
		.subscribe((response)=>
		{
			this.category_store_info_list = response.category_store_info.data;
			this.setPages( this.category_search.page, response.category_store_info.total );
		});
	}
}
