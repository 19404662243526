<div class="container-fluid">
	<div class="row">
		<div class="col-8 col-lg-10">
			<h1 class="text-capitalize mx-3 my-3">Agregar Articulo a
				<span *ngIf="item_info.category && rest.local_preferences.display_categories_on_items == 'YES'">
					{{item_info.category.name}} -
				</span>
				{{item_info.item.name}}
			</h1>
		</div>
		<div class="col-4 col-lg-2">
			<a [routerLink]="['/search-serial']" class="btn btn-primary">Buscar</a>
		</div>
	</div>
	<form ngNativeValidate (submit)="addNewSerial()">
		<div class="card p-1 p-md-3 my-3">
			<div class="row">
				<div class="col-4 form-group">
					<label>Serial</label>
					<input type="text" name="serial" [(ngModel)]="serial_info.serial.serial_number" class="form-control" required>
				</div>
				<div class="col-4">
					<label>Sucursal {{serial_info.serial.store_id}}-{{store_id}}</label>
					<select name="store_id" [(ngModel)]="store_id" class="form-control" required [disabled]="!rest.current_permission?.global_add_stock">
						<option value="" selected>Seleccionar</option>
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name |titlecase}}</option>
					</select>
				</div>
				<div class="col-12 col-md-5 form-group">
					<label>Descripcion</label>
					<textarea class="form-control" [name]="'desc'+serial_info.serial.serial_number" [(ngModel)]="serial_info.serial.description"></textarea>
				</div>
				<div class="col-12 col-md-5 form-group">
					<label>Datos Adicionales</label>
					<textarea class="form-control" [name]="'adata'+serial_info.serial.serial_number" [(ngModel)]="serial_info.serial.additional_data"></textarea>
				</div>
				<div class="col-12 col-md-2">
					<div class="d-inline-block">
						<app-image-uploader (imageChange)="onNewImage($event,serial_info)" [displayUploadedImage]="false" [displayUploadedImageName]="false" style="width:30px;height:30px;"></app-image-uploader>
					</div>
				</div>

				<div class="col-4 form-group">
					<label>&nbsp;</label>
					<div>
						<button type="submit" class="btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13v3q0 .425.288.713T12 17q.425 0 .713-.288T13 16v-3h3q.425 0 .713-.288T17 12q0-.425-.288-.713T16 11h-3V8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8v3H8q-.425 0-.713.288T7 12q0 .425.288.713T8 13h3Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"/></svg>
							Agregar
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<form action="https://bars.integranet.xyz/index.php" method="POST" name="form_barcodes" id="form_barcodes" ngNoForm>
		<div class="row w-100">
			<div class="col-10 col-lg-11">
				<h2>Seriales agregados</h2>
			</div>
			<div class="col-2 col-lg-1 text-end">
				<button type="button" class="btn btn-secondary" (click)="show_print_codes = true">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 8H5c-1.66 0-3 1.34-3 3v4c0 1.1.9 2 2 2h2v2c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2h2c1.1 0 2-.9 2-2v-4c0-1.66-1.34-3-3-3m-4 11H9c-.55 0-1-.45-1-1v-4h8v4c0 .55-.45 1-1 1m4-7c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1m-2-9H7c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1"/></svg>
					Codigos
				</button>
			</div>
		</div>
		<div class="card py-3 px-1 px-md-3">
			<div class="row border-bottom align-items-center py-1" *ngFor="let serial of item_info.serials;let i=index">
				<input type="hidden" name="codes[{{i}}][value]" value="{{serial.serial.serial_number}}">
				<input type="hidden" name="codes[{{i}}][format]" value="{{format}}"> {{format}}
				<input type="hidden" name="codes[{{i}}][width]" value="{{width}}px">
				<input type="hidden" name="codes[{{i}}][height]" value="{{height}}px">
				<input type="hidden" name="codes[{{i}}][diplayValue]" value="true">
				<!-- Nuevos campos para QR -->
				<input type="hidden" name="codes[{{i}}][errorCorrectionLevel]" value="{{qr_options === 'secure' ? 'Q' : 'L'}}">
				<input type="hidden" name="codes[{{i}}][printFormat]" value="{{print_format}}">
				<div class="col-12 col-md-3">{{serial.serial.serial_number}}</div>
				<div class="col col-md-3">{{serial.serial.description}}</div>
				<div class="col col-md-3">{{serial.serial.additional_data}}</div>
				<div class="col col-md-3 text-end">
					<button class="btn btn-primary me-1" type="button" (click)="confirmRemoveSerial( serial )">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"/></svg>
					</button>
					<button class="btn btn-primary" type="button" (click)="editSerial( serial )">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#e8e8e8" d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"/></svg>
					</button>
				</div>
			</div>
			<button type="button" (click)="generateCodes()">Enviar</button>
		</div>
	</form>
</div>
<app-modal [(show)]="show_print_codes">
	<div class=" py-3 px-1 px-md-3">
		<h2>Imprimir códigos</h2>
		<div class="row">
			<div class="col-12 py-1">
				<label>Formato</label>
				<select name="format" [(ngModel)]="format" class="form-control">
					<option value="CODE128">Código 128</option>
					<option value="CODE39">Código 39</option>
					<option value="EAN8">EAN 8</option>
					<option value="EAN13">EAN 13</option>
					<option value="UPCA">UPC A</option>
					<option value="ITF">ITF</option>
					<option value="ITF-14">ITF-14</option>
					<option value="farmacode">farmacode</option>
					<option value="codabar">farmacode</option>

					<option value="QR">Código QR</option>
				</select>
			</div>

			<div class="col-12 py-1">
				<label>Cantidad de códigos por hoja</label>
				<select name="printFormat" [(ngModel)]="print_format" class="form-control">
					<option value="single">Individual</option>
					<option value="multiple">Múltiples</option>
				</select>
			</div>

			<div class="col-12 col-md-6 py-1">
				<label>Tamaño de la imagen en px</label>
				<input type="number" step="1" name="height" class="form-control" [(ngModel)]="height" placeholder="200">
			</div>

            <!-- Cambiar entre "Ancho de cada línea" y "Opciones" según el formato -->
            <div class="col-12 col-md-6 py-1" *ngIf="format !== 'QR'">
                <label>Ancho de cada línea en px</label>
				<input type="number" step="1" name="width" class="form-control" [(ngModel)]="width" placeholder="2">
			</div>
            <div class="col-12 col-md-6 py-1" *ngIf="format === 'QR'">
                <label>Modo</label>
                <select name="qrOptions" [(ngModel)]="qr_options" class="form-control">
                    <option value="fast">Rápido</option>
                    <option value="secure">Seguro</option>
                </select>
            </div>

			<div class="col-12 col-md-6 py-1">
				<button type="button" (click)="generateCodes()" class="btn btn-primary" [disabled]="+height < 50">
					Generar Códigos
				</button>
			</div>
		</div>
	</div>
</app-modal>
