<div class="h-100 w-100">
	<div class="d-flex flex-column align-items-stretch h-100">
		<div *ngIf="rest.is_maps_loaded" class="flex-fill">
			<google-map [width]="'100%'" [height]="'100%'" (centerChanged)="centerChanged($event)">
				<!--map-directions-renderer *ngIf="directions_results"  [directions]="directions_results"></map-directions-renderer-->
				<map-marker [position]="center_position"></map-marker>
			</google-map>
		</div>
		<div>
			<button class="btn btn-primary" (click)="saveAddress()">Guardar</button>
		</div>
	</div>
</div>
