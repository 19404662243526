<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{post.id ? 'Actualizar':'Agregar'}} Articulo</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-12">
					<label class="">Titulo</label>
					<input type="text" name="titulo" [(ngModel)]="post.title" class="form-control">
				</div>
				<div class="col-6 col-md-6">
					<label class="">Descripción</label>
					<textarea row="10" name="descripcion" [(ngModel)]="post.post" class="form-control">
					</textarea>
				</div>
				<div class="col-6 col-md-6">
					<div>
						<label class="">Agregar Imagen</label>
						<app-image-uploader [image]="image" (imageChange)="addNewImage($event)"></app-image-uploader>
					</div>
					<div class="w-100">
						<div *ngFor="let image of image_links" class="d-inline-block mx-3">
							<div>
								<img [src]="'https://eservices.enlinea.cloud/api/image.php?id='+image" width="100px;" height="100px;" alt=""  >
							</div>
							<div class="d-inline-block mb-3">Imagen {{image}} </div>
						</div>
					</div>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-right">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
