import { Component } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/pages/base/base.component';
import { Address } from 'src/app/models/RestModels';
import { Platform_Address } from 'src/app/models/PlatformModels';

import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-client-address',
	templateUrl: './client-address.component.html',
	styleUrls: ['./client-address.component.css']
})
export class ClientAddressComponent	extends BaseComponent
{
	address_list:Address[] = [];
	platform_address:Partial<Platform_Address> | null = null;
	geolocation_watch_id:number | null = null;
	geolocation_position:GeolocationPosition | null = null;
	app_settings:any =  environment.app_settings;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap(()=>
			{
				return this.rest.address.search
				({
					sort_order:['address_ASC'],
					limit:9999
				});
			})
		).subscribe((responses)=>
		{
			this.address_list = responses.data;
		},(error)=>this.showError(error));
	}

	confirmRemoveAddress(address:Address):void
	{
		//this.platform_address = address;
	}
}
