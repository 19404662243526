<div class="container-fluid">
	<h1 class="text-capitalize my-3">{{shipping_info?.shipping?.id ? 'Actualizar':'Agregar'}} Envío</h1>
	<form id="save-shipping" (submit)="save($event)" ngNativeValidate>
		<div class="card py-3 px-1 px-lg-3 shadow">
			<div class="row">
				<div class="col-6 col-md-4">
					<label class=""># Económico o Guía</label>
					<input type="text" name="shipping_guide" [(ngModel)]="shipping_info.shipping.shipping_guide" placeholder="xxxxxx" class="form-control" [disabled]="shipping_info.shipping.status != 'PENDING'" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Nombre chofer/Paqueteria</label>
					<input type="text" name="shipping_company" [(ngModel)]="shipping_info.shipping.shipping_company" placeholder="Jesus martinez" class="form-control" [disabled]="shipping_info.shipping.status != 'PENDING'" required>
				</div>
				<!-- div class="col-6 col-md-4">
					<label class="">Requisition</label>
					<input type="number" name="requisition_id" [(ngModel)]="shipping.requisition_id" class="form-control">
					Agregar Nota
					Quein lo lleva
					Unidad
					Numero economico
					Unidad
					Estatus en transito/
					Folio de Traspaso
					Que salga la cantidad de inventario de la etiqueta en asiganr marbete
					asignar marbete a las botella
					y de que marbete a marbete
					seleccionar el cliente, y las direcciones del cliente
					Si es sin marbete asignar el marbete
					Ok, asignar el marbete por rango
				</div -->
				<div class="col-6 col-md-4" *ngIf="!shipping_info.shipping.purchase_id">
					<label class="">Almacén Origen</label>
					<select name="from_store_id" [ngModel]="from_store_id" (ngModelChange)="onFromStoreChange($event)" class="form-control" [disabled]="shipping_info.shipping.status == 'SENT'  || !!(shipping_info?.shipping?.requisition_id) || (!rest.current_permission.global_send_shipping)" required>
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name | titlecase}}</option>
					</select>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Almacén Destino</label>
					<select name="to_store_id" [ngModel]="to_store_id" (ngModelChange)="onToStoreChange($event)" class="form-control" [disabled]="(!!shipping_info?.shipping?.requisition_id)" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name | titlecase}}</option>
					</select>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Fecha de envío</label>
					<input type="date" name="date" [(ngModel)]="shipping_info.shipping.date" class="form-control" placeholder="YYYY-mm-dd" [disabled]="shipping_info.shipping.status != 'PENDING'" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Nota</label>
					<input type="note" name="note" [(ngModel)]="shipping_info.shipping.note" class="form-control" placeholder="Motivo del envío">
				</div>
			</div>
		</div>
	</form>

	<h4	*ngIf="requisition_info">Requisición
      <b>#{{requisition_info.requisition.id}}</b>
	</h4>
	<div *ngIf="requisition_info" class="card p-3 shadow">
		<div class="row d-none d-md-flex">
			<div class="col-4 fw-bold">Artículo</div>
			<div class="col-4 text-end fw-bold">Cantidad Solicitada</div>
			<div class="col-3 text-end fw-bold">Cantidad a Enviar</div>
			<div class="col-1 text-end fw-bold">Acciones</div>
		</div>
		<div class="row border-bottom pb-2" *ngFor="let rii of requisition_info.items">
			<div class="col-12 col-md-4 fw-bold">
				<a routerLink="/list-item" [queryParams]="{item_id:rii.item.id}">
					{{rii?.category?.name | titlecase }} {{rii.item.name }}
				</a>
			</div>
			<div class="col-6 d-md-none fw-bold">Cantidad Solicitada</div>
			<div class="col-6 col-md-4 text-end">
				{{rii?.requisition_item?.qty | number }}
			</div>
			<div class="col-6 d-md-none fw-bold">Cantidad a enviar</div>
			<div class="col-6 col-md-3 text-end">
				{{qty_by_item_id[ rii.item.id ] || 0 | number }}
			</div>
			<div class="col-6 col-md-1 text-end">
			<button type="button" class="btn-primary" (click)="addRequistionItem(rii)">+</button>
		</div>
		</div>
	</div>
	<div class="card my-3 py-3 px-1">
		<div class="row">
			<div class="col-12 col-lg-4 form-group">
				<app-search-items (item_selected)="onItemSelected($event)" [reset_on_search]="true"></app-search-items>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row d-none d-lg-flex">
				<div class="col-6">Nombre</div>
				<div class="col-2 text-end">Disponibles</div>
				<div class="col-2 text-end">Cantidad</div>
				<div class="col-2 text-end">Acciones</div>
			</div>
			<div class="row align-items-center border-bottom mb-3 mb-1 pb-3" *ngFor="let sii of shipping_info.items;let i=index">
				<div class="col-9 col-lg-6 pb-3 px-1 pb-lg-0">
					<img [src]="rest.getImagePath(sii.item.image_id,sii?.category?.image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
					<a routerLink="/list-item" [queryParams]="{'csv.id':sii.item.id}">
						<span *ngIf="sii.category">{{sii.category.name | titlecase}}</span>
						{{sii.item.name | titlecase }}
					</a>
					<div *ngIf="sii.shipping_item.serial_number">#{{sii.shipping_item.serial_number}}</div>
				</div>
				<div class="col-3 col-lg-2 pb-3 px-1 pb-lg-0 order-lg-last">
					<button type="button" class="btn btn-primary w-100" (click)="removeItem(sii)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="none"><path d="M10 1.25a2.75 2.75 0 0 1 2.739 2.5H17a.75.75 0 0 1 .102 1.493L17 5.25h-.583L15.15 16.23A2 2 0 0 1 13.163 18H6.837a2 2 0 0 1-1.987-1.77L3.582 5.25H3a.75.75 0 0 1-.743-.648L2.25 4.5a.75.75 0 0 1 .648-.743L3 3.75h4.261A2.75 2.75 0 0 1 10 1.25zM8.5 7.5c-.245 0-.45.155-.492.359L8 7.938v6.125l.008.078c.042.204.247.359.492.359s.45-.155.492-.359L9 14.062V7.939l-.008-.08C8.95 7.656 8.745 7.5 8.5 7.5zm3 0c-.245 0-.45.155-.492.359L11 7.938v6.125l.008.078c.042.204.247.359.492.359s.45-.155.492-.359l.008-.079V7.939l-.008-.08c-.042-.203-.247-.358-.492-.358zM10 2.75c-.605 0-1.11.43-1.225 1h2.45c-.116-.57-.62-1-1.225-1z" fill="currentColor"/></g></svg>
						<span class="d-none d-md-inline">Eliminar</span>
					</button>
				</div>
				<div class="col-6 col-lg-2 text-lg-end px-1">
					<label class="d-lg-none">Disponibles</label>
					<div>{{sii.available | number}}</div>
				</div>
				<div class="col-6 col-lg-2 text-end px-1">
					<ng-container *ngIf="!sii.shipping_item.serial_number">
						<label class="d-lg-none">Cantidad</label>
						<input form="save-shipping" type="number" [name]="'shippint_item'+sii.shipping_item.item_id" [(ngModel)]="sii.shipping_item.qty" (ngModelChange)="updateValues()" class="form-control text-end" [step]="sii.item.partial_sale == 'NO'?'1':'any'" required>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="row p-3">
			<div class="col-12 text-end">
				<button form="save-shipping" type="submit" [disabled]="is_loading" class="btn btn-primary">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
					Guardar
				</button>
			</div>
		</div>
	</div>
</div>

<app-modal [(show)]="show_serial_numbers">
	<div class="p-3">
		<h2 *ngIf="selected_item_info">{{(selected_item_info.category? selected_item_info.category.name+' - ':'')}}{{selected_item_info.item.name}}</h2>
    </div>
	<input *ngIf="serial_list.length>5" type="text" [ngModel]="serial_search" (ngModelChange)="onSerialSearchChange($event)">
	<div>
		<div *ngFor="let s of tmp_serial_list" class="row">
			<div class="col">{{s.serial_info.serial.serial_number}}</div>
			<div class="col">
				<button type="button" (click)="addSerial(s.serial_info.serial.serial_number,s.item_info.item, s.item_info.category)">
					<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><path fill="currentColor" d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-24 104h-48v48a8 8 0 0 1-16 0v-48H72a8 8 0 0 1 0-16h48V72a8 8 0 0 1 16 0v48h48a8 8 0 0 1 0 16Z"/></svg>
				</button>
			</div>
		</div>
	</div>
</app-modal>
