import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Commanda_Type} from '../../models/RestModels';


@Component({
	selector: 'app-save-commanda-type',
	templateUrl: './save-commanda-type.component.html',
	styleUrls: ['./save-commanda-type.component.css']
})
export class SaveCommandaTypeComponent extends BaseComponent implements OnInit {

	commanda_type:Commanda_Type	= {};




	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			//this.company = this.rest.getCompanyFromSession();
			if( params.has('id') )
			{
				this.is_loading = true;
				this.subs.sink	= this.rest.commanda_type.get( params.get('id') )
				.subscribe((commanda_type)=>
				{
					this.is_loading = false;
					this.commanda_type= commanda_type;
				},(error)=>this.showError( error ));
			}
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.commanda_type.id	)
		{
			this.subs.sink	= this.rest.commanda_type.update( this.commanda_type ).subscribe((commanda_type)=>{
				this.is_loading = false;
				this.router.navigate(['/list-commanda-type']);
				this.showSuccess('El tipo de comanda se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.commanda_type.create( this.commanda_type ).subscribe((commanda_type)=>{
				this.is_loading = false;
				this.showSuccess('El tipo de comanda se guardó exitosamente');
				this.router.navigate(['/list-commanda-type']);
			},(error)=>this.showError(error));
		}
	}
}
