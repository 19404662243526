import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Store, Store_Sale_Report } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface CustomStoreSaleReport extends Store_Sale_Report
{
	store:Store;
}

@Component({
	selector: 'app-list-sales-report',
	templateUrl: './list-sales-report.component.html',
	styleUrls: ['./list-sales-report.component.css']
})
export class ListSalesReportComponent extends BaseComponent implements OnInit
{
	sales_store_report_list: CustomStoreSaleReport[] = [];
	store_list:Store [] = [];
	sales_store_report_search:SearchObject<CustomStoreSaleReport> = this.getEmptySearch();

	path = '/list-sales-report';

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let fields = ['store_id','date'];
				let search	= this.getSearch(response[0],fields,[]);
				this.sales_store_report_search = this.getSearch(response[0],fields,[]);

				search.sort_order = ['id_DESC'];

				return forkJoin({
					ssr: this.rest.store_sale_report.search( search ),
					store: this.rest.getStores()
				})
			})
		)
		.subscribe((response)=>
		{
			this.store_list = response.store.data;
			this.setPages(this.sales_store_report_search.page,response.ssr.total);

			this.sales_store_report_list = response.ssr.data.map
			(
				ssr=>
				{
					let store =	this.store_list.find(s=>ssr.store_id == s.id ) || GetEmpty.store();
					return { ...ssr, store };
				}
			);
		});
	}
}
