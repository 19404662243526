import { Component, OnInit } from '@angular/core';
import { RestResponse, SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, mergeMap} from 'rxjs/operators';
import {Category, Item, Item_Exception, Item_Option, Price} from '../../models/RestModels';
import {ParamMap} from '@angular/router';
import {ItemInfo, ItemOptionInfo, ItemOptionValueInfo} from 'src/app/models/models';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';

@Component
({
	selector: 'app-list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.css']
})

export class ListItemComponent extends BaseComponent implements OnInit
{
	file:File | null= null;
	show_import:boolean = false;
	item_search:SearchObject<Item> = this.getEmptySearch();
	item_info_list:ItemInfo[] = [];

	search_subject = new Subject<string>();

	category_list:Category[] = [];
	type:string = null;
	category_dictionary:Record<number,Category> = {}
	first:string[] = [];
	item_to_copy:ItemInfo | null = null;
	new_item_name:string = '';
	show_copy_item:boolean = false;
	selected_item: ItemInfo | null= null;
	show_image: boolean = false;
	category:Category | null = null;

	ngOnInit()
	{
		this.path = '/list-item';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap:ParamMap) =>
		{
			this.setTitle('Artículos');
			this.type = this.route.snapshot.params.type || null;
			this.loadData(queryParamMap);
		});
		this.subs.sink = this.search_subject.pipe
		(
			debounceTime(300)
		)
		.subscribe((search: string) =>
		{
			console.log(search);
			this.item_search.search_extra.category_name = search;
			this.item_search.page = 0;
			this.searchNoForceReload(this.item_search);
		}, (error) => this.showError(error));
	}

	loadData(params: ParamMap)
	{

		let fields = [ "id","image_id","category_id","on_sale","content",
			"content_measure_type","name","description","reference_price",
			"code","created_by_user","updated_by_user","created","updated"
		];

		let extra_keys: Array<string> = ['category_name',];
		this.item_search = this.getSearch(params, fields,extra_keys );

		console.log('Item search', this.item_search );
		this.is_loading = true;
		this.item_search.limit = this.page_size;
		this.current_page = this.item_search.page;

		this.is_loading = true;
		this.item_search.search_extra.category_type = this.type;
		this.item_search.sort_order = ['category_name_ASC'];
		this.item_search.eq.status = 'ACTIVE';
		this.item_search.search_extra['for_listings'] = 1;

		this.subs.sink = this.rest.item_info.search(this.item_search)
		.subscribe((responses)=>
		{

			if( this.item_search.eq.category_id )
			{
				if( responses.data.length )
					this.category = responses.data[0].category;
			}
			else
			{
				this.category = null;
			}

			//this.category_dictionary = Utils.createDictionary( responses.category.data, 'id' );
			this.item_info_list = responses.data;
			this.setPages( this.item_search.page, responses.total );
			//this.category_list = responses.category.data;
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	onFileChanged(event:any)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}

	//uploadFile()
	//{
	//	this.is_loading = true;

	//	if( this.file == null )
	//		return;

	//	Utils.xlsx2json( this.file,["name","extra_name","note_required","on_sale","availability_type","description","reference_price","clave_sat","unidad_medida_sat_id"])
	//	.then((json:Record<string,any>[])=>
	//	{

	//		let items:ItemInfo[] = json.map((i)=>{
	//			let item = {
	//				name: i.name,
	//				extra_name: i.extra_name as string,
	//				on_sale: i.on_sale,
	//				note_required: i.note_required,
	//				availability_type: i.availability_type,
	//				description: i.description,
	//				status: 'ACTIVE',
	//				reference_price: i.reference_price,
	//				clave_sat: i.clave_sat,
	//				unidad_medida_sat_id: i.unidad_medida_sat_id,
	//			} as Item;

	//			return {
	//				item,
	//				category:
	//				prices: [] as Price[],
	//				records: [] as Stock_Record[],
	//				options: [] as ItemOptionInfo[],
	//				exceptions:[] as Item_Exception[],
	//			}
	//		})
	//		//Filter json then upload
	//		this.subs.sink	= this.rest.item.batchUpdate(items as Partial<Item>[])
	//		.subscribe((result)=>
	//		{
	//			this.searchNoForceReload(this.item_search);
	//			this.showSuccess('Imported succesfully '+result.length+' items');
	//		},(error)=>this.showError(error));
	//	});
	//}

	showCopyItem(item_info:ItemInfo)
	{
		this.item_to_copy = item_info;
		this.show_copy_item = true;
	}

	copyItem()
	{
		this.is_loading = true;
		this.subs.sink = this.rest.item_info.get( this.item_to_copy.item.id )
		.subscribe
		({
			next:(response)=>
			{
				this.item_to_copy = response;
				this.copyCallback();
			},
			error:(_error)=>
			{
				this.showError('Ocurrio un error al cargar la informacion por favor intente de nuevo');
			}
		});
	}

	copyCallback()
	{
		this.is_loading = true;
		let item = {...this.item_to_copy.item, name: this.new_item_name };

		item.id = 0;
		item.code = '';

		let new_item:ItemInfo ={
			item: item,
			category: this.item_to_copy.category,
			exceptions:[],
			prices:[],
			records:[],
			serials: [],
			options:[]
		};

		new_item.exceptions = this.item_to_copy.exceptions.map((ie:Item_Exception)=>
		{
			let item_exeption:Item_Exception = {...ie};
			item_exeption.id = 0;
			return item_exeption;
		});

		new_item.item.id = null;
		new_item.options = this.item_to_copy.options.map((item_option_info:ItemOptionInfo)=>
		{
			let option_values:ItemOptionValueInfo[] = item_option_info.values.map((item_option_value_info:ItemOptionValueInfo)=>
			{
				let iovi:ItemOptionValueInfo = {
					item: item_option_value_info.item,
					item_option_value:
					{
						item_id: item_option_value_info.item_option_value.item_id,
						max_extra_qty: item_option_value_info.item_option_value.max_extra_qty,
						extra_price: item_option_value_info.item_option_value.extra_price,
						charge_type: item_option_value_info.item_option_value.charge_type,
						price: item_option_value_info.item_option_value.price
					}
				}
				return iovi;

			});

			let item_option:Item_Option = {
				item_id: item_option_info.item_option.item_id,
				name: item_option_info.item_option.name,
				included_options: item_option_info.item_option.included_options,
				max_options: item_option_info.item_option.max_options,
				min_options: item_option_info.item_option.min_options,
				min_selections: item_option_info.item_option.min_selections,
				included_extra_qty: item_option_info.item_option.included_extra_qty,
				max_extra_qty: item_option_info.item_option.max_extra_qty
			};

			let ioi:ItemOptionInfo = {
				item_option,
				values: option_values
			};
			return ioi;
		})

		new_item.exceptions = this.item_to_copy.exceptions.map((item_exeption)=>
		{
			return { ...item_exeption, id:0 };
		});

		this.subs.sink = this.rest.item_info.create( new_item ).subscribe((item)=>
		{
			console.log( item );
			this.showSuccess('El artículo se copio con exito')
			this.is_loading = true;
			let prices:Price[] = this.item_to_copy.prices.map((p:Price)=>
			{
				let np:Price = {
					item_id : item.item.id,
					price_list_id: p.price_list_id,
					price_type_id: p.price_type_id,
					tax_included: p.tax_included,
					percent: 0,
					price: p.price,
					currency_id: p.currency_id,
					created: new Date(),
					updated: new Date()
				};
				return np;
			});

			this.subs.sink = this.rest.price
			.batchCreate( prices )
			.subscribe(()=>
			{
				this.showSuccess('Los precios se copiaron exitosamente al nuevo artículo');
				this.is_loading = false;
				this.show_copy_item = false;
				this.search(this.item_search);
			},(error)=>
			{
				this.showError('El artículo se guardó exitosamente, Los precios no puediron guardarse. '+error);
				this.search(this.item_search);
				this.is_loading = false;
				this.show_copy_item = false;
			});
		},(error)=>{
			this.showError(error)
		});
	}

	showConfirmDelete(item_info:ItemInfo)
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert(item_info, "Eliminar Artículo", "Esta seguro de querer eliminar el articulo?")
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.item_info
				.delete(response.obj.item)
				.subscribe(()=>
				{
					let index = this.item_info_list.findIndex((item)=>
					{
						return item.item.id == response.obj.item.id;
					});
					this.item_info_list.splice(index,1);
					this.showSuccess('El artículo se elimino con exito');
				},(error)=>{
					this.showError(error);
				});
			}
		});
	}
	onSearch(evt: any)
	{
		let search: string = evt.target.value;
		this.search_subject.next(search);
	}
	custom_search(search_object:SearchObject<Item>)
	{
		if( search_object.csv?.id )
		{
			search_object.csv.id = [];
			//search_object.csv.status = ['ACTIVE'];
		}
		this.search( search_object );
	}
	showImage(item_info:ItemInfo)
	{
		this.selected_item = item_info;
		this.show_image = true;
	}

	exportFile()
	{
		let SEARCH_LIMIT = 100;

		let search:SearchObject<Item> = this.getEmptySearch();
		search.limit = SEARCH_LIMIT;
		search.eq.status = 'ACTIVE';

		this.subs.sink = this.rest.item_info
		.search( search )
		.pipe
		(
			mergeMap((response)=>
			{
				let observables = [ of(response) ];

				let items_promises:Observable<RestResponse<ItemInfo>>[] = [];
				let pages_needed = Math.ceil( response.total/SEARCH_LIMIT );

				for(let i=1;i<pages_needed;i++)
				{
					let s:SearchObject<Item> = this.getEmptySearch();
					s.limit = SEARCH_LIMIT;
					s.page = i;
					observables.push( this.rest.item_info.search( s ) );
				}

				return forkJoin(observables);
			}),
			mergeMap((response)=>
			{
				let items = response.reduce((p,c)=>
				{
					p.push(...c.data );
					return p;
				},[]);

				let item_response:RestResponse<ItemInfo> = {
					total: items.length,
					data: items
				};

				return forkJoin
				({
					items: of( item_response ),
					price_type: this.rest.getPriceTypes(false),
					price_list: this.rest.getPriceLists( false ),
					store: this.rest.getStores(false)
				})
			})
		)
		.subscribe((response)=>
		{

			let data:Record<string,string|number>[] = [];

			let headers = [
				'Id','Artículo',
				'Id Categoría',
				'Categoría',
				'Precio Referencia',
				'Fecha Creación', 'Última Actualización'
			];
			let pl_list = response.price_list.data;
			let pt_list = response.price_type.data;
			let store_list = response.store.data;

			for(let price_list of pl_list)
			{
				for(let price_type of pt_list )
				{
					let name = 'Precio '+price_list.name+' - '+price_type.name;
					headers.push( name );
				}
			}

			for(let s of store_list)
			{
				headers.push( 'Inventario '+s.name);
			}


			for(let item_info of response.items.data)
			{
				let category_id = '';
				let category_name = '';

				if( item_info.category )
				{
					category_id = '';
					category_name = item_info.category.name;
				}

				item_info.category;
				let obj =
				{
					'Id': item_info.item.id,
					'Artículo': item_info.item.name,
					'Código' : item_info.item.code,
					'Id Categoría': category_id,
					'Categoría': category_name,
					'Precio Referencia': item_info.item.reference_price,
					'Fecha Creación': Utils.getLocalMysqlStringFromDate( item_info.item.created ),
					'Última Actualización': Utils.getLocalMysqlStringFromDate( item_info.item.updated ),
					'Tags': item_info.item.json_tags.join(','),
				}

				for(let pl of pl_list)
				{
					for(let pt of pt_list )
					{
						let p = item_info.prices.find(p=>p.price_list_id==pl.id && pt.id == p.price_type_id);
						let name = 'Precio '+pl.name+' - '+pt.name;
						let price = p ? p.price : '';
						obj[ name ] = price;
					}
				}

				for(let s of store_list)
				{
					let record = item_info.records.find(sr=>sr.store_id == s.id );

					obj['Inventario '+s.name] = record?record.qty: 0;
				}
				console.log( obj );
				data.push( obj );
			}

			ExcelUtils.array2xlsx(data,'Artículos.xlsx',headers)
		},(error)=>this.showError(error));
	}
}
