<!--ngx-charts-line-chart
	[scheme]="waiter_order_graph.scheme"
	[legend]="waiter_order_graph.legend"
	[showXAxisLabel]="waiter_order_graph.showXAxisLabel"
	[showYAxisLabel]="waiter_order_graph.showYAxisLabel"
	[xAxis]="waiter_order_graph.xAxis"
	[yAxis]="waiter_order_graph.yAxis"
	[xAxisLabel]="waiter_order_graph.xAxisLabel"
	[yAxisLabel]="waiter_order_graph.yAxisLabel"
	[timeline]="waiter_order_graph.timeline"
	[results]="waiter_order_graph.results">
</ngx-charts-line-chart -->
<div class="container-fluid">
	<div class="my-3">
		<div class="row align-items-end">
			<div class="col">
				<h1>Ordenes por mesero</h1>
			</div>
			<div class="col-auto">
				<button class="btn btn-secondary" type="button" (click)="export()">Agregar</button>
			</div>
		</div>
	</div>


	<div class="card p-3">
    <div style="min-height:400px;">
			<ngx-charts-area-chart-stacked
				[scheme]="waiter_order_graph.scheme"
				[legend]="waiter_order_graph.legend"
				[showXAxisLabel]="waiter_order_graph.showXAxisLabel"
				[showYAxisLabel]="waiter_order_graph.showYAxisLabel"
				[xAxis]="waiter_order_graph.xAxis"
				[yAxis]="waiter_order_graph.yAxis"
				[xAxisLabel]="waiter_order_graph.xAxisLabel"
				[yAxisLabel]="waiter_order_graph.yAxisLabel"
				[timeline]="waiter_order_graph.timeline"
				[results]="waiter_order_graph.results">
			</ngx-charts-area-chart-stacked>
    </div>
	</div>
	<div class="card p-3 my-3">
		<table class="w-100">
			<thead>
				<tr>
					<th>Nombre</th>
					<th class="text-end" *ngFor="let d of days ">{{d.substring(8)}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of waiter_order_graph.results">
					<td class="border">{{r.name}}</td>
					<td class="border text-end" *ngFor="let s of r.series">
						<span *ngIf="s.value>=1">{{s.value | number:'1.0-0'}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
