<ng-container *ngIf="order_info">
	<ng-container *ngFor="let i of copies">
		<div class="break_pages px-0" style="background-color:white;color:black">
			<div class="text">
				<img [src]="rest.getImagePath(order_info.store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" class="image_logo_ticket">
			</div>
			<div class="d-print-none text-center">
				<button type="button" class="btn btn-primary m-3" (click)="justPrint()">Imprimir</button>
				<button type="button" class="btn btn-primary m-3" (click)="location.back()">Regresar</button>
			</div>
			<div class="px-0">
				<div class="fw-bold">Sucursal: {{order_info.store.name}}</div>
				<div class="pb-1" *ngIf="billing_data == null">
					<div>{{order_info.store.business_name}}</div>
					<div *ngIf="order_info.store.rfc">{{order_info.store.rfc}}</div>
					<div>{{order_info.store.name}}</div>
					<div *ngIf="order_info.store.address">{{order_info.store.address}}</div>
					<div *ngIf="order_info.store.city">{{order_info.store.city | titlecase}}&nbsp;</div>
					<div *ngIf="order_info.store.state">{{order_info.store.state | titlecase}}&nbsp;</div>
					<div *ngIf="order_info.store.zipcode || order_info.store.phone">
						<div *ngIf="order_info.store.zipcode">CP: {{order_info.store.zipcode| titlecase}}.&nbsp;</div>
						<div *ngIf="order_info.store.phone">Tel:{{order_info.store.phone}}</div>
					</div>
				</div>
				<div class="pb-1" style="text-transform:uppercase" *ngIf="billing_data">
					<div>{{billing_data.razon_social}} {{billing_data.regimen_capital}}</div>
					<div>{{billing_data.rfc}}</div>
					<span *ngIf="billing_data.address">{{billing_data.address}}</span>
					<span *ngIf="billing_data.city">{{billing_data.city | titlecase}}</span>
					<span *ngIf="billing_data.state">{{billing_data.state| titlecase}}</span>
					<span>{{billing_data.zipcode | titlecase}}&nbsp;</span>
					<span *ngIf="order_info.store.phone">Tel:{{order_info.store.phone}}</span>
				</div>
				<h1 *ngIf="order_info.order.status=='CANCELLED'" class="text-center">ORDEN CANCELADA</h1>
				<h2 class="text-center text-black">{{service_type_dictionary[order_info.order.service_type]}}</h2>
				<div class="" *ngIf="order_info.order.sync_id && !order_info.order.id ">
					IDOF:<span class="fw-bold">#{{order_info.order.sync_id}}</span>
				</div><div class="" *ngIf="order_info?.order?.client_name">
					Cliente: <span class="fw-bold monospace">{{order_info.order?.client_name | uppercase}}</span>
				</div>
				<div class="" *ngIf="table">
					Mesa:<span class="fw-bold">#{{table.name | uppercase}}</span>
				</div>
				<ng-container *ngIf="currency_rate && currency_rate.rate > 1">{{('Tipo de cambio '+currency_rate.currency_id+':')|ticketString:15}}<b>{{(currency_rate.rate | currency )|ticketString:15:'Start'}}</b></ng-container>
<pre class="prelabel fw-bold mb-0">{{('ID:'+order_info.order.id) | ticketString:16}}{{('FOLIO:#'+order_info.order.store_consecutive) | ticketString : 14 : 'Start'}}
Fecha: <span class="fw-bold monospace">{{order_info.order.created | shortDate:'full' | ticketString:23:'Start'}}</span></pre>
				<!--4_15$8-->
<pre class="prelabel mb-0" >
<b>{{'Artículo' | ticketString: 15}}{{'Cant' | ticketString: 6 : 'Start' }}{{'Subt' | ticketString: 9 : 'Start' }}</b>
<ng-container  *ngFor="let oii of order_info.resume_of_items">{{((rest.local_preferences.display_categories_on_items == 'YES' && oii.category ? oii.category.name+'-':'')+oii.item.name) | ticketString:15}}{{(oii.order_item.qty|number) | ticketString: 6 : 'Start' }}{{(oii.total_cost|currency:'$') | ticketString: 9 : 'Start' }}<ng-container *ngIf="oii.total_options">
extras: {{oii.total_options}}
</ng-container>
</ng-container>{{'Subtotal' | ticketString: 15}}{{((order_info.order.total)| currency:'$') | ticketString: 15:'Start'}}
<b>{{'Total' | ticketString: 15}}{{((order_info.order.total-order_info.order.discount)| currency:'$') | ticketString: 15:'Start'}}</b>
<span *ngIf="(order_info.order.discount + article_discount) > 0">{{'Descuento aplicado' | ticketString:15}}{{ (order_info.order.discount + article_discount | currency:'$') | ticketString:15:'Start'}}</span>
<ng-container *ngIf="store.suggested_tip">{{'Propina Sugerida:' | ticketString: 17}}{{((order_info.order.total-order_info.order.discount)*(store.suggested_tip/100)| currency:'$') | ticketString: 13:'Start'}}
</ng-container><ng-container *ngFor="let bmi of movements">{{bmi.transaction_label+(bmi.currency_id == 'MXN'?'': '('+(bmi.exchange_rate|currency:'$')+')') | ticketString:15:'Start'}}{{(bmi.amount_received |currency:'$')|ticketString:15:'Start'}}
</ng-container><b *ngIf="payment_info">Total Recibido:{{(payment_info.payment.received_amount | currency:'$')|ticketString:15:'Start'}}</b>
<ng-container *ngIf="payment_info?.payment"><b>{{'Cambio' | ticketString: 15}}{{(payment_info.payment.change_amount | currency:'$')+' MXN'| ticketString:15:'Start'}}</b></ng-container>
<ng-container *ngIf="payment_info?.payment">{{'Cambio en: '+currency_rate.currency_id|ticketString:15}}{{(payment_info.payment.change_amount/currency_rate.rate | currency:'$')|ticketString:15:'Start'}}</ng-container>
</pre>
			</div>
			<ng-container *ngIf="order_info.order.status == 'CLOSED'">
				<div class="text-center">
					<div id="markdown" [innerHTML]="order_info?.store?.ticket_footer_text | marked"></div>
					<div *ngIf="show_bill_code">
						Código Facturación:<br>
						<div style="font-size:20px;color:#000000;text-transform:uppercase">{{order_info.order.facturacion_code}}</div>
					</div>
				</div>
				<div *ngIf="show_bill_code && store.show_facturacion_qr == 'YES'">
					<ngx-qrcode
						[elementType]="element_type"
						[value]="url_for_facturacion"
						[cssClass]="order_info.store.qr_size"
						[scale]="5"
						[errorCorrectionLevel]="error_correction_level">
					</ngx-qrcode>
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-container>
