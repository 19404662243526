import { AfterViewChecked, Component, OnInit } from '@angular/core';
import {forkJoin, from, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo, PaymentInfo} from 'src/app/models/models';
import {Billing_Data, Currency_Rate, Payment } from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {environment} from 'src/environments/environment';
import {BaseComponent} from '../base/base.component';
import Hashids from 'hashids';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
	selector: 'app-print-receipt2',
	templateUrl: './print-receipt2.component.html',
	styleUrls: ['./print-receipt2.component.css']
})
export class PrintReceipt2Component extends BaseComponent implements OnInit, AfterViewChecked
{
	payment_info:PaymentInfo = null;
	order_info:OrderInfo | null = null;
	print_and_close:boolean = false;
	print_copies:number = 1;
	copies:number[]=[1];
	that:any = this;
	discount:number = 0;
	force_close:boolean = false;
	has_dolars:boolean = false;
	currency_rate:Currency_Rate | null = null;
	billing_data:Billing_Data | null = null;

	url_for_facturacion:string = 'https://'+window.location.hostname+'/facturar';
	element_type = NgxQrcodeElementTypes.URL;
	error_correction_level: NgxQrcodeErrorCorrectionLevels.HIGH;



	hashids = new Hashids
	(
		environment.app_settings.hashids_salt,
		environment.app_settings.hashids_length,
		environment.app_settings.hashids_alphabet
	);

	legend:Record<string,string> = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'T. Credito',
		'DEBIT_CARD-MXN':'T. Debito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
		'RETURN_DISCOUNT-MXN':'Descuento',
	}

	service_type_dictionary:Record<string,string> = {
		'QUICK_SALE': '',
		'TOGO':'PARA LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'RECOGER',
	}

	beforeprint = ()=>
	{
		console.log('beforeprint');
		if( this.rest.printer_ticket_config )
		{
			document.getElementById('print-config').innerHTML = this.rest.printer_ticket_config;
			return;
		}

		document.getElementById('print-config').innerHTML = '';
	};

	afterprint = function(){
		console.log('afterprint');
		document.getElementById('print-config').innerHTML = '';
	};

	is_mobile: boolean = false;
	show_bill_code: boolean = true;

	ngOnDestroy(): void {
		window.removeEventListener('beforeprint', this.beforeprint);
		window.removeEventListener('afterprint', this.afterprint);
		super.ngOnDestroy();
	}

	ngAfterViewInit()
	{

	}

	ngAfterViewChecked()
	{
	}

	ngOnInit(): void
	{
		let regex = /mobi/i;

		this.is_mobile = regex.test(navigator.userAgent);
		window.addEventListener('beforeprint' , this.beforeprint);
		window.addEventListener('afterprint' , this.afterprint );

		this.subs.sink = this.route.paramMap.subscribe((paramMap)=>
		{
			this.currency_rate = null;
			this.print_and_close = paramMap.has('print');
			this.print_copies = paramMap.has('copies') ? parseInt(paramMap.get('copies')) : 1;
			this.force_close = paramMap.has('print') ? paramMap.get('print') == "1" || paramMap.get('print')=='print' : false;

			console.log('Printt has', paramMap.get('print') );

			console.log('Print copies has', this.copies );
			let c = new Array( this.print_copies );
			c.fill(1);
			this.copies = c;

			let search:SearchObject<Payment> = this.getEmptySearch();
			search.search_extra = {order_id:paramMap.get('order_id')};
			search.limit = 1;
			search.sort_order=['id_ASC'];

			console.log("Se inicializo cuantas veces");

			let something = paramMap.has('order_id') ? paramMap.get('order_id') : paramMap.get('order_sync_id');

			this.setTitle('ReciboOrden-'+something);

			if( paramMap.has('order_id') )
			{
				this.subs.sink = this.rest.order_info.get( paramMap.get('order_id') ).pipe
				(
					mergeMap((response)=>
					{
						return forkJoin
						({
							order: of( response ),
							store: this.rest.getStore( response.order.store_id ),
							payment_info: this.rest.payment_info.search( search ),
							stores: this.rest.getStores(true),
							billing_data: this.rest.getBillingData(),
							price_type: this.rest.getPriceTypes()
						})
					})
				)
				.subscribe((responses)=>
				{
					responses.order.items.forEach((oii)=>
					{
						oii.serials_string = oii.serials
							.map((i)=>i.serial.serial_number)
							.join(',');
					});


					this.rest.printer_ticket_config = responses.store.printer_ticket_config;
					this.rest.createStructuredItems( responses.order );
					this.billing_data = responses.billing_data.data.find(bd=>bd.id == responses.store.id) || null;

					responses.order.price_type = responses.price_type.data.find((pt)=>pt.id == responses.order.order.price_type_id);

					this.order_info = responses.order;
					this.setShowCode();
					this.discount = responses.order.items.reduce((p,i)=>p+i.order_item.discount,0);

					if( responses.payment_info.data.length )
					{
						this.payment_info= responses.payment_info.data[0];
					}

					if( this.payment_info?.movements )
					{
						dance:
						for(let bmi of this.payment_info.movements)
						{
							for(let bmo of bmi.bank_movement_orders )
							{
								if( bmo.currency_id != this.order_info.store.default_currency_id )
								{
									this.currency_rate = {
										store_id: responses.order.store.id,
										currency_id: bmo.currency_id,
										rate: bmo.exchange_rate
									};
									break dance;
								}
							}
						}
					}

					if( this.print_and_close)
					{
						console.log('Pringint');
						setTimeout(()=>{
								window.print();
								if( !this.is_mobile )
									window.close();
						},700);
					}
				},(error)=>this.showError(error));
			}
			else
			{
				console.log('Leyendo ooffline db order sync id is ', paramMap.get('order_sync_id') );
				this.subs.sink = forkJoin
				({
					order: from( this.rest.getOfflineOrderInfoBySyncId( paramMap.get('order_sync_id') )),
					payment: from(this.rest.getOfflinePaymentByOderSyncId( paramMap.get('order_sync_id') )),
					stores: this.rest.getStores(true),
					price_type: this.rest.getPriceTypes()
				})
				.subscribe((response)=>
				{
					try
					{
						this.payment_info =	response.payment;

						response.order.price_type = response.price_type.data.find((pt)=>pt.id == response.order.order.price_type_id);
						this.order_info = response.order;
						this.setShowCode();

						if( this.payment_info.movements )
						{
							dance:
							for(let bmi of response.payment.movements)
							{
								for(let bmo of bmi.bank_movement_orders )
								{
									if( bmo.currency_id != this.order_info.store.default_currency_id )
									{
										this.currency_rate = {
											store_id: response.order.store.id,
											currency_id: bmo.currency_id,
											rate: bmo.exchange_rate
										};
										break dance;
									}
								}
							}
						}

						console.log('Buscqando order ',response );
						//this.order_info = order_info;
						console.log('payment info ',this.payment_info);

						if( this.force_close)
						{
							console.log('Pringint');
							setTimeout(()=>{
								window.print();
								window.close();
							},700);
						}
					}
					catch(e)
					{
						console.error('error ocurrido', e );
						this.showError('Ocurrio un error aqui');
					}
				});
			}
		});
	}

	/*
1-1643157008003
1-1643157096411
1-1643157134876
*/

	facturar(_evt:Event)
	{
		let url = this.rest.getUrlBase()+'/facturar.php?id='+this.order_info.order.id;

		this.subs.sink = this.rest
			.getUrlAsString( url )
			.subscribe((response)=>
			{
				this.showSuccess('Exito');
			},(error)=>
			{
				this.showError(error);
			})
	}

	justPrint()
	{
		window.print();
	}
	closeWindow()
	{
		window.close();
	}

	setShowCode()
	{
	//Original Comparison
	//order_info && order_info.store.autofacturacion_settings != 'DISABLED' && order_info.store.show_facturacion_qr == 'YES'"
	//order_info && order_info.store.autofacturacion_settings != 'DISABLED' && order_info?.order?.paid_status == 'PAID' && order_info.store.default_billing_data_id
		this.show_bill_code	=	this.order_info	&&
			this.order_info.store.autofacturacion_settings	!=	'DISABLED'	&&
			this.order_info.store.default_billing_data_id	&&
			this.order_info?.price_type?.show_bill_code	!=	'NO'
	}
}
