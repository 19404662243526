<div>
	<div class="row-cart">
		&nbsp;
		<a class="cart-link mt-1 text-end" routerLink="/c-cart">
			<svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"/><circle cx="10.5" cy="19.5" r="1.5" fill="currentColor"/><circle cx="17.5" cy="19.5" r="1.5" fill="currentColor"/></svg>
			<span class="number_indicator" *ngIf="cart_items">{{cart_items}}</span>
		</a>
	</div>
	<div class="categories-container d-flex flex-wrap justify-content-evenly "><!--categories-container-->
		<button type="button" class="btn btn-link category-item align"
				*ngFor="let category of category_list"
				[ngStyle]="{'background-color': 'white', 'background-image': 'url('+this.rest.getImagePath( category.image_id)+')' }"
				[routerLink]="['/c-select-category-item',category.id]">
			<div class="text text-wrap overflow:hidden;">{{category.name | titlecase}}</div>
		</button>
	</div>
</div>
