<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col-8">
			<h1>Editar Precios
				<span class="fw-bold" *ngIf="price_list">{{price_list.name | uppercase}}</span>
			</h1>
		</div>
		<div class="col-4 text-end">
			<a class="btn btn-primary" *ngIf="price_list" [routerLink]="['/list-category-price',price_list.id]" >Por Categoría</a>
		</div>
	</div>

	<div class="card my-3 px-1 px-md-3 py-3">
		<div class="row">
			<div class="12 form-group">
				<label class="">Artículo</label>
				<input type="text" name="name" [ngModel]="item_search.search_extra.category_name" placeholder="Artículo..." class="form-control" autocomplete="off" (input)="onSearch($event)">
			</div>
		</div>
	</div>

	<!--div class="card px-1 px-lg-3 py-3 my-3">
		<div class="row">
			<div class="col-8 col-md-3 form-group">
				<label>Nombre</label>
				<input type="text" class="form-control" name="search_term" [(ngModel)]="search_term">
			</div>
			<div class="col-4 col-md-2 form-group">
				<label>&nbsp;</label>
				<div>
					<button class="btn btn-primary" (click)="search(this.item_search )">Buscar</button>
				</div>
			</div>
		</div>
	</div-->
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="card p-3 py-3">
		<!--div class="d-none d-xl-flex row">
			<div class="col-4 fw-bold">Artículo</div>
			<div class="col-8 fw-bold">
				<div class="d-flex justify-content-end">
					<div class="mx-0 input-container" *ngFor="let pt of price_type_list">{{pt.name | titlecase }}</div>
				</div>
			</div>
		</div-->
		<table class="table" border=1>
			<tbody>
				<ng-container *ngFor="let item_info of item_info_list">
					<ng-container *ngFor="let price of item_info.prices;let i=index">
						<tr>
							<th *ngIf="i==0" [rowSpan]="price_type_list.length" class="border">
								<ng-container *ngIf="item_info?.category && rest.local_preferences.display_categories_on_items == 'YES'">
									{{item_info.category.name}} -
								</ng-container>
								{{item_info.item.name | titlecase}}
							</th>
							<td class="align-middle">{{price_type_list[i].name | titlecase}}</td>
							<td class="input-group">
								<input *ngIf="price_type_list[i].model == 'AMOUNT'" type="number" class="form-control" [name]="'pprice_'+item_info.item.id+'-'+price_type_list[i].id" [ngModel]="price.price" (ngModelChange)="priceChange($event,price)" min="0" step="any" required>
								<span *ngIf="price_type_list[i].model == 'AMOUNT'" class="input-group-text">$</span>
								<input *ngIf="price_type_list[i].model == 'PERCENT'" type="number" class="form-control" [name]="'ppercent'+item_info.item.id+'-'+price_type_list[i].id" [ngModel]="price.percent" (ngModelChange)="percentChange($event,price,item_info)" min="0" step="any" required>
								<span *ngIf="price_type_list[i].model == 'PERCENT'" class="input-group-text">%</span>
							</td>
							<td class="align-middle">
								<select *ngIf="price_type_list[i].tax_model=='ALL'" [name]="'tax-'+item_info.item.id+'-'+price_type_list[i].id" [ngModel]="price.tax_included" (ngModelChange)="taxModelChange($event,price)" class="form-control" required>
									<option value="YES">IVA Incluido</option>
									<option value="NO">Más IVA</option>
								</select>
								<span *ngIf="price_type_list[i].tax_model != 'ALL'">
									{{price_type_list[i].tax_model=='PLUS_TAX'?'Mas IVA':'IVA Incluido'}}
								</span>
							</td>
							<td>
								{{price_type_list[i].model == 'PERCENT' ? (item_info.item.reference_price * (1+(price.percent/100)) | currency ):''}}
							</td>
							<td *ngIf="rest.local_preferences.currency_price_preference == 'MULTIPLE_CURRENCY'" required>
								<select [name]="'currency'+item_info.item.id+'-'+price.price_type_id" [(ngModel)]="price.currency_id" class="form-control"  (ngModelChange)="currencyChange($event,price)">
									<option *ngFor="let currency of currency_list" [value]="currency.id">{{currency.id}} - {{currency.name | uppercase}}</option>
								</select>
								<!--input type="text" class="form-control" min="0" step="any" list="tipo_precio" minlength="3" maxlength="3" [name]="'currr'+item_info.item.id+'-'+price_type_list[i].id" [ngModel]="price.currency_id" (ngModelChange)="currencyChange($event,price)" placeholder="moneda" required-->
							</td>
						</tr>
					</ng-container>
				</ng-container>
			</tbody>
		</table>
	</div>
	<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
