import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { mergeMap } from 'rxjs/operators';
import { Bill, Currency_Rate, Store, User } from 'src/app/models/RestModels';
import { GetEmpty } from 'src/app/classes/Empties';
import { BankMovementBillInfo, BankMovementInfo, BillInfo, PaymentInfo } from 'src/app/models/models';
import { forkJoin } from 'rxjs';
import { Utils } from 'src/app/classes/Utils';

interface CBillInfo extends BillInfo
{
	selected:boolean;
}

@Component({
  selector: 'app-list-provider-bills',
  templateUrl: './list-provider-bills.component.html',
  styleUrls: ['./list-provider-bills.component.css']
})
export class ListProviderBillsComponent extends BaseComponent implements OnInit {

	provider:User = GetEmpty.user();
	cbill_info_list:CBillInfo[] = [];
	currency_rate_list:Currency_Rate[] = [];
	user_store:Store = GetEmpty.store();

	show_make_payment:boolean = false;
	payment_amount:number = 0;
	selected_bill_info_list:BillInfo[] = [];

	toggle_all:boolean = true;

	total:number = 0;
	pending:number = 0;
	total_to_pay:number = 0;
	total_to_pay_currency_id:string = '';

	ngOnInit(): void {

		this.is_loading = true;

		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((params) => {

				let provider_id = Number(params.get('id'));
				return forkJoin
				({
					provider: this.rest.user.get(provider_id),
					bill_info: this.rest.bill_info.search({ eq: {provider_user_id: provider_id, paid_status: 'PENDING'}}),
					currency_rate: this.rest.getCurrencyRates(),
					user_store: this.rest.store.get(this.rest.current_user.store_id),
				})
			}),
		)
		.subscribe({
			next: (response) => {

				this.setTitle('Compras de ' + response.provider.name);

				this.provider = response.provider;
				this.currency_rate_list = response.currency_rate.data;
				this.user_store = response.user_store;

				this.cbill_info_list = response.bill_info.data.map((bill_info:BillInfo) =>
				{
					return {...bill_info, selected:true};
				});

				this.cbill_info_list.forEach((bill_info:CBillInfo) =>
				{
					if( bill_info.selected )
					{
						this.total += (bill_info.bill.total / this.getExchangeRate(this.user_store.default_currency_id, bill_info.bill));

						this.pending += (bill_info.bill.total - bill_info.bill.amount_paid) / this.getExchangeRate(this.user_store.default_currency_id, bill_info.bill);
					}
				});

				this.calculateTotals();

				this.selected_bill_info_list = this.cbill_info_list.filter((bill_info:CBillInfo) => bill_info.selected);

				this.is_loading = false;
			},
			error: (error) => {
				this.showError(error);
			},
		})
	}

	getExchangeRate(currency_id:string, bill:Bill):number
	{
		if( bill.currency_id == currency_id )
			return 1;

		let default_currency_id = this.user_store.default_currency_id;

		let currency_rate = this.currency_rate_list.find((r:Currency_Rate)=>
		{
			if( r.store_id != bill.store_id )
				return false;

			if( bill.currency_id == default_currency_id )
			{
				return r.currency_id == currency_id;
			}

			return r.currency_id == bill.currency_id;
		});

		return default_currency_id == bill.currency_id
			? currency_rate.rate
			: 1/currency_rate.rate;
	}


	calculateTotals()
	{
		let reduce_currencies = (p,bi) =>
		{
			if( !p.includes(bi.bill.currency_id) )
			{
				p.push(bi.bill.currency_id);
			}

			return p;
		};

		let currencies_ids = this.cbill_info_list
			.filter(bi=>bi.selected)
			.reduce( reduce_currencies, [] );

		this.total_to_pay_currency_id = currencies_ids.length == 1 ? currencies_ids[0] : this.user_store.default_currency_id;
		this.total_to_pay = 0;

		this.cbill_info_list.forEach((bill_info:CBillInfo) =>
		{
			if( bill_info.selected )
			{
				this.total_to_pay += (bill_info.bill.total - bill_info.bill.amount_paid) / this.getExchangeRate(this.total_to_pay_currency_id, bill_info.bill);
			}
		});
	}

	someChecked():boolean
	{
		return !this.cbill_info_list.some(bill_info=>!bill_info.selected);
	}

	onToggleAll(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		let value = input.checked ? 1 : null;

		this.cbill_info_list.forEach((bill_info:CBillInfo) => bill_info.selected = value ? true : false);

		this.calculateTotals();
	}

	onToggleCheck(bill_info:CBillInfo)
	{
		bill_info.selected = !bill_info.selected;
		this.calculateTotals();
	}

	onPay()
	{
		this.selected_bill_info_list = this.cbill_info_list.filter((bill_info:CBillInfo) => bill_info.selected);

		if( this.selected_bill_info_list.length == 0)
		{
			this.showError('No hay ninguna orden seleccionada');
			return;
		}

		let first_currency_id = this.selected_bill_info_list[0].bill.currency_id;

		for(let bi of this.selected_bill_info_list)
		{
			if( bi.bill.currency_id != first_currency_id )
			{
				this.showError('Las ordenes seleccionadas deben ser del mismo tipo de moneda');
				return;
			}
		}

		this.payment_amount = 0;
		this.total_to_pay_currency_id = first_currency_id;
		this.show_make_payment = true;
	}

	makePayment()
	{
		if( this.rest.is_offline )
		{
			this.showError('Opcion no soportada todavia');
			return;
		}

		//validar que la cantidad no sea mayor al total a pagar
		if (this.payment_amount > this.total_to_pay)
		{
			this.showError('La cantidad a pagar no puede ser mayor al total a pagar');
			return;
		}

		this.is_loading = true;

		let first_bill = this.selected_bill_info_list[0].bill;

		let concept = 'Pago por orden de compra ';

		this.selected_bill_info_list.forEach((bill_info:CBillInfo) =>
		{
			concept += '#' + bill_info.bill.id + ', ';
		});

		//remover la ultima coma (dummy)
		concept = concept.substring(0, concept.length - 2);

		let payment_info:Partial<PaymentInfo> =
		{
			payment:{
				type:'expense',
				tag:'Compra',
				payment_amount: this.payment_amount,
				concept,
				received_amount: this.payment_amount,
				facturado: 'NO',
				change_amount: 0,
				exchange_rate: 1,
				currency_id: first_bill.currency_id,
				paid_by_user_id: this.rest.current_user.id,
				sat_pdf_attachment_id: null,
				sat_uuid: null,
				sat_xml_attachment_id: null,
				sync_id: this.rest.getSyncId()
			},
			movements: []
		}

		let bm:BankMovementInfo = {
			bank_movement:{
				amount_received: this.payment_amount,
				bank_account_id: null,
				card_ending: '',
				client_user_id: null,
				created:new Date(),
				currency_id: first_bill.currency_id,
				id: null,
				invoice_attachment_id: null,
				note: '',
				origin_bank_name: '',
				paid_date: Utils.getMysqlStringFromLocalDate( new Date() ).substring(0,10),
				payment_id: null,
				provider_user_id: first_bill.provider_user_id || null,
				receipt_attachment_id: null,
				received_by_user_id: null,
				reference: '',
				status: 'ACTIVE',
				exchange_rate: 1,
				total: this.payment_amount,
				transaction_type: null,
				type:'expense',
				updated:new Date()
			},
			bank_movement_bills: []
		}

		let remaining = this.payment_amount;

		for(let bi of this.selected_bill_info_list)
		{
			if( remaining <= 0.003)
				continue;

			let paid_amount = bi.bill.amount_paid;
			let bill_remaining = bi.bill.total - paid_amount;
			let bmb_amount = remaining < bill_remaining ? remaining : bill_remaining;

			let bmb:BankMovementBillInfo =
			{
				bill: bi.bill,
				bank_movement_bill: {
					id: null,
					amount: bmb_amount,
					bill_id: bi.bill.id,
					currency_amount: bmb_amount ,
					currency_id: bi.bill.currency_id,
					exchange_rate: 1,
					status: 'ACTIVE'
				}
			};

			remaining -= bmb_amount;
			bm.bank_movement_bills.push(bmb);
		}

		payment_info.movements = [ bm ];

		this.subs.sink = this.rest.makePayment(payment_info,null)
		.subscribe
		({
			next:(response)=>
			{
				this.showSuccess('El pago se realizo correctamente');
				this.is_loading = false;
				this.router.navigate(['/list-provider']);
			},
			error:(error)=>
			{
				this.showError(error);
			}
		});
	}

	// onPay()
	// {
	//	this.selected_bill_info_list = this.cbill_info_list.filter((bill_info:CBillInfo) => bill_info.selected);

	//	if( this.selected_bill_info_list.length == 0)
	//	{
	//		this.showError('No hay ninguna orden seleccionada');
	//		return;
	//	}

	//	let first_currency_id = this.selected_bill_info_list[0].bill.currency_id;

	//	for(let bi of this.selected_bill_info_list)
	//	{
	//		if( bi.bill.currency_id != first_currency_id )
	//		{
	//			this.showError('Las ordenes seleccionadas deben ser del mismo tipo de moneda');
	//			return;
	//		}
	//	}

	//	this.show_make_payment = true;
	// }

}
