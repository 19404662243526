<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col-12 col-sm">
			<h2>{{response_info.response.id == 0 ? 'Crear' : 'Editar'}} respuestas para {{response_info.form.title}}</h2>
		</div>
		<div class="col-12 col-sm-3" *ngIf="response_info.response.id != 0 && response_info.user">
			<div class="card p-2 text-center">
				<h3 class="fw-bold">Cliente</h3>
				<p>{{response_info.user.name}}</p>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col" *ngIf="response_info.form.is_response_title_required">
			<h3 class="fw-bold">Nombre de {{response_info.form.title}}</h3>
			<input *ngIf="response_info.form.is_response_title_required == 1" type="text" class="form-control" [(ngModel)]="response_info.response.title" placeholder="Titulo de la respuesta">
		</div>
		<div class="col" *ngIf="response_info.response.id == 0">
			<h3 class="fw-bold">Cliente</h3>
			<app-search-users (user_selected)="changeResponseUser($event)" [reset_on_search]="false" [type]="'CLIENT'" ></app-search-users>
		</div>
	</div>
	<form class="mt-2" (submit)="save($event)" ngNativeValidate>
		<ng-container *ngFor="let ai of response_info.answers;let i = index">
			<label class="fw-bold my-1" >{{ai.question.question}}</label>
			<div class="card p-3 my-1">
				<div class="row">
					<div class="col-12">
						<label *ngIf="ai.question.help" >{{ai.question.help + ':'}}</label>
						<ng-container *ngIf="ai.question.type == 'text' || 'textarea' || 'number' || 'date' || 'tel'">
							<input [type]="ai.question.type" [name]="'answer'+i" class="form-control" [(ngModel)]="ai.answer.answer_text" [required]="ai.question.required == 1">
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<button type="submit" class="btn btn-primary float-end my-3">Guardar</button>
	</form>
</div>
