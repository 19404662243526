<div class="container-fluid break_pages" style="background-color:white;color:black" *ngIf="payment_info">
	<div class="text-center">
		<img [src]="rest.getImagePath(payment_info.store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" style="width:50%;max-width:700;padding-bottom:50px">
	</div>
	<div class="px-3">
		<div *ngIf="payment_info" class="text-center fw-bold pb-3">
			<div *ngIf="payment_info.store.business_name" class="fw-bold">{{payment_info.store.business_name}}</div>
			<div *ngIf="payment_info.store.rfc">{{payment_info.store.rfc}}</div>
			<span>{{payment_info.store.name}}</span>
			<span *ngIf="payment_info.store.address">{{payment_info.store.address}}</span>
			<span *ngIf="payment_info.store.city">,{{payment_info.store.city | titlecase}}</span>
			<span *ngIf="payment_info.store.phone">{{payment_info.store.phone}}</span>
		</div>

		<h3>Recibo de Pago Folio #{{payment_info.payment.id}}</h3>
		<div class="mt-3">Fecha: <span class="fw-bold">#{{payment_info?.payment?.created | shortDate:'full'}}</span></div>
		<div class="pb-3">Atendio:<span class="fw-bold">{{payment_info?.created_by_user?.name}}</span></div>
		<div class="pb-3">Monto Pagado:
			<span class="fw-bold">{{payment_info?.payment?.payment_amount}} {{payment_info.payment.currency_id}}</span>
		</div>
	</div>
	<div class="px-3">
		El pago por la cantidad de: <span class="fw-bold">${{payment_info?.payment?.payment_amount}}</span>
		Solo es valido cuando presenta la firma del Gerente
		<br>
	</div>
	<div class="px-3 mb-3 mt-3 fw-bold">Recibio:</div>
	<div class="px-3 border-bottom my-3 py-3">&nbsp;</div>
	<div class="px-3 my-3 py-3">&nbsp;</div>
</div>
