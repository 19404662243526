<form (submit)="save($event)" ngNativeValidate>
	<div class="p-1 px-1 px-lg-3 ">
		<div class="row">
			<h4>Nuevo Cliente</h4>
		</div>
		<div class="row my-1">
			<div class="col-3 col-lg-2">
				<app-image-uploader ></app-image-uploader>
			</div>
			<div class="col-9 col-lg-6 form-group">
				<label>Nombre</label>
				<input type="text" class="form-control" name="name" [(ngModel)]="user.name" required>
			</div>
			<div class="col-12 col-lg-4 form-group">
				<label>Tipo de Consumo</label>
				<select class="form-control" [(ngModel)]="user.price_type_id" name="price_type_id" required>
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let price_type of price_types" [ngValue]="price_type.id">{{price_type.name}}</option>
				</select>
			</div>

			<div class="col-12 col-lg-8 form-group">
				<label>Dirección</label>
				<input type="text" class="form-control"  name="address" [(ngModel)]="billing_address.address">
			</div>

			<div class="col-12 col-lg-4 form-group">
				<label>Limite de Crédito</label>
				<input type="number" class="form-control" min="0" name="credit_limit" [(ngModel)]="user.credit_limit" required>
			</div>

			<div class="col-12 col-lg-8 form-group">
				<label>Correo Electrónico</label>
				<input type="user_email" class="form-control" name="email" [(ngModel)]="user.email">
			</div>

			<div class="col-12 col-lg-4 form-group">
				<label>Teléfono</label>
				<input type="text" class="form-control" name="phone" [(ngModel)]="user.phone">
			</div>

			<!-- <div class="col-2 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary"  name="address" (click)="putAddressOnMap()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7z" fill="currentColor"/></svg>
					</button>
				</div>
			</div> -->
			<hr class="mt-4">
			<div class="col-12">
				<h5>Datos de Facturación</h5>
			</div>

			<div class="col-12 col-lg-4 form-group">
				<label>RFC</label>
				<input type="text" class="form-control" name="rfc" [(ngModel)]="billing_address.rfc" [required]="billing_address.rfc != ''">
			</div>
			<ng-container *ngIf="billing_address.rfc">

				<div class="col-12 col-lg-5 form-group">
					<label>Razón social</label>
					<input type="text" name="billing_name" [(ngModel)]="billing_address.name" class="form-control" [required]="billing_address.rfc != ''">
				</div>

				<div class="col-12 col-lg-3">
					<label>Codigo Postal</label>
					<input type="text" name="zipcode" [(ngModel)]="billing_address.zipcode" class="form-control" [required]="billing_address.rfc != ''">
				</div>
	
				<div class="col-12 col-lg-6 form-group">
					<label>Regimen Fiscal</label>
					<select class="form-control" name="sat_regimen_fiscal" [(ngModel)]="billing_address.sat_regimen_fiscal" [required]="billing_address.rfc != ''">
						<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
						<option value="606">606 - Arrendamiento</option>
						<option value="609">609 - Consolidación</option>
						<option value="624">624 - Coordinados</option>
						<option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
						<option value="608">608 - Demás ingresos</option>
						<option value="630">630 - Enajenación de acciones en bolsa de valores</option>
						<option value="601">601 - General de Ley Personas Morales</option>
						<option value="628">628 - Hidrocarburos</option>
						<option value="621">621 - Incorporación Fiscal</option>
						<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
						<option value="614">614 - Ingresos por intereses</option>
						<option value="623">623 - Opcional para Grupos de Sociedades</option>
						<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
						<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
						<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
						<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
						<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
						<option value="626">626 - Régimen Simplificado de Confianza</option>
						<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
						<option value="616">616 - Sin obligaciones fiscales</option>
						<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
						<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
					</select>
				</div>

				<div class="col-12 col-lg-6 form-group">
					<label class="">Uso CFDI</label>
					<select type="text" name="sat_uso_cfdi" [(ngModel)]="billing_address.sat_uso_cfdi" class="form-control" [required]="billing_address.rfc != ''">
						<option value="G01">G01 - Adquisición de mercancías</option>
							<option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
							<option value="G03">G03 - Gastos en general</option>
							<option value="I01">I01 - Construcciones</option>
							<option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
							<option value="I03">I03 - Equipo de transporte</option>
							<option value="I04">I04 - Equipo de cómputo y accesorios</option>
							<option value="I05">I05 - Dados, troqueles, moldes, matrices y herramental</option>
							<option value="I06">I06 - Comunicaciones telefónicas</option>
							<option value="I07">I07 - Comunicaciones satelitales</option>
							<option value="I08">I08 - Otra maquinaria y equipo</option>
							<option value="D01">D01 - Honorarios médicos, dentales y gastos hospitalarios.</option>
							<option value="D02">D02 - Gastos médicos por incapacidad o discapacidad</option>
							<option value="D03">D03 - Gastos funerales.</option>
							<option value="D04">D04 - Donativos</option>
							<option value="D05">D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
							<option value="D06">D06 - Aportaciones voluntarias al SAR.</option>
							<option value="D07">D07 - Primas por seguros de gastos médicos.</option>
							<option value="D08">D08 - Gastos de transportación escolar obligatoria.</option>
							<option value="D09">D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
							<option value="D10">D10 - Pagos por servicios educativos (colegiaturas)</option>
							<option value="P01">P01 - Por definir</option>
							<option value="S01">S01 - Sin efectos Fiscales</option>
					</select>
				</div>
				
				<div class="col-12 col-lg-5 form-group">
					<label>Correo Electrónico Facturación</label>
					<input type="email" class="form-control" name="email" [(ngModel)]="billing_address.email" [required]="billing_address.rfc != ''">
				</div>

				<div class="col-12 col-lg-3 form-group">
					<label>Regimen Capital</label>
					<input type="text" name="sat_regimen_capital" [(ngModel)]="billing_address.sat_regimen_capital" placeholder="SA de CV" class="form-control" >
				</div>
	
				<div class="col-12 col-lg-4">
					<label class="">Nota</label>
					<input type="text" name="note" [(ngModel)]="billing_address.note" class="form-control">
				</div>

			</ng-container>

		</div>
		<!-- <div class="">
			<div class="ratio ratio-21x9" *ngIf="rest.is_maps_loaded">
				<div>
					<google-map [width]="'100%'" [height]="'100%'" (centerChanged)="centerChanged($event)">
					map-directions-renderer *ngIf="directions_results"  [directions]="directions_results"></map-directions-renderer
						<map-marker [position]="center_position"></map-marker>
					</google-map>
				</div>
			</div>
		</div> -->
		<div class="row my-3">
			<div class="col-12 text-end">
				<button type="button" class="btn btn-danger mx-1" (click)="cancel()">Cancelar</button>
				<button type="submit" class="btn btn-success mx-1">Guardar</button>
			</div>
		</div>
	</div>
</form>
