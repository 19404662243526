<div class="small_size">
  <div class="text-center" *ngIf="rest.local_preferences.default_ticket_image_id">
  	<img [src]="rest.getImagePath(rest.local_preferences.default_ticket_image_id)" class="image_logo_ticket">
  </div>
  <div class="d-print-none text-center">
  	<button type="button" class="btn btn-primary m-3" (click)="justPrint()">Imprimir</button>
  	<button type="button" class="btn btn-primary m-3" style="" (click)="location.back()">Regresar</button>
  </div>
  <div *ngIf="payment_info_list.length">
	<div *ngIf="payment_info.payment.paid_by_user_id">
		Cliente: <b>{{payment_info.paid_by_user?.name || order_info.client?.name | titlecase}}</b>
	</div>
  	<div>
  		Le atendio: <b>{{payment_info.created_by_user?.name | titlecase}}</b>
  	</div>
  	<div>
  		Fecha: <b>{{payment_info.payment.created | shortDate}}</b>
  	</div>

  	<!--table class="w-100">
  		<tr>
  			<th class="text-end">Folio</th>
  			<td class="text-end">{{order_info.order.store_consecutive}}</td>
  		</tr>
  		<tr>
  			<th class="text-end">Subtotal</th>
  			<td class="text-end">{{order_info.order.total |currency}}</td>
  		</tr>
  		<tr>
  			<th class="text-end">Descuento</th>
  			<td class="text-end">{{order_info.order.discount |currency}}</td>
  		</tr>
  		<tr>
  			<th class="text-end">Total</th>
  			<td class="text-end">{{(order_info.order.total-order_info.order.discount) |currency}}</td>
  		</tr>
  	</table-->

  	<h1 class="text-center">ABONOS</h1>
  	<table class="w-100">
  		<tr>
  			<td class="text-end">Orden</td>
  			<td class="text-end">Pago</td>
  			<td class="text-end">Por Saldar</td>
  			<td class="text-end">Fecha</td>
  			<td class="text-end">Abono</td>
  			<td class="text-end">Balance</td>
  		</tr>
  		<ng-container *ngFor="let bmo of sums">
  			<tr>
  				<td class="text-end">#{{bmo.order_info.order.id}}</td>
  				<td class="text-end">#{{bmo.payment_info.payment.id}}</td>
  				<td class="text-end">{{bmo.prev_balance | currency}} {{bmo.order_info.order.currency_id}} </td>
  				<td class="text-end">{{bmo.created | shortDate:'full' }}</td>
  				<td class="text-end">${{bmo.currency_amount | number:'1.2-2'}} {{bmo.currency_id}}</td>
  				<td class="text-end">{{bmo.balance | currency }} {{bmo.order_info.order.currency_id}}</td>
  			</tr>
  		</ng-container>
  	</table>

  	<table class="w-100">
  		<ng-container	*ngIf="payment_info">
  			<tr class="border-top">
  				<td class="text-end">Total Abonado</td>
  				<td class="text-end">{{payment_info.payment.payment_amount | currency:'$'}}</td>
  			</tr>
  			<tr class="border-top">
  				<td class="text-end larger fw-bold">Total Recibido</td>
  				<td class="text-end larger fw-bold">{{payment_info.payment.received_amount | currency:'$'}}</td>
  			</tr>
  			<tr class="mx-0">
  				<td class="text-end fw-bold">Cambio</td>
  				<td class="text-end fw-bold">{{payment_info.payment.change_amount | currency:'$'}}</td>
  			</tr>
  			<tr class="border-top">
  				<td class="text-end">Pendiente</td>
  				<td class="text-end">{{orders_total - all_partial_payments | currency:'$'}}</td>
  			</tr>
  		</ng-container>
  	</table>
  </div>
</div>
