<div class="container-fluid pb-3 corte_de_caja" *ngIf="cash_close_info">
	<div class="text-center">
		<img [src]="rest.getImagePath(cash_close_info.store.ticket_image_id, rest.local_preferences.default_ticket_image_id)" class="image_logo">
	</div>
	<!-- Para futuro
	<div class="px-0 text-center">
		<div class="fw-bold">Sucursal {{store.name}}</div>
		<div class="pb-3" *ngIf="billing_data.id == 0">
			<div>{{store.business_name}}</div>
			<div>{{store.rfc}}</div>
			<span>{{store.name}}&nbsp;</span>
			<span *ngIf="store?.address">{{store.address}}&nbsp;</span>
			<span *ngIf="store?.city">,{{store.city | titlecase}}&nbsp;</span>
			<span *ngIf="store?.state">,{{store.state | titlecase}}&nbsp;</span>
			<span *ngIf="store?.zipcode">,{{store.zipcode| titlecase}}&nbsp;</span>
		</div>
		<div class="" style="text-transform:uppercase" *ngIf="billing_data">
			<div>{{billing_data.razon_social}} {{billing_data.regimen_capital}}</div>
			<div>{{billing_data.rfc}}</div>
			<div>
				<span *ngIf="billing_data.address">{{billing_data.address}}&nbsp;</span>
				<span *ngIf="billing_data?.city">,{{billing_data.city | titlecase}}&nbsp;</span>
				<span *ngIf="billing_data?.state">,{{billing_data.state| titlecase}}&nbsp;</span>
			</div>
			<div>{{billing_data.zipcode | titlecase}}&nbsp;</div>
		</div>
		<span *ngIf="store.phone">Tel: {{store.phone}}</span>
	</div>
	-->

	<h4>CORTE DE CAJA</h4>

	<div class="d-print-none">
		<button type="button" class="btn btn-primary" (click)="printDirect()">Imprimir</button>
	</div>
	<div>
		Solicitado por: <b class="float-end">{{cash_close_info?.user?.name}}</b>
	</div>
	<div>
		Fecha:
		<b class="float-end">{{current_date | shortDate: 'full'}}</b>
	</div>
	<div>
		Hora:
		<b class="float-end">{{current_date | date: 'h:mm a'}}</b>
	</div>
	<div>
		De:<b class="float-end">{{cash_close_info.cash_close.start | shortDate}}</b>
	</div>
	<div>
		A:<b class="float-end">{{cash_close_info.cash_close.end | shortDate}}</b>
	</div>
	<hr>
	<h4 class="mt-3">ARTICULOS</h4>
	<div class="row border-bottom mx-0">
		<div class="col-9 text-end">Cantidad</div>
		<div class="col-3 text-end">Precio</div>
	</div>

	<div *ngFor="let ois of cash_close_info.item_sales" class="row mx-0">
		<ng-container *ngIf="ois.unitary_price || ois.type=='REFUND'">
			<div class="col-4 px-0">
				{{(rest.local_preferences.display_categories_on_items == 'YES' ? ois.name : ois.item_name) | ticketString: 10 }}
			</div>
			<div class="col-4 px-0 text-end" *ngIf="ois.type != 'REFUND'">{{ois.qty | number }} × {{ ois.unitary_price | number:'1.2-2'}}</div>
			<div class="col-4 px-0 text-end"	*ngIf="ois.type == 'REFUND'">{{ois.qty | number }} × DEVOLUCION</div>
			<div class="col-4 px-0 text-end">{{ ois.type== 'REFUND' ? 0 : ois.total | number:'1.2-2'}} </div>
		</ng-container>
	</div>

	<div class="row border-top mx-0">
		<div class="col-6 px-0 fw-bold">Total Ventas</div>
		<div class="col-6 px-0 fw-bold text-end">{{item_total | currency:'$'}}</div>
	</div>

	<ng-container *ngIf="cash_close_info.funds.length">
		<h4 class="mt-3">Fondo</h4>
		<div class="row mx-0" *ngFor="let fund of cash_close_info.funds">
			<div class="col-6 px-0">{{fund.created | shortDate: 'full'}}</div>
			<div class="col-6 px-0 text-end">{{fund.amount | currency:'$'}} {{fund.currency_id}}</div>
		</div>
	</ng-container>

	<ng-container *ngIf="expenses_paymens.length">
		<h4 class="mt-3">Gastos/Retiros</h4>
		<div class="row mx-0" *ngFor="let payment of expenses_paymens">
			<div class="col-6 px-0">{{payment.created | shortDate: 'full'}} - {{payment.concept || payment.tag }}</div>
			<div class="col-6 px-0 text-end">{{payment.payment_amount | currency:'$'}} {{payment.currency_id}}</div>
		</div>
	</ng-container>

	<h4 class="mt-3">CAJA</h4>

	<div class="row mx-0">
		<div class="col-6 px-0 fw-bold">Total Ventas</div>
		<div class="col-6 px-0 fw-bold text-end">{{item_total | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top black">
		<div class="col-6 px-0">Credito</div>
		<div class="col-6 px-0 text-end">{{total_credit | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Descuentos</div>
		<div class="col-6 px-0 text-end">{{total_discount | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Total Pagos de Ventas</div>
		<div class="col-6 px-0 text-end">{{total_income_sales | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Total Otros Pagos</div>
		<div class="col-6 px-0 text-end">{{total_income_other | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Gastos</div>
		<div class="col-6 px-0 text-end">{{total_expenses | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Total Fondos</div>
		<div class="col-6 px-0 text-end">{{total_funds|currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0">Total Ingresos</div>
		<div class="col-6 px-0 text-end">
		{{
				total_income_sales
				+total_income_other
				-total_expenses
				-total_discount
			| currency:'$'
		}}
		</div>
	</div>
	<div class="row border-top black mx-0">
		<div class="col-6 px-0 fw-bold">Total en caja</div>
		<div class="col-6 px-0 fw-bold text-end">{{
				total_income_sales
				+total_income_other
				+total_funds
				-total_expenses
				-( movements_qtys['Tarjeta Crédito'].total || 0 )
				-( movements_qtys['Tarjeta Débito'].total || 0 )
				-( movements_qtys['Transferencia'].total || 0 )
				| currency:'$' }}
		</div>
		<div class="col-6 fw-bold">Efectivo En Caja</div>
		<div class="col-6 fw-bold">{{cash_close_info.cash_close.cash_on_hand | currency}}</div>
		<div class="col-6 fw-bold">Diferencia</div>
		<div class="col-6 fw-bold">
			{{
				cash_close_info.cash_close.cash_on_hand -
				(
					total_income_sales
					+total_income_other
					+total_funds
					-total_expenses
					-( movements_qtys['Tarjeta Crédito'].total || 0 )
					-( movements_qtys['Tarjeta Débito'].total || 0 )
					-( movements_qtys['Transferencia'].total || 0 )
				) | currency
			}}
		</div>
	</div>
	<div class="tex-bold">Notas</div>
  <div class="mb-3">{{cash_close_info.cash_close.note}}</div>
	<h4 class="mt-3 mx-0">Formas de ingreso</h4>
	<div class="row mx-0" *ngFor="let kv of movements_qtys | keyvalue">
		<div class="col-6 px-0">{{kv.key | titlecase}}</div>
		<div class="col-3 px-0 text-end">
			<span *ngIf="kv.value.currency_id == 'USD'">
				{{kv.value.total | currency:'$' }} × {{currency_rate.rate | number:'1.2-2'}}
			</span>
		</div>
		<div class="col-3 px-0 text-end">{{kv.value.equivalencia | currency:'$'}}</div>
	</div>
	<div class="row mx-0 border-top">
		<div class="col-6 px-0 fw-bold">Total</div>
		<div class="col-6 px-0 fw-bold text-end">{{currencies_total | currency:'$'}}</div>
	</div>
</div>
