<!--
	Si estoy medio pen**jo Pero la razon por la que no se puso un select para seleccionar el tipo de pago
	Es por que ya se tenia el pago de ordenes y se copio el codigo
	para no hacer re-hacer todo el funcionamiento y el maquetado de nuevo y se adapto
-->
<form (submit)="makePayment($event)" ngNativeValidate>
	<div class="d-flex flex-column align-items-stretch">
		<div class="text-white px-3 display-6" [ngClass]="{'bg-danger':total_to_pay > payment_info.payment.received_amount, 'bg-success': total_to_pay <= payment_info.payment.received_amount }">
			<div class="row" style="height:62px">
				<div class="col-6 align-self-center">Total
					<span class="d-none d-lg-inline">a pagar</span>
				</div>
				<div class="col-6 align-self-center text-end">{{total_to_pay | currency:'$'}}</div>
			</div>
		</div>
		<div class="flex-grow-1 align-self-stretch">
			<div class="flex-row">
				<div class="row px-1 m-0">
					<div class="col-12 col-md-3 p-3">
						<div class="row py-1 border border-bottom-0">
							<select name="payment_type" [(ngModel)]="payment_type" class="form-control">
								<option value="MXN">Pesos</option>
								<option value="USD">Dolares</option>
								<option value="CHECK">Cheque</option>
								<option value="TRANSFER">Transferencia</option>
							</select>
						</div>

						<div class="row py-1 border border-bottom-0 form-group" *ngIf="payment_type=='MXN'">
							<div class="col-6 align-self-center">
								<label for="efectivo">Efectivo</label>
							</div>
							<div class="col-6 px-0">
								<input type="number" class="form-control ps-0 pe-1 border-0 text-end" id="efectivo" [ngModel]="cash_string" name="cash" (ngModelChange)="cashChange($event)" (input)="updatePaymentData()" min="0" step="any" tabindex="1" autofocus>
							</div>
						</div>

						<!--div class="row py-1 border border-bottom-0 d-flex d-md-none">
								<div class="col-6 align-self-center">Mostrar otros metodos de pago</div>
								<div class="form-check form-switch">
									<label class="form-check-label">
										<input type="checkbox" name="facturar" (click)="toggleShowOther($event)" class="form-check-input" [checked]="show_other_payment_methods">
									</label>
								</div>
						</div-->

						<div class="row py-1 border border-bottom-0" *ngIf="payment_type=='USD'">
								<div class="col-6 align-self-center">Dolares</div>
								<div class="col-6 px-0">
									<input class="form-control ps-0 pe-1 border-0 text-end"type="number" name="dolares" [(ngModel)]="dolares" (input)="updatePaymentData()"   min="0" step="any">
								</div>
						</div>

						<div class="d-md-block" >
							<ng-container *ngIf="payment_type == 'CARD'">
								<div class="row py-1 border border-bottom-0 ">
									<div class="col-6 align-self-center">Tarjeta</div>
									<div class="col-6 px-0">
										<input class="form-control ps-0 pe-1 border-0 text-end" type="number" name="card_amount" [(ngModel)]="card_amount" (input)="updatePaymentData()"  min="0" step="any">
									</div>
								</div>
								<div class="row p-3-1 border border-bottom-0" *ngIf="card_amount>0">
									<div class="col-9 pe-0">
										<label>Es Tarjeta de crédito</label>
									</div>
									<div class="col-3 form-check form-switch pl-1">
										<input type="checkbox" name="es_tarjeta_credito" (click)="toggle_targeta_credito($event)" class="form-check-input">
									</div>
								</div>
								<div class="row py-1 border border-bottom-0 " *ngIf="card_amount>0">
									<div class="col-9 align-self-center">Terminación Tarjeta</div>
									<div class="col-3 px-0">
										<input class="form-control px-0 border-0 px-0 text-end" type="number" name="card_ending" [(ngModel)]="card_ending" (focus)="onElementFocus($event)" required>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="payment_type=='TRANSFER'">
								<div class="row py-1 border border-bottom-0 ">
									<div class="col-6 align-self-center">Transferencia</div>
									<div class="col-6 px-0">
										<input class="form-control ps-0 pe-1 border-0 text-end"type="number" name="transfer" [(ngModel)]="transfer" (input)="updatePaymentData()"  min="0" step="any">
									</div>
								</div>
								<div class="row py-1 border border-bottom-0 ">
									<div class="col-4 align-self-center">Banco</div>
									<div class="col-8 px-0">
										<input list="bank_list" class="form-control px-0 border-0 text-end" type="text" name="origin_bank_name" [(ngModel)]="origin_bank_name" [required]="transfer>0">
										<datalist id="bank_list">
											<option>AHORRO FAMSA</option>
											<option>AMERICAN EXPRESS</option>
											<option>BANAMEX</option>
											<option>BANCO AZTECA</option>
											<option>BANCO DE MEXICO (BANXICO)</option>
											<option>BANCO HSBC</option>
											<option>BANCO INBURSA</option>
											<option>BANCO INMOBILIARIO MEXICANO</option>
											<option>BANCOPPEL</option>
											<option>BANJERCITO</option>
											<option>BANK OF AMERICA</option>
											<option>BANORTE</option>
											<option>BBVA BANCOMER</option>
											<option>ICBC MEXICO</option>
											<option>INVESTA BANCO</option>
											<option>SCOTIABANK</option>
										</datalist>
									</div>
								</div>
								<div class="row py-1 border border-bottom-0 " >
									<div class="col-6 align-self-center">Referencia</div>
									<div class="col-6 px-0">
										<input class="form-control ps-0 pe-1 border-0 text-end" type="text" name="transfer_reference" [(ngModel)]="transfer_reference"  [required]="transfer>0">
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="payment_type == 'CHECK'">
								<div class="row py-1 border" [ngClass]="{'border-bottom-0':check>0}">
									<div class="col-6 align-self-center">Cheque</div>
									<div class="col-6 py-1 px-0">
										<input class="form-control ps-0 pe-1 border-0 text-end" type="number" step="any" name="chek" [(ngModel)]="check" (input)="updatePaymentData()" min="0">
									</div>
								</div>
								<div class="row py-1 border" *ngIf="check>0" [ngClass]="">
									<div class="col-6 align-self-center">Referencia</div>
									<div class="col-6 px-0">
										<input class="form-control ps-0 pe-1 border-0 text-end" type="text" name="check_reference" [(ngModel)]="check_reference" required>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="row mt-3 d-none d-md-flex">
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(20)">
								<img class="w-100" src="/assets/billetes/20.webp">
							</div>
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(50)">
								<img class="w-100" src="/assets/billetes/50.webp">
							</div>
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(100)">
								<img class="w-100" src="/assets/billetes/100.webp">
							</div>
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(200)">
								<img class="w-100" src="/assets/billetes/200.webp">
							</div>
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(500)">
								<img class="w-100" src="/assets/billetes/500.webp">
							</div>
							<div class="col-4 px-1 pt-0 pb-2" (click)="addEfectivo(1000)">
								<img class="w-100" src="/assets/billetes/1000.webp">
							</div>
						</div>
						<div class="row">
							<div class="col-6  p-1">
								<button type="button" class="btn btn-primary w-100 text-white" (click)="dolarsExacto()" style="background-color:#60B044">Dolares exacto </button>
							</div>
							<div class="d-none d-md-block col-6 p-1">
								<button type="button" class="btn btn-primary w-100 text-white" style="background-color:#80A6CD" (click)="transferenciaExcta()">
									Solo transferencia
								</button>
							</div>
							<div class="d-none d-md-block col-6 p-1">
								<button type="button" class="btn btn-danger w-100 text-white"  (click)="tarjetaExacta()" style="background-color:#DB2828">
									Solo tarjeta
								</button>
							</div>
							<div class="col-6 p-1">
								<button type="button" class="btn btn-primary w-100 text-white" style="background-color:#444C55" (click)="cleanAmounts()">
									Limpiar cantidades
								</button>
							</div>
							<div class="col-6 p-1">
								<button type="button" class="btn btn-primary w-100 text-white" (click)="efectivoExacto()" style="background-color:#767676">
									<span class="h2">Efectivo exacto</span>
								</button>
							</div>
							<div class="col-6 p-1">
								<button type="submit" class="btn btn-success w-100 text-white" [disabled]="is_loading || payment_button_disabled" tabindex=2>
									<span class="h2 text-white">Procesar pago</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3 pt-3 px-1">
						<div class="row mx-0 border mt-3">
							<div class="col-8">
								<label for="facturar">Facturar</label>
							</div>
							<div class="col-4 text-end">
								<div class="form-check form-switch">
									<label class="form-check-label">
										<input type="checkbox" id="facturar" name="facturar" (click)="toggleFactura($event)" class="form-check-input" [checked]="factura_enabled">
									</label>
								</div>
							</div>
						</div>

						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled">
							<div class="col-3 align-self-center">RFC</div>
							<div class="col-9 px-0">
								<input type="text" class="form-control text-end px-0" name="sat_receptor_rfc" [(ngModel)]="order_info.order.sat_receptor_rfc" required>
							</div>
						</div>

						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled" >
							<div class="col-6 align-self-center">Razón Social</div>
							<div class="col-6 p-0">
								<input type="text" class="form-control text-end px-0" name="sat_razon_social" [(ngModel)]="order_info.order.sat_razon_social" required>
							</div>
						</div>

						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled" >
							<div class="col-6 align-self-center">Código Postal</div>
							<div class="col-6 p-0">
								<input type="text" class="form-control text-end px-0" name="sat_domicilio_fiscal" [(ngModel)]="order_info.order.sat_domicilio_fiscal_receptor" required>
							</div>
						</div>

						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled" >
							<div class="col-6 align-self-center">Regimen</div>
							<div class="col-6 p-0">
								<select class="form-control" name="sat_regimen_fiscal" [(ngModel)]="order_info.order.sat_regimen_fiscal_receptor" required>
									<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
									<option value="606">606 - Arrendamiento</option>
									<option value="609">609 - Consolidación</option>
									<option value="624">624 - Coordinados</option>
									<option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
									<option value="608">608 - Demás ingresos</option>
									<option value="630">630 - Enajenación de acciones en bolsa de valores</option>
									<option value="601">601 - General de Ley Personas Morales</option>
									<option value="628">628 - Hidrocarburos</option>
									<option value="621">621 - Incorporación Fiscal</option>
									<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
									<option value="614">614 - Ingresos por intereses</option>
									<option value="623">623 - Opcional para Grupos de Sociedades</option>
									<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
									<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
									<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
									<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
									<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
									<option value="626">626 - Régimen Simplificado de Confianza</option>
									<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
									<option value="616">616 - Sin obligaciones fiscales</option>
									<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
									<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
								</select>

							</div>
						</div>
						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled">
							<div class="col-8 align-self-center">Uso cfdi</div>
							<div class="col-4 px-0">
								<input type="text" class="form-control text-end px-0" name="sat_uso_cfdi" [(ngModel)]="order_info.order.sat_uso_cfdi" list="uso_cfdi" required>
								<datalist id="uso_cfdi">
									<option value="G01">G01 - Adquisición de mercancías.</option>
									<option value="G03">G03 - Gastos en general.</option>
									<option value="D06">D06 - Aportaciones voluntarias al SAR.</option>
									<option value="I07">I07 - Comunicaciones satelitales.</option>
									<option value="I06">I06 - Comunicaciones telefónicas.</option>
									<option value="I01">I01 - Construcciones.</option>
									<option value="I05">I05 - Dados, troqueles, moldes, matrices y herramental.</option>
									<option value="D09">D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
									<option value="G02">G02 - Devoluciones, descuentos o bonificaciones.</option>
									<option value="D04">D04 - Donativos.</option>
									<option value="I04">I04 - Equipo de computo y accesorios.</option>
									<option value="I03">I03 - Equipo de transporte.</option>
									<option value="D08">D08 - Gastos de transportación escolar obligatoria.</option>
									<option value="D03">D03 - Gastos funerales.</option>
									<option value="D02">D02 - Gastos médicos por incapacidad o discapacidad.</option>
									<option value="D01">D01 - Honorarios médicos, dentales y gastos hospitalarios.</option>
									<option value="D05">D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
									<option value="I02">I02 - Mobiliario y equipo de oficina por inversiones.</option>
									<option value="CN01">CN01- Nómina</option>
									<option value="I08">I08 - Otra maquinaria y equipo.</option>
									<option value="CP01">CP01- Pagos</option>
									<option value="D10">D10 - Pagos por servicios educativos (colegiaturas).</option>
									<option value="D07">D07 - Primas por seguros de gastos médicos.</option>
									<option value="S01">S01 - Sin efectos fiscales.</option>
								</datalist>
							</div>
						</div>
						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled">
							<div class="col-8 align-self-center">Serie</div>
							<div class="col-4 px-0">
								<input type="text" class="form-control text-end px-0" name="sat_serie" [(ngModel)]="order_info.order.sat_serie" required>
							</div>
						</div>
						<div class="row mx-0 border border-top-0" *ngIf="factura_enabled">
							<div class="col-3 align-self-center">Email</div>
							<div class="col-9 p-0">
								<input type="text" class="form-control text-end px-0"name="sat_receptor_email" [(ngModel)]="order_info.order.sat_receptor_email" required>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="display-5 text-white px-3" [ngClass]="{'bg-danger':(total_to_pay) > payment_info.payment.received_amount, 'bg-success': total_to_pay <= payment_info.payment.received_amount }">
			<div class="row align-items-center" style="height:82px">
				<div class="col-6 align-self-center">
					{{ (total_to_pay) > payment_info.payment.received_amount ? 'Restan' : 'Cambio' }}
				</div>

				<div class="col-6 align-self-center text-end">{{
					(   (total_to_pay)> payment_info.payment.received_amount ?
						(total_to_pay-payment_info.payment.received_amount)
						: (payment_info.payment.received_amount-total_to_pay) )
						| currency:'$'
					}} {{ order_info.order.currency_id }}
				</div>
			</div>
		</div>
	</div>
</form>
