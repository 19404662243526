import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';


@Component({
	selector: 'app-item-in-sales-report',
	templateUrl: './item-in-sales-report.component.html',
	styleUrls: ['./item-in-sales-report.component.css']
})
export class ItemInSalesReportComponent	extends BaseComponent implements OnInit
{
	store_list: Store[] = [];
	report_list:Record<string,string | number>[] = [];
	report_search:SearchObject<Record<string,string>> = this.getEmptySearch();

	startx:string = '';
	endx:string = '';

	ngOnInit(): void {

		this.path = '/item-in-sales-report';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap)=>
			{
				this.setTitle('Reporte Ventas por artículo');
				this.is_loading = true;

				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.getStores(true)
				});
			}),
			mergeMap((response)=>
			{
				this.store_list = response.stores.data;
				this.report_search = this.getSearch(response.param_map, [],['item_name','store_id','start','end']);

				if( !this.report_search.search_extra['start'] )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.report_search.search_extra.start = Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.search_extra['end'] )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours(23,59,59,0);

					this.report_search.search_extra['end'] = Utils.getUTCMysqlStringFromDate( end_of_month );

				}

				let start = Utils.getDateFromMysqlString( this.report_search.search_extra['start'] as string );
				let end = Utils.getDateFromMysqlString( this.report_search.search_extra['end']	as string);

				this.endx = Utils.getLocalMysqlStringFromDate( end ).substring(0,16);
				this.startx = Utils.getLocalMysqlStringFromDate( start ).substring(0,16);

				console.log('query has',response.param_map.get('search_extra.store_id') );

				if( !response.param_map.has('search_extra.store_id') )
				{
					console.log('NO HAY NADA AQUI');
					this.report_search.search_extra.store_id =	''+this.rest.current_user.store_id;
				}

				return this.rest.getReporte('getUsageSalesItem',this.report_search.search_extra);
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.report_list = response.map((x)=>{
				x.total_orders = x.order_ids.split(',').length;
				return x;
			});
		},(error)=>this.showError(error))
	}
	searchReport()
	{
		let start = Utils.getDateFromLocalMysqlString( this.report_search.search_extra['startx'] as string );
		start.setHours(0,0,0,0);
		this.report_search.search_extra['start'] = Utils.getUTCMysqlStringFromDate( start );
		let end = Utils.getDateFromLocalMysqlString( this.report_search.search_extra['endx'] as string );
		this.report_search.search_extra['end'] = Utils.getUTCMysqlStringFromDate( end );
		//this.report_search.
		this.search( this.report_search );
	}

	getUtcStringFromDate(date:Date):string
	{
		return Utils.getUTCMysqlStringFromDate( date as Date );
	}
}
