import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Address, Form, Installment, Price_Type, Response, User } from 'src/app/models/RestModels';
import { GetEmpty } from 'src/app/classes/Empties';
import { filter, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { OrderInfo, ResponseInfo, UserBalance } from 'src/app/models/models';
import { SearchObject } from 'src/app/services/Rest';

type cstatus = 'PENDIENTE' | 'PAGADO' | 'VENCIDO';

interface CInstallment extends Installment
{
	due_status:cstatus;
}

interface CFormResponse
{
	form:Form;
	response:Response[];
}

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
}

@Component
({
	selector: 'app-view-client',
	templateUrl: './view-client.component.html',
	styleUrls: ['./view-client.component.css']
})

export class ViewClientComponent extends BaseComponent implements OnInit {

	user:UserBalance = GetEmpty.user();
	agent:User = GetEmpty.user();
	price_type:Price_Type = GetEmpty.price_type();
	address_list:Address[] = [];
	response_info_list:ResponseInfo[] = [];
	selected_address:Address | null = null;
	show_address:boolean = false;
	cinstallment_list:CInstallment[] = [];
	cformresponse_list:CFormResponse[] = [];
	last_corder_info_list:CustomOrderInfo[] = [];
	pending_corder_info_list:CustomOrderInfo[] = [];


	payment_option_dict = {
		'ADDRESS': 'Dirección',
		'TRANSFER': 'Transferencia',
		'STORE': 'Sucursal'
	}

	address_type_dict = {
		'BILLING': 'Facturación',
		'SHIPPING': 'Envío',
	}

	regimen_fiscal_dict = {
		'622': '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
		'606': '606 - Arrendamiento',
		'609': '609 - Consolidación',
		'624': '624 - Coordinados',
		'629': '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
		'608': '608 - Demás Ingresos',
		'630': '630 - Encajenación de acciones en bolsa de valores',
		'601': '601 - General de Ley Personas Morales',
		'628': '628 - Hidrocarburos',
		'621': '621 - Incorporación Fiscal',
		'611': '611 - Ingresos por Dividendos (socios y accionistas)',
		'614': '614 - Ingresos por intereses',
		'623': '623 - Opcional para Grupos de Sociedades',
		'612': '612 - Personas Físicas con Actividades Empresariales y Profesionales',
		'603': '603 - Personas Morales con Fines no',
		'607': '607 - Régimen de Enajenación o Adquisición de Bienes',
		'625': '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
		'615': '615 - Régimen de los ingresos por obtención de premios',
		'626': '626 - Régimen Simplificado de Confianza',
		'610': '610 - Residentes en el Extranjero sin Establecimiento Permanente en México',
		'616': '616 - Sin obligaciones fiscales',
		'620': '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
		'605': '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
	}

	uso_cfdi_dict = {
		'G01': 'G01 - Adquisición de mercancías',
		'G02': 'G02 - Devoluciones, descuentos o bonificaciones',
		'G03': 'G03 - Gastos en general',
		'I01': 'I01 - Construcciones',
		'I02': 'I02 - Mobiliario y equipo de oficina por inversiones',
		'I03': 'I03 - Equipo de transporte',
		'I04': 'I04 - Equipo de cómputo y accesorios',
		'I05': 'I05 - Dados, troqueles, moldes, matrices y herramental',
		'I06': 'I06 - Comunicaciones telefónicas',
		'I07': 'I07 - Comunicaciones satelitales',
		'I08': 'I08 - Otra maquinaria y equipo',
		'D01': 'D01 - Honorarios médicos, dentales y gastos hospitalarios.',
		'D02': 'D02 - Gastos médicos por incapacidad o discapacidad',
		'D03': 'D03 - Gastos funerales.',
		'D04': 'D04 - Donativos',
		'D05': 'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
		'D06': 'D06 - Aportaciones voluntarias al SAR.',
		'D07': 'D07 - Primas por seguros de gastos médicos.',
		'D08': 'D08 - Gastos de transportación escolar obligatoria.',
		'D09': 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
		'D10': 'D10 - Pagos por servicios educativos (colegiaturas)',
		'P01': 'P01 - Por definir',
		'S01': 'S01 - Sin efectos Fiscales'
	}

	ngOnInit(): void {

		this.is_loading = true;
		this.setTitle('Resumen de Cliente')

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params) =>
			{
				let user_id = params.get('id');
				let search_obj:Partial<SearchObject<UserBalance>> = {csv: {id: [user_id]}};
				return forkJoin({
					user: this.rest.user.search(search_obj)
				})
			}),
			mergeMap((responses) => {

				let user = responses.user.data[0];

				let search_obj:Partial<SearchObject<Installment>> =
				{
					eq: {status: 'ACTIVE'},
					le: {due_date: new Date()},
					is_null: ['paid_timestamp'],
					search_extra: {user_id: user.id},
					sort_order: ['due_date_ASC']
				}

				return forkJoin({
					user: of(user),
					agent: this.rest.user.get(user.created_by_user_id),
					address: this.rest.address.search({eq: {user_id: user.id}}),
					installments: this.rest.installment.search(search_obj),
					price_type: this.rest.price_type.get(user.price_type_id),
					response_info: this.rest.response_info.search({eq: {user_id: user.id}}),
					last_order_info: this.rest.order_info.search({eq: {client_user_id: user.id, paid_status: 'PAID'}, sort_order: ['closed_timestamp_DESC'], limit: 5}),
					pending_order_info: this.rest.order_info.search({eq: {client_user_id: user.id, paid_status: 'PARTIALLY_PAID'}, sort_order:['closed_timestamp_ASC'], limit: 5})
				})
			})
		)
		.subscribe
		({
			next: (responses) => {
				this.user = responses.user;
				this.agent = responses.agent;
				this.price_type = responses.price_type;
				this.address_list = responses.address.data;
				this.last_corder_info_list = responses.last_order_info.data.map(oi=> this.getCustomOrderInfo(oi));
				this.pending_corder_info_list = responses.pending_order_info.data.map(oi=> this.getCustomOrderInfo(oi));

				if(responses.installments.total > 0)
				{
					this.cinstallment_list = responses.installments.data.map((installment:Installment)=>
					{
						let due_status:cstatus = 'PENDIENTE';

						if (!installment.paid_timestamp && new Date(installment.due_date) < new Date())
						{
							due_status = 'VENCIDO';
						}

						if (installment.paid_timestamp)
						{
							due_status = 'PAGADO';
						}

						return {...installment, due_status};
					});
				}

				if ( responses.response_info.total > 0 )
				{
					responses.response_info.data.forEach((response_info:ResponseInfo)=>
					{
						let form_response = this.cformresponse_list.find((fr)=>fr.form.id == response_info.form.id);
						if (!form_response)
						{
							form_response = {form:response_info.form, response:[]};
							this.cformresponse_list.push(form_response);
						}
						form_response.response.push(response_info.response);
					});
				}

				this.is_loading = false;
			},
			error: (error) => this.showError(error)
		})

	}

	getAddressName(address_id:number):string
	{
		let address = this.address_list.find((address) => address.id == address_id);
		return address ? address.name : '';
	}

	showAddress(address:Address)
	{
		this.selected_address = address;
		this.show_address = true;
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			let diff = oi.order_item.unitary_price_meta - oi.order_item.original_unitary_price;
			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		return {
			...order_info,
			article_discount
		}
	}

	confirmRemoveClient(user:User)
	{
		let title	= 'Eliminar Usuario';
		let body	= 'Estas seguro de querer eliminar al usuario "'+user.name+'"';
		this.subs.sink = this.confirmation
		.showConfirmAlert(user,title,body)
		.pipe
		(
			filter((response)=>
			{
				return response.accepted;
			}),
			mergeMap((response)=>
			{
				return this.rest.user.delete( response.obj )
			})
		)
		.subscribe
		({
			next:(_response)=>
			{
				this.is_loading = false;
				this.router.navigate(['/list-client']);
			},
			error:(error)=>
			{
				this.showError( error );
			}
		});
	}
}
