import { Utils } from "./Utils";

export class NumeroALetras
{
	constructor(public monedasSingular:string,public monedasPlural:string,public centavosPlural:string,public centavosSingular:string)
	{
	}

	Unidades(num:number):string
	{
		switch(num)
		{
				case 1: return 'UN';
				case 2: return 'DOS';
				case 3: return 'TRES';
				case 4: return 'CUATRO';
				case 5: return 'CINCO';
				case 6: return 'SEIS';
				case 7: return 'SIETE';
				case 8: return 'OCHO';
				case 9: return 'NUEVE';
		}

		return '';
	}//Unidades()

	Decenas(num:number):string
	{
		let decena = Math.floor(num/10);
		let unidad = num - (decena * 10);

		switch(decena)
		{
			case 1:
				switch(unidad)
			{
				case 0: return 'DIEZ';
				case 1: return 'ONCE';
				case 2: return 'DOCE';
				case 3: return 'TRECE';
				case 4: return 'CATORCE';
				case 5: return 'QUINCE';
				default: return 'DIECI' + this.Unidades(unidad);
			}
			case 2: return unidad == 0 ? 'VEINTE' : 'VEINTI'+this.Unidades( unidad );
			case 3: return this.DecenasY('TREINTA', unidad);
			case 4: return this.DecenasY('CUARENTA', unidad);
			case 5: return this.DecenasY('CINCUENTA', unidad);
			case 6: return this.DecenasY('SESENTA', unidad);
			case 7: return this.DecenasY('SETENTA', unidad);
			case 8: return this.DecenasY('OCHENTA', unidad);
			case 9: return this.DecenasY('NOVENTA', unidad);
			case 0: return this.Unidades(unidad);
		}
		return '';
	}//Unidades()

	DecenasY(strSin:string, numUnidades):string
	{
		return strSin+( numUnidades ? ' Y ' + this.Unidades(numUnidades) : '');
	}//DecenasY()

	Centenas(num:number):string
	{
		let centenas = Math.floor(num / 100);
		let decenas = num - (centenas * 100);

		switch(centenas)
		{
			case 1: return decenas > 0 ? 'CIENTO ' + this.Decenas(decenas) : 'CIEN';
			case 2: return 'DOSCIENTOS ' + this.Decenas(decenas);
			case 3: return 'TRESCIENTOS ' + this.Decenas(decenas);
			case 4: return 'CUATROCIENTOS ' + this.Decenas(decenas);
			case 5: return 'QUINIENTOS ' + this.Decenas(decenas);
			case 6: return 'SEISCIENTOS ' + this.Decenas(decenas);
			case 7: return 'SETECIENTOS ' + this.Decenas(decenas);
			case 8: return 'OCHOCIENTOS ' + this.Decenas(decenas);
			case 9: return 'NOVECIENTOS ' + this.Decenas(decenas);
		}

		return this.Decenas(decenas);
	}//Centenas()

	Seccion(num:number, divisor:number, strSingular:string, strPlural:string):string
	{
		let cientos = Math.floor(num / divisor)
		let resto = num - (cientos * divisor)

		let letras = '';

		if (cientos > 0)
		{
			letras = cientos > 1 ?this.Centenas(cientos) + ' ' + strPlural : strSingular;
		}

		if (resto > 0)
			letras += ''; //WTF

		return letras;
	}//Seccion()

	Miles(num:number):string
	{
		let divisor = 1000;
		let cientos = Math.floor(num / divisor)
		let resto = num - (cientos * divisor)

		let strMiles = this.Seccion(num, divisor, 'UN MIL', 'MIL');
		let strCentenas = this.Centenas(resto);

		return (strMiles? strMiles+' ': '')+strCentenas;
	}//Miles()

	Millones(num:number):string
	{
		let divisor = 1000000;
		let cientos = Math.floor(num / divisor)
		let resto = num - (cientos * divisor)

		let strMillones = this.Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
		let strMiles = this.Miles(resto);

		if(strMillones == '')
				return strMiles;

		return strMillones + ' ' + strMiles;
	}//Millones()

	numeroALetras(num:number):string
	{
		let data = {
			numero: num,
			enteros: Math.floor(num),
			centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
			letrasCentavos: '',
			letrasMonedaPlural: this.monedasPlural,
			letrasMonedaSingular: this.monedasSingular,
			letrasMonedaCentavoPlural: this.centavosPlural,
			letrasMonedaCentavoSingular: this.centavosSingular
		};


		if (data.centavos > 0)
		{
			let centavos = ''
			if (data.centavos == 1)
				centavos = Utils.zero( data.centavos )+data.letrasMonedaCentavoSingular;
			else
				centavos = Utils.zero( data.centavos )+data.letrasMonedaCentavoPlural;

			data.letrasCentavos = ' ' + centavos+' ';
		};

		if(data.enteros == 0)
			return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;

		if (data.enteros == 1)
			return this.Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;

		return this.Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
	};
}
