<div class="container-fluid">
	<div class="card p-3 my-3">
		<form (submit)="save()">
			<div *ngIf="item_stock_info">
				<div *ngFor="let store of store_list" class="row mt-1">
					<div class="col-6 col-md-6">{{store.id}} - {{store.name}}</div>
					<div class="col-6 col-md-2 form-group">
						<input class="form-control" [name]="'stock_recor'+store.id" type="number" [(ngModel)]="stock_record_dictionary[ store.id ].qty" required>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-end">
						<button type="submit" class="btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
							Guardar
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
