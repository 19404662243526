<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8 col-md-10 col-lg-11">
			<h1 class="my-3">Productos por tienda</h1>
		</div>
		<div class="col-4 col-md-2 col-lg-1">
			<a routerLink="/category-store" class="btn btn-primary">Agregar</a>
		</div>
	</div>
	<div class="card my-3 p-3">
		<table>
			<thead></thead>
			<tbody>
				<tr *ngFor="let csi of category_store_info_list">
					<td>
						<a [routerLink]="['/category-store',csi.category_store.id]">
							{{csi.category.name}}
						</a>
					</td>
					<td>{{csi.store.name}}</td>
					<td>
						{{csi.category_store.pos_preference == 'SHOW'?'A la venta':''}}
						{{csi.category_store.pos_preference == 'HIDE'?'No Vender':''}}
						{{csi.category_store.pos_preference == 'HIDE'?'Por defecto':''}}
					</td>
				</tr>
				<tr *ngIf="category_store_info_list.length == 0">
					<td colspan="3">No se encontraron categorias</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
