import { Component } from '@angular/core';
import { Location } from	'@angular/common';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService} from 'src/app/services/confirmation.service';
import {RestService} from 'src/app/services/rest.service';
import {ShortcutsService} from 'src/app/services/shortcuts.service';
import {BaseComponent} from '../base/base.component';
import {MapDirectionsService} from '@angular/google-maps';
import { map, mergeMap } from 'rxjs/operators';
import {LatLng, OrderInfo} from 'src/app/models/models';
import {of} from 'rxjs';

// https://www.youtube.com/watch?v=fnC5lOaOc5I
// Direcciones
// https://github.com/angular/components/blob/master/src/google-maps/map-directions-renderer/README.md

interface Coordinates
{
	lat:number;
	lng:number;
}


@Component({
	selector: 'app-delivery-order-map',
	templateUrl: './delivery-order-map.component.html',
	styleUrls: ['./delivery-order-map.component.css']
})

export class DeliveryOrderMapComponent extends BaseComponent {

	directions_results:google.maps.DirectionsResult | undefined = undefined;
	order_info:OrderInfo | null = null;
	geolocation_watch_id:number | null = null;
	order_distance:number  = -1;
	maps_enabled:boolean = true;
	distance:number | 0;
	duration:string = 'N/A';
	current_position:LatLng | null = null;
	last_time_location_updated:number = 0;

	constructor
	(
		public rest: RestService,
		public confirmation:ConfirmationService,
		public shortcuts:ShortcutsService,
		public router: Router,
		public route: ActivatedRoute,
		public loc: Location,
		public title_service: Title,
		public map_direction_service:MapDirectionsService
	)
	{
		super(rest,confirmation,shortcuts,router,route,loc,title_service);
	}

	ngOnInit(): void
	{
		if('geolocation' in navigator) {
				/* geolocation is available */
				const options = {
					enableHighAccuracy: true,
					maximumAge: 30000,
					timeout: 27000
				};

				this.geolocation_watch_id = navigator.geolocation.watchPosition((p)=>this.processPosition(p),(error)=>{},options );
		}

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap)=>
			{
				return this.rest.order_info.get( paramMap.get('order_id') );
			}),
			mergeMap((order_info)=>
			{
				this.order_info = order_info;
				if( this.maps_enabled )
					return this.rest.loadGoogleMapsApi();
				return of(true);
			})

		).subscribe(()=>
		{
			this.displayDirections();
		});
	}


	displayDirections()
	{
		if( !this.current_position || this.directions_results )
		{
			console.log('FOOOO');
			return;
		}

		if( !this.order_info?.order?.lng && !this.order_info?.order?.lng )
		{
			console.log('Saliendo FOOOO');
			return;
		}


		if( this.maps_enabled )
		{
			console.log('Looking for directions');

			const request: google.maps.DirectionsRequest = {
				destination: {lat:this.order_info.order.lat, lng: this.order_info.order.lng},
				origin: this.current_position,
				travelMode: google.maps.TravelMode.DRIVING
			};



			this.subs.sink =  this.map_direction_service.route(request)
			.pipe(map(response => response.result))
			.subscribe((result)=>
			{
				if( result.routes.length )
				{
					if( result.routes[0].legs.length )
					{
						this.distance = result.routes[0].legs[0].distance.value;
						this.duration = result.routes[0].legs[0].duration.text;
					}
				}
				result.routes
				console.log( result );
				this.directions_results = result;
			});
		}
	}


	processPosition(geolocation_position:GeolocationPosition)
	{
		this.current_position = {
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};
		let current:Coordinates = {
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};

		if( this.last_time_location_updated + 5000 < Date.now() )
		{
			this.last_time_location_updated = Date.now();
			this.subs.sink = this.rest.updateUserPosition(current).subscribe(()=>{
				console.log('Posicion actualizada');
			});
		}

		if( this.order_info )
		{
			if( this.order_info.order.lat )
			{
				let to:Coordinates = {
					lat: this.order_info.order.lat,
					lng: this.order_info.order.lng
				};
				let d:number = this.distanceTo(current, to );
				console.log('Distance is ',d);
				this.order_distance = d;
				this.displayDirections();
			}
			else
			{
				this.order_distance = -1;
			}
		}
	}

	ngOnDestroy()
	{
		if( this.geolocation_watch_id )
		{
			navigator.geolocation.clearWatch(this.geolocation_watch_id);
		}
		this.directions_results = undefined;
		this.current_position = null;
		super.ngOnDestroy();
	}

	/**
	 * Returns the distance along the surface of the earth from ‘this’ point to destination point.
	 *
	 * Uses haversine formula: a = sin²(Δφ/2) + cosφ1·cosφ2 · sin²(Δλ/2); d = 2 · atan2(√a, √(a-1)).
	 *
	 * @param	{Coordinates} point_a - Latitude/longitude of destination point.
	 * @param	{Coordinates} point_b
	 * @param	{number} [radius=6371e3] - Radius of earth (defaults to mean radius in metres).
	 * @returns {number} Distance between this point and destination point, in same units as radius.
	 */
	distanceTo(point_a:Coordinates,point_b:Coordinates, radius=6371e3):number
	{
		// a = sin²(Δφ/2) + cos(φ1)⋅cos(φ2)⋅sin²(Δλ/2)
		// δ = 2·atan2(√(a), √(1−a))
		// see mathforum.org/library/drmath/view/51879.html for derivation

		const R = radius;

		const φ1 = this.toRadians(point_a.lat);
		const λ1 = this.toRadians(point_a.lng);
		const φ2 = this.toRadians(point_b.lat);
		const λ2 = this.toRadians(point_b.lng);
		const Δφ = φ2 - φ1;
		const Δλ = λ2 - λ1;

		const a = Math.sin(Δφ/2)*Math.sin(Δφ/2) + Math.cos(φ1)*Math.cos(φ2) * Math.sin(Δλ/2)*Math.sin(Δλ/2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		const d = R * c;

		return d;
	}

	toRadians(lat_or_lng:number):number
	{
		return lat_or_lng* Math.PI / 180;
	}

	marcarOrdenComoEntregada()
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert(this.order_info, "Entregar", "Esta seguro de marcar la orden como entregada")
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.updateOrderDeliveryStatus(this.order_info.order.id, 'DELIVERED')
				.subscribe((order_info)=>
				{
					this.rest.sendNotification('order', this.order_info.order.id);
					this.order_info.order.delivery_status = 'DELIVERED';
					this.showSuccess('La orden se marco como entregada');
				});
			}
			else
			{

			}
		})
	}
}
