import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';


import {Storage, Store} from '../../models/RestModels';
import {forkJoin} from 'rxjs';


@Component({
	selector: 'app-list-storage',
	templateUrl: './list-storage.component.html',
	styleUrls: ['./list-storage.component.css']
})

export class ListStorageComponent extends BaseComponent implements OnInit {
	file:File = null;
	show_import:boolean = false;
	storage_search:SearchObject<Storage> = this.getEmptySearch();
	search_extra:Record<string,string> = { };
	storage_list:Storage[] = [];
	store_dictionary:Record<number,Store> = {};


	ngOnInit()
	{
		this.path = '/list-storage';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","store_id","shelf","section","sort_order","created_by_user_id","updated_by_user_id","created","updated" ];
			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.storage_search = this.getSearch(queryParamMap, fields,extra_keys );

			this.setTitle('Espacios');
			this.current_page = this.storage_search.page;

			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				storage: this.rest.storage.search(this.storage_search),
				store: this.rest.getStores(true)
			})
			.subscribe((response)=>
			{
				response.store.data.forEach((i)=> this.store_dictionary[ i.id ] = i);
				this.setPages( this.storage_search.page, response.storage.total );
				this.storage_list = response.storage.data;
				this.is_loading = false;
			},(error)=>this.showError(error));

		});
	}

	onFileChanged(event)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}
/*
	uploadFile()
	{
		this.is_loading = true;
		Utils.xlsx2json( this.file,["id","store_id","shelf","section","sort_order","created_by_user_id","updated_by_user_id","created","updated"]).then((json)=>
		{
			//Filter json then upload
			this.subs.sink	= this.rest.storage.batchUpdate(json).subscribe((result)=>
			{
				if( this.storage_list.length == 0 )
				{
					this.setPages( 0, result.length );
					this.storage_list = result.slice(0,this.pageSize);
				}
				this.is_loading =  false;
                this.show_import = false;
                this.showSuccess('Imported succesfully '+result.length+' items');

			},(error)=>this.showError(error));
		});
	}

	exportFile()
	{
		this.is_loading = true;
		this.subs.sink	= this.rest.storage.search({limit:100000}).subscribe((response)=>
		{
			Utils.array2xlsx(response.data,'storage.xlsx',["id","store_id","shelf","section","sort_order","created_by_user_id","updated_by_user_id","created","updated"])
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
*/
}
