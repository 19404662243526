<div class="container-fluid">
	<h1 class="my-3">Reporte de ocupación Dia</h1>
	<div class="card px-lg-3 px-1 py-3 d-print-none">
		<div class="row">
			<div class="col-5 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.ge,'created')" class="form-control">
			</div>
			<div class="col-5 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx" (ngModelChange)="onDateChange($event,report_search.le,'created')" class="form-control">
			</div>
			<div class="col-2 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary w-100" (click)="search(report_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="row my-3 px-3">
		<div class="col-4 px-3">
			<div class="card p-3 mx-3">
				<h3 class="fs-5 fw-light">Promedio</h3>
				<span class="fs-3">{{avg_tables|number}} Mesas</span>
			</div>
		</div>
		<div class="col-4 px-3">
			<div class="card p-3 mx-3">
				<h3 class="fs-5 fw-light">Promedio</h3>
				<span class="fs-3">{{avg_minutes|number : '1.0-0'}} Minutos</span>
			</div>
		</div>
		<div class="col-4 px-3">
			<div class="card p-3 mx-3">
				<h3 class="fs-5 fw-light">Promedio</h3>
				<span class="fs-3">{{avg_guests|number: '1.1-1'}} Personas</span>
			</div>
		</div>
	</div>
	<div style="height:400px;width:100%">

<ngx-charts-bar-vertical-2d
  [view]="graph.view"
  [scheme]="graph.scheme"
  [results]="graph.results"
  [gradient]="graph.gradient"
  [xAxis]="graph.xAxis"
  [yAxis]="graph.yAxis"
  [legend]="graph.legend"
  [showXAxisLabel]="graph.showXAxisLabel"
  [showYAxisLabel]="graph.showYAxisLabel"
  [xAxisLabel]="graph.xAxisLabel"
  [yAxisLabel]="graph.yAxisLabel"
  [legendTitle]="graph.legendTitle"
  (select)="graph.select($event)"
  (activate)="graph.activate($event)"
  (deactivate)="graph.deactivate($event)">
</ngx-charts-bar-vertical-2d>
	<!-- ngx-charts-bar-vertical-2d
		[scheme]="graph.scheme"
		[legend]="graph.legend"
		[showXAxisLabel]="graph.showXAxisLabel"
		[showYAxisLabel]="graph.showYAxisLabel"
		[xAxis]="graph.xAxis"
		[yAxis]="graph.yAxis"
		[xAxisLabel]="graph.xAxisLabel"
		[yAxisLabel]="graph.yAxisLabel"
		[results]="graph.results">
	</ngx-charts-bar-vertical-2d -->
	<!-- ngx-charts-bar-horizontal-normalized>
	  [scheme]="graph.scheme"
	  [results]="graph.results"
	  [xAxis]="graph.xAxis"
	  [yAxis]="graph.yAxis"
	  [legend]="graph.legend"
	  [showXAxisLabel]="graph.showXAxisLabel"
	  [showYAxisLabel]="graph.showYAxisLabel"
	  [xAxisLabel]="graph.xAxisLabel"
	  [yAxisLabel]="graph.yAxisLabel"
	  [legendTitle]="graph.legendTitle"
	  (select)="graph.select"
	  (activate)="graph.select"
	  (deactivate)="graph.select">
	</ngx-charts-bar-horizontal-normalized-->
<!-- ngx-charts-area-chart-stacked
	[scheme]="graph.scheme"
	[legend]="graph.legend"
	[showXAxisLabel]="graph.showXAxisLabel"
	[showYAxisLabel]="graph.showYAxisLabel"
	[xAxis]="graph.xAxis"
	[yAxis]="graph.yAxis"
	[xAxisLabel]="graph.xAxisLabel"
	[yAxisLabel]="graph.yAxisLabel"
	[timeline]="graph.timeline"
	[results]="graph.results">
</ngx-charts-area-chart-stacked-->
<!--ngx-charts-line-chart
	[scheme]="avg_order_graph.scheme"
	[legend]="avg_order_graph.legend"
	[showXAxisLabel]="avg_order_graph.showXAxisLabel"
	[showYAxisLabel]="avg_order_graph.showYAxisLabel"
	[xAxis]="avg_order_graph.xAxis"
	[yAxis]="avg_order_graph.yAxis"
	[xAxisLabel]="avg_order_graph.xAxisLabel"
	[yAxisLabel]="avg_order_graph.yAxisLabel"
	[timeline]="avg_order_graph.timeline"
	[results]="avg_order_graph.results">
</ngx-charts-line-chart-->

	</div>
	<!-- div class="card my-3">
		<table class="w-100 table-striped">
			<thead>
				<tr class="border">
					<th class="border">Sucursal</th>
					<th class="border">Ordenes</th>
					<th class="border">Promedio</th>
					<th class="border">Máximo</th>
					<th class="border">Mínimo</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let x of graph.results">
					<td class="border">{{x.name}}</td>
					<td class="border">{{x.name}}</td>
				</tr>
			</tbody>
		</table>
		<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	</div -->
</div>
