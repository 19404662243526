import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Form, Question } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

interface CQuestion extends Question
{
	cid:string;
}

@Component({
	selector: 'app-save-form',
	templateUrl: './save-form.component.html',
	styleUrls: ['./save-form.component.css']
})
export class SaveFormComponent extends BaseComponent implements OnInit
{
	form:Form = GetEmpty.form();
	question_list:CQuestion[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((paramMap:ParamMap)=>
			{
				this.is_loading = true;
				if( !paramMap.has('id') )
					return of({form: GetEmpty.form(), questions:[]});

				return this.rest.form_info.get( paramMap.get('id') );
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.is_loading = false;
				this.form = response.form;
				this.question_list = response.questions.map((q:CQuestion,cid)=>({...q, cid:''+cid}));
				if( this.question_list.length == 0 )
				{
					this.addQuestion();
				}
				this.sortQuestions();
			},
			error:(error)=>
			{
				this.showError( error );
			}
		});

	}

	toggleRequired(q:CQuestion)
	{
		q.required = q.required ? null : 1;
	}

	markAllRequired(evt: Event)
	{
		let input = evt.target as HTMLInputElement;
		let value = input.checked ? 1 : null;

		this.question_list.forEach(q=>
		{
			q.required = value;
		});
	}

	someChecked():boolean
	{
		//if there is one question that is null, return false
		return !this.question_list.some(q=>!q.required);
	}

	removeQuestion(cquestion:CQuestion)
	{
		this.subs.sink = this.confirmation.showConfirmAlert(cquestion, 'Eliminar','¿Está seguro de eliminar esta pregunta?')
		.pipe(filter((result)=>result.accepted))
		.subscribe((result)=>
		{
			cquestion.status = 'DELETED';
		});
	}

	sortQuestions()
	{
		//sort questions by question.priority
		this.question_list.sort((a,b)=>
		{
			return b.priority - a.priority;
		});
	}

	save(evt: Event)
	{

		if( this.question_list.length == 0 )
		{
			this.showError('Debe agregar almenos 1 pregunta');
			return;
		}

		if( this.form.id )
		{
			this.subs.sink = this.rest.form_info
			.update({form:this.form, questions:this.question_list})
			.subscribe
			({
				next:(response)=>
				{
					this.showSuccess('Formulario actualizado');
					this.router.navigateByUrl('/',{skipLocationChange: true})
					.then(()=>
					{
						this.router.navigate(['/list-form'],{replaceUrl:true});
					});
				},
				error:(error)=>
				{
					this.showError( error );
				}
			});
		}
		else
		{
			this.subs.sink = this.rest.form_info
			.create({form:this.form, questions:this.question_list})
			.subscribe
			({
				next:(response)=>
				{
					this.showSuccess('Formulario creado');
					this.router.navigateByUrl('/',{skipLocationChange: true})
					.then(()=>
					{
						this.router.navigate(['/list-form'],{replaceUrl:true});
					});
				},
				error:(error)=>
				{
					this.showError(error);
				}
			});
		}
	}

	addQuestion()
	{
		let q = GetEmpty.question();
		let cid = this.question_list.length.toString();
		this.question_list.push({ ...q, cid });
	}
}
