<div class="container-fluid">
	<h1 class="my-3">Reporte de Ventas de afiliados</h1>

	<div class="card p-1 p-md-3 mb-3">
		<div class="row">
			<div class="col-4">
				<label>Fecha inicial</label>
				<input type="date" name="date_start" class="form-control" [(ngModel)]="csearch.eq.date_start">
			</div>
			<div class="col-4">
				<label>Fecha Final</label>
				<div>
					<input type="date" name="date_end" class="form-control" [(ngModel)]="csearch.eq.date_end">
				</div>
			</div>
			<div class="col-4">
				<label>&nbsp;</label>
				<div>
					<button type="button" (click)="search(csearch )" class="btn btn-primary">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<app-loading [is_loading]="is_loading"></app-loading>

	<div class="card p-1 p-md-3">
		<table class="w-100 border">
			<thead>
				<tr class="">
					<th>Agente</th>
					<th>Artículo</th>
					<th>Categoría</th>
					<th class="text-end">Cantidad</th>
					<th class="text-end">Ventas</th>
					<th class="text-end">Comisión</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let resume of resumes">
					<tr class="border">
						<th>
							<a routerLink="/list-order" [queryParams]="{'eq.created_by_user_id': resume.user_id}">
							{{resume.user_name}}
							</a>
						</th>
						<th></th>
						<th></th>
						<th class="text-end">{{resume.order_item_qty | number}}</th>
						<th class="text-end">{{resume.item_total | currency}}</th>
						<th class="text-end">{{resume.commission_fee | number}}</th>
						<th class="text-end">
							<button type="button" class="btn btn-primary" (click)="resume.show_resume = !resume.show_resume">mostrar</button>
						</th>
					</tr>
					<ng-container *ngIf="resume.show_resume">
						<tr *ngFor="let r of resume.rows">
							<td class="border "></td>
							<td class="border ">{{r.item_name}}</td>
							<td class="border ">{{r.category_name}}</td>
							<td class="border text-end">{{r.order_item_qty | number}}</td>
							<td class="border text-end">{{r.item_total | currency}}</td>
							<td class="border text-end">{{r.commission_fee | number}}</td>
							<td></td>
						</tr>
					</ng-container>
				</ng-container>
			</tbody>
		</table>
	</div>
</div>
