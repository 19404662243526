import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FormInfo, ResponseInfo } from 'src/app/models/models';
import { GetEmpty } from 'src/app/classes/Empties';
import { User } from 'src/app/models/RestModels';
import { mergeMap } from 'rxjs/operators';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';

@Component({
	selector: 'app-save-response',
	templateUrl: './save-response.component.html',
	styleUrls: ['./save-response.component.css']
})
export class SaveResponseComponent extends BaseComponent implements OnInit
{

	response_info: ResponseInfo = GetEmpty.response_info(0);
	user_id: number | null = null;
	user_list:User[] = [];

	ngOnInit(): void {

		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((paramMap: ParamMap) =>
			{
				this.is_loading = true;

				if (paramMap.has('user_id'))
				{
					this.user_id = parseInt(paramMap.get('user_id'));
				}

				if (paramMap.has('id'))
				{
					return forkJoin({
						response_info: this.rest.response_info.get(paramMap.get('id')),
						users: this.rest.user.search({ eq:{status: 'ACTIVE'}	,limit:99999})
					})
				}

				return this.rest.form_info.get(paramMap.get('form_id'))
				.pipe
				(
					mergeMap((form_info: FormInfo) =>
					{
						let tmp_response_info = GetEmpty.response_info(form_info.form.id);

						tmp_response_info.response.form_id = form_info.form.id;

						tmp_response_info.form = form_info.form;

						tmp_response_info.answers = form_info.questions
						.map((question) =>
						{
							let answer = GetEmpty.answer();
							answer.question_id = question.id;

							return {question,answer};
						});

						return forkJoin
						({
							response_info: of(tmp_response_info),
							users: this.rest.user.search({ eq:{status: 'ACTIVE'}	,limit:99999})
						});
					}),
					mergeMap((response) =>
					{
						if (this.user_id)
						{
							response.response_info.response.user_id = this.user_id;

							let user = response.users.data.find((user) => user.id == this.user_id);
							if (user)
							{
								response.response_info.user = user;
							}
						}

						return forkJoin({
							response_info: of(response.response_info),
							users: of(response.users)
						});
					})
				)
			}),
		)
		.subscribe
		({
			next: (result) =>
			{
				this.is_loading = false;
				this.response_info = result.response_info;
				this.sortAnswers();
				this.user_list = result.users.data;
			},
			error: (error) =>
			{
				this.showError(error);
			}
		});
	}

	sortAnswers()
	{
		this.response_info.answers.sort((a,b) => b.question.priority - a.question.priority);
	}

	changeResponseUser(user: User)
	{
		if (!user)
		{
			this.response_info.response.user_id = null;
			this.response_info.user = null;
			return;
		}
		this.response_info.response.user_id = user.id;
		this.response_info.user = user;
	}

	save(evt: Event)
	{
		evt.preventDefault();
		this.is_loading = true;

		if(this.response_info.response.id != 0)
		{
			this.subs.sink = this.rest.response_info.update(this.response_info)
			.subscribe
			({
				next: (response) =>
				{
					this.is_loading = false;
					this.showSuccess('Respuesta actualizada');
					this.location.back();
				},
				error: (error) =>
				{
					this.is_loading = false;
					this.showError(error);
				}
			});
			return;
		}

		this.subs.sink = this.rest.response_info.create(this.response_info)
		.subscribe
		({
			next: (response) =>
			{
				this.is_loading = false;
				this.showSuccess('Respuesta creada');
				this.location.back();
			},
			error: (error) =>
			{
				this.is_loading = false;
				this.showError(error);
			}
		});
	}
}
