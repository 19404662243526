<div class="container-fluid">
	<h1 class="my-3">Reporte Ventas</h1>
	<div class="card p-3">
		<div class="row">
			<div class="col-4">
				<label>Sucursal</label>
				<select class="form-control" [(ngModel)]="order_report_search.eq.store_id">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let store of store_list" [value]="store.id">{{store.name |titlecase}}</option>
				</select>
			</div>
			<div class="col-4">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="ge_created" [ngModel]="ge_created" (ngModelChange)="onGeCreatedChange($event)" class="form-control">
			</div>
			<div class="col-4">
				<label>Fecha Final</label>
				<input type="datetime-local" name="le_created" [ngModel]="le_created" (ngModelChange)="onLeCreatedChange($event)" class="form-control">
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-3 form-check form-switch mx-3 pt-2">
				<label class="form-check-label">
					<input type="checkbox" (click)="toggleValue('store_id')" class="form-check-input" [checked]="selected_fields['store_id']"> Sucursal
				</label>
			</div>
			<div class="col-3 form-check form-switch pt-2">
				<label class="form-check-label">
					<input type="checkbox" (click)="toggleValue('cashier_user_id')" class="form-check-input" [checked]="selected_fields['cashier_user_id']"> Cajero
				</label>
			</div>

			<!--div class="col-2 form-check form-switch pt-2">
				<label class="form-check-label">
					<input type="checkbox" (click)="toggleValue('item_id')" class="form-check-input" [checked]="selected_fields['item_id']"> Artículo
				</label>
			</div>
			<div class="col-2 form-check form-switch pt-2">
				<label class="form-check-label">
					<input type="checkbox" (click)="toggleValue('category_id')" class="form-check-input" [checked]="selected_fields['category_id']"> Categoria
				</label>
			</div-->
			<div class="col">
				<button type="button" class="btn btn-primary w-100" (click)="makeASearch()">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
					Buscar
				</button>
			</div>
		</div>
	</div>
	<div class="card p-3 my-3">
		<div class="row fw-bold">
			<div class="col-2">Sucursal</div>
			<div class="col-2">Cajero</div>
			<div class="col-2 text-end">Ordenes</div>
			<div class="col-2 text-end">IVA</div>
			<div class="col-2 text-end">Pagado</div>
			<div class="col-2 text-end">Total</div>
		</div>
		<div class="row" *ngFor="let order_report of order_report_list">
			<div class="col-2">{{store_dictionary[order_report.store_id]?.name | titlecase}}</div>
			<div class="col-2">
				<span *ngIf="order_report.cashier_user_id">{{user_dictionary[order_report.cashier_user_id]?.name | titlecase}}</span>
			</div>
			<div class="col-2 text-end">{{order_report.order_count | number: '1.0-0'}}</div>
			<div class="col-2 text-end">{{order_report.tax_total |  number: '1.2-2'}}</div>
			<div class="col-2 text-end">{{order_report.paid_total| number: '1.2-2'}}</div>
			<div class="col-2 text-end">{{order_report.grand_total | number: '1.2-2'}}</div>
		</div>
		<div class="row fw-bold border-top">
			<div class="col-2"></div>
			<div class="col-2"></div>
			<div class="col-2 text-end">{{order_count}}</div>
			<div class="col-2 text-end">{{tax_total | currency:'$'}}</div>
			<div class="col-2 text-end">{{paid_total|currency:'$'}}</div>
			<div class="col-2 text-end">{{grand_total |currency:'$'}}</div>
		</div>
	</div>
</div>
