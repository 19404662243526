import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';

import {Address} from '../../models/RestModels';
import {User} from '../../models/RestModels';

@Component
({
	selector: 'app-list-address',
	templateUrl: './list-address.component.html',
	styleUrls: ['./list-address.component.css']
})

export class ListAddressComponent extends BaseComponent implements OnInit {
	file:File = null;
	show_import:boolean = false;
	address_search:SearchObject<Address> = this.getEmptySearch()
	address_list:Address[] = [];
	user:User | null = null;

	ngOnInit()
	{
		this.path = '/list-address';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","name","phone","business_name","email","rfc",
				"user_id","address","zipcode",
				"country","state","city","suburb",
				"note","created","updated" 
			];

			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.address_search = this.getSearch(queryParamMap, fields, extra_keys );

			this.setTitle('Direcciones');
			this.is_loading = true;
			this.current_page = this.address_search.page;

			this.is_loading = true;
			this.subs.sink = forkJoin
			({
				address : this.rest.address.search(this.address_search),
				user : this.rest.user.get( this.address_search.eq.user_id )
			})
			.subscribe((responses)=>
			{
				this.address_list = responses.address.data;
				this.setPages( this.address_search.page, responses.address.total );
				this.user = responses.user;
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}
}
