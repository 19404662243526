<div class="container-fluid">
	<h1 class="my-3">Cortes de caja</h1>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Usuario</th>
						<th>Sucursal</th>
						<th class="text-end">Fecha</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let c of cash_close_list">
						<td>
							<img *ngIf="c?.user?.image_id" [src]="rest.getImagePath(c?.user.image_id)" style="max-width:30px;max-height:30px;">
							<span *ngIf="!c.user.image_id" class="default_image">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path _ngcontent-tvn-c138="" d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"></path></svg>
							</span>
							&nbsp;
							<a [routerLink]="['/user',c?.user.id]">{{c.user.name}}</a>
						</td>
						<td>{{c?.store?.name}}</td>
						<td class="text-end">{{c?.cash_close.created | shortDate:'full' }}</td>
						<td class="text-end">
							<a [routerLink]="['/print-corte',rest.local_preferences.default_cash_close_receipt,c.cash_close.id]" class="btn btn-primary">
								<svg _ngcontent-kdj-c245="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g _ngcontent-kdj-c245="" fill="none"><path _ngcontent-kdj-c245="" d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"></path></g></svg>
								<label class="d-none d-xxl-inline">
									Imprimir
								</label>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
