import { Component, Output,EventEmitter, Input } from '@angular/core';
import { GetEmpty } from 'src/app/classes/Empties';
import {PaymentInfo} from 'src/app/models/models';
import { Bank_Movement } from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-add-pos-bill',
	templateUrl: './add-pos-bill.component.html',
	styleUrls: ['./add-pos-bill.component.css']
})
export class AddPosBillComponent extends BaseComponent
{
	total:number | null = null;
	note:string	= '';
	receipt_attachment_id:number | null = null;
	imprimir_recibo:boolean = true;
	tag:string = '';
	@Input() store_id: number | null = null;
	@Output() expenseAdded = new EventEmitter<PaymentInfo | null>();

	ngOnInit(): void
	{
		this.total = null;
		this.note	= '';
	}

	save(evt:Event)
	{
		this.is_loading = true;

		let bank_movement:Bank_Movement =  GetEmpty.bank_movement();

		let payment:Partial<PaymentInfo> = {
			payment:{
				change_amount: 0,
				concept: this.note,
				currency_id: 'MXN',
				facturado: 'NO',
				payment_amount: this.total,
				received_amount: this.total,
				sat_pdf_attachment_id: null,
				sat_uuid: null,
				sat_xml_attachment_id: null,
				store_id: this.store_id,
				sync_id: this.rest.getSyncId(),
				tag: this.tag,
				type:'expense',
			},
			movements:
			[{
				bank_movement:
				{
					...bank_movement,
					type: 'expense',
					transaction_type: 'CASH',
					status			: 'ACTIVE',
					origin_bank_name: null,
					total: this.total,
					receipt_attachment_id:this.receipt_attachment_id,
					amount_received: this.total,
					exchange_rate: 1,
					currency_id: 'MXN'
				},
				bank_movement_orders:[],
				bank_movement_bills:[{
					bill:{
						total: this.total,
						note: this.note,
						accepted_status:'ACCEPTED',
						paid_status:'PAID',
						currency_id:'MXN',
						receipt_attachment_id:this.receipt_attachment_id,
						name: this.tag,
						status:'ACTIVE'
					},
					bank_movement_bill:
					{
						amount:this.total,
						currency_amount: this.total,
						exchange_rate: 1,
						currency_id:'MXN',
						status:'ACTIVE',
					}
				}]
			}]
		};

		this.subs.sink = this.rest.payment_info
		.create(payment)
		.subscribe((response)=>
		{
			this.expenseAdded.emit(response);
			if( this.imprimir_recibo )
			{
				let x	= window.open('/#/print-payment-expense/'+response.payment.id+'/print=1');
			}

			this.total = null;
			this.note	= '';
			this.receipt_attachment_id = null;
			this.tag = '';

			//let form = evt.target as HTMLFormElement;
			//form.reset();
			setTimeout(()=>{this.imprimir_recibo = true },300);
			this.is_loading = false;
		},(error)=>this.showError(error));
		evt.preventDefault();
	}

	cancel()
	{
		this.expenseAdded.emit(null);
	}

	imprimirChanged(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		this.imprimir_recibo = input.checked;
	}
}
