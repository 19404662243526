<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{offer.id ? 'Actualizar Oferta':'Agregar nueva Oferta'}}</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card px-1 px-md-3 py-3">
			<div class="row">
				<!--div class="col-6 col-md-4">
					<label class="">Categoría</label>
					<select name="category_id" [(ngModel)]="offer.category_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of category_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div-->
				<div class="col-6 col-md-4">
					<label class="">Código del cupón</label>
					<input type="text" name="coupon_code" [(ngModel)]="offer.coupon_code" class="form-control" required>
				</div>
				<!--div class="col-6 col-md-4">
					<label class="">Articulo de regalo</label>
					<select name="gift_item_id" [(ngModel)]="offer.gift_item_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of item_list" [value]="c.id">{{c.category_id}} {{c.name}}</option>
					</select>
				</div-->
				<div class="col-6 col-md-4">
					<label class="">Artículo</label>
					<select name="item_id" [(ngModel)]="offer.item_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of item_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Sucursal</label>
					<select name="store_id" [(ngModel)]="offer.store_id" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<input type="hidden" value="N_X_M" [(ngModel)]="offer.type" name="type" required>
				<div class="col-6 col-md-4">
					<label class="">Elementos que el cliente adquiere</label>
					<input type="number" name="n" [(ngModel)]="offer.n" [min]="1" class="form-control" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Elementos que el cliente debe pagar</label>
					<input type="number" name="m" [(ngModel)]="offer.m"  [min]="1" class="form-control" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">TAG(En vez de por articulo o categoria es el tag</label>
					<input type="text" name="tag" [(ngModel)]="offer.tag" maxlength="20" class="form-control">
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-lg-6">
				<div class="row">
					<div class="col-12 col-md-6 col-lg-12">
						<h2 class="my-3">Vigencia</h2>
						<div class="card px-1 px-md-3 py-3">
							<div class="row">
								<div class="col-6">
										<label class="">Fecha Inicio</label>
										<input type="datetime-local" name="valid_from" [(ngModel)]="offer.valid_from" class="form-control">
								</div>
								<div class="col-6">
									<label class="">Fecha Fin</label>
									<input type="datetime-local" name="valid_thru" [(ngModel)]="offer.valid_thru" class="form-control">
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-12">
						<h2 class="my-3">Horario</h2>
						<div class="card px-1 px-md-3 py-3">
							<div class="row">
								<div class="col-6 col-md-4">
									<label class="">Inicio</label>
									<input type="time" name="hour_start" [(ngModel)]="offer.hour_start" class="form-control">
								</div>
								<div class="col-6">
									<label class="">Fin</label>
									<input type="time" name="hour_end" [(ngModel)]="offer.hour_end" class="form-control">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6">
				<h2 class="my-3">Días Válidos</h2>
				<div class="card px-1 px-md-3 py-3">
					<div class="col-12 col-md-4">
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_sunday" (click)="toggleValue('is_valid_sunday')" class="form-check-input" [checked]="offer.is_valid_sunday== 1">
								Domingo
							</label>
						</div>
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_monday" (click)="toggleValue('is_valid_monday')" class="form-check-input" [checked]="offer.is_valid_monday== 1">
								Lunes
							</label>
						</div>
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_tuesday" (click)="toggleValue('is_valid_tuesday')" class="form-check-input" [checked]="offer.is_valid_tuesday== 1">
								Martes
							</label>
						</div>
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_wednesday" (click)="toggleValue('is_valid_wednesday')" class="form-check-input" [checked]="offer.is_valid_wednesday== 1">
								Miercoles
							</label>
						</div>
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_thursday" (click)="toggleValue('is_valid_thursday')" class="form-check-input" [checked]="offer.is_valid_thursday== 1">
								Jueves
							</label>
						</div>
						<div class="form-check form-switch pt-2">
							<label class="form-check-label">
								<input type="checkbox" name="is_valid_friday" (click)="toggleValue('is_valid_friday')" class="form-check-input" [checked]="offer.is_valid_friday== 1">
								Viernes
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-right">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
