<div class="container-fluid">
	<div class="row align-items-center my-3">
		<div class="col-10">
			<h1>Tipos de ingreso</h1>
		</div>
		<div class="col-2 d-print-none">
		<button type="button" class="btn btn-secondary w-100" (click)="print()">
			<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 7H6V3h12Zm0 5.5q.425 0 .712-.288q.288-.287.288-.712t-.288-.713Q18.425 10.5 18 10.5t-.712.287Q17 11.075 17 11.5t.288.712q.287.288.712.288ZM16 19v-4H8v4Zm2 2H6v-4H2v-6q0-1.275.875-2.137Q3.75 8 5 8h14q1.275 0 2.138.863Q22 9.725 22 11v6h-4Z"/></svg>
			<span class="d-none d-lg-inline">Imprimir</span>
		</button>
		</div>
	</div>
	<div class="card py-3 px-1 px-xl-3 my-3">
		<div class="row">
			<div class="col-6 col-lg-3">
				<label>Fecha Inicio</label>
				<input type="datetime-local" name="start" [(ngModel)]="start" (ngModelChange)="onDateChange($event,report_search.ge,'created')" class="form-control d-print-none">
				<div class="d-none d-print-block">{{start|date}}</div>
			</div>
			<div class="col-6 col-lg-3">
				<label>Fecha Fin</label>
				<input type="datetime-local" name="end" [(ngModel)]="end" (ngModelChange)="onDateChange($event,report_search.le,'created')" class="form-control d-print-none">
				<div class="d-none d-print-block">{{end|date}}</div>
			</div>
			<div class="col-6 col-lg-3">
				<label>Sucursal</label>
				<select name="store_id" [(ngModel)]="report_search.eq.store_id" class="form-control d-print-none">
					<option value="">Seleccionar</option>
					<option *ngFor="let s of store_list" [value]="s.id">{{s.name}}</option>
				</select>
				<div *ngIf="report_search.eq.store_id && records.length" class="d-none d-print-block">
					{{records[0]['store_name']}}
				</div>
			</div>
			<div class="col-6 col-lg-3 d-print-none">
				<label>&nbsp;</label>
				<div class="">
					<button type="button" class="w-100 btn btn-primary" (click)="search(report_search)">Buscar</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card p-1 p-xl-3">
		<table class="w-100">
			<thead class="border-bottom">
				<tr>
					<th>Sucursal</th>
					<th class="text-center">Moneda</th>
					<th class="text-center">Tipo</th>
					<th class="text-end">Cantidad</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of records">
					<td>{{r['store_name']}}</td>
					<td class="text-center">{{r['currency_id']}}</td>
					<td class="text-center">{{type[r['type']]}}</td>
					<td class="text-end">{{r['total_income'] | currency}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
