import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { Category, Order_Item_Cost, Store } from 'src/app/models/RestModels';
import { RestResponse, SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';

interface Report_Search
{
	system_start:Date;
	system_end:Date;
}

interface Profit
{
	bigger:number;
	profit_color:string;
	profit_percent:number;
	cost_percent: number;
}

interface CRow
{
	row:Order_Item_Cost;
	profit:Profit;
}

@Component({
	selector: 'app-report-order-item-profit',
	templateUrl: './report-order-item-profit.component.html',
	styleUrls: ['./report-order-item-profit.component.css']
})
export class ReportOrderItemProfitComponent extends BaseComponent implements OnInit
{
	store_list: Store[] = [];
	report_search: SearchObject<Order_Item_Cost> = this.getEmptySearch();
	endx: string = '';
	startx: string = '';
	report_list:CRow[] = [];
	category_list: Category[] = [];

	ngOnInit(): void
	{
		this.path = '/report-order-item-profit';
		this.page_size = 50;

		this.route.queryParamMap.pipe
		(
			mergeMap
			(
				(param_map: ParamMap)=>{

				this.setTitle('Reporte ganancia por articulos');
				this.is_loading = true;
				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.getStores(true),
					category: this.rest.category.getAll()
				});
			}),
			mergeMap((response)=>
			{
				this.store_list = response.stores.data;
				this.category_list = response.category.data;
				let params = [
					'child_items_cost', 'cost', 'created',
					'id', 'ingredients_cost', 'item_cost',
					'item_id', 'name', 'order_id',
					'order_item_id', 'qty', 'sale_profit',
					'sale_total', 'total', 'category_id',
				];

				this.report_search = this.getSearch(response.param_map, params ,['item_name','store_id','start','end']);

				if( !this.report_search.ge.created )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);
					this.report_search.ge.created = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.le.created )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours( 23, 59, 59, 0 );

					this.report_search.le.created= end_of_month;//Utils.getUTCMysqlStringFromDate( end_of_month );
				}

				let start = new Date( this.report_search.ge.created as Date);
				let end = new Date( this.report_search.le.created as Date );

				start.setSeconds( 0 );
				end.setSeconds( 0 );

				this.startx	= Utils.getLocalMysqlStringFromDate(start);
				this.endx	= Utils.getLocalMysqlStringFromDate( end );

				//if( !response.param_map.has('store_id') )
				//{
				//	this.report_search.eq.store_id = this.rest.current_user.store_id;
				//}

				this.report_search.sort_order = ['order_id_DESC'];
				this.report_search.limit = this.page_size;
				return this.rest.order_item_cost.search( this.report_search );
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			//let start = Utils.getMysqlStringFromDate( this.report_search.search_extra['start'] as Date);
			//let end = Utils.getMysqlStringFromDate( this.report_search.search_extra['end'] as Date);

			this.report_list = response.data.map((row)=>{
				let bigger = row.sale_total;

				let profit_color = 'bg-success';
				//let profit_color = 'yellow';

				let profit_percent	= (row.total-row.cost)/row.sale_total;
				let cost_percent	= row.cost/row.sale_total;

				if( row.cost > row.sale_total )
				{
					bigger = row.cost;
					profit_color = 'bg-danger';
					profit_percent = (row.cost-row.sale_total)/row.cost;
					cost_percent = row.sale_total/row.cost;
				}

				return {row,profit:{bigger,cost_percent, profit_percent, profit_color}};
			});
			this.setPages( this.report_search.page, response.total );
		},(error)=>this.showError(error))
	}

	exportReport(evt:Event)
	{
		const LIMIT = 1000;
		let rows: Object[] = [];
		let local_date = Utils.getLocalMysqlStringFromDate(new Date()).substring(0, 10);
		this.is_loading = true;
		this.showWarning('Exportando, por favor espere...');
		let tmp_search:SearchObject<Order_Item_Cost> = {...this.report_search};

		tmp_search.limit = LIMIT;

		this.subs.sink = this.rest.order_item_cost.search(tmp_search).pipe
		(
			mergeMap((response)=>{
				let observables:Observable<RestResponse<Order_Item_Cost>>[] = [ of(response) ];
				let pages= response.total/LIMIT;

				for(let i=1;i<pages;i++)
				{
					let s:SearchObject<Order_Item_Cost> = {...tmp_search};
					s.limit = LIMIT;
					s.page = i;
					observables.push( this.rest.order_item_cost.search( s ) );
				}

				return forkJoin( observables );
			}),
			mergeMap((response)=>{
				let all:RestResponse<Order_Item_Cost> = { total: 0, data: []};

				response.forEach((i)=>{
					all.total = i.total;
					all.data.push(...i.data);
				})

				return of( all );
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.showSuccess('Reporte exportado');
			for (let x of response.data)
			{
				let id = x.order_id;
				let nombre = x.name;
				let cantidad = x.qty;
				let costo_ingredientes = x.ingredients_cost;
				let costo_opciones = x.child_items_cost;
				let costo_total = x.item_cost;
				let ingreso = x.sale_total;
				let ganancia = x.sale_profit;
				let fecha = Utils.getLocalMysqlStringFromDate(x.created).substring(0, 10);

				let profit_percent	= (x.total-x.cost)/x.sale_total;

				if( x.cost > x.sale_total )
				{
					profit_percent = (x.cost-x.sale_total)/x.cost;
				}

				let margen = Math.round(profit_percent * 100)+'%';
				
				let new_obj = {
					'Id': id,
					'Nombre': nombre,
					'Cantidad': cantidad,
					'Costo Ingredientes': costo_ingredientes,
					'Costo Opciones': costo_opciones,
					'Costo Total': costo_total,
					'Ingreso': ingreso,
					'Ganancia': ganancia,
					'Fecha': fecha,
					'Margen': x.sale_profit < 0 ? '-'+margen : margen,
				}

				rows.push(new_obj);
			}

			ExcelUtils.array2xlsx(rows, 'order-profit-'+local_date+'.xlsx', ['Id', 'Nombre', 'Cantidad', 'Costo Ingredientes', 'Costo Opciones', 'Costo Total', 'Ingreso', 'Ganancia', 'Fecha', 'Margen']);
		}, (error)=>this.showError(error));
	}
}
