import { Component, OnDestroy, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Utils, Coordinates } from 'src/app/classes/Utils';
import {OrderInfo} from 'src/app/models/models';
import {Order, Price_Type, Store} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component
({
	selector: 'app-delivery-orders',
	templateUrl: './delivery-orders.component.html',
	styleUrls: ['./delivery-orders.component.css']
})
export class DeliveryOrdersComponent extends BaseComponent implements OnInit, OnDestroy {

	file:File = null;
	show_import:boolean = false;
	order_search:SearchObject<Order> = this.getEmptySearch();
	total_search:SearchObject<Order> = this.getEmptySearch();

	order_info_list:OrderInfo[] = [];

	store_list:Store[] = [];
	price_type_list:Price_Type[] = [];
	store_dictionary:Record<number,Store> = {};
	price_type_dic:Record<number,Price_Type> = {};
	pendiente_amount:number = 0;
	geolocation_watch_id:number | null = null;
	order_distance:Record<number,number> = {};

	ngOnInit()
	{
		this.path = '/delivery-orders';

		this.subs.sink = this.rest.updates.subscribe((message)=>
		{
			console.log('llego mensaje de socket pedidos', message);
			this.router.navigateByUrl('/',{skipLocationChange: true}).then(()=>{
				this.search(this.order_search);
			});
		});


		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap:ParamMap) =>
		{
			let fields = [ "id","client_user_id","cashier_user_id","delivery_user_id","store_id","shipping_address_id",
				"price_type_id","status","type","client_name","total","subtotal","tax","amount_paid",
				"address","suburb","city","state","zipcode","name","created_by_user_id","delivery_status",
				"updated_by_user_id","created","updated" ];

			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.order_search = this.getSearch( queryParamMap, fields,extra_keys );
			this.order_search.eq.service_type = 'TOGO';
			this.order_search.eq.status = 'ACTIVE';
			this.order_search.eq.delivery_user_id = this.rest.current_user.id;

			this.setTitle('Ordenes de entrega');
			this.is_loading					= true;
			this.order_search.limit			= this.page_size;
			this.current_page				= this.order_search.page;
			this.order_search.sort_order	= ['id_DESC'];
			this.is_loading					= true;


			this.total_search = this.getSearch(queryParamMap, fields);
			this.total_search.eq.service_type = 'TOGO';
			this.total_search.eq.delivery_status = 'DELIVERED';
			this.total_search.eq.paid_status = 'PENDING';

			if('geolocation' in navigator)
			{
				/* geolocation is available */
				const options = {
					enableHighAccuracy: true,
					maximumAge: 30000,
					timeout: 27000
				};

				this.geolocation_watch_id = navigator.geolocation.watchPosition((p)=>this.processPosition(p),(error)=>{},options );
			}

			this.subs.sink = this.rest.order_info.search( this.order_search ).pipe
			(
				mergeMap((order_data)=>
				{
					order_data.data.forEach((o)=>
					{
						this.order_distance[ o.order.id ] = -1;
					});
					return forkJoin
					({
						order : of( order_data ),
						store : this.rest.getStores(true),
						price_type : this.rest.getPriceTypes(true),
						order_total: order_data.data.length == 0
							? of({total:0,data:[]})
							: this.rest.getOrderTotal(this.total_search )
					})
				})
			)
			.subscribe((responses)=>
			{
				this.is_loading = false;
				responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);
				responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
				this.price_type_list	= responses.price_type.data;
				this.pendiente_amount	= responses.order_total.total;
				this.order_info_list = responses.order.data;
				this.setPages( this.order_search.page, responses.order.total );
				this.store_list = responses.store.data;
			},(error)=>this.showError(error));
		});
	}

	processPosition(geolocation_position:GeolocationPosition)
	{
		let current:Coordinates = {
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};

		this.order_info_list.forEach((order_info:OrderInfo)=>
		{
			if( order_info.order.lat )
			{
				let to:Coordinates = {
					lat: order_info.order.lat,
					lng: order_info.order.lng
				};
				let d:number = Utils.distanceTo(current, to );
				console.log('Distance is ',d);
				this.order_distance[ order_info.order.id ] = d ;
			}
			else
			{
				this.order_distance[ order_info.order.id ] = -1;
			}
		});
	}

	ngOnDestroy()
	{
		if( this.geolocation_watch_id )
		{
			navigator.geolocation.clearWatch(this.geolocation_watch_id);
		}
		console.log('Usubscribing delivery-orders');
		super.ngOnDestroy();
	}

	/**
	* Returns the distance along the surface of the earth from ‘this’ point to destination point.
	*
	* Uses haversine formula: a = sin²(Δφ/2) + cosφ1·cosφ2 · sin²(Δλ/2); d = 2 · atan2(√a, √(a-1)).
	*
	* @param	{Coordinates} point_a - Latitude/longitude of destination point.
	* @param	{Coordinates} point_b
	* @param	{number} [radius=6371e3] - Radius of earth (defaults to mean radius in metres).
	* @returns {number} Distance between this point and destination point, in same units as radius.
	*/



	testNotification()
	{
		//this.rest.updateOrder(3);
		this.rest.sendNotification('order', 3);
		//this.rest.sendNotification('update', 3);
	}
}
