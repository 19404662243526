import { Component } from '@angular/core';
import {User} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-signup',
	templateUrl: './client-signup.component.html',
	styleUrls: ['./client-signup.component.css']
})

export class ClientSignupComponent extends BaseComponent
{
	user:Partial<User> = this.getEmptyUser();

	ngOnInit(): void
	{
		this.route.paramMap.subscribe(()=>{
			this.user = this.getEmptyUser();
		});
	}

	getEmptyUser():Partial<User>
	{
		return {
			type: 'CLIENT',
		};
	}

	save()
	{
		this.user.username = this.user.email;
		this.rest.platform_client.create( this.user ).subscribe((response)=>
		{
			//this.router.navigate(['/login'],{replaceUrl:true});
			this.subs.sink = this.rest.doLoginPlatform( this.user.email, this.user.password ).subscribe(()=>{
				this.router.navigate(['/dashboard'],{replaceUrl:true});
			});
		},(error)=>this.showError(error));
	}
}
