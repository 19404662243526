import { Component } from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ShippingItemInfo, ShippingInfo} from 'src/app/models/models';
import {Category, Item, Shipping_Item, Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';


interface Received_Qty
{
	box_content_id?:number;
	item_id?:number;
	qty:number;
}

@Component({
	selector: 'app-view-shipping',
	templateUrl: './view-shipping.component.html',
	styleUrls: ['./view-shipping.component.css']
})
export class ViewShippingComponent extends BaseComponent {

	item_dictionary:Record<number,Item> = {};
	category_dictionary:Record<number,Category> = {};
	selected_category_id:number = null;
	item_list:Item[];
	category_list:Category[] = [];
	selected_item_id:number = null;
	shipping_info:ShippingInfo = null;
	store_list:Store[] = [];
	store_dictionary:Record<number,Store> = {};
	received_qty:Record<string,Received_Qty> = {};

	total_pallets:number = 0;
	total_boxes:number = 0;
	total_items:number = 0;
	total_received_items:number = 0;
	total_individual_boxes: number = 0;

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.subscribe( paramMap =>
		{
			this.received_qty = {};
			this.is_loading = true;

			this.subs.sink = forkJoin
			({
				shipping_info : this.rest.shipping_info.get(paramMap.get('shipping_id')),
				store : this.rest.store.search({limit:9999,sort_order:['name_ASC']})
			})
			.pipe
			(
				mergeMap((response)=>
				{
					response.store.data.forEach((store)=>this.store_dictionary[store.id]=store);

					let tmp_category_ids:number[] = response.shipping_info.items
					.map((sii:ShippingItemInfo)=>
					{
						return sii.item?.category_id || null;
					});

					let	category_ids:number[] = tmp_category_ids.filter(i=>i!==null);

					return forkJoin
					({
						shipping_info: of( response.shipping_info ),
						category: this.rest.category.search({limit:99999,csv:{id:category_ids},sort_order:['name_ASC']})
					})
				})
			)
			.subscribe((responses)=>
			{
				this.is_loading = false;
				responses.category.data.forEach(c=>this.category_dictionary[c.id]=c);

				this.total_pallets = 0;
				this.total_boxes = 0;
				this.total_items = 0;
				this.total_received_items = 0;
				this.total_individual_boxes= 0;

				if( this.rest.current_permission.shipping_receive_type == 'VALIDATE' )
				{
					responses.shipping_info.items.forEach((sii:ShippingItemInfo)=>
					{
						sii.shipping_item.received_qty = sii.shipping_item.qty;
					});
				}

				responses.shipping_info.items.forEach((sii:ShippingItemInfo)=>
				{
					this.total_items	+= sii.shipping_item.qty;
					this.total_received_items += sii.shipping_item.received_qty;
				});

				this.calculateReceivedItems();

				this.shipping_info = responses.shipping_info;
				this.item_list = [];
				this.category_list = responses.category.data;
				this.shipping_info = responses.shipping_info;
			},(error)=>this.showError(error));
		});
	}

	calculateReceivedItems()
	{
		if( !this.shipping_info )
			return;

		this.total_received_items = 0;
		this.shipping_info.items.forEach((sii:ShippingItemInfo)=>
		{
			console.log( sii );
			this.total_received_items += sii.shipping_item.received_qty;
		});
	}

	save(evt:any)
	{
		//console.log('que Pedo');

		let params:Record<string,any> = {
			shipping_id: this.shipping_info.shipping.id,
			items: this.shipping_info.items.map((i)=>i.shipping_item)
		};

		this.subs.sink = this.rest.update('receiveShipping',params)
		.subscribe((response)=>
		{
			this.showSuccess('Se guardo la información correctamente');
			this.location.back();
			this.router.navigate(['/list-shipping']);
		},(error)=>this.showError(error));
	}

	onClickedCheckBox(evt:Event, shipping_item:Shipping_Item)
	{
		let t = evt.currentTarget as HTMLInputElement;
		shipping_item.received_qty = t.checked ? 1 : 0;
	}
}
