import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';


import {Stock_Record} from '../../models/RestModels';
import {Item} from '../../models/RestModels';
import {Order_Item} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import {Shipping_Item} from '../../models/RestModels';
import {Purchase_Detail} from '../../models/RestModels';
import {ItemInfo, StockRecordInfo} from 'src/app/models/models';
import {Utils} from 'src/app/classes/Utils';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';


@Component({
	selector: 'app-list-stock-record',
	templateUrl: './list-stock-record.component.html',
	styleUrls: ['./list-stock-record.component.css']
})

export class ListStockRecordComponent extends BaseComponent implements OnInit {
	file:File | null = null;
	show_import:boolean = false;
	stock_record_search:SearchObject<Stock_Record> = this.getEmptySearch();
	stock_record_info_list:StockRecordInfo[] = [];

	fecha_inicial:string = '';
	fecha_final:string = '';

	item_list:Item[] = [];
	order_item_list:Order_Item[] = [];
	store_list:Store[] = [];
	shipping_item_list:Shipping_Item[] = [];
	purchase_detail_list:Purchase_Detail[] = [];
	search_str:string = '';
	type_dic:Record<string,string> ={
		POSITIVE: 'Positivo',
		NEGATIVE: 'Negativo',
		ADJUSTMENT: 'Ajuste'
	};
	show_modal_report: boolean = false;
	start_time: string	= '';
	end_time: string	= '';

	ngOnInit()
	{
		this.path = '/list-stock-record';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [
				"item_id","purchase_detail_id","order_item_id","store_id","shipping_item_id",
				"production_item_id","serial_number_record_id","previous_qty","movement_qty"
				,"qty", "description","movement_type","created_by_user_id","updated_by_user_id",
				"created","updated"
			];

			let extra_keys:Array<string> = ['item_name'];

			this.stock_record_search = this.getSearch(queryParamMap, fields, extra_keys );
			this.setTitle('Bitacora de inventario');
			this.stock_record_search.sort_order = ['id_DESC'];


			if( !this.rest.current_permission.global_add_stock )
			{
				this.stock_record_search.eq.store_id = this.rest.current_user.store_id;
			}

			if( queryParamMap.has('ge.created') )
			{
				let str = Utils.getLocalMysqlStringFromDate( this.stock_record_search.ge.created ).substring(0,10);

				this.fecha_inicial	= str;
			}
			else
			{
				this.fecha_inicial	= '';
			}

			if( !queryParamMap.has('eq.movement_type') )
			{
				this.stock_record_search.eq.movement_type = undefined;
			}

			if( queryParamMap.has('le.created') )
			{

				let str = Utils.getLocalMysqlStringFromDate( this.stock_record_search.le.created ).substring(0,10);

				this.fecha_final = str;
				console.log('Fecha final', this.fecha_final, this.stock_record_search.le.created );
			}
			else
			{
				this.fecha_final = '';
			}

			console.log( this.stock_record_search );

			this.is_loading = true;

			this.subs.sink = forkJoin
			({
				stock_record_info: this.rest.stock_record_info.search(this.stock_record_search),
				store : this.rest.getStores( true ),
			})
			.subscribe((responses)=>
			{
				if( this.stock_record_search.eq.item_id )
				{
					if( responses.stock_record_info.data.length )
					{
						let sri = responses.stock_record_info.data[0];
						let str = (sri.category ? sri.category.name+' - ': '')+sri.item.name;
						this.search_str = str;
					}
				}
				this.is_loading = false;
				this.stock_record_info_list = responses.stock_record_info.data;
				this.setPages( this.stock_record_search.page, responses.stock_record_info.total );
				this.store_list = responses.store.data;
			},(error)=>this.showError(error));
		});
	}

	onItemSelected(item_info:ItemInfo)
	{
		this.stock_record_search.eq.item_id = item_info.item.id;
	}

	exportXls()
	{
		let new_search:SearchObject<Stock_Record> = { ...this.stock_record_search};
		new_search.limit = 999999;

		this.subs.sink = this.rest.stock_record_info.search( new_search )
		.subscribe((response)=>{
			let rows:any[] = response.data.map(i=> {
				let Referencia = '';

				if( i.shipping )
					Referencia += 'Envio '+i.shipping.id;

				if( i.order )
					Referencia += 'Venta '+i.order.id;

				if( i.purchase )
					Referencia += 'Orden De Compra'+i.purchase.id;

				if( i.stock_record.movement_type == 'ADJUSTMENT')
					Referencia += 'Ajuste inventario';

				let tipo = 'Negativo';

				if( i.stock_record.movement_type == 'ADJUSTMENT')
				{
					tipo = 'Ajuste';
				}
				else if( i.stock_record.movement_type == 'POSITIVE')
				{
					tipo = 'Positivo'
				}

				return {
					id: i.stock_record.id,
					Categoría: i?.category?.name,
					Artículo: i.item.name,
					Sucursal: i.store.name,
					Usuario: i.user.name,
					Referencia,
					Tipo: tipo,
					Previo: i.stock_record.previous_qty,
					Movimiento: i.stock_record.movement_qty,
					Cantidad: i.stock_record.qty,
					Descripción: i.stock_record.description,
				}
			});
			let str = Utils.getMysqlStringFromLocalDate(new Date() ).substring(0,10)+'.xlsx';
			let headers:string[] = [
				'id','Categoría','Artículo',
				'Sucursal','Usuario','Referencia',
				'Previo', 'Movimiento','Cantidad',
				'Descripción'
			];
			ExcelUtils.array2xlsx( rows, str, headers );
		});
	}

	fechaInicialCambio(str:string)
	{
		this.fecha_inicial = str;
		if( str == '' )
		{
			this.stock_record_search.ge.created = null;
		}
		else
		{
			this.stock_record_search.ge.created = Utils.getDateFromLocalMysqlString( str+' 00:00:00');
		}
	}

	fechaFinalCambio(str:string)
	{
		this.fecha_final= str;
		if( str == '' )
		{
			this.stock_record_search.le.created = null;
		}
		else
		{
			this.stock_record_search.le.created = Utils.getDateFromLocalMysqlString( str+' 23:59:59' );
		}
	}

	saveAll()
	{
	}
	showModalReport()
	{
		let start	= new Date();
		start.setHours(0,0,0,0);


		let end = new Date();
		end.setMinutes(end.getMinutes()+61);
		end.setMinutes(0);
		end.setSeconds(0);
		end.setMilliseconds(0);


		this.start_time = Utils.getMysqlStringFromLocalDate( start ).replace(' ','T');
		this.end_time = Utils.getMysqlStringFromLocalDate( end ).replace(' ','T');;
		this.show_modal_report = true;
	}
	createReport()
	{
		let start = Utils.getLocalDateFromMysqlString( this.start_time );
		let end = Utils.getLocalDateFromMysqlString( this.end_time );

		let ss = this.getEmptySearch<Stock_Record>();
		ss.ge.created = start;
		ss.le.created = end;
		ss.limit = 999999;
		ss.eq.movement_type = 'ADJUSTMENT';
		ss.sort_order = ['id_ASC'];
		ss.eq.store_id == this.stock_record_search.eq.store_id || this.rest.current_user.store_id;

		if( !this.rest.current_permission.global_add_stock )
			ss.eq.store_id = this.rest.current_user.store_id;

		this.subs.sink = this.rest.stock_record_info.search( ss )
		.subscribe((response)=>
		{
			let m = new Map<number,StockRecordInfo>();

			for(let i of response.data)
			{
				if( m.has( i.stock_record.item_id ) )
				{
					let sri = m.get( i.item.id );
					sri.stock_record.qty = i.stock_record.qty;
				}
				else
				{
					m.set(i.item.id, i );
				}
			}

			let a:Array<StockRecordInfo> = Array.from( m.values());

			a.sort((a,b)=>{
				return a.stock_record.id > b.stock_record.id ? 1 : -1;
			});

			let rows:any[] = a.map(i=> {
				return {
					id: i.stock_record.id,
					Categoría: i?.category?.name,
					Artículo: i.item.name,
					Sucursal: i.store.name,
					'Cantidad Inicial': i.stock_record.previous_qty,
					'Ajuste': i.stock_record.qty,
					Diferencia: i.stock_record.qty - i.stock_record.previous_qty
				}
			});
			let str = Utils.getMysqlStringFromLocalDate(new Date() ).substring(0,10)+'.xlsx';
			let headers:string[] = [
				'Categoría','Artículo', 'Sucursal',
				'Cantidad Inicial', 'Ajuste', 'Diferencia'
			];
			ExcelUtils.array2xlsx( rows, str, headers );

		},(error)=>this.showError(error));
	}

}
