<div class="container-fluid">

	<ng-container *ngIf="quote_info">
		<div class="row border-bottom">
			<div class="col-3 align-items-center mb-3">
				<img [src]="rest.getImagePath(quote_info?.store?.image_id,rest.local_preferences.logo_image_id)" class="company-logo ms-3" width="90%" style="vertical-align:top">
			</div>
			<div class="d-print-none">
				<button type="button" class="btn btn-print" (click)="printDirect()">Imprimir</button>
			</div>
			<div class="col-6 align-items-center text-center pb-3">
				<h6 *ngIf="quote_info?.store?.business_name" class="fw-bold">
					{{quote_info?.store?.business_name | uppercase}}
				</h6>
				<div>
					<span>{{quote_info?.store?.address | uppercase}}</span>
					<ng-container *ngIf="quote_info?.store?.address && quote_info?.store?.city">, </ng-container>
					<span>{{quote_info?.store?.city | uppercase}}</span>
					<span *ngIf="quote_info?.store?.state">, {{quote_info?.store?.state | uppercase}}</span>
					<span *ngIf="quote_info?.store?.zipcode">, CP:{{quote_info?.store?.zipcode}}</span>
				</div>
				<div *ngIf="quote_info?.store?.phone">Teléfono: {{quote_info?.store.phone}}</div>
				<!--div *ngIf="quote_info?.store?.email">Correo Electrónico: {{quote_info?.store.email}}</div-->
				<!--div *ngIf="quote_info?.store?.email">{{quote_info?.store.email}}</div-->
			</div>
			<div class="col-3 align-items-center rounded">
				<div class="border">
					<div class="border-bottom px-2 py-1">
						Cotización:<br>
						<b>No.{{ quote_info?.quote?.id }}</b>
					</div>
					<div class="border-bottom px-2 py-1">
						Fecha de elaboración:<br>
						<b>{{quote_info?.quote?.created | date: 'dd MMMM YYYY' }}</b>
					</div>
					<div class="px-2 py-1">
						Vigencia:<br>
						<b>{{ quote_info?.quote.valid_until | date: 'dd MMMM YYYY' }}</b>
					</div>
				</div>
			</div>
		</div>

		<div class="py-3 px-1 px-md-3">
			<table>
				<tr>
					<td>Nombre:</td>
					<th>{{billing_address?.name || quote_info?.client_user?.name || quote_info.quote.name | titlecase}}</th>
				</tr>
				<tr *ngIf="quote_info?.quote?.email || quote_info.client_user?.email">
					<td>Correo Electrónico:</td>
					<th>{{quote_info?.quote?.email || billing_address?.email || quote_info.client_user?.email | lowercase }}</th>
				</tr>
				<tr *ngIf="quote_info?.quote?.phone || quote_info.client_user?.phone">
					<td>Teléfono:</td>
					<th>{{quote_info?.quote?.phone || quote_info.client_user?.phone | lowercase }}</th>
				</tr>
			</table>
		</div>
		<div class="px-1 px-lg-3 py-3 my-3">
			<div class="row mb-3 align-items-center">
				<div class="col-6 fw-bold">Artículo</div>
				<div class="col-2 fw-bold text-end">Cantidad</div>
				<div class="col-2 fw-bold text-end">Precio</div>
				<div class="col-2 fw-bold text-end">Total</div>
			</div>
			<div class="row py-1 align-items-center border-top" *ngFor="let qi of quote_info.items">
				<div class="col-6">{{qi.item.name | titlecase}}</div>
				<div class="col-2 text-end">{{qi.quote_item.qty | number}}</div>
				<div class="col-2 text-end">{{qi.quote_item.original_unitary_price | currency: '$'}}</div>
				<div class="col-2 text-end">{{qi.quote_item.total | currency: '$'}}</div>
			</div>
			<div class="row align-items-center border-top">
				<div class="col-10"></div>
				<div class="col-2 text-end fw-bold">{{total | currency: '$'}}</div>
			</div>
		</div>
	</ng-container>
</div>
<hr>
<!--div class="row">
	<div class="col-12 p-2 border-bottom" *ngIf="quote_info?.order?.note">
		<b>NOTA:</b><span class=""> {{quote_info?.quote?.note | titlecase}}</span>
	</div>
	<div class="col-5">
		<label>Autorizado Por:</label>
		<div class="black_border mt-1 p-2 w-100">
			{{quote_info?.quote?.authorized_by}}&nbsp;
		</div>
		<!--label class="mt-3">Elaborado Por:</label>
		<div class="black_border mt-1 p-2 w-100">{{quote_info?.person_in_charge?.name | titlecase}} &nbsp;</div>
	</div>
	<div class="col-2"></div>
	<!--div class="col-5">
		<label>Recibe:</label>
		<div class="black_border mt-1 p-2 w-100">&nbsp;</div>
		<label class="mt-3">Fecha de recepción:</label>
		<div class="black_border mt-1 p-2 w-100">&nbsp;</div>
	</div>
</div-->
<div class="d-print-none my-3 py-3 text-center">
	<!--utton type="button" (click)="print()" class="btn btn-primary">Imprimir</button-->
	<div class="row align-items-center">
		<div class="col-10">
			<button type="button" (click)="printDirect()" class="my-3 btn btn-primary">Imprimir Dialogo</button>
		</div>
		<!--div class="col-2 text-end" *ngIf="!quote_info?.order">
			<button (click)="createOrder()" class="btn btn-primary">
				<span style="position:relative; top:-2px;">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/></svg>
				</span>
				Crear Orden
			</button>
		</div-->
	</div>
</div>
