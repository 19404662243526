import { Component } from '@angular/core';
import { ParamMap } from '@angular/router';
import {combineLatest, forkJoin, of, Subject} from 'rxjs';
import {debounceTime, mergeMap, startWith} from 'rxjs/operators';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';
import {ItemInfo} from 'src/app/models/models';
import {Currency, Item, Price, Price_List, Price_Type} from 'src/app/models/RestModels';
import {RestResponse, SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-list-price',
	templateUrl: './list-price.component.html',
	styleUrls: ['./list-price.component.css']
})
export class ListPriceComponent	extends BaseComponent
{
	item_search:SearchObject<Item> = this.getEmptySearch();
	item_info_list:ItemInfo[] = [];
	price_list:Price_List | null = null;
	price_type_list:Price_Type[] = [];
	price_of:Record<string,number> = {};
	model_of:Record<string,'YES'|'NO'> = {};
	currency_of:Record<string,string> = {};
	search_term:string = '';
	currency_list: Currency[] = [];
	price_type_dict: Record<number, Price_Type> = {};
	search_subject = new Subject<string>();

	ngOnInit(): void
	{
		this.path = '/list-price';

		let p:ParamMap = {
			has:(_prop)=>null,
			keys:[],
			get:(_value:string)=>{ return null},
			getAll:()=>{ return []},
		};

		this.subs.sink = combineLatest
		([
			this.route.queryParamMap.pipe(startWith(p)),
			this.route.paramMap
		])
		.pipe
		(
			mergeMap((response)=>
			{
				console.log('Navegando',response);

				let param_map = response[1];
				let query_map = response[0];


				this.item_search = this.getCombinedSearch(param_map, query_map,['id','name','code','type'] );
				this.item_search.eq.on_sale = 'YES';
				this.item_search.eq.status = 'ACTIVE';

				console.log('category_name',query_map.get('search_extra.category_name'));
				console.log('query_map', query_map );

				this.item_search.search_extra.category_name = query_map.get('search_extra.category_name') || '';

				//this.search_term = query_map.get('category_name') || '';

				this.path = '/list-price/'+param_map.get('price_list_id');

				if( this.item_search.search_extra.category_name )
				{
					this.item_search.limit = 9999;
				}
				else
				{
					this.item_search.limit = 50;
				}

				this.is_loading = true;

				return forkJoin
				({
					items: this.rest.item_info.search(this.item_search),
					price_type: this.rest.getPriceTypes(),
					price_list: ( ''+this.price_list?.id == param_map.get('price_list_id') )
						? of( this.price_list )
						: this.rest.price_list.get(param_map.get('price_list_id') ),
					currency: this.currency_list.length
						? of({total:this.currency_list.length,data:this.currency_list})
						: this.rest.currency.search({limit:99999, sort_order:['name_ASC']})
				});
			})
		).subscribe((response)=>
		{
			this.is_loading = true;
			this.price_type_dict = Utils.createDictionary(response.price_type.data,'id');
			this.price_list = response.price_list;
			let price_list:Price_List = response.price_list;
			this.currency_list = response.currency.data;
			this.price_type_list = response.price_type.data;

			//let price_of:Record<string,number> = {};
			//let model_of:Record<string,'YES'|'NO'> = {};
			//let currency_of:Record<string,string> = {};


			for(let item_info of response.items.data)
			{
				//Filtramos solo los precios y vamos a ordenar en el orden que este en price_type;
				//para que al final solo iterememos en la forma agregarmos los que falten en el mismo orden
				let prices = item_info.prices.filter((price)=>price.price_list_id == price_list.id);

				for(let pt of this.price_type_list)
				{
					if( !prices.some( p => p.price_type_id == pt.id ) )
					{
						prices.push({
							currency_id: 'MXN',
							price_type_id: pt.id,
							percent: 0,
							price_list_id: price_list.id,
							price: 0,
							tax_included: pt.tax_model == 'ALL' || pt.tax_model == 'TAX_INCLUDED' ? 'YES' : 'NO',
							created: new Date(),
							updated: new Date(),
							item_id: item_info.item.id,
						});
					}
				}

				prices.sort((a,b)=>
				{
					let a_index = this.price_type_list.findIndex((price_type)=>price_type.id == a.price_type_id);
					let b_index = this.price_type_list.findIndex((price_type)=>price_type.id == b.price_type_id);
					return a_index > b_index ? 1 : -1;
				});
				item_info.prices = prices;
			}

			this.setPages( this.item_search.page, response.items.total );
			this.item_info_list	= response.items.data;
		})

		this.subs.sink = this.search_subject.pipe
		(
			debounceTime(300)
		)
		.subscribe((search:string)=>
		{
			console.log('LLego aldo de searhc', search );
			this.item_search.search_extra.category_name = search;
			this.item_search.page = 0;
			this.searchNoForceReload( this.item_search );
		},(error)=>this.showError(error));
	}

	updatePrice(price:Price)
	{
		this.subs.sink = this.rest.price.create( price ).subscribe(()=>
		{
			this.showSuccess('El precio se actualizo correctamente');
		},(error)=>this.showError(error));
	}

	percentChange(value:number, price:Price,item_info:ItemInfo)
	{
		let oprice	= item_info.item.reference_price || 1;
		price.percent = value;
		price.price = Math.round(oprice*(1+value/100)*100)/100;
		this.updatePrice(price)
	}
	priceChange(value:number,price:Price)
	{
		price.price = value;
		this.updatePrice(price)
	}

	currencyChange(value:string, price:Price)
	{
		price.currency_id = value;
		this.updatePrice(price);
	}

	taxModelChange(value:'YES'|'NO',price:Price)
	{
		price.tax_included = value;
		this.updatePrice( price )
	}

	search(item_search:Partial<SearchObject<any>> | null = null )
	{
		this.router.navigate(['/list-price',this.price_list.id,this.search_term]);
	}

	onSearch(evt:any)
	{
		let search: string = evt.target.value;
		this.search_subject.next(search);
	}
}
