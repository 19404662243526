import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.css']
})
export class PostComponent extends BaseComponent implements OnInit 
{
	post = GetEmpty.post();

	ngOnInit(): void 
	{
		this.route.paramMap.pipe
		(
			mergeMap((response)=>this.rest.post.get( response.get('id') ))
		)
		.subscribe((post )=>{
			this.post = post;
		});
	}
}
