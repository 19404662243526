import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Item, Item_Store, Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface ItemStoreInfo
{
	store:Store;
	item_store:Item_Store;
	hash:number;
}
interface ISInfo
{
	item:Item;
	item_store_info_list:ItemStoreInfo[];
}



@Component({
	selector: 'app-save-item-store',
	templateUrl: './save-item-store.component.html',
	styleUrls: ['./save-item-store.component.css']
})

export class SaveItemStoreComponent extends BaseComponent implements OnInit
{

	item_search:SearchObject<Item> = this.getEmptySearch();
	item_str_search:string = '';
	store_list:Store[] = [];
	cs_info_list:ISInfo[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable()
		.pipe
		(
			mergeMap((response)=>
			{
				return forkJoin
				({
					item : this.rest.item.search( this.item_search ),
					store: this.rest.getStores()
				})
			}),
			mergeMap((response)=>
			{
				if( this.store_list.length  == 0 )
					this.store_list = response.store.data;

				//let ids:number[] =  response.item.data.map((i:Item)=>i.id);

				return forkJoin({
					item_store: this.rest.item_store.search({limit:9999999}),
					categories: of( response.item )
				})
			})
		)
		.subscribe((response)=>
		{
			let counter = 1;
			this.cs_info_list = response.categories.data.map((item:Item)=>
			{
				let item_store_info_list:ItemStoreInfo[] = this.store_list.map((store:Store)=>
				{
					let item_store:Item_Store = response.item_store.data.find(item_store=>{
						return item_store.item_id == item.id &&  item_store.store_id == store.id;
					});

					if( item_store == undefined )
					{
						item_store = {
							id: 0,
							store_id: store.id,
							item_id: item.id,
							pos_preference: 'DEFAULT'
						} as Item_Store;
					}
					return { store, item_store, hash: counter++ };
				});

				console.log('Que pedo', item_store_info_list );

				return {
					item,
					item_store_info_list
				} as ISInfo;
			});

			console.log( this.cs_info_list );

		},(error)=>this.showError(error));
	}

	update(cs:Item_Store, pos_preference:'SHOW' |'HIDE')
	{
		let cs_clone = { ...cs };
		cs_clone.pos_preference = pos_preference;

		this.subs.sink = this.rest.item_store.update( cs_clone )
		.subscribe((_response)=>
		{
			cs.pos_preference = pos_preference;
			this.showSuccess('La preferencia se actualizo');
		},(error)=>this.showError(error));
	}
}

