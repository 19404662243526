import { Component, OnInit } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {GraphData, GraphUtils } from 'src/app/classes/GraphUtils';
import { Utils } from 'src/app/classes/Utils';
import {ItemInfo, MermaInfo} from 'src/app/models/models';
import {User} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-dashboard-user',
	templateUrl: './dashboard-user.component.html',
	styleUrls: ['./dashboard-user.component.css']
})

export class DashboardUserComponent extends BaseComponent implements OnInit {

	//storesStockGraph:GraphData = GrapUtils.getEmptyBarChart();
	top_ten_items_graph:GraphData = GraphUtils.getEmptyBarChart();
	top_ten_merma_graph:GraphData = GraphUtils.getEmptyBarChart();
	sales_by_user_graph:GraphData	= GraphUtils.getEmptyBarChart();
	orders_by_type_graph:GraphData	= GraphUtils.getEmptyPieData();
	sales_by_hour_graph:GraphData	= GraphUtils.getEmptyStackAreaChart();
	preparation_times_graph:GraphData	= GraphUtils.getEmptyStackAreaChart();
	//sales_by_hour:GraphData Utils.getEmptyBarChart();
	user_dictionary:Record<number,User> = {};
	item_info_dictionary:Record<number,ItemInfo> = {};
	end: string = '';
	start: string = '';

	requestPermission(evt:any)
	{
		this.rest.requestPushNotificationPermission();
	}

	type_dictionary = {
		'TOGO':'Para Llevar',
		'IN_PLACE':'Comer Aqui',
		'PICK_UP':'A Domicilio',
		'QUICK_SALE':'En Sucursal'
	};

	ngOnInit(): void
	{
		//this.sales_by_hour_graph.scheme.domain = ['#B8FF3D','#D80093'];
		this.subs.sink = this.route.paramMap
		.subscribe(()=>
		{
			if( !this.rest.current_permission.reports )
			{

			}
			this.loadData();
		});
	}

	loadData()
	{
		let month_start = new Date();
		month_start.setDate(1);
		month_start.setHours(0,0,0,0);

		if( !this.start )
		{
			this.start = Utils.getLocalMysqlStringFromDate( month_start ).substring(0,10);;
		}
		else
		{
			month_start = Utils.getLocalDateFromMysqlString( this.start );
		}

		let month_end = new Date();

		if( !this.end )
		{
			month_end = Utils.getEndOfMonth( month_start );
			this.end = Utils.getLocalMysqlStringFromDate( month_end ).substring(0,10);;
		}
		else
		{
			month_end = Utils.getLocalDateFromMysqlString( this.end );
		}


		let date = new Date();
		let minutes_offset = date.getTimezoneOffset();

		this.subs.sink = forkJoin
		({
			user: this.rest.user.search({limit:99999}),
			top_ten_items: this.rest.getReporte('getTopTenItems', {start: month_start, end:month_end }).pipe
			(
				mergeMap((top_ten_items)=>
				{
					let ids_items = [];
					top_ten_items.forEach(i=>ids_items.push(i.item_id));

					return forkJoin
					({
						item_info:this.rest.item_info
						.search({
							eq: { status: 'ACTIVE' },
							csv:{ id: ids_items },
              				search_extra: { for_listings: 1 },
							limit:999999
						}),
						top_ten_items: of(top_ten_items)
					})
				})
			),
			sales_by_user: this.rest.getReporte('getSalesByCashierUser',
			{
				start: month_start, end:month_end
			}),
			sales_by_hour: this.rest.getReporte('getSalesByHour',{ minutes_offset, start:month_start, end:month_end }),
			sales_by_type: this.rest.getReporte('getSalesByType',{}),
			merma: this.rest.merma_info.search({limit:99999,gt:{created:month_start},lt:{created:month_end}}),
			prepatation_time: this.getOrderPreparationTimes( month_start, month_end )
		})
		.subscribe((response)=>
		{
			response.user.data.forEach(i=>this.user_dictionary[i.id] = i);
			response.top_ten_items.item_info.data.forEach(i=>this.item_info_dictionary[i.item.id] = i);
			response.top_ten_items.top_ten_items.sort((a,b)=>{ return a.amount_total < b.amount_total? 1 :-1});

			let merma_dictionary:Record<number,MermaInfo> = {};

			response.merma.data.forEach((merma_info)=>
			{
				if(merma_info.merma.item_id in merma_dictionary )
					merma_dictionary[ merma_info.merma.item_id ].merma.qty += merma_info.merma.qty;
				else
					merma_dictionary[ merma_info.merma.item_id ] = merma_info;
			})

			let results = [];
			for(let i in merma_dictionary)
			{
				let merma_info:MermaInfo = merma_dictionary[i];
				let name = '';

				if( merma_info?.category?.name )
					name += merma_info.category.name +'-';

				name+= merma_info.item.name;
				results.push({ name, value: merma_info.merma.qty });
			}

			results.sort((a,b)=>{ return a.value < b.value? 1 :-1});

			if( results.length > 10 )
				results.splice(10,results.length-10);

			this.top_ten_merma_graph.results = results;


			this.top_ten_items_graph.results = response.top_ten_items
			.top_ten_items
			.filter((value,index)=>{return index< 10 })
			.map((cs)=>
			{
				return {
					name: this.item_info_dictionary[cs.item_id].item.name ,
					value : cs.amount_total??0
				}
			});


			let tmp_results:any[] = [];

			let empty_qty:Record<string,number|string>[] = [];
			let empty_pesos:Record<string,number|string>[] = [];

			for(let i=0;i<24;i++)
			{
				empty_qty.push({ name:''+i,value:0});
				empty_pesos.push({ name:''+i,value:0});
			}

			tmp_results.push({
				name: "ventas",
				series: empty_qty
			});

			tmp_results.push
			({
				name: "pesos",
				series: empty_pesos
			});

			response.sales_by_hour.forEach((rc:any)=>
			{
				tmp_results[0].series[rc.the_hour].value += rc.qty;
				tmp_results[1].series[rc.the_hour].value += rc.amount_total;
				//let a = {
				//	name: 'total',
				//	value: rc.qty
				//};
				//let b = {
				//	name: 'amount',
				//	value: rc.amount_total
				//};
				//tmp_results.push({
				//	name: rc.the_hour,
				//	series: [a,b]
				//});
			});

			this.sales_by_hour_graph.results = tmp_results;
			this.orders_by_type_graph.results = response.sales_by_type.map((cs)=>
			{
				console.log( cs );
				return { name: this.type_dictionary[cs.service_type ], value: cs.total };
			});

			console.log( this.orders_by_type_graph.results );

			this.orders_by_type_graph.labels = true;

			this.sales_by_user_graph.results = response.sales_by_user.map((cs)=>
			{
				return { name: cs.user_name, value:cs.amount_total }
			});

			let xx = [{
				name: 'Fecha',
				series: response.prepatation_time.map((cs)=>
				{
					return {
						name: cs.the_date,
						value: cs.average_time
					}
				})
			}];
			this.preparation_times_graph.results = xx;
		});
	}

	getOrderPreparationTimes(date_start:Date,date_end:Date):Observable<Record<string,number>[]>
	{
		let start:string = Utils.getUTCMysqlStringFromDate(date_start);
		let end:string	= Utils.getUTCMysqlStringFromDate(date_end);
		let d = new Date();
		let offset = Math.round(d.getTimezoneOffset()/60);

		return this.rest.getReporte('getPreparationTimes',{start,end,offset}).pipe
		(
			mergeMap((response)=>
			{
				let init = new Date();
				let loop_end = new Date();
				loop_end.setHours(23,59,59,999);

				init.setTime(date_start.getTime());
				init.setHours(0,0,0,0);
				while(init<loop_end)
				{
					let str = Utils.getUTCMysqlStringFromDate(init).substr(0,10);

					if( response.findIndex((i)=>{ return i.the_date==str})==-1 )
					{
						response.push({ the_date: str, average_time: 0 });
					}
					init.setDate(init.getDate()+1);
				}

				response.sort((a,b)=>{ return a.the_date < b.the_date? -1 : 1});
				return of(response);
			})
		);
	}

	subscribeToNotifications(evt:any)
	{

	}
}
