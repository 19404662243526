<div class="container-fluid">
	<div class="row my-3">
		<div class="col-6">
			<h1 class="my">Carrito</h1>
		</div>
		<div class="col-6 text-end">
			<a routerLink="/c-select-category" *ngIf="selected_store_id" class="btn btn-secondary">+ Artículo</a>
			<button type="button" *ngIf="!selected_store_id" class="btn btn-secondary" (click)="showStoreSelector()">Agregar</button>
		</div>
	</div>
	<ul class="list-group">
		<ng-container *ngFor="let item of order_item_info_list">
			<li *ngIf="!item.order_item.item_option_id" class="list-group-item px-1">
				<div class="row mx-0 align-items-center">
					<div class="col-10 col-md-7 col-lg-9 px-1">
						<img [src]="rest.getImagePath(item.item.image_id,item?.category?.image_id)" width=40 height=40>
						<span *ngIf="item?.category?.name">{{item.category.name |titlecase}}</span>
						{{item?.item?.name}}
					</div>
					<div class="col-2 col-md-2 col-lg-1 text-end">
						<span class="fw-bold">{{item.order_item.qty}}</span>
					</div>
					<div class="col-12 col-md-3 col-lg-2 text-end aling-middle">
						<div>
							<span *ngIf="item.order_item.item_option_id">{{item.order_item.item_option_qty}}</span>
							<button class="btn btn-primary" (click)="decrement(item)">-</button>
							<button class="btn btn-primary mx-1" (click)="incremente(item)">+</button>
							<button class="btn btn-primary ms-2" (click)="removeCartItem(item)">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm10.618-3L15 2H9L7.382 4H3v2h18V4z" fill="currentColor"/></svg>
							</button>
						</div>
					</div>
				</div>
			</li>
		</ng-container>
	</ul>
	<div class="card p-3 my-3">
		<div class="row" *ngIf="!table">
			<div class="col-12 col-md-9">
				<span class="fw-bold">Dirección de entrega</span>
				<div *ngIf="platform_address">{{platform_address.address }}</div>
			</div>
			<div class="col-12 col-md-3 text-end">
				<a class="btn btn-primary" *ngIf="!platform_address_list.length" routerLink="/c-add-address">Agregar Dirección</a>
				<button class="btn btn-primary" *ngIf="platform_address_list.length" (click)="changeAddress()" [disabled]="is_loading" type="button">Seleccionar</button>
			</div>
		</div>
		<div class="row" *ngIf="table">
			<div class="col-12 col-md-9">
				<span class="fw-bold">Dirección de entrega</span>
				<div>{{table.name}}</div>
			</div>
			<div class="col-12 col-md-3 text-end">
				<a class="btn btn-primary" *ngIf="!platform_address_list.length" routerLink="/c-add-address">Cambiar Dirección</a>
				<button class="btn btn-primary" *ngIf="platform_address_list.length" (click)="changeAddress()" [disabled]="is_loading" type="button">Seleccionar</button>
			</div>
		</div>
		<div class="row mt-3 border-top pt-3">
			<div class="col-4">
				<label>Subtotal</label>
				<div class="fw-bold fs-1">
					<label>{{subtotal |currency:'$' }}</label>
				</div>
			</div>
			<div class="col-4 text-center">
				<label>IVA</label>
				<div class="fw-bold fs-1">
					<label>{{tax|currency:'$'}}</label>
				</div>
			</div>
			<div class="col-4 text-end">
				<label>Total</label>
				<div class="fw-bold fs-1">
					<label>{{total |currency:'$'}}</label>
				</div>
			</div>
			<div class="col-12 text-end pt-3">
				<button type="button" (click)="createOrder()" [disabled]="is_loading" class="btn btn-primary w-sm-100">Ordenar</button>
			</div>
		</div>
	</div>
</div>

<app-modal [(show)]="show_select_store">
	<div class="p-3">
		<h1>Seleccionar Sucursal</h1>
		<div class="row">
			<div class="col-12 form-group mb-3">
				<label>Sucursal</label>
				<select  name="selected_store_id" [(ngModel)]="selected_store_id" class="form-control">
					<option [ngValue]="null">Seleccionar Tienda</option>
					<option *ngFor="let store of store_list" [ngValue]="store.id">{{ store.id }} - {{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100">Cerrar</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100" (click)="onStoreSelected()">Aceptar</button>
			</div>
		</div>
	</div>
</app-modal>

<app-modal [(show)]="show_delivery_address">
	<div class="m-3">Seleccione Direccion de entrega</div>
	<ul class="list-group">
		<li class="list-group-item">
			<a [routerLink]="['/c-add-address']">Agregar Nueva Dirección</a>
		</li>
		<li class="list-group-item"  *ngFor="let temp_address of platform_address_list" (click)="onSelectDeliveryAddress(temp_address)">
			{{temp_address.address}}
			<span *ngIf="platform_address && platform_address.id == temp_address.id">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112l6.82-8.69a.486.486 0 0 1 .04-.045z"/></g></svg>
			</span>
		</li>
	</ul>
</app-modal>
