import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Stocktake, Store } from 'src/app/models/RestModels';
import { filter, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Utils } from 'src/app/classes/Utils';
import { GetEmpty } from 'src/app/classes/Empties';

interface CResume
{
	item_count:number;
	stocktake_item_count:number;
	db_qty_sum:number;
	real_qty_sum:number;
	surplus_qty:number;
	surplus_item_count:number;
	missing_qty:number;
	missing_item_count:number;
	ok_item_sum:number;
	ok_item_count:number;
}

@Component
({
	selector: 'app-list-stocktake',
	templateUrl: './list-stocktake.component.html',
	styleUrls: ['./list-stocktake.component.css']
})
export class ListStocktakeComponent extends BaseComponent implements OnInit {

	store_id:number = 0;
	store_dictionary:Record<number, Store> = {};
	store_list:Store[] = [];
	stocktake_list:Stocktake[] = [];
	show_new_stocktake:boolean = false;
	new_stocktake_name:string = '';
	new_stocktake_store_id:number = 0;
	show_resume: boolean = false;
	selected_stocktake:Stocktake | null = null;

	cresume: CResume = {
		item_count:0,
		stocktake_item_count:0,
		db_qty_sum:0,
		real_qty_sum:0,
		surplus_qty:0,
		surplus_item_count:0,
		missing_qty:0,
		missing_item_count:0,
		ok_item_sum:0,
		ok_item_count:0,
	};

	ngOnInit(): void
	{
		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((queryParamMap)=>
			{
				this.setTitle('Tomas de inventario');
				this.store_id = 0;

				return forkJoin({
					stores: this.rest.store.search({limit:999999}),
					stocktakes: this.rest.stocktake.search({limit:999999}),
				});
			}),
		)
		.subscribe((response)=>
		{
			this.store_dictionary = Utils.createDictionary(response.stores.data, 'id');
			this.store_list = response.stores.data;
			this.stocktake_list = response.stocktakes.data;
		}, (error)=> this.showError(error));
	}

	searchStockTakes()
	{
		let search = this.getEmptySearch<Stocktake>();
		if(this.store_id != 0)
		{
			search.eq.store_id = this.store_id;
		}
		search.sort_order = ['id_DESC'];
		this.subs.sink = this.rest.stocktake.search(search)
		.subscribe((response)=>
		{
			this.stocktake_list = response.data;
		});
	}

	createStockTake()
	{
		let stocktake:Stocktake = {
			name: this.new_stocktake_name,
			created_by_user_id: this.rest.current_user.id,
			store_id: this.new_stocktake_store_id,
			stock_adjustment: 'DIFFERENCE',
			status: 'ACTIVE',
		}

		this.subs.sink = this.rest.stocktake.create
		(
			stocktake
		)
		.subscribe((response)=>
		{
			this.router.navigate(['/edit-stocktake', response.id]);
			this.showSuccess('Stocktake creado');
		},
		(error)=>
		{
			this.showError( error );
		});
	}

	showResume(stocktake:Stocktake)
	{
		this.selected_stocktake = stocktake;

		this.subs.sink = this.rest
		.getReporte('getStocktakeResume',{stocktake_id:stocktake.id})
		.subscribe((response)=>
		{
			console.log('Lo que viene del servidor es', response );
			this.selected_stocktake  = stocktake;
			this.cresume = response;
			this.show_resume = true;
		},(error)=>this.showError( error ));
	}
	applyStockDifferences()
	{
		this.subs.sink = this.confirmation.showConfirmAlert
		(
				this.selected_stocktake,
				'Aplicar diferencias',
				'Se aplicaran las diferencias al inventario, Ej. Si faltaban 5 piezas se restaran las 5 piezas del inventario'
		)
		.pipe
		(
			filter((response:any)=>
			{
				return response.accepted;
			}),
			mergeMap((response)=>
			{
				let stock_take = {...response.obj, status: 'CLOSED',stock_adjustment:'DIFFERENCE'};
				return this.rest.stocktake.update(stock_take);
			})
		)
		.subscribe((response)=>
		{
			this.selected_stocktake = response;
			this.stocktake_list = this.stocktake_list.map((stocktake)=>
			{
				if(stocktake.id == response.id)
				{
					return response;
				}
				return stocktake;
			});
			this.showSuccess('El inventario se ajusto correctamente');
		},(error)=>this.showError(error));
	}

	adjustStockValues()
	{
		this.subs.sink = this.confirmation.showConfirmAlert
		(
				this.selected_stocktake,
				'Ajustar Inventario',
				'Se asignaran los valores del inventario, Ej. Si en al hacer inventario habia 5 piezas, Se asignara el valor 5 al total de piezas.'
		)
		.pipe
		(
			filter((response:any)=>
			{
				return response.accepted;
			}),
			mergeMap((response)=>
			{
				let stock_take = {...response.obj, status: 'CLOSED',stock_adjustment:'EXACT_QTY'};
				return this.rest.stocktake.update( stock_take );
			})
		)
		.subscribe((response)=>
		{
			this.selected_stocktake = response;
			this.stocktake_list = this.stocktake_list.map((stocktake)=>
			{
				if(stocktake.id == response.id)
				{
					return response;
				}
				return stocktake;
			});
			this.showSuccess('Las cantidades se ajustaron correctamente');
		},(error)=>this.showError(error));

	}
}
