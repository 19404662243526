import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo} from 'src/app/models/models';
import { BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-offline-resume',
	templateUrl: './offline-resume.component.html',
	styleUrls: ['./offline-resume.component.css']
})

export class OfflineResumeComponent	extends BaseComponent
{
	order_info_list:OrderInfo[] = [];

	ngOnInit(): void
	{
		this.route.params.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				return forkJoin
				({
					orders: this.rest.getOfflineOrders(),
					stores: this.rest.getStores(),
				})
			})
		)
		.subscribe
		(
			response=>
			{
				this.is_loading = false;
				this.order_info_list = response.orders;
			},(error)=>this.showError(error)
		);
	}

	proccessOrderInCloud(oi:OrderInfo)
	{
		this.is_loading = true;
		this.rest.proccessOfflineOrder(oi)
		.then((response)=>
		{
			this.is_loading = false;
		},(error)=>{
			this.showError(error);
		});
	}
}
