<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col-4 col-lg-6">
			<h1 class="text-capitalize">Ordenes Canceladas</h1>
		</div>
		<div class="col-4 col-lg-4 text-end">
			<b>total</b>:<br>
			<span>{{order_total |number: '1.2-2'}}</span>
		</div>
	</div>
	<div class="card my-3 p-3" *ngIf="!(order_search?.csv?.status?.length) && !rest.is_offline">
		<div class="row">

			<div class="col-6 col-md-3 col-lg-3 form-group">
				<label class="">Fecha Inicial</label>
				<input type="datetime-local" name="inicial_created" [ngModel]="fecha_inicial" (ngModelChange)="fechaIncialChange($event)" class="form-control">
			</div>
			<div class="col-6 col-md-3 col-lg-3 form-group">
				<label class="">Fecha Final</label>
				<input type="datetime-local" name="final_created" [ngModel]="fecha_final" (ngModelChange)="fechaFinalChange($event)" class="form-control">
			</div>

			<div class="col-6 col-md-3 col-lg-2 form-group">
				<label class="">Sucursal</label>
				<div class="fw-bold" *ngIf="!rest.current_permission.global_pos">{{ store_dictionary[ rest.current_user.store_id ]?.name | titlecase}}</div>
				<select name="store_id" [(ngModel)]="order_search.eq.store_id" class="form-control" *ngIf="rest.current_permission.global_pos">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>

			<div class="col-6 col-md-3 col-lg-2 form-group" *ngIf="rest.current_permission.advanced_order_search">
				<label class="">Búsqueda Avanzada</label>
				<div class="form-check form-switch">
					<label class="form-check-label">
						<input type="checkbox" name="show_advanced_search" value=1 class="form-check-input" (click)="toggleAdvancedSearch($event)" [checked]="show_advanced_search">
					</label>
				</div>
			</div>

			<ng-container *ngIf="show_advanced_search">
				<div class="col-6 col-md-3 form-group">
					<label class="">Precio</label>
					<select name="price_type_id" [(ngModel)]="order_search.eq.price_type_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of price_type_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Estatus De Pago</label>
					<select name="paid_status" [(ngModel)]="order_search.eq.paid_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="PARTIALLY_PAID">Pago Parcial</option>
						<option value="PAID">Pagado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Estatus De Orden</label>
					<select name="status" [(ngModel)]="order_search.eq.status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="ACTIVE">Activas</option>
						<option value="CANCELLED">Canceladas</option>
						<option value="CLOSED">Finalizadas</option>
						<option value="PENDING">Pendientes</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Estatus De Entrega</label>
					<select name="delivery_status" [(ngModel)]="order_search.eq.delivery_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="DELIVERED">Entregado</option>
						<option value="CANCELLED">Cancelado</option>
						<option value="READY_TO_PICKUP">Listo para Recoger</option>
						<option value="SENT">Enviado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Método de pago</label>
					<select name="transaction_type" [(ngModel)]="order_search.search_extra.transaction_type" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option value="CASH">Efectivo</option>
						<option value="CREDIT_CARD">Tarjeta Credito</option>
						<option value="DEBIT_CARD">Tarjeta Debito</option>
						<option value="CHECK">Cheque</option>
						<option value="COUPON">Coupon</option>
						<option value="TRANSFER">Transferencia</option>
						<option value="DISCOUNT">Descuento</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Folio</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.eq.store_consecutive" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Cliente</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.lk.client_name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Solo publico general</label>
					<div class="form-check form-switch">
						<label class="form-check-label">
							<input type="checkbox" name="publico_general" class="form-check-input" (click)="togglePublicoGeneral($event)" [checked]="order_search.search_extra.publico_general">
						</label>
					</div>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Facturado</label>
					<select name="facturado" [(ngModel)]="order_search.csv.facturado" class="form-control">
						<option value="YES,NO" [selected]="order_search.csv.facturado == null">Todos</option>
						<option value="YES">Facturado</option>
						<option value="NO">Sin Facturar</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Cajero</label>
					<select name="cashier_id" [(ngModel)]="order_search.eq.cashier_user_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of cashier_user_list" [value]="c.id">
							{{c?.name | titlecase}} <ng-container *ngIf="c.username?.toLowerCase()?.localeCompare( c?.name?.toLowerCase() ) != 0">({{c.username}})</ng-container>
						</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Con Descuento</label>
					<select name="cashier_id" [(ngModel)]="order_search.search_extra.with_discounts" class="form-control">
						<option value="">Todos</option>
						<option value="1">Con Descuento</option>
					</select>
				</div>
			</ng-container>
			<div class="col-6 col-md-3 col-lg-2">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(order_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive d-none d-lg-block">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Cliente</th>
						<th>Sucursal</th>
						<th class="d-none d-xxl-table text-center">Cancelo</th>
						<th class="text-start">Tipo</th>
						<th>Fecha</th>
						<th class="text-center">Motivo</th>
						<th class="text-center">Cancelación</th>
						<th class="text-end">Total</th>
					</tr>
				</thead>
				<tbody *ngIf="price_type_list.length">
					<tr *ngFor="let oi of order_info_list" class="align-middle">
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<div>
								<a *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING' )" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="!oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.sync_id,'offline']">{{oi.order.sync_id}}</a>
							</div>
						</td>
						<td>
							<div>
								<span *ngIf="rest.is_offline && oi.order.status == 'CLOSED'">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</span>
								<a *ngIf="oi.order.id && (oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="!oi.order.id && (oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos/offline',oi.order.sync_id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="!rest.is_offline && (oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
							</div>
							<div class="d-xxl-none">{{oi.store.name}}</div>
						</td>
						<td class="d-none d-xxl-table-cell">{{oi.store.name | titlecase }}</td>
						<td>{{oi?.cancelled_by_user?.name | titlecase }}</td>
						<td>
							<span *ngIf="oi.order.service_type == 'TOGO'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 4a2 2 0 0 0-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4h-3V4m-7 2l4 4l-4 4v-3H4V9h6m7 .5h2.5l1.97 2.5H17M6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'PICK_UP'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z"/></g></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'IN_PLACE'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z" fill="currentColor"/></svg>
							</span>

							<!--{{price_type_dic | json}} {{price_type_list |json }}-->

							{{price_type_dic[ oi?.order.price_type_id ].name | titlecase}}
						</td>
						<td>
							<abbr title="{{oi.order.created | date: 'MMM d, y HH:mm' }}">{{oi.order.created | shortDate}}</abbr>
						</td>
						<td class="text-center">
							{{oi.order.cancellation_reason}}
						</td>
						<td class="text-center">
							<abbr *ngIf="oi.order.cancellation_timestamp" title="{{oi.order.cancellation_timestamp | date: 'MMM d, y HH:mm' }}">{{oi.order.cancellation_timestamp | shortDate:'Relative'}}</abbr>
						</td>

						<td class="text-end">{{oi.order.total-oi.order.discount | currency:'$' }}</td>
						<td class="text-end nowrap"></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row mb-3 border-bottom pb-2 d-lg-none" *ngFor="let oi of order_info_list">
			<div class="col-9">
				<a [routerLink]="['/view-order',oi.order.id]" *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a *ngIf="!oi.order.id && oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order-offline',oi.order.id]" >
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a [routerLink]="['/pos',oi.order.id]" *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{oi.order.client_name}}
				</a>
			</div>
			<div class="col-3 px-0 text-end align-self-center">{{oi?.order?.total | currency:'$'}}</div>
			<div class="col-6 col-sm-4 align-self-center">
				<span *ngIf="oi?.order?.delivery_status == 'PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/></g></svg>
				</span>
				{{oi?.store?.name | titlecase}} {{(oi?.order?.delivery_status == 'DELIVERED') ? '✓':'' | titlecase}}
			</div>
			<div class="col-6 col-sm-2 col-md-2 align-self-center">
				{{oi.order.created | shortDate: 'MMM d' }}
			</div>
			<div class="col-12 col-sm-6 col-md-6 text-end px-0">
				<a *ngIf="oi.order.status != 'CANCELLED' " [routerLink]="['/edit-order',oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-solid clr-i-solid-path-1" d="M4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4z" fill="currentColor"/><path class="clr-i-solid clr-i-solid-path-2" d="M33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93z" fill="currentColor"/></svg>
				</a>
				<a *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-order'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

