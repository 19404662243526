import { Component, OnInit } from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {OrderInfo, ReturnsInfo} from 'src/app/models/models';
import {Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-print-return-receipt',
	templateUrl: './print-return-receipt.component.html',
	styleUrls: ['./print-return-receipt.component.css']
})
export class PrintReturnReceiptComponent extends BaseComponent implements OnInit
{
	order_info: OrderInfo | null = null;
	print_receipt:boolean = false;
	returns_info: ReturnsInfo | null = null;
	store: Store;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap(params =>
			{
				this.print_receipt = params.has('print');
				this.setTitle('ReciboDevolucion-'+params.get('returns_id'));
				return forkJoin
				({
					returns_info: this.rest.returns_info.get( params.get('returns_id') ),
					store: this.rest.getStore( this.rest.current_user.store_id )
				});
			}),
			mergeMap((responses)=>
			{
				this.store =responses.store;
				this.returns_info = responses.returns_info;
				console.log('order_info', this.returns_info );
				return this.rest.order_info.get(this.returns_info.returns.order_id);
			})
		).subscribe((order_info) =>
		{
			console.log('order_info', order_info);
			this.order_info = order_info;

			if( this.print_receipt )
			{
				setTimeout(()=>{
					window.print()
					setTimeout(()=>window.close(),500)
				},1000);
			}
		},(error)=>{
			this.showError(error);
		});
	}
}
