import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface Report_Search
{
	system_start:Date;
	system_end:Date;
}

interface Profit
{
	order_id:number;
	bigger:number;
	profit_color:string;
	profit_percent:number;
	cost_percent: number;
	avg_sale:number;
}

interface CRow
{
	row:any,
	profit:Profit
}

interface CTotals
{
	total_qty:number;
	total_cost:number;
	total_sale:number;
	total_profit:number;
	avg_price:number;
};

@Component({
	selector: 'app-reports-item-profit',
	templateUrl: './reports-item-profit.component.html',
	styleUrls: ['./reports-item-profit.component.css']
})
export class ReportsItemProfitComponent extends BaseComponent implements OnInit
{
	store_list: Store[] = [];
	report_search: SearchObject<unknown> = this.getEmptySearch();
	endx: string = '';
	startx: string = '';
	report_list:CRow[] = [];
	totals: CTotals = this.getEmptyTotals();

	ngOnInit(): void
	{
		this.path = '/reports-item-profit';
		this.page_size = 50;

		this.route.queryParamMap.pipe
		(
			mergeMap
			(
				(param_map: ParamMap)=>
				{
					this.setTitle('Reporte Ventas por artículo');
					this.is_loading = true;

					return forkJoin
					({
						param_map: of(param_map),
						stores: this.rest.getStores(true)
					});
				}
			),
			mergeMap((response)=>
			{
				this.store_list = response.stores.data;
				this.report_search = this.getSearch(response.param_map, ['item_name','store_id','start','end'],['item_name','store_id','start_timestamp','end_timestamp']);

				if( !this.report_search.search_extra['start_timestamp'] )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.report_search.search_extra.start_timestamp = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.search_extra['end_timestamp'] )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours(23,59,0,0);
					this.report_search.search_extra.end_timestamp = end_of_month;
				}

				this.endx = Utils.getLocalMysqlStringFromDate( this.report_search.search_extra['end_timestamp'] as Date);
				this.startx = Utils.getLocalMysqlStringFromDate( this.report_search.search_extra['start_timestamp'] as Date);

				if( !response.param_map.has('search_extra.store_id') )
				{
					this.report_search.search_extra.store_id = null;
				}

				let search = {
					...this.report_search.search_extra,
					page: this.report_search.page,
					limit: this.page_size
				};

				return forkJoin
				({
					item_profit: this.rest.getReporte('getItemProfit',search),
					item_profit_total: this.rest.getReporte('getItemProfitTotal',search)
				})
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			let start = Utils.getMysqlStringFromDate( this.report_search.search_extra['start_timestamp'] as Date);
			let end = Utils.getMysqlStringFromDate( this.report_search.search_extra['end_timestamp'] as Date);

			this.totals = response.item_profit_total.data[0] ||{ };

			this.report_list = response.item_profit.data.map((row:any)=>
			{
				let bigger = row.total_sale;

				let profit_color = 'bg-success';
				//let profit_color = 'yellow';

				let profit_percent	= (row.total_sale-row.total_cost)/row.total_sale;
				let cost_percent	= row.total_cost/row.total_sale;

				console.log('ROW ',row );

				if( row.total_cost > row.total_sale )
				{
					bigger = row.total_cost;
					profit_color = 'bg-danger';
					profit_percent = (row.total_cost-row.total_sale)/row.total_cost;
					cost_percent = row.total_sale/row.total_cost;
				}

				console.log( {row,profit:{bigger,cost_percent, profit_percent, profit_color}} )
				return {row,profit:{bigger,cost_percent, profit_percent, profit_color}};
			});
			this.setPages( this.report_search.page, response.item_profit.total );
		},(error)=>this.showError(error))
	}

	getEmptyTotals(): CTotals
	{
		return {
			total_qty:0,
			total_cost:0,
			total_sale:0,
			total_profit:0,
			avg_price:0
		};
	}
}
