<div class="container-fluid login_background">
	<div style="max-width:400px; margin: auto auto">
		<form (ngSubmit)="save($event)" ngNativeValidate>
			<div class="card p-1 p-md-3">
				<div class="mb-3 text-center py-3">
					<img [src]="rest.getImagePath(rest.local_preferences?.logo_image_id)" style="max-width:100%">
				</div>
				<div class="row">
					<div class="col-12 form-group">
						<label>Código facturación</label>
					</div>
					<div class="col-10 form-group">
						<input class="form-control" type="text" name="facturacion_code" [(ngModel)]="facturacion_request.facturacion_code" autocomplete="off">
					</div>
					<div class="col-2 text-end">
						<button type="button" class="btn btn-primary float-end" (click)="show_codigo_ticket=true">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
						</button>
					</div>
					<div class="col-12 form-group">
						<label>Razón social</label>
					</div>
					<div class="col-10">
						<input class="form-control" type="text" name="razon_social" [(ngModel)]="facturacion_request.razon_social" autocomplete="organization">
					</div>
					<div class="col-2">
						<button type="button" class="btn btn-primary float-end" (click)="show_razon_social=true">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
						</button>
					</div>
					<!--
						div class="col-12 form-group">
						<label>Régimen Capital</label>
						<input class="form-control" type="text" name="regimen_capital" [(ngModel)]="facturacion_request.regimen_capital" autocomplete="organization" placeholder="Ej: SA de CV">
					</div -->
					<div class="col-12 form-group">
						<label>RFC con homoclave</label>
					</div>
					<div class="col-10">
						<input class="form-control" type="text" minlength="12" maxlength="13" name="rfc" [(ngModel)]="facturacion_request.rfc" autocomplete="on" placeholder="Ej: XAXX010101000" required>
					</div>
					<div class="col-2">
						<button type="button" class="btn btn-primary float-end" (click)="show_rfc=true">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
						</button>
					</div>
					<div class="col-12 form-group">
						<label>
							Código postal
						</label>
					</div>
					<div class="col-10 form-group">
						<input class="form-control" type="text"
							name="domicilio_fiscal" [(ngModel)]="facturacion_request.domicilio_fiscal"
							autocomplete="postal-code"
							pattern="[0-9]+" minlength="5" maxlength="5"
							placeholder="Ej: 22800">
					</div>
					<div class="col-2 form-group">
						<button type="button" class="btn btn-primary float-end" (click)="show_domicilio_fiscal = true">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
						</button>
					</div>
					<div class="col-12 form-group">
						<label>Uso CFDI</label>
						<select class="form-control" name="regimen_fiscal" [(ngModel)]="facturacion_request.uso_cfdi" required>
							<option value="G01">G01 - Adquisición de mercancías.</option>
							<option value="G03">G03 - Gastos en general.</option>
							<option value="D06">D06 - Aportaciones voluntarias al SAR.</option>
							<option value="I07">I07 - Comunicaciones satelitales.</option>
							<option value="I06">I06 - Comunicaciones telefónicas.</option>
							<option value="I01">I01 - Construcciones.</option>
							<option value="I05">I05 - Dados, troqueles, moldes, matrices y herramental.</option>
							<option value="D09">D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
							<option value="G02">G02 - Devoluciones, descuentos o bonificaciones.</option>
							<option value="D04">D04 - Donativos.</option>
							<option value="I04">I04 - Equipo de computo y accesorios.</option>
							<option value="I03">I03 - Equipo de transporte.</option>
							<option value="D08">D08 - Gastos de transportación escolar obligatoria.</option>
							<option value="D03">D03 - Gastos funerales.</option>
							<option value="D02">D02 - Gastos médicos por incapacidad o discapacidad.</option>
							<option value="D01">D01 - Honorarios médicos, dentales y gastos hospitalarios.</option>
							<option value="D05">D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
							<option value="I02">I02 - Mobiliario y equipo de oficina por inversiones.</option>
							<option value="CN01">CN01- Nómina</option>
							<option value="I08">I08 - Otra maquinaria y equipo.</option>
							<option value="CP01">CP01- Pagos</option>
							<option value="D10">D10 - Pagos por servicios educativos (colegiaturas).</option>
							<option value="D07">D07 - Primas por seguros de gastos médicos.</option>
							<option value="S01">S01 - Sin efectos fiscales.</option>
						</select>
					</div>
					<div class="col-12 form-group">
						<label>Regimen fiscal</label>
					</div>
					<div class="col-10 form-group">
						<select class="form-control" name="regimen_fiscal" [(ngModel)]="facturacion_request.regimen_fiscal" required>
							<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
							<option value="606">606 - Arrendamiento</option>
							<option value="609">609 - Consolidación</option>
							<option value="624">624 - Coordinados</option>
							<option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
							<option value="608">608 - Demás ingresos</option>
							<option value="630">630 - Enajenación de acciones en bolsa de valores</option>
							<option value="601">601 - General de Ley Personas Morales</option>
							<option value="628">628 - Hidrocarburos</option>
							<option value="621">621 - Incorporación Fiscal</option>
							<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
							<option value="614">614 - Ingresos por intereses</option>
							<option value="623">623 - Opcional para Grupos de Sociedades</option>
							<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
							<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
							<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
							<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
							<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
							<option value="626">626 - Régimen Simplificado de Confianza</option>
							<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
							<option value="616">616 - Sin obligaciones fiscales</option>
							<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
							<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
						</select>
					</div>
					<div class="col-2 form-group">
						<button type="button" class="btn btn-primary float-end" (click)="show_regimen_fiscal= true">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41c0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/></svg>
						</button>
					</div>
					<div class="col-12 form-group">
						<label>Email</label>
						<input class="form-control" type="email" name="email" [(ngModel)]="facturacion_request.email" autocomplete="email" required>
					</div>
					<!--div class="col-12 form-group">
						<label>Versión</label>
						<select name="version" [(ngModel)]="facturacion_request.version" class="form-control" required>
							<option value="3.0" selected>Facturacion 3.3</option>
							<option value="4.0">Facturación 4.0</option>
						</select>
					</div-->
					<div class="col-12">
						<app-loading [is_loading]="is_loading"></app-loading>
					</div>
					<div class="col-12 py-3 text-end">
						<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Facturar</button>
					</div>
					<div class="text-danger" *ngIf="error_string">
						<b>ERROR: </b> {{error_string}}
					</div>
					<div *ngIf="code" class="col-12 my-3">
						<a [href]="rest.getApiPath()+'/getFacturaZip.php?facturacion_code='+code" class="btn btn-primary">Descargar</a>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<app-modal [(show)]="show_rfc">
	<div class="p-1 p-md-3">
		<h2>RFC</h2>
		<div class="img-example">
			<img src="/assets/facturacion/RFC.png" class="w-100">
		</div>
		<p class="mt-3">
		El RFC se encuentra en la primera hoja de la <b>CEDULA DE IDENTIFICACION FISCAL</b>
		En la sección <b>Datos de Identifcación del Contribuyente</b>
		</p>
	</div>
</app-modal>

<app-modal [(show)]="show_razon_social">
	<div class="p-1 p-md-3">
		<h2>Razón social</h2>
		<div>La razón social sin el regimen capital.</div>
		<div>Ej. Si la razón social es "ABARROTES EL CAMINO S.A DE C.V"</div>
		<div>
			En el campo de razón social solo se pondría "ABARROTES EL CAMINO"
		</div>
		<div class="img-example">
			<img src="/assets/facturacion/razon_social.png" class="w-100">
		</div>
	</div>
</app-modal>

<app-modal [(show)]="show_codigo_ticket">
	<div class="p-1 p-md-3">
		<h2>El código de facturación</h2>
		<div>
			<img src="/assets/facturacion/ticket_hd.png" class="w-100">
		</div>
		<p class="mt-3">
			El código del facturación se encuentra en la parte inferior del ticket de compra.
		</p>
	</div>
</app-modal>

<app-modal [(show)]="show_domicilio_fiscal">
	<div class="p-1 p-md-3">
		<h2>Código Postal</h2>
		En este campo debe ir el código postal del domicilio fiscal, normalmente se encuentra en la primera hoja de la
		<b>CEDULA DE IDENTIFICACION FISCAL</b>
		<div class="img-example">
			<img src="/assets/facturacion/codigo_postal.png" class="w-100">
		</div>
	</div>
</app-modal>

<app-modal [(show)]="show_regimen_fiscal">
	<div class="p1 p-md-3">
		<h2>Regimen fiscal</h2>
		Esta información la puede validar en su constancia de situación fiscal, En las sección llamada "Regímenes".
		<div class="img-example">
			<img src="/assets/facturacion/regimen_fiscal.png" class="w-100">
		</div>
	</div>
</app-modal>
