import { Component } from '@angular/core';
import {RestResponse} from 'src/app/services/Rest';
import {Commanda, Push_Notification } from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent {

	push_notification_list:Push_Notification[] = [];
	unread_notifications:number = 0;
	show_dropdown:boolean = false;
	commanda_list:Commanda[] = [];
	show_commandas:boolean = false;

	ngOnInit(): void
	{
		this.subs.sink = this.rest.notification.subscribe((message)=>
		{
			this.loadData();
		},(error)=>{
			console.log( error );
		});

		this.subs.sink = this.route.url.subscribe(()=>
		{
			this.show_dropdown = false;
		})
	}

	loadData()
	{
		this.rest.push_notification.search({eq:{},limit:10,sort_order:['id_DESC']}).toPromise()
		.then((response:RestResponse<Push_Notification>)=>
		{
			this.push_notification_list= response.data;
			this.unread_notifications	= this.push_notification_list.reduce((p,c)=>p+(c.read_status == 'PENDING' ? 1: 0),0);
		})
		.catch((error)=>
		{
			console.error( error );
		});
	}

	markAsRead(_evt:any)
	{
		this.show_dropdown = !this.show_dropdown;

		this.rest.update('markPushNotificationsAsRead',{}).toPromise().then((_resposne)=>
		{
			this.unread_notifications = 0;
		}).catch((error)=>{
			this.rest.showError(error);
		})
	}

	showConfirmCommanda()
	{
		this.subs.sink = this.rest.commanda
		.search
		({
			eq:{ store_id: this.rest.current_user.store_id },
			sort_order:['name_ASC']
		})
		.subscribe((response)=>
		{
			if( response.data.length == 1 )
			{
				this.router.navigate(['/view-commanda/',response.data[0].id]);
			}
			else
			{
				this.show_commandas = true;
				this.commanda_list = response.data;
			}
		},(error)=>{
			this.rest.showError(error);
		});
	}

	commandaSelected(commanda:Commanda)
	{
		this.show_commandas = false;
		this.router.navigate(['/view-commanda/',commanda.id]);
	}
}
