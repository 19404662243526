<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col-4 col-lg-6">
			<h1 class="text-capitalize">Ventas Offline</h1>
		</div>
		<div class="col-4 col-lg-2 text-end">
			<button type="button" class="btn btn-secondary" [routerLink]="['/pos']">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"><path _ngcontent-uxx-c124="" d="M17 2H7c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 4H7V4h10v2zm3 16H4c-1.1 0-2-.9-2-2v-1h20v1c0 1.1-.9 2-2 2zm-1.47-11.81A2.008 2.008 0 0 0 16.7 9H7.3c-.79 0-1.51.47-1.83 1.19L2 18h20l-3.47-7.81zM9.5 16h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm3 4h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5zm0-2h-1c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h1c.28 0 .5.22.5.5s-.22.5-.5.5z" fill="currentColor"></path></svg>
				&nbsp;Agregar
			</button>
		</div>
	</div>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive d-none d-lg-block">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th style="width:30px">id</th>
						<th>Cliente</th>
						<th>Atendio</th>
						<th>Sucursal</th>
						<th>Tipo</th>
						<th>Fecha</th>
						<th class="text-center">Estatus</th>
						<th class="text-end">Total</th>
						<th class="text-end">Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let oi of order_info_list">
						<td>
							<a *ngIf="oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
							<a *ngIf="oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
						</td>
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<a *ngIf="(oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
							<a *ngIf="oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order',oi.order.id]">{{(oi?.client?.name || oi.order.client_name ) | uppercase}}</a>
						</td>
						<td>{{oi?.cashier?.name | titlecase }}</td>
						<td>{{oi?.store?.name | titlecase}}</td>
						<td>
							<span *ngIf="oi.order.service_type == 'QUICK_SALE'">
								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'TOGO'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 4a2 2 0 0 0-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4h-3V4m-7 2l4 4l-4 4v-3H4V9h6m7 .5h2.5l1.97 2.5H17M6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'PICK_UP'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z"/></g></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'IN_PLACE'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z" fill="currentColor"/></svg>
							</span>
							<!--{{price_type_dic[ oi?.order.price_type_id ].name | titlecase}} -->
						</td>
						<td>{{oi.order.created | date: 'MMM d, y HH:mm' }}</td>
						<td class="text-end">{{oi?.order?.total | currency:'$' }}</td>
						<td class="text-end">
							<button type="button" class="btn btn-primary" (click)="proccessOrderInCloud(oi)">
								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318C0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"/></svg>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="row mb-3 border-bottom pb-2 d-lg-none" *ngFor="let oi of order_info_list">
			<div class="col-9">
				<a [routerLink]="['/view-order',oi.order.id]">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{oi.order.client_name}}
				</a>
			</div>
			<div class="col-3 px-0 text-end align-self-center">{{oi?.order?.total | currency:'$'}}</div>
			<div class="col-6 col-sm-4 align-self-center">
				<span *ngIf="oi?.order?.delivery_status == 'PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/></g></svg>
				</span>
				{{oi?.store?.name | titlecase}} {{(oi?.order?.delivery_status == 'DELIVERED') ? '✓':'' | titlecase}}
			</div>
			<div class="col-6 col-sm-2 col-md-2 align-self-center">{{oi.order.created | date: 'MMM d' }}</div>
			<div class="col-12 col-sm-6 col-md-6 text-end px-0">
				<a *ngIf="oi.order.status != 'CANCELLED' " [routerLink]="['/edit-order',oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-solid clr-i-solid-path-1" d="M4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4z" fill="currentColor"/><path class="clr-i-solid clr-i-solid-path-2" d="M33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93z" fill="currentColor"/></svg>
				</a>
				<!-- button type="button" *ngIf="oi.order.status !== 'CANCELLED' && oi.order.delivery_status == 'PENDING'" (click)="cancelOrder(oi)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59z" fill="currentColor"/></svg>
				</button -->
				<a *ngIf="oi.order.status !== 'CANCELLED'" [routerLink]="['/print-receipt',rest.local_preferences.default_print_receipt,oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path d="M90.443 35.141a1.825 1.825 0 0 0-1.818-1.708H75.511V11.857c0-1.012-.819-1.83-1.83-1.83H26.319c-1.011 0-1.83.818-1.83 1.83v21.576H11.377h-.002c-.969 0-1.754.755-1.818 1.708h-.012V71.91a1.83 1.83 0 0 0 1.83 1.829h13.114V58.425H75.51v15.314h13.114a1.83 1.83 0 0 0 1.83-1.829V35.141h-.011zm-19.919 6.49H29.476V16.844c0-1.012.819-1.83 1.83-1.83h37.387c1.011 0 1.83.818 1.83 1.83v24.787z" fill="currentColor"/><path d="M29.602 88.143c0 1.012.819 1.83 1.83 1.83h37.136c1.011 0 1.83-.818 1.83-1.83v-24.64H29.602v24.64z" fill="currentColor"/></svg>
				</a>
				<a *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-order'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
