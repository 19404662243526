<div class="container-fluid">
	<h1 class="my-3">Orden #{{order_info?.order?.id}}</h1>
	<div class="row">
		<div class="col-12 col-md">
			<h4 class="my-3">Datos de la orden</h4>
			<div class="card p-3">
				<div class="row" *ngIf="order_info">
					<div class="col-6 col-md-4">
						<label class="">Cliente:</label>
						<div>
							<img [src]="rest.getImagePath(order_info?.client?.image_id, rest.local_preferences?.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px;">
							<a *ngIf="order_info?.client" routerLink="/list-client" [queryParams]="{'csv.id':order_info.order.client_user_id}">{{order_info?.client?.name}}</a>
							<b *ngIf="!(order_info?.order?.client_user_id)">{{order_info?.order?.client_name}}</b>
						</div>
					</div>
					<div class="col-6 col-md-4 py-2" *ngIf="order_info">
						<label class="">Estatus:</label>
						<div class="fw-bold">
							<span *ngIf="order_info.order.status=='PENDING'">SIN CONFIRMAR</span>
							<span *ngIf="order_info.order.status=='ACTIVE'">EN CURSO</span>
							<span *ngIf="order_info.order.status=='CLOSED'">FINALIZADA</span>
							<span *ngIf="order_info.order.status=='CANCELLED'">CANCELADA</span>
						</div>
					</div>
					<div class="col-6 col-md-4 py-2">
						<label>Atendió</label>
						<div class="fw-bold">
							<img [src]="rest.getImagePath(order_info?.cashier?.image_id, rest.local_preferences?.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px;">
							{{order_info.cashier?.name}}
						</div>
					</div>
					<div class="col-6 col-md-4 py-2">
						<label class="">Almacén</label>
						<div class="fw-bold">{{order_info.store?.name | titlecase}}</div>
					</div>
					<div class="col-6 col-md-4 py-2">
						<label class="">Fecha Creación</label>
						<div class="fw-bold">{{order_info.order.created | shortDate:'full'}}</div>
					</div>
					<div class="col-6 col-md-4 py-2"	*ngIf="order_info.order.closed_timestamp">
						<label class="">Cerrada</label>
						<div class="fw-bold">{{order_info.order.closed_timestamp | shortDate:'full'}}</div>
					</div>
					<div class="col-6 col-md-4 py-2">
						<label class="">Fecha activacion</label>
						<div class="fw-bold">{{order_info.order.system_activated| shortDate:'full'}}</div>
					</div>
					<div class="col-6 col-md-4 py-2">
						<label class="">Código Facturación</label>
						<div class="fw-bold"> {{order_info.order.facturacion_code }}</div>
					</div>
					<div class="col-6 col-md-4 py-2" *ngIf="order_info?.price_type">
						<label class="">Tipo de consumo:</label>
						<div class="fw-bold">{{order_info.price_type.name | uppercase}}</div>
					</div>
					<div class="col-6 col-md-4 py-2" *ngIf="cancel_user">
						<label class="">Cancelado por:</label>
						<div class="fw-bold">{{ cancel_user.name }}</div>
					</div>
					<div class="col-6 col-md-4 py-2" *ngIf="order_info.order.cancellation_reason">
						<label class="">Motivo de cancelación:</label>
						<div class="fw-bold">{{ order_info.order.cancellation_reason }}</div>
					</div>
					<div class="col-6 col-md-2 py-2" *ngIf="order_info.order.status == 'PENDING' && order_info.order.delivery_status=='PENDING'">
						<label class="">&nbsp;</label>
						<div>
							<button class="btn btn-primary w-100" type="button" (click)="cancelOrder($event)">Cancelar Orden</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md" *ngIf="cformresponse_list.length">
			<h4 class="my-3">Formularios</h4>
			<div class="card p-3">
				<ng-container *ngFor="let form of cformresponse_list">
					<div class="row my-1 fw-bold border-bottom">
						<div class="col-6">{{form.form.title}}</div>
						<div class="col-6 text-end">Fecha</div>
					</div>
					<div class="row my-1 ms-0 me-0" *ngFor="let response of form.response">
						<div class="col-6 p-0 fw-bold">
							<a [routerLink]="['/edit-response',response.id]" >
								{{response.title ? response.title : form.form.title}}
							</a>
						</div>
						<div class="col-6 p-0 text-end" *ngIf="response">{{ response.created | shortDate: 'full'}}</div>
					</div>

				</ng-container>
			</div>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col-12 col-md-6">
			<h4 class="my-3">Facturación</h4>
			<div class="card py-3 px-0">
				<div class="row m-0">
					<div class="col-12 col-sm-6 mb-1">
						<a *ngIf="order_info.order.id" class="btn btn-primary w-100" [routerLink]="['/print-receipt',this.store.print_receipt || rest.local_preferences.default_print_receipt,order_info.order.id]">
							Imprimir Ticket
						</a>
						<a *ngIf="!order_info.order.id" class="btn btn-primary w-100 " [routerLink]="['/print-receipt-offline',this.store.print_receipt || rest.local_preferences.default_print_receipt,order_info.order.sync_id]">
							Imprimir Ticket
						</a>
					</div>
					<div class="col-12 col-sm-6 mb-1">
						<a class="btn btn-primary w-100" [routerLink]="['/print-remission',order_info.order.id]">Imprimir Remisión</a>
					</div>
					<div class="col-12 col-sm-6 mb-1" *ngIf="order_info.order.facturado == 'NO' && ( order_info.order.status == 'CLOSED' ) ">
						<button class="btn btn-primary w-100" type="button" (click)="showFacturar($event)">Facturar</button>
					</div>
					<div class="col-12 col-sm-6 mb-1">
						<button class="btn btn-primary w-100" type="button" (click)="show_refacturar=true">Reiniciar Pago/Facturacion</button>
					</div>
					<div class="col-12 col-sm-6 mb-1" *ngIf="order_info.order.facturado == 'YES' && order_info.order.sat_pdf_attachment_id">
						<button class="btn btn-primary w-100" (click)="showPdfFile(order_info?.order?.sat_pdf_attachment_id)">Ver Factura</button>
					</div>
					<div class="col-12 col-sm-6 mb-1" *ngIf="order_info.order.facturado == 'YES' && !order_info.order.sat_pdf_attachment_id">
						<a target="_blank" class="btn btn-primary w-100" [href]="getPdfUrl(order_info.order.id)">
							Ver Factura
						</a>
					</div>
					<div class="col-12 col-sm-6 mb-1" *ngIf="cancelar_factura_enabled">
						<button class="btn btn-primary w-100" (click)="cancelarFactura()">
							Cancelar Factura
						</button>
					</div>
					<div class="col-12 col-sm-6 mb-1" *ngIf="order_info.order.sat_pdf_attachment_id">
						<button *ngIf="order_info.order.sat_pdf_attachment_id" class="btn btn-primary w-100" (click)="show_reenviar_factura=true">
							Reenviar Factura
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="my-3 col-12 col-md-6" *ngIf="order_info.order.sat_xml_attachment_id">
			<app-satxmlviewer [xml_attachment_id]="order_info.order.sat_xml_attachment_id"></app-satxmlviewer>
		</div>
		<div class="col-12 col-md-6">
			<div class="row align-items-center">
				<div class="col-8 col-md-10">
					<h4 class="p-3 my-3">Pagos</h4>
				</div>
				<div class="col-4 col-md-2 text-end" *ngIf="order_info.order.status == 'CLOSED' && this.order_info.order.paid_status != 'PAID'">
					<button class="btn btn-secondary" type="button" (click)="showPaymentForm()">
						<svg xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"><path fill="currentColor" d="M251.1 207.1c0-11 9-20 20-20h16c11.9 0 20.9 9 20.9 20v68h4c11 0 20 9 20 20c0 11.9-9 20-20 20h-48.9c-11 0-20-8.1-20-20c0-11 9-20 20-20h4v-47.5c-8.2-1.9-16-9.9-16-20.5zM48.66 79.13C128.4 100.9 208.2 80.59 288 60.25c87-22.17 174-44.35 261-11.87c16.9 6.31 27 23.24 27 41.28V399.5c0 23.9-25.6 39.7-48.7 33.4c-79.7-21.8-159.5-1.5-239.3 18.8c-87.9 22.2-174.9 44.4-261.03 11.9C10.06 457.3 0 440.4 0 422.3V112.5c0-23.91 25.61-39.67 48.66-33.37zM127.1 416c0-35.3-27.75-64-64-64v64h64zm-64-192.9c36.25 0 64-27.8 64-64h-64v64zM512 352v-64.9c-35.3 0-64 29.6-64 64.9h64zm0-256.9h-64c0 36.2 28.7 64 64 64v-64zm-224.9 48c-53 0-96 51-96 112c0 62.8 43 112.9 96 112.9c53.9 0 96.9-50.1 96.9-112.9c0-61-43-112-96.9-112z"/></svg>
						<span class="">&nbsp;Abonar</span>
					</button>
				</div>
			</div>
			<div class="card p-3" *ngIf="order_info">
				<div class="row d-none d-md-flex fw-bold">
					<div class="col-3">Fecha</div>
					<div class="col-3 text-end">Tipo de pago</div>
					<div class="col-3 text-end">Cantidad</div>
					<div class="col-3 text-end">Acciones</div>
				</div>
				<div class="row border-bottom" *ngIf="payment_info_list.length == 0">
					<div class="col-12 text-center">
						No hay pagos registrados
					</div>
				</div>
				<div class="" *ngFor="let p of payment_info_list">
					<div class="row" *ngFor="let bm of p.movements">
						<div class="col-3">
							<a [routerLink]="['/print-payment-receipt',p.payment.id,order_info.order.id]">
								{{p.payment.created | date:'dd/MM/yyyy'}}
							</a>
						</div>
						<div class="col-4 col-md-3 text-end">
							{{ payment_type_dic[ bm.bank_movement.transaction_type ]}}
						</div>
						<div class="col-4 col-md-3 text-end">
							{{ bm.bank_movement.total | currency }}
							{{bm.bank_movement.currency_id}}
						</div>
						<div class="col-12 col-md-3 text-end">
							<button *ngIf="p.payment.facturado !== 'YES'" class="btn btn-danger w-10" (click)="cancelarPago(p.payment.id)" title="Cancelar Pago">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
							</button>
							<button type="button" *ngIf="order_info.order.facturado == 'YES' && p.payment.facturado == 'NO'" class="btn btn-primary ms-2" (click)="showFacturarPago(p)" title="Facturar Pago">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
									<path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
									<path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z"/>
								  </svg>
							</button>
							<!--button *ngIf="p.payment.facturado == 'YES' && !p.payment.sat_pdf_attachment_id" class="btn btn-primary w-100" (click)="showPdfFile(p?.order?.sat_pdf_attachment_id)">
								Factura
								</button-->
							<a target="_blank" *ngIf="p.payment.facturado == 'YES'" class="btn btn-primary w-10" [href]="getPaymentPdfUrl(p.payment.id)" title="Ver Factura">
								<svg _ngcontent-yke-c140="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1.25em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path _ngcontent-yke-c140="" d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"></path></svg>
							</a>
							<button class="btn btn-primary w-10 ms-2" [routerLink]="['/print-payment-receipt',p.payment.id,order_info.order.id]" title="Ver Pago">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
									<path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
									<path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md">
			<h4 class="p-3 my-3">Artículos</h4>
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table class="table">
							<thead class="thead-light">
								<tr>
									<th>Nombre</th>
									<th class="text-end"></th>
									<th class="text-end">Cantidad</th>
									<th class="text-end">Precio</th>
									<th class="text-end">Total</th>
								</tr>
							</thead>
							<tbody *ngIf="order_info">
								<ng-container *ngFor="let io of order_info.items">
									<tr>
										<td class="border-bottom-0 border-top" *ngIf="!io.order_item.item_option_id">
											<img [src]="rest.getImagePath(io.item.image_id,io?.category?.image_id,rest.local_preferences.default_product_image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
											<span *ngIf="!this.rest.current_permission.add_items">{{io.category?.name | titlecase}} - {{io.item.name| titlecase}}</span>
											<a *ngIf="this.rest.current_permission.add_items" [routerLink]="['/edit-item',io.item.id]">{{io.category?.name | titlecase}} - {{io.item.name| titlecase}}</a>

										</td>
										<td class="" [ngClass]="{'border-0':io.order_item.item_option_id,'border-top':!io.order_item.item_option_id, 'border-bottom-0':!io.order_item.item_option_id}"></td>
										<td class="border-0" *ngIf="io.order_item.item_option_id">
											{{io.item.name| titlecase}}
										</td>
										<td class="text-end" [ngClass]="{'border-0':io.order_item.item_option_id, 'border-top':!io.order_item.item_option_id,'border-bottom-0':!io.order_item.item_option_id}">
											{{io.order_item.qty | number}}
										</td>
										<td class="text-end" [ngClass]="{'border-0':io.order_item.item_option_id,'border-top': !io.order_item.item_option_id, 'border-bottom-0': !io.order_item.item_option_id}">
											<ng-container *ngIf="io.order_item.total">
												{{io.order_item.unitary_price | currency}}
											</ng-container>
										</td>
										<td class="text-end" [ngClass]="{'border-0':io.order_item.item_option_id, 'border-top': !io.order_item.item_option_id, 'border-bottom-0':!io.order_item.item_option_id}">
											<ng-container *ngIf="io.order_item.total">
												{{io.order_item.total | currency}}
											</ng-container>
										</td>
									</tr>
									<ng-container *ngIf="io.serials.length">
										<tr>
											<td colspan="6">
												<span *ngFor="let serial of io.serials">
													<br>
													{{serial.serial.serial_number}} - {{serial.serial.additional_data}} - {{serial.serial.description}}
												</span>
											</td>
										</tr>
									</ng-container>
								</ng-container>
								<tr *ngIf="order_info.order.discount>0">
									<th colspan="4">Descuento</th>
									<td colspan="4">{{order_info.order.discount | currency}}</td>
								</tr>
								<tr *ngIf="order_info.order.discount>0">
									<th class="fw-bold text-end" colspan="4">subtotal</th>
									<td class="fw-bold text-end">{{order_info.order.total | currency}}</td>
								</tr>
								<tr>
									<th class="fw-bold text-end" colspan="4">Total</th>
									<td class="fw-bold text-end">{{order_info.order.total-order_info.order.discount | currency}} {{order_info.order.currency_id}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md" *ngIf="cinstallment_list.length > 0">
			<h4 class="p-3 my-3">Registro de plazos</h4>
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table class="table">
							<thead class="thead-light">
								<tr>
									<th>#</th>
									<th class="text-end">Vencimiento</th>
									<th class="d-none d-md-table-cell text-end">Estatus</th>
									<th class="text-end">Monto</th>
									<th class="text-end">Abonado</th>
									<th class="d-none d-md-table-cell text-end">Fecha saldado</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let installment of cinstallment_list">
									<tr [ngClass]="{'bg-danger':installment.due_status == 'VENCIDO','text-white':installment.due_status == 'VENCIDO'}">
										<td>{{installment.installment_number}}</td>
										<td class="text-end">{{installment.due_date | shortDate:'full'}}</td>
										<td class="d-none d-md-table-cell text-end fw-bold">
											{{installment.due_status | titlecase}}
										</td>
										<td class="text-end">{{installment.amount | currency}}</td>
										<td class="text-end">{{installment.paid_amount | currency}}</td>
										<td class="d-none d-md-table-cell text-end">{{installment.paid_timestamp | shortDate:'full'}}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-modal [show]="show_add_payment_to_order" [biggest_posible]="true" [closable]="false" *ngIf="show_add_payment_to_order">
	<app-add-client-payment [currency_rate_list]="currency_rate_list" [order_info_list]="client_order_info_list" (onPayment)="onPayment($event)"></app-add-client-payment>
</app-modal>
<app-modal [(show)]="show_facturar">
	<div *ngIf="order_info">
		<app-save-order-factura [order_info]="order_info" (onFinish)="onTerminoFacturar($event)" [billing_data_list]="billing_data_list"></app-save-order-factura>
	</div>
</app-modal>
<app-modal [(show)]="show_reenviar_factura" [closable]="false">
	<div class="container-fluid" *ngIf="order_info">
		<h2>Reenviar Factura por correo</h2>
		<form name="send_factura_form" (submit)="resendFactura($event)" ngNativeValidate>
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row">
				<div class="col-12 col-md-6">
					<label>Nombre</label>
					<input type="text" name="name" [(ngModel)]="reenviar_factura_name" class="form-control">
				</div>
				<div class="col-12 col-md-6">
					<label>Correo Electrónico</label>
					<input type="email" name="factura_email" [(ngModel)]="reenviar_factura_email" class="form-control" required>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-6">
					<button type="button" class="btn btn-primary w-100" (click)="show_reenviar_factura=false" [disabled]="is_loading">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100">Enviar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>

<app-modal [(show)]="show_refacturar" [closable]="false">
	<div class="container-fluid" *ngIf="order_info">
		<h2>Reiniciar Facturación</h2>
		<form name="reset_factura_form" (submit)="resetFacturacion($event)" ngNativeValidate>
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row">
				<div class="col-12 col-md-6">
					<label>Forma de Pago</label>
					<select class="form-control" name="sat_forma_pago" [(ngModel)]="sat_forma_pago">
						<option value="">Seleccionar</option>
						<option value="01">Efectivo</option>
						<option value="02">Cheque</option>
						<option value="04">Tarjeta de crédito</option>
						<option value="28">Tarjeta de débito</option>
						<option value="03">Transferencia</option>
					</select>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-6">
					<button type="button" class="btn btn-primary w-100" (click)="show_refacturar=false" [disabled]="is_loading">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100">Reiniciar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>

<app-modal [(show)]="show_facturar_pago">
	<div class="container-fluid" *ngIf="order_info">
		<h2>Facturar el pago</h2>
		<app-loading [is_loading]="is_loading"></app-loading>
		<form name="facturar_tfp" (submit)="facturarElPago($event)" ngNativeValidate>
			<div class="row">
				<div class="col-12">
					<label>Fecha de pago</label>
					<input type="datetime-local" class="form-control" name="fecha_pago" [(ngModel)]="fecha_pago">
				</div>
				<div class="col-12">
					<label>Correo Electrónico</label>
					<input type="email" name="pago_factura_email" [(ngModel)]="pago_factura_email" class="form-control" required>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-6">
					<button type="button" class="btn btn-primary w-100" (click)="show_facturar_pago=false" [disabled]="is_loading">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100">Facturar Pago</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
