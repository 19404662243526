import { Component, OnInit } from '@angular/core';
import { Location } from	'@angular/common';
import {MapDirectionsService} from '@angular/google-maps';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, map, mergeMap} from 'rxjs/operators';
import {LatLng, OrderInfo, OrderItemInfo} from 'src/app/models/models';
import {BaseComponent} from 'src/app/pages/base/base.component';
import {ConfirmationService} from 'src/app/services/confirmation.service';
import {RestService} from 'src/app/services/rest.service';
import {ShortcutsService} from 'src/app/services/shortcuts.service';
import { forkJoin, of } from 'rxjs';
import { Table } from 'src/app/models/RestModels';

declare function renderPaypalButton2(rest:RestService,order_id:number, paypal_button_selector:string,callback:any ):any;

interface Coordinates
{
	lat:number;
	lng:number;
}

@Component({
	selector: 'app-client-view-order',
	templateUrl: './client-view-order.component.html',
	styleUrls: ['./client-view-order.component.css']
})
export class ClientViewOrderComponent extends BaseComponent
{
	order_info:OrderInfo | null = null;
	options_by_group:Record<number,OrderItemInfo[]> = {};

	directions_results:google.maps.DirectionsResult | undefined = undefined;
	geolocation_watch_id:number | null = null;
	order_distance:number = -1;
	maps_enabled:boolean = true;
	distance:number | 0;
	duration:string = 'N/A';
	current_position:LatLng | null = null;
	center:LatLng = {lat: 0, lng: 0};
	last_time_location_updated:number = 0;


	interval: number | null = null;
	table: Table | null = null;

	constructor
	(
		public rest: RestService,
		public confirmation:ConfirmationService,
		public shortcuts:ShortcutsService,
		public router: Router,
		public route: ActivatedRoute,
		public loc: Location,
		public title_service: Title,
		public map_direction_service:MapDirectionsService
	)
	{
		super(rest,confirmation,shortcuts,router,route,loc,title_service);
	}


	ngOnInit(): void
	{
		this.subs.sink = this.rest.updates.subscribe((message)=>
		{
			if( ! this.order_info || message.type != 'order' )
				return;

			this.subs.sink = this.rest.order_info.get( this.order_info.order.id )
			.subscribe((response)=>
			{
				console.log('THE SUPER TABLE IS ', response);
				this.order_info = response;
				if( this.order_info.delivery_user )
				{
					//this.current_position = { lat: order_info.delivery_user.lat, lng: order_info.delivery_user.lng };
					this.current_position = this.getCenter();
					this.center = this.getCenter();

					console.log('Displaying directions');
					this.displayDirections();
				}
			});
		});

		//this.interval = setInterval(()=>{
		//	console.log('QUE PESOOOOOO');
		//	if( !this.order_info || this.order_info.order.delivery_status == 'DELIVERED')
		//		return;

		//	this.subs.sink = this.rest.order_info.get( this.order_info.order.id ).subscribe( (order_info)=>
		//	{
		//		this.order_info = order_info;
		//		if( order_info.delivery_user )
		//		{
		//			this.current_position = { lat: order_info.delivery_user.lat, lng: order_info.delivery_user.lng };
		//			console.log('Displaying directions');
		//			this.displayDirections();
		//		}
		//	});
		//},10000);



		this.rest.loadGoogleMapsApi().subscribe(()=>{
			console.log('Google Maps API loaded');
		});

		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				return this.rest.order_info.get( param_map.get('order_id') )
			}),
			mergeMap((order_info)=>
			{
				return forkJoin({
					order_info: of( order_info ),
					table: order_info.order.table_id
						? this.rest.table.get( order_info.order.table_id )
						: of(null)
				})
			})
		)
		.subscribe((response)=>
		{
			response.order_info.items.forEach((asdf)=>
			{
				if( !(asdf.order_item.item_group in this.options_by_group) )
				{
					this.options_by_group[ asdf.order_item.item_group ] = [];
				}

				if( asdf.order_item.item_option_id )
				{
					this.options_by_group[ asdf.order_item.item_group ].push( asdf );
				}
			})

			this.table = response.table;

			this.order_info = response.order_info;

			console.log('Time loading',Date.now());

			if( this.order_info.order.paid_status == 'PENDING' )
			{
				setTimeout(()=>{
					console.log('RENDER PAYAPAL BUTTON');
					//renderPaypalButton2(this.rest, this.order_info.order.id, '#paypal_button_client', ()=>{
					//	this.showSuccess('El pago fue recibido con exito');
					//});
				},1000);
			}
			else
			{
				console.log('FALLO EL PEDO');
			}

			if( this.order_info.order.lat || this.order_info?.delivery_user?.lat )
			{
				if( this.order_info.order.lat && this.order_info.order.lng )
				{
					this.current_position = { lat: this.order_info.order.lat, lng: this.order_info.order.lng };
					return;
				}

				this.current_position = { lat: this.order_info.delivery_user.lat, lng: this.order_info.delivery_user.lng };
			}
		})
	}


	getCenter()
	{
		if( !this.order_info )
			return { lat: 0, lng: 0 };


		let lat:number = this.order_info.order.lat;
		let lng:number = this.order_info.order.lng;

		if( this.order_info.delivery_user )
		{
			lat = (lat+this.order_info.delivery_user.lat)/2;
			lng = (lng+this.order_info.delivery_user.lng)/2;
		}

		//else if( this.order_info.store.lat && this.order_info.store.lng )
		//{
		//	lat = (lat+this.order_info.lat)/2;
		//	lng = (lng+this.order_info.delivery_user.lng)/2;
		//}

		return { lat, lng };
	}

	ngOnDestroy(): void
	{
		if( this.interval )
		{
			clearInterval( this.interval );
		}
	}

	processPosition(geolocation_position:GeolocationPosition)
	{
		this.current_position = {
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};
		let current:Coordinates = {
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};

		if( this.last_time_location_updated + 5000 < Date.now() )
		{
			this.last_time_location_updated = Date.now();
			this.subs.sink = this.rest.updateUserPosition(current).subscribe(()=>{
				console.log('Posicion actualizada');
			});
		}

		if( this.order_info )
		{
			if( this.order_info.order.lat )
			{
				let to:Coordinates = {
					lat: this.order_info.order.lat,
					lng: this.order_info.order.lng
				};
				let d:number = this.distanceTo(current, to );
				console.log('Distance is ',d);
				this.order_distance = d;
				//this.displayDirections();
			}
			else
			{
				this.order_distance = -1;
			}
		}
	}

	displayDirections()
	{
		if( this.rest.is_maps_loaded )
		{
			console.log('Looking for directions');

			const request: google.maps.DirectionsRequest = {
				destination: {lat:this.order_info.order.lat, lng: this.order_info.order.lng},
				origin: this.current_position,
				travelMode: google.maps.TravelMode.DRIVING
			};

			this.subs.sink = this.map_direction_service.route(request)
			.pipe(map(response => response.result))
			.subscribe((result)=>
			{
				if( result.routes.length )
				{
					if( result.routes[0].legs.length )
					{
						this.distance = result.routes[0].legs[0].distance.value;
						this.duration = result.routes[0].legs[0].duration.text;
					}
				}
				result.routes
				console.log( result );
				this.directions_results = result;
			});
		}
	}

	/**
	* Returns the distance along the surface of the earth from ‘this’ point to destination point.
	*
	* Uses haversine formula: a = sin²(Δφ/2) + cosφ1·cosφ2 · sin²(Δλ/2); d = 2 · atan2(√a, √(a-1)).
	*
	* @param	{Coordinates} point_a - Latitude/longitude of destination point.
	* @param	{Coordinates} point_b
	* @param	{number} [radius=6371e3] - Radius of earth (defaults to mean radius in metres).
	* @returns {number} Distance between this point and destination point, in same units as radius.
	*/
	distanceTo(point_a:Coordinates,point_b:Coordinates, radius=6371e3):number
	{
		// a = sin²(Δφ/2) + cos(φ1)⋅cos(φ2)⋅sin²(Δλ/2)
		// δ = 2·atan2(√(a), √(1−a))
		// see mathforum.org/library/drmath/view/51879.html for derivation

		const R = radius;

		const φ1 = this.toRadians(point_a.lat);
		const λ1 = this.toRadians(point_a.lng);
		const φ2 = this.toRadians(point_b.lat);
		const λ2 = this.toRadians(point_b.lng);
		const Δφ = φ2 - φ1;
		const Δλ = λ2 - λ1;

		const a = Math.sin(Δφ/2)*Math.sin(Δφ/2) + Math.cos(φ1)*Math.cos(φ2) * Math.sin(Δλ/2)*Math.sin(Δλ/2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		const d = R * c;

		return d;
	}

	toRadians(lat_or_lng:number):number
	{
		return lat_or_lng* Math.PI / 180;
	}
}
