<div class="container-fluid">
	<div class="row align-items-center mx-0">
		<div class="col-10">
			<h1 class="my-3">Ajustar inventario</h1>
		</div>
		<div class="col-2 text-end px-0">
			<button type="button" class="btn btn-secondary me-1" (click)="showModalReport()">
				<svg  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 7H6V4q0-.425.287-.713Q6.575 3 7 3h10q.425 0 .712.287Q18 3.575 18 4Zm0 5.5q.425 0 .712-.288q.288-.287.288-.712t-.288-.713Q18.425 10.5 18 10.5t-.712.287Q17 11.075 17 11.5t.288.712q.287.288.712.288ZM8 19h8v-4H8v4Zm0 2q-.825 0-1.412-.587Q6 19.825 6 19v-2H3q-.425 0-.712-.288Q2 16.425 2 16v-5q0-1.275.875-2.137Q3.75 8 5 8h14q1.275 0 2.138.863Q22 9.725 22 11v5q0 .425-.288.712Q21.425 17 21 17h-3v2q0 .825-.587 1.413Q16.825 21 16 21Z"></path></svg>
				<span class="d-none d-lg-inline">Generar Reporte</span>
			</button>
		</div>
	</div>



	<div class="card p-3">
		<div class="row mb-3">
			<div class="col-5 col-md-4 form-group">
				<label>Artículo</label>
				<!--input type="text" name="item_name" [ngModel]="item_name" class="form-control"-->
				<input type="text" (input)="onSearch($event)" class="form-control" placeholder="Buscar..." [disabled]="is_saving" autocomplete="off">
			</div>
			<div class="col-5 col-md-4">
				<label>Sucursal</label>
				<select name="store_id" [(ngModel)]="store_id" class="form-control" (ngModelChange)="cambioStockRecord($event)" [disabled]="is_saving || !rest.current_permission.global_add_stock">
					<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3">
				<div class="form-group">
					<label>Disponibilidad</label>
					<select name="availability" [ngModel]="item_search.eq.availability_type" (ngModelChange)="onAvailabilityChange($event)" class="form-control">
						<option [ngValue]="undefined">Todos</option>
						<option value="ON_STOCK">Siempre en Inventario</option>
					</select>
				</div>
			</div>
			<!--div class="col-2 form-group">
				<label></label>
				<div>
					<button type="button" class="btn btn-primary" (click)="searchTerm()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div-->
		</div>
	</div>

	<form (submit)="saveAll($event)" ngNativeValidate>
		<div class="card p-3 mt-3">
			<div>
				<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
			</div>
			<div class="row my-1 fw-bold d-none d-md-flex align-items-center">
				<div class="col-6">Artículo</div>
				<div class="col-2 text-end">En Existencia</div>
				<div class="col-2 text-end">Cantidad</div>
				<div class="col-2 text-end">
					<button type="submit" class="btn btn-primary btn-sm">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						&nbsp;<span class="d-none d-lg-inline">
							Guardar Todo
						</span>
					</button>
				</div>
			</div>
			<div class="row py-1 border-bottom align-items-center" *ngFor="let item_info of item_info_list">
				<div class="col-6 fw-bold">
					{{item_info?.category?.name || 'N/A'}} - {{item_info.item.name }}
					<span style="color:#BBBBBB">{{item_info.item.code}}</span>
				</div>
				<div class="col-6  col-md-2 text-end">
					<span class="d-inline d-md-none">Existencia -</span> {{item_info.on_stock | number }}
				</div>
				<div class="col-10 col-md-2 form-group">
					<label class="d-md-none">Cantidad</label>
					<input name="item_qty" maxlength="13" [name]="''+item_info.item.id" [(ngModel)]="item_info.stock_record.qty" type="number" class="form-control text-end" min=0 required [step]="item_info.item.partial_sale == 'NO'?'1':'any'" max=999999999>
				</div>
				<div class="col-2 text-end">
					<label class="d-md-none">&nbsp;</label>
					<div>
						<button type="button" class="btn btn-primary" (click)="updateStock(item_info)">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
							&nbsp;<span class="d-none d-lg-inline">
								Guardar
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div>
			<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
		</div>
	</form>
</div>

<app-modal [(show)]="show_modal_report">
	<form (submit)="createReport($event)" ngNativeValidate>
		<div class="p-3">
			<h2>Reporte de ajustes</h2>
			<div class="row">
				<div class="col-6 form-group">
					<label>Sucursal</label>
					<select name="report_store_id" [(ngModel)]="report_store_id" class="form-control" required [disabled]="!rest.current_permission.global_add_stock">
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
					</select>
				</div>

				<div class="col-6 form-group">
					<label>Inicio</label>
					<input type="datetime-local" name="start_time" [(ngModel)]="start_time" class="form-control" required>
				</div>
				<div class="col-6 form-group">
				<label>Fin</label>
					<input type="datetime-local" name="end_time" [(ngModel)]="end_time" class="form-control" required>
				</div>
				<div class="col-12 form-group mt-2 text-end">
					<!-- button type="submit" class="btn btn-primary">Generar</button -->
				</div>

				<div class="col-4 form-group mt-2 text-end">
					<button type="reset" class="btn btn-primary w-100" (click)="show_modal_report=false">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6Zm3.6 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"/></svg>
						Cancelar
					</button>
				</div>
				<div class="col-4 form-group mt-2 text-end">
				<button type="submit" class="btn btn-primary w-100" name="action" value="print">
					<svg  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 7H6V4q0-.425.287-.713Q6.575 3 7 3h10q.425 0 .712.287Q18 3.575 18 4Zm0 5.5q.425 0 .712-.288q.288-.287.288-.712t-.288-.713Q18.425 10.5 18 10.5t-.712.287Q17 11.075 17 11.5t.288.712q.287.288.712.288ZM8 19h8v-4H8v4Zm0 2q-.825 0-1.412-.587Q6 19.825 6 19v-2H3q-.425 0-.712-.288Q2 16.425 2 16v-5q0-1.275.875-2.137Q3.75 8 5 8h14q1.275 0 2.138.863Q22 9.725 22 11v5q0 .425-.288.712Q21.425 17 21 17h-3v2q0 .825-.587 1.413Q16.825 21 16 21Z"></path></svg>
					Imprimir
				</button>
				</div>
				<div class="col-4 form-group mt-2 text-end">
				<button type="submit" class="btn btn-primary w-100" name="action" value="export">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
					Exportar
				</button>
				</div>
			</div>
		</div>
	</form>
</app-modal>
