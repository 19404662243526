import { Component, OnInit } from '@angular/core';
import {mergeMap,filter} from 'rxjs/operators';
import {TableInfo} from 'src/app/models/models';
import {Table} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-tables',
	templateUrl: './tables.component.html',
	styleUrls: ['./tables.component.css']
})

export class TablesComponent extends BaseComponent implements OnInit
{
	table_info_list:TableInfo[] = [];
	selected_table_info:TableInfo | null = null;
	show_orders:boolean = false;

	showOrders(table_info:TableInfo)
	{
		this.selected_table_info = table_info;
		this.show_orders = true;
	}

	ngOnInit(): void
	{
		this.route.paramMap.pipe
		(
			mergeMap(()=>
			{
				this.is_loading = true;
				return this.getSearchObservable( this.rest.current_user.store_id );
			})
		)
		.subscribe((response)=>
		{
			this.is_loading = false;
			this.table_info_list = response.data;
		},(error)=>this.showError(error));

		this.subs.sink = this.rest
			.updates
			.pipe
			(
				filter((response)=>response?.type == 'order'),
				mergeMap((response)=> this.rest.order.get( response.id )),
				filter((order)=>order.store_id == this.rest.current_user.store_id && !!(order.table_id) ),
				mergeMap((order)=>
				{
					return this.getSearchObservable( order.store_id );
				})
			)
			.subscribe((response)=>
			{
				this.table_info_list = response.data;
			});
	}

	getSearchObservable(store_id:number)
	{
		let table_search:SearchObject<Table> = this.getEmptySearch();

		if( !(table_search.eq.store_id) )
		{
			table_search.eq.store_id = this.rest.current_user.store_id;
		}

		return this.rest.table_info.search( table_search );
	}
}
