import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import {Utils} from 'src/app/classes/Utils';
import {BankMovementInfo, FacturacionRequest, OrderInfo, OrderItemInfo, PaymentInfo, ReturnsInfo} from 'src/app/models/models';
import {Bank_Movement, Bank_Movement_Order, Billing_Data, Currency_Rate, Order, Payment, Price_Type, Store} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';
import { SearchObject } from 'src/app/services/Rest';
import * as marked from 'marked';

declare function txt2html(str:string):string;
declare function printHtml(html_body:string,title:string):any;

@Component
({
	selector: 'app-make-payment',
	templateUrl: './make-payment.component.html',
	styleUrls: ['./make-payment.component.css']
})
export class MakePaymentComponent extends BaseComponent implements OnInit,OnChanges,AfterViewInit
{
	@Input() order_info:OrderInfo = GetEmpty.orderInfo(this.rest);
	@Input() order_item_qty_dictionary:Record<number,number> = {};
	@Input() partial_amount:number = 0;
	@Input() tag:string = 'SALE';
	@Input() currency_rate_list:Currency_Rate[] = [];
	@Input() price_type_list:Price_Type[] = [];
	@ViewChild('efectivo') efectivoElement:ElementRef;

	@Output() order_infoChange: EventEmitter<OrderInfo> = new EventEmitter<OrderInfo>();

	discount_code:string = '';
	copies:number = 1;

	payment_button_disabled:boolean = true;

	print_store:Store = GetEmpty.store();

	cash:number = 0;
	dolares:number = 0;
	points:number = 0;
	card_amount:number=0;
	transfer:number = 0;
	check:number = 0;

	is_credit_card:boolean = false;
	card_ending:string = '0000';
	reference:string = null;
	exchange_rate:number = 19.5;
	factura_enabled:boolean = false;
	transfer_reference:string = '';
	origin_bank_name:string = '';
	check_reference:string = '';
	is_partial_payment:boolean = false;
	returns_info_list: ReturnsInfo[] = [];
	percentage_discount:number = 0;
	total_discount:number = 0;
	items_total:number = 0;
	cash_string:string = '';
	show_other_payment_methods:boolean =false;
	show_discounts:boolean =false;
	currency_rate: any;
	dolares_display:number = 0;
	display_coupon_amount:number = 0;
	received_amount: number = 0;
	change_amount: number = 0;
	//credit variables
	client_has_credit:boolean = false;
	user_credit_permission:boolean = false;
	total_amount_pagado:boolean = false;
	@Input() user_balance:number = 0;
	store:Store = GetEmpty.store();
	store_list:Store[] = [];

	//installment variables
	show_installments:boolean = false;

	ngOnInit()
	{
		this.tag = 'SALE';
		this.rest.getStores(true).subscribe((stores)=>
		{
			this.store_list = stores.data;
		});

		this.rest.getStore( this.rest.current_user.store_id ).subscribe((store)=>
		{
			this.print_store = store;
		});

		this.subs.sink = this.route.params.subscribe(_params =>
		{
			this.restartVars();
		});
	}

	focusCash()
	{
		let interval_id = setInterval(()=>{
			let x = document.getElementById('efectivo') as HTMLInputElement;
			if( x )
			{
				clearInterval( interval_id );
				try{
					x.focus();
					//x.setSelectionRange(0,x.value.length,'forward');
				}
				catch(e){
					console.log('FOOOOO',e);
				}
			}
		},100);
	}

	ngAfterViewInit()
	{
		//this.efectivoElement.nativeElement.focus();
		//let length = this.efectivoElement.nativeElement.value.length;
		//this.efectivoElement.nativeElement.setSelectionRange(0,length+1);
	}

	restartVars()
	{
		this.returns_info_list = [];

		this.display_coupon_amount = 0;
		this.total_discount = this.order_info.order.discount || 0;
		this.addAmountDiscount( this.order_info.order.discount );

		let store = this.store_list.find( store => store.id == this.order_info.order.store_id ) as Store;

		this.order_info.store = store ? store : GetEmpty.store();


		this.percentage_discount = 0;
		this.cash = 0;
		this.dolares = 0;
		this.points = 0;
		this.card_amount =0;
		this.transfer = 0;
		this.check = 0;
		this.is_credit_card = false;
		this.card_ending = '0000';
		this.reference = '';
		this.exchange_rate = 21;
		this.factura_enabled = false;
		this.transfer_reference = '';
		this.origin_bank_name = '';
		this.check_reference = '';
		this.is_partial_payment = false;
		this.returns_info_list = [];
		this.discount_code = '';
		this.payment_button_disabled = true;
		this.payment_info.payment.currency_id = this.order_info.order.currency_id;
		//credit variables
		this.total_amount_pagado = false;

		// Las preferencias de impresion son de la sucursal donde se esta imprimiendo no donde se esta haciendo la orden
		if( this.print_store.print_receipt_copies )
		{
			this.copies = this.print_store.print_receipt_copies;
		}
	}

	addAmountDiscount(discount:number,updateOther=true)
	{
		if( discount == 0 )
		{
			this.order_info.items.forEach(i=>i.order_item.discount = 0);
		}

		let subtotal	= this.order_info.items.reduce((a,b)=>a+b.order_item.subtotal,0)
		let total		= subtotal*(1+this.order_info.order.tax_percent/100);
		let percentage	= (discount/total);

		this.order_info.items.forEach(i=>i.order_item.discount = i.order_item.subtotal*percentage);

		this.items_total = this.order_info.items.reduce((p,i)=>
		{
			return i.order_item.subtotal - i.order_item.discount
		},0);

		//this.order_info.items.forEach(i=>console.log({total:	i.order_item.total, discount: i.order_item.discount, subtotal: i.order_item.subtotal }));

		this.total_discount = discount;
		this.order_info.order.discount = discount;

		if( updateOther )
			this.percentage_discount = this.roundTo4(percentage)*100;

		this.updatePaymentData();
	}

	addPercentageDiscount(percent:number,updateOther=true)
	{
		let total		= this.order_info.items.reduce((a,b)=>a+b.order_item.total,0);
		let amount		= Math.round( total*(percent/100 ) );

		if( percent == 100 )
		{
			amount = total;
		}

		if( updateOther )
		{
			this.addAmountDiscount(amount,false);
		}
		else
		{
			this.updatePaymentData();
		}
	}

	roundTo2(value:number)
	{
		return Math.floor(value*100)/100;
	}

	roundTo4(value:number)
	{
		return Math.floor(value*1000)/1000;
	}

	payment_info:Partial<PaymentInfo> =
	{
		movements:[],
		payment:
		{
			type:'income',
			tag:'SALE',
			payment_amount: 0,
			received_amount: 0,
			facturado: 'NO',
			sat_pdf_attachment_id: null,
			sat_uuid: null,
			sat_xml_attachment_id: null,
			change_amount: 0,
			currency_id: 'MXN',
			sync_id: this.rest.getSyncId()
		},
	};

	updatePaymentData()
	{
		if( this.hasElectronicPayment() )
		{

		}

		let movements:BankMovementInfo[] = [];

		//let total = (this.card_amount || 0)
		//			+ (this.cash || 0 )
		//			+ (this.check || 0 )
		//			+ (this.dolares || 0 )
		//			+ (this.transfer || 0 );

		let bank_movement:Bank_Movement = GetEmpty.bank_movement();

		let total_returns_discount:number = 0;

		this.returns_info_list.forEach((returns_info)=>
		{
			let amount = Utils.truncate( returns_info.returns.total-returns_info.returns.amount_paid, 2 );

			if( (total_returns_discount + amount) > this.order_info.order.total )
			{
				amount = Utils.truncate(this.order_info.order.total - total_returns_discount,2);
			}

			total_returns_discount +=	amount;

			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'RETURN_DISCOUNT',
					client_user_id	: this.order_info.order.client_user_id,
					total			: Utils.truncate(amount,2),
					status			: 'ACTIVE',
					amount_received	: Utils.truncate(amount,2),
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
					origin_bank_name: null,
					reference		: returns_info.returns.code,
				},
				bank_movement_orders:[{
					currency_amount: amount,
					amount: amount*this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			})
		});

		//console.log('Aqui es ', total_returns_discount );
		this.display_coupon_amount = total_returns_discount;


		//El orden es importante
		if( this.card_amount )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: this.is_credit_card ? 'CREDIT_CARD' : 'DEBIT_CARD',
					client_user_id	: this.order_info.order.client_user_id,
					total			: Utils.truncate(this.card_amount,2),
					amount_received	: Utils.truncate(this.card_amount,2),
					status			: 'ACTIVE',
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					origin_bank_name: null,
					type			: 'income',
					card_ending		: this.card_ending,
				},
				bank_movement_orders:[{
					currency_amount: Utils.truncate(this.card_amount,2),
					amount: Utils.truncate(this.card_amount,2)*this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.transfer )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'TRANSFER',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.transfer,
					amount_received	: this.transfer,
					status			: 'ACTIVE',
					origin_bank_name: null,
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
					reference		: this.reference
				},
				bank_movement_orders:[{
					currency_amount: this.transfer,
					amount: this.transfer*this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					currency_id: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.check )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CHECK',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.check,
					origin_bank_name: this.origin_bank_name,
					status			: 'ACTIVE',
					amount_received	: this.check,
					currency_id		: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					type			: 'income',
				},
				bank_movement_orders:
				[{
					currency_amount: this.check,
					amount: this.check*this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					status			: 'ACTIVE',
					exchange_rate	: this.getExchangeRate('MXN'),
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.cash )
		{
			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CASH',
					client_user_id: this.order_info.order.client_user_id,
					total: Utils.truncate(this.cash,2),
					amount_received	: this.cash,
					origin_bank_name: null,
					exchange_rate	: this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					status			: 'ACTIVE',
					type: 'income',
				},
				bank_movement_orders:
				[{
					currency_amount: Utils.truncate(this.cash),
					amount: Utils.truncate(this.cash)*this.getExchangeRate('MXN'),
					currency_id: 'MXN',
					exchange_rate	: this.getExchangeRate('MXN'),
					status			: 'ACTIVE',
					order_id: this.order_info.order.id
				}]
			});
		}

		if( this.dolares )
		{
			let currency_rate = this.currency_rate_list.find((i:Currency_Rate)=>i.currency_id == 'USD' && this.order_info.order.store_id == i.store_id) as Currency_Rate;

			//console.log('Rate is FOR USD', currency_rate.rate, this.getExchangeRate('USD') );

			movements.push
			({
				bank_movement:
				{
					...bank_movement,
					transaction_type: 'CASH',
					client_user_id	: this.order_info.order.client_user_id,
					total			: this.dolares,
					origin_bank_name: null,
					exchange_rate	: this.getExchangeRate('USD'),
					amount_received	: this.dolares,
					currency_id		: 'USD',
					status			: 'ACTIVE',
					type			: 'income',
				},
				bank_movement_orders:[{
					currency_id		: 'USD',
					status			: 'ACTIVE',
					currency_amount	: this.dolares,
					amount			: Utils.truncate(this.dolares*this.getExchangeRate('USD')),
					exchange_rate	: this.getExchangeRate('USD'),
					order_id		: this.order_info.order.id
				}]
			});
			//console.log('QUE ESTA PASAAANDA',this.dolares, Utils.truncate(this.dolares*this.getExchangeRate('USD')));
		}

		let received_amount:number = 0;
		let payment_amount:number = 0;

		let order_total =this.order_info.order.total-this.order_info.order.discount;

		let received = [];

		movements.forEach((bmi:BankMovementInfo)=>
		{
			let total = 0;
			bmi.bank_movement_orders.forEach((bmo:Bank_Movement_Order)=>
			{
				received_amount += bmo.amount;
				received.push({ received_amount });

				if( payment_amount >= order_total )
					return;

				//Si sobra dinero
				if( order_total < (payment_amount+bmo.amount ) )
				{
					let new_amount = order_total-payment_amount;
					bmo.amount = new_amount;
					bmo.currency_amount	= new_amount/bmo.exchange_rate;
					payment_amount	= order_total;
				}
				else
				{
					payment_amount += bmo.amount;
				}
				total += bmo.currency_amount;
			})
			bmi.bank_movement.total = total;
		});

		//console.table( received );
		let t = this.order_info.order.total-this.order_info.order.discount;
		this.change_amount = Math.abs( t -received_amount );
		this.change_amount = this.change_amount <= 0.01 ? 0 : this.change_amount;
		//console.log('payment_amount',payment_amount,'received_amount',received_amount,'change_amount',this.change_amount);
		this.received_amount = received_amount;
		//console.log({payment_amount:payment_amount,received_amount:received_amount})

		this.payment_info.payment.payment_amount = payment_amount;
		this.payment_info.payment.received_amount = received_amount;

		//si el payment_amount es menor al total de la orden, el cambio es 0
		//esto es para evitar que el cambio sea negativo
		//tambien verifica el unico caso de credito
		if( this.payment_info.payment.payment_amount < (this.order_info.order.total - this.order_info.order.discount) )
		{
			this.payment_info.payment.change_amount	= 0;
		}
		else
		{
			this.payment_info.payment.change_amount	= this.change_amount;
		}
		this.payment_info.movements = movements;

		//console.log("Disabled because",!(this?.order_info.client?.id),
		this.payment_button_disabled = !(this?.order_info.client?.id) && this.order_info.order.total > (( this.payment_info.payment.received_amount + this.total_discount )+0.003)
		//console.log('Updateed data',this.payment_button_disabled);
		//
		//
		//
		//set credit variables
		this.client_has_credit = this.order_info.client && this.order_info.client.credit_limit > 0;
		this.user_credit_permission = !!(this.rest.current_permission.add_credit_sales);
		this.total_amount_pagado =  this.payment_info.payment.received_amount >= (this.order_info.order.total-this.order_info.order.discount);
		//console.log('Total amount pagado',this.total_amount_pagado);

		if( this.currency_rate_list.length )
		{
			let rate = this.getExchangeRate('USD');

			this.dolares_display= Math.ceil( ((this.order_info.order.total-this.total_discount)/rate*100 ) )/100;
		}
	}

	hasElectronicPayment():boolean
	{
		return !!(this.card_amount || this.transfer || this.check);
	}

	makePayment(evt:any)
	{
		//console.log('Procesar pago hay va ');

		this.is_loading = true;
		this.updatePaymentData();
		if( this.payment_info.payment.payment_amount == 0 )
		{
			this.addOrderToCredit()
			return;
		}

		this.setStoreBillingData();
		// Registrar Pago

		this.subs.sink = ( this.rest.is_offline ? of( this.order_info ) : this.rest.order_info.update( this.order_info ) )
		.pipe
		(
			mergeMap((_order_info:OrderInfo)=>
			{
				return this.rest.makePayment(this.payment_info,this.order_info)
			}),
			mergeMap((_response)=>
			{
				this.showSuccess('El pago se guardo exitosamente' );
				this.rest.sendNotification('order', this.order_info.order.id);
				this.rest.updateCommandas( this.order_info.order.store_id );

				if( !this.rest.is_offline )
				{
					this.subs.sink = this.rest
					.update('addOrderItemsToCommanda',{order_id: this.order_info.order.id })
					.subscribe(()=>
					{
						//console.log('Order items added to commanda',this.order_info.order.id);
					},(error)=>this.showError(error))
				}

				return this.rest.is_offline
					? of( this.order_info )
					: this.rest.order_info.get( this.order_info.order.id );
			}),
			mergeMap((response)=>
			{
				return this.printFloatWindowReceipt( response );
			})
		)
		.subscribe((response)=>
		{
			//this.order_info = response;
			console.log('Hay que imprimir el pago', response );


			let print_receipt = this.rest.local_preferences.default_print_receipt;

			if( this.store.print_receipt )
				print_receipt = this.store.print_receipt;


			if( print_receipt == 3 )
			{
				//this.router.navigate(['/pos'],{replaceUrl:true});
			}
			else if( print_receipt == 7 )
			{
				this.router.navigate(['/pos'],{replaceUrl:true});

				if( this.order_info.order.id )
				{
					this.rest.sendNotification('order',this.order_info.order.id );
					//	url = '/#/print-receipt/'+this.rest.local_preferences.default_print_receipt+'/'+this.order_info.order.id+'/print/'+this.copies;

					if( this.copies == 0)
						return;

					this.router.navigate(['/print-receipt',print_receipt,this.order_info.order.id,1,this.copies]);
					return;
					//'/print-receipt',rest.local_preferences.default_print_receipt,order_info.order.id]">Imprimir Ticket</a>
				}
				else
				{
					if( this.copies == 0)
						return;

					this.router.navigate(['/print-receipt-offline',print_receipt,this.order_info.order.id,1,this.copies]);
					return;
				}
				return;
			}
			else
			{
				let url = '';
				if( this.order_info.order.id )
				{
					url = '/#/print-receipt/'+print_receipt+'/'+this.order_info.order.id+'/print/'+this.copies;
					this.rest.sendNotification('order',this.order_info.order.id );
				}
				else
				{
					url = '/#/print-receipt-offline/'+print_receipt+'/'+response.order.sync_id+'/print/'+this.copies;
				}

				//XXX NO BORRAR el log Es indispensable para que siga existiendo el contexto del evento y no bloque la
				//el abrir la ventana
				console.log( evt );
				//XXX NO BORRAR
				//this.router.navigate(['/print-receipt',this.rest.local_preferences.default_print_receipt,this.order_info.order.id,1,this.copies]);

				/*
				console.log('Es mobile', this.isMobile() );
				if( this.isMobile() )
				{
					//url = '/#/print-receipt-offline/'+this.rest.local_preferences.default_print_receipt+'/'+response.order.sync_id+'/print/'+this.copies;
					console.log('Navigating to');
					this.router.navigate(['/print-receipt',this.rest.local_preferences.default_print_receipt,this.order_info.order.id]);
					return;
				}
				else
				{

				}
				*/
				if( this.copies != 0)
				{
					console.log('Evento de abrir ventana');
					console.log('Redireccionando');
					window.open( url );
				}

			}
			//x.addEventListener('afterprint',(evt)=>
			if( this.factura_enabled && !this.rest.is_offline && this.order_info.order.id ) //&& !this.isMobile() )
			{
				this.showWarning('Facturando por favor espere');
				this.subs.sink = this.rest
				.updateDatosFacturacion(this.order_info.order)
				.pipe
				(
					mergeMap((x)=>
					{
						let o:Order = this.order_info.order;
						let facturacion_request:FacturacionRequest ={
							facturacion_code: o.facturacion_code,
							domicilio_fiscal: o.sat_domicilio_fiscal_receptor,
							regimen_fiscal: o.sat_regimen_fiscal_receptor,
							rfc: o.sat_receptor_rfc,
							email: o.sat_receptor_email,
							uso_cfdi: o.sat_uso_cfdi,
							razon_social: o.sat_razon_social,
							regimen_capital: o.sat_regimen_capital_receptor,
							note: '',
							version: "4.0"
						};

						return this.rest.facturacion_request.create( facturacion_request );
					})
				)
				.subscribe(()=>
				{
					this.rest.showSuccess('Se facturo correctamente');
					this.router.navigateByUrl('/',{skipLocationChange: true})
					.then(()=>
					{
						this.router.navigate(['/pos'],{replaceUrl:true});
					});

				},(error)=>
				{
					this.rest.showError(error, false);
					this.router.navigateByUrl('/',{skipLocationChange: true})
					.then(()=>
					{
						this.router.navigate(['/pos'],{replaceUrl:true});
					});
				});
			}
			else
			{
				this.router.navigateByUrl('/',{skipLocationChange: true})
				.then(()=>
				{
					this.router.navigate(['/pos'],{replaceUrl:true});
				});
			}
		},(error)=>
		{
			this.showError(error);
		});
	}

	setStoreBillingData()
	{
		if( this.order_info.order.store_id )
		{
			this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;

			if( !this.order_info.order.sat_codigo_postal )
				this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
		}
	}

	addOrderToCredit()
	{
		if( this.rest.is_offline )
		{
			this.order_info.order.status = 'CLOSED';
			this.order_info.order.delivery_status = 'DELIVERED';

			this.rest.updateOfflineOrderInfo(this.order_info)
			.then(()=>
			{
				let print_receipt = this.rest.local_preferences.default_print_receipt;

				if( this.order_info.store.print_receipt )
					print_receipt = this.order_info.store.print_receipt;

				if( this.copies == 0 )
				{
					this.router.navigateByUrl('/',{skipLocationChange: true})
					.then(()=>
					{
						this.rest.sendNotification('order', this.order_info.order.id);
						this.router.navigate(['/pos'],{replaceUrl:true});
					});
					this.showSuccess('La Orden se agrego a credito');
					return;
				}

				let url = '/index.html#/print-receipt-offline/'+print_receipt+'/'+this.order_info.order.sync_id+'/print/'+this.copies;
				console.log('%c Abriendo url aqui ', 'background: #222; color: #bada55');
				window.open( url );
				this.router.navigateByUrl('/',{skipLocationChange: true})
				.then(()=>
				{
					this.rest.sendNotification('order', this.order_info.order.id);
					this.router.navigate(['/pos'],{replaceUrl:true});
				});

				this.showSuccess('La Orden se agrego a credito');
			},(error)=>this.showError(error));
			return;
		}

		//No more offline stuff from here
		this.rest.updateCommandas( this.order_info.order.store_id );


		if( this.order_info.order.total-this.order_info.order.discount <= 0.01 )
		{
			this.order_info.items.forEach((oii:OrderItemInfo)=>
			{
				//oii.order_item.is_free_of_charge = 'YES';
			});
		}

		this.subs.sink = this.rest.order_info.update(this.order_info).pipe
		(
			mergeMap((response)=>
			{
				return this.rest.update('closeOrder',{order_id:this.order_info.order.id})
			})
		)
		.subscribe(()=>
		{
			if( this.copies == 0 )
			{
				this.router.navigateByUrl('/',{skipLocationChange: true})
				.then(()=>
				{
					this.router.navigate(['/pos'],{replaceUrl:true});
					this.rest.sendNotification('order', this.order_info.order.id);
				});
				this.showSuccess('La Orden se agrego a credito');
				return;
			}

			let print_receipt = this.rest.local_preferences.default_print_receipt;

			if( this.order_info.store.print_receipt )
				print_receipt = this.order_info.store.print_receipt;


			let url = '/index.html#/print-receipt/'+print_receipt+'/'+this.order_info.order.id+'/print/'+this.copies;
			window.open( url );
			this.router.navigateByUrl('/',{skipLocationChange: true})
			.then(()=>
			{
				this.router.navigate(['/pos'],{replaceUrl:true});
				this.rest.sendNotification('order', this.order_info.order.id);
			});
		},(error)=>this.showError(error));
	}

	generateInstallments()
	{
		if(this.order_info.order.installment_months <= 0 || !this.order_info.order.first_payment_date || !this.order_info.order.frequency)
		{
			this.showError('Las mensualidades deben de ser mayor a 0, fecha de primer pago y frecuencia')
			return
		}

		this.rest.updateCommandas( this.order_info.order.store_id );

		this.subs.sink = this.rest.order_info.update(this.order_info).pipe
		(
			mergeMap((response)=>
			{
				return this.rest.update('closeOrder',{order_id:this.order_info.order.id})
			})
		)
		.subscribe(()=>
		{
			this.router.navigateByUrl('/',{skipLocationChange: true})
			.then(()=>
			{
				this.router.navigate(['/view-order/' + this.order_info.order.id],{replaceUrl:true});
				this.rest.sendNotification('order', this.order_info.order.id);
			});
			this.showSuccess('La orden se genero a plazos');
			return;
		},(error)=>this.showError(error));
		this.show_installments = false;
	}

	showInstallments()
	{
		this.order_info.order.frequency = 'MONTHLY';

		let price_type = this.price_type_list.find(pt => pt.id == this.order_info.order.price_type_id);

		if(price_type && price_type.installments)
		{
			this.order_info.order.installment_months = price_type.installments;
		}

		this.show_installments = true;
	}

	closeInstallments()
	{
		this.order_info.order.initial_payment = 0;
		this.order_info.order.installment_months = 0;
		this.order_info.order.installment_round_amount = 0;
		this.order_info.order.frequency = 'NONE';
		this.order_info.order.first_payment_date = null;

		this.show_installments = false;
	}

	addEfectivo(amount:number)
	{
		this.cash += amount;
		this.cash_string = ''+this.cash;
		this.updatePaymentData();
	}

	efectivoExacto()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.cash = Math.ceil(((this.order_info.order.total - this.total_discount) * 100)/rate )/100;
		this.cash_string = ''+this.cash;
		this.updatePaymentData();
	}

	dolarsExacto()
	{
		this.cleanAmounts();
		let currency_rate = this.currency_rate_list.find((i)=>i.currency_id == 'USD' && this.order_info.order.store_id == i.store_id) as Currency_Rate;
		let rate = this.getExchangeRate('USD');
		this.dolares = Math.ceil( ((this.order_info.order.total-this.total_discount)/rate*100 ) )/100;
		this.updatePaymentData();
	}

	cleanAmounts()
	{
		this.cash = 0;
		this.cash_string = '';
		this.transfer = 0;
		this.check = 0;
		this.card_amount = 0;
		this.dolares	= 0;

		//this.total_discount = 0;
		//this.percentage_discount = 0;

		this.updatePaymentData();
		this.returns_info_list = [];
	}

	transferenciaExcta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.transfer = Math.ceil(((this.order_info.order.total - this.total_discount) * 100)/rate )/100;
		this.updatePaymentData();
	}

	tarjetaExacta()
	{
		this.cleanAmounts();
		let rate = this.getExchangeRate('MXN');
		this.card_amount= Math.ceil(((this.order_info.order.total - this.total_discount) * 100)/rate )/100;
		this.updatePaymentData();
	}
	toggle_targeta_credito(evt:any)
	{
		this.is_credit_card = evt.target.checked;
	}
	toggleFactura(evt:any)
	{
		this.factura_enabled = evt.target.checked;
	}
	ngOnChanges(changes: SimpleChanges): void
	{
		//console.log('On changes update payment data');
		if( changes.order_info )
		{
			this.restartVars();
			this.updatePaymentData();
			this.setSatClientRfc();

			this.focusCash();
		}

		if( changes.partial_amount )
		{
			this.is_partial_payment = true;
		}

		this.payment_info.payment.tag = this.tag;
	}

	addReturnDiscount(code:string)
	{
		if( !code )
			return;

		let find= this.returns_info_list.find(rs=>rs.returns.code == code.trim())

		if( find !== undefined )
		{
			this.showError('El código ya fue ingresado');
			return;
		}

		this.subs.sink = this.rest.returns_info
		.search({eq:{code:code}})
		.subscribe((response)=>
		{
			if( response.total == 0 )
			{
				this.showError('No se encontro ningún cupon');
				return;
			}

			let return_info = response.data[0];
			if( return_info.returns.total - return_info.returns.amount_paid < 0.01 )
			{
				this.showError('El cupon no puede ser aplicado a esta orden');
				return;
			}

			this.returns_info_list.push( return_info );
			this.discount_code = '';
			this.updatePaymentData();
		},
		(error)=>
		{
			this.showError(error);
		})
	}

	setSatClientRfc()
	{
		if( this.order_info?.order?.sat_receptor_rfc )
			return;

		if( this.order_info?.client?.default_billing_address_id )
		{
			this.subs.sink = this.rest.address
			.get(this.order_info.client.default_billing_address_id)
			.subscribe((address)=>
			{
				this.order_info.order.sat_razon_social = address.name;
				this.order_info.order.sat_receptor_rfc = address.rfc;
				this.order_info.order.sat_receptor_email = address.email;
				this.order_info.order.sat_uso_cfdi = 'G01';
				this.order_info.order.sat_serie = this.order_info.store.default_sat_serie;
				this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
				this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;
				this.updateDatosFacturacionOrder(this.order_info.order);
			});
		}
		else
		{
			let client_user_id = this.order_info.order.client_user_id;

			if( client_user_id )
			{
				let search_info = {eq:{user_id:client_user_id},nn:['rfc'],limit:1};
				this.subs.sink = this.rest.address
				.search( search_info )
				.subscribe((response)=>
				{
					if( response.data.length )
					{
						let address = response.data[0];
						this.order_info.order.sat_receptor_rfc = address.rfc;
						this.order_info.order.sat_razon_social = address.name;
						this.order_info.order.sat_receptor_email = address.email;
						this.order_info.order.sat_uso_cfdi = 'G01';
						this.order_info.order.sat_serie = this.order_info.store.default_sat_serie;
						this.order_info.order.sat_codigo_postal = this.order_info.store.zipcode;
						this.order_info.order.billing_data_id = this.order_info.store.default_billing_data_id;
						this.updateDatosFacturacionOrder(this.order_info.order);
					}
				});
			}
		}
	}

	gotoPrintLocation()
	{

	}

	updateDatosFacturacionOrder(order:Order)
	{
		this.subs.sink = this.rest.updateDatosFacturacion(this.order_info.order)
		.subscribe(()=>{

		},(error)=>{

		});
	}

	printPreReceipt(evt:Event)
	{
		this.is_loading = true;
		this.updatePaymentData();

		this.setStoreBillingData();
		// Registrar Pago

		let print_receipt = this.rest.local_preferences.default_print_receipt;

		if( this.order_info.store.print_receipt )
			print_receipt = this.order_info.store.print_receipt;

		this.subs.sink = ( this.rest.is_offline ? of( this.order_info ) : this.rest.order_info.update(this.order_info) )
		.subscribe(()=>{
			let url = '/index.html#/print-receipt/'+print_receipt+'/'+this.order_info.order.id+'/print/'+this.copies;
			console.log('Redireccionando');

			//XXX NO BORRAR el log Es indispensable para que siga existiendo el contexto del evento y no bloque la
			//el abrir la ventana
			console.log( evt );
			//XXX NO BORRAR

			this.is_loading = false;
			window.open( url );
		})
	}

	cashChange(amount:number)
	{
		this.cash = amount;
		this.cash_string = ''+this.cash;
	}
	toggleShowOther(evt:Event)
	{
		let t = evt.target as HTMLInputElement;
		this.show_other_payment_methods = t.checked;
	}

	billing_data = GetEmpty.billing_data();

	currency_formater = new Intl.NumberFormat('us-EN', { style: 'currency', currency: 'USD', currencyDisplay:"narrowSymbol" });
	number_formater = new Intl.NumberFormat('us-EN', { maximumSignificantDigits: 2,} ); // useGrouping:"always"

	formatCurrency(amount:number):string
	{
		return this.currency_formater.format(amount);
	}
	formatNumber(amount:number):string
	{
		return this.number_formater.format(amount);
	}

	service_type_dictionary:Record<string,string> = {
		'TOGO':'PARA LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'A DOMICILIO',
		'QUICK_SALE':'',
	}

	printReceipt2(order_info:OrderInfo, payment_info:PaymentInfo,billing_data:Billing_Data | null, store:Store)
	{
		let currency_rate:Currency_Rate | null = null;
		if( payment_info?.movements )
		{
			dance:
			for(let bmi of payment_info.movements)
			{
				for(let bmo of bmi.bank_movement_orders )
				{
					if( bmo.currency_id != order_info.store.default_currency_id )
					{
						currency_rate = {
							store_id: order_info.store.id,
							currency_id: bmo.currency_id,
							rate: bmo.exchange_rate
						};
						break dance;
					}
				}
			}
		}

		let html:string = '';
		let address = store.address;


		address += (address && store.city ? (','+store.city.toUpperCase()):'');
		address += (address && store.state ? (','+store.city.toUpperCase()):'');
		address += (address && store.state ? (','+store.state.toUpperCase()):'');
		address += (address && store.zipcode ? (','+store.zipcode):'');

		let zipcode = store.zipcode ? ('CP:'+store.zipcode || ''):'';

		let rfc = store.rfc;


		if( this.billing_data )
		{
			address = this.billing_data.address || '';
			address += (address && this.billing_data.city ? (','+this.billing_data.city.toUpperCase()):'');
			address += (address && this.billing_data.state ? (','+this.billing_data.city.toUpperCase()):'');
			address += (address && this.billing_data.state ? (','+this.billing_data.state.toUpperCase()):'');
			address += (address && this.billing_data.zipcode ? (','+this.billing_data.zipcode):'');
			zipcode = this.billing_data.zipcode ? ('CP:'+txt2html(this.billing_data.zipcode) || ''):'';

			rfc = this.billing_data.rfc;
		}

		zipcode += store.phone ? ' TEL:'+txt2html(store.phone) : '';

		let store_bussiness_name = store.business_name || '';
		let phone = store.phone? ('&nbsp;TEL: '+txt2html(store.phone )|| ''):'';

		let image_path = this.rest.getImagePath(order_info.store?.ticket_image_id, this.rest.local_preferences.default_ticket_image_id);

		let tipo = this.service_type_dictionary[order_info.order.service_type];

		let order_title_html = tipo?`<h2 class="text-center text-black">${tipo}</h2>` : '';

		if( order_info.order.status == 'CANCELLED' )
		{
			order_title_html = `<h1 class="text-center">ORDEN CANCELLADA</h1>`
		}


		let order_id_string = order_info.order.id
				? ('<b>ID:</b>'+order_info.order.id)
				: ('<b>IDOF</b>'+order_info.order.sync_id);

		let folio = order_info.order.store_consecutive ? ('<b>FOLIO</b>:'+order_info.order.store_consecutive ) : '';


		let items_html = '';

		for(let order_item_info of order_info.items)
		{
			let item_name = '';

			if( this.rest.local_preferences.display_categories_on_items == 'YES'&&order_item_info.category )
				item_name = order_item_info.category.name+' - ';

			item_name+= order_item_info.item.name;
			let border_top = !(order_item_info.order_item.item_option_id) ? 'border-top' : '';

			let discount = order_item_info.order_item.discount_percent ? '('+order_item_info.order_item.discount_percent+'%)': '';

			items_html += `
				<tr class="${border_top}">
					<td class="truncate td_first">
						${txt2html(item_name)}
					</td>
					<td class="text-end monospace td_other">
						${order_item_info.order_item.type == 'REFUND'?'-':''}
						${this.formatNumber( order_item_info.order_item.qty )}×${this.formatCurrency((order_item_info.order_item.total || 0 )/(order_item_info.order_item.qty || 1))}
						${discount}
					</td>
					<td class="text-end monospace td_other">
						${order_item_info.order_item.type == 'REFUND'?'-':''}
						${this.formatCurrency(order_item_info.order_item.total)}
					</td>
				</tr>
				`
			if( order_item_info.order_item.type == 'REFUND' || order_item_info.order_item.note || order_item_info.order_item.exceptions )
			{
				items_html += `
					<tr>
						<td colspan=3 class="text-start monospace">
							${order_item_info.order_item.type == 'REFUND'?'<div>DEVOLUCIÓN</div>':''}
							${order_item_info.order_item.note?`<div>${txt2html(order_item_info.order_item.note)}</div>`:''}
							${order_item_info.order_item.exceptions?`<div>${txt2html(order_item_info.order_item.exceptions)}</div>`:''}
						</td>
					</tr>`;
			}
		}

		if( order_info.order.discount )
		{
			items_html += `
				<tr class="border-top">
					<td class="text-end monospace td_first">Descuento</td>
					<td class="td_other"></td>
					<td class="text-end monospace td_other">${this.formatCurrency(order_info.order.discount)}</td>
				</tr>`;
		}

		items_html += `
				<tr class="border-top">
					<td class="text-end fw-bold td_first">Total</td>
					<td class="text-center td_other"></td>
					<td class="text-end fw-bold monospace td_other">
						${this.formatCurrency(order_info.order.total-order_info.order.discount)}
					</td>
				</tr>`;

		if(payment_info?.movements)
		{
			items_html+= `
				<tr class="border-top">
					<td class="text-end">Total Recibido</td>
					<td class="text-center"></td>
					<td class="text-end monospace">${this.formatCurrency(payment_info.payment.received_amount)}</td>
				</tr>
				<tr class="mx-0">
					<td class="text-end fw-bold">Cambio</td>
					<td class="text-end fw-bold monospace" colspan=2>${this.formatCurrency(payment_info.payment.change_amount)}MXN</td>
				</tr>`

		}

		if( currency_rate )
		{
			items_html =`
			<tr class="mx-0">
				<td class="text-end fw-bold">Cambio en ${currency_rate.currency_id}}</td>
				<td class="text-end fw-bold monospace" colspan=2>${this.formatCurrency( payment_info.payment.change_amount/currency_rate.rate)} USD</td>
			</tr>
			`;
		}

		if( order_info.order.status == 'CLOSED' && order_info.order.paid_status != 'PAID' && (order_info.order.total-order_info.order.amount_paid >0.01) )
		{
			items_html += `
			<div>
				A través de este pagaré yo
				<b>${txt2html( order_info?.client?.name || '')}</b>, me comprometo a pagar incondicionalmente la cantidad de
				<b>${this.formatCurrency( order_info.order.total-(order_info.order.amount_paid+order_info.order.discount))}</b>
				de la venta con el folio No. ${order_info.order.id}} a la orden
				de
				<b>${txt2html(order_info?.store?.rfc)}</b>. Dicha cantidad será pagada el día ______ de _________ del ______, de la
				forma
				en la que lo solicite el beneficiario de este documento, y en caso de que no se realice tal precisión, en el
				domicilio
				del suscriptor.
				<br>
				<b>${txt2html(order_info.store.city+' '+order_info.store.state)}</b>, a
				<b>${Utils.getDateString( order_info.order.created, true )}</b>
				<br>
				<br>
			</div>
			`
		}

		html += `
			<style>
				.px-0{
					padding-left: 0;
					padding-right: 0;
					text-center:
				}
				.text-center{
					text-align: center;
				}
				.text-end{
					text-align: right;
				}
				.w-100
				{
					width:100%;
				}
				.border-bottom
				{
					border-bottom: 1px solid #AAAAAA;
				}

				.fw-bold
				{
					font-weight: bold;
				}
				.border-top
				{
					border-top: 1px solid #AAAAAA;
				}
				.break_pages
				{
					filter: grayscale(100%);
					break-after: page;
				}

				.td_first
				{
					width:33%;
					overflow: hidden;
				}

				.td_other
				{
					width:33%;
				}

				@media screen{
					.image_logo_ticket
					{
						width: 25%;
						padding-bottom: 15px;
					}
				}

				@media print {
					.image_logo_ticket
					{
						width: 80%;
						padding-bottom: 15px;
					}
				}

				a {
					color: #000;
					text-decoration: none;
				}

				#markdown a:visited,
				#markdown a:link,
				#markdown a:hover,
				#markdown a:focus
				{
					color: #000 !important;
					text-decoration: none !important;
				}

				.truncate
				{
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

			</style>
			<div class="print_ticket_container">
		`;

		let footer = '';
		if(order_info.store.autofacturacion_settings != 'DISABLED' && order_info.order.paid_status == 'PAID' && order_info.store.default_billing_data_id )
		{
			footer += `
			<div class="text-center">
				Código:
				<code style="font-size:20px;color:#000000;text-transform:uppercase">${order_info.order.facturacion_code}</code>
			</div>
			`
		}
		if( order_info.store.ticket_footer_text )
		{
			footer+=`<div id="markdown" class="text-center">${marked( order_info.store.ticket_footer_text )}</div>`;
		}

		for(let i=0;i<this.copies;i++)
		{
			html += `
				<div class="break_pages px-0">
					<div class="text-center">
						<img src="${image_path}" class="image_logo_ticket">
					</div>
					<div style="text-transform:uppercase">
						<div class="fw-bold text-center">
							Sucursal ${txt2html(store.name)}
						</div>
						<div class="text-center pb-2">
							<div>${txt2html(store_bussiness_name ||'')}</div>
							<div>${rfc}</div>
							<span>${txt2html(address)}</span>
							<div>
								<span>${zipcode}</span>
							</div>
						</div>
					</div>
					${order_title_html}
					<table>
						<tr>
							<td>
								<div>${order_id_string}</div>
								<div>${folio}</div>
							</td>
							<td class="text-end">
								Fecha:<br>
								<b>${Utils.getDateString(order_info.order.created, true)}</b>
							</td>
						</tr>
					</table>

					<table class="mx-0" style="table-layout: fixed; width: 100%;">
						<tr class="border-bottom">
							<td class="fw-bold border-bottom td_first truncate">Artículo</td>
							<td class="fw-bold text-center border-bottom td_other truncate">Cantidad</td>
							<td class="fw-bold text-end border-bottom td_other truncate">Total</td>
						</tr>
						${items_html}
					</table>
					${footer}
				</div>
			`
		}

		html += `</div>`
		//console.log("big html is", html);

		console.log('%c Se mando a imprimir ventana flotante', 'background: #FF0000; color: #bada55');
		printHtml( html, 'Orden-'+( order_info.order.id || order_info.order.sync_id ) );

	}

	printFloatWindowReceipt(order_info:OrderInfo):Observable<OrderInfo>
	{
		if( this.rest.local_preferences.default_print_receipt != 3 || this.copies == 0)
		{
			return of( order_info );
		}

		let search:SearchObject<Payment> = this.getEmptySearch();
		search.search_extra = {order_id: order_info.order.id};
		search.limit = 1;
		search.sort_order=['id_ASC'];


		//console.log('Ultima vez que se ve por aqui');
		return forkJoin
		({
			order: of( order_info ),
			store: this.rest.getStore( order_info.order.store_id ),
			payment_info: this.rest.is_offline
				? this.rest.getPaymentInfoByOrderSyncId( order_info.order.sync_id )
				: this.rest.payment_info.search( search )
		})
		.pipe
		(
			mergeMap((response)=>
			{
				//console.log('Todos regresaron el problema es billing_data posiblemente');
				return forkJoin({
					order: of(response.order),
					store: of( response.store ),
					payment_info: of( response.payment_info ),
					billing_data: this.rest.getBillingData()
				});
			}),
			mergeMap((responses)=>
			{
				this.rest.printer_ticket_config = responses.store.printer_ticket_config;
				this.rest.createStructuredItems( responses.order );
				let store = responses.store;

				let billing_data = responses.billing_data.data.find(bd=>bd.id == responses.store.id) || null;

				let payment_info:PaymentInfo = {} as PaymentInfo;

				if( responses.payment_info.total )
				{
					payment_info= responses.payment_info.data[0];
				}

				this.printReceipt2(responses.order, payment_info, billing_data, store);
				return of( order_info );
			}
		));
	}
	onElementFocus(evt:Event)
	{
		let input = evt.currentTarget as HTMLInputElement;
		//input.setSelectionRange(0,input.value.length);
		if( this.card_ending == '0000' )
			this.card_ending = '';
	}

	isMobile()
	{
		const userAgent = navigator.userAgent.toLowerCase();
		const isMobile = /mobile|android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
		const isTablet = /tablet|ipad/i.test(userAgent);
		return isMobile || isTablet;
	}

	getExchangeRate(currency_id:string):number
	{

		if( this.order_info.order.currency_id == currency_id )
			return 1;

		if( !this.order_info?.store )
			return 1;

		let default_currency_id = this.order_info.store.default_currency_id;

		let currency_rate = this.currency_rate_list.find((r:Currency_Rate)=>
		{
			console.log('COnsole',r);
			if( r.store_id != this.order_info.order.store_id )
				return false;

			if( this.order_info.order.currency_id == default_currency_id )
			{
				return r.currency_id == currency_id;
			}

			return r.currency_id == this.order_info.order.currency_id;
		});

		//console.log('FOOOOOOOOOOOOOOOOO is undefined', default_currency_id);

		if( !currency_rate )
		{
			//console.log('FOOOOOOOOOOOOOOOOO is undefined');
		}

		return default_currency_id == this.order_info.order.currency_id
			? currency_rate.rate
			: 1/currency_rate.rate;
	}
}
