import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { GraphUtils } from 'src/app/classes/GraphUtils';
import { BaseComponent } from '../base/base.component';

interface FOO
{
	user_id:number;
	user_name:string;
	avg_ticket:number;
	day:string;
	total:number;
	order_count:number;
}

@Component({
	selector: 'app-report-waiter-orders',
	templateUrl: './report-waiter-orders.component.html',
	styleUrls: ['./report-waiter-orders.component.css']
})

export class ReportWaiterOrdersComponent extends BaseComponent implements OnInit
{

	waiter_order_graph = GraphUtils.getEmptyLineChart();
	days:string[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let d = new Date();
				let minutes_offset = d.getTimezoneOffset();
				return this.rest.getReporte('orderWaiterAvg',{minutes_offset})
			})
		)
		.subscribe((response:FOO[])=>
		{
			let map = new Map<number,FOO[]>()
			let user_ids:Record<string,string|number>[] = [];
			let user_count:Record<string,number> = {};
			let user_max:Record<string,number> = {};

			let dayIndex = new Map<string,number>();

			for(let d of response )
			{
				dayIndex.set(d.day, 0 );
			}

			let days:string [] = Array.from(dayIndex.keys());

			days.sort((a,b)=>a>b?1:-1);

			days.forEach((str,index)=>
			{
				dayIndex.set(str, index );
			});

			this.days = days;

			response.sort((a,b)=>{
				return a.day > b.day ? 1 : -1;
			});

			console.log( response );

			console.log('Days', days );

			for(let d of response)
			{
				let m = map.get( d.user_id );
				if( m == null )
				{
					m = days.map((day)=>{
						return {
							day,
							avg_ticket: 0,
							order_count: 0,
                            total: 0,
							user_name: d.user_name,
							user_id: d.user_id
						};
					})
					map.set(d.user_id, m );
					user_ids.push({ user_id:d.user_id,user_name:d.user_name });
					user_count[ d.user_name ] = 0;
					user_max[ d.user_name ] = 0;
				}
				let index = dayIndex.get(d.day);
				m[ index ] = d;
				//m.push(d);
				user_count[ d.user_name]++;
				user_max[ d.user_name]=Math.max( user_count[d.user_name], d.avg_ticket );
			}

			console.log( map );

			let data = [];
			for(let r of user_ids )
			{
				let d = map.get(r.user_id as number);
				//console.log('Que tiene D', d, r );

				if( !r.user_name )
				{
					console.log('Record malo', d, r );
					continue;
				}
				data.push({
					name: r.user_name,
					series: d.map((x)=>{return { value: x.total, name: x.day }})
				})
			}

			//data.sort((a,b)=>{
			//	console.log('COmpare',a.name+' '+user_count[a.name],b.name+' '+user_count[b.name]);
			//	return user_count[b.name] < user_count[a.name] ? 1: -1;
			//	//return user_max[b.name] < user_max[a.name] ? 1: -1;
			//	//return (user_count[a.name]/user_max[a.name]) < (user_count[b.name]/user_max[b.name]) ? 1: -1;
			//});
			console.log('Data graph', data);

			this.waiter_order_graph.results = data;

			console.log( response );
		},(error)=>this.showError(error))
	}
  export()
  {
    let rows = [];
      rows.push('Nombre');
      for(let d of this.days )
      {
        rows.push( d );
      }

      for(let i of this.waiter_order_graph.results )
      {
        rows.push( i.name )
        for(let z of i.series )
        {
            rows.push
        }
      }

      /*
	<div class="card p-3 my-3">
		<table class="w-100">
			<thead>
				<tr>
					<th>Mesero</th>
					<th class="text-end" *ngFor="let d of days ">{{d.substring(8)}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of waiter_order_graph.results">
					<td class="border">{{r.name}}</td>
					<td class="border text-end" *ngFor="let s of r.series">
						<span *ngIf="s.value>=1">{{s.value | number:'1.0-0'}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
  */

  }
}
