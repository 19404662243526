<div class="container-fluid">
	<h1>Mesas</h1>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="d-flex flex-row flex-wrap">
		<ng-container *ngFor="let t of table_info_list">
			<div *ngIf="t.order_list.length == 1" class="t_element" (click)="showOrders(t)">
				<div class="text-center">{{t.table.name}}</div>
				<div class="text-center fw-bold">{{t.attended_by_user?.name}}</div>
				<div class="ratio ratio-1x1 w-50 mx-auto">
					<img *ngIf="rest.current_user.image_id" [src]="rest.getImagePath(rest.current_user.image_id)">
					<svg *ngIf="!rest.current_user?.image_id" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"/></svg>
				</div>
				<div class="text-center w-100">
					<a class="btn btn-primary" [routerLink]="['/pos',t.order_list[0].id]">
						{{t.order_list[0].client_name | titlecase}}
					</a>
				</div>
			</div>
			<div *ngIf="t.order_list.length > 1" (click)="showOrders(t)" class="t_element">
				<div class="text-center">{{t.table.name}}</div>
				<div class="text-center fw-bold">{{t.attended_by_user?.name}}</div>
				<div class="ratio ratio-1x1 w-50 mx-auto">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"/></svg>
				</div>
				<div class="text-center w-100"></div>
			</div>
			<div *ngIf="t.order_list.length == 0" [routerLink]="['/pos/table',t.table.id]" class="t_element">
				<div class="text-center w-100">{{t.table.name}}</div>
				<div class="ratio ratio-1x1 w-50 mx-auto">&nbsp;</div>
				<div class="text-center">&nbsp;</div>
			</div>
		</ng-container>
	</div>
</div>
<app-modal [(show)]="show_orders">
	<div *ngIf="selected_table_info">
		<ul class="list-group">
			<li class="list-group-item pointer" *ngFor="let order of selected_table_info.order_list">
				<a class="d-block w-100" [routerLink]="['/pos',order.id]">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M10 19.11L12.11 17H7v-2h7v.12L16.12 13H7v-2h10v1.12l1.24-1.23c.48-.48 1.11-.75 1.8-.75c.33 0 .66.07.96.19V5a2 2 0 0 0-2-2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h5v-1.89M7 7h10v2H7V7m14.7 7.35l-1 1l-2.05-2.05l1-1a.55.55 0 0 1 .77 0l1.28 1.28c.21.21.21.56 0 .77M12 19.94l6.06-6.06l2.05 2.05L14.06 22H12v-2.06Z"/></svg>
					{{order.client_name}}
				</a>
			</li>
			<li class="list-group-item pointer" >
				<a class="d-block w-100" [routerLink]="['/pos/table',selected_table_info.table.id]">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M17 14h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3m-5 3v-2H7v2h5m5-6H7v2h7.69A5.983 5.983 0 0 0 12 18c0 1.09.29 2.12.8 3H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h14a2 2 0 0 1 2 2v7.8c-.88-.51-1.91-.8-3-.8l-1 .08V11m0-2V7H7v2h10Z"/></svg>
					Nueva Orden
				</a>
			</li>
			<li class="list-group-item pointer">
				<a type="button" class="d-block w-100" (click)="show_orders=false">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496z"/></svg>
					Cerrar Ventana
				</a>
			</li>
		</ul>
	</div>
</app-modal>
