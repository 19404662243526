import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { CashCloseInfo } from 'src/app/models/models';
import { Bank_Movement, Billing_Data, Currency_Rate, Fund, Payment, Store, User} from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

interface Equivalencia{
	total:number;
	currency_id:string;
	equivalencia:number;
}

@Component({
	selector: 'app-print-cash-closing',
	templateUrl: './print-cash-closing.component.html',
	styleUrls: ['./print-cash-closing.component.css']
})

export class PrintCashClosingComponent extends BaseComponent implements OnInit
{

	cash_close_info:CashCloseInfo = null;
	current_date:Date = new Date();

	total_sales:number			= 0;
	total_credit:number			= 0;
	total_discount:number		= 0;

	item_total:number			= 0;
	currencies_total:number		= 0;
	total_income_sales:number	= 0;
	total_income_other:number	= 0;
	total_order_amount_paid:number = 0;
	order_counter:number		= 0;
	total_caja:number			= 0;
	total_funds:number			= 0;
	total_expenses				= 0;
	expenses_paymens:Payment[] = [];
	print_and_close:boolean = true;
	print_listener = ()=>{ window.close() };
	store:Store = GetEmpty.store();
	user:User = GetEmpty.user();

	d:number = Math.random();

	currency_rate:Currency_Rate = {
		id: 0,
		rate: 19,
		store_id: 0,
		currency_id: 'USD'
	}
	movements_qtys:Record<string,Equivalencia> = {
		'Tarjeta Crédito':{ total: 0, currency_id:'MXN', equivalencia: 0 },
		'Tarjeta Débito':{ total: 0, currency_id:'MXN', equivalencia: 0 },
		Transferencia:{ total: 0, currency_id:'MXN', equivalencia: 0 }
	};

	legend = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'Tarjeta Crédito',
		'DEBIT_CARD-MXN':'Tarjeta Débito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
	}

	payment_movements:Bank_Movement[] = [];
	billing_data:Billing_Data | null = null;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap)=>
			{
				this.print_and_close = paramMap.has('cerrar');
				return this.rest.cash_close_info.get( paramMap.get('id') )
			})
			,mergeMap((response)=>
			{
				if( response.store?.default_billing_data_id )
				{
					return forkJoin
					({
						default_billing_data: this.rest.billing_data.get( response.store.default_billing_data_id),
						cash_close_info: of( response )
					})
				}
				else
				{
					return forkJoin
					({
						default_billing_data: of( null ),
						cash_close_info: of( response )
					})
				}
			})
			,mergeMap((response)=>
			{
				this.billing_data = response.default_billing_data;
				return of( response.cash_close_info );
			})
		)
		.subscribe((response)=>
		{
			this.current_date = new Date();
			this.cash_close_info = response;

			this.user = response.user;

			this.total_sales	= 0;
			this.total_credit	= 0;
			this.total_discount = 0;

			this.order_counter	= 0;
			this.item_total		= 0;
			this.total_expenses	= 0;

			this.total_income_sales = 0;
			this.total_income_other = 0;
			this.total_order_amount_paid = 0;

			this.store = response.store;

			let tmp_payments:Payment[] = [];

			let currency_rate = this.cash_close_info.currency_rates.find(i=>i.currency_id == 'USD' && i.store_id == this.user.store_id )

			if( currency_rate )
				this.currency_rate = currency_rate;

			this.cash_close_info.payments.forEach((payment)=>
			{
				if( payment.type ==  'income' )
				{
					if( payment.tag == 'SALE' )
					{
						this.total_income_sales += payment.payment_amount;
					}
					else
					{
						this.total_income_other += payment.payment_amount;
					}
				}
				else
				{
					tmp_payments.push(payment);
				}
			})

			this.expenses_paymens = tmp_payments;

			this.cash_close_info.orders.forEach((order)=>
			{
				this.total_sales				+= order.total;
				this.total_discount				-= order.discount;
				this.total_order_amount_paid	+= order.amount_paid;

				if( order.amount_paid > (order.total-order.discount))
					return;

				this.total_credit				+= ((order.total-order.discount) - order.amount_paid );
			});

			this.cash_close_info.item_sales.forEach((x)=>
			{
				if( x.type != 'REFUND' )
					this.item_total += x.total;
			});

			this.total_funds = this.cash_close_info.funds.reduce((prev:number,fund:Fund)=>
			{
				return prev+fund.amount;
			},0);

			this.cash_close_info.movements.forEach((bm:Bank_Movement)=>
			{
				if( bm.type == 'income' )
				{
					let key = this.legend[ bm.transaction_type+'-'+bm.currency_id ];

					if( !(key in this.movements_qtys) )
					{
						this.movements_qtys[key] = { total: 0, currency_id:bm.currency_id, equivalencia: 0 };
					}

					if( this.movements_qtys[key].currency_id == 'USD' )
					{
						let total = bm.total*this.currency_rate.rate;

						this.movements_qtys[key].equivalencia += total;
						this.movements_qtys[key].total+= bm.total;
						this.currencies_total	+= total;
					}
					else
					{
						this.movements_qtys[key].equivalencia += bm.total;
						this.movements_qtys[key].total+= bm.total;
						this.currencies_total	+= bm.total;
					}
				}
				else
				{
					this.total_expenses += bm.total;
				}
			});

			this.cash_close_info = response;

			if( this.print_and_close )
			{
				setTimeout(()=>{
					console.log('Printing',this.d);
					window.print();
					this.rest.logout(false);
					window.close();
				},700);
			}
		},this.showError);
	}

	closeSesion()
	{
		this.rest.logout();
		window.removeEventListener('afterprint',this.closeSesion);
	}

	printDirect()
	{
		window.print();
	}
}
