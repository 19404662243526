<div class="container-fluid">
	<h1 class="text-capitalize mt-3">{{user.id ? 'Actualizar':'Agregar'}} Proveedor</h1>
	<form ngNativeValidate (submit)="save()" name="save-user-form">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-12 col-sm-2 col-lg-1">
					<label class="">Imagen</label>
					<app-image-uploader [(image)]="user.image_id"></app-image-uploader>
				</div>
				<div class="col-12 col-sm-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="user.name" class="form-control">
				</div>
				<!--div class="col-6 col-md-4">
					<label class="">Usuario</label>
					<input type="text" name="email" [(ngModel)]="user.email" class="form-control" required>
				</div-->
				<div class="col-12 col-sm-4">
					<label class="">Teléfono</label>
					<input type="number" name="phone" [(ngModel)]="user.phone" class="form-control">
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
