import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';


import {Offer} from '../../models/RestModels';
import {Item} from '../../models/RestModels';
import {User} from '../../models/RestModels';
import {Category} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import { mergeMap } from 'rxjs/operators';

interface CustomOffer extends Offer
{
	active_days:string;
	store_name:string;
	item_name:string;
}


@Component({
	selector: 'app-list-offer',
	templateUrl: './list-offer.component.html',
	styleUrls: ['./list-offer.component.css']
})

export class ListOfferComponent extends BaseComponent implements OnInit {
	file:File | null = null;
	show_import:boolean = false;
	offer_search:SearchObject<Offer> = this.getEmptySearch();
	offer_list:CustomOffer[] = [];
	item_list:Item[] = [];
	user_list:User[] = [];
	category_list:Category[] = [];
	store_list:Store[] = [];

	ngOnInit()
	{
		this.path = '/list-offer';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((query_param_map)=>
			{
				let fields = [ "id","category_id","coupon_code","created_by_user_id","created","gift_item_id","hour_end","hour_start","is_valid_friday","is_valid_monday","is_valid_saturday","is_valid_sunday","is_valid_thursday","is_valid_tuesday","is_valid_wednesday","item_id","qty","store_id","type","updated_by_user_id","updated","valid_from","valid_thru" ];
				let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
				this.offer_search = this.getSearch(query_param_map, fields, extra_keys );
				this.setTitle('Ofertas');
				this.is_loading = true;

				return forkJoin
				({
					offer: this.rest.offer.search(this.offer_search),
					store: this.rest.getStores()
				})
			}),
			mergeMap((response)=>
			{
				let item_ids = [];
				response.offer.data.forEach((offer)=>{
					if( offer.item_id && !item_ids.includes( offer.item_id ) )
						item_ids.push( offer.item_id )

					if( offer.gift_item_id && !item_ids.includes( offer.gift_item_id ) )
						item_ids.push( offer.gift_item_id )
				});

				return forkJoin({
					offer: of( response.offer ),
					store: of( response.store ),
					item : this.rest.item.search({
						csv:{ id: item_ids },
						limit: item_ids.length
					}),
					user : this.rest.user.search({limit:9999}),
					category : this.rest.category.search({limit:9999}),
				});
			})
		)
		.subscribe((responses)=>
		{
			this.store_list = responses.store.data;
			this.item_list = responses.item.data;
			this.user_list = responses.user.data;
			this.category_list = responses.category.data;

			this.offer_list = responses.offer.data.map((offer)=>{
				let active_days = (offer.is_valid_sunday ? 'D':'-')+
				(offer.is_valid_monday ? 'L':'-')+
				(offer.is_valid_tuesday ? 'M':'-')+
				(offer.is_valid_wednesday ? 'M':'-')+
				(offer.is_valid_thursday ? 'J':'-')+
				(offer.is_valid_friday ? 'V':'-')+
				(offer.is_valid_saturday ? 'S':'-');

				let store_name = 'Todas';

				if( offer.store_id )
				{
					let store = this.store_list.find((store)=>store.id == offer.store_id) as Store;
					if( store === undefined )
					{
						console.log('Store is undefined');
					}
					store_name = store.name;
				}

				let item_name:string = '';

				if( offer.item_id )
				{
					let item:Item | undefined = this.item_list.find( i=>i.id == offer.item_id );
					item_name = item?.name || '';
				}

				return {...offer, active_days, store_name, item_name };
			});

			this.setPages( this.offer_search.page, responses.offer.total );
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	/*
	onFileChanged(event)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}

	uploadFile()
	{
		this.is_loading = true;
		Utils.xlsx2json( this.file,["id","category_id","coupon_code","created_by_user_id","created","gift_item_id","hour_end","hour_start","is_valid_friday","is_valid_monday","is_valid_saturday","is_valid_sunday","is_valid_thursday","is_valid_tuesday","is_valid_wednesday","item_id","qty","store_id","type","updated_by_user_id","updated","valid_from","valid_thru"]).then((json)=>
		{
			//Filter json then upload
			this.subs.sink	= this.rest.offer.batchUpdate(json).subscribe((result)=>
			{
				if( this.offer_list.length == 0 )
				{
					this.setPages( 0, result.length );
					this.offer_list = result.slice(0,this.pageSize);
				}
				this.is_loading =  false;
				this.show_import = false;
				this.showSuccess('Imported succesfully '+result.length+' items');

			},(error)=>this.showError(error));
		});
	}

	exportFile()
	{
		this.is_loading = true;
		this.subs.sink	= this.rest.offer.search({limit:100000}).subscribe((response)=>
		{
			Utils.array2xlsx(response.data,'offer.xlsx',["id","category_id","coupon_code","created_by_user_id","created","gift_item_id","hour_end","hour_start","is_valid_friday","is_valid_monday","is_valid_saturday","is_valid_sunday","is_valid_thursday","is_valid_tuesday","is_valid_wednesday","item_id","qty","store_id","type","updated_by_user_id","updated","valid_from","valid_thru"])
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
	*/
}
