<div class="container-fluid login_background">
	<h1>Registro</h1>
	<form (ngSubmit)="save()" ngNativeValidate>
		<div class="card p-3 mt-3">
			<div class="row">
				<div class="col-12 form-group">
					<label>Nombre</label>
					<input class="form-control" type="text" name="name" [(ngModel)]="user.name" required>
				</div>

				<div class="col-12 form-group">
					<label>Teléfono</label>
					<input  class="form-control" type="phone" name="phone" [(ngModel)]="user.phone" required>
				</div>

				<div class="col-12 form-group">
					<label>Correo</label>
					<input  class="form-control" type="email" name="phone" [(ngModel)]="user.email" required>
				</div>

				<div class="col-12 form-group">
					<label>Contraseña</label>
					<input type="password"  class="form-control" name="password" [(ngModel)]="user.password" required>
				</div>
				<div class="col-12">
					<button type="submit" class="btn btn-primary mt-3">Registrarse</button>
				</div>
			</div>
		</div>
	</form>
</div>
