
import { Component, OnInit } from '@angular/core';
import { RestResponse, SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { filter, mergeMap } from 'rxjs/operators';
import { OrderInfo, PaymentInfo, UserBalance} from 'src/app/models/models';
import { Currency_Rate, Price_List, Price_Type, Store, User } from 'src/app/models/RestModels';
import { forkJoin, Observable, of } from 'rxjs';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';


const SEARCH_LIMIT = 1000;

@Component({
	selector: 'app-list-client',
	templateUrl: './list-client.component.html',
	styleUrls: ['./list-client.component.css']
})
export class ListClientComponent extends BaseComponent implements OnInit
{

	file:File = null;
	show_import:boolean = false;
	user_search:SearchObject<UserBalance> = this.getEmptySearch();
	user_list:UserBalance[] = [];
	show_new_client:boolean = false;
	show_add_payment_to_client:boolean = false;
	client_order_info_list:OrderInfo[] = [];
	price_type_map:Map<number,Price_List> = new Map();
	price_type_list: Price_Type[] = [];
	currency_rate_list:Currency_Rate[] = [];
	store_list:Store[] = [];

	ngOnInit()
	{
		this.path = '/list-client';
		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((queryParamMap)=>
			{
				this.setTitle('Clientes');
				this.client_order_info_list = [];
				let fields = [
					"id","username","image_id", "empresa_id","name","created_by_user_id",
					"updated_by_user_id","balance","created", "created_by_store_id", "updated",
					"sort_weight","price_type_id"
				];

				let extra_keys:Array<string> = ['con_rfc']; //['search_param1','project_id','some_id'];
				this.user_search = this.getSearch(queryParamMap, fields, extra_keys );

				if( this.user_search.start.name )
				{
					this.user_search.start.email = this.user_search.start.name;
				}

				//if( !queryParamMap.has('price_type_id') )
				//{
				//	this.user_search.eq.price_type_id = null;
				//}

				//if( !this?.user_search?.eq?.price_type_id )
				//	this.user_search.eq.price_type_id = null;

				this.setTitle('Clientes');
				this.is_loading				= true;
				this.user_search.eq.type	= 'CLIENT';
				this.user_search.limit		= this.page_size;
				this.current_page			= this.user_search.page;
				this.is_loading				= true;

				return forkJoin
				({
					price_type: this.rest.price_type.search({limit:99999}),
					user: this.rest.user.search(this.user_search),
					currency_rate: this.rest.getCurrencyRates(),
					stores: this.rest.getStores(),
				})
			})
		)
		.subscribe
		({
			next:(responses)=>
			{
				this.store_list= responses.stores.data;
				this.user_list = responses.user.data;
				this.price_type_list = responses.price_type.data;
				responses.price_type.data.forEach((i)=>this.price_type_map.set(i.id,i));
				this.setPages( this.user_search.page, responses.user.total );
				this.currency_rate_list = responses.currency_rate.data
				this.is_loading = false;
			},
			error:(error)=>
			{
				this.showError(error)
			}
		});
	}

	onNewClient()
	{
		this.show_new_client = false;

		if( this.user_search.page == 0 )
		{
			this.search( this.user_search );
		}
	}

	addPayment(client_user_id:number)
	{
		this.subs.sink = this.rest.order_info.search
		({
			eq:{ client_user_id: client_user_id},
			csv: {
				status:['CLOSED','ACTIVE'],
				paid_status:['PARTIALLY_PAID','PENDING']
			},
			limit:999999,
		})
		.subscribe
		({
			next:(response)=>
			{
				this.client_order_info_list = response.data;
				this.show_add_payment_to_client = true;
			}
			,error:(error)=>
			{
				this.showError(error);
			}
		})
	}

	onPayment(payment_info:PaymentInfo | null)
	{
		this.show_add_payment_to_client = false;

		if( payment_info != null )
		{
			this.router.navigateByUrl('/',{skipLocationChange: true}).then(()=>{
				this.router.navigate([this.path],{queryParams: {}});
			});
		}
	}

	searchClient(search_object:SearchObject<User>)
	{
		search_object.csv.id = [];
		search_object.eq.type = 'CLIENT';
		this.search(search_object);
	}

	confirmRemoveClient(user:User)
	{
		let title	= 'Eliminar Usuario';
		let body	= 'Estas seguro de querer eliminar al usuario "'+user.name+'"';
		this.subs.sink = this.confirmation
		.showConfirmAlert(user,title,body)
		.pipe
		(
			filter((response)=>
			{
				return response.accepted;
			}),
			mergeMap((response)=>
			{
				return this.rest.user.delete( response.obj )
			})
		)
		.subscribe
		({
			next:(_response)=>
			{
				this.is_loading = false;
				let index = this.user_list.findIndex( i=> i==user );
				if( index> -1 )
				{
					this.user_list.splice( index,1 );
				}
			},
			error:(error)=>
			{
				this.showError( error );
			}
		});
	}

	clickOnHeader(header:string)
	{
		this.sort(header,this.user_search);
	}

	exportFile()
	{
		let search:SearchObject<UserBalance> = {...this.user_search, limit: SEARCH_LIMIT };
		search.eq.type = 'CLIENT';
		search.page = 0;

		this.subs.sink = this.rest.user
		.search( search )
		.pipe
		(
			mergeMap((response)=>
			{
				let observables = [ of(response) ];

				let items_promises:Observable<RestResponse<UserBalance>>[] = [];
				let pages_needed = Math.ceil( response.total/SEARCH_LIMIT );

				for(let i=1;i<pages_needed;i++)
				{
					let s:SearchObject<User> = this.getEmptySearch();
					s.eq.type = 'CLIENT';
					s.limit = SEARCH_LIMIT;
					s.page = i;
					observables.push(	this.rest.user.search( s ) );
				}

				console.log('Returning promises', items_promises);
				return forkJoin(observables);
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				let items = response.reduce((p,c)=>{
					p.push(...c.data );
					return p;
				},[]);

				let data:Record<string,string|number>[] = [];

				let headers = [
					'Id', 'Nombre', 'balance', 'Total Descuentos', 'Descuento Más Grande',
					'Precio', 'Total en ventas', 'Télefono', 'Límite Credito','Email',
					'Fecha Creación', 'Última Actualización'
				];

				//let rows:Record<string,string|number>[] =[];

				items.forEach((r)=>
				{
					let price_type = this.price_type_map.get(r.price_type_id ) as Price_Type;

					data.push
					({
						Id: r.id,
						Nombre: r.name,
						balance: r.balance,
						'Total Descuentos': r.total_discount || 0,
						'Descuento Más Grande': r.biggest_discount || 0,
						'Precio': price_type.name,
						'Total en Ventas':	r.total_orders || 0,
						'Teléfono' : r.phone,
						'Límite Crédito': r.credit_limit,
						'Email': r.email,
						'Fecha Creación': Utils.getLocalMysqlStringFromDate( r.created ),
						'Última Actualización': Utils.getLocalMysqlStringFromDate( r.updated )
					});
				});
				ExcelUtils.array2xlsx(data,'Clientes.xlsx',headers)
			},
			error:(error)=>
			{
				this.showError(error)
			}
		});
	}

	updateCreatedStore(store_id:number | '')
	{
		this.user_search.eq.created_by_store_id = store_id || undefined;
	}
}
