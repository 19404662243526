<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col">
			<h1 class="header-title">{{ title }}</h1>
		</div>
	</div>

	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-6 col-md-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="price_type.name" class="form-control" required>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Prioridad</label>
					<input type="number" name="sort_priority" [(ngModel)]="price_type.sort_priority" class="form-control">
				</div>
				<div class="col-6 col-md-4">
					<label class="">Modelo</label>
					<select name="model" [(ngModel)]="price_type.model" class="form-control">
						<option value="AMOUNT">Cantidad</option>
						<option value="PERCENT">Porcentaje de referencia</option>
					</select>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Modelos IVA</label>
					<select name="tax_model" [(ngModel)]="price_type.tax_model" class="form-control">
						<option value="TAX_INCLUDED">Iva Incluido</option>
						<option value="PLUS_TAX">Mas Iva</option>
						<option value="ALL">Todos</option>
					</select>
				</div>


				<div class="col-6 col-md-4">
					<label class="">Código Facturación</label>
					<select name="show_bill_code" [(ngModel)]="price_type.show_bill_code" class="form-control">
						<option value="YES">Mostrar</option>
						<option value="NO">Ocultar</option>
					</select>
				</div>

				<div class="col-6 col-md-4">
					<label class="">Tipo de cobro</label>
					<select name="type" [(ngModel)]="price_type.type" class="form-control">
						<option value="RETAIL">Menudeo</option>
						<option value="WHOLESALE">Mayoreo</option>
					</select>
				</div>

				<div class="col-6 col-md-4" *ngIf="price_type.type=='WHOLESALE'">
					<label class="">Tipo de mayoreo</label>
					<select name="wholesale_type" [(ngModel)]="price_type.wholesale_type" class="form-control">
						<option value="BY_ARTICLE">Por Artículo</option>
						<option value="BY_CATEGORY">Por Categoría</option>
						<option value="BY_TAG">Por tag</option>
					</select>
				</div>

				<div class="col-6 col-md-4" *ngIf="price_type.type=='WHOLESALE'">
					<label class="">Cantidad Minima Mayoreo</label>
					<input type="number" name="wholesale_min_qty" [(ngModel)]="price_type.wholesale_min_qty" step="0.0001" [min]="0.0001" class="form-control" required>
				</div>
				<div class="col-12 col-sm-6 col-md-4 form-group">
					<label>Color fondo barra</label>
					<input type="color" name="pv_bar_background_color" class="form-control" [(ngModel)]="price_type.pv_bar_background_color">
				</div>
				<div class="col-12 col-sm-6 col-md-4 form-group">
					<label>Color texto</label>
					<input type="color" name="pv_bar_text_color" class="form-control" [(ngModel)]="price_type.pv_bar_text_color">
				</div>
				<div class="col-12 col-sm-6 col-md-4 form-group">
					<label>Color texto total</label>
					<input type="color" name="pv_bar_total_color" class="form-control" [(ngModel)]="price_type.pv_bar_total_color">
				</div>
				<div class="col-12 col-sm-6 col-md-4 form-group">
					<label>Tags</label>
					<div>
						<span *ngFor="let tag of price_type.json_tags" class="badge bg-secondary mx-1">{{tag}}</span>
					</div>
					<input name="the_tags" #the_tags class="form-control">
					<button type="button" class="btn btn-primary" (click)="addTag(the_tags.value); the_tags.value = ''">Agregar Tag</button>
				</div>
				<div class="col-12 col-sm-6 col-md-4 form-group">
					<label>Mensualidades</label>
					<input type="number" name="installments" class="form-control" [(ngModel)]="price_type.installments">
				</div>


			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="button" *ngIf="price_type.tax_model != 'ALL'" [disabled]="is_loading" class="btn btn-primary" (click)="confirmSave()">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>

					<button type="submit" [disabled]="is_loading" class="btn btn-primary" *ngIf="price_type.tax_model == 'ALL'">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
