<div class="container-fluid">
	<h1 class="my-3">Crear Mesas</h1>
	<div class="w-25">
		<div class="card p-3">
			<form (submit)="createTables($event)">
				<div class="form-group">
					<label for="qty">Cantidad de mesas</label>
					<input type="text" class="form-control" id="name" name="qty" [(ngModel)]="qty" required>
				</div>

				<div class="form-group">
					<label>Sucursal</label>
					<select class="form-control" id="store_id" [(ngModel)]="store_id" name="store_id" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
					</select>
				</div>
				<div class="my-3">
					<button type="submit" [disabled]="is_loading" class="btn btn-primary">Crear Mesas</button>
				</div>
			</form>
		</div>
	</div>

	<h2>Actualizar</h2>
	<div class="card p-3 my-3">
		<div class="row">
			<div class="col-12 my-1">
				<button type="button" (click)="exportReferences()" class="btn btn-primary">Exportar Referencias</button>
			</div>
			<div class="col-12 my-1">
				<button type="button" (click)="exportPrices()" class="btn btn-primary">Exportar Precios</button>
			</div>
			<div class="col-12 my-1">
				<button type="button" (click)="updateOffline()" class="btn btn-primary">Actualizar Datos offline</button>
			</div>
			<div class="col-12 my-1">
				<button type="button" (click)="reverseItemsSortWeight()" class="btn btn-primary">Cambiar Prioridades Articulos</button>
			</div>
		</div>
	</div>

	<h2>Buscar Factura</h2>
	<div class="card p-3 my-3">
		<div class="row">
			<div class="col-12 my-1">
				<input type="facturacion_code">
			</div>
			<div class="col-12 my-1">
				<button type="button" (click)="searchOrders()" class="btn btn-primary">Buscar Ordenes</button>
			</div>
		</div>
	</div>


	<h2>Artículos En Bola</h2>
	<div class="card p-3 my-3">
		<form (submit)="createItems($event)">
			<div class="">
				<input type="file" name="items_files" (change)="onFileChanged($event)"	class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Agregar Artículos</button>
			</div>
		</form>
	</div>
	<h2>Precios En Bola</h2>
	<div class="card p-3 my-3">
		<form (submit)="updatePrices($event)">
			<div class="">
				<input type="file" name="items_files" (change)="setPriceFile($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Agregar Precios</button>
			</div>
		</form>
	</div>
	<h2>Inventarios</h2>
	<div class="card p-3 my-3">
		<form (submit)="updateStock($event)">
			<div class="">
				<input type="file" name="stock_file" (change)="setStockFile($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Ajustar Inventarios</button>
			</div>
		</form>
	</div>

	<h2>Maximos y minimos</h2>
	<div class="card p-3 my-3">
			Headers,
			<code>[ "item_id", "min", "max", "store_id" ];</code>
		<form (submit)="updateMaxMin($event)">
			<div class="">
				<input type="file" name="maxmin_file" (change)="setMaxMinFile($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Actualizar Maximos y minimos</button>
			</div>
		</form>
	</div>

	<h2>Minimos</h2>
	<div>
		Headers, Sucursal va tal cual Ej. "Centro", Articulo se ignora, Posiblemente borra los máximos<br>
		<code> [ "item_id", "Articulo", "Minimo", "Sucursal" ] </code>
	</div>
	<div class="card p-3 my-3">
		<form (submit)="updateItemMin($event)">
			<div class="">
				<input type="file" name="min_file" (change)="onMinimunFileChanged($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Actualizar Maximos y minimos</button>
			</div>
		</form>
	</div>

	<h2>Production Area Items</h2>
	<div>
		<code> [ "item_id", "production_area_id"] </code>
	</div>
	<div class="card p-3 my-3">
		<form (submit)="updateProductionAreaItem($event)">
			<div class="">
				<input type="file" name="min_file" (change)="onProductionAreaItemFileChanged($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Articulos en areas de producción</button>
			</div>
		</form>
	</div>

	<h2>Agregar Facturacion</h2>
	<div>
		<code>
			[
				user_id, name,email,name, note, phone,
				rfc, address,suburb, city, country, state,
				sat_regimen_capital, sat_regimen_fiscal, sat_uso_cfdi, zipcode, type
			]
		</code>
	</div>
	<div class="card p-3 my-3">
		<form (submit)="updateAddresses($event)">
			<div class="">
				<input type="file" name="min_file" (change)="onAddressFileChanged($event)" class="form-control" required>
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Actualizar Direcciones</button>
			</div>
		</form>
	</div>


	<h2>Edicion de articulos</h2>
		<div>
			Headers, Sucursal va tal cual Ej. "Centro", Articulo se ignora, Posiblemente borra los máximos<br>
			<code><pre>[
				"id", "applicable_tax", "background", "commission", "commission_type", "commission_currency_id",
				"product_id", "commanda_type_id", "category_id", "currency_id", "image_id", "brand_id", "provider_user_id",
				"reference_currency_id", "code", "has_serial_number", "ieps_type", "ieps_value", "name", "extra_name", "form_id", "note_required",
				"on_sale", "availability_type", "status", "sort_weight", "description", "reference_price", "return_action",
				"clave_sat", "measurement_unit", "unidad_medida_sat_id", "image_style", "json_tags", "text_color",
				"text_style", "shadow_color", "partial_sale"
];</pre></code>
		</div>
	<div class="card p-3 my-3">
		<form (submit)="updateItems($event)">
			<div class="">
				<input type="file" name="update_item_files" (change)="onUpdateItemsFile($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary" [disabled]="is_loading || update_items_file == null">Actualizar Artículos</button>
			</div>
		</form>
	</div>


	<h2>Dar de alta Ordenes</h2>
	<div class="card p-3 my-3">
		<code>
		[ name, total,user_id,currency_id, paid ]
		</code>
		<button type="submit" form="update_orders" class="btn btn-primary">
			Registrar Ordenes
		</button>
	</div>
	<form (submit)="createOrders($event)" id="update_orders">


		<div class="card p-3 my-3">
			<input type="file" (change)="onFileOrdersChanged($event)" name="asdf" multiple required>
		</div>
	</form>

	<h2>Agregar Option Items</h2>
	<div class="card p-3 my-3">
		<form (submit)="addOptionItems($event)">
			<div class="">
				<input type="file" name="add_option_files" (change)="onAddOptionFileChanged($event)" required class="form-control">
			</div>
			<div class="my-3">
				<button type="submit" class="btn btn-primary">Agregar Option Items</button>
			</div>
		</form>
	</div>


	<h2>Subir Archivo</h2>
	<div class="card p-3 my-3">
		<app-attachment-uploader (attachmentChange)="onAttachmentChange($event)" [multiple]="true"></app-attachment-uploader>
	</div>


	<h2>Subir Imagenes</h2>
	<div class="card p-3 my-3">
		<input type="file" (change)="uploadImages($event)" name="asdf" multiple>
	</div>

	<h2></h2>
	<div>
		<button type="button" class="btn btn-primary" (click)="evalProperties()" >Evaluar </button>
		<div *ngFor="let obj of test_objs">
			{{obj | json}}
		</div>
	</div>
</div>
