<form (submit)="save($event)">
	<div class="container-fluid my-3">
		<h3>Fondo de Caja</h3>
		<div class="col-12">
			<b>Ingresos de fondos en sesion actual</b>
		</div>
		<table class="w-100">
			<tr>
				<th>Cantidad</th>
				<th>Moneda</th>
				<th>Fecha</th>
			</tr>
			<tr *ngFor="let f of fund_list">
				<td>{{f.amount | currency}}</td>
				<td>{{f.currency_id}}</td>
				<td>{{f.created | shortDate:'relative'}}</td>
			</tr>
		</table>

		<div class="row mt-3 border-top my-3 py-3">
			<div class="col-6 form-group">
				<label>Pesos</label>
				<input type="number" name="pesos" class="form-control" [(ngModel)]="pesos" step="any" min="0">
			</div>
			<div class="col-6 form-group">
				<label>Dólares</label>
				<input type="number" name="dolares" class="form-control" [(ngModel)]="dolares" step="any" min="0">
			</div>
		</div>
		<div class="row border-top pt-3 my-3">
			<div class="col-6">
				<button type="button" (click)="close()" class="w-100 btn btn-danger" [disabled]="is_loading">Cancelar</button>
			</div>
			<div class="col-6">
				<button type="submit" class="w-100 btn btn-success" [disabled]="is_loading">Guardar</button>
			</div>
		</div>
		<div class="my-3" *ngIf="error_string">
			<div class="alert alert-danger" role="alert">
				{{error_string}}
			</div>
		</div>
	</div>
</form>
