<div class="container-fluid">
	<div class="row align-items-center mx-0">
		<div class="col">
			<h1 class="my-3 text-capitalize">Mesas</h1>
		</div>
		<div class="col-auto px-0 text-end">
			<button type="button" class="ml-2 btn btn-secondary" [routerLink]="['/add-table']"> Agregar mesa</button>
		</div>
	</div>

	<div>
		<div class="row">
			<div class="col-6 col-md-3 form-group">
				<label class="">Nombre</label>
				<input type="text" name="name" [(ngModel)]="table_search.lk.name" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Sucursal</label>
				<select name="store_id" [(ngModel)]="table_search.eq.store_id" class="form-control">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<div  class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Nombre</th>
						<th>Capacidad</th>
						<th>Sucursal</th>
						<th>Requiere Limpieza</th>
						<th>Actualizacíon</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let table of table_list">
						<td>
							<a [routerLink]="['/edit-table',table.id]">{{table.name}}</a>
						</td>
						<td>{{table.capacity}}</td>
						<td>{{table.store.name }}</td>
						<td>{{table.is_dirty}}</td>
						<td>{{table.updated | shortDate: 'relative' }}</td>
					</tr>
					<tr *ngIf="table_list == null || table_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-table'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

