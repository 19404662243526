<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col">
			<h1 class="my-3 text-capitalize">Respuestas</h1>
		</div>
		<div class="col-auto" *ngIf="form_info">
			<button type="button" class="btn btn-secondary" [routerLink]="['/add-response',form_info.form.id]">Agregar</button>
		</div>
	</div>

	<div class="card p-3 shadow">
		<div class="row">
				<div class="col-6 col-md-3 form-group">
					<label class="">Usuario</label>
					<select name="user_id" [(ngModel)]="response_search.eq.user_id" class="form-control">
						<option [value]="null">Todos</option>
						<option *ngFor="let user of user_list" [value]="user.id">{{user.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Fecha Inicial</label>
					<input type="datetime-local" name="created" [(ngModel)]="search_start_date" (ngModelChange)="onDateChange($event, response_search.ge, 'created')" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Fecha Final</label>
					<input type="datetime-local" name="updated" [(ngModel)]="search_end_date" (ngModelChange)="onDateChange($event, response_search.le, 'created')" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Creado por</label>
					<select name="created_by_user_id" [(ngModel)]="response_search.eq.created_by_user_id" class="form-control">
						<option [value]="null">Todos</option>
						<ng-container *ngFor="let user of user_list">
							<option *ngIf="user.type=='USER'" [value]="user.id">{{user.name}}</option>
						</ng-container>
					</select>
				</div>
				<div class="col-6 col-md-3">
					<label>&nbsp;</label>
					<button type="button" class="w-100 btn btn-primary" (click)="search(response_search)">Buscar</button>
				</div>
		</div>
		<app-loading [is_loading]="is_loading"></app-loading>
	</div>
	<div class="card p-3 d-print-none">
		<div class="" *ngIf="pills">
			<span class="btn-sm badge rounded-pill text-bg-primary me-3 pointer" (click)="clickPill('id')" [ngClass]="{'text-bg-primary':pills['id'],'text-bg-secondary':!pills['id']}" >Id</span>
			<span class="btn-sm badge rounded-pill text-bg-primary me-3 pointer" (click)="clickPill('user_id')" [ngClass]="{'text-bg-primary':pills['user_id'],'text-bg-secondary':!pills['user_id']}" >Usuario</span>
			<span class="btn-sm badge rounded-pill text-bg-primary me-3 pointer" (click)="clickPill('date')" [ngClass]="{'text-bg-primary':pills['date'],'text-bg-secondary':!pills['date']}" >Fecha</span>
			<span *ngFor="let col of form_columns" class="btn-sm badge rounded-pill text-bg-primary me-3 pointer" (click)="clickPill(col)" [ngClass]="{'text-bg-primary':pills[col],'text-bg-secondary':!pills[col]}">{{col}}</span>
		</div>
	</div>

	<div class="card p-3 mt-3" *ngIf="form_info">
		<div class="table-responsive">
			<table class="table">
				<thead class="">
					<tr>
						<th>
							<span [ngClass]="{'d-none':!pills['id']}">Id -</span> Nombre
						</th>
						<th [ngClass]="{'d-none':!pills['user_id']}" >Usuario</th>
						<th [ngClass]="{'d-none':!pills['date']}" >Fecha</th>
						<th *ngFor="let col of form_columns" [ngClass]="{'d-none':!pills[col]}">{{col}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let response_info of response_list">
						<td>
							<span [ngClass]="{'d-none':!pills['id']}">{{response_info.response.id}} -</span> <a [routerLink]="['/edit-response',response_info.response.id]"> {{response_info.response.title}} </a>
						</td>
						<td [ngClass]="{'d-none':!pills['user_id']}">{{response_info?.user?.name | titlecase}}</td>
						<td [ngClass]="{'d-none':!pills['date']}">{{response_info?.response.created | shortDate}}</td>
						<td *ngFor="let canswer of response_info.canswers" [ngClass]="{'d-none':!pills[canswer.question.question]}">{{canswer?.answer?.answer_text}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card p-3 mt-3" *ngIf="total_pages>1">
		<app-pagination [path]="'/list-response'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	</div>
</div>
