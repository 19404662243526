import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';

import {Requisition, Stock_Alert, Stock_Record} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import {ItemInfo, RequisitionInfo, RequisitionItemInfo} from 'src/app/models/models';
import { Utils } from 'src/app/classes/Utils';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';

interface CRequisitionItemInfo extends RequisitionItemInfo
{
	stock_alerts:Stock_Alert[];
	store_min:number | null;
	records:Stock_Record[];
	current_stock:number;
}

@Component({
	selector: 'app-save-requisition',
	templateUrl: './save-requisition.component.html',
	styleUrls: ['./save-requisition.component.css']
})

export class SaveRequisitionComponent extends BaseComponent implements OnInit
{
	requisition:Requisition = GetEmpty.requisition();
	store_list:Store[] = [];
	fecha_envio:string;
	requisition_item_info_list:CRequisitionItemInfo[] = [];
	type: 'store' | 'requisition' = 'requisition';
	random_focus:number = 0;
	selected_requisition_item_info: CRequisitionItemInfo | null = null;
	tmp_qty: number | '';
	show_create_with_min:boolean = false;
	required_by_store_id:string = '';
	requested_to_store_id:string = '';
	filter_tags: string = '';

	classes_1 = { 'col-6': true, 'col-md-3': true, 'col-md-4': false, };

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params)=>
			{
				let req_obs = params.has('id')
					? this.rest.requisition_info.get( params.get('id') )
					: of( null );

				return forkJoin
				({
					requisition: req_obs,
					store: this.rest.store.search({limit:9999}),
					type: params.has('type') ? of( params.get('type') ) : of( null )
				})
			}),
			mergeMap((response)=>
			{
				console.log('Responses', response );
				this.updateClasses();

				let requisition = response.requisition != null
					? response.requisition.requisition
					: GetEmpty.requisition();

				if( response.type )
				{
					this.type = response.type;
				}
				else
				{
					this.type = requisition.requested_to_store_id ? 'store' : 'requisition';
				}

				let item_id = response.requisition
					? response.requisition.items.map(i=>i.item.id)
					: [];

				let stock_alerts_objs = item_id.length == 0
					? of({data:[], total: 0})
					: this.rest.stock_alert.search({csv:{item_id:item_id},limit: 99999});

				let item_info_obs = item_id.length == 0
					? of({data:[], total: 0})
					: this.rest.item_info.search({csv:{id:item_id},limit: 99999});

				return forkJoin
				({
					requisition: of( requisition ),
					store: of( response.store ),
					stock_alert: stock_alerts_objs,
					item_info: item_info_obs,
					requisition_items: of( response.requisition ? response.requisition.items : [] )
				})
			}),
		)
		.subscribe((responses)=>
		{
			this.requisition = responses.requisition;

			if( !this.requisition.id )
			{
				this.requisition.required_by_store_id =  this.rest.current_permission.global_requisition
					? null
					: this.rest.current_user.store_id;
			}

			this.requested_to_store_id = this.requisition.requested_to_store_id
				? this.requisition.requested_to_store_id.toString()
				: '';

			this.required_by_store_id = this.requisition.required_by_store_id
				? this.requisition.required_by_store_id.toString()
				: '';


			let stock_alerts = responses.stock_alert;
			let tmp = responses.requisition_items.map((i)=>
			{
				let item_info = responses.item_info.data.find((ii)=>ii.item.id == i.item.id);

				let records:Stock_Record[] = [];
				let current_stock = 0;

				if( item_info )
				{
					records = item_info.records;
					let cr = records.find(sr=>sr.store_id == this.requisition.required_by_store_id);
					current_stock = cr ? cr.qty : 0;
				}

				let item_stock_alerts= stock_alerts.data.filter((sa:Stock_Alert)=>sa.item_id == i.item.id);
				let stock_finder = (sa:Stock_Alert)=>sa.store_id == this.requisition.required_by_store_id;
				let stock_alert = item_stock_alerts.find( stock_finder );
				//let min:number | null = stock_alert && stock_alert.min ? stock_alert.min : null;

				let cri:CRequisitionItemInfo =
				{
					...i,
					records: records,
					current_stock,
					stock_alerts: item_stock_alerts,
					store_min:  stock_alert ? stock_alert.min : null
				};

				return cri;
			});

			tmp.sort((a,b)=>a.item.name.toLowerCase().localeCompare(b.item.name.toLowerCase()));
			this.requisition_item_info_list = tmp;
			this.store_list = responses.store.data;

			if( this.requisition.required_by_store_id )
			{
				this.updateRequiredStoreId( ''+this.requisition.required_by_store_id );
			}

			if( this.requisition.requested_to_store_id)
			{
				this.updateRequestedStoreId( ''+this.requisition.requested_to_store_id );
			}

			let date =  new Date( this.requisition.required_by_timestamp );
			this.fecha_envio = Utils.getLocalMysqlStringFromDate( date ) ;
			this.requisition.required_by_timestamp = Utils.getUTCMysqlStringFromDate(new Date(this.fecha_envio));
			this.updateClasses();
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	updateClasses()
	{
		console.log('Update classes', this.required_by_store_id );

		let qty_elements = 2 +
			+ ( this.type == 'store' ? 1 : 0 )
			+( this.filter_tags.trim() != '' ? 1 : 0 );

		this.classes_1['col-md-4'] = qty_elements == 3;
		this.classes_1['col-md-3'] = qty_elements == 4;
	};

	creteRequisitionWithMinimuns()
	{

	}

	onFechaChange(fecha:string)
	{
		if(fecha)
		{
			this.requisition.required_by_timestamp = Utils.getUTCMysqlStringFromDate(new Date(fecha));
			this.requisition.date = Utils.getUTCMysqlStringFromDate(new Date()).split(' ')[0];
		}
		else
		{
			this.requisition.required_by_timestamp = null;
		}
	}

	onItemSelected(item_info:ItemInfo):void
	{
		let i = this.requisition_item_info_list.find(i=>i.item.id == item_info.item.id);

		if( i !== undefined )
		{
			this.showError('El elemento ya se encuentra en la lista');
			return;
		}

		if( !this.rest.current_permission.add_stock )
		{
			let stock_record = item_info.records.find(sr=>sr.store_id == this.requisition.required_by_store_id);
			let current_stock = stock_record ? stock_record.qty : 0;

			this.selected_requisition_item_info =
			{
				item: item_info.item,
				category: item_info.category,
				requisition_item:
				{
					status: 'ACTIVE',
					aproved_status: 'NOT_APPROVED',
					id: null,
					requisition_id: 0,
					created: new Date(),
					updated: new Date(),
					item_id : item_info.item.id,
					qty: 1
				},
				stock_alerts: [],
				store_min: null,
				records: item_info.records,
				current_stock
			};
			return;
		}

		this.subs.sink = this.rest.stock_alert.search
		({
			eq:
			{
				item_id: item_info.item.id
			},
			limit: 9999
		})
		.subscribe
		({
			next:(response)=>
			{
				console.log( response.data );
				this.selected_requisition_item_info = this.getRequisitionInfo( item_info, response.data );
			},
			error:(error)=>
			{
				console.log('Stock alert Fail', error );
				this.selected_requisition_item_info = this.getRequisitionInfo( item_info );
			}
		});
	}

	getRequisitionInfo(item_info:ItemInfo, sa:Stock_Alert[] = []):CRequisitionItemInfo
	{
		let stock_alerts = sa.filter((s)=>s.item_id == item_info.item.id);
		let min_stock_alert = stock_alerts.find((x)=>x.store_id==this.requisition.required_by_store_id);
		let store_min =min_stock_alert && min_stock_alert.min ? min_stock_alert.min : null;

		let stock_record = item_info.records.find(sr=>sr.store_id == this.requisition.required_by_store_id);
		let current_stock = stock_record ? stock_record.qty : 0;

		return {
			item: item_info.item,
			category: item_info.category,
			requisition_item:
			{
				status: 'ACTIVE',
				aproved_status: 'NOT_APPROVED',
				id: null,
				requisition_id: 0,
				created: new Date(),
				updated: new Date(),
				item_id : item_info.item.id,
				qty: 1
			},
			stock_alerts,
			store_min,
			records: item_info.records,
			current_stock
		};
	}



	//getCRequisitionItemInfo(requisition_item_info:RequisitionItemInfo, sa:Stock_Alert[]):CRequisitionItemInfo
	//{
	//	let stock_alerts = sa.filter((s)=>s.item_id == requisition_item_info.item.id);
	//	let store_stock_alert = stock_alerts.find((s)=>s.store_id== this.requisition.required_by_store_id);
	//	let store_min = store_stock_alert && store_stock_alert.min ? store_stock_alert.min : null;

	//	return { ...requisition_item_info, stock_alerts, store_min , stock_record:};
	//}

	removeItem(rii:RequisitionItemInfo):void
	{
		this.requisition_item_info_list = this.requisition_item_info_list.filter(i=>i != rii);
	}

	save()
	{
		this.is_loading = true;

		if( this.requisition.id	)
		{
			this.subs.sink	= this.rest.requisition_info.update
			({
				requisition:this.requisition,
				items:this.requisition_item_info_list
			})
			.subscribe
			({
				next:(_requisition)=>
				{
					this.is_loading = false;
					this.router.navigate(['/list-requisition']);
					this.showSuccess('La requisición se actualizo exitosamente');
				}
				,error:(error)=>
				{
					this.showError(error);
				}
			});
		}
		else
		{
			this.subs.sink	= this.rest.requisition_info.create
			({
				requisition:this.requisition,
				items:this.requisition_item_info_list
			})
			.subscribe((_requisition)=>
			{
				this.is_loading = false;
				this.showSuccess('La requisición se guardo exitosamente');
				this.router.navigate(['/list-requisition']);
			},(error)=>this.showError(error));
		}
	}

	addSelectedRequisitionItem(crequisition_item_info: CRequisitionItemInfo)
	{
		this.requisition_item_info_list.push( crequisition_item_info );
		this.selected_requisition_item_info = null;
		this.tmp_qty = '';
		this.random_focus++;
	}

	selectText(evt: MouseEvent)
	{
		let input = evt.target as HTMLInputElement;
		input.select();
	}
	selectedRequsitionQtyChange(value: number): void
	{
		this.selected_requisition_item_info.requisition_item.qty = value;
	}

	updateRequiredStoreId(val:string)
	{
		this.required_by_store_id = val;

		if( val )
		{
			let value = parseInt( val );
			this.requisition.required_by_store_id = value;
		}
		else
		{

		}

		for(let cri of this.requisition_item_info_list)
		{
			let stock_alert = cri.stock_alerts.find(sa=>sa.store_id == this.requisition.required_by_store_id);
			cri.store_min = stock_alert ? stock_alert.min : null;
			let stock_record = cri.records.find(sr=>sr.store_id == this.requisition.required_by_store_id);
			cri.current_stock = stock_record? stock_record.qty: 0;
		}
	}

	updateRequestedStoreId(val: string )
	{
		this.requested_to_store_id = val;

		if( val )
		{
			let value = parseInt( val );
			this.requisition.requested_to_store_id = value;
		}
	}

	requisitionMinimuns(evt:Event)
	{
		this.updateClasses();

		if( this.required_by_store_id == '' )
		{
			this.showError('Ocurrio un error por favor seleccione donde se requiren los articulos');
		}

		this.is_loading = true;

		this.subs.sink = this.rest.stock_alert.search
		({
			eq:{ store_id: this.requisition.required_by_store_id },
			ge:{ min_diff: 1 },
			limit: 9999999
		})
		.pipe
		(
			mergeMap((response)=>
			{
				let ids = response.data.map(i=>i.item_id);
				return forkJoin
				({
					items: this.rest.item_info.search({csv:{ id: ids }, limit:999999}),
					stock_alerts: of( response )
				})
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				console.log( response );
				this.is_loading = false;

				let tags = this.filter_tags
					.split(',')
					.map(x=>x.toLowerCase().trim())
					.filter((x)=> x != '')

				console.log('Tags', tags );

				let tmp = [];

				for(let sa of response.stock_alerts.data )
				{
					let item_info = response.items.data.find((ii)=>ii.item.id == sa.item_id );

					if( !item_info || tags.length > item_info.item.json_tags.length )
					{
						console.log('Descartando x menos tags', item_info.item.name );
						continue;
					}

					if( tags.length )
					{
						if( !item_info.item.json_tags )
						{
							item_info.item.json_tags = [];
						}

						let item_tags = item_info.item.json_tags.map((x)=>x.trim().toLowerCase());
						let intersect = item_tags.filter(it=>tags.includes( it ))

						console.log('Item tags', item_tags);
						console.log('intersect', intersect);

						if( intersect.length != tags.length )
						{
							console.log('Descartando no cumple con los tags', item_info.item.name );
							continue;
						}
						console.log('SI cumplen los tags', item_info.item.name, item_tags, tags, intersect );
					}

					let stock_record = item_info.records.find(sr=>sr.store_id == this.requisition.required_by_store_id);
					let current_stock = stock_record ? stock_record.qty : 0;


					tmp.push
					({
						item: item_info.item,
						category: item_info.category,
						requisition_item:
						{
							status: 'ACTIVE',
							aproved_status: 'NOT_APPROVED',
							id: null,
							requisition_id: 0,
							created: new Date(),
							updated: new Date(),
							item_id : item_info.item.id,
							qty: sa.min_diff
						},
						stock_alerts: [],
						store_min: sa.min,
						records: item_info.records,
						current_stock
					});
				}

				tmp.sort((a,b)=>a.item.name.toLowerCase().localeCompare(b.item.name.toLowerCase()));
				this.requisition_item_info_list = tmp;
				this.show_create_with_min = false;
			},
			error:(error)=>
			{
				this.showError(error);
			}
		})
	}
}
