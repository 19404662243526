import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Price_Type } from '../../models/RestModels';


@Component({
selector: 'app-save-price-type',
templateUrl: './save-price-type.component.html',
styleUrls: ['./save-price-type.component.css']
})
export class SavePriceTypeComponent extends BaseComponent implements OnInit
{
	title:string = 'Agregar Tipo de consumo';

	price_type:Price_Type	= {
		id: 0,
		installments: 0,
		json_tags:[],
		model: 'AMOUNT',
		name: '',
		pv_bar_background_color: this.rest.local_preferences.pv_bar_background_color,
		pv_bar_text_color: this.rest.local_preferences.pv_bar_text_color,
		show_bill_code: 'YES',
		sort_priority: 0,
		tax_model: 'ALL',
		type: 'RETAIL',
		wholesale_min_qty: 12,
		wholesale_type: 'BY_ARTICLE',
		pv_bar_total_color: this.rest.local_preferences.pv_bar_total_color,
	};

	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			//this.company = this.rest.getCompanyFromSession();
			if( params.has('id') )
			{
				this.is_loading = true;
				this.subs.sink	= this.rest.price_type.get( params.get('id') ).subscribe((price_type)=>
				{
					this.is_loading = false;
					if( !price_type.json_tags )
					{
						price_type.json_tags = [];
					}

					this.price_type= price_type;
					this.title = 'Editar Tipo de Consumo '+price_type.name;
					this.setTitle(this.title);
				},(error)=>this.showError( error ));
			}
			else
			{
				this.title = 'Nuevo Tipo de consumo';
				this.setTitle(this.title);
			}
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.price_type.id	)
		{
			this.subs.sink	= this.rest.price_type.update( this.price_type ).subscribe((price_type)=>{
				this.is_loading = false;
				this.router.navigate(['/list-price-type']);
				this.showSuccess('El tipo de consumo se actualizo exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.rest.price_type.create( this.price_type )
			.subscribe((price_type)=>
			{
				this.is_loading = false;
				this.showSuccess('El tipo de consumo se guardo exitosamente');
				this.router.navigate(['/list-price-type']);
			},(error)=>this.showError(error));
		}
	}

	confirmSave()
	{
		let message = 'El modelo seleccionado modificara para que todos los precios de este tipo de consumo sean con el iva incluido,\n	seguro que desea continuar?';

		if( this.price_type.tax_model == 'PLUS_TAX' )
		{
			message = 'El modelo seleccionado modificara para que todos los precios de este tipo de consumo sean mas IVA,	seguro que desea continuar?';
		}

		this.subs.sink = this.confirmation.showConfirmAlert(this.price_type, 'Guardar tipo de consumo', message )
		.subscribe((result)=>
		{
			if( result.accepted )
			{
				this.save();
			}
		});
	}
	addTag(tag: string):void
	{
		if( this.price_type.json_tags.includes(tag.toLowerCase() ) )
			return;

		this.price_type.json_tags.push( tag.toLowerCase() );
	}
}
