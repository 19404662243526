import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';


import {Commanda_Type} from '../../models/RestModels';


@Component({
	selector: 'app-list-commanda-type',
	templateUrl: './list-commanda-type.component.html',
	styleUrls: ['./list-commanda-type.component.css']
})

export class ListCommandaTypeComponent extends BaseComponent implements OnInit {
	file:File = null;
	show_import:boolean = false;
	commanda_type_search:SearchObject<Commanda_Type> = this.getEmptySearch();
	search_extra:Record<string,string> = { };
	commanda_type_list:Commanda_Type[] = [];






	ngOnInit()
	{
		this.path = '/list-commanda-type';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","name","created","updated" ];
			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.commanda_type_search = this.getSearch(queryParamMap, fields );

			this.setTitle('Tipos de comanda');
			this.current_page = this.commanda_type_search.page;
			this.is_loading = true;
			this.subs.sink = this.rest.commanda_type.search(this.commanda_type_search)
			.subscribe((response)=>
			{
				this.setPages( this.commanda_type_search.page, response.total );
				this.commanda_type_list = response.data;
				this.is_loading = false;
			},(error)=>this.showError(error));

		});
	}

	onFileChanged(event)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}
	/*

	uploadFile()
	{
		this.is_loading = true;
		Utils.xlsx2json( this.file,["id","name","created","updated"]).then((json)=>
		{
			//Filter json then upload
			this.subs.sink	= this.rest.commanda_type.batchUpdate(json).subscribe((result)=>
			{
				if( this.commanda_type_list.length == 0 )
				{
					this.setPages( 0, result.length );
					this.commanda_type_list = result.slice(0,this.pageSize);
				}
				this.is_loading =  false;
                this.show_import = false;
                this.showSuccess('Imported succesfully '+result.length+' items');

			},(error)=>this.showError(error));
		});
	}

	exportFile()
	{
		this.is_loading = true;
		this.subs.sink	= this.rest.commanda_type.search({limit:100000}).subscribe((response)=>
		{
			Utils.array2xlsx(response.data,'commanda_type.xlsx',["id","name","created","updated"])
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
	*/
}
