import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { GraphUtils } from 'src/app/classes/GraphUtils';
import { Utils } from 'src/app/classes/Utils';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface ISearch
{
	created;
}

interface FOO
{
	store_id:number;
	store_name:string;
	avg_ticket:number;
	day:string;
	order_count:number;
}

@Component({
	selector: 'app-report-ticket-avg',
	templateUrl: './report-ticket-avg.component.html',
	styleUrls: ['./report-ticket-avg.component.css']
})
export class ReportTicketAvgComponent extends BaseComponent implements OnInit
{
	avg_order_graph = GraphUtils.getEmptyLineChart();
	days:string[] = [];
	start:string = '';

	endx: string = '';
	startx: string = '';
	isearch:SearchObject<ISearch> = this.getEmptySearch();

	ngOnInit(): void
	{
		this.path = '/report-ticket-avg';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let d = new Date();
				let minutes_offset = d.getTimezoneOffset();

				this.isearch = this.getSearch(response[0],['created'],[]);


				if( !this.isearch.ge.created )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.isearch.ge.created = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.isearch.le.created )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours( 23, 59, 59, 0 );

					this.isearch.le.created= end_of_month;//Utils.getUTCMysqlStringFromDate( end_of_month );
				}

				this.endx	= Utils.getLocalMysqlStringFromDate( this.isearch.le.created as Date);
				this.startx	= Utils.getLocalMysqlStringFromDate( this.isearch.ge.created as Date);

				let payload = {
					minutes_offset,
					start:Utils.getUTCMysqlStringFromDate(this.isearch.ge.created ),
					end:Utils.getUTCMysqlStringFromDate(this.isearch.le.created ),
				};

				return this.rest.getReporte('averageTicket',payload);
			})
		)
		.subscribe((response:FOO[])=>
		{
			let map = new Map<number,FOO[]>()
			let store_ids:Record<string,string|number>[] = [];

			let dayIndex = new Map<string,number>();

			for(let d of response )
			{
				dayIndex.set(d.day, 0 );
			}

			let days:string [] = Array.from(dayIndex.keys());
			days.sort((a,b)=>a>b?1:-1);

			days.forEach((str,index)=>
			{
				dayIndex.set(str, index );
			});

			this.days = days;

			response.sort((a,b)=>{
				return a.day > b.day ? 1 : -1;
			});

			console.log( response );

			console.log('Days', days );

			for(let d of response)
			{
				let m = map.get( d.store_id);
				if( m == null )
				{
					m = days.map((day)=>{
						return {
							day,
							avg_ticket: 0,
							order_count: 0,
							store_name: d.store_name,
							store_id: d.store_id
						};
					});

					map.set(d.store_id, m );

					store_ids.push
					({ 
						store_id:d.store_id,
						store_name:d.store_name
					});
				}
				let index = dayIndex.get(d.day);
				m[ index ] = d;
				//m.push(d);
			}

			console.log( map );

			let data = [];
			for(let r of store_ids )
			{
				let d = map.get(r.store_id as number);
				//console.log('Que tiene D', d, r );

				if( !d )
				{
					console.log('Record malo',  d, r );
					continue;
				}
				data.push({
					name: r.store_name,
					series: d.map((x)=>{ return { value: x.avg_ticket, name: x.day }})
				})
			}
			console.log('Data graph', data);

			this.avg_order_graph.results = data;

			console.log( response );
		},(error)=>this.showError(error))
	}
	searchReport()
	{
		this.search( this.isearch );
	}
}
