import { Pipe, PipeTransform } from '@angular/core';
import { ItemInfo, ItemOptionValueInfo, RequisitionItemInfo } from '../models/models';
import { RestService } from '../services/rest.service';

@Pipe({
  name: 'itemName'
})
export class ItemNamePipe implements PipeTransform {

  rest:RestService;

  constructor(rest:RestService)
  {
    this.rest = rest;
  }
  transform(value: ItemInfo|ItemOptionValueInfo|RequisitionItemInfo):string
  {
      if( value.category && this.rest.local_preferences.display_categories_on_items == 'YES' )
      {
        return value.category.name+' - '+value.item.name;
      }
      return value.item.name;
  }

}
