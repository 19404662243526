import { Component } from '@angular/core';
import { ParamMap } from '@angular/router';
import { combineLatest, forkJoin, of, Subject} from 'rxjs';
import {debounceTime, mergeMap, startWith} from 'rxjs/operators';
import {CategoryInfo} from 'src/app/models/models';
import {Category, Price_List, Price_Type} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-list-category-price',
	templateUrl: './list-category-price.component.html',
	styleUrls: ['./list-category-price.component.css']
})
export class ListCategoryPriceComponent extends BaseComponent
{
	category_info_search:SearchObject<Category> = this.getEmptySearch();
	category_info_list:CategoryInfo[] = [];
	price_list:Price_List | null = null;
	price_type_list:Price_Type[] = [];
	price_of:Record<string,number> = {};
	model_of:Record<string,'YES'|'NO'> = {};
	search_term:string = '';
	search_subject = new Subject<string>();

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable()
		.pipe
		(
			mergeMap((response)=>
			{
				console.log('Navegando',response);
				let query_param_map= response[0];
				let param_map = response[1];
				this.path = '/list-category-price/'+param_map.get('price_list_id');
				this.setTitle('Precios por categoría')
				this.category_info_search = this.getSearch( param_map,['id','name']);
				this.category_info_search.sort_order = ['name_ASC'];

				this.category_info_search.lk.name = query_param_map.get('lk.name') || '';

				return forkJoin
				({
					category_info: this.rest.category_info.search(this.category_info_search),
					price_type: this.rest.getPriceTypes(true),
					price_list: ''+(this.price_list?.id ||'') == param_map.get('price_list_id') ? of( this.price_list ) : this.rest.price_list.get( param_map.get('price_list_id') )
				});
			})
		)
		.subscribe((response)=>
		{
			this.price_list	= response.price_list;

			response.category_info.data.forEach((category_info)=>
			{
				response.price_type.data.forEach((price_type:Price_Type)=>
				{
					if( price_type.tax_model == 'TAX_INCLUDED' )
					{
						this.model_of[ this.price_list.id+'-'+category_info.category.id+'-'+price_type.id ] = 'YES';
					}
					else
					{
						this.model_of[ this.price_list.id+'-'+category_info.category.id+'-'+price_type.id ] = 'NO';
					}
				})
			});

			this.price_type_list = response.price_type.data;
			this.category_info_list = response.category_info.data;
		},(error)=>this.showError(error));

		this.subs.sink = this.search_subject.pipe
		(
			debounceTime(300)
		)
		.subscribe((search:string)=>
		{
			this.category_info_search.page = 0;
			this.category_info_search.lk.name = search;

			this.searchNoForceReload( this.category_info_search );
		},(error)=>this.showError(error));

	}

	saveCategoryPrice(category_info:CategoryInfo, price_type:Price_Type)
	{
		let price = this.price_of[this.price_list.id+'-'+category_info.category.id+'-'+price_type.id];
		let model = this.model_of[this.price_list.id+'-'+category_info.category.id+'-'+price_type.id];

		this.subs.sink = this.rest.updateCategoryPrices(category_info.category.id,this.price_list.id,price_type.id,price,model,'MXN').subscribe(()=>
		{

		},(error)=>this.showError(error));
		/*
		this.subs.sink = this.rest.price.create
		({
			currency_id: 'MXN',
			item_id: item_info.item.id,
			price_list_id: this.price_list.id,
			price_type_id: price_type.id,
			price: value
		}).subscribe(()=>
		{
			this.showSuccess('El precio se actualizo correctamente');
		},(error)=>this.showError(error));
		*/
	}

	priceChange(price_list:Price_List,category_info:CategoryInfo,price_type:Price_Type,price:number)
	{
		let id = price_list.id+'-'+category_info.category.id+'-'+price_type.id;
		let model = this.model_of[id];

		this.subs.sink = this.rest.updateCategoryPrices(category_info.category.id,this.price_list.id,price_type.id,price,model,'MXN').subscribe(()=>
		{
			this.showSuccess('El precio se actualizo correctamente');
		},(error)=>this.showError(error));

	}
	modelChange(price_list:Price_List,category_info:CategoryInfo,price_type:Price_Type,model:'YES'|'NO')
	{
		let id = price_list.id+'-'+category_info.category.id+'-'+price_type.id;
		let price = this.price_of[id];

		this.subs.sink = this.rest.updateCategoryPrices(
			category_info.category.id,
			this.price_list.id,
			price_type.id,
			price,
			model,
			'MXN'
		).subscribe(()=>
		{
			this.showSuccess('El precio se actualizo correctamente');
		},(error)=>this.showError(error));

	}

	onSearch(evt:any)
	{
		let search: string = evt.target.value;

		this.search_subject.next(search);
	}
}
