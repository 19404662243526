<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3">Reporte de utilidad de artículos por venta</h1>
		</div>
		<div class="col-2 text-end">
			<button type="button" class="btn btn-secondary" (click)="exportReport($event)" *ngIf="!rest.is_offline">
				<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
				&nbsp;
				<!-- <span class="d-none d-sm-inline">Exportar</span> -->
				Exportar
			</button>
		</div>
	</div>
	<div class="card px-lg-3 px-1 py-3 d-print-none">
		<div class="row">
			<div class="col-6 col-lg-3 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.ge,'created','',99)" class="form-control">
			</div>
			<div class="col-6 col-lg-3 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx" (ngModelChange)="onDateChange($event,report_search.le,'created','',99)" class="form-control">
			</div>
			<div class="col-6 col-lg-3 form-group">
				<label>Sucursal</label>
				<select [(ngModel)]="report_search.search_extra.store_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id" [selected]="report_search.search_extra.store_id==store.id">
						{{store.name | titlecase}}
					</option>
				</select>
			</div>

			<div class="col-6 col-lg-3 form-group">
				<label>Nombre</label>
				<input name="item_name" [(ngModel)]="report_search.search_extra.item_name" class="form-control">
			</div>

			<div class="col-6 col-lg-3 form-group">
				<label>Categoría</label>
				<select [(ngModel)]="report_search.eq.category_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let category of category_list" [value]="category.id" [selected]="report_search.eq.category_id ==category.id">
						{{category.name | titlecase}}
					</option>
				</select>
			</div>
			<div class="col-6 col-lg-3 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary w-100" (click)="search(report_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="card my-3">
		<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
		<div class="table-responsive">
			<table class="w-100 table-striped">
				<thead>
					<tr class="border">
						<th class="border">Orden</th>
						<th class="border">Nombre</th>
						<th class="border">Cantidad</th>
						<th class="border">Costo Ingredientes</th>
						<th class="border">Costo Opciones</th>
						<th class="border">Costo Total</th>
						<th class="border">Ingreso</th>
						<th class="border">Ganancia</th>
						<th class="border">Fecha</th>
						<th class="border">Margen</th>
					</tr>
				</thead>
				<tr	*ngFor="let x of report_list">
					<td class="border">
						<a [routerLink]="['/view-order',x.row.order_id]">{{x.row.order_id}}</a>
					</td>
					<td class="border">{{x.row.name}}</td>
					<td class="border">{{x.row.qty}}</td>
					<td class="border">{{x.row.ingredients_cost | currency}}</td>
					<td class="border">{{x.row.child_items_cost | currency}}</td>
					<td class="border">{{x.row.item_cost | currency}}</td>
					<td class="border">{{x.row.sale_total | currency}}</td>
					<td [ngClass]="{border:1,'text-danger':x.row.sale_profit<0}">{{x.row.sale_profit | currency}}</td>
					<td class="border">{{x.row.created | shortDate}}</td>
					<td [ngClass]="{'border':1,'text-danger':x.row.sale_profit<0}">
						<span *ngIf="x.row.sale_profit<0" >-</span>{{x.profit.profit_percent|percent}}
					</td>
					<td class="border w-25">
						<div class="progress">
							<div class="progress-bar bg-warning" style="width: {{x.profit.cost_percent*100|number:'1.2-2'}}%">
							</div>
							<div [ngClass]="{'progress-bar':1,'bg-danger':x.profit.profit_color=='bg-danger','bg-success':x.profit.profit_color=='bg-success'}" style="width: {{x.profit.profit_percent*100|number:'1.2-2'}}%"></div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	</div>
</div>
