import { Component} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {QuoteInfo} from 'src/app/models/models';
import {Address} from 'src/app/models/RestModels';
import {ViewQuoteComponent} from '../view-quote/view-quote.component';

@Component({
	selector: 'app-print-quote',
	templateUrl: './print-quote.component.html',
	styleUrls: ['./print-quote.component.css']
})

export class PrintQuoteComponent extends ViewQuoteComponent
{
	quote_info:QuoteInfo | null = null;
	total:number = 0;
	billing_address:Address | null = null;

	ngOnInit(): void
	{
		this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				return this.rest.quote_info.get(param_map.get('id'));
			}),
			mergeMap((quote_info)=>
			{
				let obs:Observable<Address | null> | null = null;

				if( !quote_info?.client_user )
				{
					obs = of(null);
				}
				else
				{
					if( quote_info.client_user.default_billing_address_id )
					{
						obs = this.rest.address.get(quote_info.client_user.default_billing_address_id);
					}
					else ( quote_info?.client_user )
					{
						obs = this.rest.address.search
						({
							eq		:{ user_id:quote_info.client_user.id },
							nn		:['rfc'],
							limit	:1
						})
						.pipe
						(
							mergeMap((response)=>
							{
								if( response.total > 0 )
								{
									return of( response.data[0] );
								}
								else
								{
									return of( null );
								}
							})
						);
					}
				}

				return forkJoin
				({
					quote_info: of(quote_info),
					address:	obs
				});
			})

		).subscribe((response)=>
		{
			this.quote_info = response.quote_info;
			this.total = response.quote_info.items.reduce((p,i)=>p+i.quote_item.total,0);
			this.billing_address = response.address;
		},(error)=>this.showError(error));
	}
	printDirect()
	{
		window.print();
	}
	print()
	{
		//let mes = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Nombiembre','Diciembre'];
		//generatePDF(
		//			''+this.order_info.order.created.getFullYear()+'-'
		//			+mes[ this.order_info.order.created.getMonth()]+'-'
		//			+this.order_info.order.id+'-'
		//			+this.order_info.order.client_name+'-'
		//			+Utils.zero( this.order_info.order.created.getDate())
		//			);
		//console.log('try to save');

		//var doc = new jsPDF({
		//	orientation: 'p',
		//	format:'letter',
		//})

		//doc.setFontSize(12);
		//doc.html(document.body, {
		//	callback: function (doc) {
		//		doc.save();
		//	},
		//	x: 0,
		//	y: 0
		//});

	//	generatePDF('miname.pdf');
		//const doct = new jsPDF();
		//const specialElementHandlers = {
		//	'#pdf': function(element,renderer ){
		//		return true;
		//	}
		//};
	}
}
