import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, Observable, Observer, of } from 'rxjs';
import {Address} from '../../models/RestModels';
import {User} from '../../models/RestModels';
import {GeocodingAddressComponent, GeocodingResponse, GeocodingResult, LatLng} from 'src/app/models/models';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Coordinates } from 'src/app/classes/Utils';
import {GoogleMap} from '@angular/google-maps';


@Component({
	selector: 'app-save-address',
	templateUrl: './save-address.component.html',
	styleUrls: ['./save-address.component.css']
})
export class SaveAddressComponent extends BaseComponent implements OnInit
{
	@ViewChild('map_dialog') map_dialog:ElementRef;
	@ViewChild(GoogleMap, { static: false }) map: GoogleMap;

	address:Address	= GetEmpty.address();
	user_list:User[] = [];
	states:String[] = [
		'Aguascalientes',
		'Baja California',
		'Baja California Sur',
		'Campeche',
		'Chiapas',
		'Chihuahua',
		'Ciudad de México',
		'Coahuila',
		'Colima',
		'Durango',
		'Guanajuato',
		'Guerrero',
		'Hidalgo',
		'Jalisco',
		'México',
		'Michoacán',
		'Morelos',
		'Nayarit',
		'Nuevo León',
		'Oaxaca',
		'Puebla',
		'Querétaro',
		'Quintana Roo',
		'San Luis Potosí',
		'Sinaloa',
		'Sonora',
		'Tabasco',
		'Tamaulipas',
		'Tlaxcala',
		'Veracruz',
		'Yucatán',
		'Zacatecas',
	];

	country_suggestion:string = 'Mexico';
	city_suggestion:string = '';
	address_suggestion:string=''
	state_suggestion:string = '';
	zipcode_suggestion:string = '';
	user:User | null = null;
	sublocalities:string[] = [];
	show_map_location: boolean = false;
	current_position:LatLng = { lat:31.86365,lng:-116.60719 };
	geolocation_watch_id: number | null = null;
    center_position:LatLng = this.current_position;

	ngOnInit()
	{
		if('geolocation' in navigator)
		{
			/* geolocation is available */
			const options = {
				enableHighAccuracy: true,
				maximumAge: 30000,
				timeout: 27000
			};

			let success = (position:GeolocationPosition)=>
			{
			};

			let error = (_error:any)=>
			{
				this.showError('No se pudo obtener la posicion, por favor asinge una manualmente');
			};

			navigator.geolocation.getCurrentPosition( success, error, options );
		}

		this.subs.sink = this.route.paramMap.subscribe( params =>
		{
			this.is_loading = true;
			this.address = GetEmpty.address();
			this.address.type = 'BILLING';

			if( params.has('id') )
			{
				this.subs.sink = this.rest.address.get( params.get('id') ).pipe
				(
					mergeMap((response)=>
					{
						return forkJoin
						({
							address: of( response ),
							user: this.rest.user.get( response.user_id )
						})
					})
				)
				.subscribe((response)=>
				{
					this.is_loading = false;
					this.address = response.address;
					this.user = response.user;
				},(error)=>this.showError(error));
			}
			else
			{
				this.subs.sink = this.rest.user.get(params.get('user_id') )
				.subscribe((response)=>
				{
					this.user = response;
					this.address.user_id = this.user.id;
					this.is_loading = false;
				},(error)=>this.showError(error));
			}

			navigator.geolocation.getCurrentPosition((geolocation_position:GeolocationPosition)=>
			{
				console.log('Buscando informacion para ',geolocation_position );

				this.sublocalities = [];
				this.subs.sink = this.rest.getAddressFromGeolocationPosition( geolocation_position ).subscribe((geocoding_response:GeocodingResponse)=>
				{
					console.log('Respuesta de google', geocoding_response );
					if( geocoding_response.status == 'OK' )
					{
						//Tal vez hay que quitar este if
						if( geocoding_response.results.length > 0 )
						{
							let geocoding_result:GeocodingResult = geocoding_response.results[0];

							geocoding_result.address_components.forEach((geocoding_address_component:GeocodingAddressComponent)=>
							{
								//if( geocoding_address_component.types.includes('country') )
								//{
								//	console.log('Detectado pais');
								//	this.country_suggestion =  geocoding_address_component.long_name;
								//}
								//if( geocoding_address_component.types.includes('administrative_area_level_1') )
								//{
								//	console.log('Detectado estado');
								//	this.state_suggestion =  geocoding_address_component.long_name;
								//}
								//if( geocoding_address_component.types.includes('locality') )
								//{
								//	console.log('Detectado ciudad');
								//	this.city_suggestion =  geocoding_address_component.long_name;
								//}

								//if( geocoding_address_component.types.includes('postal_code') )
								//{
								//	console.log('codigo_+postal');
								//	this.zipcode_suggestion =  geocoding_address_component.long_name;
								//}
							})
						}

						geocoding_response.results.forEach((geocoding_result)=>
						{
							geocoding_result.address_components.forEach((geocoding_address_component)=>
							{
								if( geocoding_address_component.types.includes('sublocality') )
								{
									this.sublocalities.push( geocoding_address_component.long_name );
								}
							});
						})
					}
				},(error)=>{
					console.error('NO se pudo obtener la informacion de la geolocalizacion. google no pudo dar informacion posible problema de el punto geografico del dispositivo', error);
				});
			},(error)=>
			{
				console.error('NO se pudo obtener la informacion de la geolocalizacion. Error dispositivo o permisos',error);
			},{
				maximumAge: Infinity, timeout: 500,enableHighAccuracy:false
			})
		});
	}

	save()
	{
		this.is_loading = true;


		//if( this.address.type == 'SHIPPING' || this.address.type == 'BILLING_AND_SHIPPING' )
		//{
			let str = this.address.address;

			//if( this.address.suburb )
			//	str+=','+this.address.suburb;

			//if( this.address.city )
			//	str+=','+this.address.city;

			//if( this.address.state )
			//	str+=','+this.address.state+'.';

			//if( this.address.country)
			//	str+=''+this.address.state+'.';

			//if( this.address.zipcode )
			//	str+=''+this.address.state+'CP '+this.address.zipcode;


		if( this.address.id	)
		{
			this.subs.sink	= this.rest.address
			.update( this.address )
			.subscribe((address)=>
			{
				this.is_loading = false;
				this.location.back();
				this.updateAddressLocation(address).subscribe((response)=>
				{
					this.showSuccess('La dirección se actualizó exitosamente');
					this.location.back();
				},(error)=>{
					this.showSuccess('La dirección se actualizó exitosamente');
					this.location.back();
				});
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.address
			.create( this.address )
			.subscribe((address)=>
			{
				this.is_loading = false;
				this.showSuccess('la dirección se guardó exitosamente');

				this.updateAddressLocation(address).subscribe((response)=>
				{
					this.showSuccess('La dirección se actualizó exitosamente');
					this.location.back();
				},(error)=>{
					this.showSuccess('La dirección se actualizó exitosamente');
					this.location.back();
				});
			},(error)=>this.showError(error));
		}
	}

	updateAddressLocation(address:Address):Observable<Address>
	{
		if( !this.rest.is_maps_loaded )
			return of( address );

		return this.rest.getLocationFromAddress( address.address )
		.pipe
		(
			mergeMap((geocoding_response:GeocodingResponse)=>
			{
				if( geocoding_response.status == 'OK' && geocoding_response.results.length > 0)
				{
					this.address.lat = geocoding_response.results[0].geometry.location.lat;
					this.address.lng = geocoding_response.results[0].geometry.location.lng;

					return this.rest.address.update( this.address );
				}
				return of( this.address );
			})
		)
	}

	onAddressChange(address:string)
	{
		console.log('It changes 1');
		this.address.address = address;

		if( this.address.address == this.address_suggestion )
		{
			console.log('It changes');
			//this.address.city = this.city_suggestion;
			//this.address.state = this.state_suggestion;
			//this.address.zipcode = this.zipcode_suggestion;
			//this.address.country = this.country_suggestion;
		}
	}
	onCityChange(city:string)
	{
		//this.address.city = city;
		//if( this.address.city == this.city_suggestion )
		//{
		//	console.log('It changes');
		//	//this.address.city = this.city_suggestion;
		//	//this.address.state = this.state_suggestion;
		//	//this.address.zipcode = this.zipcode_suggestion;
		//	//this.address.country = this.country_suggestion;
		//}
	}

	showAssignMapLocation()
	{
		this.show_map_location = true;

		if( this.map_dialog )
		{
			if( this.map_dialog.nativeElement )
			{
				let dialog = this.map_dialog.nativeElement;
				dialog.showModal();
			}
		}

		if( this.rest.is_maps_loaded )
		{
			this.show_map_location = true
			return;
		}

		this.rest.loadGoogleMapsApi().subscribe(()=>
		{
			this.show_map_location = true;
		});
	}

	processPosition(geolocation_position:GeolocationPosition)
	{
		this.current_position =
		{
			lat: geolocation_position.coords.latitude,
			lng: geolocation_position.coords.longitude
		};
	}

	hideMapDialog()
	{
		this.map_dialog.nativeElement.close();
	}
	addLocation()
	{
		this.address.lat = this.center_position.lat;
		this.address.lng = this.center_position.lng;
		this.map_dialog.nativeElement.close();
		//this.save();
	}

	centerChanged(evt:any)
	{
		let x = this.map.googleMap.getCenter();
		this.center_position = { lat: x.lat(), lng: x.lng() };
	}


}
