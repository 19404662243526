<div class="container-fluid">
	<h1 class="my-3">Utilidad de artículos</h1>
	<div class="card px-lg-3 px-1 py-3 d-print-none">
		<div class="row">
			<!-- div class="col-6 form-group">
				<label>Nombre</label>
				<input type="text" [(ngModel)]="report_search.search_extra.compound_name" class="form-control">
			</div -->
			<div class="col-6 col-md-6 col-xl-3 form-group">
				<label>Artículo</label>
				<input type="text" name="item_name" [(ngModel)]="report_search.search_extra.item_name" class="form-control">
			</div>
			<div class="col-6 col-md-6 col-xl-3 form-group">
				<label>Sucursal</label>
				<select [(ngModel)]="report_search.search_extra.store_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id" [selected]="report_search.search_extra.store_id==store.id">{{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 col-md-4 col-xl-2 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.search_extra,'start_timestamp')" class="form-control">
			</div>
			<div class="col-6 col-md-4 col-xl-2 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx" (ngModelChange)="onDateChange($event,report_search.search_extra,'end_timestamp')" class="form-control">
			</div>
			<div class="col-6 col-md-4 col-xl-2 form-group text-end">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary w-100" (click)="search(report_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="px-1 my-3">
		<div class="row text-center">
			<div class="col-12 col-md-3 my-2">
				<div class="card mx-3 px-3">
					<b class="fs-3">Cantidad</b>
					<div>{{totals.total_qty}}</div>
				</div>
			</div>
			<div class="col-12 col-md-3 my-2">
				<div class="card mx-3 px-3">
					<b class="fs-3 fs-md-1">Costo</b>
					<div>{{totals.total_cost | currency}}</div>
				</div>
			</div>
			<div class="col-12 col-md-3 my-2">
				<div class="card mx-3 px-3">
					<b class="fs-3 fs-md-1">Venta</b>
					<div>{{totals.total_sale | currency}}</div>
				</div>
			</div>
			<div class="col-12 col-md-3 my-2">
				<div class="card mx-3 px-3">
					<b class="fs-3 fs-md-1">Ganancia</b>
					<div>{{totals.total_profit| currency}}</div>
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card my-3">
		<div class="row border-bottom d-none d-md-flex mx-0 align-items-center my-3">
			<div class="col-3 fw-bold text-start">Nombre</div>
			<div class="col-9">
				<div class="row align-items-center">
					<div class="col-2 fw-bold text-end px-1">Cantidad</div>
					<div class="col-2 fw-bold text-end px-1">
						Costo<br>Total
					</div>
					<div class="col-2 fw-bold text-end px-1">Ingreso</div>
					<div class="col-2 fw-bold text-end px-1">Ganancia</div>
					<div class="col-2 fw-bold text-end px-1">
						Precio<br>
						Promedio
					</div>
					<div class="col-2 fw-bold text-end px-1">Margen</div>
				</div>
			</div>
		</div>
		<div class="row border-bottom mx-0" *ngFor="let x of report_list">
			<div class="col-12 col-md-3 semitable fw-bold h3">
				{{x.row.name}}
			</div>
			<div class="col-12 col-md-9">
				<div class="row">
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Artículos: </span>
						{{x.row.total_qty | number}}
					</div>
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Costo Total: </span>
						{{x.row.total_cost | currency}}
					</div>
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Venta Total: </span>
						{{x.row.total_sale | currency}}
					</div>
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Ganacia: </span>
						{{x.row.total_sale-x.row.total_cost | currency}}
					</div>
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Precio Promedio: </span>
						<span [ngClass]="{'text-danger':x.row.sale_profit<0}">
							{{x.row.avg_price | currency}}
						</span>
					</div>
					<div class="col-12 col-md-2 semitable">
						<span class="d-md-none fw-bold">Porcentaje Ganancia: </span>
						<span [ngClass]="{'text-danger':x.row.sale_profit<0}">
							<span *ngIf="x.row.sale_profit<0">-</span>
							{{x.profit.profit_percent|percent}}
						</span>
					</div>
				</div>
			</div>
			<div class="col-12 pb-1">
				<div class="progress">
						<div class="progress-bar bg-warning" style="width: {{x.profit.cost_percent*100|number:'1.2-2'}}%"></div>
						<div [ngClass]="{'progress-bar':1,'bg-danger':x.profit.profit_color=='bg-danger','bg-success':x.profit.profit_color=='bg-success'}" style="width: {{x.profit.profit_percent*100|number:'1.2-2'}}%"></div>
					</div>
				</div>
			</div>
		</div>
		<app-pagination [path]="this.path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
