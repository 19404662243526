<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{user.id ? 'Actualizar':'Agregar'}} Cliente</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">

			<div class="row p-3">
				<div class="col-12 col-lg-2 col-xl-1">
					<label class="">Imagen</label>
					<app-image-uploader [(image)]="user.image_id"></app-image-uploader>
				</div>
				<div class="col-12 col-lg-10">
					<div class="row">
						<div class="col-12 col-md-4">
							<label class="">Nombre</label>
							<input type="text" name="nombre" [(ngModel)]="user.name" class="form-control">
						</div>
						<div class="col-12 col-md-4">
							<label class="">Teléfono</label>
							<input type="text" name="phone" [(ngModel)]="user.phone" class="form-control">
						</div>
						<!--div class="col-12 col-md-4">
							<label class="">Contacto</label>
							<input type="text" name="contacto" [(ngModel)]="user.contact" class="form-control">
						</div-->
						<div class="col-12 col-md-4">
							<label class="">Correo electrónico</label>
							<input type="text" class="form-control" name="email" [(ngModel)]="user.email">
						</div>

						<div class="col-12 col-md-3 form-group">
							<label>Limite de crédito</label>
							<input type="number" min="0" max="10000000" class="form-control" name="credit_limit" [(ngModel)]="user.credit_limit" required>
						</div>
						<div class="col-12 col-md-3 form-group">
							<label>Días de crédito</label>
							<input type="number" min="0" max="10000000" class="form-control" name="credit_days" [(ngModel)]="user.credit_days" [required]="user.credit_limit > 0">
						</div>
						<!--div class="col-6 col-md-4">
							<label class="">Tipo</label>
							<select name="type" [(ngModel)]="user.type" class="form-control">
								<option [ngValue]="null">Seleccionar</option>
								<option value="VENTA">VENTA</option>
								<option value="COPEO">COPEO</option>
							</select>
						</div-->
						<!-- div class="col-6 col-md-4">
							<label class="">Razón social</label>
							<input type="text" class="form-control" name="business_name" [(ngModel)]="user.business_name">
						</div>
						<div class="col-6 col-md-4">
							<label class="">Rfc</label>
							<input type="text" name="rfc" [(ngModel)]="user.rfc" class="form-control">
						</div -->
						<div class="col-12 col-md-3 form-group">
							<label>Tipo de consumo</label>
							<select class="form-control" [(ngModel)]="user.price_type_id" name="price_type_id" required>
								<option [ngValue]="null">Seleccionar</option>
								<option *ngFor="let price_type of price_type_list" [ngValue]="price_type.id">{{price_type.name}}</option>
							</select>
						</div>
						<div class="col-12 col-md-3 form-group" *ngIf="rest.current_permission.add_user">
							<label>Agente</label>
							<select class="form-control" [(ngModel)]="user.created_by_user_id" name="created_by_user_id"  required>
								<option value="null" [selected]="user.created_by_user_id==null">Seleccionar</option>
								<option *ngFor="let agent of agents" [value]="agent.id">{{agent.name}}</option>
							</select>
						</div>

					</div>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
		<h2 class="my-3">Datos complementarios</h2>
		<div class="card p-3">
			<div class="row">
				<div class="col-12 col-md-4">
					<label>Empresa donde labora</label>
					<input type="text" name="job_name" placeholder="" class="form-control" id="job_name" [(ngModel)]="user.job_name" >
				</div>
				<div class="col-12 col-md-4">
					<label>Dirección de empresa</label>
					<input type="text" name="job_address" placeholder="" class="form-control" id="job_address" [(ngModel)]="user.job_address" >
				</div>
				<div class="col-12 col-md-4">
					<label>Teléfono de empresa</label>
					<input type="text" name="job_phone" placeholder="" class="form-control" id="job_phone" [(ngModel)]="user.job_phone" >
				</div>
				<div class="col-12 col-sm">
					<label>Cumpleaños</label>
					<input type="date" name="birthday" placeholder="" class="form-control" id="birthday" [(ngModel)]="user.birthday" >
				</div>
				<div class="col-12 col-sm">
					<label>Preferencia de cobro en</label>
					<select name="payment_option" class="form-control" [(ngModel)]="user.payment_option">
						<option value="ADDRESS">Dirección</option>
						<option value="TRANSFER">Transferencia</option>
						<option value="STORE">Sucursal</option>
					</select>
				</div>
				<div class="col-12 col-sm" *ngIf="user.payment_option == 'ADDRESS'">
					<label>Dirección de cobro</label>
					<select name="payment_address_id" class="form-control" [(ngModel)]="user.payment_address_id" [required]="user.payment_option == 'ADDRESS'">
						<ng-container *ngFor="let address of address_list">
							<option [value]="address.id">{{address.name}}</option>
						</ng-container>
					</select>
				</div>
			</div>
		</div>
		<ng-container *ngIf="rest.local_preferences.user_attachments != 'DISABLED'">
			<h2 class="my-3">Archivos</h2>
			<div class="card p-3">
				<div class="row">
					<div class="col-12 col-md-4">
						<label>Alias</label>
						<input type="text" class="form-control" placeholder="" id="alias" name="alias" [(ngModel)]="new_user_attachment.alias">
					</div>
					<div class="col-12 col-md-4">
						<label>Subir Archivo</label>
						<input type="file" class="form-control" name="attachment" (change)="setUserFile($event)" [(ngModel)]="new_file">
					</div>
					<div class="col-12 col-md-4">
						<label>&nbsp;</label>
						<button type="button" class="btn btn-primary w-100" (click)="uploadUserFile($event)">Subir</button>
					</div>
				</div>
				<ng-container *ngFor="let user_attachment of user_attachments_list">
					<span class="d-block my-3">
						<a [href]="rest.getFilePath(user_attachment.id)" target="_blank">{{user_attachment.alias}}</a>
						<span class="text-danger" class="" style="cursor: pointer;" (click)="deleteUserFile(user_attachment)">
							<svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 24 24"><path fill="red" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/></svg>
						</span>
					</span>
				</ng-container>
			</div>
		</ng-container>
	</form>
</div>
