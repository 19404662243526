import { Component, OnInit, Provider } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { Currency_Rate, Purchase, Store, User } from '../../models/RestModels';
import {PurchaseDetailInfo, PurchaseInfo, ShippingItemInfo, ShippingInfo} from 'src/app/models/models';
import {forkJoin} from 'rxjs';
import {Utils} from 'src/app/classes/Utils';
import {mergeMap} from 'rxjs/operators';

@Component({
	selector: 'app-list-purchase',
	templateUrl: './list-purchase.component.html',
	styleUrls: ['./list-purchase.component.css']
})

export class ListPurchaseComponent extends BaseComponent implements OnInit
{
	file:File = null;
	show_import:boolean = false;
	purchase_search:SearchObject<Purchase> = this.getEmptySearch();
	purchase_info_list:PurchaseInfo[] = [];
	currency_rate_list:Currency_Rate[] = [];
	provider_list:User[] = [];
	store_list: Store[];
	store_dictionary: Record<number, Store> = {};

	shipping_guide:string = '';
	shipping_date:string = '';
	shipping_company:string = '';
	search_start:string = '';
	search_end:string = '';
	selected_purchase_info: PurchaseInfo | null;
	show_create_shipping:boolean = false;
	mark_as_sent:boolean = false;

	ngOnInit()
	{
		this.path = '/list-purchase';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			this.is_loading = true;

			let fields = [ "provider_user_id","created","store_id" ];
			let extra_keys = ["paid_status"];
			this.purchase_search = this.getSearch(queryParamMap, fields, extra_keys );
			this.purchase_search.sort_order = ['id_DESC'];
			this.purchase_search.eq.status = 'ACTIVE';

			let start = new Date();
			let end = new Date();

			if(!queryParamMap.has('ge.created'))
			{
				start.setDate(1);
				start.setHours(0,0,0,0);
				this.purchase_search.ge.created = start;
			}
			this.search_start = Utils.getLocalMysqlStringFromDate(this.purchase_search.ge.created);

			if(!queryParamMap.has('le.created'))
			{
				let end_date = Utils.getEndOfMonth(end);
				end_date.setHours(23,59,0);
				this.purchase_search.le.created = end_date;
			}

			this.search_end = Utils.getLocalMysqlStringFromDate(this.purchase_search.le.created);
			this.setTitle('Ordenes de compra');
			this.current_page = this.purchase_search.page;

			this.subs.sink = forkJoin
			({
				purchase_info: this.rest.purchase_info.search( this.purchase_search ),
				store: this.rest.getStores(true),
				currency_rate: this.rest.getCurrencyRates(),
				providers: this.rest.user.search({eq:{type: 'USER'}, search_extra:{'user_permission.is_provider':1}})
			})
			.subscribe((response)=>
			{
				this.setPages( this.purchase_search.page, response.purchase_info.total );
				this.store_list = response.store.data;
				this.provider_list = response.providers.data;

				for( let purchase_info of response.purchase_info.data )
				{
					purchase_info.purchase.total = this.getPurchaseInfoTotal(purchase_info);
				}

				this.purchase_info_list = response.purchase_info.data;

				this.currency_rate_list = response.currency_rate.data;
				this.is_loading = false;
				this.store_dictionary = Utils.createDictionary(response.store.data, 'id');
			},(error)=>this.showError(error));
		});
	}

	// getExchangeRate(currency_id:string, purchase_info:PurchaseInfo):number
	// {
	//	if( purchase_info.bill.currency_id == currency_id )
	//		return 1;

	//	let store = this.store_dictionary[purchase_info.purchase.store_id];
	//	let default_currency_id = store.default_currency_id;

	//	let currency_rate = this.currency_rate_list.find((r:Currency_Rate)=>
	//	{
	//		if( r.store_id != purchase_info.purchase.store_id )
	//			return false;

	//		if( purchase_info.bill.currency_id == default_currency_id )
	//		{
	//			return r.currency_id == currency_id;
	//		}

	//		return r.currency_id == purchase_info.bill.currency_id;
	//	});

	//	return default_currency_id == purchase_info.bill.currency_id
	//		? currency_rate.rate
	//		: 1/currency_rate.rate;
	// }

	addToStock(purchase_info:PurchaseInfo)
	{
		this.subs.sink = this.confirmation.showConfirmAlert
		(
			purchase_info,
			'Agregar Inventario',
			'La orden de compra se agregara al inventario actual de la sucursal '+this.store_dictionary[purchase_info.purchase.store_id].name+', esta seguro que desea continuar',
		)
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.subs.sink = this.rest.addPurchaseToStock(purchase_info.purchase.id).subscribe((response)=>
				{
					this.showSuccess('El inventario se agrego con exito');
					purchase_info.purchase.stock_status = 'ADDED_TO_STOCK';
				},(error)=>this.showError(error));
			}
		})
	}

	markAsSentChange(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		this.mark_as_sent = input.checked;
	}

	showCreateShipping( purchase_info:PurchaseInfo )
	{
		this.selected_purchase_info = purchase_info;
		this.show_create_shipping = true;
	}

	createShipping(evt:Event)
	{
		this.rest.purchase_info.get( this.selected_purchase_info.purchase.id ).pipe
		(
			mergeMap((pi:PurchaseInfo)=>
			{
				let shipping_info:ShippingInfo = {
					shipping: {
						to_store_id: pi.purchase.store_id,
						from_store_id: null,
						shipping_guide:this.shipping_guide,
						shipping_company:this.shipping_company,
						date: this.shipping_date,
						purchase_id: pi.purchase.id
					},
					items: []
				};

				pi.details.forEach((pdi:PurchaseDetailInfo)=>
				{
					let si:ShippingItemInfo = {
						shipping_item:
						{
							item_id: pdi.purchase_detail.item_id,
							qty: pdi.purchase_detail.qty,
							serial_number: pdi.purchase_detail.serial_number,
							received_qty: 0
						}
					};

					shipping_info.items.push(si);
				});

				return this.rest.shipping_info.create( shipping_info );
			})
		)
		.subscribe((response)=>
		{
			this.selected_purchase_info.purchase.stock_status = 'SHIPPING_CREATED';
			this.showSuccess('El envio se registro con exito')
			if( this.mark_as_sent )
			{
				this.subs.sink = this.rest.update
				(
					'markShippingAsSent',
					{shipping_id:response.shipping.id}
				)
				.subscribe(()=>
				{
					this.showSuccess('El envio se marco como enviado');
					this.show_create_shipping = false;
				},(error)=>
				{
					this.showSuccess('El envio se registro con exito pero no se pudo marcar como enviado');
					this.showError(error);
					this.mark_as_sent = false;
					this.show_create_shipping = false;
				})
			}
			else
			{
				this.show_create_shipping = false;
				this.mark_as_sent = false;
				this.show_create_shipping = false;

				let form = evt.target as HTMLFormElement;
				form.reset();

			}
		},(error)=>this.showError(error));
	}

	getPurchaseInfoTotal(purchase_info:PurchaseInfo):number
	{
		let total = 0;

		for( let detail of purchase_info.details )
		{
			total += (detail.purchase_detail.unitary_price*detail.purchase_detail.qty);
		}

		return total;
	}
}
