import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, of } from 'rxjs';

import {Offer} from '../../models/RestModels';
import {Item} from '../../models/RestModels';
import {User} from '../../models/RestModels';
import {Category} from '../../models/RestModels';
import {Store} from '../../models/RestModels';
import { GetEmpty } from 'src/app/classes/Empties';
import { mergeMap } from 'rxjs/operators';


@Component({
	selector: 'app-save-offer',
	templateUrl: './save-offer.component.html',
	styleUrls: ['./save-offer.component.css']
})
export class SaveOfferComponent extends BaseComponent implements OnInit {

	offer:Offer = GetEmpty.offer();
	item_list:Item[] = [];
	user_list:User[] = [];
	category_list:Category[] = [];
	store_list:Store[] = [];

	ngOnInit()
	{
		this.route.paramMap.pipe
		(
			mergeMap((response)=>
			{
				let obs = response.has('id')
					? this.rest.offer.get( response.get('id') )
					: of( GetEmpty.offer() );

				return forkJoin
				({
					offer : obs,
					item : this.rest.item.search({limit:9999}),
					user : this.rest.user.search({limit:9999}),
					category : this.rest.category.search({limit:9999}),
					store : this.rest.getStores( true )
				});
			})
		)
		.subscribe((responses)=>
		{
			this.offer= responses.offer;
			this.item_list = responses.item.data;
			this.user_list = responses.user.data;
			this.category_list = responses.category.data;
			this.store_list = responses.store.data;
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	save()
	{
		this.is_loading = true;

		if( this.offer.id	)
		{
			this.subs.sink	= this.rest.offer.update( this.offer ).subscribe((offer)=>{
				this.is_loading = false;
				this.showSuccess('offer se actualizo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.offer.create( this.offer ).subscribe((offer)=>{
				this.is_loading = false;
				this.showSuccess('offer se guardo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
	}

	toggleValue(key:any)
	{
		if( this.offer[key] )
		{
			this.offer[key] = 0;
		}
		else
		{
			this.offer[key] = 1;
		}
	}
}
