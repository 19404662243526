import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Store, User, User_Permission } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-save-provider',
	templateUrl: './save-provider.component.html',
	styleUrls: ['./save-provider.component.css']
})
export class SaveProviderComponent extends BaseComponent implements OnInit
{
	user:Partial<User>	= {};
	user_permission:User_Permission = GetEmpty.user_permission();
	store_list:Store[] = [];
	password_type:'text'|'password' = 'password';

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.subscribe( params =>
		{
			this.is_loading = true;
			let defaultUser:Partial<User> = {
				name: '',
				store_id: undefined,
				type:'USER',
				credit_days: 0,
				credit_limit: 0,
				price_type_id: undefined,
			};

			let user_permission = GetEmpty.user_permission();
			user_permission.is_provider = 1;

			this.subs.sink = forkJoin({
				user: params.has('id') ? this.rest.user.get(params.get('id')) : of(defaultUser),
				stores: this.rest.store.search({limit:99999,sort_order:['name_ASC']}),
				user_permission: params.has('id') ? this.rest.user_permission.get( params.get('id') ) : of( user_permission ),

			}).subscribe((response)=>
			{
				if( response.user_permission.is_provider )
				{
					this.is_loading = false;
					this.user		= response.user;
					this.store_list	= response.stores.data;
					this.user_permission = response.user_permission;
				}
				else
				{
					this.user = {type:'USER',name:'',credit_days: 0, credit_limit: 0};
					this.showError('NO se encontrol el proveedor');
				}
			});
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.user.id	)
		{
			this.user.username = this.user.name;
			this.subs.sink	= this.rest.user.update( this.user )
			.subscribe((_response)=>
			{
				this.is_loading = false;
				this.location.back();
				this.showSuccess('El usuario se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.user.name;// = this.user.username;
			this.user.username = this.user.email;
			this.subs.sink	= this.rest.user
			.create( this.user ).pipe
			(
				mergeMap((response)=>
				{
					this.showSuccess('El Usuario se guardó exitosamente');
					this.user_permission.user_id = response.id;
					this.user_permission.is_provider = 1;
					return this.rest.user_permission.update( this.user_permission )
				})
			)
			.subscribe((_user)=>
			{
				this.showSuccess('El usuario se asigno el rol de proveedor exitosamente');
				this.is_loading = false;
				this.location.back();
			},(error)=>this.showError(error));
		}
	}
}
