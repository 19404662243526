import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Utils } from 'src/app/classes/Utils';
import { OrderInfo, PaymentInfo } from 'src/app/models/models';
import { Fund, Order, Price_Type, Store, Store_Sale_Report } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface Category_Resume
{
	category_name:string;
	qty:number;
	total:number;
}

interface Tax_Resume
{
	name: string;
	qty: number;
	total: number;
}

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	checked:boolean;
	paid_cash:boolean;
	paid_card:boolean;
	paid_transfer:boolean;
	paid_check:boolean;
	paid_other:boolean;
	price_type_name:string;
	disabled:boolean;
}

interface PaymentsResume
{
	amount: number;
	payment_type: string;
	transaction_type: string;
	currency_id: string;
	name: string;
}

@Component
({
	selector: 'app-create-sales-report',
	templateUrl: './create-sales-report.component.html',
	styleUrls: ['./create-sales-report.component.css']
})

export class CreateSalesReportComponent extends BaseComponent implements OnInit
{
	order_total:number = 0;
	total_facturacion: number = 0;
	order_search:SearchObject<Order> = this.getEmptySearch();
	corte_date:string = '';
	fecha_inicial: string = '';
	fecha_final: string ='';
	cashier_user_list: any[];
	price_type_list: Price_Type[] = [];
	order_info_list: CustomOrderInfo[] = [];
	store_list: Store[] = [];
	user_store: Store = GetEmpty.store();
	payment_info_list: PaymentInfo[] = [];
	all_checked: boolean = false;
	last_sort:string = '';
	is_sort_ascending:boolean = true;
	funds:Fund[] = [];
	custom_range: boolean= false;

	payment_resume_list:PaymentsResume[] = [];
	category_resume_list:Category_Resume[] = [];
	tax_resume:Tax_Resume[] = [];
	total_order_discount:number = 0;

	headers_classes = {
		'col-2':true,
		'col-md-2':true,
		'col-3': false,
		'col-md-3':false,
		'text-end': true
	};
	percentage_to_select: number = 100;

	ngOnInit(): void
	{
		this.path = '/create-sales-report';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				this.total_order_discount = 0;
				let query_params = response[0];
				let param_maps = response[1];

				let fields = [
					'store_id','status','type',
					'paid_status','closed_timestamp'
				];

				let extra_keys:Array<string> = [
					'publico_general','advanced_search',
					'with_discounts','date','custom_range'
				];

				this.setTitle('Corte por sucursal');
				this.is_loading = true;
				this.current_page = 0;


				let order_search:SearchObject<Order> = this.getSearch(query_params, fields, extra_keys);
				order_search.eq.status ='CLOSED';
				order_search.limit = this.page_size;
				order_search.sort_order = ['id_DESC'];
				order_search.limit = 999999;

				if( !(order_search.eq.price_type_id ) )
					order_search.eq.price_type_id = null;

				if( order_search.search_extra.publico_general )
				{
					order_search.is_null = ['client_user_id']
					order_search.eq.price_type_id = 1;
				}
				else
				{
					order_search.is_null = [];
				}

				if( !query_params.has('eq.store_id') )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
				}

				if( !this.rest.current_permission.global_pos )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
				}

				this.custom_range = query_params.get('search_extra.custom_range') != null;
				console.log('Order Search', order_search );

				let date_start = new Date();
				date_start.setHours( 0, 0, 0, 0);


				if( !order_search.search_extra.date )
				{
					order_search.search_extra.date = Utils.getLocalMysqlStringFromDate( date_start ).substring(0,10);
				}
				else
				{
					date_start = Utils.getDateFromLocalMysqlString( order_search.search_extra.date as string);
				}

				//*
				//XXX Toggle functionality
				//


				//*/
				//XXX Esto funciona
				//Por lo pronto no tiene pero despues poisblemente


				if( this.custom_range )
				{
					if( !order_search.ge.closed_timestamp )
					{
						order_search.ge.closed_timestamp= date_start;
					}

					if( !order_search.le.closed_timestamp )
					{
						let end = new Date();
						end.setTime( order_search.ge.closed_timestamp.getTime() );
						end.setHours( order_search.ge.closed_timestamp.getHours()+23,59,59 );
						order_search.le.closed_timestamp = end;
					}


					let fecha_fin = new Date();
					fecha_fin.setTime( order_search.le.closed_timestamp.getTime() );
					fecha_fin.setSeconds( 0 );

					this.fecha_inicial = Utils.getLocalMysqlStringFromDate( order_search.ge.closed_timestamp ).replace(' ','T');
					this.fecha_final = Utils.getLocalMysqlStringFromDate( fecha_fin ).replace(' ','T');
				}
				else
				{
					this.fecha_inicial = Utils.getLocalMysqlStringFromDate( date_start ).substring(0,16);

					let end = new Date();
					end.setTime( date_start.getTime() );
					end.setHours( 23,59,59 );

					order_search.ge.closed_timestamp = date_start;
					order_search.le.closed_timestamp = end;
				}
				//*/

				this.order_search = order_search;

				return this.rest.order_info.search(this.order_search);
			}),
			mergeMap((order_data)=>
			{
				let tmp_total = order_data.data.reduce((p,c)=>p+c.order.total,0);
				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, eq:{sales_enabled:1}, sort_order:['name_ASC']});

				return forkJoin
				({
					order : of( order_data ),
					store : store_obs,
					price_type : this.rest.getPriceTypes(),
					users: this.rest.is_offline
						? of({data:[], total: 0})
						: this.rest.user.search({limit:9999, eq:{type:'USER'},sort_order:['name_ASC']}),
					order_total: this.rest.is_offline
						? of({total:tmp_total,data:[]})
						: this.rest.getOrderTotal(this.order_search),
					funds: this.rest.fund.search
					({
						ge:{ created: this.order_search.ge.closed_timestamp },
						le:{ created: this.order_search.le.closed_timestamp },
						limit: 99999
					})
					//offline_orders: from( this.rest.getOfflineOrders() )
				})
			}),
			mergeMap((response)=>
			{
				let ids = response.order.data.map(i=>i.order.id);
				let order_ids :string = ids.join(',');

				let payment_search =
				{
					search_extra:{ order_id:order_ids },
					limit: 9999999
				};

				return forkJoin
				({
					payments: ids.length ==0
						? of({data:[],total:0})
						: this.rest.payment_info.search( payment_search ),
					order : of( response.order ),
					store : of( response.store ),
					price_type : of( response.price_type),
					users: of(response.users),
					order_total: of( response.order_total),
					funds: of( response.funds )
				})
			})
		)
		.subscribe((responses)=>
		{
			this.headers_classes['col-md-2'] = !!(this.rest.current_permission.hades);
			this.headers_classes['col-md-3'] = !this.rest.current_permission.hades;

			this.is_loading = false;
			this.payment_info_list = responses.payments.data;

			let category_map:Map<string,Category_Resume> = new Map();
			let tax_resume_map:Map<string,Tax_Resume> = new Map();

			let total_order_discount:number = 0;

			//Esto en teoria esta mal tenemos que sumar los Normales y
			//Restar los que regresan.

			for(let oi of responses.order.data)
			{
				for(let ii of oi.items )
				{
					if(!( ii.order_item.total && ii.order_item.type == 'NORMAL') )
					{
						continue;
					}

					let cr = ii.category == null
						? category_map.get('S/C')
						: category_map.get(ii.category.name );

					if( cr == null )
					{
						cr = {
							category_name: ii.category ? ii.category.name : 'S/C',
							qty: 0,
							total: 0
						};
						category_map.set( cr.category_name, cr );
					}

					cr.total += ii.order_item.total;
					cr.qty += ii.order_item.qty;

					let tr_name = ii.item.applicable_tax == 'EXEMPT'
						? 'Excento'
						: ''+oi.order.tax_percent;

					let tr:Tax_Resume | null = tax_resume_map.get( tr_name ) || null;

					if( tr == null )
					{
						tr = { name :tr_name, qty:0, total:0 };
						tax_resume_map.set( tr_name, tr);
					}

					tr.qty += ii.order_item.qty;
					tr.total += ii.order_item.tax;
				}

				this.total_order_discount += oi.order.discount;
			}

			let payment_resume_list = [];

			for(let pi of this.payment_info_list)
			{
				for(let m of pi.movements)
				{
					let pr = payment_resume_list.find(i=>{
						return i.transaction_type == m.bank_movement.transaction_type &&
							i.currency_id == m.bank_movement.currency_id ;
					});

					let name = this.getName
					(
						m.bank_movement.currency_id,
						m.bank_movement.transaction_type
					);

					if( pr === undefined )
					{
						pr = {
							transaction_type : m.bank_movement.transaction_type,
							currency_id	: m.bank_movement.currency_id,
							name,
							amount: 0
						}
						payment_resume_list.push( pr );
					}
					pr.amount += m.bank_movement.total;
				}
			}

			payment_resume_list.sort((a,b)=>{
				return a.name > b.name ? 1: -1;
			});
			this.payment_resume_list = payment_resume_list;


			this.store_list = responses.store.data;
			this.cashier_user_list = responses.users.data;
			this.price_type_list	= responses.price_type.data;
			//this.order_total		= responses.order_total.total;
			this.order_total		= responses.order.data.reduce((p,oi)=>p+oi.order.total-oi.order.discount,0);
			this.funds				= responses.funds.data;

			this.user_store = this.store_list.find((i)=>i.id==this.rest?.current_user.store_id) || GetEmpty.store();
			this.order_info_list = responses.order.data.map(oi=> this.getCustomOrderInfo(oi));
			if( !this.rest.current_permission.hades )
			{
				this.toggleAll();
			}
			this.updateTotal();
			//this.setPages( this.order_search.page, responses.order.total );
		},(error)=>this.showError(error));
	}


	testPaymentMethod(method:string,payment_info:PaymentInfo,order_id:number)
	{
		return payment_info.movements.some((bm)=>
		{
			if( bm.bank_movement_orders.some(bmo=>bmo.order_id == order_id) )
			{
				return bm.bank_movement.transaction_type == method;
			}
			return false;
		});
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let payments = this.payment_info_list.filter(payment=>
		{
			return payment.movements.some((bm)=>
			{
				return bm.bank_movement_orders.some(bmo=>bmo.order_id == order_info.order.id);
			});
		});

		let paid_cash = payments.some(pi=>this.testPaymentMethod('CASH',pi,order_info.order.id));
		let paid_card = payments.some(pi=>this.testPaymentMethod('CREDIT_CARD',pi,order_info.order.id)) ||
			payments.some(pi=>this.testPaymentMethod('DEBIT_CARD',pi,order_info.order.id));

		let paid_transfer = payments.some(pi=>this.testPaymentMethod('TRANSFER',pi,order_info.order.id));
		let paid_check = payments.some(pi=>this.testPaymentMethod('CHECK',pi,order_info.order.id));
		let paid_other = payments.some(pi=>this.testPaymentMethod('PAYPAL',pi,order_info.order.id));

		let disabled =	paid_card|| paid_transfer || order_info.order.facturado == 'YES';
		let checked	=	!( order_info.order.facturado == 'YES');

		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			//Discount must be with the tax included

			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let price_type = this.price_type_list.find(pt=>pt.id == order_info.order.price_type_id );

		return {
			...order_info,
			article_discount,
			paid_cash,
			paid_card,
			paid_transfer,
			paid_check,
			paid_other,
			disabled,
			price_type_name: price_type?.name || '',
			checked
		}
	}

	updateTotal()
	{
		this.total_facturacion = this.order_info_list.reduce((p,oi)=>
		{
			if( !oi.checked )
				return p;
			return p+oi.order.total-oi.order.discount
		},0);
	}

	clickedOnOrder(coi:CustomOrderInfo)
	{
		coi.checked= !coi.checked;
		this.all_checked= !this.order_info_list.some(oi=>!oi.checked);
		this.updateTotal();
	}

	toggleAll()
	{
		this.all_checked= !this.order_info_list.some(oi=>!oi.checked);

		this.all_checked = !this.all_checked;

		for(let oi of this.order_info_list)
		{
			if( oi.disabled )
			{
				continue;
			}

			oi.checked = this.all_checked;
		}

		this.updateTotal();
	}

	last_payment_sort:string[] = 'CASH,CARD,CHECK,TRANSFER,OTHER'.split(',');

	sortBy(type:string)
	{
		if( this.last_sort == type )
		{
			this.is_sort_ascending = !this.is_sort_ascending;
			if( this.last_payment_sort )
			{
				let last = this.last_payment_sort.shift();
				this.last_payment_sort.push( last );
			}
		}
		else
		{
			this.is_sort_ascending = true;
			this.last_sort = type;
		}

		if( this.last_sort == 'Total' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.order.total-a.order.discount;
				let bb = b.order.total-b.order.discount;
				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return bb>aa ?1:-1;
			});
			return;
		}

		if( this.last_sort == 'Payment' )
		{
			let index_multiplier = [100000,10000,1000,100,10,1];

			let cash = index_multiplier[ this.last_payment_sort.indexOf('CASH') ];
			let check = index_multiplier[ this.last_payment_sort.indexOf('CHECK') ];
			let txt = index_multiplier[ this.last_payment_sort.indexOf('TRANSFER') ];
			let card = index_multiplier[ this.last_payment_sort.indexOf('CARD') ];
			let other = index_multiplier[ this.last_payment_sort.indexOf('OTHER') ];

			let sum_values = (a:CustomOrderInfo)=>{
				return ( a.paid_cash ? cash : 0 ) +
					( a.paid_check ? check : 0 )+
					( a.paid_card ? card : 0 )+
					( a.paid_transfer ? txt : 0 )+
					( a.paid_other ? other : 0 );
			};

			this.order_info_list.sort((a,b)=>
			{
				let asum = sum_values(a);
				let bsum = sum_values(b);

				return bsum > asum ? 1 : -1;
			});
			return;
		}

		if( this.last_sort == 'Client' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.order.client_name < a.order.client_name ? 1:-1;
				}
				return a.order.client_name < b.order.client_name ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Price_type' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.price_type_name < a.price_type_name ? 1:-1;
				}
				return a.price_type_name < b.price_type_name ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Id' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return b.order.id < a.order.id ? 1:-1;
				}
				return a.order.id < b.order.id ? 1:-1;
			});
			return;
		}

		if( this.last_sort == 'Date' )
		{
			this.order_info_list.sort((a,b)=>
			{
				if( this.is_sort_ascending )
				{
					return a.order.created > b.order.created ? 1: -1;
				}
				return a.order.created < b.order.created ? 1: -1;
			});
			return;
		}

		if( this.last_sort == 'Selected' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.checked ? 1:0;
				let bb = b.checked ? 1:0;

				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return aa<bb ?1:-1;
			});
			return;
		}

		if( this.last_sort == 'Cashier' )
		{
			this.order_info_list.sort((a,b)=>
			{
				let aa = a.order.total-a.order.discount;
				let bb = b.order.total-b.order.discount;
				if( this.is_sort_ascending )
				{
					return aa>bb ?1:-1;
				}
				return bb>aa ?1:-1;
			});
			return;
		}
	}

	save(evt:Event)
	{
		console.log('FOOOO');
		evt.preventDefault();
		let total =	this.total_facturacion;
		let filtered_orders = this.order_info_list.filter(i=>!this.rest.has_hades || i.checked );

		if( filtered_orders.length == 0 )
		{
			this.showError('Por favor seleccione al menos una orden');
			evt.preventDefault();
			evt.stopPropagation();
			return;
		}

		let ares = this.order_info_list.filter(i=>!i.checked && this.rest.has_hades );
		let discounts = filtered_orders.reduce((p,oi)=>p + oi.order.discount,0);
		let average_order_amount = total/filtered_orders.length;

		let end_timestamp = this.order_search.le.closed_timestamp|| new Date();

		let store_sale_report:Store_Sale_Report = {
			average_order_amount,
			amount_description: 'asdfasd fasdf kajs;l',
			created: this.order_search.ge.closed_timestamp,
			created_by_user_id: this.rest.current_user.id,
			date: this.order_search.search_extra.date as string,
			discounts,
			end_timestamp,
			expense_payments: 0,
			id: 0,
			income_payments: 0,
			localtime_end: Utils.getMysqlStringFromLocalDate( this.order_search.le.closed_timestamp ),
			localtime_start: Utils.getMysqlStringFromLocalDate( this.order_search.ge.closed_timestamp ),
			order_count: filtered_orders.length,
			order_ids:	filtered_orders.map(i=>i.order.id).join(','),
			ares_order_ids: ares.map(i=>i.order.id).join(','),
			start_timestamp: this.order_search.ge.closed_timestamp,
			store_consecutive: 0,
			store_id: this.order_search.eq.store_id,
			total_sales: filtered_orders.length,
			updated: new Date(),
			updated_by_user_id: this.rest.current_user.id
		};

		this.subs.sink = this.rest.store_sale_report
		.create( Utils.transformDatesToString( store_sale_report) )
		.subscribe((response)=>
		{
			this.router.navigate(['/view-sales-report',response.id]);
		},(error)=>this.showError(error));
	}

	getName(currency_id:string, transaction_type:string):string
	{
		if( transaction_type == 'CASH' )
		{
			if( currency_id == 'USD' )
			{
				return 'Dolares';
			}

			return 'Efectivo';
		}

		if( transaction_type == 'CREDIT_CARD' )
		{
			return 'T. Credito';
		}

		if( transaction_type == 'DEBIT_CARD' )
		{
			return 'T. Debito';
		}

		if( transaction_type == 'CHECK' )
		{
			return 'Cheque';
		}

		if( transaction_type == 'TRANSFER' )
		{
			return 'Transferencia';
		}

		return transaction_type+' '+currency_id;
	}

	fechaIncialChange(fecha:string)
	{
		this.fecha_inicial = fecha;

		if( !fecha )
		{
			return;
		}

		this.order_search.ge.closed_timestamp = Utils.getDateFromLocalMysqlString(fecha);

		let end = new Date();
		end.setTime( this.order_search.ge.closed_timestamp.getTime() );
		end.setHours( this.order_search.ge.closed_timestamp.getHours()+24 );
		end.setSeconds( this.order_search.ge.closed_timestamp.getSeconds()-1 );

		this.order_search.le.closed_timestamp = new Date();
		this.order_search.le.closed_timestamp.setTime( end.getTime() );

		end.setSeconds( 0 );
		this.fecha_final = Utils.getLocalMysqlStringFromDate( end ).substring(0,16);
		console.log('Segtgint dates start', this.order_search.ge.closed_timestamp);
		console.log('EDN', end );
	}

	fechaFinalChange(fecha:string)
	{
		this.fecha_final = fecha;

		if( !fecha )
		{
			return;
		}

		this.order_search.le.closed_timestamp = Utils.getDateFromLocalMysqlString(fecha);
		this.order_search.le.closed_timestamp.setSeconds(59);
	}

	fechaPricipalChanges(value: string)
	{
		let d = Utils.getDateFromLocalMysqlString( value );
		console.log('Value is', value, d );
		let start = new Date();
		let end = new Date();

		if( d )
		{
			start.setTime( d.getTime() );
			start.setHours( 0, 0, 0, 0 );

			end.setTime( start.getTime() );
			end.setHours( 23, 59, 59, 0 );

			this.order_search.ge.closed_timestamp.setTime( start.getTime() );
			this.order_search.le.closed_timestamp.setTime( end.getTime() );
		}
		//2024-01-01 00:00
		this.fecha_inicial	= Utils.getLocalMysqlStringFromDate( this.order_search.ge.closed_timestamp ).substring(0,16);
		this.fecha_final	= Utils.getLocalMysqlStringFromDate( this.order_search.le.closed_timestamp ).substring(0,16);
	}

	onCustomRangeClick(evt: MouseEvent)
	{
		let input = evt.target as HTMLInputElement;
		this.custom_range = input.checked;
		this.order_search.search_extra['custom_range'] = input.checked ? 1 : 0;

		if( !this.custom_range )
		{
			let d = Utils.getDateFromLocalMysqlString( this.order_search.search_extra.date as string );
			let start = new Date();
			let end = new Date();

			if( d )
			{
				start.setTime( d.getTime() );
				start.setHours( 0, 0, 0, 0 );

				end.setTime( start.getTime() );
				end.setHours( 23, 59, 59, 0 );

				this.order_search.ge.closed_timestamp.setTime( start.getTime() );
				this.order_search.le.closed_timestamp.setTime( end.getTime() );
			}
			//2024-01-01 00:00
			this.fecha_inicial	= Utils.getLocalMysqlStringFromDate( this.order_search.ge.closed_timestamp ).substring(0,16);
			this.fecha_final	= Utils.getLocalMysqlStringFromDate( this.order_search.le.closed_timestamp ).substring(0,16);
		}
	}

	selectPercentage()
	{
		let selected = [];
		let trys = 0;
		let list_length = this.order_info_list.length;

		console.log('WTF WITH THIS');

		for(let oi of this.order_info_list)
		{
			if( oi.disabled )
				continue;

		//	let checked	=	!( order_info.order.facturado == 'YES');
			oi.checked = false;
		}

		while( trys <= list_length*2 )
		{
			console.log('IS IN');
			let x = Math.floor( Math.random() * list_length );

			console.log('Tryin here');

			trys++;

			if( selected.includes(x)  || this.order_info_list[x].disabled )
			{
				console.log('Fails miserably');
				continue;
			}

			trys = 0;

			selected.push(x);
			let p = this.getSelectedPercentage(selected);

			if( p >= this.percentage_to_select )
			{
				break;
			}

			//Si se pasa de la cantidad de elementos
			//Entonces tronamos
		}
		console.log('IS OUT');

		for(let i of selected)
		{
			this.order_info_list[i].checked = true;
		}

		this.updateTotal();
	}

	getSelectedPercentage(indexes:number[]):number
	{
		let total = 0;
		let total_selected = 0;

		let index = 0;

		for(let oi of this.order_info_list)
		{
			if( !oi.paid_cash )
			{
				index++;
				console.log('paid cash','F'+JSON.stringify(oi.paid_cash));
				continue;
			}

			if( !oi.disabled )
			{
				total += oi.order.total-oi.order.discount;
			}

			if( indexes.includes(index) )
			{
				total_selected += oi.order.total-oi.order.discount;
			}
			index++;
		}

		console.log('total_selected',total_selected/total*100);

		return (total_selected/total*100);
	}

	getSelectedPercentageCash(indexes:number[]):number
	{
		let total = 0;
		let total_selected = 0;

		let index = 0;

		for(let oi of this.order_info_list)
		{
			if( !oi.paid_cash )
			{
				index++;
				console.log('paid cash','F'+JSON.stringify(oi.paid_cash));
				continue;
			}

			if( !oi.disabled )
				total += oi.order.total-oi.order.discount;

			if( indexes.includes(index) )
			{
				total_selected += oi.order.total-oi.order.discount;
			}
			index++;
		}

		console.log('total_selected',total_selected/total*100);

		return (total_selected/total*100);
	}
}
