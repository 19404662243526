import { Component, OnInit, EventEmitter,Output,Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {RestService} from 'src/app/services/rest.service';
import {ItemInfo} from 'src/app/models/models';
import {SubSink} from 'subsink';
import { KeyboardShortcutEvent, ShortcutsService } from 'src/app/services/shortcuts.service';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface CItemInfo extends ItemInfo
{
	display_category:boolean;
}

@Component({
	selector: 'app-search-items',
	templateUrl: './search-items.component.html',
	styleUrls: ['./search-items.component.css']
})
export class SearchItemsComponent implements OnInit,OnDestroy,OnChanges{

	@ViewChild('item_search', { read: ElementRef }) item_search:ElementRef;
	@Input() update_focus:number = 0;
	item_info_list:CItemInfo[] = [];
	@Input() search_str:string = '';
	@Output() search_strChange = new EventEmitter<string>();
	@Input() store_id:number	= null;
	@Input() reset_on_search:boolean = true;
	@Output() item_selected = new EventEmitter<ItemInfo>();
	@Input() reset:number = 0;
	@Input() callfocus:number = 0;

	search_subject = new Subject<string>();

	subs = new SubSink();
	selected_index = -1;

	constructor(public rest:RestService,public shortcuts:ShortcutsService) { }

	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.search_str )
		{
			if( this.search_str == '' )
			{
				this.item_info_list.splice(0,this.item_info_list.length);
			}
		}

		if( changes.update_focus && changes.update_focus.currentValue != changes.update_focus.previousValue )
		{
			this.item_search?.nativeElement?.focus();
		}
	}

	ngOnInit(): void
	{
		this.subs.sink = this.shortcuts.shortcuts.pipe
		(
			filter((evt:KeyboardShortcutEvent)=>
			{
				return evt.is_stopped === false;
			})
		)
		.subscribe
		({
			next:(evt)=>
			{
				this.shortcutHandler(evt);
			},
			error:(_error)=>
			{

			}
		});

		this.subs.sink = this.search_subject
		.pipe
		(
			filter((x)=>
			{
				if( !x )
				{
					this.item_info_list = [];
					return false;
				}
				return true;
			}),
			debounceTime(350),
			mergeMap((response)=>
			{
				return this.rest.item_info.search
				({
					eq:{ status: 'ACTIVE'},
					limit: 50,
					search_extra:{ store_id:this.store_id, category_name: response }
				})
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.item_info_list = response.data.map((ii)=>
				{
					let index = ii.category ? ii.item.name.trim().toLowerCase().indexOf(ii.category.name.trim().toLowerCase()) : -1;
					ii.display_category = ii.category && ii.item.name.trim().toLowerCase().indexOf(ii.category.name.trim().toLowerCase()) >= 0;
					return ii as CItemInfo;
				});

				this.selected_index = 0;
			},
			error:(error)=>
			{

			}
		});
	}

	shortcutHandler(evt: KeyboardShortcutEvent)
	{
		if( this.item_info_list.length )
		{
			switch( evt.shortcut.key_combination )
			{
				case 'Escape':
				{
					if( evt.stopPropagation() )
					{
						this.selected_index = -1;
						this.item_info_list.splice(0,this.item_info_list.length);
					}
					return;
				}
				//case 'ArrowLeft':
				case 'ArrowUp':
				{
					if( this.selected_index > 0 )
					{
						this.selected_index--;
						evt.stopPropagation();
					}
					return;
				}
				case 'ArrowDown':
				{
					if( this.selected_index < this.item_info_list.length -1)
					{
						this.selected_index++;
						console.log('arrow down');
						evt.stopPropagation();
					}
					return;
				}
				case 'Enter':
				{
					this.onItemSelected( this.item_info_list[ this.selected_index ] );
					evt.stopPropagation();
					return;
				}
			}
		}
	}

	ngOnDestroy()
	{
		this.subs.unsubscribe();
	}

	onItemSelected(item_info:ItemInfo)
	{
		this.item_selected.emit( item_info );

		if( this.reset_on_search )
		{
			this.search_str = '';
			this.search_strChange.emit( this.search_str );
		}
		else
		{
			this.search_str = item_info.item.name || '';
			this.search_strChange.emit( this.search_str );
		}
		this.item_info_list.splice(0,this.item_info_list.length );
	}

	keyPressed(_event:any)
	{
		if( this.search_str == '' )
		{
			this.item_info_list = [];
			return;
		}

		this.search_subject.next( ''+this.search_str );
		this.search_strChange.emit( ''+this.search_str );
	}
}
