<div class="container-fluid">
	<div class="row align-items-center mx-0 mb-3">
		<div class="col-8">
			<h1 class="my-3">Envíos</h1>
		</div>
		<div class="col-4 text-end">
			<a class="btn btn-secondary lift" [routerLink]="['/add-shipping']">Agregar Envío</a>
		</div>
	</div>

	<div class="card p-3 shadow">
		<div class="row align-items-center justify-content-center">
			<div class="col-6 col-md-3 form-group">
				<label class="">Número Economico - Guia</label>
				<input type="text" name="shipping_guide" [(ngModel)]="shipping_search.lk.shipping_guide" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Requisición</label>
				<input name="requisition_id" type="number" [(ngModel)]="shipping_search.eq.requisition_id" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Estatus</label>
				<select name="status" [(ngModel)]="shipping_search.lk.status" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option value="PENDING">Pendiente</option>
					<option value="DELIVERED">Recibido</option>
					<option value="SENT">Enviado</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Origen</label>
				<select name="from_store_id" [(ngModel)]="shipping_search.eq.from_store_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Destino</label>
				<select name="to_store_id" [(ngModel)]="shipping_search.eq.to_store_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>

			<div class="col-6 col-md-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(shipping_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>

		<div class="row d-none d-lg-flex pb-3 border-bottom">
			<div class="col-3 fw-bold">Folio - Numero de guía</div>
			<div class="col-2 fw-bold">Origen</div>
			<div class="col-2 fw-bold">Destino</div>
			<div class="col-1 fw-bold text-end">Artículos</div>
			<div class="col-2 fw-bold">Fecha Envío</div>
			<div class="col-2 fw-bold">Recibido</div>
		</div>
		<div class="row border-bottom align-items-center py-1" *ngFor="let si of shipping_info_list">
			<div class="col-12 col-lg-3">
				<a [routerLink]="['/edit-shipping',si.shipping.id]" *ngIf="si.shipping.status != 'DELIVERED' && si.shipping.status != 'SENT' && si.shipping.status != 'CANCELLED' && rest.current_permission.send_shipping && (si.shipping.to_store_id != rest.current_user.store_id || rest.current_permission.global_send_shipping)">
					{{si.shipping.id}} - {{si.shipping.shipping_company}} #{{si.shipping.shipping_guide}}
				</a>
				<a [routerLink]="['/print-shipping',si.shipping.id]" *ngIf="si.shipping.status == 'SENT' && rest.current_permission.send_shipping && (si.shipping.to_store_id != rest.current_user.store_id || rest.current_permission.global_send_shipping)">
					{{si.shipping.id}} - {{si.shipping.shipping_company}} #{{si.shipping.shipping_guide}}
				</a>
				<span *ngIf="si.shipping.status == 'DELIVERED' || si.shipping.status == 'CANCELLED' || si.shipping.to_store_id == rest.current_user.store_id">
					{{si.shipping.id}} - {{si.shipping.shipping_company}} #{{si.shipping.shipping_guide}}
				</span>
			</div>
			<div class="col-6 d-lg-none fw-bold">Origen</div>
			<div class="col-6 col-lg-2">
				<ng-container *ngIf="si.shipping.from_store_id">
					{{store_dictionary[si.shipping.from_store_id].name | titlecase}}
				</ng-container>
			</div>
			<div class="col-6 d-lg-none fw-bold">Destino</div>
			<div class="col-6 col-lg-2">{{store_dictionary[ si.shipping.to_store_id].name | titlecase}}</div>
			<div class="col-6 d-lg-none fw-bold">Artículos</div>
			<div class="col-6 col-lg-1 text-lg-end ">
				<ng-container *ngIf="si.shipping.status != 'SENT'">
					{{qty_by_shipping[ si.shipping.id ] | number }}
				</ng-container>
			</div>
			<div class="col-6 d-lg-none fw-bold">Fecha</div>
			<div class="col-6 col-lg-2">{{si.shipping.date | shortDate}}</div>
			<div class="col-6 col-lg-2">{{si.shipping.delivery_timestamp | shortDate:'full'}}</div>
			<div class="col-12 text-end">
				<a *ngIf="si.shipping.status == 'DELIVERED' || (rest.current_permission.send_shipping && rest.current_user.store_id ==si.shipping.from_store_id) || rest.current_permission.global_send_shipping" [routerLink]="['/print-shipping',si.shipping.id]" class="btn btn-primary me-2">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g fill="none"><path d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></g></svg>
					<span class="d-none d-md-inline">Imprimir</span>
				</a>
				<a *ngIf="si.shipping.status == 'PENDING' && (rest.current_permission.send_shipping || rest.current_permission.global_receive_shipping)" [routerLink]="['/edit-shipping',si.shipping.id]" class="btn btn-primary me-3">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pencil fa-w-16 fa-3x"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z" class=""></path></svg>
					<span class="d-none d-md-inline" >Editar</span>
				</a>

				<button *ngIf="si.shipping.status == 'SENT' && (rest.current_permission.receive_shipping|| rest.current_permission.global_receive_shipping)" class="btn btn-primary" (click)="receiveShipping(si)">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="angle-double-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-angle-double-down fa-w-10 fa-3x"><path fill="currentColor" d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z" class=""></path></svg>
					<span class="d-none d-md-inline">Recibir</span>
				</button>

				<a *ngIf="si.shipping.status == 'DELIVERED'" [routerLink]="['/view-shipping',si.shipping.id]" class="btn btn-primary">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-search fa-w-16 fa-3x"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" class=""></path></svg>
					<span class="d-none d-md-inline">Ver</span>
				</a>

				<button *ngIf="si.shipping.status == 'PENDING' && (rest.current_permission.send_shipping || rest.current_permission.global_receive_shipping )" type="button" class="btn btn-primary" (click)="confirmSendShipping(si.shipping)">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="location-arrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-location-arrow fa-w-16 fa-3x"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z" class=""></path></svg>
					<span class="d-none d-md-inline"> Enviar </span>
				</button>

				<button *ngIf="(si.shipping.status == 'PENDING' || si.shipping.status == 'SENT' ) && (rest.current_permission.send_shipping )" type="button" class="btn btn-primary ms-1" (click)="cancelShipping(si)">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0h120.4c12.1 0 23.2 6.848 28.6 17.69L320 32h96c17.7 0 32 14.33 32 32s-14.3 32-32 32H32C14.33 96 0 81.67 0 64s14.33-32 32-32h96l7.2-14.31zM31.1 128H416v320c0 35.3-28.7 64-64 64H95.1c-34.45 0-64-28.7-64-64V128zm80 80v224c0 8.8 8.1 16 16 16c9.7 0 16-7.2 16-16V208c0-8.8-6.3-16-16-16c-7.9 0-16 7.2-16 16zm96 0v224c0 8.8 8.1 16 16 16c9.7 0 16.9-7.2 16.9-16V208c0-8.8-7.2-16-16.9-16c-7.9 0-16 7.2-16 16zm96.9 0v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/></svg>
					<span class="d-none d-md-inline">Eliminar</span>
				</button>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-shipping'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
