<div class="container-fluid" *ngIf="store">
	<h1 class="text-capitalize my-3">{{store.id ? 'Actualizar':'Agregar'}} Sucursal</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-2 col-lg-1">
					<app-image-uploader [(image)]="store.ticket_image_id" [can_be_deleted]="true"></app-image-uploader>
				</div>
				<div class="col-10 col-lg-11">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="store.name" class="form-control" required>
				</div>
				<div class="col-12 col-md-4">
					<label class="">Razón Social</label>
					<input type="text" name="business_name" [(ngModel)]="store.business_name" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">RFC</label>
					<input type="text" name="rfc" [(ngModel)]="store.rfc" class="form-control">
				</div>

				<!-- div class="col-6 col-md-4">
					<label class="">Descripción</label>
					<textarea class="form-control" name="description" [(ngModel)]="store.description"></textarea>
				</div -->
				<div class="col-12 col-md-4">
					<label class="">Precios</label>
					<select name="price_list_id" [(ngModel)]="store.price_list_id" class="form-control" required>
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let pl of price_list_list" [ngValue]="pl.id">{{pl.name | titlecase }}</option>
					</select>
				</div>
								<div class="col-12 col-md-4">
					<label class="">Ciudad</label>
					<input type="text" name="city" [(ngModel)]="store.city" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">Código Postal</label>
					<input type="text" name="zipcode" [(ngModel)]="store.zipcode" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">Estado</label>
					<input type="text" name="state" [(ngModel)]="store.state" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">Dirección</label>
					<input type="text" name="address" [(ngModel)]="store.address" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<label class="">Teléfono</label>
					<input type="text" name="phone" [(ngModel)]="store.phone" class="form-control">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Paypal Email</label>
					<input type="email" name="paypal_email" [(ngModel)]="store.paypal_email" class="form-control">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Estatus</label>
					<select name="status" [(ngModel)]="store.status" class="form-control">
						<option value="ACTIVE">Activo</option>
						<option value="DISABLED">Inactiva</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Visibilidad predetermindado de categorías</label>
					<select name="pos_category_preferences" [(ngModel)]="store.pos_category_preferences" class="form-control">
						<option value="DEFAULT_BY_PRODUCT">Por Producto (PREDETERMINADO)</option>
						<option value="HIDE_BY_DEFAULT">Esconder</option>
						<option value="SHOW_BY_DEFAULT">Mostrar</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Cantidad Máxima en caja</label>
					<input type="number" name="max_cash_amount" [(ngModel)]="store.max_cash_amount" class="form-control">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Propina sugerida</label>
					<input type="number" name="suggested_tip" [(ngModel)]="store.suggested_tip" class="form-control" min="0" max="100" required>
				</div>



				<div class="col-12">
					<label class="">Nota en Ticket</label>
					<textarea type="text" name="ticket_footer_text" [(ngModel)]="store.ticket_footer_text" class="form-control"></textarea>
				</div>

				<div class="w-100 text-end mt-4">
					<button type="submit" [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
				<!-- div class="col-6 col-md-4">
					<label class="">Type</label>
					<select name="type" [(ngModel)]="store.type" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option value="PRODUCCION">PRODUCCION</option>
					</select>
				</div-->
			</div>
		</div>
		<!-- <h2 class="my-3">Tipo de Cambio</h2>
		<div class="card p-3" >
			<ng-container *ngFor="let currency of currency_list">
				<div class="row p-3" *ngIf="currency.id != store.default_currency_id">
					<div class="col-6 col-md-4">
						<label class="">{{currency.name | titlecase}} {{currency.id | uppercase}}</label>
						<input type="number" [name]="'exchange_rate'+currency.id" [(ngModel)]="currency_rate_dictionary[currency.id].rate" class="form-control" min="0.01" step="any" required>
					</div>
				</div>
			</ng-container>
		</div> -->
		<h2 class="my-3">Facturación</h2>
		<div class="card p-3">
			<div class="row">
				<div class="col-12 col-md-6">
					<label class="">Usuario</label>
					<select name="default_billing_data_id" [(ngModel)]="store.default_billing_data_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of billing_data_list" [value]="c.id">{{c.usuario}} -- {{c.rfc}}</option>
					</select>
				</div>
				<div class="col-12 col-md-6">
					<label class="">Porcentaje IVA
						<abbr title="Porcentaje en valores enteros ej: 8 equivale al 8%">
							<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601c0-5.302-4.299-9.6-9.6-9.6zm.896 3.466c.936 0 1.211.543 1.211 1.164c0 .775-.62 1.492-1.679 1.492c-.886 0-1.308-.445-1.282-1.182c0-.621.519-1.474 1.75-1.474zM8.498 15.75c-.64 0-1.107-.389-.66-2.094l.733-3.025c.127-.484.148-.678 0-.678c-.191 0-1.022.334-1.512.664l-.319-.523c1.555-1.299 3.343-2.061 4.108-2.061c.64 0 .746.756.427 1.92l-.84 3.18c-.149.562-.085.756.064.756c.192 0 .82-.232 1.438-.719l.362.486c-1.513 1.512-3.162 2.094-3.801 2.094z"/></svg>
						</abbr>
					</label>
					<input type="text" name="tax_percent" [(ngModel)]="store.tax_percent" placeholder="16" class="form-control">
				</div>

				<div class="col-12 col-md-6">
					<label class="">Serie</label>
					<input type="text" name="default_sat_serie" [(ngModel)]="store.default_sat_serie" class="form-control">
				</div>

				<div class="col-12 col-md-6">
					<label class="">
						Plantilla
						<abbr title="Compacta: Se muestra un solo concepto en la factura.&#10; Normal: Se muestran todos lo conceptos en la factura.&#10; NOTA: Si la factura contiene retenciones aplicara la plantilla compacta.">
							<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601c0-5.302-4.299-9.6-9.6-9.6zm.896 3.466c.936 0 1.211.543 1.211 1.164c0 .775-.62 1.492-1.679 1.492c-.886 0-1.308-.445-1.282-1.182c0-.621.519-1.474 1.75-1.474zM8.498 15.75c-.64 0-1.107-.389-.66-2.094l.733-3.025c.127-.484.148-.678 0-.678c-.191 0-1.022.334-1.512.664l-.319-.523c1.555-1.299 3.343-2.061 4.108-2.061c.64 0 .746.756.427 1.92l-.84 3.18c-.149.562-.085.756.064.756c.192 0 .82-.232 1.438-.719l.362.486c-1.513 1.512-3.162 2.094-3.801 2.094z"/></svg>
						</abbr>

					</label>
					<select type="text" name="modo_facturacion" [(ngModel)]="store.modo_facturacion" class="form-control" required>
						<option value="COMPACTA">Compacta</option>
						<option value="DESGLOSADA">Normal</option>
					</select>
					<div></div>
				</div>

				<div class="col-12 col-md-6">
					<label class="">SAT claveprodserv por defecto</label>
					<input type="text" name="default_claveprodserv" [(ngModel)]="store.default_claveprodserv" class="form-control">
				</div>

				<div class="col-12 col-md-6">
					<label class="">Nombre del concepto en Factura Compacta</label>
					<input type="text" name="default_sat_item_name" [(ngModel)]="store.default_sat_item_name" class="form-control">
				</div>
				<div class="col-12 col-md-6">

					<label class="">Autofacturación</label>
					<select name="autofacturacion_settings" [(ngModel)]="store.autofacturacion_settings" class="form-control">
						<option value="DISABLED">Desabilitada</option>
						<option value="ONLY_DAY_LIMIT">Limite de dias</option>
						<option value="ONLY_SAME_MONTH">Dentro del mes</option>
						<!--option value="BOTH">Dentro del mes + Limite de dias</option-->
					</select>
				</div>
				<div class="col-12 col-md-6" *ngIf="store.autofacturacion_settings == 'ONLY_DAY_LIMIT'">
					<label class="">Limite de días para autofacturación</label>
					<input type="text" name="autofacturacion_day_limit" [(ngModel)]="store.autofacturacion_day_limit" class="form-control" [disabled]="store.autofacturacion_settings != 'ONLY_DAY_LIMIT' && store.autofacturacion_settings != 'BOTH'">
				</div>
			</div>
		</div>
		<!--ng-container *ngIf="store.id">
			<h2 class="my-3">Cliente-Sucursal</h2>
			<div class="card p-3">
				<div *ngFor="let store_client of store_client_user_list">
					<div class="col-10 col-md-6">
						<div class="form-group">
							<input type="text" class="form-control mt-2" name="client_name" [(ngModel)]="search_client_str" placeholder="Nombre Cliente" autocomplete="off" (keyup)="onClientSearch($event)">
						</div>
					</div>
					<div class="col-2 px-0">
						<span style="line-height:38px">
							<svg *ngIf="!user_client?.image_id" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="38" height="38" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" fill="currentColor"/></svg>
						</span>
						<img *ngIf="user_client?.image_id" [src]="rest.getImagePath(user_client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="margin-top:4px" width="38" height="38">
					</div>
				</div>
			</div>
		</ng-container-->
		<h2 class="my-3">Configuración Impresora</h2>
		<div class="card p-3 my-3">
			<div class="row">
				<div class="col-12">
					<label class="">Qr para facturar</label>
					<select name="show_facturacion_qr" [(ngModel)]="store.show_facturacion_qr" class="form-control">
						<option value="NO">No Imprimir</option>
						<option value="YES">Imprimir</option>
					</select>
				</div>
				<div class="col-12">
					<label class="">Tamaño QR</label>
					<select name="qr_size" [(ngModel)]="store.qr_size" class="form-control">
						<option value="PERCENT_25">Pequeño</option>
						<option value="PERCENT_50">Mediano</option>
						<option value="PERCENT_75">Grande</option>
						<option value="PERCENT_100">Máximo</option>
					</select>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-12 col-md-6 form-group">
					<label class="">Retardo para imprimir(milisegundos)</label>
					<input type="number" name="print_delay" [(ngModel)]="store.print_delay" class="form-control" min="0" max="10000" step="100" required>
				</div>
				<div class="col-12 col-md-6 form-group">
					<label>Ticket</label>
					<select name="print_receipt" [ngModel]="print_receipt" (ngModelChange)="printReceiptChange($event)" class="form-control">
						<option value="">Default</option>
						<option value="1">Normal</option>
						<option value="2">Compacto</option>
						<option value="3">Compacto(flotante)</option>
						<option value="4">Con Total,Porcentaje y descuento total</option>
						<option value="5">Simple 57mm</option>
						<option value="6">Normal 80mm</option>
						<option value="7">Normal Redirección</option>
					</select>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-12">
					<label class="">Ticket</label>
					<textarea name="printer_ticket_config" [(ngModel)]="store.printer_ticket_config" rows="15" class="form-control"></textarea>
				</div>
			</div>
		</div>
		<h2 class="my-3">Configuración Lugar</h2>
		<div class="card p-3 my-3">
			<div class="row">
				<div class="col-6">
					<label class="">Latitude</label>
					<input type="number" name="lat" step="any" [(ngModel)]="store.lat">
				</div>
				<div class="col-6">
					<label class="">Longitude</label>
					<input type="number" name="lat" step="any" [(ngModel)]="store.lng">
				</div>
			</div>
		</div>
	</form>
</div>
