<div class="container-fluid">

	<div class="row align-items-end my-3 mx-0">
		<div class="col">
			<h1 class="text-capitalize my-1">Direcciones de {{user?.name | titlecase }}</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-address',user.id]">Agregar</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="address_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Name</label>
					<input type="text" name="name" [(ngModel)]="address_search.lk.name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Phone</label>
					<input type="text" name="phone" [(ngModel)]="address_search.lk.phone" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Business name</label>
					<input type="text" name="business_name" [(ngModel)]="address_search.lk.business_name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Email</label>
					<input type="text" name="email" [(ngModel)]="address_search.lk.email" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Rfc</label>
					<input type="text" name="rfc" [(ngModel)]="address_search.lk.rfc" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">User</label>
					<select name="user_id" [(ngModel)]="address_search.eq.user_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of user_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Address</label>
					<input type="text" name="address" [(ngModel)]="address_search.lk.address" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Zipcode</label>
					<input type="text" name="zipcode" [(ngModel)]="address_search.lk.zipcode" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Country</label>
					<input type="text" name="country" [(ngModel)]="address_search.lk.country" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">State</label>
					<input type="text" name="state" [(ngModel)]="address_search.lk.state" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">City</label>
					<input type="text" name="city" [(ngModel)]="address_search.lk.city" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Suburb</label>
					<input type="text" name="suburb" [(ngModel)]="address_search.lk.suburb" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Note</label>
					<input type="text" name="note" [(ngModel)]="address_search.lk.note" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="address_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="address_search.eq.updated" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Nombre</th>
						<th>Dirección</th>
						<th>Tipo</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let address of address_list">
						<td>
							<a [routerLink]="['/edit-address',address.id]">{{address.name | titlecase}}</a>
						</td>
						<td>{{address.address}}</td>
						<th>
							<span *ngIf="address.type == 'BILLING_AND_SHIPPING' || address.type == 'SHIPPING'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><defs/><path d="M16 2A11.013 11.013 0 0 0 5 13a10.889 10.889 0 0 0 2.216 6.6s.3.395.349.452L16 30l8.439-9.953c.044-.053.345-.447.345-.447l.001-.003A10.885 10.885 0 0 0 27 13A11.013 11.013 0 0 0 16 2zm0 15a4 4 0 1 1 4-4a4.005 4.005 0 0 1-4 4z" fill="currentColor"/><circle id="_Inner-Path_" cx="16" cy="13" r="4" fill="none"/></svg>
							</span>
							<span *ngIf="address.type == 'BILLING_AND_SHIPPING' || address.type == 'BILLING'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" fill="currentColor"/></svg>
							</span>
						</th>
					</tr>
					<tr *ngIf="address_list == null || address_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-address'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

