import { Component, OnInit } from '@angular/core';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';
import {Quote, User, Store} from '../../models/RestModels';
import {QuoteInfo} from 'src/app/models/models';
import {Utils} from 'src/app/classes/Utils';

interface CQuote extends Quote
{
	fecha_inicial:string;
	fecha_final:string;
}

@Component({
	selector: 'app-list-quote',
	templateUrl: './list-quote.component.html',
	styleUrls: ['./list-quote.component.css']
})

export class ListQuoteComponent extends BaseComponent implements OnInit 
{
	file:File | null = null;
	show_import:boolean = false;
	quote_search:SearchObject<CQuote> = this.getEmptySearch();
	quote_info_list:QuoteInfo[] = [];
	show_copy_quote:boolean = false;
	quote_to_copy:QuoteInfo | null = null;
	new_quote_client:User | null = null;
	new_valid_until: String = '';

	store_list:Store[] = [];
	approved_status_dict:Record<string,string> = {
		'PENDING':'Pendiente',
		'SENT':'Enviado',
		'DECLINED':'Declinado',
		'APPROVED':'Aprobado',
		'CANCELLED':'Cancelado'
	};
    fecha_final: string = '';
    fecha_inicial: string;

	//store_dictionary: Record<number,Store> = {};

	ngOnInit()
	{
		this.path = '/list-quotes';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","store_id","client_user_id","name","email","phone","created","updated","sync_id","created_by_user_id","approved_status","valid_until","approved_time", "fecha_inicial","fecha_final" ];
			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.quote_search = this.getSearch( queryParamMap, fields, extra_keys );
			this.setTitle('Cotizaciones');
			this.quote_search.sort_order = ['id_DESC'];
			this.is_loading = true;

			if( this.quote_search.ge.created )
			{
				this.fecha_inicial = Utils.getLocalMysqlStringFromDate( this.quote_search.ge.created );
			}
			else
			{
				this.fecha_inicial = '';
			}

			if( this.quote_search.le.created )
			{
				this.fecha_final = Utils.getLocalMysqlStringFromDate( this.quote_search.le.created );
			}
			else
			{
				this.fecha_final = '';
			}

			this.subs.sink = forkJoin
			({
				quote : this.rest.quote_info.search( this.quote_search ),
				store : this.rest.getStores()
			})
			.subscribe((responses)=>
			{
				//this.store_dictionary = Utils.createDictionary(this.store_list, 'id');
				this.is_loading = false;
				this.quote_info_list = responses.quote.data;
				this.setPages( this.quote_search.page, responses.quote.total );
				this.store_list = responses.store.data;
			},(error)=>this.showError(error));
		});
	}

	showCopyQuote(quote_info:QuoteInfo)
	{
		console.log("Mostrando cotización para copiar:", quote_info);
		this.quote_to_copy = quote_info;
		this.show_copy_quote = true;
	}

	copyQuote()
	{
		if (!this.new_valid_until)
		{
			this.showError('Por favor, ingrese una fecha válida');
			return;
		}

		this.is_loading = true;
		this.subs.sink = this.rest.quote_info.get( this.quote_to_copy.quote.id )
		.subscribe
		({
			next:(response)=>
			{
				this.quote_to_copy = response;
				let new_quote = {...this.quote_to_copy};

				new_quote.client_user = this.new_quote_client || null;
				new_quote.quote.name = this.new_quote_client?.name || null;
				new_quote.quote.client_user_id = this.new_quote_client?.id || null;
				new_quote.quote.approved_status = 'PENDING';
	
				const validUntilDate = new Date(this.new_valid_until as string);
            	new_quote.quote.valid_until = validUntilDate.toISOString().split('T')[0];

				new_quote.quote.id = 0;
		
				this.subs.sink = this.rest.quote_info.create(new_quote).subscribe({
					next: () =>
					{
						this.showSuccess('La cotización se copió con éxito');
						this.is_loading = false;
						this.show_copy_quote = false;
						window.location.reload();
					},
					error: (error) =>
					{
						this.showError('Error al copiar la cotización: ' + error);
						this.is_loading = false;
					}
				});
			},
			error:(_error)=>
			{
				this.showError('Ocurrio un error al cargar la informacion por favor intente de nuevo');
			}
		});
	}

	onClientSelected(user: User)
	{
		console.log("Usuario seleccionado para la nueva cotización:", user);
		if (user) 
		{
			this.new_quote_client = user;
		} else 
		{
			this.new_quote_client = null;
		}
	}

	isExpired(valid_until: string)
	{
		const valid_until_date = new Date(valid_until);
		const current_date = new Date();
		valid_until_date.setHours(23, 59, 59, 999);
		return valid_until_date < current_date;
	}
	
	cancelQuote(quote_info: QuoteInfo)
	{
		this.is_loading = true;
		let quote = {...quote_info.quote}
		quote.approved_status = 'CANCELLED';
		const quote_info2: QuoteInfo = {...quote_info, quote};

		this.subs.sink = this.rest.quote_info.update(quote_info2)
		.subscribe({
			next: () => 
			{
				quote_info.quote.approved_status = 'CANCELLED';
				this.showSuccess('La cotización ha sido cancelada');
				this.is_loading = false;
			},
			error: (error) =>
			{
				this.showError('Error al cancelar la cotización: ' + error);
				this.is_loading = false;
			}
		});
	}
	
    /*
	onFileChanged(event)
	{
		if (event.target.files.length)
		{
			this.file = event.target.files[0];
		}
	}

	uploadFile()
	{
		this.is_loading = true;
		Utils.xlsx2json( this.file,["id","store_id","client_user_id","name","email","phone","created","updated","sync_id","created_by_user_id","approved_status","valid_until","approved_time"]).then((json)=>
		{
			//Filter json then upload
			this.subs.sink	= this.rest.quote.batchUpdate(json).subscribe((result)=>
			{
				if( this.quote_list.length == 0 )
				{
					this.setPages( 0, result.length );
					this.quote_list = result.slice(0,this.pageSize);
				}
				this.is_loading =	false;
					this.show_import = false;
					this.showSuccess('Imported succesfully '+result.length+' items');

			},(error)=>this.showError(error));
		});
	}

	exportFile()
	{
		this.is_loading = true;
		this.subs.sink	= this.rest.quote.search({limit:100000}).subscribe((response)=>
		{
			Utils.array2xlsx(response.data,'quote.xlsx',["id","store_id","client_user_id","name","email","phone","created","updated","sync_id","created_by_user_id","approved_status","valid_until","approved_time"])
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
	*/
}
