import { forkJoin, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { OrderInfo } from "src/app/models/models";
import { RestService } from "src/app/services/rest.service";

export function createOrder(rest:RestService, title:string, client_user_id:number, total:number, tax_percent:number, amount_paid:number, currency_id:string):any
{
	let subtotal = total/(1+(tax_percent/100));
	let tax = subtotal*(tax_percent/100);

	let payload = {
		"items": [
			{
				"order_item": {
					"discount_percent": 0,
					"discount": 0,
					"id": 0,
					"item_option_id": null,
					"exceptions": "",
					"item_id": 1067,
					"has_separator": "NO",
					"ieps_calculated": 0,
					"ieps_type": "RATE",
					"reservation_item_id": null,
					"ieps_value": 0,
					"qty": 1,
					"offer_id": null,
					"tax": tax,
					"item_group": 1730912065770,
					"tax_included": "YES",
					"unitary_price_meta": subtotal,
					"original_unitary_price": total,
					"stock_status": "IN_STOCK",
					"unitary_price": subtotal,
					"status": "ACTIVE",
					"type": "NORMAL",
					"total": total,
					"subtotal": subtotal,
					"is_free_of_charge": "NO",
					"note": ""
				},
				"prices": [ ],
				"commanda_type_id": null
			}

		],
		"order": {
			"amount_paid": 0,
			"ares": 0,
			"cancellation_timestamp": null,
			"billing_data_id": 1,
			"cashier_user_id": 3,
			"currency_id": currency_id,
			"cancellation_reason": "",
			"cancelled_by_user_id": null,
			"facturacion_timestamp": null,
			"closed_timestamp": null,
			"discount": 0,
			"initial_payment": 0,
			"sat_isr": 0,
			"sat_ieps": 0,
			"discount_calculated": 0,
			"price_type_id": 1,
			"sat_forma_pago": "01",
			"sat_serie": "A",
			"sat_exchange_rate": 0,
			"sat_domicilio_fiscal_receptor": '',
			"sat_regimen_fiscal_receptor": '',
			"sat_regimen_capital_receptor": "",
			"service_type": "QUICK_SALE",
			"status": "PENDING",
			"paid_status": "PENDING",
			"period_id": null,
			"store_id": 1,
			"subtotal": subtotal,
			"sync_id": ""+1+""+Date.now(),
			"system_activated": null,
			"table_id": null,
			"tag": "",
			"tax": tax,
			"tax_percent": tax_percent,
			"total": total,
			"updated": "2024-11-06T16:54:07.812Z",
			"version_created": "NXT-VIA_API",
			"version_updated": "NXT-VIA_API",
			"client_name": title,
			"client_user_id": client_user_id,
			"sat_receptor_rfc": "",
			"sat_razon_social": ""
		},
	};

	if( amount_paid )
	{
		console.log('Pagando Orden ', payload );

		return rest.order_info.create( payload ).pipe
		(
			mergeMap((order_info:OrderInfo)=>
			{
				console.log('Cerramos el pedido', order_info );
				return forkJoin
				({
					closeOrder: rest.update('closeOrder',{order_id:order_info.order.id}),
					order_info: of( order_info )
				});
			}),
			mergeMap((response)=>
			{
				let order_info:OrderInfo = response.order_info;
				let payment_payload = {
					"movements": [
						{
							"bank_movement": {
								"amount_received": amount_paid,
								"bank_account_id": null,
								"card_ending": "",
								"client_user_id": null,
								"created": new Date(),
								"currency_id": order_info.order.currency_id,
								"id": null,
								"invoice_attachment_id": null,
								"note": "",
								"origin_bank_name": null,
								"paid_date": null,
								"payment_id": null,
								"provider_user_id": null,
								"receipt_attachment_id": null,
								"received_by_user_id": null,
								"reference": "",
								"status": "ACTIVE",
								"exchange_rate": 1,
								"total": amount_paid,
								"transaction_type": "CASH",
								"type": "income",
								"updated": new Date()
							},
							"bank_movement_orders": [
								{
									"currency_amount": amount_paid,
									"amount": amount_paid,
									"currency_id": order_info.order.currency_id,
									"exchange_rate": 1,
									"status": "ACTIVE",
									"order_id": order_info.order.id
								}
							]
						}
					],
					"payment": {
						"type": "income",
						"tag": "SALE",
						"payment_amount": amount_paid,
						"received_amount": amount_paid,
						"facturado": "NO",
						"store_id:": 1,
						"sat_pdf_attachment_id": null,
						"sat_uuid": null,
						"sat_xml_attachment_id": null,
						"change_amount": 0,
						"currency_id": order_info.order.currency_id,
						"sync_id": "3-"+Date.now()
					}
				};
				return rest.payment_info.create( payment_payload )
			}),
		);
	}
	return rest.order_info.create( payload ).pipe
	(
		mergeMap((order_info:OrderInfo)=>
		{
			return rest.update('closeOrder',{order_id:order_info.order.id});
		})
	);
};


//fetch("http://127.0.0.184/PointOfSale/payment_info.php", {
//  "headers": {
//    "accept": "application/json, text/plain, */*",
//    "accept-language": "en-US,en;q=0.9",
//    "authorization": "Bearer TgfEWFSLDcVCngwl",
//    "content-type": "application/json",
//    "sec-ch-ua": "\"Chromium\";v=\"130\", \"Google Chrome\";v=\"130\", \"Not?A_Brand\";v=\"99\"",
//    "sec-ch-ua-mobile": "?0",
//    "sec-ch-ua-platform": "\"Linux\"",
//    "sec-fetch-dest": "empty",
//    "sec-fetch-mode": "cors",
//    "sec-fetch-site": "same-site"
//  },
//  "referrer": "http://127.0.0.184:4000/",
//  "referrerPolicy": "strict-origin-when-cross-origin",
//  "body": "{\"movements\":[{\"bank_movement\":{\"amount_received\":1000,\"bank_account_id\":null,\"card_ending\":\"\",\"client_user_id\":209,\"created\":\"2024-11-06T17:17:35.072Z\",\"currency_id\":\"MXN\",\"id\":null,\"invoice_attachment_id\":null,\"note\":\"\",\"origin_bank_name\":null,\"paid_date\":null,\"payment_id\":null,\"provider_user_id\":null,\"receipt_attachment_id\":null,\"received_by_user_id\":null,\"reference\":\"\",\"status\":\"ACTIVE\",\"exchange_rate\":1,\"total\":1000,\"transaction_type\":\"CASH\",\"type\":\"income\",\"updated\":\"2024-11-06T17:17:35.072Z\"},\"bank_movement_orders\":[{\"currency_amount\":1000,\"amount\":1000,\"currency_id\":\"MXN\",\"exchange_rate\":1,\"status\":\"ACTIVE\",\"order_id\":15003}]}],\"payment\":{\"type\":\"income\",\"tag\":\"SALE\",\"payment_amount\":1000,\"received_amount\":1000,\"facturado\":\"NO\",\"sat_pdf_attachment_id\":null,\"sat_uuid\":null,\"sat_xml_attachment_id\":null,\"change_amount\":0,\"currency_id\":\"MXN\",\"sync_id\":\"3-1730913371060\",\"paid_by_user_id\":209}}",
//  "method": "POST",
//  "mode": "cors",
//  "credentials": "include"
//});
