import { Component, Input, OnChanges, OnInit, Output, SimpleChanges,EventEmitter, OnDestroy } from '@angular/core';
import {GeocodingAddressComponent, GeocodingResponse, GeocodingResult} from 'src/app/models/models';
import {Address, User} from 'src/app/models/RestModels';
import {RestService} from 'src/app/services/rest.service';
import {SubSink} from 'subsink';

@Component({
	selector: 'app-add-new-address',
	templateUrl: './add-new-address.component.html',
	styleUrls: ['./add-new-address.component.css']
})
export class AddNewAddressComponent implements OnInit,OnDestroy, OnChanges{

	is_loading:boolean = false;
	subs = new SubSink();

	constructor(public rest:RestService){ }

	address:Address	= {
		sat_regimen_fiscal: '',
		sat_regimen_capital: ''
	};

	user_list:User[] = [];
	states:String[] = [
		'Aguascalientes',
		'Baja California',
		'Baja California Sur',
		'Campeche',
		'Chiapas',
		'Chihuahua',
		'Ciudad de México',
		'Coahuila',
		'Colima',
		'Durango',
		'Guanajuato',
		'Guerrero',
		'Hidalgo',
		'Jalisco',
		'México',
		'Michoacán',
		'Morelos',
		'Nayarit',
		'Nuevo León',
		'Oaxaca',
		'Puebla',
		'Querétaro',
		'Quintana Roo',
		'San Luis Potosí',
		'Sinaloa',
		'Sonora',
		'Tabasco',
		'Tamaulipas',
		'Tlaxcala',
		'Veracruz',
		'Yucatán',
		'Zacatecas',
	];

	country_suggestion:string = 'Mexico';
	city_suggestion:string = '';
	address_suggestion:string=''
	state_suggestion:string = '';
	zipcode_suggestion:string = '';
	sublocalities:string[] = [];

	@Input() user:User | null = null;
	@Output() addressChange = new EventEmitter<Address | null>();

	ngOnInit()
	{
		navigator.geolocation.getCurrentPosition((geolocation_position:GeolocationPosition)=>
		{
			console.log('Buscando informacion para ',geolocation_position );
			this.sublocalities = [];
			this.subs.sink = this.rest.getAddressFromGeolocationPosition( geolocation_position ).subscribe((geocoding_response:GeocodingResponse)=>
			{
				console.log('Respuesta de google', geocoding_response );
				if( geocoding_response.status == 'OK' )
				{
					//Tal vez hay que quitar este if
					if( geocoding_response.results.length > 0 )
					{
						let geocoding_result:GeocodingResult = geocoding_response.results[0];

						geocoding_result.address_components.forEach((geocoding_address_component:GeocodingAddressComponent)=>
						{
							//if( geocoding_address_component.types.includes('country') )
							//{
							//	console.log('Detectado pais');
							//	this.country_suggestion =  geocoding_address_component.long_name;
							//}
							//if( geocoding_address_component.types.includes('administrative_area_level_1') )
							//{
							//	console.log('Detectado estado');
							//	this.state_suggestion =  geocoding_address_component.long_name;
							//}
							//if( geocoding_address_component.types.includes('locality') )
							//{
							//	console.log('Detectado ciudad');
							//	this.city_suggestion =  geocoding_address_component.long_name;
							//}

							//if( geocoding_address_component.types.includes('postal_code') )
							//{
							//	console.log('codigo_+postal');
							//	this.zipcode_suggestion =  geocoding_address_component.long_name;
							//}
						})
					}

					geocoding_response.results.forEach((geocoding_result)=>
					{
						geocoding_result.address_components.forEach((geocoding_address_component)=>
						{
							if( geocoding_address_component.types.includes('sublocality') )
							{
								this.sublocalities.push( geocoding_address_component.long_name );
							}
						});
					})
				}
			},(error)=>{
				console.error('NO se pudo obtener la informacion de la geolocalizacion. google no pudo dar informacion posible problema de el punto geografico del dispositivo', error);
			});
		},(error)=>
		{
			console.error('NO se pudo obtener la informacion de la geolocalizacion. Error dispositivo o permisos',error);
		},{
			maximumAge: Infinity, timeout: 500,enableHighAccuracy:false
		})
	}

	ngOnDestroy():void
	{
		this.subs.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.user  && this.user )
		{
			if(! changes.address && changes.user && this.user.id )
			{
				this.address = {
					id: 0,
					user_id : this.user.id,
					address:'',
					sat_regimen_capital: '',
					sat_regimen_fiscal: '',
					city:'',
					country:'',
					created: new Date(),
					email: '',
					lat: null,
					lng: null,
					name:'',
					note:'',
					phone: '',
					rfc:'',
					state: '',
					status:'ACTIVE',
					suburb:'',
					type:'SHIPPING',
					updated: new Date(),
					zipcode:'',
				};
			}
		}
	}

	save()
	{
		this.is_loading = true;
		let str = this.address.address;

		//if( this.address.suburb )
		//	str+=','+this.address.suburb;

		//if( this.address.city )
		//	str+=','+this.address.city;

		//if( this.address.state )
		//	str+=','+this.address.state+'.';

		//if( this.address.country)
		//	str+=''+this.address.state+'.';

		//if( this.address.zipcode )
		//	str+=''+this.address.state+'CP '+this.address.zipcode;

		if( this.address.id	)
		{
			this.subs.sink	= this.rest.address.update( this.address ).subscribe((address)=>
			{
				this.is_loading = false;
				this.rest.showSuccess('La dirección se actualizó exitosamente');
				this.addressChange.emit( address );
			},(error)=>this.rest.showError(error));
		}
		else
		{

			this.subs.sink	= this.rest.address.create( this.address ).subscribe((address)=>{
				this.is_loading = false;
				this.rest.showSuccess('la dirección se guardó exitosamente');
				this.addressChange.emit( address );
			},(error)=>this.rest.showError(error));
		}

		//this.rest.getLocationFromAddress( str ).subscribe((geocoding_response:GeocodingResponse)=>
		//{
		//	console.log('Respuesta dos de google', geocoding_response );
		//	if( geocoding_response.status == 'OK' && geocoding_response.results.length > 0)
		//	{
		//		this.address.lat = geocoding_response.results[0].geometry.location.lat;
		//		this.address.lng = geocoding_response.results[0].geometry.location.lng;

		//

		//	}
		//},(error)=>
		//{
		//	this.rest.showError('No se pudo encontrar la informacion de geolocalizacion de la dirección'+error);
		//});
	}


	onAddressChange(address:string)
	{
		console.log('It changes 1');
		this.address.address = address;

		if( this.address.address == this.address_suggestion )
		{
			console.log('It changes');
			//this.address.city = this.city_suggestion;
			//this.address.state = this.state_suggestion;
			//this.address.zipcode = this.zipcode_suggestion;
			//this.address.country = this.country_suggestion;
		}
	}
	onCityChange(city:string)
	{
		//this.address.city = city;

		//if( this.address.city == this.city_suggestion )
		//{
		//	console.log('It changes');
		//	this.address.city = this.city_suggestion;
		//	this.address.state = this.state_suggestion;
		//	this.address.zipcode = this.zipcode_suggestion;
		//	this.address.country = this.country_suggestion;
		//}
	}
}
