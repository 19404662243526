<div>
	<div class="info_container">
		<div *ngIf="!rest.is_maps_loaded">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
		<div *ngIf="rest.is_maps_loaded">
			<google-map [width]="'100%'" [height]="'100%'" [center]="current_position">
				<map-directions-renderer *ngIf="directions_results"  [directions]="directions_results"></map-directions-renderer>
				<map-marker *ngIf="current_position && !directions_results" [position]="current_position"></map-marker>
			</google-map>
		</div>
		<div class="p-3" *ngIf="order_info">
			<div class="card">
				<div class="row w-100">
					<div class="col-3 col-md-1 p-0 text-center align-middle">
						<span *ngIf="!order_info?.client?.image_id">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="4em" height="4em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><defs/><path d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.899 13.899 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3c.28.304.568.596.87.87c.092.084.187.162.28.242c.32.276.649.538.99.782c.044.03.084.069.128.1v-.012a13.901 13.901 0 0 0 16 0v.012c.044-.031.083-.07.128-.1c.34-.245.67-.506.99-.782c.093-.08.188-.159.28-.242c.302-.275.59-.566.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24zM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8zM8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0z" fill="currentColor"/></svg>
						</span>
					</div>
					<div class="col-9 ps-0">
						<div class="fw-bold">#{{order_info.order.id}}-{{order_info.order.client_name}}</div>
						<div>{{order_info?.order?.address}} {{order_info.order.suburb}}</div>
					</div>
					<div class="col-6 col-1 text-end">
						<div class="d-block-inline" *ngIf="order_distance > -1">{{ order_distance/1000 | number: '1.2-2'}} KM.</div>
						<div class="d-block-inline" *ngIf="order_distance == -1">N/A</div>
						<div class="btn btn-primary">{{order_info.order.total | currency:'$'}}</div>
						<div class="">{{duration}}</div>
					</div>
					<div class="col-6 col-md-1 pt-3 px-0 text-end">
						<button class="btn btn-primary p-3 w-100" *ngIf="order_info.order.delivery_status=='SENT'" (click)="marcarOrdenComoEntregada()"> Entregar </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
