<div class="container-fluid break_pages" style="background-color:white;color:black">
	<div class="text-center" *ngIf="order_info">
		<img [src]="rest.getImagePath(order_info.store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" style="width:50%;max-width:700;padding-bottom:50px">
	</div>
	<div class="px-3">
		<table class="w-100">
			<ng-container  *ngIf="returns_info">
					<!--tr><td colspan=3>{{returns_info |json}}</td></tr-->
					<tr>
						<td>Artículos</td>
						<td class="text-center">Cantidad</td>
						<td class="text-end">Precio</td>
					</tr>
					<tr *ngFor="let rii of returns_info.items">
						<td>{{rii?.category?.name }} {{ rii.item.name }}</td>
						<td class="text-center">{{rii.returned_item.returned_qty}}×{{(rii.returned_item.total || 0 )/(rii.returned_item.returned_qty) | currency:'$'}}</td>
						<td class="text-end">{{rii.returned_item.total | currency:'$' }}</td>
					</tr>
					<tr class="border-top">
						<td class="text-end fw-bold">Total</td>
						<td class="text-center"></td>
						<td class="text-end fw-bold">{{returns_info.returns.total | currency:'$'}}</td>
					</tr>
			</ng-container>
		</table>
	</div>
	<p>
		Esta nota de crédito contiene el valor correspondiente a los artículos devueltos de la venta con el
		folio: {{order_info?.order?.id}}, y podras hacerlo valido en cualquiera de nuestras sucursales participantes en un
		periodo de no mas de ___ dias, sera necesario mostrar esta nota de crédito al encargado de tienda al
		momento de realizar su compra. Los puntos generados por dichos artículos seran removidos de su
		cuenta. Para cualquier duda o aclaración estamos a sus ordenes.
		Código: <b>{{returns_info?.returns?.code}}</b>
	</p>
	<div class="text-center" [innerHTML]="store?.ticket_footer_text | marked"></div>
</div>
