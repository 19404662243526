<div class="container-fluid">
	<div class="my-3">
		<div class="row align-items-end">
			<div class="col">
				<h1 class="text-capitalize">
					Artículos {{category?'De:': ''}}
					<b *ngIf="category">{{category.name | titlecase}}</b>
				</h1>
			</div>
			<div class="col-auto">
				<!--button type="button" class="mr-2 btn btn-secondary lift" (click)="show_import=true">Importar</button-->
				<button type="button" class="me-1 btn btn-secondary" (click)="exportFile()">Exportar</button>
				<!-- bug angular -->
				<a class="btn btn-secondary" routerLink="/add-item/{{type}}" *ngIf="rest.current_permission.add_items">Agregar</a>
			</div>
		</div>
	</div>

	<div class="card p-3" *ngIf="!(item_search.eq.category_id != null)">
		<div class="row">
			<div class="col-10 form-group">
				<label class="">Nombre</label>
				<input type="text" name="name" (input)="onSearch($event)" class="form-control" autocomplete="off">
			</div>
			<div class="col-2">
				<label>&nbsp;</label>
				<div>
					<button type="submit" class="w-100 btn btn-primary" (click)="custom_search(item_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-item'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="d-none d-lg-flex row py-1 border-bottom fw-bold">
			<div class="col-sm-6 col-lg-3 pe-0 text-start">Artículo</div>
			<div class="col-lg-1 px-0">Código</div>
			<div class="col-lg-2 px-0">Actualizado</div>
			<div class="col-lg-1 px-0">Alta</div>
			<div class="col-12 col-sm-6 col-lg-4 text-end px-0">Acciones</div>
		</div>
		<div class="row border-bottom align-items-center" *ngFor="let item_info of item_info_list">
			<div class="col-12 col-lg-3 pe-0 text-start">
				<div *ngIf="item_info.item.image_id || item_info?.category?.image_id" class="d-inline-block ratio ratio-1x1" [style]="{'background-image':'url('+rest.getImagePath(item_info.item.image_id,item_info?.category?.image_id)+')','background-size':'contain',width:'40px','vertical-align':'middle','background-repeat':'no-repeat','background-position':'center center'}" (click)="showImage(item_info)">
						<img src="/assets/2px_transparent.png" width=40 height="40">
				</div>
				<a [routerLink]="['/edit-item',item_info.item.id]">
					<!--img [src]="rest.getImagePath(item_info.item.image_id,item_info.category?.image_id,rest.local_preferences.default_product_image_id)" style="max-height:30px;max-width:30px;margin-right:10px"-->
					<span *ngIf="!(item_info.item.image_id || item_info?.category?.image_id)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M13.409 2.512a3.75 3.75 0 0 0-2.818 0l-2.19.888l9.592 3.73l3.374-1.303a1.747 1.747 0 0 0-.46-.275l-7.498-3.04zM22 7.19l-9.25 3.574v10.923c.224-.045.444-.112.659-.199l7.498-3.04A1.75 1.75 0 0 0 22 16.826V7.19zM11.25 21.687V10.764L2 7.19v9.636a1.75 1.75 0 0 0 1.093 1.622l7.498 3.04c.215.087.435.154.659.2zM2.633 5.827L12 9.446l3.917-1.514l-9.543-3.71l-3.281 1.33c-.17.069-.324.162-.46.275z" fill="currentColor"/></g></svg>
					</span>
					<span *ngIf="item_info.category">{{item_info.category.name | titlecase }} - </span>
					{{item_info.item.name}}
				</a>
			</div>
			<div class="col-12 col-sm-5 col-lg-1 col-md-3">{{item_info.item.code}}</div>
			<div class="d-none d-md-block col-lg-2 col-md-3 px-0">
				<abbr title="{{item_info.item.updated | date: 'MMM d, y hh:mm a' }}">{{item_info.item.updated | date: 'MMM d, y' }}</abbr>
			</div>
			<div class="d-none d-lg-block col-lg-1 px-0">
				<abbr title="{{item_info.item.created | date: 'MMM d, y hh:mm a' }}">{{item_info.item.created | date: 'MMM d, y' }}</abbr>
			</div>
			<div class="col-12 mt-1 mt-sm-0 col-sm-7 col-md-6 col-lg-5 text-end">
				<button *ngIf="rest.current_permission.add_items" class="btn btn-primary mt-1 me-1" (click)="showCopyItem(item_info)">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M408 480H184a72 72 0 0 1-72-72V184a72 72 0 0 1 72-72h224a72 72 0 0 1 72 72v224a72 72 0 0 1-72 72z" fill="currentColor"/><path d="M160 80h235.88A72.12 72.12 0 0 0 328 32H104a72 72 0 0 0-72 72v224a72.12 72.12 0 0 0 48 67.88V160a80 80 0 0 1 80-80z" fill="currentColor"/></svg>
					<span class="d-none d-xl-inline">Copiar</span>
				</button>
				<a href="#" *ngIf="rest.current_permission.add_stock && item_info.item.has_serial_number=='NO'" class="btn btn-primary mt-1 me-1" [routerLink]="['/adjust-stock',item_info.item.id]">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M13.409 2.512a3.75 3.75 0 0 0-2.818 0l-2.19.888l9.592 3.73l3.374-1.303a1.747 1.747 0 0 0-.46-.275l-7.498-3.04zM22 7.19l-9.25 3.574v10.923c.224-.045.444-.112.659-.199l7.498-3.04A1.75 1.75 0 0 0 22 16.826V7.19zM11.25 21.687V10.764L2 7.19v9.636a1.75 1.75 0 0 0 1.093 1.622l7.498 3.04c.215.087.435.154.659.2zM2.633 5.827L12 9.446l3.917-1.514l-9.543-3.71l-3.281 1.33c-.17.069-.324.162-.46.275z" fill="currentColor"/></g></svg>
					<span class="d-none d-xl-inline">Inventario</span>
				</a>
				<a class="btn btn-primary mt-1 me-1" *ngIf="item_info.item.has_serial_number == 'YES'" [routerLink]="['/serial',item_info.item.id]">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M419.13 96H419l-35.05.33L128 96h-.16l-36.74.33C66.93 96.38 48 116.07 48 141.2v230.27c0 25.15 19 44.86 43.2 44.86h.15l36.71-.33l255.92.33h.17l35.07-.33A44.91 44.91 0 0 0 464 371.13V140.87A44.92 44.92 0 0 0 419.13 96ZM144 320a16 16 0 0 1-32 0V192a16 16 0 0 1 32 0Zm64 32a16 16 0 0 1-32 0V160a16 16 0 0 1 32 0Zm64-16a16 16 0 0 1-32 0V176a16 16 0 0 1 32 0Zm64 16a16 16 0 0 1-32 0V160a16 16 0 0 1 32 0Zm64-32a16 16 0 0 1-32 0V192a16 16 0 0 1 32 0Z"/></svg>
					<span class="d-none d-xl-inline">#Series</span>
				</a>

				<a href="#" *ngIf="item_info.item.on_sale == 'YES'" class="btn btn-primary mt-1 me-1" [routerLink]="['/edit-prices',item_info.item.id]">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm12 152h-4v8a8 8 0 0 1-16 0v-8h-16a8 8 0 0 1 0-16h36a12 12 0 0 0 0-24h-24a28 28 0 0 1 0-56h4v-8a8 8 0 0 1 16 0v8h16a8 8 0 0 1 0 16h-36a12 12 0 0 0 0 24h24a28 28 0 0 1 0 56z" fill="currentColor"/></svg>
					<span class="d-none d-xl-inline">Precios</span>
				</a>
				<button class="btn btn-primary mt-1" *ngIf="rest.current_permission.add_items" (click)="showConfirmDelete(item_info)">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5z" fill="currentColor"/></g></svg>
					<span class="d-none d-xl-inline">Eliminar</span>
				</button>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-item'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
<!--app-modal [(show)]="show_import">
	<div class="card p-3">
		<h2>Import excel</h2>
		<div class="from-group mb-3">
			<label>Excel data</label>
			<input name="file" type="file" (change)="onFileChanged($event)" accept="*/*" class="form-control">
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<button type="button" class="btn btn-secondary w-100" (click)="show_import=false">Cancel</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100" (click)="uploadFile()" [disabled]="file==null">Upload</button>
			</div>
		</div>
	</div>
</app-modal-->

<app-modal [(show)]="show_copy_item">
	<div class="p-3">
		<form (ngSubmit)="copyItem()" ngNativeValidate>
			<h2>Copiar Producto</h2>
			<div class="row">
				<div class="col-12 col-6 form-group">
					<label>Nombre</label>
					<input type="text" name="new_item_name" class="form-control" [(ngModel)]="new_item_name" autocomplete="off" required>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-6">
					<button type="button" class="btn btn-danger w-100" (click)="show_copy_item=false">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Copiar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
<app-modal [(show)]="show_image">
	<div class="selected_category">
		<div *ngIf="selected_item" class="d-inline-block ratio ratio-1x1" [ngStyle]="{'background-image':'url('+rest.getImagePath(selected_item.item.image_id,selected_item.category.image_id)+')','background-size':'contain','width':'100%','max-width':'500px','vertical-align':'middle','background-repeat':'no-repeat','background-position':'center center'}">
			<img src="/assets/2px_transparent.png" width=40 height="40">
		</div>
	</div>
</app-modal>
