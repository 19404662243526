import { Component, OnInit } from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { NumeroALetras } from 'src/app/classes/NumeroALetras';
import {OrderInfo} from 'src/app/models/models';
import {Address, Billing_Data, Currency, Currency_Rate, Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-print-remission',
	templateUrl: './print-remission.component.html',
	styleUrls: ['./print-remission.component.css']
})
export class PrintRemissionComponent extends BaseComponent
{
	order_info:OrderInfo | null = null;
	forma_pago:string = '';
	store_list:Store[] = [];
	billing_address:Address | null = null;
	billing_data:Billing_Data | null = null;
	show_billing_address:boolean	= false;
	currency_rate:Currency_Rate | null = null;
	total_letra:string = '';
	currency_list: Currency[] = [];
	currency:Currency = GetEmpty.currency();

	subtotal:number = 0;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				this.is_loading = true;

				return forkJoin
				({
					billing_data: this.rest.getBillingData(),
					order_info: this.rest.order_info.get( param_map.get('id') ),
					currency_rate: this.rest.currency_rate.search({limit: 999999}),
					currencies: this.rest.currency.search({limit:999999})
				})
			}),
			mergeMap((response)=>
			{
				this.currency_list = response.currencies.data;
				this.order_info = response.order_info;

				this.currency_rate = response.currency_rate.data.find((cr)=>
				{
					return this.order_info.order.store_id == cr.store_id && (cr.currency_id == 'USD');
				}) || null;

				let billing_data:Billing_Data | undefined = response.billing_data.data.find(bd=>bd.id == response.order_info.order.billing_data_id );

				let address_id:number | null = response.order_info.order.billing_address_id ||
					this.order_info.client?.default_billing_address_id ||
					this.order_info.client?.default_shipping_address_id;

				let billing_data_id = billing_data?.id || response.order_info.store.default_billing_data_id;

				return forkJoin
				({
					address: address_id ? this.rest.address.get( address_id ) : of( null ),
					client_address: this.rest.address.search({limit:99999, eq:{user_id: this.order_info.order.client_user_id, type:'BILLING'}}),
					billing_data: billing_data ? of( billing_data ) : this.rest.billing_data.get( billing_data_id )
				})
			})
		)
		.subscribe((response)=>
		{

			let total = this.order_info.order.total-this.order_info.order.discount;

			let n = new NumeroALetras('','','/100','/100');
			this.total_letra = n.numeroALetras( total );
			console.log('TOTAL IS ', this.total_letra);

			this.currency = this.currency_list.find(c => c.id == this.order_info.order.currency_id) as Currency;
			//subtotal with the sum of the total of each product
			this.subtotal = this.order_info.order.subtotal;
			this.billing_data = response.billing_data;
			this.billing_address = response.address || response.client_address.data[0];
			this.show_billing_address = this.billing_address != null;
			this.setSatFormaPago();
			this.is_loading = false;
		},(error)=>this.showError(error));
	}

	setSatFormaPago()
	{
		if( this.order_info.order.paid_status == 'PENDING' )
			return '';

		if( this.order_info.order.paid_status == 'PARTIALLY_PAID' )
			return 'Crédito';

		switch( this.order_info.order.sat_forma_pago )
		{
			case '01':
			{
				return 'Efectivo';
			}
			case '02':
			{
				return 'Cheque';
			}
			case '04':
			{
				return 'Tarjeta de credito';
			}
			case '28':
			{
				return 'Tarjeta Debito';
			}
		}
		return '';
	}

	printDirect()
	{
		window.print();
	}

	goBack()
	{
		this.location.back();
	}
}
