<div class="break_pages px-0" style="background-color:white;color:black">
	<div class="text-center">
		<img [src]="rest.getImagePath(store.ticket_image_id, rest.local_preferences.default_ticket_image_id)" class="image_logo_ticket">
	</div>
	<div class="d-print-none text-center my-3">
		<button type="button" class="btn btn-primary" (click)="print()">
			<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path fill="currentColor" d="M90.443 35.141a1.825 1.825 0 0 0-1.818-1.708H75.511V11.857c0-1.012-.819-1.83-1.83-1.83H26.319c-1.011 0-1.83.818-1.83 1.83v21.576H11.375c-.969 0-1.754.755-1.818 1.708h-.012V71.91a1.83 1.83 0 0 0 1.83 1.829h13.114V58.425H75.51v15.314h13.114a1.83 1.83 0 0 0 1.83-1.829V35.141h-.011zm-19.919 6.49H29.476V16.844c0-1.012.819-1.83 1.83-1.83h37.387c1.011 0 1.83.818 1.83 1.83v24.787z"/><path fill="currentColor" d="M29.602 88.143c0 1.012.819 1.83 1.83 1.83h37.136c1.011 0 1.83-.818 1.83-1.83v-24.64H29.602v24.64z"/></svg>
			Imprimir
		</button>
	</div>
	<div class="px-0 text-center">
		<div class="fw-bold">Sucursal {{store.name}}</div>
		<div class="pb-3" *ngIf="billing_data.id == 0">
			<div>{{store.business_name}}</div>
			<div>{{store.rfc}}</div>
			<span>{{store.name}}&nbsp;</span>
			<span *ngIf="store?.address">{{store.address}}&nbsp;</span>
			<span *ngIf="store?.city">,{{store.city | titlecase}}&nbsp;</span>
			<span *ngIf="store?.state">,{{store.state | titlecase}}&nbsp;</span>
			<span *ngIf="store?.zipcode">,{{store.zipcode| titlecase}}&nbsp;</span>
		</div>
		<div class="" style="text-transform:uppercase" *ngIf="billing_data">
			<div>{{billing_data.razon_social}} {{billing_data.regimen_capital}}</div>
			<div>{{billing_data.rfc}}</div>
			<div>
				<span *ngIf="billing_data.address">{{billing_data.address}}&nbsp;</span>
				<span *ngIf="billing_data?.city">,{{billing_data.city | titlecase}}&nbsp;</span>
				<span *ngIf="billing_data?.state">,{{billing_data.state| titlecase}}&nbsp;</span>
			</div>
			<div>{{billing_data.zipcode | titlecase}}&nbsp;</div>
		</div>
		<span *ngIf="store.phone">Tel: {{store.phone}}</span>
	</div>
	<table class="border">
		<thead>
			<tr>
				<th>#{{store_sale_report?.id}}</th>
				<th class="text-end">{{store_sale_report?.date | date: 'd/MMM/Y'}}</th>
			</tr>
			<tr>
				<th colspan=2>
					<h2>Ventas ({{this?.store_sale_report?.order_ids?.split(',').length}})</h2>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr class="">
				<td class="w-50">Subtotal</td>
        <th class="text-end">{{total-discounts-tax-ieps|currency}}</th>
			</tr>
			<tr>
				<td class="w-25">IVA</td>
				<th class="text-end">{{tax|currency}}</th>
			</tr>
			<tr *ngIf="ieps>0">
				<td class="w-25">IEPS</td>
				<th class="text-end">{{ieps|currency}}</th>
			</tr>
			<tr>
				<td class="w-25">Total</td>
				<th class="text-end">{{total-discounts |currency}}</th>
			</tr>
		</tbody>
	</table>

	<table class="border my-3" *ngIf="this.rest.local_preferences.default_cash_close_receipt == 1 || this.rest.local_preferences.default_cash_close_receipt ==3">
		<thead>
			<tr>
				<th colspan="3 border">
					<h2>Ventas por categoría</h2>
				</th>
			</tr>
			<tr>
				<th>Categoria</th>
				<th class="border text-end w-25">Cantidad</th>
				<th class="border text-end w-25">Total</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let cr of category_resume_list">
				<td class="border">{{cr.name}}</td>
				<td class="border text-end w-25">{{cr.qty | number}}</td>
				<td class="border text-end w-25">{{cr.total | currency}}</td>
			</tr>
		</tbody>
	</table>
	<table class="border my-3">
		<thead>
			<tr>
				<th>Impuesto</th>
				<th class="text-end w-25">Artículos</th>
				<th class="text-end w-25">Total</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let cr of tax_resume_list">
				<td class="border">{{cr.name}}</td>
				<td class="border text-end w-25">{{cr.qty | number}}</td>
				<td class="border text-end w-25">{{cr.total | currency}}</td>
			</tr>
		</tbody>
	</table>

	<table class="border my-3">
		<!--tr>
			<th>Ventas</th>
			<td>{{total-discounts|currency}}</td>
		</tr-->
		<thead>
			<tr>
				<th colspan=2>
					<h2>Balance</h2>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Retiros</td>
				<th class="text-end">{{0 | currency}}</th>
			</tr>
			<tr>
				<td>Descuentos</td>
				<th class="text-end">{{discounts| currency}}</th>
			</tr>
			<tr>
				<td>Total en Caja</td>
				<th class="text-end">{{box_amount | currency}}</th>
			</tr>
		</tbody>
	</table>
	<table class="border">
		<tr *ngFor="let payment_entry of payment_entries">
			<td>{{payment_entry.name}}</td>
			<th class="text-end">
				{{payment_entry.amount | currency}} {{payment_entry.currency_id}}
			</th>
		</tr>
	</table>
<!--
{{total|currency}} <br>
{{subtotal | currency}} <br>
{{iva | currency}} <br>
{{amount_paid | currency }}<br>
<table>
	<tr>
		<th class="text-end px-3">id</th>
		<th class="text-end px-3">Total</th>
		<th class="text-end px-3">Descuento</th>
		<th class="text-end px-3">Subtotal</th>
		<th class="text-end px-3">Tax</th>
		<th class="text-end px-3">Tax 2</th>
	</tr>
	<tr *ngFor="let oi of order_info_list">
		<td class="text-end px-3">{{oi.order.id}}</td>
		<td class="text-end px-3">{{oi.order.total}}</td>
		<td class="text-end px-3">{{oi.order.discount}}</td>
		<td class="text-end px-3">{{oi.order.subtotal | number:'1.2-2'}}</td>
		<td class="text-end px-3">{{oi.order.subtotal*0.08 | number:'1.2-2'}}</td>
		<td class="text-end px-3">{{oi.order.tax | number:'1.2-2'}}</td>
	</tr>
</table-->
</div>
