import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ExcelUtils } from 'src/app/classes/ExcelUtils';
import { Utils } from 'src/app/classes/Utils';
import {PaymentInfo} from 'src/app/models/models';
import {Bank_Movement, Payment, User} from 'src/app/models/RestModels';
import {RestResponse, SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-list-payment-expenses',
	templateUrl: './list-payment-expenses.component.html',
	styleUrls: ['./list-payment-expenses.component.css']
})
export class ListPaymentExpensesComponent extends BaseComponent implements OnInit
{
	payment_search:SearchObject<Payment> = this.getEmptySearch();
	payment_info_list:PaymentInfo[] = [];
	user_list:User[] = [];
	order_by_payment_id:Record<number,number> = {};

	show_update_payment:boolean = false;
	selected_payment_info:PaymentInfo = null;

	ngOnInit()
	{
		this.path = '/list-payment-expenses';

		this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields =
			[
				"id","company_id","type","payment_amount","received_amount",
				"change_amount","currency_id","exchange_rate","received_by_user_id",
				"paid_by_user_id","created","updated"
			];
			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];
			this.payment_search = this.getSearch(queryParamMap, fields );

			this.setTitle('Lista de Pagos');
			this.current_page = this.payment_search.page;
			this.payment_search.eq.type = 'expense';
			this.payment_search.sort_order=['id_DESC'];

			this.payment_info_list = [];
			this.is_loading = true;

			this.subs.sink = this.rest.payment_info.search(this.payment_search)
			.subscribe((responses)=>
			{
				responses.data.forEach((payment_info)=>
				{
					let ids = [];

					payment_info.movements.forEach((bmi)=>
					{
						bmi.bank_movement_orders.forEach((bmo)=>
						{
							ids.push( bmo.order_id );
						})
					})

					if( ids.length > 0 )
					{
						this.order_by_payment_id[ payment_info.payment.id ] = ids[0];
					}
				});

				this.payment_info_list = responses.data;
				this.setPages( this.payment_search.page, responses.total );
				this.is_loading = false;
			},(error)=>this.showError(error));
		});
	}

	imprimir(payment_info:PaymentInfo,evt:Event)
	{
		let x	= window.open('/#/print-payment-expense/'+payment_info.payment.id+'/print=1');
		console.log( evt );
	}
	exportExcel()
	{
		let search:SearchObject<Payment> = {...this.payment_search};
		search.limit = 100;

		this.subs.sink = this.rest.payment_info
		.search( search ).pipe
		(
			mergeMap((response)=>
			{
				let observables:Observable<RestResponse<PaymentInfo>>[] = [ of(response) ];
				let pages= response.total/100;

				for(let i=1;i<pages;i++)
				{
					let s:SearchObject<Payment> = {...search};
					s.limit = 100;
					s.page = i;
					observables.push( this.rest.payment_info.search( s ) );
				}

				return forkJoin( observables );
			}),
			mergeMap((response)=>
			{
				let all:RestResponse<PaymentInfo> = { total: 0, data: []};

				response.forEach((i)=>{
					all.total = i.total;
					all.data.push(...i.data);
				})

				return of( all );
			})
		)
		.subscribe((response)=>
		{
			let toExport:Object[] = new Array();

			for(let pi of response.data )
			{
				let row = {
					Id: pi.payment.id,
					Cantidad: pi.payment.payment_amount,
					Tag: pi.payment.tag,
					Usuario: pi.payment.tag,
					Concepto: pi.payment.concept,
					fecha: Utils.getLocalMysqlStringFromDate( pi.payment.created )
				}
				toExport.push( row );
			}

			let headers = ['Id','Cantidad','Tag','Usuario','Concepto'];
			ExcelUtils.array2xlsx(toExport,'Egresos.xlsx', headers);
		},(error)=>{this.showError(error)});
	}

	showPdfFile(attachment_id:number)
	{
		window.open(this.rest.getFilePath(attachment_id));
	}

	onShowUpdatePayment(payment_info:PaymentInfo)
	{
		this.selected_payment_info = payment_info;
		this.show_update_payment = true;
	}

	updatePaymentInfo(evt: Event)
	{
		evt.preventDefault();

		let bank_movement_list:Bank_Movement[] = this.selected_payment_info.movements.map((bmi)=> bmi.bank_movement );
		
		this.subs.sink = this.rest.bank_movement.update(bank_movement_list)
		.subscribe((response)=>
		{
			this.showSuccess('Pagos actualizados');
			this.show_update_payment = false;
			this.selected_payment_info = null;
		}, (error)=>this.showError(error));
		
	}
	
}
