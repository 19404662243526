import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Price_Type, User, User_Attachment} from '../../models/RestModels';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { UserBalance } from 'src/app/models/models';
import { Address } from 'src/app/models/RestModels';

@Component({
	selector: 'app-save-client',
	templateUrl: './save-client.component.html',
	styleUrls: ['./save-client.component.css']
})
export class SaveClientComponent extends BaseComponent implements OnInit {

	address_list:Address[] = [];
	user:UserBalance= GetEmpty.client();
	user_attachments_list:User_Attachment[] = [];
	new_user_attachment:User_Attachment = GetEmpty.user_attachment();
	new_file: File | null = null;
	price_type_list:Price_Type[] = [];
	agents:User[] = [];

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((params)=>
			{
				this.is_loading = true;
				let empty_user = GetEmpty.client();
				let user_attachment_obs = params.has('id') && this.rest.local_preferences.user_attachments != 'DISABLED'
					? this.rest.user_attachment.search({eq:{user_id:parseInt(params.get('id')), status: 'ACTIVE'}})
					: of({data:[],total:0});

				return forkJoin
				({
					user: params.has('id') ? this.rest.user.get( params.get('id') ) : of( empty_user ),
					user_attachments: user_attachment_obs,
					price_type: this.rest.getPriceTypes(true),
					address: this.rest.address.search({eq:{user_id:parseInt(params.get('id'))}}),
					agents: this.rest.current_permission.add_user
						? this.rest.user.search({eq:{ type:'USER' },sort_order:['name_ASC'],limit:999999})
						: of({data:[],total:0})
				})
			})
		)
		.subscribe((responses)=>
		{
			this.address_list = responses.address.data;
			this.user = responses.user;
			this.user_attachments_list = responses.user_attachments.data;
			this.price_type_list = responses.price_type.data;
			this.agents = responses.agents.data;
			this.is_loading = false;
		}, (error) => this.showError(error));
	}

	setUserFile(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		if (input.files.length)
		{
			this.new_file = input.files[0];
		}
		else
		{
			this.new_file = null;
		}
	}

	uploadUserFile(evt:Event)
	{
		evt.preventDefault();
		if( !this.new_user_attachment || !this.new_user_attachment.alias )
		{
			this.showError('Por favor ingrese un nombre y el archivo');
			return;
		}
		if( this.new_file && this.new_user_attachment.alias )
		{
			this.is_loading = true;
			this.rest.uploadAttachment(this.new_file, false).subscribe((response)=>
			{
				this.rest.user_attachment.create({user_id:this.user.id,attachment_id:response.attachment.id,alias:this.new_user_attachment.alias}).subscribe((user_file)=>
				{
					this.user_attachments_list.push(user_file);
					this.new_file = null;
					this.new_user_attachment = GetEmpty.user_attachment();
					this.is_loading = false;

					this.showSuccess('Archivo subido exitosamente');
				},(error)=>this.showError(error));

			},(error)=>this.showError(error));
		}
	}

	deleteUserFile(user_file:User_Attachment)
	{
		this.confirmation.showConfirmAlert(user_file,'Eliminar Archivo','¿Estás seguro?').subscribe( (response)=>
		{
			if( response.accepted )
			{
				this.rest.user_attachment.update({...user_file, status: 'DELETED'}).subscribe((response)=>
				{
					this.user_attachments_list = this.user_attachments_list.filter( (f)=> f.id != user_file.id );
					this.showSuccess('Archivo eliminado exitosamente');
				},(error)=>this.showError(error));
			}
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.user.id	)
		{
			this.subs.sink	= this.rest.user.update( this.user ).subscribe((user)=>{
				this.is_loading = false;
				this.router.navigate(['/list-client']);
				this.showSuccess('cliente se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.user.type = 'CLIENT';

			if( this.user.email )
			{
				this.subs.sink = this.rest.platform_client.search({eq:{email:this.user.email}}).pipe
				(
					mergeMap((response)=>
					{
						if( response.data.length )
						{
							this.user.platform_client_id = response.data[0].id;
						}

						if( response.data.length )
							return of( response.data[0] )

						return this.rest.platform_client.create
						({
							name: this.user.name,
							phone: this.user.phone,
							email: this.user.email,
							password: this.user.password,
						})
					}),
					mergeMap((platform_client)=>
					{
						this.user.platform_client_id = platform_client.id;
						return this.rest.user.create( this.user );
					})
				).subscribe((response)=>
				{
					this.showSuccess('La información del cliente se guardó exitosamente');
					this.router.navigate(['/list-client']);
					this.is_loading = false;
				});
			}
			else
			{
				this.subs.sink	= this.rest.user.create( this.user ).subscribe((user)=>{
					this.is_loading = false;
					this.showSuccess('cliente se guardó exitosamente');
					this.router.navigate(['/list-client']);
				},(error)=>this.showError(error));
			}
		}
	}
}
