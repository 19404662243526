<div class="p-3" style="min-height:400px;">
	<div class="row">
		<div class="col-12 dropdown">
			<label>Repartidor</label>
			<input type="text" [ngModel]="user_name" name="user_name" (ngModelChange)="onNameChange($event)" class="form-control">
			<div class="dropdown-menu show w-100" *ngIf="user_list.length && user_name.length  > 1">
				<button class="dropdown-item" *ngFor="let user of user_list" type="button" (click)="selectUser(user)">
					<img src="" width="40p" [src]="rest.getImagePath(user.image_id)">
					{{user.name | titlecase }}
				</button>
			</div>
		</div>
		<!--div class="col-12">
			<button type="button" class="btn btn-danger">Cancelar</button>
		</div-->
	</div>
</div>
