import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { StockAlertDiff, StockAlertInfo } from 'src/app/models/models';
import { Stock_Alert, Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-maxmin-report',
	templateUrl: './maxmin-report.component.html',
	styleUrls: ['./maxmin-report.component.css']
})
export class MaxminReportComponent extends BaseComponent implements OnInit
{
	stock_alert_list: StockAlertInfo[] = [];
	store_id: number = 0;
	search_str:string = '';
	store_list: Store[] = [];
	store_name:string = '';

	ngOnInit(): void
	{
		this.path='/maxmin-report';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				this.is_loading = true;

				let param_map = response[1];
				let query_param_map = response[0];

				//console.log('My store', this.rest.current_user.store_id);
				this.store_id = this.rest.current_user.store_id;

				let fields = ['store_id','availability_type'];
				let extra_keys = ['category_name'];
				let search:SearchObject<StockAlertDiff> = this.getSearch(query_param_map, fields,extra_keys);

				let cname = query_param_map.get('category_name') || '';;
				search.search_extra.category_name = cname;
				search.sort_order = ['minmax_diff_DESC'];

				if( !search.eq.store_id || !this.rest.current_permission.global_add_stock )
				{
					search.eq.store_id = this.store_id;
				}

				return forkJoin
				({
					stores: this.rest.getStores(),
					stock_alerts: this.rest.stock_alert_info.search( search )
				});
			})
		)
		.subscribe((responses)=>
		{
			this.store_list = responses.stores.data;
			this.is_loading = false

			if( !this.rest.current_permission.global_add_stock )
			{
				let store =  this.store_list.find(s=>s.id == this.rest.current_user.store_id );
				this.store_name = store?.name;
			}


			this.stock_alert_list = responses.stock_alerts.data.map(i=>
			{
				if( i.stock_alert && i.stock_alert.min )
					i.min_new_qty = i.stock_alert.min;

				if( i.stock_alert && i.stock_alert.max )
					i.max_new_qty = i.stock_alert.max;

				return i;
			});
		},(error)=>this.showError(error));
	}

	doASearch(evt:Event)
	{
		//console.log('Searching store id', this.store_id);
		this.router.navigate(['/maxmin-report'],{queryParams:{category_name:this.search_str,'eq.store_id': this.store_id }});
	}

	saveAlerts(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let to_save:Stock_Alert[] = this.stock_alert_list
		.filter((sai)=>
		{
			if( (sai.max_new_qty  || sai.min_new_qty ) && sai.stock_alert  == null )
				return true;

			if(  sai.stock_alert  != null && ( sai.max_new_qty != sai.stock_alert.max || sai.min_new_qty != sai.stock_alert.min ) )
				return true;

			return false;
		})
		.map((sai)=>
		{
			return {
				store_id: this.store_id,
				item_id: sai.item.id,
				min: sai.min_new_qty || 0,
				max: sai.max_new_qty || null,
				created: new Date(),
				updated: new Date(),
				id: 0,
				updated_by_user_id: this.rest.current_user.id,
				created_by_user_id: this.rest.current_user.id
			};
		});

		if( to_save.length )
		{
			this.is_loading = true;
			this.rest.stock_alert
			.batchCreate( to_save )
			.subscribe((_response)=>{
				this.showSuccess('Los maximos y minimos se guardaron con exito');
				this.search( this.getEmptySearch() );
			});
		}
		else
		{
			this.showError('Cambiar un valor ');
		}
	}
}
