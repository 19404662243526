import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin, Observable, of } from 'rxjs';

import {Billing_Data, Currency, Currency_Rate, Price_List, Store, User } from '../../models/RestModels';
import {Utils} from 'src/app/classes/Utils';
import { SearchObject } from 'src/app/services/Rest';

@Component({
	selector: 'app-save-store',
	templateUrl: './save-store.component.html',
	styleUrls: ['./save-store.component.css']
})

export class SaveStoreComponent extends BaseComponent implements OnInit {
	store:Partial<Store> = {
		city:'',
		//exchange_rate:20,
		name:'',
		tax_percent:8,
		default_billing_data_id:null,
		status:'ACTIVE',
		suggested_tip: 0,
		print_delay: 0,
		pos_category_preferences:'DEFAULT_BY_PRODUCT',
		electronic_transfer_percent_fee: 0,
		autofacturacion_settings : 'DISABLED'
	};

	price_list_list:Price_List[] = [];
	billing_data_list:Billing_Data[] = [];
	print_receipt: string = '';

	counter = 0;

	store_client_user_list:User[] = [];

	ngOnInit()
	{
		this.counter++;
		this.subs.sink = this.route.paramMap.subscribe( params =>
		{
			this.counter++;
			this.setTitle(params.has('id') ? 'Actualizar Sucursal' : 'Nueva Sucursal');

			let def_store:Partial<Store> = {
				city:'',
				//exchange_rate:20,
				name:'',
				tax_percent:8,
				suggested_tip: 0,
				status:'ACTIVE',
				modo_facturacion:'DESGLOSADA',
				default_billing_data_id: null,
				pos_category_preferences: 'DEFAULT_BY_PRODUCT',
				autofacturacion_settings: 'DISABLED',
				default_currency_id: 'MXN',
				show_facturacion_qr: 'NO',

			};

			this.is_loading = true;

			let store_id = params.has('id') ?parseInt(params.get('id')) as number: 0 ;

			if( Number.isInteger(store_id) )
			{
				let so:SearchObject<User> = this.getEmptySearch();
				so.eq.store_id = store_id;
				so.eq.type = 'CLIENT';
				so.sort_order =['name_ASC'];
				so.limit = 999999;

				this.subs.sink = this.rest.user.search( so )
				.subscribe((responses)=>
				{
					this.store_client_user_list = responses.data;
				},()=>
				{
					this.showError('No se pudieron cargar la informacion del cliente-sucursal');
				});
			}

			this.subs.sink	= forkJoin
			({
				store: params.has('id') ? this.rest.getStore( params.get('id') ) : of( def_store ),
				price_list: this.rest.price_list.search({limit:9999,sort_order:['name_ASC']}),
				billing_data: this.rest.billing_data.search({limit:9999}),
				currency: this.rest.currency.search({limit:9999}),
				currency_rate: this.rest.currency_rate.search({eq:{store_id},limit:9999})
			})
			.subscribe((responses)=>
			{
				this.is_loading = false;

				console.log('tercero');

				this.store= responses.store;
        this.print_receipt = this.store.print_receipt ? ''+this.store.print_receipt : '';

				this.price_list_list = responses.price_list.data;
				this.billing_data_list = responses.billing_data.data;

				if( this.price_list_list.length == 1 )
				{
					this.store.price_list_id = this.price_list_list[0].id;
				}

			},(error)=>this.showError( error ));
		});
	}

	save()
	{
		this.is_loading = true;
		let obs:Observable<Store> = this.store.id
			? this.rest.store.update( this.store )
			: this.rest.store.create( this.store );

		this.subs.sink = obs.subscribe
		(
			(store)=>
			{
				this.store = store;
				this.showSuccess('La informacion de la sucursal se guardo exitosamente');

				console.log('Store from ws', store );

				this.is_loading = false;
				this.router.navigate(['/list-store']);

			},(error)=>this.showError(error)
		);
	}

	printReceiptChange(str_val: string)
	{
		this.print_receipt = str_val;
		if( str_val == '' )
		{
			this.store.print_receipt = null;
			return;
		}
		this.store.print_receipt = parseInt(str_val) as number;
	}
}
