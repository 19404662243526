<div class="container-fluid">
	<h1 *ngIf="show_title" class="text-capitalize my-3">{{storage.id ? 'Actualizar':'Agregar'}} almacenaje</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="p-3" [class.card]="isCardShaped">
			<div class="row p-1">
				<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center">
					<label class="">Sucursal</label>
					<select name="store_id" [(ngModel)]="storage.store_id" class="form-control" required>
						<option [ngValue]="undefined">Seleccione una sucursal</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="storage.name" class="form-control" required>
				</div>
				<!--
					<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center">
					<label class="">Padre del almacenamiento</label>
					<select name="parent_storage_id" [(ngModel)]="storage.parent_storage_id" class="form-control">
						<option [ngValue]="undefined">Seleccione un almacenaje</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				-->
				<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center">
					<label class="">Tipo</label>
					<select name="storage_type_id" [(ngModel)]="storage.storage_type_id" class="form-control" required>
						<option [ngValue]="undefined">Seleccione un tipo de espacio</option>
						<option *ngFor="let tipo of storage_type_list" [value]="tipo.id">{{tipo.name}}</option>
					</select>
				</div>
				<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center">
					<label class="">Codigo</label>
					<input type="text" name="code" [(ngModel)]="storage.code" class="form-control">
				</div>
				<div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2 align-self-center text-center">
					<app-image-uploader [(image)]="storage.image_id"></app-image-uploader>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
