<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8 col-md-10">
			<h1 class="my-3">Relacion de Facturas</h1>
		</div>
		<div class="col-4 col-md-2 text-end">
			<button class="btn btn-primary" (click)="export()">Exportar</button>
		</div>
	</div>
	<div class="card my-3 p-3" *ngIf="!(order_search?.csv?.status?.length) && !rest.is_offline">
		<div class="row">
			<div class="col-6 col-md-3 col-lg-2 form-group">
				<label class="">Sucursal</label>
				<div class="fw-bold" *ngIf="!rest.current_permission.global_pos">{{ store_dictionary[ rest.current_user.store_id ]?.name | titlecase}}</div>
				<select name="store_id" [(ngModel)]="order_search.eq.store_id" class="form-control" *ngIf="rest.current_permission.global_pos">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 col-lg-3 form-group order-2">
				<label class="">Fecha Inicial</label>
				<input type="datetime-local" name="inicial_created" [ngModel]="fecha_inicial" (ngModelChange)="fechaIncialChange($event)" class="form-control">
			</div>
			<div class="col-6 col-md-3 col-lg-3 form-group order-2">
				<label class="">Fecha Final</label>
				<input type="datetime-local" name="final_created" [ngModel]="fecha_final" (ngModelChange)="fechaFinalChange($event)" class="form-control">
			</div>
			<div class="col-6 col-md-3 col-lg-3 form-group order-2">
				<label class="">UUID</label>
				<input type="text" maxlength="36" name="uuid" [(ngModel)]="order_search.eq.uuid" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group order-2">
				<label class="">Total</label>
				<input type="number" name="total_final" [(ngModel)]="order_search.search_extra.total_final" class="form-control">
			</div>
			<div class="col-6 col-md-3 col-lg-2 form-group order-md-2" *ngIf="rest.current_permission.advanced_order_search">
				<label class="">Búsqueda Avanzada</label>
				<div class="form-check form-switch">
					<label class="form-check-label">
						<input type="checkbox" name="show_advanced_search" value=1 class="form-check-input" (click)="toggleAdvancedSearch($event)" [checked]="show_advanced_search">
					</label>
				</div>
			</div>
			<ng-container *ngIf="show_advanced_search">
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Precio</label>
					<select name="price_type_id" [(ngModel)]="order_search.eq.price_type_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of price_type_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus Pago</label>
					<select name="paid_status" [(ngModel)]="order_search.eq.paid_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="PARTIALLY_PAID">Pago Parcial</option>
						<option value="PAID">Pagado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus</label>
					<select name="status" [(ngModel)]="order_search.eq.status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="ACTIVE">Activas</option>
						<option value="CANCELLED">Canceladas</option>
						<option value="CLOSED">Finalizadas</option>
						<option value="PENDING">Pendientes</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus De Entrega</label>
					<select name="delivery_status" [(ngModel)]="order_search.eq.delivery_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="DELIVERED">Entregado</option>
						<option value="CANCELLED">Cancelado</option>
						<option value="READY_TO_PICKUP">Listo para Recoger</option>
						<option value="SENT">Enviado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Metodo de pago</label>
					<select name="transaction_type" [(ngModel)]="order_search.search_extra.transaction_type" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option value="CASH">Efectivo</option>
						<option value="CREDIT_CARD">Tarjeta Credito</option>
						<option value="DEBIT_CARD">Tarjeta Debito</option>
						<option value="CHECK">Cheque</option>
						<option value="COUPON">Coupon</option>
						<option value="TRANSFER">Transferencia</option>
						<option value="DISCOUNT">Descuento</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Folio</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.eq.store_consecutive" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Cliente</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.lk.client_name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Sin Cliente Registrado</label>
					<div class="form-check form-switch">
						<label class="form-check-label">
							<input type="checkbox" name="publico_general" class="form-check-input" (click)="togglePublicoGeneral($event)" [checked]="order_search.search_extra.publico_general">
						</label>
					</div>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Facturado</label>
					<select name="facturado" [(ngModel)]="order_search.csv.facturado" class="form-control">
						<option value="YES,NO" [selected]="order_search.csv.facturado == null">Todos</option>
						<option value="YES">Facturado</option>
						<option value="NO">Sin Facturar</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Cajero</label>
					<select name="cashier_id" [(ngModel)]="order_search.eq.cashier_user_id" class="form-control">
					<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of cashier_user_list" [value]="c.id">
							{{c.name | titlecase}}
							<ng-container *ngIf="c.username?.toLowerCase()?.localeCompare( c?.name?.toLowerCase() ) != 0">({{c.username}})</ng-container>
						</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Con Descuento</label>
					<select name="with_discounts" [(ngModel)]="order_search.search_extra.with_discounts" class="form-control">
						<option value="">Todos</option>
						<option value="1">Con Descuento</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Código Facturación</label>
					<input type="text" name="lkfacturacion_code" [(ngModel)]="order_search.lk.facturacion_code" class="form-control">
				</div>
			</ng-container>
			<div class="col-6 col-md-3 col-lg-2 order-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(order_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<table class="mb-0 w-100">
			<thead class="thead-light">
				<tr>
					<th>Id</th>
					<th>Cliente</th>
					<th>Factura UUID</th>
					<th>Sucursal</th>
					<th>Fecha</th>
					<th class="text-center">Estatus</th>
					<th class="text-end">Saldo</th>
					<th class="text-end">Total</th>
				</tr>
			</thead>
			<tbody *ngIf="price_type_list.length">
				<tr *ngFor="let oi of order_info_list" class="border align-middle">
					<td>
						<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
						<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
						</span>
						<div>
							<a [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
						</div>
					</td>
					<td>
						<a *ngIf="!rest.is_offline && (oi.order.status == 'CANCELLED' || oi.order.status == 'CLOSED' || oi.order.paid_status == 'PAID')" [routerLink]="['/view-order',oi.order.id]">
							{{oi.order.sat_razon_social || oi?.client?.name || oi.order.client_name }}
						</a>
					</td>
					<td>{{oi.uuid | uppercase}}</td>
					<td>{{oi.store.name | titlecase }}</td>
					<td>
						<abbr title="{{oi.order.closed_timestamp || oi.order.created | date: 'MMM d, y HH:mm' }}">{{(oi.order.closed_timestamp || oi.order.created) | shortDate}}</abbr>
					</td>
					<td class="text-center">
						<div *ngIf="oi.order.status != 'CANCELLED'">
							<span *ngIf="oi.order.paid_status == 'PAID' " class="text-success">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
							</span>
						</div>
						<div *ngIf="oi.order.status == 'CANCELLED'">
							<span *ngIf="!oi.order.system_activated" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 16 16"><path fill="currentColor" d="M6.923 1.378a3 3 0 0 1 2.154 0l4.962 1.908a1.5 1.5 0 0 1 .961 1.4v6.626a1.5 1.5 0 0 1-.961 1.4l-4.962 1.909a3 3 0 0 1-2.154 0l-4.961-1.909a1.5 1.5 0 0 1-.962-1.4V4.686a1.5 1.5 0 0 1 .962-1.4l4.961-1.908Zm1.795.933a2 2 0 0 0-1.436 0l-1.384.533l5.59 2.116l1.948-.834L8.718 2.31ZM14 4.971L8.5 7.33v6.428c.074-.019.146-.042.218-.07l4.962-1.908a.5.5 0 0 0 .32-.467v-6.34Zm-6.5 8.786V7.33L2 4.972v6.34a.5.5 0 0 0 .32.467l4.962 1.908c.072.028.144.051.218.07ZM2.564 4.126L8 6.456l2.164-.928l-5.667-2.146l-1.933.744Z"/></svg>
							</span>
							<span *ngIf="oi.order.system_activated" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 640 512"><path fill="currentColor" d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5c-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64L69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"/></svg>
							</span>
							<span *ngIf="oi.order.paid_status == 'PENDING' " class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.89-8.9c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.44-.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6.56-2.62 2.85-2.62 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 3.02 2.96V19h1.75v-1.24c.52-.09 3.02-.59 3.02-3.22c.01-1.39-.6-2.61-3-3.44z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.paid_status != 'PENDING'" class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
							</span>
						</div>
						<span [ngClass]="{'text-danger':oi.order.paid_status!='PENDING','text-warning':oi.order.paid_status == 'PENDING'}" *ngIf="oi.order.status == 'CANCELLED'">
							Cancelada
						</span>
					</td>
					<td class="text-end border-right">{{(oi.order.total-oi.order.discount)-oi.order.amount_paid | currency}}</td>
					<td class="text-end border-right">{{(oi.order.total-oi.order.discount) | currency}}</td>
					<td class="text-end nowrap"></td>
				</tr>
			</tbody>
		</table>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert" *ngIf="this.rest.is_offline">No se encontraron elementos</div>
				<div class="alert alert-light m-3 text-center" role="alert" *ngIf="!this.rest.is_offline">
					No se encontraron elementos.
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

