import { Component, OnInit} from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import Hashids from 'hashids';
import { forkJoin, from, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { OrderInfo, PaymentInfo } from 'src/app/models/models';
import { Bank_Movement, Bank_Movement_Order, Billing_Data, Currency_Rate, Payment, Store, Table } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/base.component';


interface BMO_custom extends Bank_Movement
{
	transaction_label:string;
	amount_received: number;
	currency_id: string;
	subtotal:number;
	exchange_rate:number;
}

@Component({
	selector: 'app-print-receipt5',
	templateUrl: './print-receipt5.component.html',
	styleUrls: ['./print-receipt5.component.css']
})
export class PrintReceipt5Component extends BaseComponent implements OnInit
{
	payment_info:PaymentInfo = null;
	order_info:OrderInfo | null = null;
	print_and_close:boolean = false;
	print_copies:number = 1;
	copies:number[]=[1];
	that:any = this;
	discount:number = 0;
	force_close:boolean = false;
	has_dolars:boolean = false;
	currency_rate:Currency_Rate | null = null;
	billing_data:Billing_Data | null = null;

	url_for_facturacion:string = 'https://'+window.location.hostname+'/#/facturar';
	element_type = NgxQrcodeElementTypes.URL;
	error_correction_level: NgxQrcodeErrorCorrectionLevels.HIGH;
	table:Table | null = null;
	movements:BMO_custom[] = [];

	article_discount:number = 0;

	hashids = new Hashids
	(
		environment.app_settings.hashids_salt,
		environment.app_settings.hashids_length,
		environment.app_settings.hashids_alphabet
	);

	legend:Record<string,string> = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'T. Credito',
		'DEBIT_CARD-MXN':'T. Debito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
		'RETURN_DISCOUNT-MXN':'Descuento',
	}

	service_type_dictionary:Record<string,string> = {
		'QUICK_SALE': '',
		'TOGO':'PARA LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'RECOGER',
	}

	beforeprint = ()=>
	{
		console.log('beforeprint');

    if( this.store.printer_ticket_config  || this.rest.printer_ticket_config )
    {
      document.getElementById('print-config').innerHTML = this.store.printer_ticket_config || this.rest.printer_ticket_config;
      return;
    }


		document.getElementById('print-config').innerHTML = '';
	};

	afterprint = function(){
		console.log('afterprint');
		document.getElementById('print-config').innerHTML = '';
	};

	is_mobile: boolean = false;
	store:Store = GetEmpty.store();
    show_bill_code: boolean = false;

	ngOnDestroy(): void {
		window.removeEventListener('beforeprint', this.beforeprint);
		window.removeEventListener('afterprint', this.afterprint);
		super.ngOnDestroy();
	}

	ngAfterViewInit()
	{

	}

	ngAfterViewChecked()
	{
	}

	ngOnInit(): void
	{
		let regex = /mobi/i;

		this.is_mobile = regex.test(navigator.userAgent);
		window.addEventListener('beforeprint' , this.beforeprint);
		window.addEventListener('afterprint' , this.afterprint );

		this.subs.sink = this.route.paramMap.subscribe((paramMap)=>
		{
			this.currency_rate = null;
			this.print_and_close = paramMap.has('print');
			this.print_copies = paramMap.has('copies') ? parseInt(paramMap.get('copies')) : 1;
			this.force_close = paramMap.has('print') ? paramMap.get('print') == "1" || paramMap.get('print')=='print' : false;

			console.log('Print copies has', this.copies );
			let c = new Array( this.print_copies );
			c.fill(1);
			this.copies = c;

			let search:SearchObject<Payment> = this.getEmptySearch();
			search.search_extra = {order_id:paramMap.get('order_id')};
			search.limit = 1;
			search.sort_order=['id_ASC'];

			console.log("Se inicializo cuantas veces");

			let something = paramMap.has('order_id') ? paramMap.get('order_id') : paramMap.get('order_sync_id');

			this.setTitle('ReciboOrden-'+something);

			if( paramMap.has('order_id') )
			{
				this.subs.sink = this.rest.order_info.get( paramMap.get('order_id') ).pipe
				(
					mergeMap((response)=>
					{
						return forkJoin({
							order: of( response ),
							table: response.order.table_id ? this.rest.getTable( response.order.table_id ) : of( null )
						});
					}),
					mergeMap((response)=>
					{
						this.table = response.table;
						return of( response.order );
					}),
					mergeMap((response)=>
					{
						return forkJoin
						({
							order: of( response ),
							store: this.rest.getStore( response.order.store_id ),
							payment_info: this.rest.payment_info.search( search ),
							stores: this.rest.getStores(true),
							billing_data: this.rest.getBillingData(),
							rates: this.rest.getCurrencyRates()
						})
					})
				)
				.subscribe((responses)=>
				{
					responses.order.items.forEach((oii)=>
					{
						oii.serials_string = oii.serials
							.map((i)=>i.serial.serial_number)
							.join(',');
					});

					this.store = responses.store;
					this.rest.printer_ticket_config = responses.store.printer_ticket_config;
					//this.rest.createStructuredItems( responses.order );
					this.rest.createResumeItems( responses.order );

					this.billing_data = responses.billing_data.data.find(bd=>bd.id == responses.store.id) || null;
					this.currency_rate = responses.rates.data.find((cr)=>cr.currency_id == 'USD' && cr.rate > 1 && cr.store_id == this.store.id) || null;

					this.order_info = responses.order;
					this.article_discount = this.getArticleDiscount( this.order_info );
					this.setShowCode();
					this.discount = responses.order.items.reduce((p,i)=>p+i.order_item.discount,0);

					if( responses.payment_info.data.length )
					{
						this.payment_info = responses.payment_info.data[0];
						this.setMovements( this.payment_info  );
					}

					if( this.payment_info?.movements )
					{
						dance:
						for(let bmi of this.payment_info.movements)
						{
							for(let bmo of bmi.bank_movement_orders )
							{
								if( bmo.currency_id != this.order_info.store.default_currency_id )
								{
									this.currency_rate = {
										store_id: responses.order.store.id,
										currency_id: bmo.currency_id,
										rate: bmo.exchange_rate
									};
									break dance;
								}
							}
						}
					}

					if( this.print_and_close)
					{
						console.log('Pringint');
						setTimeout(()=>{
								window.print();
								if( !this.is_mobile )
									window.close();
						},700);
					}
				},(error)=>this.showError(error));
			}
			else
			{
				console.log('Leyendo ooffline db order sync id is ', paramMap.get('order_sync_id') );
				this.subs.sink = forkJoin
				({
					order: from( this.rest.getOfflineOrderInfoBySyncId( paramMap.get('order_sync_id') )),
					payment: from(this.rest.getOfflinePaymentByOderSyncId( paramMap.get('order_sync_id') )),
					stores: this.rest.getStores()
				})
				.subscribe((response)=>
				{
					try
					{
						this.payment_info =  response.payment;
						this.order_info = response.order;

						if( this.payment_info.movements )
						{
							dance:
							for(let bmi of response.payment.movements)
							{
								for(let bmo of bmi.bank_movement_orders )
								{
									if( bmo.currency_id != this.order_info.store.default_currency_id )
									{
										this.currency_rate = {
											store_id: response.order.store.id,
											currency_id: bmo.currency_id,
											rate: bmo.exchange_rate
										};
										break dance;
									}
								}
							}
						}

						console.log('Buscqando order ',response );
						//this.order_info = order_info;
						console.log('payment info ',this.payment_info);

						if( this.force_close)
						{
							console.log('Pringint');
							setTimeout(()=>{
								window.print();
								window.close();
							},700);
						}
					}
					catch(e)
					{
						console.error('error ocurrido', e );
						this.showError('Ocurrio un error aqui');
					}
				});
			}
		});
	}

	/*
1-1643157008003
1-1643157096411
1-1643157134876
*/
	getArticleDiscount(order_info:OrderInfo):number
	{
		let discount = order_info.items.reduce((prev,oi)=>
		{
			//su son iguales, entonces no hay descuento
			if( oi.order_item.unitary_price_meta == oi.order_item.original_unitary_price )
				return prev;

			let tax = oi.order_item.tax == 0 ? 0 : order_info.order.tax_percent;

			//tax excempt
			if(tax == 0)
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		}, 0);
		return discount;
	}

	setMovements(payment_info:PaymentInfo)
	{
		let bmo_custom:BMO_custom[] = [];

		for(let bmi of payment_info.movements)
		{
			let exchange_rate =bmi.bank_movement_orders.length ? bmi.bank_movement_orders[0].exchange_rate : 1;
			bmo_custom.push
			({
				...bmi.bank_movement,
				transaction_label: this.legend[ bmi.bank_movement.transaction_type+'-'+bmi.bank_movement.currency_id ],
				amount_received: bmi.bank_movement.amount_received,
				currency_id: bmi.bank_movement.currency_id,
				subtotal: bmi.bank_movement.currency_id == 'MXN' ? bmi.bank_movement.amount_received : this.currency_rate.rate*bmi.bank_movement.amount_received,
				exchange_rate
			})
		}
		this.movements = bmo_custom;
	}

	facturar(_evt:Event)
	{
		let url = this.rest.getUrlBase()+'/facturar.php?id='+this.order_info.order.id;

		this.subs.sink = this.rest
			.getUrlAsString( url )
			.subscribe((response)=>
			{
				this.showSuccess('Exito');
			},(error)=>
			{
				this.showError(error);
			})
	}

	setShowCode()
	{
		this.show_bill_code	=	this.order_info	&&
			this.order_info.order.status == 'CLOSED' &&
			this.order_info.store.autofacturacion_settings	!=	'DISABLED'	&&
			this.order_info.store.default_billing_data_id	&&
			this.order_info?.price_type?.show_bill_code	!=	'NO'
	}

	justPrint()
	{
		window.print();
	}
	closeWindow()
	{
		window.close();
	}
}
