import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { Form } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-list-form',
	templateUrl: './list-form.component.html',
	styleUrls: ['./list-form.component.css']
})
export class ListFormComponent extends BaseComponent implements OnInit
{
	form_search:SearchObject<Form> = this.getEmptySearch();
	form_list:Form[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response:ParamMap[])=>
			{
				this.setTitle('Formularios');
				this.form_search = this.getSearch( response[0], ['id','name'], [] );
				return this.rest.form.search( this.form_search );
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.form_list = response.data;
				this.setPages( this.form_search.page, response.total );
			},
			error:(error)=>
			{
				this.showError( error );
			}
		});
	}
}
