<div class="container-fluid">
	<h1>Agregar Series por lotes</h1>
	<div class="card">
		<div class="row">
			<div class="">
				<label>Articulo</label>
				<div>
					<app-search-items (item_selected)="onItemSelected($event)"></app-search-items>
				</div>
			</div>
			<div class="col-12">
				<label for="store_id">Sucursal</label>
				<select name="store" id="store_id" [(ngModel)]="store_id" required>
					<option value="">Seleccione una sucursal</option>
					<option *ngFor="let store of store_list" [value]="store.id">{{store.name}}</option>
				</select>
			</div>
			<div class="col-12">
				<label for="serials">Números de serie</label>
				<textarea name="serials" id="serials" rows="10" cols="50"></textarea>
			</div>
		</div>
	</div>
</div>
