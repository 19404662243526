<div class="container-fluid">
	<h1 class="my-3">Articulos Utilizados</h1>
	<div class="card px-lg-3 px-1 py-3 d-print-none">
		<div class="row">
			<div class="col-6 form-group">
				<label>Nombre</label>
				<input type="text" [(ngModel)]="report_search.search_extra.compound_name" class="form-control">
			</div>

			<div class="col-6 form-group">
				<label>Sucursal</label>
				<select [(ngModel)]="report_search.search_extra.store_id" class="form-control">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let store of store_list" [value]="store.id" [selected]="report_search.search_extra.store_id==store.id">{{store.name | titlecase}}</option>
				</select>
			</div>
			<div class="col-6 col-md-4 form-group">
				<label>Fecha Inicial</label>
				<input type="datetime-local" name="startx" [(ngModel)]="startx" (ngModelChange)="onDateChange($event,report_search.search_extra,'start')" class="form-control">
			</div>
			<div class="col-6 col-md-4 form-group">
				<label>Fecha final</label>
				<input type="datetime-local" name="endx" [(ngModel)]="endx" (ngModelChange)="onDateChange($event,report_search.search_extra,'end')" class="form-control">
			</div>
			<div class="col-6 col-md-4 form-group">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary" (click)="search(report_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<div class="card my-3">
		<table class="w-100 table-striped">
			<thead>
				<tr class="border">
					<th class="border">Nombre</th>
					<th class="border">Cantidad</th>
					<th class="border">Tipo</th>
					<th class="border">Ordenes</th>
				</tr>
			</thead>
			<tr	*ngFor="let x of report_list">
				<td class="border">{{x.item_name}}</td>
				<td class="border text-end px-3">{{x.order_item_qty | number }}</td>
				<td class="border">{{x.label}}</td>
				<td class="border">
					<a routerLink="/list-order" [queryParams]="{'search_extra.start_timestamp': report_search.search_extra['start'] ,'csv.id':x.order_ids, 'search_extra.end_timestamp': report_search.search_extra['end'] }">
						{{x.total_orders}} Ordenes
			</a>
				</td>
			</tr>
		</table>
	</div>
</div>
