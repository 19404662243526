import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { RestService } from "../services/rest.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate 
{
	constructor(private router: Router, private rest: RestService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
	{
		const currentUser = this.rest.getUserFromSession()

		if(currentUser && currentUser.type == 'CLIENT' )
			return false;


		if ( currentUser ) 
		{
			// authorised so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		console.log('Navigationg heare');

		const platform_client = this.rest.getClientPlatformFromSession();

		if( platform_client )
		{
			this.router.navigate(["/dashboard"], {});
		}
		else
		{
			this.router.navigate(["/login"],{queryParams: { return_url: state.url }});
		}

		return false;
	}
}
