<div class="main_flex h-100">
	<div class="flex_search" *ngIf="rest.local_preferences.pv_show_all_categories == 'YES'">
		<input type=text [(ngModel)]="search_string" (input)="onSearchString($event)" class="w-100 form-control" #search_input>
		<div class="w-100 category_list_scroll" *ngIf="rest.local_preferences.pv_show_all_categories == 'YES'">
			<button type="button"
					*ngFor="let category of category_list"
					[ngClass]="['btn','btn-link','category-item',category.image_style, category.text_style,'text']"
					[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( category.image_id)+')', 'color':category.text_color, 'text-shadow': '0px 0px 3px '+category.shadow_color+', 0px 0px 20px '+category.shadow_color, 'background-color':category.background }"
					(click)="onCategorySelected(category)">
				{{category.name | titlecase}}
			</button>
		</div>
	</div>
	<div class="flex_items categories-container flex-wrap" style="overflow:auto">
		<ng-container *ngIf="rest.local_preferences.pv_show_all_categories!='YES'">
			<button *ngIf="categories_path.length" type="button" class="category-item back" (click)="fullBack()">
				<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50px" height="40px" viewBox="0 0 1694.000000 1421.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,1421.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none"><path d="M0 7095 l0 -7095 1555 0 1555 0 3 7073 c1 3889 6 7082 11 7095 l8 22 -1566 0 -1566 0 0 -7095z"/><path d="M10265 14018 c-130 -119 -6561 -6544 -6771 -6764 -79 -83 -144 -157 -144 -165 0 -15 6352 -6373 6759 -6765 151 -145 277 -261 280 -258 3 3 8 744 11 1647 l5 1642 3261 3 3262 2 5 43 c2 23 4 1703 3 3732 l-1 3690 -3265 5 c-1796 3 -3266 7 -3267 8 -2 1 -3 631 -3 1400 0 1322 -6 1882 -19 1882 -3 0 -55 -46 -116 -102z"/></g></svg>
			</button>
			<button *ngIf="categories_path.length>1" type="button" class="category-item back" (click)="goBack()">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="50px" height="50px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M10 2.5V6h7v8h-7v3.5L2.5 10L10 2.5z" fill="currentColor"/></svg>
			</button>
			<button type="button"
					*ngFor="let category of category_list"
					[ngClass]="['btn','btn-link','category-item',category.image_style, category.text_style,'text']"
					[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( category.image_id)+')', 'color':category.text_color, 'text-shadow': '0px 0px 3px '+category.shadow_color+', 0px 0px 20px '+category.shadow_color, 'background-color':category.background }"
					(click)="onCategorySelected(category)">
				{{category.name | titlecase}}
			</button>
		</ng-container>
		<button type="button"
				*ngFor="let item_info of item_info_list"
				[ngClass]="['btn','btn-link','category-item',item_info.item.image_style, item_info.item.text_style,'text']"
				[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( item_info.item.image_id, item_info.category?.image_id)+')', 'color':item_info.item.text_color, 'text-shadow': '0px 0px 3px '+item_info.item.shadow_color+', 0px 0px 20px '+item_info.item.shadow_color,	'background-color':item_info.item.background }"
				(click)="onItemClicked(item_info)">
				{{item_info.item.name | titlecase}}
		</button>
	</div>
</div>

<!--div class="flex flex-column h-100" style="overflow:hidden">
	<div class="flex-shrink-0 align-self-stretch" *ngIf="rest.local_preferences.pv_show_all_categories == 'YES'">
		<input type=text [(ngModel)]="search_string" class="w-100 form-control" #search_input>
		<div class="w-100 category_list_scroll" *ngIf="rest.local_preferences.pv_show_all_categories == 'YES'">
			<div>
				<button type="button"
						*ngFor="let category of category_list"
						[ngClass]="['btn','btn-link','category-item',category.image_style, category.text_style]"
						[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( category.image_id)+')', 'color':category.text_color, 'text-shadow': '0px 0px 3px '+category.shadow_color+', 0px 0px 20px '+category.shadow_color, 'background-color':category.background }"
						(click)="onCategorySelected(category)">
					<div class="text overflow:hidden;">{{category.name | titlecase}}</div>
				</button>
			</div>
		</div>
	</div>
	<div class="flex-shrink-1 categories_container">
		<div class="h-100">
			<ng-container *ngIf="rest.local_preferences.pv_show_all_categories!='YES' && category_list.length">
				<button type="button"
						*ngFor="let category of category_list"
						[ngClass]="['btn','btn-link','category-item',category.image_style, category.text_style]"
						[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( category.image_id)+')', 'color':category.text_color, 'text-shadow': '0px 0px 3px '+category.shadow_color+', 0px 0px 20px '+category.shadow_color, 'background-color':category.background }"
						(click)="onCategorySelected(category)">
					<div class="text overflow:hidden;">{{category.name | titlecase}}</div>
				</button>
			</ng-container>
			<button type="button"
					*ngFor="let item_info of item_info_list"
					[ngClass]="['btn','btn-link','category-item',item_info.item.image_style, item_info.item.text_style]"
					[ngStyle]="{'background-image': 'url('+this.rest.getImagePath( item_info.item.image_id, item_info.category?.image_id)+')', 'color':item_info.item.text_color, 'text-shadow': '0px 0px 3px '+item_info.item.shadow_color+', 0px 0px 20px '+item_info.item.shadow_color,	'background-color':item_info.item.background }"
					(click)="onItemClicked(item_info)">
				<div class="text overflow:hidden;">{{item_info.item.name | titlecase}}</div>
			</button>
		</div>
	</div>
</div-->

<!--div class="position:relative">
  <div style="position:absolute;top:0;right:0;bottom:0;left:0">
    <div *ngIf="rest.local_preferences.pv_show_all_categories == 'YES'">
      <input type=text [(ngModel)]="search_string" class="w-100 form-control" #search_input>
    </div>

    <div class="categories-container d-flex flex-wrap justify-content-start">
      <ng-container *ngIf="rest.local_preferences.pv_show_all_categories != 'YES'">
        <button *ngIf="categories_path.length" type="button" class="category-item back" (click)="fullBack()">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50px" height="40px" viewBox="0 0 1694.000000 1421.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,1421.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none"><path d="M0 7095 l0 -7095 1555 0 1555 0 3 7073 c1 3889 6 7082 11 7095 l8 22 -1566 0 -1566 0 0 -7095z"/><path d="M10265 14018 c-130 -119 -6561 -6544 -6771 -6764 -79 -83 -144 -157 -144 -165 0 -15 6352 -6373 6759 -6765 151 -145 277 -261 280 -258 3 3 8 744 11 1647 l5 1642 3261 3 3262 2 5 43 c2 23 4 1703 3 3732 l-1 3690 -3265 5 c-1796 3 -3266 7 -3267 8 -2 1 -3 631 -3 1400 0 1322 -6 1882 -19 1882 -3 0 -55 -46 -116 -102z"/></g></svg>
        </button>
        <button *ngIf="categories_path.length>1" type="button" class="category-item back" (click)="goBack()">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="50px" height="50px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M10 2.5V6h7v8h-7v3.5L2.5 10L10 2.5z" fill="currentColor"/></svg>
        </button>
      </ng-container>
      <ng-container *ngIf="rest.local_preferences.pv_show_all_categories!='YES' && category_list.length">
        <button type="button"
                *ngFor="let category of category_list"
                [ngClass]="['btn','btn-link','category-item',category.image_style, category.text_style]"
                [ngStyle]="{'background-image': 'url('+this.rest.getImagePath( category.image_id)+')', 'color':category.text_color, 'text-shadow': '0px 0px 3px '+category.shadow_color+', 0px 0px 20px '+category.shadow_color, 'background-color':category.background }"
                (click)="onCategorySelected(category)">
          <div class="text overflow:hidden;">{{category.name | titlecase}}</div>
        </button>
      </ng-container>
      <button type="button"
              *ngFor="let item_info of item_info_list"
              [ngClass]="['btn','btn-link','category-item',item_info.item.image_style, item_info.item.text_style]"
              [ngStyle]="{'background-image': 'url('+this.rest.getImagePath( item_info.item.image_id, item_info.category?.image_id)+')', 'color':item_info.item.text_color, 'text-shadow': '0px 0px 3px '+item_info.item.shadow_color+', 0px 0px 20px '+item_info.item.shadow_color,	'background-color':item_info.item.background }"
              (click)="onItemClicked(item_info)">
        <div class="text overflow:hidden;">{{item_info.item.name | titlecase}}</div>
      </button>
    </div>
  </div>
</div-->
