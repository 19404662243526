import { Component, OnInit, Output,EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import {ItemInfo} from 'src/app/models/models';
import {Category, Item} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';
import { of,forkJoin, Observable, Subject } from 'rxjs';
import { RestResponse, SearchObject } from 'src/app/services/Rest';
import { debounceTime, mergeMap } from 'rxjs/operators';
//import {RestResponse} from 'src/app/services/Rest';

interface CCategory
{
	name:string;
	items:ItemInfo[];
}

@Component({
	selector: 'app-item-selector',
	templateUrl: './item-selector.component.html',
	styleUrls: ['./item-selector.component.css']
})

export class ItemSelectorComponent extends BaseComponent implements OnInit,OnChanges
{

	@Output() onItemSelect:EventEmitter<ItemInfo> =new EventEmitter<ItemInfo>();
	@Input() navigationBack:number = 1;
	@Output() navigationBackChange = new EventEmitter<number>();
	@Input() filter:Partial<Item> = {};
	@Input() store_id:number = 0;

	current_path:string = '';
	categories_path:Category[] = [];
	category_list:Category[] = [];
	item_info_list:ItemInfo[] = [];
	display_list:'Category'|'Item' = 'Category';
	search_string:'';
	search_subject = new Subject<string>();

	ngOnInit(): void
	{
		//this.rest.local_preferences.pv_show_all_categories = 'YES';
		this.loadCategoriesAndItems(0);
		this.initSearchSubscription();
	}


	onSearchString(evt:Event)
	{
		let target = evt.target as HTMLInputElement;
		this.search_subject.next( target.value );
	}

	initSearchSubscription()
	{
		this.subs.sink = this.search_subject.pipe
		(
			mergeMap((response)=>
			{
				console.log("Se pierde", response);
				return of( response);
			}),
			debounceTime(350),
			mergeMap((search:string) =>
			{
				console.log('No se pierde', search );
				let qty = 1;

				if( this.rest.is_offline || this.rest.offline_search_enabled)
				{
					let s = search.trim();
					let extracted = s;

					if(/^\d+\*/.test( s ) )
					{
						let index = s.indexOf('*');
						extracted = s.substring(index+1).trim();
						let qty_string = s.substring(0,index);
						qty = parseInt( qty_string, 10 ) as number;
						//console.log(extracted);

						return forkJoin({
							item_info: this.rest.searchByCode( extracted ),
							term: of( extracted ),
							qty: of( qty ),
							search_by_code: of( true )
						})
					}
					else if( /^\d{8,13}$/.test( s ) )
					{
						return forkJoin
						({
							item_info: this.rest.searchByCode( extracted ),
							term: of( extracted ),
							qty: of( 1 ),
							search_by_code: of( true )
						})
					}

					console.log('BUSCANDO PERO NO POR CODIGO');

					return forkJoin
					({
						item_info: this.rest.searchOfflineItemInfo(extracted),
						term: of(extracted),
						qty: of( qty ),
						search_by_code: of( false )
					})
				}
				else
				{
					let s = search.trim();
					let extracted = s;
					let search_by_code = false;

					if(/^\d+\*/.test( s ) )
					{
						let index = s.indexOf('*');
						extracted = s.substring(index+1);
						let qty_string = s.substring(0,index);
						qty = parseInt( qty_string, 10 ) as number;
						//console.log(extracted);
						search_by_code = true;
					}
					else if( /^\d{8,13}$/.test( s ) )
					{
						search_by_code = true;
					}

					let search_extra= {
						category_name:extracted
					};

					console.log('FALIO Store id', this.store_id );
					if( this.store_id )
					{
						search_extra['store_id'] = this.store_id || this.rest.current_user.store_id;
					}

					let search_obj:Partial<SearchObject<Item>> = {
						eq:
						{
							on_sale:'YES',
							status: 'ACTIVE'
						},
						limit: 20,
						search_extra
					};

					for(let i in this.filter )
					{
						search_obj.eq[i] = this.filter[i];
					}

					return forkJoin
					({
						item_info:this.rest.item_info
						.search( search_obj ),
						term: of(extracted),
						qty: of( qty ),
						search_by_code: of( search_by_code )
					})
				}
			})
		)
		.subscribe((responses)=>
		{
			console.log('Responses', responses );

			if( responses.search_by_code )
			{
				if( responses.item_info.data.length )
				{
					console.log('Emiting item FOOOO');
					this.onItemSelect.emit(responses.item_info.data[0]);
				}
				this.search_string = '';
				//this.loadCategoriesAndItems(0);
			}
			else
			{
				this.item_info_list = responses.item_info.data;
			}
		},(error)=>
		{
			this.showError(error);
			console.log('Error occourred',error);
		});
	}


	onCategorySelected(category:Category)
	{
		this.categories_path.push( category );
		this.navigationBackChange.emit( this.categories_path.length );
		this.loadCategoriesAndItems( category.id );
	}

	loadCategoriesAndItems(category_id:number):void
	{
		if( this.rest.offline_search_enabled || this.rest.is_offline )
		{
			this.rest.getOfflineCategoriesAndItems( category_id )
			.then((response)=>
			{
				let all_categories = this.rest.local_preferences.pv_show_all_categories == 'YES' && this.category_list.length > 0 ;

				if( !all_categories )
				{
						this.category_list = response.category as Category[];
				}

				this.item_info_list = response.item_info as ItemInfo[];
			});
		}
		else
		{
			let item_obs = category_id == 0
				? this.rest.item_info.search
				({
					eq:{ on_sale:'YES','status':'ACTIVE'},
					is_null: ['category_id'],
					limit: 999999,
					sort_order:['sort_weight_DESC','name_ASC']
				})
				: this.rest.item_info.search
				({
					eq:{ category_id, on_sale:'YES', status:'ACTIVE' },
					search_extra: { store_id: this.rest.current_user.store_id },
					limit:999999,
					sort_order:['sort_weight_DESC','name_ASC']
				});

			console.log('Cargando todas las categorias');

			let all_categories = this.rest.local_preferences.pv_show_all_categories == 'YES' &&
				this.category_list.length > 0 ;

			let category_observer:Observable<RestResponse<Category>> = all_categories
					? of({data: this.category_list, total: this.category_list.length })
					:this.rest.category.search
					({
						eq:{ display_status:'NORMAL' },
						search_extra:
						{
							parent_category_id:category_id,
							store_id:this.rest.current_user.store_id
						},
						limit:999999,
						//sort_order:['name_ASC']
						sort_order:['sort_weight_DESC','name_ASC']
					});

			this.subs.sink = forkJoin
			({
				item_info : item_obs,
				category: category_observer
			})
			.subscribe((response)=>
			{
				if( this.category_list.length == 0 || this.rest.local_preferences.pv_show_all_categories != 'YES')
				{
					this.category_list = response.category.data;
				}
				this.item_info_list = response.item_info.data;
			},(error)=>this.showError(error));
		}
	}

	onItemClicked(item:ItemInfo)
	{
		//console.log('Emiting item',item);
		this.onItemSelect.emit(item);
	}

	clean()
	{
		this.item_info_list = [];
		this.display_list = 'Category';
	}

	fullBack()
	{
		this.categories_path.splice(0,this.categories_path.length);
		this.loadCategoriesAndItems(0)
		this.navigationBackChange.emit( this.categories_path.length );
	}


	ngOnChanges(changes: SimpleChanges): void
	{
		console.log('ngOnChanges',changes);
		if( changes['navigationBack'] )
		{
			if( changes.navigationBack.currentValue == -1 )
			{
				if( this.categories_path.length )
				{
					this.goBack();
					return;
				}
			}
		}
	}

	goBack()
	{
		this.categories_path.pop();
		let category_id = this.categories_path.length ? this.categories_path[ this.categories_path.length - 1].id : 0;
		console.log('Back to ', category_id, this.categories_path.length )
		this.loadCategoriesAndItems( category_id );
		this.navigationBackChange.emit( this.categories_path.length );
	}
}
