<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3 text-capitalize">Envío #{{shipping_info?.shipping?.id}}</h1>
		</div>
		<div class="col-2 text-end d-print-none">
			<button type="button" class="btn btn-secondary" (click)="print()">Imprimir</button>
		</div>
	</div>
	<div class="row align-items-start" *ngIf="shipping_info">
		<div class="col-12 col-lg-6">
			<h2>Datos del Envío</h2>
			<div class="card my-3 p-1 p-lg-3">
				<div class="row p-3">
					<div class="col-6 col-md-4">
						<label class="">Número Económico ó Guia</label>
						<div>
							<b>{{shipping_info.shipping?.shipping_guide}}</b>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<label class="">Condutor o Paquetería</label>
						<div>
							<b>{{shipping_info.shipping?.shipping_company}}</b>
						</div>
					</div>
					<div class="col-6 col-md-4" *ngIf="shipping_info.shipping.from_store_id">
						<label class="">Almacén Origen</label>
						<div>
							<b>{{store_dictionary[ shipping_info.shipping.from_store_id ]?.name }}</b>
						</div>
					</div>
					<div class="col-6 col-md-4" *ngIf="shipping_info.shipping.purchase_id">
						<label class="">Origen</label>
						<div>
							<a [routerLink]="['/purchase/edit', shipping_info.shipping.purchase_id]" *ngIf="rest.current_permission.add_purchases">Orden de compra #{{shipping_info.shipping.purchase_id}}</a>
							<b *ngIf="!rest.current_permission.add_purchases">Orden de compra #{{shipping_info.shipping.purchase_id}}</b>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<label class="">Almacén Destino</label>
						<div>
							<b>{{store_dictionary[ shipping_info.shipping.to_store_id ]?.name }}</b>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<label class="">Fecha de envío</label>
						<div>
							<b>{{ shipping_info.shipping?.date | date: 'short'}}</b>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<label class="">Recibido</label>
						<div>
							<b>{{ shipping_info.shipping.delivery_timestamp | date: 'short'}}</b>
						</div>
					</div>
					<div *ngIf="shipping_info.shipping.note" class="col-12">
						<label class="">Nota</label>
						<div>
							<b>{{ shipping_info.shipping?.note }}</b>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-6" *ngIf="shipping_info?.shipping.status != 'SENT'">
			<h2>Resumen</h2>
			<div class="card px-1 px-md-3 py-3 my-3">
				<div *ngIf="total_pallets" class="row">
					<div class="col-8">Total Tarimas</div>
					<div class="col-4 text-end">{{total_pallets}}</div>
				</div>
				<div class="row" *ngIf="total_individual_boxes">
					<div class="col-8">Total Cajas Sueltas</div>
					<div class="col-4 text-end">{{total_individual_boxes}}</div>
				</div>
				<div class="row" *ngIf="total_boxes">
					<div class="col-8">Total Cajas</div>
					<div class="col-4 text-end">{{total_boxes}}</div>
				</div>
				<div class="row" *ngIf="total_items && shipping_info.shipping.status != 'SENT'">
					<div class="col-8">Total Elementos</div>
					<div class="col-4 text-end">{{total_items}}</div>
				</div>
				<div class="row">
					<div class="col-8">Total Elementos Recibidos</div>
					<div class="col-4 text-end">{{total_received_items}}</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<h2>Artículos</h2>
			<div class="card p-3">
				<table class="w-100" *ngIf="shipping_info">
					<thead>
						<tr>
							<th class="">Artículos</th>
							<th class="text-end">Enviados</th>
							<th class="text-end">Recibido (funcional)</th>
							<th class="text-end">Merma</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let sii of shipping_info.items;let i=index">
							<td class="fw-bold">
								<b>{{ i+1 }} - </b>
								<img [src]="rest.getImagePath(sii.item?.image_id,sii?.category?.image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
								<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES'" class="text-secondary">{{sii?.category?.name}} - </span>
								<b>{{sii.item.name |titlecase }}</b>
								<span *ngIf="sii.shipping_item.serial_number">({{sii.shipping_item.serial_number |titlecase }})</span>
								<a [routerLink]="['/list-item']" [queryParams]="{'csv.id':''+sii.item.id}" *ngIf="sii.item.code"> {{sii.item.code|titlecase | uppercase }}</a>
							</td>
							<td class="text-end">
								{{sii.shipping_item.qty | number }}
							</td>
							<td class=" text-end">
								<span *ngIf="shipping_info.shipping.status == 'DELIVERED' ">
									{{sii.shipping_item.received_qty | number }}
								</span>
							</td>
							<td class="text-end">
								<span *ngIf="shipping_info.shipping.status == 'DELIVERED' ">
									{{sii.shipping_item.shrinkage_qty | number }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
