import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Billing_Data } from '../../models/RestModels';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ParamMap} from '@angular/router';
import { GetEmpty } from 'src/app/classes/Empties';


@Component({
	selector: 'app-save-billing-data',
	templateUrl: './save-billing-data.component.html',
	styleUrls: ['./save-billing-data.component.css']
})
export class SaveBillingDataComponent extends BaseComponent implements OnInit {

	billing_data:Billing_Data	= GetEmpty.billing_data();

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map:ParamMap)=>
			{
				this.is_loading = true;
				this.billing_data = GetEmpty.billing_data();
				if( param_map.has('id' ) )
				{
					this.setTitle('Editar datos De Facturación');
					return this.rest.billing_data.get( param_map.get('id') )
				}
				this.setTitle('Nuevos Datos De Facturación');
				return of( this.billing_data );
			})
		)
		.subscribe((billing_data:Billing_Data)=>
		{
			this.is_loading = false;
			this.billing_data = billing_data;
			if( this.billing_data.regimen_capital == null )
				this.billing_data.regimen_capital = '';

		},(error)=>this.showError( error ));
	}

	save()
	{
		this.is_loading = true;

		if( this.billing_data.id	)
		{
			this.subs.sink	= this.rest.billing_data.update( this.billing_data ).subscribe((billing_data)=>{
				this.is_loading = false;
				this.router.navigate(['/list-billing-data']);
				this.showSuccess('la información de facturación se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.billing_data.create( this.billing_data ).subscribe((billing_data)=>{
				this.is_loading = false;
				this.showSuccess('La información de facturación se guardó exitosamente');
				this.router.navigate(['/list-billing-data']);
			},(error)=>this.showError(error));
		}
	}
}
