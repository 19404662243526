<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-12 col-md-10">
			<h1 class="my-3 text-capitalize">
				{{requisition.id ? 'Actualizar':'Agregar'}} requisición
				<span *ngIf="type=='store'"> A Sucursal</span>
			</h1>
		</div>
		<div class="col-12 col-md-2 text-end mb-3 mb-md-0" *ngIf="type=='requisition'">
			<button type="button" class="btn btn-secondary" (click)="show_create_with_min = true" [disabled]="is_loading || !this.required_by_store_id">Crear con Minimos</button>
		</div>
	</div>
	<form ngNativeValidate (submit)="save()" ngNativeValidate>
		<div class="card p-3">
			<div class="row px-3 py-1">
				<div [ngClass]="classes_1">
					<label class="">Almacén Que Solicita</label>
					<select name="required_by_store_id" [ngModel]="required_by_store_id" (ngModelChange)="updateRequiredStoreId($event)" class="form-control" [disabled]="rest.current_permission.global_requisition==0" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div *ngIf="type=='store'" [ngClass]="classes_1">
					<label class="">Solicitado al Almacén</label>
					<select name="requested_to_store_id" [ngModel]="requested_to_store_id" (ngModelChange)="updateRequestedStoreId($event)" class="form-control" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div [ngClass]="classes_1">
					<label class="">Requerido para:</label>
					<input type="datetime-local" name="required_by_timestamp" [(ngModel)]="fecha_envio" (ngModelChange)="onFechaChange($event)" class="form-control" required>
				</div>
				<div *ngIf="filter_tags" [ngClass]="classes_1">
					<label class="">Tags:</label>
					<div class="">{{filter_tags | lowercase}}</div>
				</div>
			</div>
			<div class="row px-3 py-1">
				<label>Notas</label>
				<textarea name="note" placeholder="Escribe una nota" [(ngModel)]="requisition.note" class="form-control" rows="1"></textarea>
			</div>
		</div>
		<div class="card p-3 mt-3" *ngIf="requisition.required_by_store_id">
			<div class="col-12 form-group">
				<label>Agregar Artículo</label>
				<app-search-items [update_focus]="random_focus" (item_selected)="onItemSelected($event)"></app-search-items>
			</div>
			<div class="row my-1 fw-bold d-none d-md-flex align-items-center" *ngIf="selected_requisition_item_info">
				<div class="col-3">Artículo</div>
				<div class="col-2 text-end">Requeridos</div>
				<div class="col-2 text-end">Inventario</div>
				<div class="col-2 text-end">Cantidad</div>
				<div class="col-3 text-end">Acciones</div>
			</div>
			<ng-container *ngIf="selected_requisition_item_info">
				<form class="row py-md-1 py-0 border-bottom align-items-center" (submit)="addSelectedRequisitionItem(selected_requisition_item_info)" >
					<div class="col-12 col-md-3 fw-bold">
						{{selected_requisition_item_info?.category.name || 'N/A'}} - {{selected_requisition_item_info.item.name }}
						<span style="color:#BBBBBB">{{selected_requisition_item_info.item.code}}</span>
					</div>
					<div class="col-3 col-md-2 text-end">
						{{selected_requisition_item_info.store_min | number}}
					</div>
					<div class="col-3 col-md-2 text-end">
						{{selected_requisition_item_info.current_stock | number}}
					</div>
					<div class="col-6 col-md-2 form-group">
						<label class="d-md-none text-end">Cantidad</label>
						<input name="stock_take_item_qty" maxlength="13" [name]="'selected_item_info'" [ngModel]="tmp_qty" (ngModelChange)="selectedRequsitionQtyChange($event)" type="number" class="form-control text-end w-100" min=0 [step]="selected_requisition_item_info.item.partial_sale == 'NO'?'1':'any'" max=999999999 #input_qty>
						{{input_qty.focus()}}
					</div>
					<div class="col-12 col-md-3 text-end form-group">
						<label class="d-block d-md-none">&nbsp;</label>
						<button type="submit" class="btn btn-primary me-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 14 14"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m13.5.5l-13 13m0-13l13 13"/></svg>
							&nbsp;
							<span class="d-none d-lg-inline">
								Cancelar
							</span>
						</button>
						<button type="submit" class="btn btn-primary">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
							&nbsp;<span class="d-none d-lg-inline">
								Agregar
							</span>
						</button>
					</div>
				</form>
			</ng-container>
		</div>
		<h2 class="my-3">Articulos</h2>
		<div class="card py-3 px-1 px-lg-3 my-3">
			<div class="" *ngIf="requisition_item_info_list.length == 0">
				No hay elementos
			</div>
			<div class="row w-100 align-items-center py-1" *ngFor="let rii of requisition_item_info_list;let i=index">
				<div class="col-12 col-md-8 align-self-center">{{i+1}} - {{rii | itemName |titlecase}}</div>
				<div class="col-8 col-md-3 align-self-center">
					<input type="number" class="form-control text-end" [name]="'requisition_item-'+rii.requisition_item.item_id" [(ngModel)]="rii.requisition_item.qty" [step]="rii.item.partial_sale == 'NO'?'1':'any'" (click)="selectText($event)">
				</div>
				<div class="col-4 col-md-1 col-auto p-0 align-self-center text-end">
					<button type="button" class="btn btn-danger" (click)="removeItem(rii)">
						<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M1.707.293A1 1 0 0 0 .293 1.707L5.586 7L.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414-1.414L8.414 7l5.293-5.293A1 1 0 0 0 12.293.293L7 5.586z" clip-rule="evenodd"/></svg>
					</button>
				</div>
			</div>
			<div class="text-end">
				<button type="submit" [disabled]="is_loading" class="my-3 btn btn-primary" [tabIndex]="3">Guardar</button>
			</div>
		</div>
	</form>
</div>
<app-modal [show]="show_create_with_min">
	<div class="p-3">
		<form (submit)="requisitionMinimuns($event)" ngNativeValidate>
			<h2>Crear requisición con mínimos</h2>
			<p>Para abastecer mínimos de inventario</p>
			<div class="form-group">
				<label>Tags, separados por comas</label>
				<input name="filter_tags" type="text" [(ngModel)]="filter_tags" placeholder="tag" regex="[a-z,]" class="form-control">
			</div>
			<div class="row mt-3">
				<div class="col-6">
					<button type="button"  class="btn btn-primary w-100" (click)="show_create_with_min=false" [disabled]="is_loading">
						Cancelar
					</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Agregar Minimos</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
