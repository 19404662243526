<div class="container-fluid">
	<div class="row align-items-center mx-0 my-3">
		<div class="col">
			<h1 class="text-capitalize">Tipos de comanda</h1>
		</div>
		<div class="col-auto">
			<!-- button type="button" class="mr-2 btn btn-light lift" (click)="show_import=true">Importar</button>
			<!--button type="button" class="mr-2 btn btn-light lift" (click)="exportFile()">Exportar</button-->
			<button type="button" class="btn btn-secondary" [routerLink]="['/add-commanda-type']">Agregar Tipos de comanda</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="commanda_type_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Name</label>
					<input type="text" name="name" [(ngModel)]="commanda_type_search.lk.name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="commanda_type_search.eq.created" class="form-control">
				</div>A
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="commanda_type_search.eq.updated" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id - Nombre</th>
						<th class="text-end">Actualización</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let commanda_type of commanda_type_list">
						<td>
							<a [routerLink]="['/edit-commanda-type',commanda_type.id]">{{commanda_type.id}} - {{commanda_type.name}}</a>
						</td>
						<td class="text-end">{{commanda_type.updated | shortDate }}</td>
					</tr>
					<tr *ngIf="commanda_type_list == null || commanda_type_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-commanda-type'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
