import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { ItemInfo, SerialInfo } from 'src/app/models/models';
import { Serial, Store } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

interface CustomSerial extends SerialInfo
{
	store_name:string;
}

@Component
({
	selector: 'app-save-serial',
	templateUrl: './save-serial.component.html',
	styleUrls: ['./save-serial.component.css']
})
export class SaveSerialComponent extends BaseComponent implements OnInit
{
	item_info = GetEmpty.item_info();
	serial_info: SerialInfo = GetEmpty.serial_info();
	store_list: Store[] = [];
	store_id: number | '' = '';
	item_id: number = 0;
	format: string = 'CODE128';
	width: string = '2';
	height: string = '100';
	show_print_codes: boolean;
	print_format: string = 'multiple';
	qr_options: string = 'fast';

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				this.item_id = parseInt( param_map.get('item_id'),10) as number;
				this.path = '/serial/'+this.item_id;
				console.log("Reloading",this.path);
				return forkJoin
				({
					item_info: this.rest.item_info.get( this.item_id ),
					stores: this.rest.getStores(false),
				})
			})
		)
		.subscribe((response)=>
		{
			//Se reinicializan los numero para no tratarlos de agregar otra vez.
			this.serial_info = GetEmpty.serial_info();
			this.serial_info.serial.item_id = response.item_info.item.id;
			this.store_list = response.stores.data;
			this.serial_info.serial.store_id = this.rest.current_user.store_id;
			this.store_id = this.rest.current_user.store_id;

			for(let serial of response.item_info.serials)
			{
				let s = this.store_list.find(x=>x.id== serial.serial.store_id) as Store;
				serial.serial.store_name = s.name;
			}

			this.item_info = response.item_info;
		});
	}

	reloadByItemId(item_id:number)
	{

	}

	loadSerials(item_id:number)
	{
	}

	initSerials(item_info:ItemInfo,store_list:Store[] )
	{
		this.serial_info = GetEmpty.serial_info();
		this.store_list = store_list;
		this.serial_info.serial.item_id = item_info.item.id;

		if( !this.rest.current_permission.global_add_stock )
			this.serial_info.serial.store_id = this.rest.current_user.store_id;

		for(let serial of item_info.serials)
		{
			let s = this.store_list.find(x=>x.id== serial.serial.store_id) as Store;
			serial.serial.store_name = s.name;
		}

		this.item_info = item_info;
	}
    generateCodes() 
	{
        // Envía el formulario al backend para generar códigos de barras y QR
        const formElement = document.getElementById('form_barcodes') as HTMLFormElement;
        if (formElement) {
			const inputPrintFormat = document.createElement('input');
			inputPrintFormat.type = 'hidden';
			inputPrintFormat.name = 'printFormat';
			inputPrintFormat.value = this.print_format;
			formElement.appendChild(inputPrintFormat);

            formElement.submit(); // Envía el formulario
        }
    }
	addNewSerial()
	{
		let f = this.item_info.serials.find((si)=>
		{
			return this.serial_info.serial.serial_number.trim() == si.serial.serial_number.trim();
		});

		if( f )
		{
			if( !this.serial_info.serial.id )
			{
				this.showError('El # de serial ya se encuentra en la lista');
				console.log('El # de serial ya se encuentra en la lista',this.serial_info.serial);
				return;
			}

			console.log('Borrand y agregnao el nuevo serial',this.serial_info.serial);

			this.subs.sink = this.rest.serial_info.delete( this.serial_info.serial )
			.pipe
			(
				mergeMap((_response:any)=>
				{
					return this.rest.serial_info.create( this.serial_info );
				}),
			)
			.subscribe((response)=>
			{
				this.searchNoForceReload({});

				this.router.navigateByUrl('/',{skipLocationChange: true})
				.then(()=>
				{
					this.searchNoForceReload({});
				});

			}
			,(error)=>
			{
				this.showError(error);
			});

			return;
		}

		let store = this.store_list.find(s=>s.id == this.store_id );

		this.serial_info.serial.item_id = this.item_info.item.id;
		this.serial_info.serial.store_id = this.store_id as number;
		this.serial_info.serial.store_name = store?.name || '';
		this.item_info.serials.push({ ...this.serial_info });

		if( !this.rest.current_permission.global_add_stock )
		{
			this.store_id = this.rest.current_user.store_id;
			this.serial_info.serial.store_id = this.rest.current_user.store_id;
		}

		this.serial_info.serial.store_id = this.store_id as number;

		this.subs.sink = this.rest.serial_info.create( this.serial_info ).subscribe
		({
			next: (response)=>
			{
				this.showSuccess('Se Agrego exitosamente');
				this.is_loading = false;
				this.serial_info = GetEmpty.serial_info();
			},
			error: (error:any)=>this.showError( error )
		});
	}
	onNewImage(image_id: number, serial_info:SerialInfo)
	{
		serial_info.images = [{
			image_id,
			id: 0,
			serial_id: serial_info.serial.id,
			description: '',
			created: new Date(),
			updated: new Date(),
		}];
	}

	save()
	{
		let success_lamda = (_response:SerialInfo)=>{
			this.showSuccess('Se guardaron los nuevos elementos')
			this.is_loading = false;
			this.location.back();
		};
		let error_lamda = (error:any)=>this.showError( error );

		let groups:SerialInfo[][] = [];

		this.item_info.serials
		//.filter(si=>!si.serial.id)
		.forEach((si)=>
		{
			let group = groups.find((g)=>{
				return g[0].serial.store_id	== si.serial.store_id;
			})

			if( group )
			{
				group.push( si );
			}
			else
			{
				groups.push([ si ]);
			}
		});

		let observables = groups.map((group)=>
		{
			return this.rest.serial_info.create( group );
		});

		if( observables.length == 0 )
		{
			this.showError('No hay cambios que registrar');
			return;
		}

		this.is_loading = true;
		this.subs.sink = forkJoin( observables )
		.subscribe((_response)=>
		{
			this.showSuccess('Los numero de serie se guardaron correctamente');
			this.is_loading = false;
			this.searchNoForceReload();
		},(error)=>this.showError(error));

		//this.subs.sink = this.rest.serial_info
		//	.create( this.item_info.serials.filter((si)=>{return !si.serial.id}) )
		//	.subscribe( success_lamda, error_lamda );
	}

	confirmRemoveSerial(serial_info:SerialInfo)
	{
		let title = 'Eliminar Número de serie';
		let description = 'Estas seguro de querer eliminar el número de serie?';
		this.subs.sink = this.confirmation
		.showConfirmAlert( serial_info, title, description )
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				let serial_info = response.obj as SerialInfo;

				if( !serial_info.serial.id )
				{
					this.item_info.serials = this.item_info.serials.filter(i=>i!=serial_info);
				}
				else
				{
					this.is_loading = true;
					this.subs.sink = this.rest.serial_info
					.deleteT( response.obj.serial )
					.subscribe((response)=>
					{
						this.item_info.serials = this.item_info.serials.filter(i=>i!=serial_info);
						this.is_loading = false;
					}
					,(error)=>
					{
						this.showError(error);
					});
				}
			}
		});
	}
	editSerial(serial_info: SerialInfo)
	{
		this.serial_info = {...serial_info};
		this.serial_info.serial = {...serial_info.serial };
	}
}
