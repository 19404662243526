<div class="container-fluid login_background">
	<div class="row h-100 align-items-center">
		<div class="col-12">
			<div class="container-fluid" style="max-width:400px;">
				<div class="card col-centered" style="background:rgba(255,255,255,0.9);">
					<div class="card-body">
						<div id="formContent">
							<form #form="ngForm">
								<div class="form-group mt-3">
									<div class="mb-3 text-center">
										<img [src]="rest.getLoginLogo()" style="max-width:100%">
									</div>
									<input name="username" class="form-control" type="text" placeholder="usuario@ejemplo.com" [(ngModel)]="username" required />
								</div>
								<div class="form-group mt-3">
									<input name="password" class="form-control" type="password" placeholder="Contraseña" [(ngModel)]="password" (keyup)="doLoginKeyboard($event)" required />
								</div>
								<div class="text-end mt-3">
									<button type="button" class="btn btn-primary" size="large" type="submit"
										[disabled]="form.invalid || is_loading" expand="block" (click)="doLogin()">
										Login
									</button>
								</div>
							</form>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-6">
							<a routerLink="/registrarse" [queryParams]="{return_url:return_url}" class="btn btn-link">Registrarse</a>
						</div>
						<div class="col-6">
							<a routerLink="/admin"  [queryParams]="{return_url:return_url}" class="btn btn-link">Administrador</a>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12">
							<a routerLink="/facturar" class="btn btn-link">Facturar</a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-centered">
						<div class="alert alert-danger" *ngIf="error_message" role="alert">{{error_message}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
