import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-reports-occupancy',
	templateUrl: './reports-occupancy.component.html',
	styleUrls: ['./reports-occupancy.component.css']
})

export class ReportsOccupancyComponent extends BaseComponent implements OnInit
{
	store_list: Store[];
	report_search: SearchObject<unknown> = this.getEmptySearch();
	endx: string='';
	startx: string='';
	report_list: any[] = [];

    show_store: boolean = false;
    show_date: boolean = false;
    show_mesero: boolean = false;
    show_table: boolean = false;

	ngOnInit(): void {
		this.path = '/reports-occupancy';
		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap)=>
			{
				this.setTitle('Reporte Ventas por artículo');
				this.is_loading = true;
				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.getStores(true)
				});
			}),
			mergeMap((response)=>
			{
				let extra_params =
				[
					'item_name', 'store_id','start',
					'store_id', 'end',
					'screated_by_user_id', 'stable_id','sorder_date'
				];

				this.store_list = response.stores.data;
				this.report_search = this.getSearch(response.param_map, [], extra_params);

				if( !this.report_search.search_extra['start'] )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.report_search.search_extra.start = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.search_extra['end'] )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours(23,59,59,0);

					this.report_search.search_extra.end = end_of_month;//Utils.getUTCMysqlStringFromDate( end_of_month );
				}

				this.endx = Utils.getLocalMysqlStringFromDate( this.report_search.search_extra['end'] as Date);
				this.startx = Utils.getLocalMysqlStringFromDate( this.report_search.search_extra['start'] as Date);

				if( !response.param_map.has('store_id') )
				{
					this.report_search.search_extra.store_id = this.rest.current_user.store_id;
				}

				let group_by = [];

				if( this.report_search.search_extra['screated_by_user_id'] )
				{
					this.show_mesero = true;
					group_by.push('created_by_user_id' );
				}

				if( this.report_search.search_extra['stable_id'] )
				{
					this.show_table = true;
					group_by.push('table_id' );
				}

				if( this.report_search.search_extra['sorder_date'] )
				{
					this.show_date = true;
					group_by.push('order_date' );
				}

				

				group_by.push( 'store_id' );
				this.report_search.search_extra['group_by'] = group_by.join(',');

				return this.rest.getReporte('getOccupancy',this.report_search.search_extra);
			})
		)
		.subscribe((responses)=>
		{
			console.log('Response is ',responses );
			this.is_loading = false;
			this.report_list = responses.map((row)=>
			{
				let hours	= Math.floor( row.duration /3600 );
				let time	= row.duration % 3600;
				let minutes	= Math.floor( time/60 );
				row.avg_time = (''+row.avg_time).replace(/\..*/g,'');
				
				row.duration_string = (''+hours).padStart(2,'0')+':'+((''+minutes ).padStart(2,'0'));
				return row;
			});
		},(error)=>{
			this.showError(error);
		});
	}
}
