import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { OrderInfo, PaymentInfo } from 'src/app/models/models';
import { Billing_Data, Store, Store_Sale_Report } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';


interface Resume
{
	name:string;
	qty:number;
	total: number;
}

interface PaymentEntry
{
	currency_id:string;
	amount:number;
	payment_method: string;
	name:string;
}

@Component({
	selector: 'app-view-sales-report',
	templateUrl: './view-sales-report.component.html',
	styleUrls: ['./view-sales-report.component.css']
})
export class ViewSalesReportComponent extends BaseComponent
{
	order_info_list: OrderInfo[] = [];
	store_sale_report: Store_Sale_Report | null = null ;
	payment_info_list: PaymentInfo[] = [];

	subtotal:number = 0;
	total:number = 0;
	tax:number = 0;
    ieps: number = 0;
	amount_paid: number = 0;
	store: Store = GetEmpty.store();
	billing_data:Billing_Data = GetEmpty.billing_data();
	discounts: number = 0;
	box_amount: number = 0;
	credit: number = 0;
	payment_entries:PaymentEntry[] = [];
	total_order_discount: number = 0;

	tax_resume_list: Resume[] = [];
	category_resume_list: Resume[] = [];
	user_resume_list:Resume[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
					return this.rest.store_sale_report.get( param_map.get('id') )
			}),
			mergeMap((ssr:Store_Sale_Report)=>
			{
				let order_ids = ssr.order_ids.split(',')

				if( this.rest.has_hades )
				{
					let ares_order_ids = ssr.ares_order_ids.split(',');
					if( ares_order_ids.length )
					order_ids.push( ...ares_order_ids );
				}

				return forkJoin
				({
					billing_data: this.rest.getStore( ssr.store_id ).pipe
					(
						mergeMap((response)=>
						{
							this.store = response;
							if( this.store.default_billing_data_id )
							{
								return this.rest.billing_data.get( response.default_billing_data_id );
							}
							return of( GetEmpty.billing_data() );
						})
					),
					store_sale_report: of( ssr ),
					orders: this.rest.order_info.search({csv:{ id: order_ids },limit:9999}),
					payment: this.rest.payment_info.search
					({
						search_extra:{ order_id: order_ids.join(',') },
						limit: 99999
					})
				})
			})
		)
		.subscribe((response)=>
		{
			this.billing_data = response.billing_data;
			this.store_sale_report = response.store_sale_report;
			this.order_info_list = response.orders.data;
			this.payment_info_list = response.payment.data;

			this.total		= this.order_info_list.reduce((p,oi)=>p+oi.order.total,0);
			this.subtotal	= this.order_info_list.reduce((p,oi)=>p+oi.order.subtotal,0);
			this.discounts = this.order_info_list.reduce((p,oi)=>p+oi.order.discount,0);
			this.tax = this.order_info_list.reduce((p,oi)=>p+oi.order.tax,0);
			this.ieps = this.order_info_list.reduce((p,oi)=>p+oi.order.sat_ieps,0);
			this.amount_paid = this.order_info_list.reduce((p,oi)=>p+oi.order.amount_paid,0);

			this.credit = this.order_info_list.reduce((p,oi)=>
			{
				let total = oi.order.total-oi.order.discount;
				return p+(total-oi.order.amount_paid);
			},0);


			let category_map:Map<string,Resume> = new Map();
			let tax_resume_map:Map<string,Resume> = new Map();
			let user_resume_map:Map<string,Resume> = new Map();

			let total_order_discount:number = 0;

			//Esto en teoria esta mal tenemos que sumar los Normales y
			//Restar los que regresan.

			for(let oi of this.order_info_list)
			{
				for(let ii of oi.items )
				{
					if( ii.order_item.total && ii.order_item.type == 'NORMAL')
					{
						let name = ii.category == null ? "S/C" : ii.category.name;

						let cr = category_map.get( name );

						if( cr == null )
						{
							cr = {
								name: name,
								qty: 0,
								total: 0
							};
							category_map.set( cr.name, cr );
						}

						cr.total +=  ii.order_item.total;
						cr.qty	+= ii.order_item.qty;


						//let ieps_qty = ii.order_item.ieps_calculated ? ii.order_item.qty : 0;

						//if( ieps_qty )
						//{
						//	let cname = ii.category == null ? "S/C IEPS" : ii.category.name+' IEPS';

						//	let cr = category_map.get( cname );
						//	if( cr == null )
						//	{
						//		cr = {
						//			name: cname,
						//			qty: 0,
						//			total: 0
						//		};
						//		category_map.set( cr.name, cr );
						//	}

										//}

						let tr_name = ii.item.applicable_tax == 'EXEMPT' ? 'IVA al 0%' : 'IVA '+oi.order.tax_percent+'%';
						let tr:Resume | null = tax_resume_map.get( tr_name ) || null;

						if( tr == null )
						{
							tr = { name :tr_name, qty:0, total:0 };
							tax_resume_map.set( tr_name, tr);
						}

						tr.qty += ii.order_item.qty;
						tr.total += ii.order_item.tax;

						//if( ieps_qty )
						//{

						//	let tr2_name = ii.order_item.ieps_value ? 'IEPS al 0' : 'IEPS '+oi.order.tax_percent+'%';
						//	let tr2:Resume | null = tax_resume_map.get( tr_name ) || null;

						//	if( ii.order_item.ieps_calculated && tr2 == null )
						//	{
						//		console.log('Adding IEPS to tax resume');
						//		tr2 = { name :tr_name, qty:0, total:0 };
						//		tax_resume_map.set( tr_name, tr2);
						//	}
						//	else
						//	{
						//		console.log("IEPS MTF", ii.order_item.ieps_calculated, oi.order.sat_ieps );
						//	}


						//	tr2.qty += ieps_qty;
						//	tr2.total += ii.order_item.ieps_calculated;

						//	tr.qty += ii.order_item.qty;
						//	tr.total += ii.order_item.tax;
						//}
					}
				}

				let tr2_name = oi.order.sat_ieps ? 'IEPS %' : 'IEPS al 0%';
				let tr2:Resume | null = tax_resume_map.get( tr2_name ) || null;
				let ieps_qty = oi.items.reduce((p,ii)=>p+ii.order_item.ieps_value != 0 ? ii.order_item.qty : 0,0);

				if( ieps_qty )
				{
					if( ieps_qty && tr2 == null )
					{
						console.log('Adding IEPS to tax resume');
						tr2 = { name :tr2_name, qty:0, total:0 };
						tax_resume_map.set( tr2_name, tr2);
					}
					tr2.qty += ieps_qty;
					tr2.total += oi.order.sat_ieps;
				}
			}

			let sort_resume = (a,b)=>a.name>b.name?1:-1;

			let trl = Array.from(tax_resume_map.values());
			trl.sort(sort_resume);

			let crl =	Array.from(category_map.values());
			crl.sort(sort_resume);

			this.tax_resume_list = trl;
			this.category_resume_list = crl;
			this.box_amount = this.total- this.discounts;

			this.payment_entries	= [
				{
					name:'Pesos',
					currency_id: 'MXN',
					payment_method: 'CASH',
					amount: 0
				}
				,{
					name:'Dolares',
					currency_id: 'USD',
					payment_method: 'CASH',
					amount: 0
				}
				,{
					name:'T.Credito',
					currency_id: 'MXN',
					payment_method: 'CREDIT_CARD',
					amount: 0
				}
				,{
					name:'T.Debito',
					currency_id: 'MXN',
					payment_method: 'DEBIT_CARD',
					amount: 0
				}
			];

			let box = 0;

			for(let pi of this.payment_info_list)
			{
				let user = pi.created_by_user.name;
				//let user_name =

				for(let mi of pi.movements)
				{
					for(let bmo of mi.bank_movement_orders)
					{
						let found = this.order_info_list.find((oi)=>oi.order.id == bmo.order_id );
						if( found )
						{
							let found_entry = this.payment_entries.find((e)=>{
								return e.currency_id == bmo.currency_id &&
									e.name == this.getTransactionName( mi.bank_movement.transaction_type, bmo.currency_id );
							});

							if(! found_entry )
							{
								found_entry = {
									currency_id:bmo.currency_id,
									amount: 0,
									payment_method: mi.bank_movement.transaction_type,
									name: this.getTransactionName( mi.bank_movement.transaction_type,bmo.currency_id )
								}
								this.payment_entries.push( found_entry );
							}

							found_entry.amount += bmo.currency_amount;

							if( mi.bank_movement.transaction_type == 'CASH' )
							{
								box += bmo.amount;
							}
						}
					}
				}
			}
			this.box_amount = box;
		});
	}

	getTransactionName(transaction_type:string, currency_id:string)
	{
		if( transaction_type == 'CASH' && currency_id == 'MXN' )
			return 'Pesos';

		if( transaction_type == 'CASH' && currency_id == 'USD' )
			return 'Dolares';

		if( transaction_type == 'CREDIT_CARD')
			return 'T.Credito';

		if( transaction_type == 'DEBIT_CARD' )
			return 'T.Debito';

		if( transaction_type == 'CHECK' )
			return 'Cheque';

		if( transaction_type == 'TRANSFER' )
			return 'Transferencia';

		if( transaction_type == 'PAYPAL' )
			return 'PAYPAL';

		return 'Otro';
	}

	print()
	{
		window.print();
	}
}
