import { Component, OnInit } from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {ItemStockInfo} from 'src/app/models/models';
import {Stock_Record, Store} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-adjust-stock',
	templateUrl: './adjust-stock.component.html',
	styleUrls: ['./adjust-stock.component.css']
})
export class AdjustStockComponent extends	BaseComponent implements OnInit {

	item_stock_info:ItemStockInfo | null	= null;
	store_list:Store[] = [];
	stock_record_dictionary:Record<number,Stock_Record> = {};

	ngOnInit(): void
	{
		this.stock_record_dictionary = {};

		this.subs.sink = this.route.paramMap.subscribe((paramMap)=>
		{
			let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, sort_order:['name_ASC']});

			this.subs.sink = forkJoin
			({
				stock_info: this.rest.item_stock_info.get( paramMap.get('item_id') ),
				store: store_obs
			})
			.subscribe((response)=>
			{
				response.stock_info.records
				.forEach((st:Stock_Record)=>
				{
					this.stock_record_dictionary[st.store_id]=st;
				});

				response.store.data.forEach((store:Store)=>
				{
					if( !(store.id  in this.stock_record_dictionary ) )
					{
						this.stock_record_dictionary[ store.id ] = {
							created: new Date(),
							created_by_user_id: this.rest.current_user.id,
							description: '',
							id: 0,
							is_current:null,
							item_id: response.stock_info.item.id,
							movement_qty: 0,
							movement_type:'ADJUSTMENT',
							order_item_id:null,
							previous_qty:0,
							production_item_id:null,
							purchase_detail_id:null,
							qty:0,
							serial_number_record_id:null,
							shipping_item_id: null,
							store_id: store.id,
							updated: new Date(),
							updated_by_user_id: this.rest.current_user.id,
						};
					}
				})

				this.store_list = response.store.data;

				this.item_stock_info	= response.stock_info;
			},(error)=>this.showError(error));
		});
	}

	save()
	{
		let info:Stock_Record[] = [];

		for(let i in this.stock_record_dictionary )
		{
			info.push( this.stock_record_dictionary[i] );
		}

		this.rest
		.adjustStock(info)
		.subscribe((_response)=>
		{
			this.showSuccess('Se ajustó el inventario exitosamente');
			this.location.back();
		}
		,(error)=>
		{
			this.showError(error);
		});
	}
}
