import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends BaseComponent implements OnInit
{
	ngOnInit()
	{
		this.subs.sink = this.rest.user.get('me').subscribe((user)=>
		{
			localStorage.setItem('user',JSON.stringify(user));
			this.rest.current_user = user;
		});
	}

	requestPermission(evt:Event)
	{
		this.rest.requestPushNotificationPermission();
	}
}

