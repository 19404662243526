<div class="container-fluid">
	<h1 class="my-3">Preferencias Categoría en sucursal</h1>
	<div class="card py-3 mx-1 px-2">
		<div class="row fw-bold mx-0 border-bottom">
			<div class="col-4">Categoría</div>
			<div class="col-4">Sucursal</div>
			<div class="col-4">Preferencia</div>
		</div>
		<div class="row py-1 align-items-center border-bottom mx-0" *ngFor="let csi of cs_info_list">
			<div class="col-4">{{csi.category.name}}</div>
			<div class="col-8">
				<div *ngFor="let cs of csi.category_store_info_list" class="row align-items-center">
					<div class="col-6">{{cs.store.name }}</div>
					<div class="col-6 form-group">
						<select class="form-control" [name]="'CSI'+csi.category.id+'-'+cs.store.id" [ngModel]="cs.category_store.pos_preference" (ngModelChange)="update(cs.category_store,$event)">
							<option value="SHOW">Mostrar</option>
							<option value="HIDE">Esconder</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
