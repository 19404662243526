import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin,of } from 'rxjs';

import {Commanda_Type, Form, Item, Item_Exception, Stock_Record, Unidad_Medida_Sat} from '../../models/RestModels';
import {Category,Price,Price_Type,Store} from '../../models/RestModels';
import {mergeMap} from 'rxjs/operators';
import {ItemInfo, ItemOptionInfo, ItemOptionValueInfo, ProductoOServicio} from 'src/app/models/models';
import { GetEmpty } from 'src/app/classes/Empties';

interface Custom_Item_Exception extends Item_Exception
{
	stock_item:Item;
	stock_category:Category | null;
	form_name:string;
}

@Component({
	selector: 'app-save-item',
	templateUrl: './save-item.component.html',
	styleUrls: ['./save-item.component.css']
})
export class SaveItemComponent extends BaseComponent implements OnInit
{
	item:Item	= GetEmpty.item();
	category_list:Category[] = [];
	type:string = null;
	other_tax: 'DEFAULT' | 'PORCENTAJE_0' | 'PORCENTAJE_8' | 'PORCENTAJE_16' |'EXEMPT' = 'DEFAULT';

	commanda_type_list:Commanda_Type[] = [];
	price_type_list:Price_Type[] = [];
	price_list:Price[] = [];
	store_list:Store[] = [];
	price_dict:Record<string,Price> = {};
	item_option_info_list:ItemOptionInfo[] = [];
	unidad_medida_sat_list:Unidad_Medida_Sat[]= [];
	exceptions:Custom_Item_Exception[] = [];
	new_exception:string = '';
	productos_servicios_list: ProductoOServicio[] = [];
	form_list:Form[] = [];
	show_category_option:boolean = false;
	counter:number = 0;
	order_type: 'ALL'|'TOGO'|'IN_PLACE'|'PICK_UP'|'QUICK_SALE' = 'ALL';

	order_type_dic = {
		'ALL' : 'Todas',
		'TOGO' : 'A Domicilio',
		'PICK_UP': 'Recoger',
		'IN_PLACE': 'Comer Aqui',
		'QUICK_SALE' : 'Venta Rápida'
	};

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params)=>
			{
				this.type = params.has('type') ? params.get('type') : 'PRODUCT';
				this.show_category_option	= false;
				this.is_loading				= true;

				let item:Item = GetEmpty.item();
				item.availability_type = this.rest.local_preferences?.default_pos_availability_type || 'ALWAYS';
				item.return_action = this.rest.local_preferences?.default_return_action || 'RETURN_TO_STOCK';
				item.background = this.rest.local_preferences.btn_primary_bg_color;

				let empty:ItemInfo = {
					item,
					records:[] as Stock_Record[],
					prices:[] as Price[],
					category: null,
					options: [] as ItemOptionInfo[],
					serials: [],
					exceptions: [] as Item_Exception[]
				};

				this.setTitle( params.has('id') ? 'Editar Articulo' : 'Agregar Articulo' );

				return forkJoin
				({
					item_info: params.has('id') ? this.rest.item_info.get( params.get('id') ) : of( empty ),
					categories: this.rest.category.search({ eq:{ type: this.type }, limit:999998 }),
					commanda_type : this.rest.commanda_type.search({ limit:9999, sort_order:['name_asc'] }),
					unidad_medida_sat: this.rest.unidad_medida_sat.search({ limit:9999, sort_order:['nombre_asc'] }),
					productos_servicios: this.rest.producto_servicio_sat.search({limit:999999}),
					formularios: this.rest.form.search({limit:9999})
				})
			}),
			mergeMap((response)=>
			{
				let exception_ids = response.item_info.exceptions
					.filter(e=>e.stock_item_id!=null)
					.map(e=>e.stock_item_id);

				let data:ItemInfo[] = [];
				let e_data={ total:0, data};

				return forkJoin
				({
					item_info_exeptions: exception_ids.length
						? this.rest.item_info.search({csv:{id:exception_ids},limit:9999})
						: of(e_data),
					item_info: of( response.item_info ),
					categories: of( response.categories ),
					commanda_type : of( response.commanda_type ),
					unidad_medida_sat: of( response.unidad_medida_sat ),
					productos_servicios: of( response.productos_servicios ),
					formularios: of( response.formularios )
				})
			})
		)
		.subscribe((response)=>
		{
			this.order_type = 'ALL';

			if( !response.item_info.item.json_tags )
			{
				response.item_info.item.json_tags = [];
			}

			this.other_tax = this.getOtherTax(response.item_info.item);
			this.item = response.item_info.item,
			this.setTitle(response.item_info.item.id ? 'Editar Artículo '+response.item_info.item.name : 'Nuevo Articulo');
			this.item_option_info_list = response.item_info.options;
			response.categories.data.sort((a,b)=>a.name.localeCompare(b.name));
			this.category_list = response.categories.data;
			this.commanda_type_list = response.commanda_type.data;


			response.unidad_medida_sat.data.sort((a,b)=>a.nombre > b.nombre ? 1 : -1);

			this.unidad_medida_sat_list = response.unidad_medida_sat.data;
			this.productos_servicios_list = response.productos_servicios.data;
			this.form_list = response.formularios.data;
			this.is_loading = false;

			this.exceptions = response.item_info.exceptions
			.map((e:Item_Exception)=>
			{
				let stock_item:Item = null;
				let stock_category:Category = null;

				if( e.stock_item_id )
				{
					let e_item_info =response
						.item_info_exeptions
						.data
						.find((i:ItemInfo)=>i.item.id == e.stock_item_id )

					stock_item = e_item_info.item;
					stock_category = e_item_info.category;
				}

				return {
					...e,
					stock_item,
					stock_category,
					form_name: ''+(this.counter++)
				}
			});
		}
		,(error)=>this.showError(error));
	}

    getOtherTax(item: Item): "DEFAULT" | "PORCENTAJE_0" | "PORCENTAJE_8" | "PORCENTAJE_16" | "EXEMPT"
	{
		if (item.applicable_tax === 'DEFAULT')
			return 'DEFAULT';

		if (item.applicable_tax === 'EXEMPT')
			return 'EXEMPT';


		//Por que esta asi para evitar errores de redondeo o mala interpretacion de los valores flotantes.
		//

		if (item.tax_percent > 0 )
		{
			if (item.tax_percent > 8)
			{
				return 'PORCENTAJE_16';
			}
			return 'PORCENTAJE_8';
		}
		return 'PORCENTAJE_0';
    }

	getCategories(category_id:number,type:string)
	{
		if( type )
		{
			this.type = type;
			return this.rest.category.search
			({
				eq:{ type: this.type},
				sort_order:['name_ASC'],
				limit:9999
			});
		}

		return this.rest.category.get(category_id).pipe
		(
			mergeMap(category=>
			{
				this.type = category.type;

				return this.rest.category.search
				({
					sort_order:['name_ASC'],
					limit:9999
				})
			})
		)
	}

	exceptionAreSimilar(a:Custom_Item_Exception, b:Custom_Item_Exception):boolean
	{
		if( a.stock_item_id && b.stock_item_id )
		{
			if( a.stock_item_id == b.stock_item_id )
			{
				return a.order_type == 'ALL' || b.order_type == 'ALL' || a.order_type == b.order_type;
			}
		}

		return a.description.trim().toLowerCase() == b.description.trim().toLowerCase();
	}

	save():void
	{
		for(let e of this.exceptions )
		{
			let f = this.exceptions.find(a=>a!==e && this.exceptionAreSimilar(a,e));
			if( f )
			{
				this.showError('No puede haber dos excepciones iguales: '+e.description);
				return;
			}
		}

		this.is_loading = true;

		if( this.item.id	)
		{
			this.subs.sink	= this.rest.item_info.update
			({
				item: this.item,
				options: this.item_option_info_list,
				exceptions: this.exceptions
			})
			.subscribe((_item)=>
			{
				this.is_loading = false;
				this.showSuccess('El artículo se actualizó exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.item_info
			.create
			({
				item: this.item,
				options: this.item_option_info_list,
				exceptions: this.exceptions
			})
			.subscribe((_item)=>
			{
				this.is_loading = false;
				this.showSuccess('El artículo se guardó exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
	}

	addOption():void
	{
		this.item_option_info_list.push
		({
			values:[],
			item_option:{
				name: '',
				min_options: 0,
				included_options: 1,//Cuantos items diferentes de esta opcion se pueden seleccionar
				included_extra_qty: 1, //Cuantos elementos incluido sin precio extra ejemplo: 3, pueden ser de cualquier tipo
				min_selections: 0,
				max_extra_qty: 1, //Cuanto elementos extra se pueden incluir
				max_options: 1, //Cuantos items diferentes se pueden seleccionar, por ejemplo si es 2, podria seleccionar 3 de sabor chocolate y 5 de sabor vainilla, 8 seria la cantidad y 2 seria la opciones(chocolate y vainilla)
			}
		});
	}

	removeOption(ioi:ItemOptionInfo):void
	{
		let index = this.item_option_info_list.findIndex(i=> i === ioi );


		if( index > -1 )
		{
			this.item_option_info_list[index].item_option.status = 'DELETED';
			//this.item_option_info_list.splice(index,1);
		}
	}

	addOptionValue(ioi:ItemOptionInfo):void
	{
		ioi.values.push
		({
			item_option_value:{
				item_id: null,
				max_extra_qty: 0,
				extra_price: 0,
				price: 0,
			},
			item: null,
			category: null,
		});
	}
	onItemOptionValueSelected(item_info:ItemInfo,iei:ItemOptionValueInfo):void
	{
		iei.category = item_info.category;
		iei.item_option_value.item_id = item_info.item.id;
		iei.item = item_info.item;
	}
	addNewException()
	{
		if( this.new_exception.trim() == '' )
		{
			this.showError('La excepción no puede estar vacía');
		}
		else
		{
			let f = this.exceptions
			.find(i=>
			{
				console.log( i.description+' '+this.new_exception);

				return i.stock_item == null &&
					i.description.toLowerCase() == this.new_exception.toLowerCase().trim()
			});

			console.log('Found', f );

			if( f )
			{
				this.showError('El nombre "'+this.new_exception+'" Ya se encuentra dentro de los ingredientes');
				return;
			}

			this.exceptions.push
			({
				description: this.new_exception.trim(),
				stock_item_id: null,
				stock_item: null,
				stock_category: null,
				stock_qty: 0,
				order_type: this.order_type,
				form_name: ''+(this.counter++),
				list_as_exception: 'YES'
			});
			this.new_exception = '';
			this.order_type = 'ALL';
		}
	}
	removeException(item_exception:Item_Exception)
	{
		let index = this.exceptions.findIndex(i=>i==item_exception);

		if( index > -1)
		{
			this.exceptions.splice(index,1);
		}
	}
	removeItemOptionValueInfo(ioi:ItemOptionInfo,iovi:ItemOptionValueInfo)
	{
		let index = ioi.values.findIndex((i)=>i == iovi);

		if( index > -1 )
		{
			ioi.values.splice(index,1);
		}
	}
	updateCategory(category_id:number | null)
	{

		if( this.item.clave_sat || !(category_id))
			return;

		let category = this.category_list.find(i=>i.id == category_id );

		//if( category )
		//	this.item.clave_sat = category.default_clave_prod_serv;
	}
	onOptionCategorySelected(category:Category)
	{
		this.is_loading = true;
		this.show_category_option = false;

		this.subs.sink = this.rest.item_info
		.search
		({
			eq:
			{
				category_id: category.id,
				status:'ACTIVE'
			},
			sort_order:['name_ASC'],
			limit:99999
		})
		.subscribe((response)=>
		{
			this.is_loading = false;

			let ioil:ItemOptionInfo ={
				values:[],
				item_option:{
					name: category.name,
					included_options: 1,//Cuantos items diferentes de esta opcion se pueden seleccionar
					included_extra_qty: 1, //Cuantos elementos incluido sin precio extra ejemplo: 3, pueden ser de cualquier tipo
					min_selections: 0,
					max_extra_qty: 1, //Cuanto elementos extra se pueden incluir
					max_options:1,
				}
			};

			for(let ii of response.data)
			{
				ioil.values.push({
					item: ii.item,
					category: ii.category,
					item_option_value: {
						item_id: ii.item.id,
						charge_type: 'INCLUDED',
						extra_price: 0,
						max_extra_qty: 1,
						portion_amount: 1,
						price: 0,
						status: 'ACTIVE'
					}
				});
			}

			this.item_option_info_list.push( ioil );
		}
		,(error)=>
		{
			this.showError(error);
		});
	}

	search_exception:string = '';
	onSearchString(search_str:string)
	{
		this.new_exception = search_str;
	}

	addNewItemException(ii:ItemInfo)
	{

		console.log('Item name is', ii.item.name );

		this.exceptions.push({
			id: 0,
			item_id: this.item.id || 0,
			description: ii.item.name,
			stock_qty: 1,
			stock_item: ii.item,
			stock_category: ii.category,
			stock_item_id:ii.item.id,
			order_type: this.order_type,
			list_as_exception:'YES',
			form_name: ''+(this.counter++)
		});
		this.order_type = 'ALL';
	}

	updateBackground(evt:Event)
	{
		let f = evt.target as HTMLInputElement;

		if( f.checked )
		{
			this.item.background = 'transparent';
		}
		else
		{
			this.item.background = '#000000';
		}
	}

	addTag(tag: string)
	{
		if( this.item.json_tags == null )
			this.item.json_tags = [];

		if( this.item.json_tags.indexOf( tag ) > -1 )
		{
			this.showError('El Tag ya esta en la lista');
			return;
		}

		if( tag.trim() )
			this.item.json_tags.push( tag );
	}

	confirmTagElimination(evt:Event, tag: string)
	{
		evt.preventDefault();
		evt.stopPropagation();

		let index = this.item.json_tags.indexOf( tag );

		if( index > -1 )
			this.item.json_tags.splice( index, 1 );
	}
	updateTax(other_tax: 'DEFAULT' | 'PORCENTAJE_0' | 'PORCENTAJE_8' | 'PORCENTAJE_16' |'EXEMPT')
	{
		this.other_tax =other_tax;
		switch(other_tax)
		{
			case 'DEFAULT':
				this.item.applicable_tax = 'DEFAULT';
				this.item.tax_percent=0;
				return;
			case 'PORCENTAJE_0':
				this.item.applicable_tax = 'PERCENT';
				this.item.tax_percent=0;
				return;
			case 'PORCENTAJE_8':
				this.item.applicable_tax = 'PERCENT';
				this.item.tax_percent=8;
				return;
			case 'PORCENTAJE_16':
				this.item.applicable_tax = 'PERCENT';
				this.item.tax_percent=16;
				return;
			case 'EXEMPT':
				this.item.applicable_tax = 'EXEMPT';
				this.item.tax_percent=0;
				return;
		}
	}
}

