import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ParamMap } from '@angular/router';

import {Currency, Store, Currency_Rate} from '../../models/RestModels';

interface StoreCurrencyRate
{
  store:Store;
  currency_rate:Currency_Rate[];
}

@Component({
  selector: 'app-save-currency-rate',
  templateUrl: './save-currency-rate.component.html',
  styleUrls: ['./save-currency-rate.component.css']
})
export class SaveCurrencyRateComponent extends BaseComponent implements OnInit {

  currency_list:Currency[] = [];

  store_currency_list:StoreCurrencyRate[] = [];

  // constructor(  ) { }

  ngOnInit(): void {
    // this.path = '/save-currency-rate';
    this.setTitle('Tipos de Cambio');

    this.subs.sink = this.route.paramMap.pipe
    (
      mergeMap((paramMap:ParamMap)=>
      {

        return forkJoin
        ({
          store_list: this.rest.store.search({limit:999999}),
          currency_rate_list: this.rest.currency_rate.search({limit:999999}),
          currency_list: this.rest.currency.search({limit:999999})
        })
      })
    )
    .subscribe((responses)=>
    {
      //si tiene permiso, mostrar todas las sucursales
      responses.store_list.data.forEach(s=>
      {
        //get the array of currency rates for the store
        let store_currencys:Currency_Rate[] = [];
        responses.currency_list.data.forEach(c=>
        {
          if ( c.id != s.default_currency_id )
          {
            if(this.rest.current_permission.add_user ==1 || this.rest.current_permission.global_pos ==1 || s.id == this.rest.current_user.store_id)
            {
              let currency_rate = responses.currency_rate_list.data.find(cr=>cr.store_id == s.id && cr.currency_id == c.id); 
              store_currencys.push(
              { 
                store_id:s.id,
                currency_id:c.id,
                rate: currency_rate ? currency_rate.rate : 0,
              });
            }
          }
        });

        if(store_currencys.length != 0)
        {
          this.store_currency_list.push(
          {
            store:s,
            currency_rate:store_currencys
          });
        }
      });
      console.log(this.store_currency_list);    
    }, (error)=>this.showError(error));
  }

  updateCurrencyRate(currency_id:string, id:number, rate:number, store_id:number)
  {
    //actualizar arreglo del componente con el nuevo valor de la moneda
    this.store_currency_list.find(s=>s.store.id == store_id).currency_rate.find(c=>c.currency_id == currency_id).rate = rate;
    //actualizar con el rest el valor de la moneda en la tienda
    if(isNaN(rate) || rate <= 0)
    {
      this.showError('Error, el valor de la moneda debe ser un número mayor a 0.');
      return;
    } 
    this.subs.sink = this.rest.currency_rate.update({currency_id, id , rate, store_id}).subscribe((response)=>
    {
      console.log(response);
      //get the store name
      let store_name = this.store_currency_list.find(s=>s.store.id == store_id).store.name;
      this.showSuccess('Tipo de cambio actualizado para la sucursal ' + store_name);
    }, (error)=>this.showError(error));
    
  }

}
