import { Component } from '@angular/core';
import {Category} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-pos',
	templateUrl: './client-pos.component.html',
	styleUrls: ['./client-pos.component.css']
})
export class ClientPosComponent extends BaseComponent
{
	category_list: Category[];

	ngOnInit(): void
	{
		this.rest.category.search({limit:99999}).subscribe((response)=>
		{
			this.category_list = response.data;
		});
	}
}
