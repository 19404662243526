<div class="container-fluid">
	<div class="row align-items-center my-3">
		<div class="col-6 col-md-3 col-lg-6">
			<h1 class="text-capitalize" *ngIf="user">Ventas del Cliente: {{user.name}}</h1>
			<h1 class="text-capitalize" *ngIf="agent_user">Ventas Agente de ventas {{agent_user.name | uppercase}}</h1>
			<h1 class="text-capitalize" *ngIf="!user && !agent_user">Ventas</h1>
		</div>
		<div class="col-6 col-md-4 col-lg-2 text-end">
			<ng-container *ngIf="rest.current_permission.reports">
				<b>Total</b>:<br class="d-none d-md-inline">
				<span>{{order_total |number: '1.2-2'}}</span>
			</ng-container>
		</div>
		<div class="col-12 col-md-5 col-lg-4 text-end">
			<button type="button" class="btn btn-secondary me-1 me-lg-3" (click)="exportOrdersDetails($event)" *ngIf="!rest.is_offline">
				<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3C7.58 3 4 4.79 4 7s3.58 4 8 4c.5 0 1-.03 1.5-.08V9.5h2.89l-1-1L18.9 5c-1.4-1.2-3.96-2-6.9-2m6.92 4.08L17.5 8.5L20 11h-5v2h5l-2.5 2.5l1.42 1.42L23.84 12M4 9v3c0 2.21 3.58 4 8 4c1.17 0 2.26-.15 3.25-.37l1.13-1.13H13.5v-1.58c-.5.05-1 .08-1.5.08c-4.42 0-8-1.79-8-4m0 5v3c0 2.21 3.58 4 8 4c2.94 0 5.5-.8 6.9-2L17 17.1c-1.39.56-3.1.9-5 .9c-4.42 0-8-1.79-8-4"/></svg>
				&nbsp;
				<!-- <span class="d-none d-sm-inline">Detalles</span> -->
				Detalles
			</button>
			<button type="button" class="btn btn-secondary me-1 me-lg-3" (click)="exportOrders($event)" *ngIf="!rest.is_offline">
				<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
				&nbsp;
				<!-- <span class="d-none d-sm-inline">Exportar</span> -->
				Exportar
			</button>
			<button type="button" class="btn btn-secondary" (click)="show_offline_options=true">
				<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M21.86 12.5A4.313 4.313 0 0 0 19 11c0-1.95-.68-3.6-2.04-4.96C15.6 4.68 13.95 4 12 4c-1.58 0-3 .47-4.25 1.43s-2.08 2.19-2.5 3.72c-1.25.28-2.29.93-3.08 1.95S1 13.28 1 14.58c0 1.51.54 2.8 1.61 3.85C3.69 19.5 5 20 6.5 20h12c1.25 0 2.31-.44 3.19-1.31c.87-.88 1.31-1.94 1.31-3.19c0-1.15-.38-2.15-1.14-3M16.57 18L14 15.43c-.57.36-1.26.57-2 .57c-2.21 0-4-1.79-4-4s1.79-4 4-4s4 1.79 4 4c0 .74-.21 1.43-.57 2L18 16.57L16.57 18M14 12c0 1.11-.89 2-2 2s-2-.89-2-2s.9-2 2-2s2 .9 2 2Z"/></svg>
			</button>
		</div>
	</div>
	<div class="card my-3 p-3" *ngIf="!(order_search?.csv?.status?.length) && !rest.is_offline">
		<div class="row">
			<div class="col-6 col-md-3 col-lg-2 form-group">
				<label class="">Sucursal</label>
				<div class="fw-bold" *ngIf="!rest.current_permission.global_pos">{{ store_dictionary[ rest.current_user.store_id ]?.name | titlecase}}</div>
				<select name="store_id" [(ngModel)]="order_search.eq.store_id" class="form-control" *ngIf="rest.current_permission.global_pos">
					<option [ngValue]="null">Todos</option>
					<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 col-lg-3 form-group order-2">
				<label class="">Fecha Inicial</label>
				<input type="datetime-local" name="inicial_created" [ngModel]="fecha_inicial" (ngModelChange)="fechaIncialChange($event)" class="form-control">
			</div>

			<div class="col-6 col-md-3 col-lg-3 form-group order-2">
				<label class="">Fecha Final</label>
				<input type="datetime-local" name="final_created" [ngModel]="fecha_final" (ngModelChange)="fechaFinalChange($event)" class="form-control">
			</div>

			<div class="col-6 col-md-3 col-lg-2 form-group order-md-2" *ngIf="rest.current_permission.advanced_order_search">
				<label class="">Búsqueda Avanzada</label>
				<div class="form-check form-switch">
					<label class="form-check-label">
						<input type="checkbox" name="show_advanced_search" value=1 class="form-check-input" (click)="toggleAdvancedSearch($event)" [checked]="show_advanced_search">
					</label>
				</div>
			</div>

			<ng-container *ngIf="show_advanced_search">
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Precio</label>
					<select name="price_type_id" [(ngModel)]="order_search.eq.price_type_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of price_type_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus Pago</label>
					<select name="paid_status" [(ngModel)]="order_search.eq.paid_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="PARTIALLY_PAID">Pago Parcial</option>
						<option value="PAID">Pagado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus</label>
					<select name="status" [(ngModel)]="order_search.eq.status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="ACTIVE">Activas</option>
						<option value="CANCELLED">Canceladas</option>
						<option value="CLOSED">Finalizadas</option>
						<option value="PENDING">Pendientes</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Estatus De Entrega</label>
					<select name="delivery_status" [(ngModel)]="order_search.eq.delivery_status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="DELIVERED">Entregado</option>
						<option value="CANCELLED">Cancelado</option>
						<option value="READY_TO_PICKUP">Listo para Recoger</option>
						<option value="SENT">Enviado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Total</label>
					<input type="text" name="total_final" [(ngModel)]="order_search.eq.total_final" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Metodo de pago</label>
					<select name="transaction_type" [(ngModel)]="order_search.search_extra.transaction_type" class="form-control">
						<option [ngValue]="null">Todas</option>
						<option value="CASH">Efectivo</option>
						<option value="CREDIT_CARD">Tarjeta Credito</option>
						<option value="DEBIT_CARD">Tarjeta Debito</option>
						<option value="CHECK">Cheque</option>
						<option value="COUPON">Coupon</option>
						<option value="TRANSFER">Transferencia</option>
						<option value="DISCOUNT">Descuento</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Folio</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.eq.store_consecutive" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Cliente</label>
					<input type="text" name="client_name" [(ngModel)]="order_search.lk.client_name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Sin Cliente Registrado</label>
					<div class="form-check form-switch">
						<label class="form-check-label">
							<input type="checkbox" name="publico_general" class="form-check-input" (click)="togglePublicoGeneral($event)" [checked]="order_search.search_extra.publico_general">
						</label>
					</div>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Facturado</label>
					<select name="facturado" [(ngModel)]="order_search.csv.facturado" class="form-control">
						<option value="YES,NO" [selected]="order_search.csv.facturado == null">Todos</option>
						<option value="YES">Facturado</option>
						<option value="NO">Sin Facturar</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Cajero</label>
					<select name="cashier_id" [(ngModel)]="order_search.eq.cashier_user_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of cashier_user_list" [value]="c.id">
							{{c.name | titlecase}} <ng-container *ngIf="c.username?.toLowerCase()?.localeCompare( c?.name?.toLowerCase() ) != 0">({{c.username}})</ng-container>
						</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Con Descuento</label>
					<select name="with_discounts" [(ngModel)]="order_search.search_extra.with_discounts" class="form-control">
						<option value="">Todos</option>
						<option value="1">Con Descuento</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Código Facturación</label>
					<input type="text" name="lkfacturacion_code" [(ngModel)]="order_search.lk.facturacion_code" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Sucursal Consecutivo</label>
					<input type="number" name="store_consecutive" [(ngModel)]="order_search.eq.store_consecutive" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Sat Serie</label>
					<input type="number" name="sat_serie" [(ngModel)]="order_search.eq.sat_serie" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Sat Consecutivo</label>
					<input type="number" name="sat_serie_consecutive" [(ngModel)]="order_search.eq.sat_serie_consecutive" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group order-2">
					<label class="">Razon Social</label>
					<input type="text" name="sat_razon_social" [(ngModel)]="order_search.eq.sat_razon_social" class="form-control">
				</div>
			</ng-container>
			<div class="col-6 col-md-3 col-lg-2 order-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(order_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-order'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="d-none d-lg-block">
			<table class="mb-0 w-100">
				<thead class="thead-light">
					<tr class="d-none d-xxl-table-row">
						<th colspan="7" class=""></th>
						<th colspan="3" class="border text-center">Descuentos</th>
						<th></th>
					</tr>
					<tr>
						<th>Id</th>
						<th>Cliente</th>
						<th>Sucursal</th>
						<th class="d-none d-xxl-table text-center">Atendio</th>
						<th class="text-center">Tipo</th>
						<th>Fecha</th>
						<th class="text-center">Estatus</th>
						<th class="text-end d-none d-xxl-table-cell">Articulos</th>
						<th class="text-end d-none d-xxl-table-cell">Orden</th>
						<th class="text-end">
							<span class="d-none d-xxl-inline">Todo</span>
							<span class="d-inline d-xxl-none">Desc</span>
						</th>
						<th class="text-end" (click)="clickOnHeader('total_final')">Total</th>
						<th class="text-center">Acciones</th>
					</tr>
				</thead>
				<tbody *ngIf="price_type_list.length">
					<tr *ngFor="let oi of order_info_list" class="border align-middle">
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<div>
								<a *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING' )" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
								<a *ngIf="!oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.sync_id,'offline']">{{oi.order.sync_id}}</a>
							</div>
						</td>
						<td>
							<div>
								<span *ngIf="rest.is_offline &&( oi.order.status == 'CLOSED' ||oi.order.status == 'CANCELLED' || oi.order.paid_status == 'PAID')">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</span>
								<a *ngIf="!rest.is_offline  &&  (oi.order.status=='PENDING' || oi.order.status=='ACTIVE' )" [routerLink]="['/pos',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="rest.is_offline  && (oi.order.status == 'ACTIVE' || oi.order.status=='PENDING' ) && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos/offline',oi.order.sync_id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
								<a *ngIf="!rest.is_offline && (oi.order.status == 'CANCELLED' || oi.order.status == 'CLOSED' || oi.order.paid_status == 'PAID')" [routerLink]="['/view-order',oi.order.id]">
									{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
								</a>
							</div>
							<div class="d-xxl-none">{{oi.store.name}}</div>
						</td>
						<td>{{oi.store.name | titlecase }}</td>
						<td class="d-none d-xxl-table-cell">{{oi?.cashier?.name | titlecase }}</td>
						<td>
							<span *ngIf="oi.order.service_type == 'TOGO'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 4a2 2 0 0 0-2 2v11h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-5l-3-4h-3V4m-7 2l4 4l-4 4v-3H4V9h6m7 .5h2.5l1.97 2.5H17M6 15.5A1.5 1.5 0 0 1 7.5 17A1.5 1.5 0 0 1 6 18.5A1.5 1.5 0 0 1 4.5 17A1.5 1.5 0 0 1 6 15.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'PICK_UP'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z"/></g></svg>
							</span>
							<span *ngIf="oi.order.service_type == 'IN_PLACE'">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z" fill="currentColor"/></svg>
							</span>

							<!--{{price_type_dic | json}} {{price_type_list |json }}-->

							{{oi.price_name | titlecase}}
						</td>
						<td>
							<abbr title="{{oi.order.closed_timestamp || oi.order.created | date: 'MMM d, y HH:mm' }}">{{(oi.order.closed_timestamp || oi.order.created) | shortDate}}</abbr>
						</td>
						<td class="text-center">
							<div *ngIf="oi.order.status != 'CANCELLED'">
								<span *ngIf="oi.order.status == 'PENDING' && oi.order.delivery_status == 'PENDING' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M28 30H6V8h13.22l2-2H6a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V15l-2 2z" class="clr-i-outline clr-i-outline-path-1" fill="currentColor"/><path d="M33.53 5.84l-3.37-3.37a1.61 1.61 0 0 0-2.28 0L14.17 16.26l-1.11 4.81A1.61 1.61 0 0 0 14.63 23a1.69 1.69 0 0 0 .37 0l4.85-1.07L33.53 8.12a1.61 1.61 0 0 0 0-2.28zM18.81 20.08l-3.66.81l.85-3.63L26.32 6.87l2.82 2.82zM30.27 8.56l-2.82-2.82L29 4.16L31.84 7z" class="clr-i-outline clr-i-outline-path-2" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.status != 'PENDING' || oi.order.delivery_status != 'PENDING' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path d="M33 6.4l-3.7-3.7a1.71 1.71 0 0 0-2.36 0L23.65 6H6a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V11.76l3-3a1.67 1.67 0 0 0 0-2.36zM18.83 20.13l-4.19.93l1-4.15l9.55-9.57l3.23 3.23zM29.5 9.43L26.27 6.2l1.85-1.85l3.23 3.23z" class="clr-i-solid clr-i-solid-path-1" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status == 'READY_TO_PICKUP' || oi.order.delivery_status =='PENDING'" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M27.71 4.29a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l9.6 3.84l3.84 9.6a1 1 0 0 0 .9.63a1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05zM19 24.2l-2.79-7L21 12.41L19.59 11l-4.83 4.83L7.8 13l17.53-6.33z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status=='SENT' || oi.order.delivery_status=='DELIVERED'" class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M27.71 4.29a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l8.59 3.43L19.59 11L21 12.41l-6.37 6.37l3.44 8.59A1 1 0 0 0 19 28a1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.delivery_status != 'DELIVERED' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/></g></svg>
								</span>
								<span *ngIf="oi.order.delivery_status == 'DELIVERED' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793L6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/></g></svg>
								</span>
								<span *ngIf="oi.order.paid_status != 'PAID' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.89-8.9c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.44-.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6.56-2.62 2.85-2.62 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 3.02 2.96V19h1.75v-1.24c.52-.09 3.02-.59 3.02-3.22c.01-1.39-.6-2.61-3-3.44z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.paid_status == 'PAID' " class="text-success">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
								</span>
							</div>
							<div *ngIf="oi.order.status == 'CANCELLED'">
								<span *ngIf="!oi.order.system_activated" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 16 16"><path fill="currentColor" d="M6.923 1.378a3 3 0 0 1 2.154 0l4.962 1.908a1.5 1.5 0 0 1 .961 1.4v6.626a1.5 1.5 0 0 1-.961 1.4l-4.962 1.909a3 3 0 0 1-2.154 0l-4.961-1.909a1.5 1.5 0 0 1-.962-1.4V4.686a1.5 1.5 0 0 1 .962-1.4l4.961-1.908Zm1.795.933a2 2 0 0 0-1.436 0l-1.384.533l5.59 2.116l1.948-.834L8.718 2.31ZM14 4.971L8.5 7.33v6.428c.074-.019.146-.042.218-.07l4.962-1.908a.5.5 0 0 0 .32-.467v-6.34Zm-6.5 8.786V7.33L2 4.972v6.34a.5.5 0 0 0 .32.467l4.962 1.908c.072.028.144.051.218.07ZM2.564 4.126L8 6.456l2.164-.928l-5.667-2.146l-1.933.744Z"/></svg>
								</span>
								<span *ngIf="oi.order.system_activated" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 640 512"><path fill="currentColor" d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5c-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64L69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"/></svg>
								</span>
								<span *ngIf="oi.order.paid_status == 'PENDING' " class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.89-8.9c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.44-.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6.56-2.62 2.85-2.62 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 3.02 2.96V19h1.75v-1.24c.52-.09 3.02-.59 3.02-3.22c.01-1.39-.6-2.61-3-3.44z" fill="currentColor"/></svg>
								</span>
								<span *ngIf="oi.order.paid_status != 'PENDING'" class="text-danger">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
								</span>
							</div>
							<span [ngClass]="{'text-danger':oi.order.paid_status!='PENDING','text-warning':oi.order.paid_status == 'PENDING'}" *ngIf="oi.order.status == 'CANCELLED'">
								Cancelada
							</span>
						</td>
						<td class="text-end border-left ">{{oi.article_discount | currency}}</td>
						<td class="text-end d-none d-xxl-table-cell">{{oi.order.discount | currency}}</td>
						<td class="text-end border-right d-none d-xxl-table-cell">{{(oi.order.discount+oi.article_discount) | currency}}</td>
						<td class="text-end">{{oi.order.total-oi.order.discount | currency:'$' }}</td>
						<td class="text-end nowrap">
							<ng-container class="" *ngIf="oi.order.status != 'CANCELLED' && !rest.is_offline">
								<button type="button" class="btn btn-primary ms-1" *ngIf="oi.order.service_type =='TOGO' && oi.order.delivery_status == 'PENDING'" (click)="confirmReadyToPickup(oi)">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 12c2.76 0 5-2.24 5-5s-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5m5.45 5.6c-.39-.4-.88-.6-1.45-.6h-7l-2.08-.73l.33-.94L13 16h2.8c.35 0 .63-.14.86-.37s.34-.51.34-.82c0-.54-.26-.91-.78-1.12L8.95 11H7v9l7 2l8.03-3c.01-.53-.19-1-.58-1.4M5 11H.984v11H5V11z" fill="currentColor"/></svg>
								</button>
								<button type="button" class="btn btn-primary ms-1" *ngIf="oi.order.service_type =='TOGO' && oi.order.delivery_status == 'READY_TO_PICKUP'" (click)="confirmSend(oi)">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M27.71 4.29a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l8.59 3.43L19.59 11L21 12.41l-6.37 6.37l3.44 8.59A1 1 0 0 0 19 28a1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05z" fill="currentColor"/></svg>
								</button>
								<button type="button" class="btn btn-primary ms-1" *ngIf="oi.order.delivery_status != 'DELIVERED' && oi.order.service_type=='TOGO' && !oi.order.delivery_user_id" (click)="showSelectDeliveryUser(oi)">
									<!--svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path d="M55.017 7.5c-3.844 0-6.892 3.18-6.892 7.024s3.048 7.025 6.892 7.025c3.976 0 7.024-3.181 7.024-7.025c0-3.843-3.048-7.024-7.024-7.024zm-5.964 13.518c-1.458.133-2.651.928-3.579 1.59c-1.193.929-3.048 2.917-3.048 2.917v.132c-.133 0-7.422 8.482-11.663 12.458c-.398.398-.663.795-.795 1.326l-2.12 11.53c-.398 1.325.662 2.916 1.987 3.18c1.326.266 2.916-.794 3.048-2.252l2.121-10.736l8.217-8.217v21.868l-4.639 11.53L24.27 86.623a3.994 3.994 0 0 0-.663 2.784c.133.927.663 1.855 1.458 2.385c.795.663 1.855.795 2.916.663c.927-.265 1.855-.795 2.385-1.59v-.133l14.446-20.41l.398-.795l5.434-13.519l10.735 14.446l7.952 18.555c.663 1.723 3.048 2.65 4.771 1.855c1.723-.662 2.651-3.048 1.988-4.77l-8.084-18.82l-.398-.796L57.27 52.694V35.862l4.108 4.771l.398.398l10.602 8.217c.928.795 2.784.663 3.579-.398c.795-1.06.53-2.783-.398-3.71l-10.337-8.085c-3.181-3.711-7.29-8.88-9.145-11.265c-.928-1.193-3.314-4.374-6.362-4.772z" fill="currentColor" fill-rule="evenodd"/></svg-->
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path d="M512.9 192c-14.9-.1-29.1 2.3-42.4 6.9L437.6 144H520c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24h-45.3c-6.8 0-13.3 2.9-17.8 7.9l-37.5 41.7l-22.8-38C392.2 68.4 384.4 64 376 64h-80c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h66.4l19.2 32H227.9c-17.7-23.1-44.9-40-99.9-40H72.5C59 104 47.7 115 48 128.5c.2 13 10.9 23.5 24 23.5h56c24.5 0 38.7 10.9 47.8 24.8l-11.3 20.5c-13-3.9-26.9-5.7-41.3-5.2C55.9 194.5 1.6 249.6 0 317c-1.6 72.1 56.3 131 128 131c59.6 0 109.7-40.8 124-96h84.2c13.7 0 24.6-11.4 24-25.1c-2.1-47.1 17.5-93.7 56.2-125l12.5 20.8c-27.6 23.7-45.1 58.9-44.8 98.2c.5 69.6 57.2 126.5 126.8 127.1c71.6.7 129.8-57.5 129.2-129.1c-.7-69.6-57.6-126.4-127.2-126.9zM128 400c-44.1 0-80-35.9-80-80s35.9-80 80-80c4.2 0 8.4.3 12.5 1L99 316.4c-8.8 16 2.8 35.6 21 35.6h81.3c-12.4 28.2-40.6 48-73.3 48zm463.9-75.6c-2.2 40.6-35 73.4-75.5 75.5c-46.1 2.5-84.4-34.3-84.4-79.9c0-21.4 8.4-40.8 22.1-55.1l49.4 82.4c4.5 7.6 14.4 10 22 5.5l13.7-8.2c7.6-4.5 10-14.4 5.5-22l-48.6-80.9c5.2-1.1 10.5-1.6 15.9-1.6c45.6-.1 82.3 38.2 79.9 84.3z" fill="currentColor"/></svg>
								</button>
								<a class="mx-1 btn btn-primary" *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
								</a>
								<button type="button" class="mx-1 btn btn-primary ms-1" *ngIf="oi.order.delivery_status != 'DELIVERED'" (click)="confirmDelivered(oi)">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 174.8l-39.6-39.6c-9.4-9.4-24.6-9.4-33.9 0L192 374.7L80.6 263.2c-9.4-9.4-24.6-9.4-33.9 0L7 302.9c-9.4 9.4-9.4 24.6 0 34L175 505c9.4 9.4 24.6 9.4 33.9 0l296-296.2c9.4-9.5 9.4-24.7.1-34zm-324.3 106c6.2 6.3 16.4 6.3 22.6 0l208-208.2c6.2-6.3 6.2-16.4 0-22.6L366.1 4.7c-6.2-6.3-16.4-6.3-22.6 0L192 156.2l-55.4-55.5c-6.2-6.3-16.4-6.3-22.6 0L68.7 146c-6.2 6.3-6.2 16.4 0 22.6l112 112.2z" fill="currentColor"/></svg>
								</button>
								<button type="button" class="btn btn-primary ms-1" (click)="confirmCancel(oi)" *ngIf="(oi.order.status != 'CLOSED' || rest.current_permission.cancel_closed_orders)">
									<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8s8-3.589 8-8s-3.589-8-8-8zm-5 8c0-.832.224-1.604.584-2.295l6.711 6.711A4.943 4.943 0 0 1 12 17c-2.757 0-5-2.243-5-5zm9.416 2.295L9.705 7.584A4.943 4.943 0 0 1 12 7c2.757 0 5 2.243 5 5c0 .832-.224 1.604-.584 2.295z"/></svg>
								</button>
								<button type="button" class="btn btn-primary ms-1" (click)="confirmAddStockToStore(oi)" *ngIf="oi.purchase == null && oi.order.status == 'CLOSED' && oi?.client?.store_id && ( rest.current_permission.add_stock )">
									<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path fill="currentColor" d="m223.2 68.4l-16-32A8.2 8.2 0 0 0 200 32H56a8.2 8.2 0 0 0-7.2 4.4l-16 32A9 9 0 0 0 32 72v136a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V72a9 9 0 0 0-.8-3.6Zm-55.6 87.3l-33.9 34a8.2 8.2 0 0 1-11.4 0l-33.9-34a8 8 0 0 1 11.3-11.3l20.3 20.3V104a8 8 0 0 1 16 0v60.7l20.3-20.3a8 8 0 0 1 11.3 11.3ZM52.9 64l8-16h134.2l8 16Z"/></svg>
								</button>
								<button type="button" class="btn btn-primary ms-1" (click)="showSendToStore(oi)" *ngIf="oi.purchase == null && oi.order.status == 'CLOSED' && oi?.client?.store_id && ( rest.current_permission.send_shipping )">
									<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M13.577 2.378a3 3 0 0 0-2.154 0L7.962 3.709A1.5 1.5 0 0 0 7 5.109v1.1a4 4 0 0 1 1.936.235l3.46 1.332c.617.236 1.099.7 1.367 1.272A5.477 5.477 0 0 1 18 10.256V5.109a1.5 1.5 0 0 0-.961-1.4l-3.461-1.331Zm1.868 2.154a.5.5 0 1 1 .359.934l-2.766 1.063a1.5 1.5 0 0 1-1.077 0L9.195 5.466a.5.5 0 0 1 .36-.934l2.765 1.064a.5.5 0 0 0 .36 0l2.765-1.064ZM12.038 8.71a1.5 1.5 0 0 1 .713.574a5.49 5.49 0 0 0-1.699.93a.5.5 0 0 0-.607-.207L7.5 11.139l-2.946-1.133a.5.5 0 1 0-.359.933L7 12.018v2.49a.5.5 0 0 0 1 0v-2.49l2.088-.803A5.475 5.475 0 0 0 9 14.499c0 .977.255 1.894.7 2.689l-1.123.432a3 3 0 0 1-2.154 0l-3.461-1.33A1.5 1.5 0 0 1 2 14.89v-4.78a1.5 1.5 0 0 1 .962-1.4l3.46-1.331a3 3 0 0 1 2.155 0l3.461 1.331ZM19 14.499a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0Zm-2.147.354l.003-.002a.5.5 0 0 0 .144-.349v-.006a.5.5 0 0 0-.146-.35l-2-2a.5.5 0 0 0-.708.707l1.147 1.146H12.5a.5.5 0 0 0 0 1h2.793l-1.147 1.146a.5.5 0 0 0 .708.708l2-2Z"/></svg>
								</button>
								<!--button type="button" (click)="showOrderReturnModal(oi)" *ngIf="oi.order.paid_status == 'PAID' && oi.order.delivery_status=='DELIVERED'" class="text-success">
									Reembolsar
								</button-->
							</ng-container>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row mb-3 border-bottom pb-2 d-lg-none" *ngFor="let oi of order_info_list">
			<div class="col-9">
				<a [routerLink]="['/view-order',oi.order.id]" *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a *ngIf="!oi.order.id && oi.order.paid_status != 'PENDING'" [routerLink]="['/view-order-offline',oi.order.id]" >
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{( oi.client?.name || oi.order.client_name ) | titlecase}}
				</a>
				<a [routerLink]="['/pos',oi.order.id]" *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'">
					{{oi.order.id}} -
					<img [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:20px">
					{{oi.order.client_name}}
				</a>
			</div>
			<div class="col-3 px-0 text-end align-self-center">{{oi.order?.total | currency:'$'}}</div>
			<div class="col-6 col-sm-4 align-self-center">
				<span *ngIf="oi.order?.delivery_status == 'PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/></g></svg>
				</span>
				{{oi?.store?.name | titlecase}} {{(oi.order?.delivery_status == 'DELIVERED') ? '✓':'' | titlecase}}
			</div>
			<div class="col-6 col-sm-2 col-md-2 align-self-center">
				{{oi.order.created | shortDate: 'MMM d' }}
			</div>
			<div class="col-12 col-sm-6 col-md-6 text-end px-0">
				<a *ngIf="oi.order.status != 'CANCELLED' " [routerLink]="['/edit-order',oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path class="clr-i-solid clr-i-solid-path-1" d="M4.22 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0L13 32l15.84-15.78L20 7.4z" fill="currentColor"/><path class="clr-i-solid clr-i-solid-path-2" d="M33.82 8.32l-5.9-5.9a2.07 2.07 0 0 0-2.92 0L21.72 5.7l8.83 8.83l3.28-3.28a2.07 2.07 0 0 0-.01-2.93z" fill="currentColor"/></svg>
				</a>
				<a *ngIf="oi.order.status !== 'CANCELLED' && oi.order.id" [routerLink]="['/print-receipt',this.rest.local_preferences.default_print_receipt,oi.order.id]" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path d="M90.443 35.141a1.825 1.825 0 0 0-1.818-1.708H75.511V11.857c0-1.012-.819-1.83-1.83-1.83H26.319c-1.011 0-1.83.818-1.83 1.83v21.576H11.377h-.002c-.969 0-1.754.755-1.818 1.708h-.012V71.91a1.83 1.83 0 0 0 1.83 1.829h13.114V58.425H75.51v15.314h13.114a1.83 1.83 0 0 0 1.83-1.829V35.141h-.011zm-19.919 6.49H29.476V16.844c0-1.012.819-1.83 1.83-1.83h37.387c1.011 0 1.83.818 1.83 1.83v24.787z" fill="currentColor"/><path d="M29.602 88.143c0 1.012.819 1.83 1.83 1.83h37.136c1.011 0 1.83-.818 1.83-1.83v-24.64H29.602v24.64z" fill="currentColor"/></svg>
				</a>
				<button type="button" class="btn btn-primary ms-1" (click)="confirmCancel(oi)" *ngIf="oi.order.status != 'CANCELLED' && (oi.order.status != 'CLOSED' || rest.current_permission.cancel_closed_orders)">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8s8-3.589 8-8s-3.589-8-8-8zm-5 8c0-.832.224-1.604.584-2.295l6.711 6.711A4.943 4.943 0 0 1 12 17c-2.757 0-5-2.243-5-5zm9.416 2.295L9.705 7.584A4.943 4.943 0 0 1 12 7c2.757 0 5 2.243 5 5c0 .832-.224 1.604-.584 2.295z"/></svg>
				</button>
				<a *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)" class="btn btn-primary mx-1">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12" *ngIf="order_info_list == null || order_info_list.length == 0 && !is_loading">
				<div class="alert alert-light m-3" role="alert" *ngIf="this.rest.is_offline">No se encontraron elementos</div>
				<div class="alert alert-light m-3 text-center" role="alert" *ngIf="!this.rest.is_offline">
					No se encontraron elementos.
					<br>
					<button class="btn btn-primary" (click)="loadLastOrders()">
						buscar las últimas 20 ventas
					</button>
				</div>
			</div>
		</div>
	</div>
	<app-pagination [path]="'/list-order'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
<app-modal [(show)]="show_asign_delivery_user" *ngIf="selected_order_info">
	<app-select-delivery-user
		(onUserSelected)="onDeliveryUserSelected($event)"
		[order_id]="selected_order_info.order.id"
		[delivery_store_id]="selected_order_info.order.store_id">
	</app-select-delivery-user>
</app-modal>
<!--app-modal [show]="show_returns" [closable]="false" [biggest_posible]="true">
	<ng-container *ngIf="selected_order_info">
		<app-make-order-return [order_id]="selected_order_info.order.id" (onCancel)="this.show_returns=false" (onOrderReturned)="onOrderReturned($event)"></app-make-order-return>
	</ng-container>
</app-modal-->
<app-modal [(show)]="show_create_shipping">
	<form (ngSubmit)="confirmSendToStore($event)" ngNativeValidate>
		<div class="row align-items-center p-3">
			<div class="col-12 col-md-6">
				<label class=""># Económico o Guía</label>
				<input type="text" name="shipping_guide" [(ngModel)]="shipping_guide" placeholder="xxxxxx" class="form-control" required>
			</div>
			<div class="col-12 col-md-6">
				<label class="">Nombre chofer/Paqueteria</label>
				<input type="text" name="shipping_company" [(ngModel)]="shipping_company" placeholder="Jesus martinez" class="form-control" required>
			</div>
			<div class="col-12 col-md-6">
				<label class="">Fecha de envío</label>
				<input type="date" name="shipping_date" [(ngModel)]="shipping_date" class="form-control" placeholder="YYYY-mm-dd" required>
			</div>
			<!--div class="col-6 col-md-4">
				<label class="">Received by user</label>
				<input type="number" name="received_by_user_id" [(ngModel)]="shipping_info.shipping.received_by_user_id" class="form-control">
			</div-->
			<div class="col-12 col-md-6">
				<div class="form-check form-switch">
					<input name="mark_shipping_as_send" class="form-check-input" type="checkbox" id="mark_as_sent" (change)="markAsSentChange($event)" [checked]="mark_as_sent">
					<label class="form-check-label" for="mark_as_sent">Marcar Como enviado</label>
				</div>
			</div>
		</div>
		<div class="row p-3">
			<div class="d-none d-md-flex col-md-6"></div>
			<div class="col-6 col-md-3">
				<button type="button" class="btn btn-primary w-100" (click)="show_create_shipping=false">Cancelar</button>
			</div>
			<div class="col-6 col-md-3">
				<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Crear Envio</button>
			</div>
		</div>
	</form>
</app-modal>
<app-modal [(show)]="show_offline_options">
	<div class="card p-1 p-md-3">
		<h2 class="my-3">Opciones sin conexión</h2>
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="row align-items-middle mx-0">
			<div class="col-6 py-1 px-0">
				Modo:{{rest.is_offline?'Offline':'Online'}}
				<ng-container *ngIf="rest.is_offline">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 12c-2.03 0-3.9.67-5.4 1.8l-1.8-2.4C6.81 9.89 9.3 9 12 9s5.19.89 7.2 2.4l-1.28 1.7c-.37.07-.74.17-1.08.31C15.44 12.5 13.78 12 12 12m9-3l1.8-2.4C19.79 4.34 16.05 3 12 3S4.21 4.34 1.2 6.6L3 9c2.5-1.88 5.62-3 9-3s6.5 1.12 9 3m-9 6c-1.35 0-2.6.45-3.6 1.2L12 21l1.04-1.39c-.04-.2-.04-.4-.04-.61c0-1.34.44-2.57 1.19-3.57c-.69-.27-1.42-.43-2.19-.43m9.12.46L19 17.59l-2.12-2.12l-1.41 1.41L17.59 19l-2.12 2.12l1.41 1.42L19 20.41l2.12 2.13l1.42-1.42L20.41 19l2.13-2.12l-1.42-1.42z" fill="currentColor"/></svg>
				</ng-container>
				<ng-container *ngIf="!rest.is_offline">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 12c-2.03 0-3.9.67-5.4 1.8l-1.8-2.4C6.81 9.89 9.3 9 12 9s5.19.89 7.2 2.4l-1.28 1.7c-.37.07-.74.17-1.08.31C15.44 12.5 13.78 12 12 12m9-3l1.8-2.4C19.79 4.34 16.05 3 12 3S4.21 4.34 1.2 6.6L3 9c2.5-1.88 5.62-3 9-3s6.5 1.12 9 3m-9 6c-1.35 0-2.6.45-3.6 1.2L12 21l1.04-1.39c-.04-.2-.04-.4-.04-.61c0-1.34.44-2.57 1.19-3.57c-.69-.27-1.42-.43-2.19-.43m9.12.46L19 17.59l-2.12-2.12l-1.41 1.41L17.59 19l-2.12 2.12l1.41 1.42L19 20.41l2.12 2.13l1.42-1.42L20.41 19l2.13-2.12l-1.42-1.42z" fill="currentColor"/></svg>
				</ng-container>
			</div>
			<div class="col-6 py-1 px-0 text-end">
				<button type="button" class="btn btn-secondary" (click)="goOffline()" *ngIf="!rest.is_offline">
					Activar Offline
				</button>
				<button type="button" class="btn btn-secondary" (click)="goOnline()" *ngIf="rest.is_offline">
					Activar Online
				</button>
			</div>
		</div>
		<div class="row align-items-middle mx-0">
			<div class="col-8 px-0 py-1">
				Sincronizar Ordenes
			</div>
			<div class="col-4 px-0 py-1 text-end">
				<button type="button" class="btn btn-secondary" (click)="syncOrders()">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11 20H6.5q-2.28 0-3.89-1.57Q1 16.85 1 14.58q0-1.95 1.17-3.48q1.18-1.53 3.08-1.95q.63-2.3 2.5-3.72Q9.63 4 12 4q2.93 0 4.96 2.04Q19 8.07 19 11q1.73.2 2.86 1.5q1.14 1.28 1.14 3q0 1.88-1.31 3.19T18.5 20H13v-7.15l1.6 1.55L16 13l-4-4l-4 4l1.4 1.4l1.6-1.55Z"/></svg>
				</button>
			</div>
		</div>
		<div class="row align-items-middle mx-0">
			<div class="col-8 px-0 py-1">
				Sincronizar Datos
			</div>
			<div class="col-4 px-0 py-1 text-end">
				<button type="button" class="btn btn-secondary" (click)="syncData()">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.5 20q-2.28 0-3.89-1.57Q1 16.85 1 14.58q0-1.95 1.17-3.48q1.18-1.53 3.08-1.95q.58-2.02 2.14-3.4Q8.95 4.38 11 4.08v8.07L9.4 10.6L8 12l4 4l4-4l-1.4-1.4l-1.6 1.55V4.08q2.58.35 4.29 2.31T19 11q1.73.2 2.86 1.5q1.14 1.28 1.14 3q0 1.88-1.31 3.19T18.5 20Z"/></svg>
				</button>
			</div>
		</div>
		<div class="row align-items-middle mx-0">
			<div class="col-8 px-0 py-1">
				Limpiar Cache
			</div>
			<div class="col-4 px-0 py-1 text-end">
				<button type="button" class="btn btn-secondary" (click)="resetCache()">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><g fill="currentColor"><path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1Z"/><path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12.31 12.31 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7Zm6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.552 4.552 0 0 1 .23-2.002Zm-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.507 4.507 0 0 1-1.3-1.905Z"/><path d="M12.5 16a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7Zm-.646-4.854l.646.647l.646-.647a.5.5 0 0 1 .708.708l-.647.646l.647.646a.5.5 0 0 1-.708.708l-.646-.647l-.646.647a.5.5 0 0 1-.708-.708l.647-.646l-.647-.646a.5.5 0 0 1 .708-.708Z"/></g></svg>
				</button>
			</div>
		</div>
	</div>
</app-modal>
<app-modal [(show)]="show_cancel_order">
	<div class="card p-1 p-md-3" *ngIf="selected_order_info">
		<form (submit)="cancelOrderWithMessage($event)" ngNativeValidate>
			<h2 class="my-3">Cancelar Orden</h2>
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row">
				<div class="col-12">Motivo</div>
				<div class="col-12 py-3">
					<textarea name="cancellation_reason" [(ngModel)]="cancellation_reason" class="form-control w-100" rows="5" required></textarea>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<button type="button" class="btn btn-primary" (click)="show_cancel_order = false">Cerrar</button>
				</div>
				<div class="col-8">
					<button type="submit" class="btn btn-primary w-100" [disabled]="this.is_loading">Cancelar Orden</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
