import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { StocktakeItemInfo } from 'src/app/models/models';
import { Stocktake, Stocktake_Item, Store } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';


interface CResume
{
	item_count:number;
	stocktake_item_count:number;
	db_qty_sum:number;
	real_qty_sum:number;
	surplus_qty:number;
	surplus_item_count:number;
	missing_qty:number;
	missing_item_count:number;
	ok_item_sum:number;
	ok_item_count:number;
}

@Component
({
	selector: 'app-view-stocktake',
	templateUrl: './view-stocktake.component.html',
	styleUrls: ['./view-stocktake.component.css']
})
export class ViewStocktakeComponent extends BaseComponent implements OnInit
{
	stocktake:Stocktake = GetEmpty.stocktake();
	stocktake_item_info_list:StocktakeItemInfo[] = [];
	store:Store = GetEmpty.store();

	cresume: CResume = {
		item_count:0,
		stocktake_item_count:0,
		db_qty_sum:0,
		real_qty_sum:0,
		surplus_qty:0,
		surplus_item_count:0,
		missing_qty:0,
		missing_item_count:0,
		ok_item_sum:0,
		ok_item_count:0,
	};



	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response:ParamMap[])=>
			{
				let stocktake_id	= parseInt( response[1].get('stocktake_id') ) as number;
				let search			= this.getSearch<Stocktake_Item>(response[0],[],[]);
				search.eq.stocktake_id = stocktake_id;
				return forkJoin
				({
					stocktake: stocktake_id == this.stocktake.id ? of(this.stocktake ) : this.rest.stocktake.get( stocktake_id ),
					stocktake_item: this.rest.stocktake_item_info.search(search),
					stocktake_resume: this.rest.getReporte('getStocktakeResume',{stocktake_id:stocktake_id}),
					store: this.rest.getStores()
				});
			})
		)
		.subscribe((response)=>
		{
			console.log( response.store.data );
			this.stocktake	= response.stocktake;
			this.store		= response.store.data.find((s)=>s.id == this.stocktake.store_id) || GetEmpty.store();
			this.cresume	= response.stocktake_resume;
			this.stocktake_item_info_list = response.stocktake_item.data;
		})
	}
}
