import { Component , OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { filter, map } from 'rxjs/operators';
import {KeyboardShortcutEvent} from './classes/Utils';
import {ConfirmationService} from './services/confirmation.service';
import {ShortcutsService} from './services/shortcuts.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
	title = 'POS';

	constructor(public router:Router,public route: ActivatedRoute,public confirmation_service:ConfirmationService,public shortcuts:ShortcutsService)
	{

	}

	onActivate(evt:any)
	{
		this.scrollTop();
	}

	ngOnInit()
	{
		this.router.events.pipe(
			filter((evt:any) => evt instanceof NavigationEnd),
			map((event: NavigationEnd) => event.url)
		)
		.subscribe(url =>
		{
			if( /print/.test(url) )
			{
				document.body.classList.toggle('print',true);
			}
			else
			{
				document.body.classList.toggle('print',false);
			}
		});

		this.route.paramMap.subscribe(()=>
		{
			this.scrollTop();
		});

		this.route.queryParams.subscribe(()=>
		{
			this.scrollTop();
		});

		this.shortcuts.shortcuts.subscribe((kse)=>this.keyHandler(kse));
	}

	scrollTop()
	{
		setTimeout(()=>{
			let x = document.querySelector('.custom_scrollbar');
			if( x )
				x.scrollTo(0,0);
		},500);
	}

	keyHandler(kse:KeyboardShortcutEvent)
	{

		if( this.confirmation_service.show_confirmation )
		{
			if( kse.shortcut.key_combination == 'Enter' )
			{
				kse.stopPropagation();
				this.confirmation_service.onAccept();
				return;
			}

			if( kse.shortcut.key_combination == 'Escape' )
			{
				kse.stopPropagation();
				this.confirmation_service.onCancel();
			}
		}
	}
}
