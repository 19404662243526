<div class="container-fluid">
	<div class="row align-items-end my-3">
		<div class="col">
			<h1 class="text-capitalize">Sucursales 2</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-store']">Agregar</button>
		</div>
	</div>
	<app-pagination [path]="'/list-store'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="mb-0">
				<thead class="thead-light">
					<tr>
						<th class="px-1 pointer" (click)="this.sort('name',this.store_search)" >Nombre</th>
						<th class="px-1 d-none d-lg-table-cell text-end pointer" (click)="this.sort('updated',this.store_search)">Actualizado</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let store of store_list">
						<td>
							<a [routerLink]="['/edit-store',store.id]">{{store.name | titlecase }}</a>
						</td>
						<td class="px-1 d-none d-lg-table-cell text-end">{{store.updated | shortDate }}<td>
					</tr>
					<tr *ngIf="store_list == null || store_list.length == 0 && !is_loading" colspan="6">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-store'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

