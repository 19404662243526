import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

import {Category} from '../../models/RestModels';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ProductoOServicio} from 'src/app/models/models';
import { RestResponse } from 'src/app/services/Rest';
import { GetEmpty } from 'src/app/classes/Empties';


@Component({
	selector: 'app-save-category',
	templateUrl: './save-category.component.html',
	styleUrls: ['./save-category.component.css']
})
export class SaveCategoryComponent extends BaseComponent implements OnInit {

	category:Category = GetEmpty.category();
	type:string = '';
	productos_servicios_list: ProductoOServicio[];
	sub_category_list:Category[] = [];

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap
		.pipe
		(
			mergeMap((param_map)=>
			{
				this.type = 'PRODUCT';

				if( param_map.has('type') )
				{
					this.type = param_map.get('type') || 'PRODUCT';
				}

				let obs = param_map.has('id') ? this.rest.category.get( param_map.get('id') ) : of(GetEmpty.category());

				let tree_obs = param_map.has('id') ?
					this.rest.category_tree.search({eq:{parent_category_id:parseInt( param_map.get('id') )as number }})
						: of({data:[], total: 0});

				return forkJoin
				({
					productos_servicios: this.rest.producto_servicio_sat.search({limit:999999}),
					category: obs,
					sub_category_list: tree_obs.pipe
					(
						mergeMap((response)=>
						{
							console.log('Category tree', response );
							let ids:number [] = response.data.map(i=>i.child_category_id);
							console.log('Search',ids );
							if( ids.length == 0 )
							{
								return of({total:0, data:[]} as RestResponse<Category>)
							}
							return this.rest.category.search({csv:{ id:ids},limit:999999})
						})
					)
				})
			})
		)
		.subscribe( response =>
		{
			this.category = response.category;
			this.setTitle(this.category.id ? 'Editar Categoría '+this.category.name :  'Nueva Categoría');
			this.is_loading = false;
			this.type = this.category.type || 'PRODUCT';
			this.sub_category_list = response.sub_category_list.data;
			this.productos_servicios_list = response.productos_servicios.data;
		},(error)=>this.showError(error));
	}

	save()
	{
		this.is_loading = true;

		if( this.category.id	)
		{
			this.subs.sink	= this.rest.category.update( this.category ).pipe
			(
				mergeMap((response)=>
				{
					let foo = this.sub_category_list.map((i)=>{
						return {
							parent_category_id: response.id,
							child_category_id: i.id
						}
					});
					return this.rest.category_tree.batchUpdate( foo )
				})
			).subscribe((_response)=>{
				this.is_loading = false;
				this.router.navigate(['/list-category',this.type]);
				this.showSuccess('La categoría se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.category.type = this.type;
			this.subs.sink	= this.rest.category.create( this.category )
			.pipe
			(
				mergeMap((response)=>
				{
					this.category = response;
					let foo = this.sub_category_list.map((i)=>{
						return {
							parent_category_id: response.id,
							child_category_id: i.id
						}
					});
					return this.rest.category_tree.batchUpdate( foo )
				})
			).subscribe((_response)=>{
				this.is_loading = false;
				this.router.navigate(['/list-category',this.type]);
				this.showSuccess('La categoría se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
	}

	onCategory(category:Category)
	{
		if( category.id == this.category.id )
		{
			this.showError('La categoría no puede ser subcategoria de si misma');
			return;
		}

		console.log('Category agregad', category );
		let index = this.sub_category_list.findIndex(i=>i.id == category.id );

		if( index == -1 )
		{
			this.sub_category_list.push( category );
		}
		else
		{
			this.showError('La categoría ya se encuentra en la lista de subcategorias');
		}
	}

	removeSubcategory(category:Category)
	{
		console.log( category );
		console.log( this.sub_category_list );
		let index = this.sub_category_list.findIndex((i)=>i.id == category.id);

		console.log('Index is',index);

		if( index !== -1 )
		{
			this.subs.sink = this.rest.category_tree.search({
				eq:{
					parent_category_id: this.category.id,
					child_category_id: category.id
				},
				limit: 1
			})
			.pipe
			(
				mergeMap((response)=>
				{
					console.log('Que encontro',response);

					if( response.total )
					{
						console.log('Removiendo', category);
						return this.rest.category_tree.delete( response.data[0] );
					}
					console.log('No se encontrol');
					return of( true );
				})
			)
			.subscribe((_response)=>
			{
				this.sub_category_list.splice(index,1);
			},(error)=>this.showError(error));
		}
	}
	
	updateBackground(evt:Event)
	{
		let f = evt.target as HTMLInputElement;

		if( f.checked )
		{
			this.category.background = 'transparent';
		}
		else
		{
			this.category.background = '#000000';
		}
	}
}
