import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component
({
	selector: 'app-sales-report',
	templateUrl: './sales-report.component.html',
	styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent extends BaseComponent implements OnInit
{
	ngOnInit(): void
	{
	}
}
