import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {Address, Price_Type, User} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';
import {UserInfo} from 'src/app/models/models';
import {mergeMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {GoogleMap} from '@angular/google-maps';
import { GetEmpty } from 'src/app/classes/Empties';

@Component({
	selector: 'app-add-new-client',
	templateUrl: './add-new-client.component.html',
	styleUrls: ['./add-new-client.component.css']
})
export class AddNewClientComponent extends BaseComponent implements OnInit, OnChanges {


	@Output() newClient = new EventEmitter<UserInfo | null>();
	@Input() suggested_name:string = '';

	address:string = '';
	center_position:google.maps.LatLngLiteral = {lat:0,lng:0} ;
	@ViewChild(GoogleMap, { static: false }) map: GoogleMap | null = null;

	price_types:Price_Type[] = [];
	user:User = GetEmpty.client();

	billing_address:Address = {
		address: '',
		lat: 0,
		lng: 0,
		type: 'BILLING',
		sat_regimen_fiscal: '',
		sat_regimen_capital: '',
		email: '',
		rfc: '',
		name: '',
	};

	ngOnInit(): void
	{
		// this.subs.sink = this.route.paramMap.pipe
		// (
		// 	mergeMap((params) =>
		// 	{
		// 		this.address = params.get('address') as string;
		// 		return this.rest.loadGoogleMapsApi();
		// 	})
		// ).subscribe(()=>
		// {
		// 	if( navigator?.geolocation && this.rest.is_maps_loaded && this?.map?.googleMap )
		// 	{
		// 		navigator.geolocation.getCurrentPosition
		// 		(
		// 			(geolocation:GeolocationPosition)=>{
		// 				this.center_position.lat = geolocation.coords.latitude;
		// 				this.center_position.lng = geolocation.coords.longitude;
		// 				this.map.googleMap.setCenter(this.center_position);
		// 			},
		// 			(error)=>
		// 			{
		// 				console.log(error);
		// 			},
		// 			{
		// 				maximumAge: Infinity
		// 			}
		// 		);
		// 	}
		// });

		this.rest.getPriceTypes(true).subscribe((response)=>{
			this.price_types = response.data;
		},(error)=>this.showError(error));
	}

	save(_event:Event):void
	{
		this.subs.sink = this.rest.user.create( this.user ).pipe
		(
			mergeMap((user)=>
			{
				this.billing_address.user_id = user.id;
				this.billing_address.phone = this.user.phone == '' ? '' : this.user.phone;

				return forkJoin
				({
					user: of(user),
					address: this.createAddress(this.billing_address)
				});
			})
		)
		.subscribe((response)=>
		{
			console.log("emmitint client", response );
			this.newClient.emit
			({
				user:response.user,
				billing_address: this.billing_address?.rfc ? this.billing_address: null,
				shipping_address: this.billing_address ? this.billing_address: null,
				addresses: this.billing_address ? [this.billing_address] : []
			});
		},(error)=>this.showError(error));
	}


	createAddress(billing_address:Address):Observable<Address | null>
	{
		let formated_address:string = this.billing_address+'';

		//if( this.billing_address.suburb )
		//	formated_address+='\n'+this.billing_address.suburb;

		//if( this.billing_address.city )
		//	formated_address+='\n '+this.billing_address.city;

		//if( this.billing_address.state )
		//	formated_address+= ', '+this.billing_address.state;

		//if( this.billing_address.country)
		//	formated_address+= '\n'+this.billing_address.country;

		//if( this.billing_address.zipcode )
		//	formated_address+= '.\n'+this.billing_address.zipcode;
		if( !this.billing_address.address && !this.billing_address.rfc )
			return of( null );
		
		//si tiene direccion pero no tiene rfc, cambiar el tipo de direccion a SHIPPING
		if( this.billing_address.address && !this.billing_address.rfc )
		{
			billing_address.type = 'SHIPPING';
			billing_address.name = this.user.name;
			billing_address.email = this.user.email;
		}

		return this.rest.address.create(billing_address);
		//return this.rest.getLocationFromAddress( formated_address ).pipe
		//(
		//	mergeMap((response)=>
		//	{
		//		if( response.status == 'OK' )
		//		{
		//			if( response.results.length )
		//			{
		//				if( response.results[0].geometry.location.lat )
		//				{
		//					this.billing_address.lat = response.results[0].geometry.location.lat;
		//					this.billing_address.lng = response.results[0].geometry.location.lng;
		//				}
		//			}
		//		}
		//		return this.rest.address.create(billing_address);
		//	})
		//);
	}


	cancel():void
	{
		this.newClient.emit(null);
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		if( changes.suggested_name )
		{
			if( /\d\d\d/.test( this.suggested_name ) )
			{
				this.user.phone = this.suggested_name;
			}
		}
	}

	centerChanged(something)
	{
		let x = this.map.googleMap.getCenter();
		this.center_position = { lat: x.lat(), lng: x.lng() };

		this.billing_address.lat = this.center_position.lat;
		this.billing_address.lng = this.center_position.lng;
	}

	// putAddressOnMap()
	// {
	// 	this.subs.sink = this.rest.getLocationFromAddress( this.billing_address.address ).subscribe((response)=>
	// 	{
	// 		if( response.status == 'OK' )
	// 		{
	// 			if( response.results.length )
	// 			{
	// 				if( response.results[0].geometry.location.lat )
	// 				{
	// 					this.center_position.lat = response.results[0].geometry.location.lat;
	// 					this.center_position.lng = response.results[0].geometry.location.lng;
	// 					this.map.googleMap.setCenter(this.center_position);
	// 					this.billing_address.lat = response.results[0].geometry.location.lat;
	// 					this.billing_address.lng = response.results[0].geometry.location.lat;
	// 				}
	// 			}
	// 		}

	// 	},(error)=>this.showError(error));
	// }
}
