import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ticketString'
})
export class TicketStringPipe implements PipeTransform
{
	transform(value: unknown, length:number=1, type:'End'|'Start' = 'End'):string
	{
		if( value === null )
		{
			return ''.padEnd(length);
		}

		let v = (''+value).trim();

		if( type === 'End' )
		{
			return v.slice(0,length).padEnd( length, ' ');
		}

		return v.slice(0,length).padStart( length,' ');
	}
}
