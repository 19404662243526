<div class="container-fluid">

	<div class="row align-items-end mx-0 my-3">
		<div class="col">
			<h4 class="text-capitalize">Tipos de consumo</h4>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-price-type']">Agregar</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="price_type_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="price_type_search.lk.name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="price_type_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="price_type_search.eq.updated" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th style="width:25px;">Id</th>
						<th>Nombre</th>
						<th class="d-none d-md-table-cell text-end">Actualizado</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let price_type of price_type_list" class="align-middle">
						<td style="width:25px;">
							<a [routerLink]="['/edit-price-type',price_type.id]">{{price_type.id}}</a>
						</td>
						<td>
							<a [routerLink]="['/edit-price-type',price_type.id]">{{price_type.name}}</a>
						</td>
						<td class="d-none d-md-table-cell text-end">{{price_type.updated | date: 'MMM d, y,  h:mm a' }}</td>
					</tr>
					<tr *ngIf="price_type_list == null || price_type_list.length == 0 && !is_loading">
						<td colspan="3">
							<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-price-type'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
