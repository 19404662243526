import { Component, OnInit } from '@angular/core';

import { RestService} from 'src/app/services/rest.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Form, User} from 'src/app/models/RestModels';
import { BuildInfo } from 'src/app/models/BuildInfo';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {
	constructor( public rest: RestService, public router: Router, public route: ActivatedRoute ) {}

	showCentros:boolean = false;
	show_logout: boolean = false;
	user:Partial<User> = {};
	queryParams ={t:Date.now()}

	build_info = BuildInfo;

	forms_list:Form[] = [];

	/*
	active_section_dashboard:boolean=false;
	active_section_project:boolean=false;
	active_section_financials:boolean=false;
	active_section_users:boolean=false;
	active_section_settings:boolean=false;
	active_section_catalogs:boolean=false;
	active_section_purchasing:boolean=false;
	*/

	active_section:Record<string,boolean>= {
		'dashboard': false,
		'users': false,
		'catalogs':false,
		'finantials':false,
		project: false,
		purchasing: false,
		settings: false
	};

	t:number = Date.now();
	build_time:number = Date.now();
	build_date:Date	= new Date();

	ngOnInit()
	{
		this.route.url.subscribe(()=>
		{
			this.updateTime();
			this.user = this.rest.current_user;
			this.t = Date.now();
		});

		this.route.queryParams.subscribe(()=>
		{
			this.updateTime();
		});

		this.rest.form.search({limit:9999}).subscribe({
			next:(response)=>
			{
				this.forms_list = response.data;
			},
			error:(error)=>
			{
				this.rest.showError(error);
			}
		});

		this.route.queryParams.subscribe(this.updateTime);
		this.route.paramMap.subscribe( this.updateTime );
	}

	setActiveSection(section:string):void
	{
		for(let i in this.active_section )
		{
			this.active_section[i] = i == section;
		}
		console.log( this.active_section );
	}

	updateTime()
	{
		this.queryParams = { t: Date.now() };
		this.t = Date.now();
		if( this.rest )
		{
	//		this.rest.hideMenu();
		}
		//console.log('NEW TIME SBB',this.queryParams );
	}

	showLogoutModal() {
		this.show_logout=true;
	}

	logout()
	{
		this.rest.logout();
		this.router.navigate(['/login']);
	}
	onSwipe(evt)
	{
		let x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left'):'';
		if( x == 'left' )
		{
			this.rest.hideMenu();
		}
	}

	updateData(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		this.rest.syncOffLineItems()
		.then((resp)=>
		{
			this.rest.showSuccess('Se actualizaron los datos');
		},(error)=>
		{
			this.rest.showError('No se pudieron actualizar los articulos');
		})
	}

	syncData(evt:Event)
	{
		evt.preventDefault();
		evt.stopPropagation();

		this.rest.forceSyncOfflineItems()
		.then(()=>
		{
			this.rest.showSuccess('Datos de soporte actualizados');
		},(error)=>this.rest.showError( error ));
	}

}
