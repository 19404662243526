<div class="container-fluid">
	<h1 class="my-3 text-capitalize">{{billing_data.id ? 'Actualizar':'Agregar'}} Usuario de Facturación</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="card p-3">
			<div class="row p-3">
				<div class="col-12 col-md-6">
					<label class="">RFC</label>
					<input type="text" name="rfc" [(ngModel)]="billing_data.rfc" autocomplete=off placeholder="" class="form-control" min="15" max="16" required>
				</div>

				<div class="col-12 col-md-6">
					<div class="form-group">
						<label class="">Régimen Fiscal</label>
						<select name="regimen_fiscal" [(ngModel)]="billing_data.regimen_fiscal" class="form-control" required>
							<option value="">Seleccionar</option>
							<option value="601">601 - General de Ley Personas Morales</option>
							<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
							<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
							<option value="606">606 - Arrendamiento</option>
							<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
							<option value="608">608 - Demás ingresos</option>
							<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
							<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
							<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
							<option value="614">614 - Ingresos por intereses</option>
							<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
							<option value="616">616 - Sin obligaciones fiscales</option>
							<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
							<option value="621">621 - Incorporación Fiscal</option>
							<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
							<option value="623">623 - Opcional para Grupos de Sociedades</option>
							<option value="624">624 - Coordinados</option>
							<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
							<option value="626">626 - Régimen Simplificado de Confianza</option>
						</select>
					</div>
				</div>

				<div class="col-12 col-md-6">
					<label class="">Razón Social</label>
					<input type="text" name="razon_social" [(ngModel)]="billing_data.razon_social" autocomplete=off placeholder="GRUPO JFTI SOLUCIONES" class="form-control" required>
				</div>

				<div class="col-12 col-md-6">
					<label class="">Régimen capital</label>
					<input type="text" name="regimen_capital" [(ngModel)]="billing_data.regimen_capital" autocomplete=off placeholder="SA de CV" class="form-control">
				</div>
				<div class="col-12">
					<label class="">Dirección</label>
					<input type="text" name="address" [(ngModel)]="billing_data.address" autocomplete=off placeholder="" class="form-control" required>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Ciudad</label>
					<input type="text" name="city" [(ngModel)]="billing_data.city" autocomplete=off placeholder="Ciudad" class="form-control" required>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Estado</label>
					<input type="text" name="state" [(ngModel)]="billing_data.state" autocomplete=off placeholder="" class="form-control" required>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Código Postal</label>
					<input type="text" name="zipcode" [(ngModel)]="billing_data.zipcode" autocomplete=off placeholder="" class="form-control" required>
				</div>

				<div class="col-12 col-md-4">
					<div class="form-group">
						<label class="">Presición Decimales</label>
						<select name="precision" [(ngModel)]="billing_data.precision" class="form-control">
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select>
					</div>
				</div>

				<div class="col-12 col-md-4">
					<div class="form-group">
						<label class="">Porcentaje ISR</label>
						<input type="text" name="porcentaje_ISR" [(ngModel)]="billing_data.porcentaje_ISR" autocomplete=off placeholder="Ej. 1.25 para 1.25%" class="form-control">
					</div>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Usuario Sicofi</label>
					<input type="text" name="usuario" [(ngModel)]="billing_data.usuario" autocomplete=off placeholder="demo1@sicofi.com.mx" class="form-control">
				</div>
				<div class="col-12 col-md-4">
					<div class="form-group">
						<label class="">Contraseña Sicofi</label>
						<input type="password" name="password" [(ngModel)]="billing_data.password" autocomplete=off placeholder="demodemoD" class="form-control">
					</div>
				</div>
				<div class="col-12 text-end p-3 mt-3">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary w-50">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
