import { Injectable } from "@angular/core";
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { RestService } from "../services/rest.service";

@Injectable({ providedIn: "root" })
export class ClientGuard implements CanActivate {
	constructor(private router: Router, private rest: RestService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)

	{
		const platform_client = this.rest.getClientPlatformFromSession();

		if ( platform_client ) {
			// authorised so return true
			return true;
		}

		if( this.rest.current_user )
		{
			this.router.navigate(["/dashboard"], {});
		}
		else
		{
		// not logged in so redirect to login page with the return url
			this.router.navigate(["/login"], {
				queryParams: { return_url: state.url }
			});
		}
		return false;
	}
}
