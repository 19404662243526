<div class="container-fluid">
	<h1 class="my-3">Partir Orden</h1>
	<div class="card p-3">
		<div class="row">
			<div class="col-3">
				Nombre
			</div>
			<div class="col-3">Cantidad</div>
			<div class="col-3">Cantidad Nueva Orden</div>
			<div class="col-2 form-check form-switch">Partir</div>
		</div>

		<div *ngIf="order_info">
			<div class="row py-3" [ngClass]="{'border-top':!oii.order_item.item_option_id}" *ngFor="let oii of order_info.items">
				<div class="col-3" [ngClass]="{'text-end': !!oii.order_item.item_option_id}">
					<span *ngIf="!oii.order_item?.item_option_id && rest.local_preferences.display_categories_on_items == 'YES' && oii.category">{{oii.category.name}}</span>
					{{oii.item.name}}
				</div>
				<div class="col-3">{{oii.order_item.qty}}</div>
				<div class="col-3">
					<input type="number" *ngIf="!(oii.order_item.item_option_id)" [name]="'qty_item'+oii.order_item.id" [max]="oii.order_item.qty" [(ngModel)]="order_item_qty_dictionary[oii.order_item.id]" class="form-control">
					<span *ngIf="oii.order_item.item_option_id">
						{{order_item_qty_dictionary[oii.order_item.id]}}
					</span>
				</div>
				<div class="col-2 form-check form-switch">
					<label class="form-check-label" *ngIf="!(oii.order_item.item_option_id)">
						<input type="checkbox" name="bg_color_transparente" class="form-check-input">
					</label>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-6"></div>
			<div class="col-3 text-end">
				<button class="btn btn-primary" (click)="splitOrder()">Separar Cuenta</button>
			</div>
			<div class="col-3">
			</div>
		</div>
	</div>
</div>
<!--app-modal [(show)]="show_receive_payment">
	<div style="min-width:1000px">
		<app-make-payment [order_info]="order_info" [partial_amount]="partial_amount" [order_item_qty_dictionary]="order_item_qty_dictionary"></app-make-payment>
	</div>
</app-modal-->

