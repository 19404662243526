<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8 col-md-10">
			<h1 class="my-3 text-capitalize">Requisición #{{requisition_info?.requisition?.id}}</h1>
		</div>
		<div class="col-4 col-md-2 text-end d-print-none">
			<button type="button" class="btn btn-secondary" (click)="print()">Imprimir</button>
		</div>
		<div class="d-none col-4 text-end d-print-block">
			Fecha de impresión: <b>{{ print_date | shortDate: 'full' }}</b>
		</div>
	</div>
	<div class="row align-items-start" *ngIf="requisition_info">
		<div class="col-12">
			<h2>Datos de la Requisición</h2>
			<div class="card my-3 p-1 text-center">
				<div class="row p-3">
					<div class="col-6 col-md-3" *ngIf="requisition_info.requisition.required_by_store_id">
						<label class="">Requerido en:</label>
						<div>
							<b>{{ requisition_info.required_by_store.name }}</b>
						</div>
					</div>
					<div class="col-6 col-md-3">
						<label class="">Solicitado a:</label>
						<div>
							<b>{{ requisition_info.requested_to_store.name}}</b>
						</div>
					</div>
					<div class="col-6 col-md-3">
						<label class="">Fecha de requisición:</label>
						<div>
							<b>{{ requisition_info.requisition.date | date: 'short'}}</b>
						</div>
					</div>
					<div *ngIf="requisition_info.user" class="col-6 col-md-3">
						<label class="">Requerido por:</label>
						<div>
							<b>{{ requisition_info.user.name }}</b>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12">
			<h2>Artículos</h2>
			<div class="card p-3">
				<table class="w-100" *ngIf="requisition_info">
					<thead>
						<tr>
							<th class="">Artículos</th>
							<th class="text-end">Requeridos</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let rii of requisition_info.items;let i=index">
							<td class="fw-bold">
								<b>{{ i+1 }} - </b>
								<img [src]="rest.getImagePath(rii.item?.image_id,rii?.category?.image_id)" style="max-height:30px;max-width:30px;margin-right:10px">
								<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES'" class="text-secondary">{{rii?.category?.name}} - </span>
								<b>{{rii.item.name |titlecase }}</b>
								<a [routerLink]="['/list-item']" [queryParams]="{'csv.id':''+rii.item.id}" *ngIf="rii.item.code"> {{rii.item.code|titlecase | uppercase }}</a>
							</td>
							<td class="text-end">
								{{rii.requisition_item.qty | number }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="d-none col-12 text-center mt-5 d-print-block">
			<p class="p-0 m-0">_______________________________</p>
			<p class="p-0 m-0">Fírma</p>
		</div>
	</div>
</div>
