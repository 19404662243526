<div class="container-fluid">
	<!-- Título -->
	<div class="row align-items-center">
		<div class="col-12 col-md-4">
			<h2 class="my-3">Datos Generales</h2>
		</div>
		<!-- Botones -->
		<div class="col-12 col-md-8 d-flex flex-wrap justify-content-md-end align-items-center">
			<button type="button" class="btn btn-primary m-1" [routerLink]="['/edit-client', user.id]">Editar</button>
			<button type="button" class="btn btn-primary m-1" [routerLink]="['/list-user-orders']" *ngIf="user.balance < 0" [queryParams]="{'eq.client_user_id':user.id}">Pagar</button>
			<button type="button" class="btn btn-primary m-1" [routerLink]="['/list-order']" [queryParams]="{'eq.client_user_id':user.id}">Órdenes</button>
			<button type="button" class="btn btn-primary m-1" [routerLink]="['/list-address']" [queryParams]="{'eq.user_id':user.id}">Direcciones</button>
			<button type="button" class="btn btn-primary m-1" [routerLink]="'/factura-multiple'" [queryParams]="{'eq.client_user_id':user.id,'eq.facturado':'NO'}">Facturar</button>
			<button type="button" class="btn btn-primary m-1" *ngIf="rest.current_permission.add_user" (click)="confirmRemoveClient(user)">Eliminar</button>
		</div>
	</div>
	<!--- Fin Título -->
	<div class="card p-3">
		<div class="row">
			<div class="col-12 col-md-2">
				<div class="text-center">
					<app-image-uploader [(image)]="user.image_id"></app-image-uploader>
				</div>
			</div>
			<div class="col-12 col-md-10 d-flex align-items-center">
				<div class="row ">
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Nombre</label>
						<p class="">{{user.name}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Teléfono</label>
						<p class="">{{user.phone}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Correo electrónico</label>
						<p class="">{{user.email}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Consumo</label>
						<p class="">{{price_type.name}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Fecha Nacimiento</label>
						<p class="">{{user.birthday | shortDate: 'full'}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Limite de crédito</label>
						<p class="">{{user.credit_limit | currency: 'USD'}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Días de crédito</label>
						<p class="">{{user.credit_days}}</p>
					</div>
					<div class="col-12 col-sm-4 col-lg-3">
						<label class="fw-bold">Agente</label>
						<p class="">{{agent.name}}</p>
					</div>
					<div class="col-12 col-sm-6 col-md-3">
						<label class="fw-bold">Preferencia de cobro en</label>
						<p class="">{{payment_option_dict[user.payment_option]}}</p>
					</div>
					<div class="col-12 col-sm-6 col-md-3" *ngIf="user.payment_option == 'ADDRESS'">
						<label class="fw-bold">Dirección de cobro</label>
						<p class="">{{getAddressName(user.payment_address_id)}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-loading [is_loading]="is_loading"></app-loading>
	<h2 class="my-3" *ngIf="address_list.length > 0">Direcciones</h2>
	<div class="card mt-3" *ngIf="address_list.length > 0">
		<ng-container *ngFor="let address of address_list">
			<div class="row myborde p-3">
				<div class="col-12 col-md-3 align-items-center">
					<label class="fw-bold">Tipo</label>
					<p class="m-0">
						<span class="mx-1" *ngIf="address.type == 'BILLING'">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" fill="currentColor"/></svg>
						</span>
						<span class="mx-1" *ngIf="address.type == 'SHIPPING'">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><defs/><path d="M16 2A11.013 11.013 0 0 0 5 13a10.889 10.889 0 0 0 2.216 6.6s.3.395.349.452L16 30l8.439-9.953c.044-.053.345-.447.345-.447l.001-.003A10.885 10.885 0 0 0 27 13A11.013 11.013 0 0 0 16 2zm0 15a4 4 0 1 1 4-4a4.005 4.005 0 0 1-4 4z" fill="currentColor"/><circle id="_Inner-Path_" cx="16" cy="13" r="4" fill="none"/></svg>
						</span>
						{{address_type_dict[address.type]}}
					</p>
				</div>
				<div class="col-12 col-md-3">
					<label class="fw-bold">Nombre</label>
					<p class="m-0">{{address.name}}</p>
				</div>
				<div class="col-12 col-md-3">
					<label class="fw-bold">Dirección</label>
					<p class="m-0">{{address.address}}</p>
				</div>
				<div class="col-12 col-md-3">
					<div class="row">
						<div class="col-6 justify-content-end">
							<label class="m-0">&nbsp;</label>
							<a [routerLink]="['/edit-address', address.id]" class="btn btn-primary w-100">Editar</a>
						</div>
						<div class="col-6 text-end">
							<label class="m-0">&nbsp;</label>
							<button class="btn btn-primary w-100" (click)="showAddress(address)">Ver</button>
						</div>
						<div class="col- text-end" *ngIf="address.type == 'BILLING' || address.type == 'BILLING_AND_SHIPPING'">
							<label class="m-0">&nbsp;</label>
							<a class="btn btn-primary w-100" [routerLink]="['/saldo-razon-social']" [queryParams]="{'eq.sat_razon_social':address.name}">
								Saldo Razon Social
							</a>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div class="row">
		<div class="col">
			<h2 class="my-3">Crédito</h2>
			<div class="card p-3 mt-2">
				<div class="row">
					<h3 class="my-3">Estado de cuenta</h3>
					<div class="col-12 col-md-3">
						<label class="fw-bold">Adeudo</label>
						<p>{{user.balance * (-1)| currency:'$'}}</p>
					</div>
					<div class="col-12 col-md-3">
						<label class="fw-bold">Pagos vencidos</label>
						<p [ngClass]="{'text-danger':user.installment_expired_qty > 0}">
							{{user.installment_expired_qty}}
						</p>
					</div>
					<div class="col-12 col-md-3">
						<label class="fw-bold">Saldo vencido</label>
						<p [ngClass]="{'text-danger':user.installment_expired_amount > 0}">
							{{user.installment_expired_amount | currency:'$'}}
						</p>
					</div>
					<div class="col-12 col-md-3">
						<label class="fw-bold">Fecha vencido</label>
						<p *ngIf="user.installment_expired_since" [ngClass]="{'text-danger':user.installment_expired_since != null}">
							{{user.installment_expired_since | shortDate: 'full'}}
						</p>
					</div>
				</div>
				<ng-container *ngIf="cinstallment_list.length > 0">
					<h3 class="my-3">Pagos vencidos</h3>
					<div class="table-responsive">
						<table class="table">
							<thead class="thead-light">
								<tr>
									<th># Orden</th>
									<th class="text-end">Vencimiento</th>
									<th class="d-none d-md-table-cell text-end">Estatus</th>
									<th class="text-end">Monto</th>
									<th class="text-end">Abonado</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let installment of cinstallment_list">
									<tr [ngClass]="{'bg-danger':installment.due_status == 'VENCIDO','text-white':installment.due_status == 'VENCIDO'}">
										<td>{{installment.order_id}}</td>
										<td class="text-end">{{installment.due_date | shortDate:'full'}}</td>
										<td class="d-none d-md-table-cell text-end fw-bold">
											{{installment.due_status | titlecase}}
										</td>
										<td class="text-end">{{installment.amount | currency}}</td>
										<td class="text-end">{{installment.paid_amount | currency}}</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="col-12 col-lg-6" *ngIf="cformresponse_list.length > 0">
			<h2 class="my-3">Formularios</h2>
			<div class="card p-3 mt-2">
				<ng-container *ngFor="let form of cformresponse_list">
					<div class="row my-1 fw-bold border-bottom">
						<div class="col-6">{{form.form.title}}</div>
						<div class="col-6 text-end">Fecha</div>
					</div>
					<div class="row my-1 ms-0 me-0" *ngFor="let response of form.response">
						<div class="col-6 p-0 fw-bold">
							<a [routerLink]="['/edit-response',response.id]" >
								{{response.title ? response.title : form.form.title}}
							</a>
						</div>
						<div class="col-6 p-0 text-end" *ngIf="response">{{ response.created | shortDate: 'full'}}</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<h2 class="my-3">Historial</h2>
	<div class="card p-3 mt-3">
		<div class="row">
			<div class="col-12 col-md-6">
				<h3 class="my-3">Ultimas ventas</h3>
				<ng-container *ngIf="last_corder_info_list.length > 0">
					<div class="table-responsive">
						<table class="table mb-0 w-100">
							<thead class="thead-light">
								<tr>
									<th>Id</th>
									<th>Sucursal</th>
									<th>Fecha</th>
									<th>Total</th>
									<th class="text-end">Acciones</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let oi of last_corder_info_list" class="border align-middle">
									<td>
										<a *ngIf="oi.order.id && oi.order.status != 'CANCELLED' && oi.order.paid_status == 'PENDING'" [routerLink]="['/pos',oi.order.id]">{{oi.order.id}}</a>
										<a *ngIf="oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING' )" [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
										<a *ngIf="!oi.order.id && ( oi.order.status == 'CANCELLED' || oi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',oi.order.sync_id,'offline']">{{oi.order.sync_id}}</a>
									</td>
									<td>{{oi.store.name | titlecase }}</td>
									<td>
										<abbr title="{{oi.order.closed_timestamp || oi.order.created | date: 'MMM d, y HH:mm' }}">{{(oi.order.closed_timestamp || oi.order.created) | shortDate}}</abbr>
									</td>
									<td>{{oi.order.total-oi.order.discount | currency:'$' }}</td>
									<td class="text-end nowrap">
										<ng-container class="" *ngIf="oi.order.status != 'CANCELLED' && !rest.is_offline">
											<a class="mx-1 btn btn-primary" *ngIf="oi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(oi.order.sat_pdf_attachment_id,true)">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
											</a>
										</ng-container>
										<a class="mx-1 btn btn-primary" [routerLink]="['/view-order',oi.order.id]" *ngIf="oi.order.status != 'CANCELLED' && !rest.is_offline">
											Ver
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>
				<span *ngIf="last_corder_info_list.length == 0">No hay ventas</span>
			</div>
			<div class="col-12 col-md-6">
				<h3 class="my-3">Ventas pendientes de pago</h3>
				<ng-container *ngIf="pending_corder_info_list.length > 0">
					<div class="table-responsive">
						<table class="table mb-0 w-100">
							<thead class="thead-light">
								<tr class="d-none d-xxl-table-row">
									<th colspan="7" class=""></th>
									<th colspan="3" class="border text-center">Descuentos</th>
									<th></th>
								</tr>
								<tr>
									<th>Id</th>
									<th>Sucursal</th>
									<th>Fecha</th>
									<th class="text-end">Total</th>
									<th class="text-end">Pendiente</th>
									<th class="text-end">Acciones</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let poi of pending_corder_info_list" class="border align-middle">
									<td>
										<a *ngIf="poi.order.id && poi.order.status != 'CANCELLED' && poi.order.paid_status == 'PENDING'" [routerLink]="['/pos',poi.order.id]">{{poi.order.id}}</a>
										<a *ngIf="poi.order.id && ( poi.order.status == 'CANCELLED' || poi.order.paid_status != 'PENDING' )" [routerLink]="['/view-order',poi.order.id]">{{poi.order.id}}</a>
										<a *ngIf="!poi.order.id && ( poi.order.status == 'CANCELLED' || poi.order.paid_status != 'PENDING')" [routerLink]="['/view-order',poi.order.sync_id,'offline']">{{poi.order.sync_id}}</a>
									</td>
									<td>{{poi.store.name | titlecase }}</td>
									<td>
										<abbr title="{{poi.order.closed_timestamp || poi.order.created | date: 'MMM d, y HH:mm' }}">{{(poi.order.closed_timestamp || poi.order.created) | shortDate}}</abbr>
									</td>
									<td class="text-end">{{poi.order.total-poi.order.discount | currency:'$' }}</td>
									<td class="text-end">{{ ( poi.order.total + poi.order.discount ) - poi.order.amount_paid | currency: '$'}}</td>
									<td class="text-end nowrap">
										<ng-container class="" *ngIf="poi.order.status != 'CANCELLED' && !rest.is_offline">
											<a class="mx-1 btn btn-primary" *ngIf="poi.order?.sat_pdf_attachment_id" [href]="rest.getFilePath(poi.order.sat_pdf_attachment_id,true)">
												<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="0.75em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 384 512"><path d="M181.9 256.1c-5-16-4.9-46.9-2-46.9c8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7c18.3-7 39-17.2 62.9-21.9c-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2c-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8c4.5-18.5 11.6-46.6 6.2-64.2c-4.7-29.4-42.4-26.5-47.8-6.8c-5 18.3-.4 44.1 8.1 77c-11.6 27.6-28.7 64.6-40.8 85.8c-.1 0-.1.1-.2.1c-27.1 13.9-73.6 44.5-54.5 68c5.6 6.9 16 10 21.5 10c17.9 0 35.7-18 61.1-61.8c25.8-8.5 54.1-19.1 79-23.2c21.7 11.8 47.1 19.5 64 19.5c29.2 0 31.2-32 19.7-43.4c-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9c37.1 15.8 42.8 9 42.8 9z" fill="currentColor"/></svg>
											</a>
										</ng-container>
										<a class="mx-1 btn btn-primary" [routerLink]="['/view-order',poi.order.id]" *ngIf="poi.order.status != 'CANCELLED' && !rest.is_offline">
											Ver
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>
				<span *ngIf="pending_corder_info_list.length == 0">No hay ventas</span>
			</div>
		</div>
	</div>
</div>

<app-modal [(show)]="show_address">
	<div class="row mx-0 p-3" *ngIf="selected_address">
		<div class="col-12 align-items-center" *ngIf="selected_address">
			<span class="h4 fw-bold">{{address_type_dict[selected_address.type]}}</span>
			<span class="mx-2" *ngIf="selected_address.type == 'BILLING'">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112c0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" fill="currentColor"/></svg>
			</span>
			<span class="mx-2" *ngIf="selected_address.type == 'SHIPPING'">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><defs/><path d="M16 2A11.013 11.013 0 0 0 5 13a10.889 10.889 0 0 0 2.216 6.6s.3.395.349.452L16 30l8.439-9.953c.044-.053.345-.447.345-.447l.001-.003A10.885 10.885 0 0 0 27 13A11.013 11.013 0 0 0 16 2zm0 15a4 4 0 1 1 4-4a4.005 4.005 0 0 1-4 4z" fill="currentColor"/><circle id="_Inner-Path_" cx="16" cy="13" r="4" fill="none"/></svg>
			</span>
		</div>
		<div class="col-12 col-md-3" *ngIf="selected_address.type == 'BILLING'">
			<label class="fw-bold">RFC</label>
			<p>{{selected_address.rfc}}</p>
		</div>
		<div class="col-12 col-md-3" *ngIf="selected_address.type == 'BILLING'">
			<label class="fw-bold">Razón social</label>
			<p>{{selected_address.name}}</p>
		</div>
		<div class="col-12 col-md-3" *ngIf="selected_address.type == 'BILLING'">
			<label class="fw-bold">Regimen Capital</label>
			<p>{{selected_address.sat_regimen_capital}}</p>
		</div>
		<div class="col-12 col-md-3" *ngIf="selected_address.type == 'BILLING'">
			<label class="fw-bold">Regimen Fiscal</label>
			<p>{{regimen_fiscal_dict[selected_address.sat_regimen_fiscal]}}</p>
		</div>
		<div class="col-12 col-md-3" *ngIf="selected_address.type == 'BILLING'">
			<label class="fw-bold">Uso CFDI</label>
			<p>{{uso_cfdi_dict[selected_address.sat_uso_cfdi]}}</p>
		</div>
		<div class="col-12 col-md-3">
			<label class="fw-bold">Teléfono</label>
			<p>{{selected_address.phone}}</p>
		</div>
		<div class="col-12 col-md-3">
			<label class="fw-bold">Dirección</label>
			<p>{{selected_address.address}}</p>
		</div>
		<div class="col-12 col-md-3">
			<label class="fw-bold">Código Postal</label>
			<p>{{selected_address.zipcode}}</p>
		</div>
		<div class="col-12 col-md-3">
			<label class="fw-bold">Email</label>
			<p>{{selected_address.email}}</p>
		</div>
		<div class="col-12 text-end">
			<a [routerLink]="['/edit-address', selected_address.id]" class="btn btn-primary">Editar</a>
		</div>
	</div>
</app-modal>
