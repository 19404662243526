import { Component, OnInit } from '@angular/core';
import {forkJoin} from 'rxjs';
import {mergeMap } from 'rxjs/operators';
import {Utils} from 'src/app/classes/Utils';
import {Order, Price_Type, Store} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-report-sells-by-price',
	templateUrl: './report-sells-by-price.component.html',
	styleUrls: ['./report-sells-by-price.component.css']
})
export class ReportSellsByPriceComponent extends BaseComponent
{

	report_search:SearchObject<Order> = this.getEmptySearch();
	report_list:Record<string,string | number>[] = [];
	price_type_list:Price_Type[] = [];
	store_list:Store[] = [];
	start_str:string = '';
	end_str:string = '';

	ngOnInit(): void
	{
		let extra_keys:string[] = [
			'store_id',
			'price_type_id',
			'start_str',
			'end_str'
		];

		this.path = '/report-sells-by-price';
		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map)=>
			{
				this.report_search = this.getSearch( param_map, [], extra_keys );

				this.start_str = ''+(this.report_search.search_extra.start_str??'');
				this.end_str = ''+(this.report_search.search_extra.end_str??'');

				if( !this.start_str )
				{

					let d = new Date();
					d.setDate(1);
					d.setHours(0,0,0,0);
					this.start_str = Utils.getLocalMysqlStringFromDate(d);
					this.report_search.search_extra.start = Utils.getUTCMysqlStringFromDate(d);
					this.report_search.search_extra.start_str = Utils.getLocalMysqlStringFromDate(d).substring(0,10);
				}
				else
				{
					let d = Utils.getDateFromLocalMysqlString(this.report_search.search_extra.start_str as string);
					this.report_search.search_extra.start = Utils.getUTCMysqlStringFromDate(d);
					this.report_search.search_extra.start_str = Utils.getMysqlStringFromLocalDate(d).substring(0,10);
				}

				if( !this.end_str )
				{
					let end_of_month = Utils.getEndOfMonth(new Date());
					this.end_str = Utils.getLocalMysqlStringFromDate( end_of_month );
					this.report_search.search_extra.end = Utils.getUTCMysqlStringFromDate( end_of_month );
					this.report_search.search_extra.end_str = Utils.getMysqlStringFromLocalDate( end_of_month ).substring(0,10);
				}
				else
				{

					let d = Utils.getLocalDateFromMysqlString(this.report_search.search_extra.end_str as string);
					this.report_search.search_extra.end = Utils.getUTCMysqlStringFromDate(d).substring(0,10);;
					this.report_search.search_extra.end_str= this.report_search.search_extra.end;
				}

				this.start_str = this.report_search.search_extra.start_str.substring(0,10);
				this.end_str = this.report_search.search_extra.end_str.substring(0,10);


				return forkJoin
				({
					price_type: this.rest.getPriceTypes(true),
					report:this.rest.getReporte('getSalesByPriceType', this.report_search.search_extra),
					store: this.rest.getStores(true)
				})
			})
		).subscribe
		(
			(response)=>
			{
				this.price_type_list = response.price_type.data;
				this.store_list = response.store.data;
				this.report_list= response.report;
			},
			(error)=>{this.showError(error)}
		);
	}

	searchReport()
	{
		this.search( this.report_search );
	}
	print()
	{
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
	}
}
