<div class="container-fluid">

	<div class="row align-items-end mx-0">
		<div class="col">
			<h1 class="my-3 text-capitalize">Ofertas</h1>
		</div>
		<div class="col-auto">
			<!--button type="button" class="mr-2 btn btn-light lift" (click)="show_import=true">Importar</button>
			<button type="button" class="mr-2 btn btn-light lift" (click)="exportFile()">Exportar</button-->
			<button type="button" class="ml-2 btn btn-secondary lift" routerLink="/offer"> Agregar offer</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="offer_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Category</label>
					<select name="category_id" [(ngModel)]="offer_search.eq.category_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of category_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Coupon code</label>
					<input type="text" name="coupon_code" [(ngModel)]="offer_search.lk.coupon_code" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created by user</label>
					<select name="created_by_user_id" [(ngModel)]="offer_search.eq.created_by_user_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of user_list" [value]="c.id">{{c.status}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="offer_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Gift item</label>
					<select name="gift_item_id" [(ngModel)]="offer_search.eq.gift_item_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of item_list" [value]="c.id">{{c.applicable_tax}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Hour end</label>
					<input type="time" name="hour_end" [(ngModel)]="offer_search.lk.hour_end" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Hour start</label>
					<input type="time" name="hour_start" [(ngModel)]="offer_search.lk.hour_start" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid friday</label>
					<input type="number" name="is_valid_friday" [(ngModel)]="offer_search.eq.is_valid_friday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid monday</label>
					<input type="number" name="is_valid_monday" [(ngModel)]="offer_search.eq.is_valid_monday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid saturday</label>
					<input type="number" name="is_valid_saturday" [(ngModel)]="offer_search.eq.is_valid_saturday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid sunday</label>
					<input type="number" name="is_valid_sunday" [(ngModel)]="offer_search.eq.is_valid_sunday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid thursday</label>
					<input type="number" name="is_valid_thursday" [(ngModel)]="offer_search.eq.is_valid_thursday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid tuesday</label>
					<input type="number" name="is_valid_tuesday" [(ngModel)]="offer_search.eq.is_valid_tuesday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Is valid wednesday</label>
					<input type="number" name="is_valid_wednesday" [(ngModel)]="offer_search.eq.is_valid_wednesday" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Item</label>
					<select name="item_id" [(ngModel)]="offer_search.eq.item_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of item_list" [value]="c.id">{{c.applicable_tax}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Qty</label>
					<input type="number" name="qty" [(ngModel)]="offer_search.eq.qty" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Store</label>
					<select name="store_id" [(ngModel)]="offer_search.eq.store_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Type</label>
					<select name="type" [(ngModel)]="offer_search.lk.type" class="form-control">
						<option [ngValue]="null">Select</option>
						<option value="PERCENT_DISCOUNT">PERCENT DISCOUNT</option>
						<option value="N_X_M">N X M</option>
						<option value="AMOUNT_DISCOUNT">AMOUNT DISCOUNT</option>
						<option value="GIFT">GIFT</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated by user</label>
					<select name="updated_by_user_id" [(ngModel)]="offer_search.eq.updated_by_user_id" class="form-control">
						<option [ngValue]="null">Select</option>
						<option *ngFor="let c of user_list" [value]="c.id">{{c.status}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="offer_search.eq.updated" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Valid from</label>
					<input type="datetime-local" name="valid_from" [(ngModel)]="offer_search.lk.valid_from" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Valid thru</label>
					<input type="datetime-local" name="valid_thru" [(ngModel)]="offer_search.lk.valid_thru" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Cupon</th>
						<th>Artículo</th>
						<th>Horario</th>
						<th>Dias Habiles</th>
						<th>Sucursal</th>
						<th>Tipo</th>
						<th>Valido</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let offer of offer_list">
						<td><a [routerLink]="['/offer',offer.id]">{{offer.id}}</a></td>
						<td>{{offer.coupon_code}}</td>
						<td>{{offer.item_name }}</td>
						<td>{{offer.hour_start.substring(0,5)}} - {{offer.hour_end.substring(0,5)}}</td>
						<td>{{offer.active_days}}</td>
						<td>{{offer.store_name}}</td>
						<td>{{offer.type}}</td>
						<td>{{offer.valid_from | shortDate:'date'}} - {{offer.valid_thru | shortDate:'date'}}</td>
					</tr>
					<tr *ngIf="offer_list == null || offer_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-offer'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
<!--app-modal [(show)]="show_import">
	<div class="card p-3">
		<h2>Import excel</h2>
		<div class="from-group mb-3">
			<label>Excel data</label>
			<input name="file" type="file" (change)="onFileChanged($event)" accept="*/*" class="form-control">
		</div>
		<div class="row mt-3">
			<div class="col-6">
				<button type="button" class="btn btn-secondary w-100" (click)="show_import=false">Cancel</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100" (click)="uploadFile()" [disabled]="file==null">Upload</button>
			</div>
		</div>
	</div>
</app-modal-->
