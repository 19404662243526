<div class="container-fluid">
	<div class="my-3">
		<div class="row align-items-end">
			<div class="col">
				<h1>Costo y rango por artículo</h1>
			</div>
			<!--div class="col-auto">
				<a class="btn btn-secondary lift" routerLink="/add-item/{{type}}" *ngIf="rest.current_permission.add_items">Agregar</a>
			</div-->
		</div>
	</div>

	<!-- div class="card p-3" *ngIf="!(item_search.eq.category_id != null)">
		<div class="row">
			<div class="col-10 form-group">
				<label class="">Nombre</label>
				<input type="text" name="name" (input)="onSearch($event)" class="form-control" autocomplete="off">
			</div>
			<div class="col-2">
				<label>&nbsp;</label>
				<div>
					<button type="submit" class="w-100 btn btn-primary" (click)="custom_search(item_search)">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
						<span class="d-none d-md-inline">Buscar</span>
					</button>
				</div>
			</div>
		</div>
	</div -->

	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<table class="w-100">
			<thead>
				<tr class="border-bottom">
					<th (click)="clickOnHeader('item_name')">Artículo</th>
					<th (click)="clickOnHeader('category_name')">Categoría</th>
					<th class="text-end" (click)="clickOnHeader('item_reference_price')">Costo Referencia</th>
					<th class="text-end" (click)="clickOnHeader('options_min_price')">Opciones Mínimo</th>
					<th class="text-end" (click)="clickOnHeader('options_max_price')">Opciones Máximo</th>
					<th class="text-end" (click)="clickOnHeader('min_price')">Precio Mínimo</th>
					<th class="text-end" (click)="clickOnHeader('max_price')">Precio Máximo</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let d of data">
					<td class="border-bottom">
						<a *ngIf="rest.current_permission.add_items" [routerLink]="['/edit-item',d.info.item_id]">{{d.info.item_name}}</a>
						<span *ngIf="!rest.current_permission.add_items" [routerLink]="['/edit-item',d.info.item_id]">{{d.info.item_name}}</span>
					</td>
					<td class="border-bottom py-1">{{d.info.category_name}}</td>
					<td class="border-bottom py-1 text-end">{{d.info.item_reference_price | currency: '$'}}</td>
					<td class="border-bottom py-1 text-end">{{d.info.options_min_price | currency: '$'}}</td>
					<td class="border-bottom py-1 text-end">{{d.info.options_max_price | currency: '$'}}</td>
					<td class="border-bottom py-1 text-end">{{d.info.min_price| currency: '$'}}</td>
					<td class="border-bottom py-1 text-end">{{d.info.max_price| currency: '$'}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
