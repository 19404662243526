import { Component, OnInit, ViewChild } from '@angular/core';
import {GoogleMap} from '@angular/google-maps';
import {mergeMap} from 'rxjs/operators';
import {GeocodingResponse} from 'src/app/models/models';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-select-map-address',
	templateUrl: './client-select-map-address.component.html',
	styleUrls: ['./client-select-map-address.component.css']
})
export class ClientSelectMapAddressComponent extends BaseComponent implements OnInit
{
	address:string = '';
	center_position:google.maps.LatLngLiteral =  {lat:0,lng:0} ;
	 @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params) =>
			{
				this.address = params.get('address') as string;
				return this.rest.loadGoogleMapsApi();
			})
		).subscribe(()=>
		{
			if( navigator?.geolocation )
			{
				navigator.geolocation.getCurrentPosition
				(
					(geolocation:GeolocationPosition)=>{
						this.center_position.lat = geolocation.coords.latitude;
						this.center_position.lng = geolocation.coords.longitude;
						this.map.googleMap.setCenter(this.center_position);
					},
					()=>{

					},
					{
						maximumAge: Infinity
					}
				);
			}
		});
	}

	saveAddress()
	{
		let latLngLit = { lat: this.center_position.lat, lng: this.center_position.lng } as google.maps.LatLngLiteral;
		this.subs.sink = this.rest.getAddressFromLatLng(latLngLit)
		.pipe
		(
			mergeMap((response:GeocodingResponse)=>
			{
				if( response.results.length > 0 )
				{
					return this.rest.platform_address.create
					({
						address: response.results[0].formatted_address,
						lat: this.center_position.lat,
						lng: this.center_position.lng
					})
				}
				throw new Error('No se encontro ninguna dirección en la posición especificada');
			})
		)
		.subscribe((address)=>
		{
			this.showSuccess('La dirección se guardo exitosamente');
			this.location.back();
		},(error)=>{
			this.showError(error);
		});
	}

	centerChanged(something)
	{
		let x = this.map.googleMap.getCenter();
		this.center_position = { lat: x.lat(), lng: x.lng() };
	}
}
