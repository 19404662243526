import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { GraphData, GraphUtils } from 'src/app/classes/GraphUtils';
import { Utils } from 'src/app/classes/Utils';
import { BaseComponent } from '../base/base.component';

interface ISearch
{
	created:Date;
}

interface NameValue
{
	name:string;
	value:number;
}

interface GGData
{
	name:string;
	series:NameValue[]
}

@Component({
	selector: 'app-report-occupancy',
	templateUrl: './report-occupancy.component.html',
	styleUrls: ['./report-occupancy.component.css']
})
export class ReportOccupancyComponent extends BaseComponent implements OnInit
{
	report_search = this.getEmptySearch<ISearch>();
	startx:string = '';
	endx:string = '';

	graph:GraphData = GraphUtils.getEmptyStackAreaChart();

	avg_guests:number = 0;
	avg_minutes:number = 0;
	avg_tables:number = 0;

	ngOnInit(): void
	{
		this.path = '/report-occupancy';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				let date = new Date();
				let minute_offset = date.getTimezoneOffset();

				this.report_search = this.getSearch(response[0],['created'],[]);

				if( !this.report_search.ge.created )
				{
					let d = new Date();
					d.setDate(1);
					d.setHours(0, 0, 0, 0 );
					this.report_search.ge.created = d;
				}

				if( !this.report_search.le.created )
				{
					this.report_search.le.created = Utils.getEndOfMonth( this.report_search.ge.created );
				}

				this.startx = Utils.getMysqlStringFromLocalDate( this.report_search.ge.created );
				this.endx = Utils.getLocalMysqlStringFromDate( this.report_search.le.created );

				let start = this.report_search.ge.created;
				let end = this.report_search.le.created;
				return this.rest.getReporte('occupancy',{ minute_offset, start, end });
			})
		)
		.subscribe((response)=>
		{
			this.graph.results = response.map(i=>this.map(i));

			let sum_minutes = 0;
			let sum_guests = 0;
			let sum_tables = 0;

			for(let i of response)
			{
				sum_minutes+= i.duration;
				sum_guests+= i.total_guests;
				sum_tables+= i.table_count;
			}

			this.avg_guests  = sum_guests/response.length;
			this.avg_tables  = sum_tables/response.length;
			this.avg_minutes = sum_minutes/sum_tables;

/*
				[
				{ "name": "Germany", "series": [ { "name": "2010", "value": 7300000 }, { "name": "2011", "value": 8940000 } ] },
				{ "name": "USA", "series": [ { "name": "2010", "value": 7870000 }, { "name": "2011", "value": 8270000 } ] },
				{ "name": "France", "series": [ { "name": "2010", "value": 5000002 }, { "name": "2011", "value": 5800000 } ] }
			];
*/

		});
	}

	map(v:any):GGData
	{
		return {
			name: v.date,
			series: [
				{ name: 'Mesas', value:v.table_count},
				{ name: 'Duracion', value:v.duration/60},
				{ name: 'Personas', value:v.total_guests }
			]
		};
	}
}
