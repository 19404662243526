<div class="container-fluid">
	<h1 class="my-3">{{ 'Toma de inventario - ' + (stocktake.name ? stocktake.name : '') }}</h1>
    <p class="fs-4">
        Sucursal:
        <b>{{stocktake_store.name}}</b>
    </p>
    <div class="card p-3 mt-3" *ngIf="stocktake.id">
        <div class="col-12 form-group">
            <label>Agregar Artículo</label>
            <app-search-items [update_focus]="update_focus_number" (item_selected)="onItemInfoSelect($event)"></app-search-items>
        </div>
        <div class="row my-1 fw-bold d-none d-md-flex align-items-center">
            <div class="col-6">Artículo</div>
            <div class="col-2 text-end">Cantidad</div>
            <div class="col-4 text-end">Acciones</div>
        </div>
        <ng-container *ngIf="selected_stocktake_item_info">
            <form class="row py-1 border-bottom align-items-center" (submit)="updateStockTakeItem(selected_stocktake_item_info)" >
                <div class="col-12 col-md-6 fw-bold">
                    {{selected_stocktake_item_info.category?.name || 'N/A'}} - {{selected_stocktake_item_info.item.name }}
                    <span style="color:#BBBBBB">{{selected_stocktake_item_info.item.code}}</span>
                </div>
                <div class="col-6 col-md-2 form-group">
                    <label class="d-md-none">Cantidad</label>
                    <input name="stock_take_item_qty" maxlength="13" [name]="'selected_item_info'" [(ngModel)]="selected_stocktake_item_info.stocktake_item.real_qty" type="number" class="form-control text-end" min=0 [step]="selected_stocktake_item_info.item.partial_sale == 'NO'?'1':'any'" max=999999999 #input_qty>
                    {{input_qty.focus()}}
                </div>
                <div class="col-6 col-md-4 text-end form-group">
                    <label class="d-md-none">&nbsp;</label>
                    <button type="submit" class="btn btn-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
                        &nbsp;<span class="d-none d-sm-inline">
                            Guardar
                        </span>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>

    <h4 class="my-2">Ultímos artículos</h4>
    <div class="card p-3 mt-3" *ngIf="stocktake.id">
        <div class="row my-1 fw-bold d-none d-md-flex align-items-center">
            <div class="col-6">Artículo</div>
            <div class="col-2 text-end">Cantidad</div>
            <div class="col-4 text-end">Acciones</div>
        </div>
        <h3 class="my-3 text-center" *ngIf="stocktake_item_info_list.length <= 0">Artículos agregados</h3>
        <ng-container *ngFor="let stii of sorted_stocktake_item_info">
            <div class="row py-1 border-bottom align-items-center" *ngIf="stii.stocktake_item.db_qty != null">
                <div class="col-12 col-md-6 fw-bold">
                   {{stii.category?.name || 'N/A'}} - {{stii.item.name }}
                    <span style="color:#BBBBBB">{{stii.item.code}}</span>
                </div>
                <div class="col-6 col-md-2 text-end">
                    <label class="d-md-none fw-bold">Cantidad</label>
                    {{stii.stocktake_item.real_qty}}
                </div>
                <div class="col-6 col-md-4 text-end form-group">
                    <label class="d-md-none">&nbsp;</label>
                    <button type="button" class="btn btn-primary" (click)="restartStockTakeInfo(stii)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M1.707.293A1 1 0 0 0 .293 1.707L5.586 7L.293 12.293a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414-1.414L8.414 7l5.293-5.293A1 1 0 0 0 12.293.293L7 5.586z" clip-rule="evenodd"/></svg>
                        &nbsp;<span class="d-none d-sm-inline">
                            Editar
                        </span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <h4 class="my-2">Artículos no contados</h4>
    <app-pagination [path]="'/edit-stocktake/' + stocktake.id" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
    <div class="card p-3 mt-3">
        <h3 class="my-3 text-center" *ngIf="pending_item_category_list.length <= 0 && stocktake.id">Artículos no contados</h3>
        <div class="row py-1 border-bottom" *ngFor="let item_category of pending_item_category_list">
            <div class="col-6 fw-bold align-items-center">
                <img [src]="rest.getImagePath(item_category.item.image_id,item_category?.category?.image_id,rest.local_preferences.default_product_image_id)" style="height:44px;width:44px;">
                {{item_category?.category?.name}} {{item_category.item.name}}
            </div>
            <div class="col-6 fw-bold align-items-center text-end">
                <button class="btn btn-primary" type="button" (click)="onItemCategorySelect(item_category)" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 14h-6v6h-4v-6H4v-4h6V4h4v6h6z"/></svg>
                    &nbsp;<span class="d-none d-sm-inline">
                        Agregar
                    </span>
                </button>
            </div>
        </div>
    </div>
    <app-pagination [path]="'/edit-stocktake/' + stocktake.id" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
    <app-loading [is_loading]="is_loading"></app-loading>
</div>
