import { Component, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import Hashids from 'hashids';
import { forkJoin, from, of } from 'rxjs';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { OrderInfo, OrderItemInfo, PaymentInfo } from 'src/app/models/models';
import { Billing_Data, Currency_Rate, Payment } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/base.component';

interface COIItem extends OrderItemInfo
{
	unitary_price_with_tax:number;
	unitary_discount:number;
	total_without_discount:number;
	total_discount:number;
	percent_discount:number;
	total_with_discount:number;
	original_unitary_price_with_tax:number;
}
@Component
({
	selector: 'app-print-receipt3',
	templateUrl: './print-receipt3.component.html',
	styleUrls: ['./print-receipt3.component.css']
})
export class PrintReceipt3Component extends BaseComponent implements OnInit
{
	payment_info:PaymentInfo = null;
	order_info:OrderInfo | null;
	print_and_close:boolean = false;
	print_copies:number = 1;
	copies:number[]=[1];
	that:any = this;
	discount:number = 0;
	force_close:boolean = false;
	has_dolars:boolean = false;
	currency_rate:Currency_Rate | null = null;
	billing_data:Billing_Data | null = null;
	active:boolean = false;
	is_mobile:boolean = false;
	tax:number = 0;
	custom_items:COIItem[] = [];

	url_for_facturacion:string = 'https://'+window.location.hostname+'/facturar';
	element_type = NgxQrcodeElementTypes.URL;
	error_correction_level: NgxQrcodeErrorCorrectionLevels.HIGH;
	show_bill_code: boolean;
	print_delay: number = 500;

	transformCustomItems(oii:OrderItemInfo,order_info:OrderInfo):COIItem
	{
		let unitary_price_with_tax = 0;
		let unitary_discount = 0;
		let percent_discount = 0;
		let total_discount = 0;
		let total_without_discount = 0;
		let total_with_discount = 0;
		let original_unitary_price_with_tax= 0;

		if( oii.order_item.tax_included == 'YES' )
		{
			original_unitary_price_with_tax = oii.order_item.unitary_price_meta;
		}
		else
		{
			original_unitary_price_with_tax = oii.order_item.unitary_price_meta*(1+order_info.order.tax_percent/100);
		}

		if( oii.item.applicable_tax =='EXEMPT' )
		{
			unitary_price_with_tax = oii.order_item.unitary_price;
		}
		else
		{
			unitary_price_with_tax = oii.order_item.unitary_price*(1+order_info.order.tax_percent/100);
		}

		if( oii.order_item.discount_percent)
		{
			percent_discount = oii.order_item.discount_percent;
			unitary_discount = unitary_price_with_tax*(percent_discount/100);
			total_without_discount = original_unitary_price_with_tax*oii.order_item.qty;
			total_with_discount = unitary_price_with_tax*oii.order_item.qty;
			total_discount = total_without_discount-total_with_discount;
		}
		else if( !order_info.order.discount && oii.order_item.discount )
		{
			total_with_discount = (unitary_price_with_tax - oii.order_item.discount)*oii.order_item.qty;
		}
		else
		{
			total_with_discount = unitary_price_with_tax*oii.order_item.qty;
		}


		return {
			...oii,
			unitary_price_with_tax,
			original_unitary_price_with_tax,
			unitary_discount,
			total_without_discount,
			total_discount,
			percent_discount,
			total_with_discount
		};
	}

	hashids = new Hashids
	(
		environment.app_settings.hashids_salt,
		environment.app_settings.hashids_length,
		environment.app_settings.hashids_alphabet
	);

	legend:Record<string,string> = {
		'CASH-MXN':'Efectivo',
		'CASH-USD':'Dolares',
		'CREDIT_CARD-MXN':'T. Credito',
		'DEBIT_CARD-MXN':'T. Debito',
		'CHECK-MXN':'Cheque',
		'TRANSFER-MXN':'Transferencia',
		'RETURN_DISCOUNT-MXN':'Descuento',
	}

	service_type_dictionary:Record<string,string> = {
		'TOGO':'PARA LLEVAR',
		'IN_PLACE':'',
		'PICK_UP':'A DOMICILIO',
	}

	beforeprint = ()=>
	{
		if( this.rest.printer_ticket_config )
		{
			document.getElementById('print-config').innerHTML = this.rest.printer_ticket_config;
			return;
		}

		document.getElementById('print-config').innerHTML = '';
	};

	afterprint = function(){
		console.log('afterprint');
		document.getElementById('print-config').innerHTML = '';
	};

	ngOnDestroy(): void {
		this.active = false;
		window.removeEventListener('beforeprint', this.beforeprint);
		window.removeEventListener('afterprint', this.afterprint);
		super.ngOnDestroy();
	}

	ngAfterViewInit()
	{

	}

	ngAfterViewChecked()
	{
		this.active = true;
	}

	ngOnInit(): void
	{
		//window.addEventListener('beforeprint' , this.beforeprint);
		//window.addEventListener('afterprint' , this.afterprint );

		this.subs.sink = this.route.paramMap.pipe
		(
			debounceTime(200)
		)
		.subscribe((paramMap:ParamMap)=>
		{
			this.currency_rate = null;
			this.print_and_close = paramMap.has('print');
			this.print_copies = paramMap.has('copies') ? parseInt(paramMap.get('copies')) : 1;
			this.force_close = paramMap.has('print');

			let c = new Array( this.print_copies );
			c.fill(1);
			this.copies = c;

			let search:SearchObject<Payment> = this.getEmptySearch();
			search.search_extra = {order_id:paramMap.get('order_id')};
			search.limit = 1;
			search.sort_order=['id_ASC'];

			if( this.print_and_close )
			{
				//window.addEventListener('afterprint',this.afterprint);
			//	window.onafterprint=this.afterprint
			}
			let something = paramMap.has('order_id') ? paramMap.get('order_id') : paramMap.get('order_sync_id');

			this.setTitle('ReciboOrden-'+something);

			let order_observable = paramMap.has('order_id')
					? this.rest.order_info.get( paramMap.get('order_id') )
					: this.rest.getOfflineOrderInfoBySyncId( paramMap.get('order_sync_id') );

			let payment_observable = paramMap.has('order_id')
					? this.rest.payment_info.search( search )
					: (from(this.rest.getOfflinePaymentByOderSyncId( paramMap.get('order_sync_id') ))
						.pipe
						(
							mergeMap(( r )=>
							{
								if( r )
								{
									return of({ data:[r], total: 1});
								}
								return of( {data: [], total: 0} );
							}),
							catchError((_error)=>
							{
								return of({data: [], total: 0} );
							})
						)
					);

			this.subs.sink = forkJoin
			({
				order: order_observable,
				payment_info: payment_observable,
				stores: this.rest.getStores(!paramMap.has('order_id')),
				billing_data : this.rest.getBillingData(!paramMap.has('order_id'))
			})
			.subscribe((responses)=>
			{
				let store = responses.order.store;

				this.rest.printer_ticket_config = store.printer_ticket_config;

				if( this.print_and_close )
				{
					console.log('Adding print config',this.rest.printer_ticket_config );
					document.getElementById('print-config').innerHTML = this.rest.printer_ticket_config;
				}
				else
				{
					console.log('Adding print config',this.rest.printer_ticket_config );
					document.getElementById('print-config').innerHTML = this.rest.printer_ticket_config;
				}


				responses.order.items.forEach((oii)=>
				{
					oii.serials_string = oii.serials
						.map((i)=>i.serial.serial_number)
						.join(',');
				});

				this.rest.createStructuredItems( responses.order );
				this.billing_data = responses.billing_data.data.find(bd=>bd.id == store.id) || null;

				this.custom_items = responses.order.items.map((oii)=>
				{
					return this.transformCustomItems(oii,responses.order)
				});

				console.log('Custom items has', this.custom_items );


				this.order_info = responses.order;
				this.setShowCode();
				//this.discount = responses.order.items.reduce((p,i)=>p+i.order_item.discount,0);

				if( responses.payment_info.data.length )
				{
					this.payment_info= responses.payment_info.data[0];
				}

				if( this.payment_info?.movements )
				{
					dance:
					for(let bmi of this.payment_info.movements)
					{
						for(let bmo of bmi.bank_movement_orders )
						{
							if( bmo.currency_id != this.order_info.store.default_currency_id )
							{
								this.currency_rate = {
									store_id: responses.order.store.id,
									currency_id: bmo.currency_id,
									rate: bmo.exchange_rate
								};
								break dance;
							}
						}
					}
				}

				console.log('Print delay',this.print_delay+(this.order_info.store?.print_delay||0));
				if( this.print_and_close )
				{
					console.log('Print delay',this.print_delay+(this.order_info.store?.print_delay||0));
					setTimeout(()=>{
						if( this.active )
							window.print()

							if( !this.is_mobile )
								window.close();
					},this.print_delay+(this.order_info.store?.print_delay||0));
				}
			},(error)=>this.showError(error));
		});
	}

	/*
		1-1643157008003
	1-1643157096411
	1-1643157134876
	*/

	facturar(_evt:Event)
	{
		let url = this.rest.getUrlBase()+'/facturar.php?id='+this.order_info.order.id;

		this.subs.sink = this.rest
			.getUrlAsString( url )
			.subscribe((response)=>
			{
				this.showSuccess('Exito');
			},(error)=>
			{
				this.showError(error);
			})
	}

	setShowCode()
	{
	//Original Comparison
	//order_info && order_info.store.autofacturacion_settings != 'DISABLED' && order_info.store.show_facturacion_qr == 'YES'"
	//order_info && order_info.store.autofacturacion_settings != 'DISABLED' && order_info?.order?.paid_status == 'PAID' && order_info.store.default_billing_data_id
		this.show_bill_code	=	this.order_info	&&
			this.order_info.store.autofacturacion_settings	!=	'DISABLED'	&&
			this.order_info.store.default_billing_data_id	&&
			this.order_info?.price_type?.show_bill_code	!=	'NO'
	}


	justPrint()
	{
		window.print();
	}
	closeWindow()
	{
		window.close();
	}
}
