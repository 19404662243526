<router-outlet (activate)="onActivate($event)"></router-outlet>
<div class="d-print-none"><app-toast-error></app-toast-error></div>
<app-modal [(show)]="confirmation_service.show_confirmation">
	<div class="p-3">
		<h4>{{confirmation_service.title}}</h4>
		<p>{{confirmation_service.description}}</p>
		<div class="row">
			<div class="col-6 text-center">
				<button type="button" class="btn btn-secondary w-100"
					(click)="confirmation_service.onCancel()">
					{{confirmation_service.cancel_button}}
				</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100"
					(click)="confirmation_service.onAccept()" >
					{{confirmation_service.ok_button}}
				</button>
			</div>
		</div>
	</div>
</app-modal>

<!--app-modal [(show)]="confirmation.show_ask_permission">
	<div class="p-3">
		<h4>{{confirmation.title}}</h4>
		<p>{{confirmation_service.description}}</p>
		<div class="row">
			<div class="col-6 text-center">
				<button type="button" class="btn btn-secondary w-100"
					(click)="confirmation_service.onCancel()">
					{{confirmation_service.cancel_button}}
				</button>
			</div>
			<div class="col-6">
				<button type="button" class="btn btn-primary w-100"
					(click)="confirmation_service.onAccept()" >
					{{confirmation_service.ok_button}}
				</button>
			</div>
		</div>
	</div>
</app-modal-->
