<div>
	<!--{{ city_suggestion }}- {{state_suggestion}}- {{country_suggestion}}- {{zipcode_suggestion }}-->
	<form ngNativeValidate (submit)="save()" *ngIf="user">
		<div class="card">
			<div class="row p-3">
				<div class="col-6 col-md-4">
					<label class="">Nombre:</label>
					<input type="text" name="name" [(ngModel)]="address.name" class="form-control" list="user_suggestion" required>
					<datalist id="user_suggestion">
						<option>{{user.name}}</option>
					</datalist>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Teléfono</label>
					<input type="text" name="phone" [(ngModel)]="address.phone" class="form-control">
				</div>
				<!--div class="col-6 col-md-4">
					<label class="">Business name</label>
					<input type="text" name="business_name" [(ngModel)]="address.business_name" class="form-control">
				</div-->
				<div class="col-6 col-md-4" *ngIf="address.type=='BILLING' || address.type=='BILLING_AND_SHIPPING'">
					<label class="">Rfc</label>
					<input type="text" name="rfc" [(ngModel)]="address.rfc" class="form-control" required>
				</div>

				<div class="col-6 col-md-4">
					<label class="">Dirección</label>
					<input type="text" name="address" [ngModel]="address.address" class="form-control" (ngModelChange)="onAddressChange($event)" required>
					<datalist id="address_suggestion">
						<option>{{address_suggestion}}</option>
					</datalist>
				</div>

				<!--div class="col-6 col-md-4">
					<label class="">Ciudad</label>
					<input type="text" name="city" [(ngModel)]="address.city" class="form-control" (ngModelChange)="onCityChange($event)" list="city_list">
					<datalist id="city_list">
						<option>{{city_suggestion}}</option>
					</datalist>
				</div>

				<div class="col-6 col-md-4">
					<label class="">Zipcode</label>
					<input type="text" name="CP" [(ngModel)]="address.zipcode" class="form-control">
				</div>

				<div class="col-6 col-md-4">
					<label class="">Estado</label>
					<input type="text" name="state" [(ngModel)]="address.state" class="form-control">
					<datalist id="city_list">
						<option *ngFor="let state of states">{{state}}</option>
					</datalist>
				</div>

				<div class="col-6 col-md-4">
					<label class="">Country</label>
					<input type="text" name="Pai" [(ngModel)]="address.country" list="country_suggestion" class="form-control">
					<datalist id="country_suggestion">
						<option>{{country_suggestion}}</option>
					</datalist>
				</div>
				<div class="col-6 col-md-4">
					<label class="">Colonia</label>
					<input type="text" name="suburb" [(ngModel)]="address.suburb" class="form-control" list="sublocalities">
					<datalist id="sublocalities">
						<option *ngFor="let s of sublocalities">{{s}}</option>
					</datalist>
				</div-->

				<div class="col-6 col-md-4" *ngIf="address.type == 'BILLING' || address.type == 'BILLING_AND_SHIPPING'">
					<label class="">Email</label>
					<input type="text" name="email" [(ngModel)]="address.email" class="form-control" [required]="address.type=='BILLING' || address.type=='BILLING_AND_SHIPPING'">
				</div>

				<div class="col-6 col-md-4">
					<label class="">Nota</label>
					<input type="text" name="note" [(ngModel)]="address.note" class="form-control">
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">Guardar</button>
				</div>
			</div>
		</div>
	</form>
</div>
