import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GetEmpty } from 'src/app/classes/Empties';
import {Utils} from 'src/app/classes/Utils';
import { CashCloseInfo } from 'src/app/models/models';
import {Cash_Close, Currency_Rate, Store} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component
({
	selector: 'app-save-cash-close',
	templateUrl: './save-cash-close.component.html',
	styleUrls: ['./save-cash-close.component.css']
})

export class SaveCashCloseComponent extends BaseComponent implements OnInit
{
	cash_close_list:Cash_Close[] = [];
	cash_on_hand: '' | number = '';
	cash_dolares:'' | number = '' ;
	currency_rate_list:Currency_Rate[] = [];
	store: Store = GetEmpty.store();

	ngOnInit(): void
	{
		this.subs.sink = forkJoin
		({
			store: this.rest.store.get( this.rest.current_user.store_id ),
			cash_close: this.rest.cash_close.search
			({
				eq:{ created_by_user_id: this.rest.current_user.id },
				sort_order: ['id_DESC'],
				limit: 7
			}),
			currency_rate: this.rest.currency_rate.search
			({
				eq:{ store_id: this.rest.current_user.store_id },
				limit: 99999
			})
		})
		.subscribe
		({
			error:(error)=>this.showError(error),
			next:(response)=>
			{
				this.cash_close_list = response.cash_close.data;
				this.currency_rate_list = response.currency_rate.data;
				this.store = response.store;
			}
		});
	}

	crearCorteNuevo(_evt:Event)
	{
		console.log('Creando corte', Math.random());
		let date:string = Utils.getMysqlStringFromLocalDate( new Date() );
		this.is_loading = true;

		let other_currencies = parseFloat( ''+this.cash_dolares );
		let cash_on_hand = parseFloat( ''+this.cash_on_hand );
		let total_cash = 0;

		if( isNaN(cash_on_hand) )
		{
			cash_on_hand = 0;
		}

		if( isNaN(other_currencies) )
		{
			other_currencies = 0;
		}


		let order_currency_id = this.store.default_currency_id;
		let default_currency_id = this.store.default_currency_id;

		let target_currency_id = this.store.default_currency_id;

		let pesos_rate = this.rest.getExchangeRate
		(
			order_currency_id,
			default_currency_id,
			'MXN', //target_currency_id,
			this.currency_rate_list
		)

		let dolares_rate = this.rest.getExchangeRate
		(
			order_currency_id,
			default_currency_id,
			'USD', //target_currency_id,
			this.currency_rate_list
		);


		let total = pesos_rate * cash_on_hand+other_currencies*dolares_rate;

		//let other_currencies = this.cash_dolares as number;

		let cash_close:Partial<Cash_Close> = {
			end: date,
			other_currencies: 0,
			cash_on_hand: total,
			note: 'Dolares ' + other_currencies.toFixed(2)+'x'+dolares_rate.toFixed(5)
		};

		console.log('Cash close', cash_close);
		console.log('Rates', pesos_rate , dolares_rate, cash_close);

		this.subs.sink = this.rest.cash_close_info
		.create({cash_close,funds:[]})
		.subscribe((response)=>
		{
			this.showSuccess('Corte exitoso');

			let cash_close_info:CashCloseInfo = response;

			console.log('Redirigiendo a tipo de corte', this.rest.local_preferences.default_cash_close_receipt );

			let url = '#/print-corte/'+this.rest.local_preferences.default_cash_close_receipt+'/'+cash_close_info.cash_close.id+'/1/1'
			let w = window.open( url );
			let z = this;
			console.log('Pasa algo aqui');

			w.onafterprint = function()
			{
				console.log('En la funcion foo FOOOO');
		//		z.rest.logout();
			};

			w.addEventListener('afterprint',(_evt2:Event)=>
			{
				console.log('Tratando afterprint');
		//		this.rest.logout();
			});
		},(error)=>this.showError(error));
	}

	printCashClose2()
	{

	}
}

