import { Component, OnInit } from '@angular/core';
import {ReturnsInfo} from 'src/app/models/models';
import {Returns} from 'src/app/models/RestModels';
import { SearchObject } from '../../services/Rest';
import { BaseComponent } from '../base/base.component';


@Component({
	selector: 'app-list-returns',
	templateUrl: './list-returns.component.html',
	styleUrls: ['./list-returns.component.css']
})

export class ListReturnsComponent extends BaseComponent implements OnInit {
	file:File = null;
	show_import:boolean = false;
	returns_search:SearchObject<Returns> = this.getEmptySearch();
	returns_info_list:ReturnsInfo[] = [];

	ngOnInit()
	{
		this.path = '/list-returns';

		this.subs.sink = this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","store_id","client_user_id","order_id","total","note","cashier_user_id","amount_paid","code","created","updated" ];
			this.returns_search = this.getSearch(queryParamMap, fields );
			let extra_keys:Array<string> = []; //['search_param1','project_id','some_id'];

			this.setTitle('returns');
			this.is_loading = true;

			this.is_loading = true;
			this.subs.sink = this.rest.returns_info
			.search(this.returns_search)
			.subscribe((response)=>
			{
				this.setPages( this.returns_search.page, response.total );
				this.returns_info_list = response.data;
				this.is_loading = false;
			},(error)=>this.showError(error));

		});
	}
}
