<form (submit)="save($event)">
	<div class="container-fluid">
		<h1>Agregar Gasto/Retiro</h1>
		<div class="row align-intems-center">
			<div class="col-12 col-md-6 form-group">
				<label>Monto</label>
				<input type="number" name="total" [min]="1" [(ngModel)]="total"  step="any" class="form-control" required>
			</div>
			<div class="col-12 col-md-6">
				<label>&nbsp;</label>
				<div class="form-check form-switch">
					<label class="form-check-label">
						<input type="checkbox"
							name="imprimir_recibo"
							class="form-check-input"
							(change)="imprimirChanged($event)"
							[checked]="imprimir_recibo">
						Imprimir recibo
					</label>
				</div>
			</div>
			<div class="col-12 col-md-6 form-group">
				<label>Tipo de gasto</label>
				<input type="text" name="tag" [(ngModel)]="tag" class="form-control" list="reasons" required>
				<datalist id="reasons">
					<option>Retiro</option>
					<option>Renta</option>
					<option>Agua</option>
					<option>Luz</option>
					<option>Cable</option>
					<option>Internet</option>
					<option>Limpieza</option>
					<option>Agua Potable</option>
					<option>Consumibles</option>
				</datalist>
			</div>
			<div class="col-12 col-md-6 form-group">
				<label>Nota</label>
				<input type="text" name="note" [(ngModel)]="note" class="form-control" required>
			</div>
			<div class="col-12 col-md-6 form-group">
				<div>
					<app-attachment-uploader [attachment_id]="receipt_attachment_id"></app-attachment-uploader>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-6">
				<button type="button" class="w-100 btn btn-danger" (click)="cancel()" [disabled]="is_loading">Cancelar</button>
			</div>
			<div class="col-6">
				<button type="submit" class="w-100 btn btn-success"  [disabled]="is_loading">Guardar</button>
			</div>
		</div>
	</div>
</form>
