<div class="text-center" *ngIf="rest.local_preferences.default_ticket_image_id">
	<img [src]="rest.getImagePath(rest.local_preferences.default_ticket_image_id)" class="image_logo_ticket">
</div>
<div class="d-print-none text-center">
	<button type="button" class="btn btn-primary m-3" (click)="justPrint()">Imprimir</button>
	<button type="button" class="btn btn-primary m-3" style="" (click)="location.back()">Regresar</button>
</div>
<div *ngIf="payment_info_list.length && order_info">
	<div class="text-center" *ngIf="order_info.store">
		<div>{{payment_info.store?.name | titlecase }}</div>
		<div>{{payment_info.store?.city | titlecase }}</div>
		<div>{{payment_info.store?.state | titlecase }}</div>
		<div class="fw-bold">{{payment_info.store?.phone | titlecase }}</div>
	</div>

	<div>
		Cliente: <b>{{payment_info.paid_by_user?.name || order_info.client?.name | titlecase}}</b>
	</div>
	<div>
		
	</div>
	<div>
		<div class="row mx-0">
			<div class="col-4 p-0">
				Atendio: <b>{{payment_info.created_by_user?.name | titlecase}}</b>
			</div>
			<div class="col-8 p-0 text-end">
				Fecha: <b>{{payment_info.payment.created | shortDate: 'full'}}</b>
			</div>
		</div>
	</div>
	<div>
		<div class="row mx-0 mb-3">
			<div class="col p-0">
				Orden Id: <b>{{order_info.order.id}}</b>
			</div>
			<div class="col p-0 text-end">
				Folio: <b>{{order_info.order.store_consecutive}}</b>
			</div>
		</div>
	</div>

	<table class="w-100 mb-3">
		<tr>
			<th class="text-end">Subtotal</th>
			<td class="text-end">{{order_info.order.total |currency}} {{order_info.order.currency_id}}</td>
		</tr>
		<tr>
			<th class="text-end">Descuento</th>
			<td class="text-end">{{order_info.order.discount |currency}} {{order_info.order.currency_id}}</td>
		</tr>
		<tr>
			<th class="text-end">Total</th>
			<td class="text-end">{{(order_info.order.total-order_info.order.discount) |currency}} {{order_info.order.currency_id}}</td>
		</tr>
	</table>

	<h1 class="text-center">ABONOS</h1>
	<table class="w-100">
		<tr>
			<th class="text-end">Fecha</th>
			<th class="text-end">Tipo P.</th>
			<!-- <th class="text-end">Adeudo</th> -->
			<th class="text-end">Abono</th>
			<th class="text-end">Balance {{order_info.order.currency_id}}</th>
		</tr>
		<ng-container *ngFor="let bmo of sums">
			<tr *ngIf="bmo.order_id == order_info.order.id">
				<td class="text-end">{{bmo.created | shortDate: 'date' }}</td>
				<td class="text-end">{{legend[bmo.payment_type + '-' + bmo.currency_id]}}</td>
				<!-- <td class="text-end">{{order_info.order.total-(order_info.order.discount + bmo.sum)+bmo.amount | currency}} {{order_info.order.currency_id}}</td> -->
				<td class="text-end">${{bmo.currency_amount |number:'1.2-2'}} {{bmo.currency_id}}</td>
				<td class="text-end">${{((order_info.order.total - (order_info.order.discount + bmo.sum)) > 0 ? (order_info.order.total - (order_info.order.discount + bmo.sum)) : 0) | number:'1.2-2'}}</td>
			</tr>
		</ng-container>
	</table>

	<table class="w-100">
		<ng-container *ngIf="payment_info">
			<tr class="border-top">
				<td class="text-end">Total Abonado</td>
				<td class="text-end">{{all_partial_payments | currency:'$'}} {{order_info.order.currency_id}}</td>
			</tr>
			<tr class="border-top">
				<td class="text-end larger fw-bold">Total Recibido</td>
				<td class="text-end larger fw-bold">{{payment_info.payment.received_amount | currency:'$'}} {{order_info.order.currency_id}}</td>
			</tr>
			<tr class="mx-0">
				<td class="text-end fw-bold">Cambio</td>
				<td class="text-end fw-bold">{{payment_info.payment.change_amount | currency:'$'}} {{order_info.order.currency_id}}</td>
			</tr>
			<tr class="border-top">
				<td class="text-end">Pendiente</td>
				<td class="text-end">{{((order_info.order.total - (order_info.order.discount +all_partial_payments)) > 0 ? (order_info.order.total - (order_info.order.discount + all_partial_payments)) : 0) | currency:'$'}} {{order_info.order.currency_id}}</td>
			</tr>
		</ng-container>
	</table>
</div>
