import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Category, Item, Stocktake, Stocktake_Item, Store, User } from 'src/app/models/RestModels';
import { ItemInfo, StocktakeItemInfo } from 'src/app/models/models';
import { GetEmpty } from 'src/app/classes/Empties';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { ParamMap } from '@angular/router';
import { SearchObject } from 'src/app/services/Rest';

interface CItemCategory
{
  item: Item,
  category: Category
}

@Component({
	selector: 'app-save-stocktake',
	templateUrl: './save-stocktake.component.html',
	styleUrls: ['./save-stocktake.component.css']
})
export class SaveStocktakeComponent extends BaseComponent implements OnInit {

	@ViewChild('item_search', { read: ElementRef }) item_search:ElementRef;
	update_focus_number:number = 0;
	missing_search:SearchObject<any> = this.getEmptySearch();
	pending_item_category_list:CItemCategory[] = [];
	stocktake:Stocktake = GetEmpty.stocktake();
	stocktake_item_info_list:StocktakeItemInfo[] = [];
	selected_stocktake_item_info:StocktakeItemInfo = null;
	sorted_stocktake_item_info:StocktakeItemInfo[] = null;
	stocktake_store:Store = GetEmpty.store();


	ngOnInit(): void {

		this.stocktake = GetEmpty.stocktake();

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			debounceTime(250),
			mergeMap((response:ParamMap[])=>
			{	
				this.is_loading = true;

				this.setTitle('Editar toma de inventario');
				let stocktake_id = parseInt(response[1].get('stocktake_id')) as number;
				this.missing_search = this.getSearch(response[0], [], []);
				this.missing_search['stocktake_id'] = stocktake_id;
				this.missing_search.limit = 10;
				return forkJoin
				({
					stocktake: this.rest.stocktake.get(stocktake_id),
					stocktake_items: this.rest.stocktake_item.search({eq:{stocktake_id:stocktake_id}}),
					missing_items: this.rest.getReporte('getStocktakeMissingItems', this.missing_search),
				});
			}),
			mergeMap((response)=>
			{
				this.stocktake = response.stocktake;
				let stocktake_items_ids = response.stocktake_items.data.map((si)=>si.item_id);
				this.setPages(this.missing_search.page, response.missing_items.total)
				return forkJoin
				({
					stocktake: of(response.stocktake),
					stocktake_items: of(response.stocktake_items.data),
					missing_items: of(response.missing_items.data),
					item_info: this.rest.item_info.search
					({
						limit: 9999,
						csv:{id:stocktake_items_ids},
						search_extra:{store_id: response.stocktake.store_id},
					}),
					store: this.rest.store.get(response.stocktake.store_id),
				});
			}),
		)
		.subscribe((response)=>
		{
			this.stocktake = response.stocktake;
			this.stocktake_item_info_list = response.stocktake_items.map((si)=>
			{
				let stocktake_item_info:StocktakeItemInfo = {
					stocktake_item: si,
					category: null,
					user: this.rest.current_user as User,
					item: response.item_info.data.find((ii)=>ii.item.id == si.item_id).item,
				};
				return stocktake_item_info;
			})

      		this.pending_item_category_list = response.missing_items;
      		this.getLastStockTakeInfo();

			this.stocktake_store = response.store;

		}), (error)=> this.showError(error);

	}

	onItemInfoSelect(item_info:ItemInfo)
	{
		if( item_info.options.length > 0 )
		{
			this.showError('No se puede agregar un item con opciones');
			return;
		}

		let stock_take_item:Stocktake_Item = {
			stocktake_id: this.stocktake.id,
			item_id: item_info.item.id,
			db_qty: null,
			real_qty: null,
			created_by_user_id: this.rest.current_user.id,
		};

		let stock_take_item_info:StocktakeItemInfo = {
			stocktake_item: stock_take_item,
			category:null,
			user: this.rest.current_user as User,
			item: item_info.item,
		};

		this.selected_stocktake_item_info = stock_take_item_info;

	}

	onItemCategorySelect(item_category:CItemCategory)
	{
		this.subs.sink = this.rest.item_info.get(item_category.item.id).subscribe((item_info:ItemInfo)=>
		{
			this.onItemInfoSelect(item_info);
		});
	}

	updateStockTakeItem(stocktake_item_info:StocktakeItemInfo)
	{
		if( stocktake_item_info.stocktake_item.real_qty == null )
		{
			this.showError('Por favor ingrese la cantidad real');
			return;
		}

		this.selected_stocktake_item_info = null;

		this.subs.sink = this.rest.stocktake_item.update(stocktake_item_info.stocktake_item).subscribe((response)=>
		{
			this.showSuccess('Item inventariado exitosamente');

			//cambios en el valor para que el focus se actualice en el componente search-items
			this.update_focus_number++;

			//si hay registro, actualizarlo en el arreglo de stocktake_item_info con la response
			if(this.stocktake_item_info_list.find((si)=>si.stocktake_item.item_id == stocktake_item_info.stocktake_item.item_id) != null)
			{
				//eliminar el registro del arreglo
				this.stocktake_item_info_list = this.stocktake_item_info_list.filter((si)=>si.stocktake_item.item_id != stocktake_item_info.stocktake_item.item_id);
				//agregar el registro actualizado
				let new_stocktake_item_info:StocktakeItemInfo = 
				{
					stocktake_item: response,
					category:null,
					item: stocktake_item_info.item,
					user: this.rest.current_user as User,
				}
				this.stocktake_item_info_list.push(new_stocktake_item_info);
				this.getLastStockTakeInfo();
			}
			else
			{
				let new_stocktake_item_info:StocktakeItemInfo = 
				{
					stocktake_item: response,
					category: null,
					user: this.rest.current_user as User,
					item: stocktake_item_info.item,
				}
				this.stocktake_item_info_list.push(new_stocktake_item_info);
				this.getLastStockTakeInfo();
			}
			
		});

	}

	restartStockTakeInfo(stock_take_item_info:StocktakeItemInfo)
	{
		stock_take_item_info.stocktake_item.real_qty = null;
		this.selected_stocktake_item_info = stock_take_item_info;
	}

	//sort by desc date
	getLastStockTakeInfo()
	{
		this.sorted_stocktake_item_info = this.stocktake_item_info_list.sort((a, b)=>
		{
			return new Date(b.stocktake_item.updated).getTime() - new Date(a.stocktake_item.updated).getTime();
		}).slice(0, 3);
	}

	getMissingItemsInfo()
	{
		this.subs.sink = this.rest
		.getReporte('getStocktakeMissingItems', {stocktake_id: this.stocktake.id})
		.subscribe((response)=>
		{
			this.pending_item_category_list = response.data;
		});
	}


}
