<div class="container-fluid">
	<div class="row align-items-center mx-0">
		<div class="col-10 px-0">
			<h1 class="my-3 text-capitalize">Merma</h1>
		</div>
		<div class="col-2 text-end px-0" (click)="exportXls()">
			<button type="button" class="btn btn-secondary">
				<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
				Exportar
			</button>
		</div>
	</div>

	<div class="card p-3">
		<form (submit)="search(merma_search)">
			<div class="row">
				<div class="col-6 col-md-3 form-group">
					<label class="">Artículos</label>
					<input name="category_name" [(ngModel)]="merma_search.search_extra.category_name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Sucursal</label>
					<select name="store_id" [(ngModel)]="merma_search.eq.store_id" class="form-control">
						<option [ngValue]="null">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Usuario</label>
					<select name="user_id" [(ngModel)]="merma_search.eq.created_by_user_id" class="form-control">
						<option value="">Seleccionar</option>
						<option *ngFor="let user of user_list" [value]="user.id">{{user.name | titlecase}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Fecha Inicial  {{merma_search.ge.created}}</label>
					<input type="date" name="final_created" [ngModel]="fecha_inicial" (ngModelChange)="onFechaInicialChange($event)" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Fecha Final {{merma_search.lt.created}}</label>
					<input type="date" name="inicial_created" [ngModel]="fecha_final" (ngModelChange)="onFechaFinalChange($event)" class="form-control">
				</div>
				<div class="col-6 col-md-3">
					<label>&nbsp;</label>
					<div>
						<button type="submit" class="w-100 btn btn-primary" (click)="search(merma_search)">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path _ngcontent-oxd-c76="" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
							Buscar
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="w-100 mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Artículo</th>
						<th>Sucursal</th>
						<th>Cant. Merma</th>
						<th>Fecha</th>
						<th class="d-none d-md-table-cell">Nota</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let merma_info of merma_info_list" class="border">
						<td>{{merma_info.merma.id}}</td>
						<td>{{merma_info?.category?.name | titlecase }} - {{ merma_info.item.name | titlecase }}</td>
						<td>{{store_dictionary[ merma_info?.merma.store_id ].name  | titlecase }}</td>
						<td>{{merma_info.merma.qty}}</td>
						<td>{{merma_info.merma.created | date: 'd MMM y, hh:mma' }}</td>
						<td class="d-none d-md-table-cell">{{merma_info.merma.note}}</td>
					</tr>
					<!--tr *ngIf="merma_info_list == null || merma_info_list.length == 0 && !is_loading">
						<td colspan="6">
							<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
						</td>
					</tr-->
				</tbody>
			</table>
		</div>
		<app-pagination [path]="'/list-merma'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
	</div>
</div>

