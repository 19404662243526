import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { Router, ActivatedRoute } from "@angular/router";
import { BaseComponent } from '../base/base.component';
import { forkJoin } from 'rxjs';

import {Storage, Storage_Type} from '../../models/RestModels';
import {Store} from '../../models/RestModels';

interface StorageNode extends Storage{
	StorageHijos?:StorageNode[];
	storage_type_name?:string;
}


@Component({
	selector: 'app-save-storage',
	templateUrl: './save-storage.component.html',
	styleUrls: ['./save-storage.component.css']
})
export class SaveStorageComponent extends BaseComponent implements OnInit {
	@Input() show_title:boolean=true
	@Input() isCardShaped:boolean=true
	storage:StorageNode	= {};
	store_list:Store[] = [];
	storage_type_list:Storage_Type[]=[]
	store_dictionary:Record<number,Store> = {};
	@Input() storage_parent_id:number|null=null
	@Output() update = new EventEmitter<Storage>()


	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			this.is_loading = true;
			console.log(params)

			this.subs.sink = forkJoin({
				storage : this.rest.storage.get( params.has('id')?params.get('id'):0),
				storage_types: this.rest.storage_type.getAll(),
				store : this.rest.store.search({limit:9999})
			})
			.subscribe((responses)=>
			{
				responses.store.data.forEach((i)=> this.store_dictionary[ i.id ] = i);
				this.storage = responses.storage;
				this.store_list = responses.store.data;
				this.storage_type_list=responses.storage_types.data;
				this.is_loading = false;

			},(error)=>this.showError(error));
		});
	}

	save()
	{
		this.is_loading = true;

		this.storage.parent_storage_id=this.storage_parent_id
		this.storage.level=1
		this.storage.storage_type_name=this.storage_type_list.find((type)=> type.id==this.storage.storage_type_id).name

		if( this.storage.id	)
		{
			this.subs.sink	= this.rest.storage.update( this.storage ).subscribe((storage)=>{
				this.is_loading = false;
				this.update.emit(this.storage)
				this.router.navigate(['/list-storage']);
				this.showSuccess('Sección de almacén se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.storage.create( this.storage ).subscribe((storage)=>{
				this.is_loading = false;
				this.showSuccess('Seccion de almacén se guardó exitosamente');
				this.update.emit(this.storage)
				this.router.navigate(['/list-storage']);
			},(error)=>this.showError(error));
		}
	}
}
