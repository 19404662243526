<div class="container-fluid">
	<h1 class="my-3">Cambiar Tienda</h1>
	<ul class="list-group">
	  <li class="list-group-item pointer" *ngFor="let psi of platform_store_list" (click)="changeStore(psi)">
		  	<div  *ngIf="psi.store.image_id" class="ratio ratio-1x1 d-inline-block"
				[style]="{'background-image':'url('+rest.getPlatformImagePath(psi.store.image_id)+')','background-size':'contain',width:'40px','vertical-align':'middle','background-repeat':'no-repeat','background-position':'center center'}">
				<img src="/assets/2px_transparent.png" style="40px;">
			</div>
			<span *ngIf="!psi.store.image_id">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="40px" height="40px;" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M1 10c.41.29.96.43 1.5.43c.55 0 1.09-.14 1.5-.43c.62-.46 1-1.17 1-2c0 .83.37 1.54 1 2c.41.29.96.43 1.5.43c.55 0 1.09-.14 1.5-.43c.62-.46 1-1.17 1-2c0 .83.37 1.54 1 2c.41.29.96.43 1.51.43c.54 0 1.08-.14 1.49-.43c.62-.46 1-1.17 1-2c0 .83.37 1.54 1 2c.41.29.96.43 1.5.43c.55 0 1.09-.14 1.5-.43c.63-.46 1-1.17 1-2V7l-3-7H4L0 7v1c0 .83.37 1.54 1 2zm2 8.99h5v-5h4v5h5v-7c-.37-.05-.72-.22-1-.43c-.63-.45-1-.73-1-1.56c0 .83-.38 1.11-1 1.56c-.41.3-.95.43-1.49.44c-.55 0-1.1-.14-1.51-.44c-.63-.45-1-.73-1-1.56c0 .83-.38 1.11-1 1.56c-.41.3-.95.43-1.5.44c-.54 0-1.09-.14-1.5-.44c-.63-.45-1-.73-1-1.57c0 .84-.38 1.12-1 1.57c-.29.21-.63.38-1 .44v6.99z" fill="currentColor"/></svg>
			</span>
			{{psi.store.storename | titlecase}}
	  </li>
	</ul>
</div>
