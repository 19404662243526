<div class="container-fluid">
	<h1 class="text-capitalize my-3">{{category.id ? 'Actualizar':'Agregar'}} Categoría</h1>
	<form ngNativeValidate (submit)="save()">
		<div class="row">
			<div class="col-12 col-xl-6 p-0 p-xl-3 mb-3">
				<div class="card p-3">
					<div class="row p-3 aling-items-center">
						<div class="col-2 col-lg-1 px-0">
							<div class="">
								<app-image-uploader [(image)]="category.image_id" [max_width]="300" [max_width]="300" [can_be_deleted]="true" ></app-image-uploader>
							</div>
						</div>
						<div class="col-10 col-xl-11 px-0">
							<label class="">Nombre</label>
							<input type="text" name="name" [(ngModel)]="category.name" class="form-control" required>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label class="">Código</label>
							<input type="text" name="code" [(ngModel)]="category.code" class="form-control">
						</div>
						<div class="col-12">
							<label class="">Clave Producto o Servicio (SAT)</label>
							<input type="text" name="default_clave_prod_serv" [(ngModel)]="category.default_clave_prod_serv" class="form-control" list="producto_o_servicio" required>

							<datalist id="producto_o_servicio">
								<option *ngFor="let ps of productos_servicios_list" [value]="ps.value">{{ps.option}}</option>
							</datalist>
						</div>
						<div class="col-12">
							<label class="">Visibilidad</label>
							<select name="display_status" [(ngModel)]="category.display_status" class="form-control" required>
								<option value="NORMAL">Visible en venta</option>
								<option value="HIDDEN">No visible en venta</option>
							</select>
						</div>
						<div class="col-12 pt-3 text-end">
							<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
								Guardar
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-6 p-0 p-xl-3" *ngIf="category.id">
				<div class="card p-3">
					<div class="row">
						<div class="col-8">
							<h2>Subcategorías</h2>
						</div>
						<div></div>
					</div>
					<div class="my-3">
						<app-search-category (category)="onCategory($event)"></app-search-category>
					</div>
					<div class="row py-1" *ngFor="let category of sub_category_list">
						<div class="col-10">
							<img [src]="rest.getImagePath(category.image_id)" style="max-width:30px;max-height:30px" *ngIf="category.image_id">
							<span *ngIf="!category.image_id" class="default_image">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm10 10h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zM17 3c-2.206 0-4 1.794-4 4s1.794 4 4 4s4-1.794 4-4s-1.794-4-4-4zM7 13c-2.206 0-4 1.794-4 4s1.794 4 4 4s4-1.794 4-4s-1.794-4-4-4z" fill="currentColor"/></svg>
							</span>
							{{category.name | titlecase}}
						</div>
						<div class="col-2 text-end">
							<button type="button" class="btn btn-primary" (click)="removeSubcategory(category)">
								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0h120.4c12.1 0 23.2 6.848 28.6 17.69L320 32h96c17.7 0 32 14.33 32 32s-14.3 32-32 32H32C14.33 96 0 81.67 0 64s14.33-32 32-32h96l7.2-14.31zM31.1 128H416v320c0 35.3-28.7 64-64 64H95.1c-34.45 0-64-28.7-64-64V128zm80 80v224c0 8.8 8.1 16 16 16c9.7 0 16-7.2 16-16V208c0-8.8-6.3-16-16-16c-7.9 0-16 7.2-16 16zm96 0v224c0 8.8 8.1 16 16 16c9.7 0 16.9-7.2 16.9-16V208c0-8.8-7.2-16-16.9-16c-7.9 0-16 7.2-16 16zm96.9 0v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/></svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-xl-6" *ngIf="category.display_status == 'NORMAL'">
				<h2 class="my-3">Otros</h2>
				<div class="card p-3">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Estilo de Imagen</label>
							<select name="image_style" [(ngModel)]="category.image_style" class="form-control">
								<option value="CONTAIN">Centrada</option>
								<option value="COVER">Expandida</option>
							</select>
						</div>
						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Texto</label>
							<select name="text_style" [(ngModel)]="category.text_style" class="form-control">
								<option value="NEVER">Sin Texto</option>
								<option value="CENTER">Centrado</option>
								<option value="DOWN">Abajo</option>
							</select>
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Color titulo sobre imagen</label>
							<input type="color" name="text_color" [(ngModel)]="category.text_color" class="form-control">
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Color Sombra titulo</label>
							<input type="color" name="shadow_color" [(ngModel)]="category.shadow_color" class="form-control">
						</div>

						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label>
								<input class="form-check-input mt-0" name="b_transparent" type="checkbox" value="" aria-label="Checkbox for following text input" (click)="updateBackground($event)" [checked]="category.background == 'transparent'">
								Color de Fondo Transparente
							</label>
							<div>
								<input type="color" name="background" [(ngModel)]="category.background" class="form-control w-100" aria-label="Color Fondo" *ngIf="category.background!='transparent'" >
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-4 form-group">
							<label class="">Orden de prioridad</label>
							<input type="text" name="sort_weight" [(ngModel)]="category.sort_weight" class="form-control" required>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
