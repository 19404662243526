import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Price_List} from '../../models/RestModels';


@Component({
	selector: 'app-save-price-list',
	templateUrl: './save-price-list.component.html',
	styleUrls: ['./save-price-list.component.css']
})
export class SavePriceListComponent extends BaseComponent implements OnInit {

	price_list:Price_List	= {};

	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			if( params.has('id') )
			{
				this.is_loading = true;

				this.subs.sink	= this.rest.price_list.get( params.get('id') ).subscribe((price_list)=>
				{
					this.setTitle('Actulizar '+price_list.name);
					this.is_loading = false;
					this.price_list= price_list;
				},(error)=>this.showError( error ));
			}
			else
			{
				this.setTitle('Agregar lista de precios');
			}
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.price_list.id	)
		{
			this.subs.sink	= this.rest.price_list
			.update( this.price_list )
			.subscribe((price_list)=>
			{
				this.is_loading = false;
				this.router.navigate(['/list-price-list']);
				this.showSuccess('La lista de precios se actualizó exitosamente');
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.price_list.create( this.price_list ).subscribe((price_list)=>{
				this.is_loading = false;
				this.showSuccess('La lista de precios se guardó exitosamente');
				this.router.navigate(['/list-price-list']);
			},(error)=>this.showError(error));
		}
	}
}
