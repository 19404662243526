import { Component } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Category, Category_Store, Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';
import { mergeMap } from 'rxjs/operators';

interface CategoryStoreInfo
{
	store:Store;
	category_store:Category_Store;
	hash:number;
}
interface CSInfo
{
	category:Category;
	category_store_info_list:CategoryStoreInfo[];
}

@Component({
	selector: 'app-save-category-store',
	templateUrl: './save-category-store.component.html',
	styleUrls: ['./save-category-store.component.css']
})
export class SaveCategoryStoreComponent extends BaseComponent
{
	category_search:SearchObject<Category> = this.getEmptySearch();
	category_str_search:string = '';
	store_list:Store[] = [];
	cs_info_list:CSInfo[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable()
		.pipe
		(
			mergeMap((_response)=>
			{
				return forkJoin
				({
					category : this.rest.category.search( this.category_search ),
					store: this.rest.getStores(true)
				})
			}),
			mergeMap((response)=>
			{
				if( this.store_list.length  == 0 )
					this.store_list = response.store.data;

				let ids:number[] =  response.category.data.map((i:Category)=>i.id);

				return forkJoin({
					category_store: this.rest.category_store.search({limit:9999999}),
					categories: of( response.category )
				})
			})
		)
		.subscribe((response)=>
		{
			let counter = 1;
			this.cs_info_list = response.categories.data.map((category:Category)=>
			{
				let category_store_info_list:CategoryStoreInfo[] = this.store_list.map((store:Store)=>
				{
					let category_store:Category_Store = response.category_store.data.find(category_store=>{
						return category_store.category_id == category.id &&  category_store.store_id == store.id;
					});

					if( category_store == undefined )
					{
						category_store = {
							id: 0,
							store_id: store.id,
							category_id: category.id,
							pos_preference: 'DEFAULT'
						} as Category_Store;
					}
					return { store, category_store, hash: counter++ };
				});

				console.log('Que pedo', category_store_info_list );

				return {
					category,
					category_store_info_list
				} as CSInfo;
			});

			console.log( this.cs_info_list );

		},(error)=>this.showError(error));
	}

	update(cs:Category_Store, pos_preference:'SHOW' |'HIDE')
	{
		let cs_clone = { ...cs };
		cs_clone.pos_preference = pos_preference;

		this.subs.sink = this.rest.category_store.update( cs_clone )
		.subscribe((_response)=>
		{
			cs.pos_preference = pos_preference;
			this.showSuccess('La preferencia se actualizo');
		},(error)=>this.showError(error));
	}
}
