import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BillInfo } from 'src/app/models/models';
import { Bill, Store } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component
({
	selector: 'app-list-bill',
	templateUrl: './list-bill.component.html',
	styleUrls: ['./list-bill.component.css']
})

export class ListBillComponent extends BaseComponent implements OnInit
{
	store_list: Store[]		= [];
	bill_search: SearchObject<Bill> = this.getEmptySearch();
	bill_info_list: BillInfo[]		= [];

	ngOnInit(): void
	{
		this.path = '/list-bill';
		this.setTitle('Cuentas por pagar');

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((queryParamMap)=>
			{
				let fields = [ "provider_user_id","created","store_id" ];
				let extra_keys = ["paid_status"];
				this.bill_search = this.getSearch(queryParamMap, fields, extra_keys );
				this.bill_search.sort_order = ['id_DESC'];
				this.setTitle('Ordenes de compra');
				this.current_page = this.bill_search.page;

				return forkJoin
				({
					bill: this.rest.bill_info.search( this.bill_search ),
					store: this.rest.getStores(false),
					currency_rate: this.rest.getCurrencyRates(),
					providers: this.rest.user.search({eq:{type: 'USER'}, search_extra:{'user_permission.is_provider':1}})
				})
			})
		)
		.subscribe((response)=>
		{
			this.setPages( this.bill_search.page, response.bill.total );
			this.bill_info_list = response.bill.data;
			this.store_list = response.store.data;
			this.is_loading = false;
		},(error)=>this.showError(error));
	}
}
