<ng-container *ngIf="!rest.current_user || rest.current_user.type == 'CLIENT'">
	  <app-c-dashboard></app-c-dashboard>
</ng-container>
<ng-container *ngIf="rest?.current_user?.type == 'USER' && rest.current_permission.reports">
    <app-dashboard-user></app-dashboard-user>
</ng-container>

<ng-container *ngIf="rest?.current_user?.type == 'USER' && !rest.current_permission.reports ">

</ng-container>
