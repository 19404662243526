import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, merge, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Utils} from 'src/app/classes/Utils';
import {Store} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

interface BCSearch
{
	created:Date;
	end:Date;
}

@Component({
	selector: 'app-report-sells-by-category',
	templateUrl: './report-sells-by-category.component.html',
	styleUrls: ['./report-sells-by-category.component.css']
})
export class ReportSellsByCategoryComponent extends BaseComponent
{
	report_search:SearchObject<BCSearch> = this.getEmptySearch();
	report_list:Record<string,string | number>[] = [];
	store_list:Store[] = [];

	endx: string = '';
	startx: string = '';
	total: number = 0;
    total_qty: number = 0;
    last_sort: string;


	ngOnInit(): void
	{

		this.path = '/report-sells-by-category';
		this.route.queryParamMap.pipe
		(
			mergeMap((param_map: ParamMap)=>
			{
				this.setTitle('Reporte Ventas por categoría');

				return forkJoin
				({
					param_map: of(param_map),
					stores: this.rest.getStores(true)
				});
			}),
			mergeMap((response)=>
			{
				this.store_list = response.stores.data;
				this.report_search = this.getSearch(response.param_map, ['created'],['category_id','store_id','cashier_user_id','start','end']);
				let d = new Date();
				this.report_search.search_extra['offset'] = 0;//d.getTimezoneOffset()*60;
				console.log( this.report_search.search_extra );

				if( !this.report_search.ge.created )
				{
					let d = new Date();
					d.setHours(0,0,0,0);
					d.setDate(1);

					this.report_search.ge.created = d;// Utils.getUTCMysqlStringFromDate( d );
				}

				if( !this.report_search.le.created )
				{
					let x = new Date();
					let end_of_month = Utils.getEndOfMonth( x );
					end_of_month.setHours( 23, 59, 59, 0 );

					this.report_search.le.created= end_of_month;//Utils.getUTCMysqlStringFromDate( end_of_month );
				}

				this.endx	= Utils.getLocalMysqlStringFromDate( this.report_search.le.created as Date);
				this.startx	= Utils.getLocalMysqlStringFromDate( this.report_search.ge.created as Date);
				this.report_search.search_extra.start = this.report_search.ge.created;
				this.report_search.search_extra.end = this.report_search.le.created;

				return this.rest.getReporte('getSalesByCategory',this.report_search.search_extra);
			})
		)
		.subscribe((response:Record<string,string|number>[])=>
		{
			this.report_list = response

			console.log( this.report_list );
			this.total = this.report_list.reduce((a,b)=>a+(b['order_item_total']as number),0);
			this.total_qty = this.report_list.reduce((a,b)=>a+(b['order_item_qty']as number),0);
			//this.total = report_list.forEach(x=>x.total = x.qty*x.price);

		},(error)=>this.showError(error));
	}

	searchReport()
	{

		this.search( this.report_search );
	}
    print()
    {
		this.rest.hideMenu();
		setTimeout(()=>window.print(),500);
    }

	sortByCategory()
	{
		if( this.last_sort == 'category_name' )
		{
			this.report_list.sort((a,b)=>(''+b['category_name']).localeCompare(''+a['category_name']));
			this.last_sort = '';
			return;
		}
		this.report_list.sort((a,b)=>(''+a['category_name']).localeCompare(''+b['category_name']));
		this.last_sort = 'category_name';
	}

	sortByQty()
	{
		if( this.last_sort == 'order_item_qty' )
		{
			this.report_list.sort((a,b)=>(a['order_item_qty'] as number)-(b['order_item_qty'] as number));
			this.last_sort = '';
			return;
		}
		this.report_list.sort((a,b)=>(b['order_item_qty'] as number)-(a['order_item_qty'] as number));
		this.last_sort = 'order_item_qty';
	}

	sortByTotal()
	{
		if( this.last_sort == 'order_item_total' )
		{
			this.report_list.sort((a,b)=>
			{
				return b['order_item_total'] as number -(a['order_item_total'] as number);
			});
			this.last_sort = '';
			return;
		}

		this.report_list.sort((a,b)=>
		{
			return a['order_item_total'] as number -(b['order_item_total'] as number);
		});

		this.last_sort = 'order_item_total';
	}
}
