import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';
import { Question, Response, User } from 'src/app/models/RestModels';
import { AnswerInfo, FormInfo, ResponseInfo } from 'src/app/models/models';
import { forkJoin } from 'rxjs';
import { ParamMap } from '@angular/router';
import { Utils } from 'src/app/classes/Utils';


interface CResponse extends ResponseInfo
{
	canswers:AnswerInfo[];
}

@Component({
	selector: 'app-list-response',
	templateUrl: './list-response.component.html',
	styleUrls: ['./list-response.component.css']
})

export class ListResponseComponent extends BaseComponent implements OnInit
{
	response_search:SearchObject<Response> = this.getEmptySearch();
	response_list: CResponse[] = [];
	form_info: FormInfo | null = null;
	form_columns: string[] = [];
	pills:{};
	user_list:User[] = [];
	search_start_date:string = '';
	search_end_date:string = '';

	ngOnInit(): void
	{
		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((response)=>
			{
				this.setTitle('Respuestas');
				this.is_loading = true;

				let param_map:ParamMap = response[1];
				let query_param_map = response[0];

				this.path = '/list-response/'+param_map.get('form_id');

				let fields =['user_id','created_by_user_id', 'created'];
				this.response_search = this.getSearch(query_param_map, fields, []);
				this.response_search.eq.form_id = parseInt(param_map.get('form_id'));

				let start = new Date();
				let end = new Date();

				if( !query_param_map.has('ge.created') )
				{
					start.setHours(0,0,0,0);
					this.response_search.ge.created = start;
				}
				this.search_start_date = Utils.getLocalMysqlStringFromDate(this.response_search.ge.created);

				if( !query_param_map.has('le.created') )
				{
					end.setHours(23,59,59);
					this.response_search.le.created = end;
				}
				this.search_end_date = Utils.getLocalMysqlStringFromDate(this.response_search.le.created);
				
				this.current_page = this.response_search.page;

				return forkJoin
				({
					responses: this.rest.response_info.search( this.response_search ),
					form_info: this.rest.form_info.get(param_map.get('form_id')),
					users: this.rest.user.search({eq:{status: 'ACTIVE'}, limit:9999}),
				})
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				let tmp_pill = {'id':true,'user_id':false,'date':false};
				let tmp_columns = response.form_info.questions.map((q:Question)=>q.question);
				tmp_columns.forEach((x)=>{ tmp_pill[ x ] = true });

				this.pills = tmp_pill;
				this.form_columns = tmp_columns;
				console.log( this.form_columns );
				this.form_info = response.form_info;
				this.user_list = response.users.data;
				
				if( response.responses.total == 0 )
				{
					this.is_loading = false;
					return;
				}
				
				this.setPages( this.response_search.page, response.responses.total );
				this.response_list = response.responses.data.map((r:ResponseInfo)=>
				{
					let canswers:AnswerInfo[] = [];

					for(let question of this.form_info.questions)
					{
						let answer = r.answers.find((a:AnswerInfo)=>a?.answer.question_id == question.id);

						if( answer )
						{
							canswers.push(answer);
						}
						else
						{
							canswers.push
							({
								question: question,
								answer: null,
							});
						}
					}

					return { ...r, canswers }
				});

				this.is_loading = false;

			},
			error:(error)=>
			{
				this.showError(error);
			}
		});
	}

	clickPill(value:string)
	{
		if(value in this.pills )
		{
			this.pills[value] = !this.pills[value];
		}
		else
		{
			this.pills[value] = true;
		}
	}
}
