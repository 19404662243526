<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col-8">
			<h1 class="">Devoluciones Por Cliente </h1>
		</div>
		<div class="col-2 d-print-none">
			<button type="button" class="btn btn-primary w-100" (click)="export()">
				<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"/></svg>
				<span class="d-none d-lg-inline">Exportar</span>
			</button>
		</div>
		<div class="col-2 d-print-none">
			<button type="button" class="btn btn-primary w-100" (click)="print()">
				<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 7H6V3h12Zm0 5.5q.425 0 .712-.288q.288-.287.288-.712t-.288-.713Q18.425 10.5 18 10.5t-.712.287Q17 11.075 17 11.5t.288.712q.287.288.712.288ZM16 19v-4H8v4Zm2 2H6v-4H2v-6q0-1.275.875-2.137Q3.75 8 5 8h14q1.275 0 2.138.863Q22 9.725 22 11v6h-4Z"/></svg>
				<span class="d-none d-lg-inline">Imprimir</span>
			</button>
		</div>
	</div>
	<div class="card my-3 p-1 p-lg-3">
		<div class="row">
			<div class="col-6 col-md-3 form-group">
				<label>Sucursal</label>
				<select class="form-control" name="store_id" [(ngModel)]="order_search.eq.store_id">
					<option *ngFor="let store of store_list" [value]="store.id">{{store.name}}</option>
				</select>
			</div>
			<div class="col-6 col-md-3 form-group">
				<label>Inicio</label>
				<input type="date" name="date" [(ngModel)]="order_search.search_extra.start" class="form-control">
			</div>
			<div class="col-6 col-md-3 form-group">
				<label>Fin</label>
				<input type="date" name="date" [(ngModel)]="order_search.search_extra.end" class="form-control">
			</div>



			<div class="col-6 col-md-3 form-group d-print-none">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary w-100" (click)="search(order_search)">
						Buscar
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="card my-3 p-1 p-lg-3">
		<table class="border">
			<thead>
				<tr>
					<th class="border">Cliente</th>
					<th class="border">Artículo</th>
					<th class="border text-end">Entregado</th>
					<th class="border text-end">Devoluciones</th>
					<th class="border text-end d-none d-md-table-cell">Subtotal Devolucion</th>
					<th class="border text-end d-none d-md-table-cell">Subtotal Entregado</th>
					<th class="border text-end d-none d-md-table-cell">Total Descuentos</th>
					<th class="border text-end d-none d-md-table-cell">Total Devolucion</th>
					<th class="border text-end">Total Pagado</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let cco of custom_client_order_list">
					<tr>
						<th class="border" [rowSpan]="cco.custom_item_list.length">{{cco?.client?.name || 'Publico General'}}</th>
						<td class="border">
							<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES' && cco.custom_item_list[0].category">
								{{cco.custom_item_list[0].category.name}} -
							</span>
							{{cco.custom_item_list[0].item.name}}
						</td>
						<td class="border text-end">{{cco.custom_item_list[0].delivered_qty		| number:'1.0-2'}}</td>
						<td class="border text-end">{{cco.custom_item_list[0].refunded_qty		| number:'1.0-2'}}</td>
						<td class="border text-end d-none d-md-table-cell">{{cco.custom_item_list[0].refunded_amount	| currency}}</td>
						<td class="border text-end d-none d-md-table-cell">{{cco.custom_item_list[0].delivered_amount	| currency}}</td>
						<td class="border text-end d-none d-md-table-cell" [rowSpan]="cco.custom_item_list.length">{{cco.total_discount |currency}}</td>
						<td class="border text-end d-none d-md-table-cell" [rowSpan]="cco.custom_item_list.length">{{cco.total_refund | currency}}</td>
						<td class="border text-end" [rowSpan]="cco.custom_item_list.length">{{cco.total_paid |currency}}</td>
					</tr>
					<ng-container *ngFor="let ci of cco.custom_item_list;let i = index">
						<tr *ngIf="i!=0">
							<td class="border">
								<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES' && ci.category">
									{{ci.category.name}} -
								</span>
								{{ci.item.name}}
							</td>
							<td class="border text-end">{{ci.delivered_qty		| number:'1.0-2'}}</td>
							<td class="border text-end">{{ci.refunded_qty		| number:'1.0-2'}}</td>
							<td class="border text-end d-none d-md-table-cell">{{ci.refunded_amount	| currency}}</td>
							<td class="border text-end d-none d-md-table-cell">{{ci.delivered_amount - ci.refunded_amount | currency}}</td>
						</tr>
					</ng-container>
				</ng-container>
			</tbody>
		</table>
	</div>
</div>
