<div class="container-fluid">
	<h1 class="my-3">Agregar Dirección</h1>
	<div class="card p-3">
		<div class="row">
			<div class="col-md-6 form-group" >
				<label for="">Dirección</label>
				<input type="text" class="form-control" placeholder="Dirección" [ngModel]="address"	(ngModelChange)="addressChange($event)" name="address">
        <button type="button" (click)="addAddress($event)">Agregar</button>
			</div>
		</div>
		<div class="row" *ngFor="let gr of address_list">
			<div class="col-12" (click)="addressSelected(gr)">{{gr.formatted_address | json }}</div>
		</div>
		<div class="row">
			<div class="col-12">
				<a class="col-12" [routerLink]="['/c-select-map-address/',address]" [replaceUrl]="true">Seleccionar en el map</a>
			</div>
		</div>
	</div>
</div>
