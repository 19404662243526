import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-table',
  templateUrl: './error-table.component.html',
  styleUrls: ['./error-table.component.css']
})
export class ErrorTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
