import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { OrderInfo } from 'src/app/models/models';
import { Order, Price_Type, Store, User } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface CustomOrderInfo extends OrderInfo
{
	article_discount:number;
	cancelled_by_user:User | null;
}

@Component({
	selector: 'app-cancelled-orders',
	templateUrl: './cancelled-orders.component.html',
	styleUrls: ['./cancelled-orders.component.css']
})
export class CancelledOrdersComponent extends BaseComponent implements OnInit
{
	file:File | null= null;
	show_import:boolean = false;
	order_search:SearchObject<Order> = this.getEmptySearch();
	order_info_list:CustomOrderInfo[] = [];

	store_list:Store[] = [];
	price_type_list:Price_Type[] = [];
	store_dictionary:Record<number,Store> = {};
	price_type_dic:Record<number,Price_Type> = {};
	order_total:number = 0;
	show_asign_delivery_user:boolean = false;
	selected_order_info:OrderInfo | null = null;
	show_returns:boolean = false;
	cashier_user_list:User[] = [];
	show_create_shipping:boolean = false;

	fecha_inicial:string = '';
	fecha_final:string = '';
	shipping_guide: any;
	shipping_company: any;
	shipping_date: any;
	mark_as_sent:boolean = false;
	show_advanced_search:boolean = false;
	show_offline_options:boolean = false;
	show_cancel_order: boolean = false;
	cancellation_reason:string = '';

	ngOnInit()
	{
		this.path = '/cancelled-orders';
		//this.subs.sink = this.

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((query_params)=>
			{
				let fields = [ "id","client_user_id","cashier_user_id","facturado","store_id","shipping_address_id",
					"price_type_id","status","type","client_name","total","subtotal","tax","amount_paid",
					"paid_status",
					"address","suburb","city","state","zipcode","name","created_by_user_id",
					"delivery_status", "updated_by_user_id","created","updated","store_consecutive" ];

				let extra_keys:Array<string> = ['transaction_type','publico_general','advanced_search','with_discounts']; //['search_param1','project_id','some_id'];
				let order_search:SearchObject<Order> = this.getSearch(query_params, fields, extra_keys);
				console.log( order_search );
				this.setTitle('Ordenes Canceladas');
				this.is_loading = true;
				order_search.limit = this.page_size;
				this.current_page = this.order_search.page;
				order_search.sort_order = ['id_DESC'];

				console.log('advanced_search,',order_search.search_extra.advanced_search );
				this.show_advanced_search = !!order_search.search_extra.advanced_search;

				if( !(order_search.eq.price_type_id ) )
					order_search.eq.price_type_id = null;

				if( order_search.search_extra.publico_general )
				{
					order_search.is_null = ['client_user_id']
					order_search.eq.price_type_id = 1;
				}
				else
				{
					order_search.is_null = [];
				}

				if( !this.rest.current_permission.global_pos )
				{
					order_search.eq.store_id = this.rest.current_user.store_id;
				}

				//let add_fecha =!(	query_params.has('ge.status')	||
				//					query_params.has('eq.client_user_id')	||
				//					query_params.has('client_name')	||
				//					query_params.has('ge.created')	||
				//					query_params.has('eq.store_consecutive')
				//				);

				//if( add_fecha )
				//{
				//	let date = new Date();
				//	date.setHours(0);
				//	date.setMinutes(0);
				//	date.setSeconds(0);
				//	order_search.ge.created = date;
				//}

				//if( order_search.ge.created )
				//{
				//	this.fecha_inicial = Utils.getLocalMysqlStringFromDate( order_search.ge.created ).replace(' ','T');
				//}
				//else
				//{
				//	this.fecha_inicial = '';
				//}

				//if( order_search.le.created )
				//{
				//	this.fecha_final = Utils.getLocalMysqlStringFromDate( order_search.le.created ).replace(' ','T');
				//}
				//else
				//{
				//	this.fecha_final = '';
				//}

				this.order_search = order_search;
				this.order_search.eq.status = 'CANCELLED';

				return this.rest.order_info.search(this.order_search);
			}),
			mergeMap((order_data)=>
			{
				let tmp_total = order_data.data.reduce((p,c)=>p+c.order.total,0);

				return forkJoin
				({
					order : of( order_data ),
					store : this.rest.getStores(true),
					price_type : this.rest.getPriceTypes(true),
					users: this.rest.is_offline ? of({data:[], total: 0}):this.rest.user.search({limit:9999, eq:{type:'USER'},sort_order:['name_ASC']}),
					order_total: this.rest.is_offline ? of({total:tmp_total,data:[]}) : this.rest.getOrderTotal(this.order_search),
					//offline_orders: from( this.rest.getOfflineOrders() )
				})
			})
		)
		.subscribe((responses)=>
		{
			this.is_loading = false;
			responses.store.data.forEach(store=>this.store_dictionary[store.id]=store);
			responses.price_type.data.forEach(price_type=>this.price_type_dic[ price_type.id ] = price_type);
			this.store_list = responses.store.data;
			this.cashier_user_list = responses.users.data;
			this.price_type_list	= responses.price_type.data;
			this.order_total		= responses.order_total.total;
			this.order_info_list = responses.order.data.map(oi=> this.getCustomOrderInfo(oi));

			this.setPages( this.order_search.page, responses.order.total );
		},(error)=>this.showError(error));
	}

	togglePublicoGeneral(evt:Event)
	{
		let mouse_event:MouseEvent = evt as MouseEvent;
		let checkbox:HTMLInputElement = evt.target as HTMLInputElement;
		//checkbox.checked = !checkbox.checked;

		let eq = this.order_search.eq as any;
		if( !checkbox.checked )
		{
			eq.price_type_id = null;//Solo personas avanzadas no lo intenteis en casa
		}
		else
		{
			eq.price_type_id = 1;
		}

		this.order_search.search_extra.publico_general = checkbox.checked ? '1': null;
	}

	search(search_object:SearchObject<Order>)
	{
		if( !this.show_advanced_search )
		{
			console.log('FOOO NOT advanced_search');
			super.search({
				eq:{ store_id : search_object.eq.store_id },
				ge:{ created: search_object.ge.created },
				le:{ created: search_object.le.created },
				search_extra:{
					advanced_search: (this.show_advanced_search ? '1' : '')
				}
			});
			return;
		};

		search_object.csv.status = [];
		search_object.eq.client_user_id = null;
		if( this.show_advanced_search )
			search_object.search_extra.advanced_search = 1;

		console.log('not advanced_search');
		super.search( search_object )
	}

	showOrderReturnModal(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.show_returns = true;
	}

	fechaIncialChange(fecha:string)
	{
		this.fecha_inicial = fecha;
		if( fecha )
		{
			this.order_search.ge.created = Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.order_search.ge.created = null;
		}
	}

	fechaFinalChange(fecha:string)
	{
		this.fecha_final = fecha;
		if( fecha )
		{
			this.order_search.le.created = Utils.getDateFromLocalMysqlString(fecha);
		}
		else
		{
			this.order_search.le.created = null;
		}
	}

	confirmAddStockToStore(order_info:OrderInfo)
	{
		let store = this.store_list.find((store)=>store.id == order_info.client.store_id);
		let msg = 'Estas seguro de querer agregar el inventario la orden a una sucursal'+store.name;
		let title = 'Agregar Inventario a "'+store.name+'"';

		this.subs.sink = this.confirmation
		.showConfirmAlert
		(
			order_info,
			title,
			msg,
		)
		.subscribe((response)=>
		{
			if( response.accepted )
			{
				this.is_loading = true;
				this.subs.sink = this.rest.update
				(
					'addStockToStoreFromClientOrder',
					{ order_id: order_info.order.id }
				)
				.subscribe
				(
					()=>{
						this.is_loading = false;
						this.showSuccess('Se agrego el inventario a la sucursal')
					},
					(error)=>{ this.showError(error) }
				);
			}
		});
	}

	showSendToStore(order_info:OrderInfo)
	{
		this.selected_order_info = order_info;
		this.show_create_shipping = true;
	}

	confirmSendToStore(evt:Event)
	{
		let store = this.store_list.find((store)=>store.id == this.selected_order_info.client.store_id);
		this.is_loading = true;

		let params = {
			store_id: store.id,
			order_id: this.selected_order_info.order.id,
			date:	this.shipping_date,
			shipping_guide:	this.shipping_guide,
			shipping_company:	this.shipping_company,
		};

		this.subs.sink = this.rest.update('addShippingFromOrderToClientStore',params)
		.subscribe(()=>
		{

			this.showSuccess('El envio se creo exitosamente');
			this.is_loading = false;

			this.shipping_date = '';
			this.shipping_guide = '';
			this.shipping_company = '';
			this.show_create_shipping = false;

			let f = evt.target as HTMLFormElement;
			f.reset();
		},(error)=>this.showError(error));
	}

	markAsSentChange(evt:Event)
	{
		let i = evt.target as HTMLInputElement;
		this.mark_as_sent = i.checked;
	}

	syncOrders()
	{
		this.is_loading = true;
		let orders_to_sync = this.order_info_list
		.filter((oi:OrderInfo)=>
		{
			return oi.order.status == 'CLOSED';
		})
		.map((oi:OrderInfo)=>
		{
			return this.rest.proccessOfflineOrder(oi)
			.then(()=>
			{
				return Promise.resolve( 1 );
			})
			.catch((error)=>
			{
				console.log('No se actualizo la orden', error);
				return Promise.resolve( 0 );
			});
		});

		Promise.all( orders_to_sync )
		.then((response)=>
		{
			let sum = response.reduce((p,c)=>p+c,0);
			this.showSuccess('Se actualizaron '+sum+' Ordenes');
			return this.rest.getOfflineOrders();
		})
		.then((response)=>
		{
			this.order_info_list = response.map(oi=>this.getCustomOrderInfo(oi));
		})
		.catch((error)=>
		{
			this.showError( error );
		});
	}

	getCustomOrderInfo(order_info:OrderInfo):CustomOrderInfo
	{
		let article_discount = order_info.items.reduce((prev,oi)=>
		{
			//If they are equal then there isn't a discount
			if( oi.order_item.original_unitary_price == oi.order_item.unitary_price_meta )
				return prev;

			//Discount must be with the tax included

			let diff = oi.order_item.unitary_price_meta - oi.order_item.original_unitary_price;
			let tax = oi.order_item.tax == 0 ? 0 :order_info.order.tax_percent;

			//Tax exepmt
			if( tax == 0 )
				return prev+(oi.order_item.unitary_price_meta-oi.order_item.original_unitary_price)*oi.order_item.qty;

			let does_price_include_tax = oi.order_item.original_unitary_price > (oi.order_item.unitary_price+0.001);
			let price_with_tax = does_price_include_tax ? oi.order_item.unitary_price_meta : (oi.order_item.unitary_price_meta*( 1 + order_info.order.tax_percent ));
			let original_total = oi.order_item.qty* price_with_tax;

			return prev+(original_total-oi.order_item.total);
		},0);

		let user = this.cashier_user_list.find((i)=>
		{
			return order_info.order.cancelled_by_user_id == i.id;
		});

		return {
			...order_info,
			article_discount,
			cancelled_by_user: user || null
		}
	}

	toggleAdvancedSearch(evt:Event)
	{
		let input = evt.target as HTMLInputElement;
		this.show_advanced_search = input.checked
	}
}
