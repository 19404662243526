import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Commanda, Commanda_Type, Store} from '../../models/RestModels';
import {forkJoin, of} from 'rxjs';


@Component({
	selector: 'app-save-commanda',
	templateUrl: './save-commanda.component.html',
	styleUrls: ['./save-commanda.component.css']
})
export class SaveCommandaComponent extends BaseComponent implements OnInit {

	commanda:Commanda	= this.getEmptyCommanda();
	store_list:Store[] = [];
	commanda_type_list: Commanda_Type[];

	ngOnInit()
	{
		this.route.paramMap.subscribe( params =>
		{
			this.is_loading = true;
			this.subs.sink	= forkJoin({
				commanda	: params.get('id') ? this.rest.commanda.get( params.get('id') ) : of( this.getEmptyCommanda() ),
				commanda_type : this.rest.commanda_type.search({limit:9999, sort_order:['name_ASC']}),
				store		: this.rest.getStores(true)
			})
			.subscribe((response)=>
			{
				this.is_loading = false;
				this.commanda	= response.commanda;
				this.commanda_type_list = response.commanda_type.data;
				this.store_list = response.store.data;
			},(error)=>this.showError( error ));
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.commanda.id	)
		{
			this.subs.sink	= this.rest.commanda
				.update( this.commanda )
				.subscribe((_commanda:Commanda)=>
				{
					this.is_loading = false;
					this.router.navigate(['/list-commanda']);
					this.showSuccess('La comanda se actualizó exitosamente');
				},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest
				.commanda.create( this.commanda )
				.subscribe((commanda)=>
				{
					this.is_loading = false;
					this.showSuccess('la comanda se guardó exitosamente');
					this.router.navigate(['/list-commanda']);
				},(error)=>this.showError(error));
		}
	}

	getEmptyCommanda():Commanda
	{
		return {
			print_preferences: 'ONLY_DISPLAY',
			order_display_preferences: 'COMMANDA_TYPE_ORDERS',
			store_id:null
		};
	}
}
