import { Component, OnInit } from '@angular/core';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { SerialInfo } from 'src/app/models/models';
import { Serial } from 'src/app/models/RestModels';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-search-serial',
  templateUrl: './search-serial.component.html',
  styleUrls: ['./search-serial.component.css']
})
export class SearchSerialComponent extends BaseComponent implements OnInit
{

	serial_info_list: SerialInfo[] = [];
	serial_search:SearchObject<Serial> = this.getEmptySearch();

	search_string:string = '';

	ngOnInit()
	{
		this.setTitle('Buscar Serial');
		this.path = '/search-serial';

		this.subs.sink = this.getQueryParamObservable()
		.pipe
		(
			debounceTime(500),
			mergeMap(params =>
			{
				let query_params = params[0];
				let serial_search = this.getSearch(query_params,['serial_number']);

				let search = query_params.get('lk.serial_number');

				if( search && this.search_string.trim() != search.trim() )
				{
					this.search_string = search;
				}

				console.log(serial_search);

				return this.rest.serial_info.search(serial_search);
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.serial_info_list = response.data;
			},
			error:(error)=>
			{
				this.showError(error)
			}
		});
	}
	onSearchStringChange(search_string:string)
	{
		this.serial_search.lk['serial_number'] = search_string;
		this.search_string = search_string;
		this.search( this.serial_search );
		this.search_string = search_string;
		this.searchNoForceReload( this.serial_search );
	}
}
