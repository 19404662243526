import { Component } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Utils} from 'src/app/classes/Utils';
import {Order_Report} from 'src/app/models/models';
import {Store, User} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-orders-report',
	templateUrl: './orders-report.component.html',
	styleUrls: ['./orders-report.component.css']
})

export class OrdersReportComponent extends BaseComponent
{
	order_report_search:SearchObject<Order_Report> = this.getEmptySearch();
	order_report_list:Order_Report[] = [];
	store_dictionary:Record<number,Store> = {};
	user_dictionary:Record<number,User> = {};
	paid_total:number	= 0;
	grand_total:number	= 0;
	tax_total:number	= 0;
	order_count:number	= 0;

	le_created:string = '';
	ge_created:string = '';

	selected_fields:Record<string,boolean> = {
		'store_id': true,
		'cashier_user_id': false,
		'item_id': false,
		'category_id': false,
	};

	search_fields:string[] = [ "authorized_by", "billing_address_id", "billing_data_id", "cashier_user_id",
		"client_user_id", "created", "currency_id", "delivery_status", "delivery_user_id", "facturado",
		"paid_status", "price_type_id", "sat_codigo_postal", "sat_razon_social", "sat_receptor_email",
		"sat_receptor_rfc", "sat_uso_cfdi", "sat_xml_attachment_id", "service_type", "shipping_address_id",
		"state", "status", "store_id", "subtotal", "suburb", "closed_timestamp", "tag", "tax", "tax_percent",
		"total", "updated"
	];

	selectable_fields:string[] = [ "authorized_by", "billing_address_id", "billing_data_id", "cashier_user_id",
		"client_user_id", "created", "currency_id", "delivery_status", "delivery_user_id", "facturado", "paid_status",
		"price_type_id", "sat_codigo_postal", "sat_razon_social", "sat_receptor_email", "sat_receptor_rfc",
		"sat_uso_cfdi","service_type", "shipping_address_id", "state", "status", "store_id",
		"closed_timestamp", "tag", "tax", "tax_percent", "total", "updated"
	];

	search_field:string = "store_id";
	group_by:string[] = [];
	store_list: Store[] = [];

	ngOnInit(): void
	{
		this.path = '/orders-report';

		this.subs.sink = this.route.queryParamMap.pipe
		(
			mergeMap((param_map:ParamMap) =>
			{
				this.setTitle('Reporte Ventas General');

				this.order_report_search = this.getSearch( param_map, this.search_fields, ['group_by']);

				if( this.order_report_search.le.closed_timestamp)
				{
					this.le_created = Utils.getLocalMysqlStringFromDate( this.order_report_search.le.closed_timestamp).replace(' ','T');
				}
				else
				{
					this.le_created = '';
				}

				if( this.order_report_search.ge.closed_timestamp)
				{
					this.ge_created = Utils.getLocalMysqlStringFromDate( this.order_report_search.ge.closed_timestamp ).replace(' ','T');
				}
				else
				{
					this.ge_created = '';
				}
				///this.order_report_search.csv.paid_status = ['PAID','PARTIAL_PAID'];

				this.order_report_search.eq.status = 'CLOSED';

				for(let x in this.selected_fields )
					this.selected_fields[x] = false;

				if( this.order_report_search.search_extra?.group_by )
				{
					let group_by_str = this.order_report_search.search_extra.group_by as string;
					this.group_by = group_by_str.split(',');
					this.group_by.forEach(i=>this.selected_fields[i] = true);
				}
				else
				{
					this.order_report_search.search_extra.group_by = "store_id";
					this.group_by = ['store_id'];
					this.selected_fields['store_id']=true;
				}

				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, eq:{sales_enabled:1}, sort_order:['name_ASC']});

				return forkJoin
				({
					store: store_obs,
					users: this.rest.user.search({limit:999999,eq:{type:'USER'}}),
					order_report: this.rest.order_report.search(this.order_report_search)
				});
			})
		).subscribe((response) =>
		{
			response.store.data.forEach((i)=>this.store_dictionary[i.id]=i);
			this.store_list = response.store.data;
			this.user_dictionary = Utils.createDictionary( response.users.data, 'id' );
			this.order_report_list = response.order_report.data;

			this.order_report_list.forEach((order_report:Order_Report) =>
			{
				this.paid_total	+= order_report.paid_total;
				this.tax_total	+= order_report.tax_total;
				this.grand_total += order_report.grand_total;
				this.order_count += order_report.order_count;
			});
		},(error)=>this.showError(error));
	}

	toggleValue(value:string)
	{
		let index = this.group_by.indexOf( value )

		if( index > -1 )
		{
			this.group_by.splice( index,1 );
			this.selected_fields[value] = false;
			return;
		}

		this.group_by.push( value );
		this.selected_fields[value] = true;
	}
	makeASearch()
	{
		this.order_report_search.search_extra['group_by'] = this.group_by.join(',');
		this.search(this.order_report_search)
	}

	onLeCreatedChange(date:string)
	{
		if( date )
			this.le_created = date+':00';

		this.onDateChange(date,this.order_report_search.le,'closed_timestamp');
	}

	onGeCreatedChange(date:string)
	{
		if( date )
			this.ge_created = date+':00';

		this.onDateChange(date,this.order_report_search.ge,'closed_timestamp');
	}
}
