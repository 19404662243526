<div class="container-fluid">
	<div class="row align-items-center my-3">
		<div class="col-8 col-lg-10">
			<h1 class="text-capitalize" *ngIf="user">Ventas del Cliente: {{user.name}}</h1>
			<h1 class="text-capitalize" *ngIf="!user && !agent_user">Ventas</h1>
		</div>
		<div class="col-4 col-lg-2 text-end">
			<button type="button" class="btn btn-secondary" (click)="showFacturarModal($event)">Facturar</button>
		</div>
	</div>
	<div class="card p-3 my-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="d-none d-lg-block">
			<table class="mb-0 w-100">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Cliente</th>
						<th>Sucursal</th>
						<th class="d-none d-xxl-table text-center">Atendio</th>
						<th>Fecha</th>
						<th class="text-center">Estatus</th>
						<th class="text-end">Total</th>
						<th class="text-center">
							<div class="form-check form-switch">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" name="selectable_orders" [checked]="select_all" (change)="onSelectAllCheckbox($event)">
								</label>
							</div>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="price_type_list.length">
					<tr *ngFor="let oi of order_info_list" class="border align-middle">
						<td>
							<img *ngIf="oi?.client?.image_id" [src]="rest.getImagePath(oi.client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="max-width:30px;max-height:30px;margin-right:10px">
							<span *ngIf="!oi?.client?.image_id" style="margin-right:10px;">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10s10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z" fill="currentColor"/></svg>
							</span>
							<div>
								<a [routerLink]="['/view-order',oi.order.id]">{{oi.order.id}}</a>
							</div>
						</td>
						<td>
							<a [routerLink]="['/view-order',oi.order.id]">
								{{(oi?.client?.name || oi.order.client_name ) | uppercase}}
							</a>
						</td>
						<td>{{oi.store.name | titlecase }}</td>
						<td class="d-none d-xxl-table-cell">{{oi?.cashier?.name | titlecase }}</td>
						<td>
							<abbr title="{{oi.order.closed_timestamp | date: 'MMM d, y HH:mm' | shortDate}}">{{oi.order.closed_timestamp | shortDate}}</abbr>
						</td>
						<td class="text-center">
							<span *ngIf="oi.order.paid_status != 'PAID' " class="text-danger">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm.89-8.9c-1.78-.59-2.64-.96-2.64-1.9c0-1.02 1.11-1.39 1.81-1.39c1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.44-.82-1.91-2.66-2.23V5h-1.75v1.26c-2.6.56-2.62 2.85-2.62 2.96c0 2.27 2.25 2.91 3.35 3.31c1.58.56 2.28 1.07 2.28 2.03c0 1.13-1.05 1.61-1.98 1.61c-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 3.02 2.96V19h1.75v-1.24c.52-.09 3.02-.59 3.02-3.22c.01-1.39-.6-2.61-3-3.44z" fill="currentColor"/></svg>
							</span>
							<span *ngIf="oi.order.paid_status == 'PAID' " class="text-success">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm.88 15.76v.36c0 .48-.39.88-.88.88c-.48 0-.88-.39-.88-.88v-.42c-.63-.15-1.93-.61-2.69-2.1c-.23-.44-.01-.99.45-1.18l.07-.03c.41-.17.87 0 1.08.39c.32.61.95 1.37 2.12 1.37c.93 0 1.98-.48 1.98-1.61c0-.96-.7-1.46-2.28-2.03c-1.1-.39-3.35-1.03-3.35-3.31c0-.1.01-2.4 2.62-2.96v-.36c0-.49.4-.88.88-.88s.88.39.88.88v.37c1.07.19 1.75.76 2.16 1.3c.34.44.16 1.08-.36 1.3c-.36.15-.78.03-1.02-.28c-.28-.38-.78-.77-1.6-.77c-.7 0-1.81.37-1.81 1.39c0 .95.86 1.31 2.64 1.9c2.4.83 3.01 2.05 3.01 3.45c0 2.63-2.5 3.13-3.02 3.22z" fill="currentColor"/></svg>
							</span>
						</td>
						<td class="text-end border-left ">{{oi.article_discount | currency}}</td>
						<td class="text-end d-none d-xxl-table-cell">{{oi.order.discount | currency}}</td>
						<td class="text-end border-right d-none d-xxl-table-cell">{{(oi.order.discount+oi.article_discount) | currency}}</td>
						<td class="text-end">{{oi.order.total-oi.order.discount | currency:'$' }}</td>
						<td class="text-end form-check form-switch">
							<div class="form-check form-switch">
								<label class="form-check-label">
									<input type="checkbox" class="form-check-input" [name]="'selectable_orders'+oi.order.id" [checked]="oi.selected" (change)="onSelectOrder($event,oi)">
								</label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<app-modal [(show)]="show_facturar_modal">
	<form (submit)="save($event)" ngNativeValidate>
		<div class="p-3">
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row" *ngIf="first_order_info">
				<div class="col-12">
					<div class="my-3">
						<h4>Datos Sucursal</h4>
					</div>
					<div class="row">
						<div class="form-group col-12 col-lg-6">
							<label>Código Postal Emisor</label>
							<input class="form-control" type="text" name="sat_codigo_postal" [(ngModel)]="first_order_info.order.sat_codigo_postal" required>
						</div>
						<div class="form-group col-12 col-lg-6">
							<label>Serie</label>
							<input class="form-control" type="text" name="sat_serie" [(ngModel)]="sat_serie" required>
						</div>
						<div class="form-group col-12">
							<label class="">Datos Facturación</label>
							<select class="form-control" name="default_billing_data_id" [(ngModel)]="first_order_info.order.billing_data_id" (ngModelChange)="onBillingDataChange($event)" class="form-control" required>
								<option [ngValue]="null">Seleccionar</option>
								<option *ngFor="let c of billing_data_list" [value]="c.id">{{c.rfc}}</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-12">
					<div class="my-3">
						<h4>Datos del cliente</h4>
					</div>
					<div class="row">

						<div class="col-12 col-md-6 form-group">
							<label>Metodo de Pago</label>
							<select class="form-control" name="metodo_pago" [(ngModel)]="sat_metodo_de_pago">
								<option value="PUE">Pago en una sola exibicion - PUE</option>
								<option value="PPD">Pago en parcialidades o diferido - PPD</option>
							</select>
						</div>
						<div class="col-12 col-md-6 form-group">
							<label>Forma de pago</label>
							<select class="form-control" name="forma_de_pago" [(ngModel)]="sat_forma_de_pago">
								<option value="99">Por Definir</option>
								<option value="01">Efectivo</option>
								<option value="02">Cheque nominativo</option>
								<option value="03">Transferencia electrónica de fondos</option>
								<option value="04">Tarjeta de crédito</option>
								<option value="05">Monedero electrónico</option>
								<option value="06">Dinero electrónico</option>
								<option value="08">Vales de despensa</option>
								<option value="12">Dación en pago</option>
								<option value="13">Pago por subrogación</option>
								<option value="14">Pago por consignación</option>
								<option value="15">Condonación</option>
								<option value="17">Compensación</option>
								<option value="23">Novación</option>
								<option value="24">Confusión</option>
								<option value="25">Remisión de deuda</option>
								<option value="26">Prescripción o caducidad</option>
								<option value="27">A satisfacción del acreedor</option>
								<option value="28">Tarjeta de débito</option>
								<option value="29">Tarjeta de servicios</option>
								<option value="30">Aplicación de anticipos</option>
								<option value="31">Intermediario pagos</option>
							</select>
						</div>

						<div class="col-12 col-md-6 form-group">
							<label>Razón Social</label>
							<input class="form-control" type="text" name="razon_social" [(ngModel)]="sat_razon_social" (ngModelChange)="onChangeAddress($event)" autocomplete="organization" placeholder="Mi Empresa" list="sat_rfc_list" required>
						</div>

						<datalist id="sat_rfc_list">
							<option value="XAXX010101000">PUBLICO GENERAL</option>
							<option *ngFor="let a of address_list" [value]="a.name">a.name</option>
						</datalist>

						<div class="col-12 col-md-6 form-group">
							<label>Régimen Capital</label>
							<input class="form-control" type="text" name="sat_regimen_capital" [(ngModel)]="sat_regimen_capital" placeholder="Ej: SA de CV">
						</div>

						<div class="col-12 col-lg-6 form-group">
							<label>RFC receptor</label>
							<input class="form-control" type="text" name="sat_receptor_rfc" [(ngModel)]="sat_receptor_rfc" list="sat_rfc_list" required>
							<datalist id="sat_rfc_list">
								<option value="XAXX010101000">PUBLICO GENERAL</option>
								<option *ngFor="let a of address_list" [value]="a.rfc">a.name</option>
							</datalist>
						</div>

						<div class="col-12 col-md-6 form-group">
							<label>Código postal del Domicilio Fiscal</label>
							<input class="form-control" type="text" name="domicilio_fiscal" [(ngModel)]="sat_domicilio_fiscal_receptor" placeholder="Ej: 22800" requrired>
						</div>

						<div class="col-6 form-group">
							<label>Regimen Fiscal</label>
							<select class="form-control" name="regimen_fiscal" [(ngModel)]="sat_regimen_fiscal_receptor" required>
								<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
								<option value="606">606 - Arrendamiento</option>
								<option value="609">609 - Consolidación</option>
								<option value="624">624 - Coordinados</option>
								<option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
								<option value="608">608 - Demás ingresos</option>
								<option value="630">630 - Enajenación de acciones en bolsa de valores</option>
								<option value="601">601 - General de Ley Personas Morales</option>
								<option value="628">628 - Hidrocarburos</option>
								<option value="621">621 - Incorporación Fiscal</option>
								<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
								<option value="614">614 - Ingresos por intereses</option>
								<option value="623">623 - Opcional para Grupos de Sociedades</option>
								<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
								<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
								<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
								<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
								<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
								<option value="626">626 - Régimen Simplificado de Confianza</option>
								<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
								<option value="616">616 - Sin obligaciones fiscales</option>
								<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
								<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
							</select>
						</div>


						<div class="col-12 col-lg-6 form-group">
							<label>Uso CFDI</label>
							<select name="sat_uso_cfdi" class="form-control" [(ngModel)]="sat_uso_cfdi" list="usos_cfdi_list" required>
								<option value="G01">G01 - Adquisición de mercancías</option>
								<option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
								<option value="G03">G03 - Gastos en general</option>
								<option value="I01">I01 - Construcciones</option>
								<option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
								<option value="I03">I03 - Equipo de transporte</option>
								<option value="I04">I04 - Equipo de cómputo y accesorios</option>
								<option value="I05">I05 - Dados, troqueles, moldes, matrices y herramental</option>
								<option value="I06">I06 - Comunicaciones telefónicas</option>
								<option value="I07">I07 - Comunicaciones satelitales</option>
								<option value="I08">I08 - Otra maquinaria y equipo</option>
								<option value="D01">D01 - Honorarios médicos, dentales y gastos hospitalarios.</option>
								<option value="D02">D02 - Gastos médicos por incapacidad o discapacidad</option>
								<option value="D03">D03 - Gastos funerales.</option>
								<option value="D04">D04 - Donativos</option>
								<option value="D05">D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
								<option value="D06">D06 - Aportaciones voluntarias al SAR.</option>
								<option value="D07">D07 - Primas por seguros de gastos médicos.</option>
								<option value="D08">D08 - Gastos de transportación escolar obligatoria.</option>
								<option value="D09">D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
								<option value="D10">D10 - Pagos por servicios educativos (colegiaturas)</option>
								<option value="P01">P01 - Por definir</option>
								<option value="S01">S01 - Sin efectos Fiscales</option>
							</select>
						</div>
						<div class="col-12 col-lg-6 form-group">
							<label>Nota</label>
							<textarea name="sat_nota" [(ngModel)]="sat_nota" class="form-control"></textarea>
						</div>
						<div class="col-12 form-group">
							<label>Email Receptor</label>
							<input	class="form-control"type="text" name="sat_receptor_email" [(ngModel)]="sat_receptor_email" required>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12 text-end">
					<button type="button" class="mx-1 btn btn-danger" (click)="show_facturar_modal = false">Cerrar</button>
					<button type="submit" class="mx-1 btn btn-primary" [disabled]="is_loading">Facturar</button>
				</div>
			</div>
		</div>
	</form>
</app-modal>
