import { Component } from '@angular/core';
import {GeocodingResult} from 'src/app/models/models';
import {Platform_Address} from 'src/app/models/PlatformModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-save-address',
	templateUrl: './client-save-address.component.html',
	styleUrls: ['./client-save-address.component.css']
})
export class ClientSaveAddressComponent extends BaseComponent
{
	platform_address:Partial<Platform_Address> | null = null;
	geolocation_watch_id:number | null = null;
	geolocation_position:GeolocationPosition | null = null;
	address_list:GeocodingResult[] = [];
	address:string = '';

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.subscribe((paramMap)=>
		{
			this.platform_address= null;
		})

		if('geolocation' in navigator)
		{
			/* geolocation is available */
			const options = {
				enableHighAccuracy: true,
				maximumAge: 30000,
				timeout: 27000
			};

			this.geolocation_watch_id = navigator.geolocation.watchPosition((p)=>this.processPosition(p),(error)=>{},options );
		}

	}

	setCurrentLocation()
	{
		if( this.geolocation_position)
		{
			this.subs.sink = this.rest.getAddressFromGeolocationPosition( this.geolocation_position)
			.subscribe((geocoding_response)=>
			{

				let geocoding_result:GeocodingResult = geocoding_response.results[0];

				console.log( geocoding_result.formatted_address );
				this.platform_address = {
					address: geocoding_result.formatted_address,
					lat: geocoding_result.geometry.location.lat,
					lng: geocoding_result.geometry.location.lng
				};

				//geocoding_result.address_components.forEach((geocoding_address_component:GeocodingAddressComponent)=>
				//{
				//	if( geocoding_address_component.types.includes('country') )
				//	{
				//		console.log('Detectado pais');
				//		this.country_suggestion =	geocoding_address_component.long_name;
				//	}
				//	if( geocoding_address_component.types.includes('administrative_area_level_1') )
				//	{
				//		console.log('Detectado estado');
				//		this.state_suggestion =	geocoding_address_component.long_name;
				//	}
				//	if( geocoding_address_component.types.includes('locality') )
				//	{
				//		console.log('Detectado ciudad');
				//		this.city_suggestion =	geocoding_address_component.long_name;
				//	}

				//	if( geocoding_address_component.types.includes('postal_code') )
				//	{
				//		console.log('codigo_+postal');
				//		this.zipcode_suggestion =	geocoding_address_component.long_name;
				//	}
				//}
			},(error)=>
			{
				this.showError('No se pudo obtener la información de la posición actual');
			});

//			this.rest.getAddressFromGeolocationPosition(geolocation_position:GeolocationPosition):Observable<GeocodingResponse>
//	{
//		let latlng:string = geolocation_position.coords.latitude+','+geolocation_position.coords.longitude;
//		return this.http.get<GeocodingResponse>('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latlng+'&location_type=ROOFTOP&key='+this.GEOLOCATION_KEY);
//	}
			//this.address_list.push
			//({
			//	address_type:'current_location',
			//	latitude:this.coords.latitude,
			//	longitude:this.coords.longitude
			//});
		}
	}

	addAddress(evt: MouseEvent)
	{
		evt.preventDefault();
		evt.stopPropagation();

		this.platform_address = {
			id: 0,
			address: this.address,
			lat: null,
			lng: null
		};

		this.subs.sink = this.rest.platform_address
		.create(this.platform_address)
		.subscribe((response)=>
		{
			this.router.navigate(['/c-cart']);
			this.showSuccess('La direccion se guardo exitosamente');
		},(error)=>this.showError(error));
	}

	addressChange(address:string)
	{
		this.address = address;
		this.platform_address = null;
		this.rest.getLocationFromAddress(address)
		.subscribe((response)=>
		{
			this.address_list = response.results;
		},(error)=>this.showError(error));
	}

	addressSelected(address:GeocodingResult)
	{
		this.platform_address = {
			address: address.formatted_address,
			lat: address.geometry.location.lat,
			lng: address.geometry.location.lng
		};

		this.subs.sink = this.rest.platform_address
		.create(this.platform_address)
		.subscribe((response)=>
		{
			this.router.navigate(['/c-cart']);
			this.showSuccess('La direccion se guardo exitosamente');
		},(error)=>this.showError(error));
	}

	ngOnDestroy(): void
	{
		if(this.geolocation_watch_id)
		{
			navigator.geolocation.clearWatch(this.geolocation_watch_id);
			this.geolocation_watch_id = null;
		}

		super.ngOnDestroy();
	}

	processPosition(position:GeolocationPosition)
	{
		this.geolocation_position = position;
	}
}

