<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-9 col-xl-10">
			<h1 class="my-3 text-capitalize">{{quote_info.quote.id ? 'Actualizar':'Nueva'}} Cotización</h1>
		</div>
		<div class="col-3 col-xl-2 text-end">
			<a class="btn btn-secondary" [routerLink]="['/list-quotes']">
				<svg xmlns="http://www.w3.org/2000/svg" width="0.88em" height="1em" viewBox="0 0 21 24"><path fill="currentColor" d="M19.502 0H1.498C.67 0 0 .671 0 1.498v21.004C0 23.33.671 24 1.498 24H19.5c.828 0 1.498-.671 1.498-1.498V1.498c0-.827-.67-1.497-1.497-1.498zM6 21H3v-3h3zm0-4.5H3v-2.998h3zM6 12H3V9.001h3zm6 9H9v-3h3zm0-4.5H9v-2.998h3zm0-4.5H9V9.001h3zm6 9h-3V9h3zm0-13.92H3V3h15z"/></svg>
				<span class="d-none d-md-inline"> Cotizaciones</span>
			</a>
		</div>
	</div>
	<form	(submit)="save($event)" ngNativeValidate>
		<div class="card p-3">
			<div class="div dropdown">
				<div class="row my-1 align-items-center">
					<div class="col-10 col-md-11">
						<div class="form-group">
							<input type="text" class="form-control mt-2" name="client_name" [(ngModel)]="search_client_str" placeholder="Nombre Cliente" autocomplete="off" (keyup)="onClientSearch($event)" [disabled]="!!quote_info.quote.id">
						</div>
					</div>
					<div class="col-2 col-md-1 px-0">
						<span style="line-height:38px">
							<svg *ngIf="!user_client?.image_id" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="38" height="38" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2S7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" fill="currentColor"/></svg>
						</span>
						<img *ngIf="user_client?.image_id" [src]="rest.getImagePath(user_client?.image_id,rest.local_preferences.default_user_logo_image_id)" style="margin-top:4px" width="38" height="38">
					</div>
				</div>
				<ul class="dropdown-menu show w-100" *ngIf="client_list.length || show_client_list">
					<li>
						<button type="button" class="dropdown-item" (click)="noRegistrarUsuario()">No Registrar</button>
					</li>
					<li *ngFor="let client of client_list">
						<button type="button" class="dropdown-item" (click)="onSelectClient(client)">
							<img [src]="rest.getImagePath(client.image_id,rest.local_preferences.default_user_logo_image_id)" width="30px" height="30px">
							{{client.name | titlecase}} - {{client.phone}}
						</button>
					</li>
				</ul>
			</div>

			<div class="row px-1 py-3">
				<div class="col-12 col-md-3">
					<label class="">Sucursal</label>
					<select name="store_id" [ngModel]="store_id" (ngModelChange)="onSucursalChange($event)" class="form-control" required>
						<option value="">Seleccionar</option>
						<option *ngFor="let c of store_list" [value]="c.id" [selected]="store_id==c.id">{{c.name}}</option>
					</select>
				</div>
				<!--div class="col-6 col-md-4">
					<label class="">Cliente</label>
					<input type="number" name="client_user_id" [(ngModel)]="quote_info.quote.client_user_id" class="form-control">
				</div-->
				<!--div class="col-6 col-md-4">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="quote_info.quote.name" class="form-control" required>
				</div-->
				<div class="col-12 col-md-3">
					<label class="">Correo <span class="d-none d-sm-inline">Electrónico</span></label>
					<input type="email" name="email" [(ngModel)]="quote_info.quote.email" class="form-control">
				</div>

				<div class="col-12 col-md-3">
					<label class="">Teléfono</label>
					<input type="tel" name="phone" [(ngModel)]="quote_info.quote.phone" class="form-control" required>
				</div>

				<div class="col-6 col-md-3">
					<label class="">Tipo de consumo</label>
					<select name="price_type" [(ngModel)]="quote_info.quote.price_type_id" class="form-control">
						<option *ngFor="let price_type of price_type_list" [value]="price_type.id">{{price_type.name}}</option>
					</select>
				</div>

				<div class="col-6 col-md-2">
					<label class="">IVA</label>
					<input type="number" name="tax_percent" [ngModel]="quote_info.quote.tax_percent" (ngModelChange)="onTaxPercentChange($event)" class="form-control" list="tax_list" required>
					<datalist id="tax_list">
						<option value="16">16%</option>
						<option value="8">8%</option>
					</datalist>
				</div>

				<div class="col-6 col-md-3">
					<label class="">Valido hasta:</label>
					<input type="date" name="valid_until" [(ngModel)]="quote_info.quote.valid_until" class="form-control" required>
				</div>

				<div class="col-6 col-md-2">
					<label> Tipo de cambio </label>
					<select name="select" [(ngModel)]="quote_info.quote.currency_id" class="form-control" [disabled]="quote_info.items.length>0">
						<option *ngFor="let currency of currency_list" [value]="currency.id">{{currency.name}}</option>
					</select>
				</div>

				<div class="col-12 col-md-5">
					<label class="">Nota</label>
					<input type="text" name="note" [(ngModel)]="quote_info.quote.note" class="form-control">
				</div>
			</div>
			<div>
				<app-loading [is_loading]="is_loading"></app-loading>
			</div>
		</div>
		<h2 class="my-3">Artículos</h2>
		<div class="card p-3 mt-3">
			<div class="row" *ngIf="quote_info.quote.currency_id">
				<div class="col-12 col-lg-10">
					<div class="mb-3 w-100">
						<div class="dropdown">
							<input type="text" name="input_search" [(ngModel)]="input_search" (input)="onInput($event)" class="form-control" placeholder="Buscar Artículo" autocomplete="off">
							<ul class="dropdown-menu show w-100" *ngIf="item_info_list.length">
								<li *ngFor="let item_info of item_info_list" class="border-bottom">
									<button type="button" class="dropdown-item" (click)="onItemInfoSelect(item_info)">
										<img [src]="rest.getImagePath(item_info.item.image_id,item_info?.category?.image_id,rest.local_preferences.default_product_image_id)" style="height:44px;width:44px;">
										{{item_info?.category?.name}} {{item_info.item.name}}
										<span *ngIf="item_info.stock_record && item_info.stock_record.qty>0" class="fw-bold">
											[{{item_info.stock_record.qty}}]
										</span>
										<span *ngIf="!item_info.stock_record || item_info.stock_record.qty<0.001" style="color:red!important" class="fw-bold">[0]</span>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!--div class="col-4 col-lg-2 text-end">
					<button type="button"	[disabled]="is_loading" class="btn btn-primary" (click)="show_add_new=true">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/></g></svg>
						Nuevo Artículo
					</button>
				</div-->
			</div>
			<div class="row fw-bold d-none d-md-flex pb-3 border-bottom mb-3">
				<div class="col-3">Artículo</div>
				<div class="col-1">Referencia</div>
				<div class="col-2">Precio Unitario</div>
				<div class="col-2">Cantidad</div>
				<div class="col-2">Iva</div>
				<div class="col-1 text-end">Total</div>
				<div class="col-1 text-end"></div>
			</div>
			<ng-container *ngFor="let pdi of quote_info.items;let i=index">
				<div *ngIf="pdi.quote_item.status == 'ACTIVE'" class="row align-items-center border-bottom border-md-none pb-3 pb-md-1 mb-3 mb-md-1">
					<div class="col-10 col-md-3 fw-bold order-0 my-2">
						<img [src]="rest.getImagePath(pdi.item.image_id,pdi?.category?.image_id,rest.local_preferences.default_product_image_id)" style="height:44px;width:44px;">
						<span *ngIf="pdi?.category">{{pdi.category.name}} - </span>
						{{pdi.item.name}}
					</div>

					<div class="col-6 fw-12 d-md-none order-3">Precio Referencia</div>
					<div class="col-6 col-md-1 order-3 order-md-2 my-2 text-end">
						{{pdi.item.reference_price*rest.getExchangeRate(quote_info.quote.currency_id, quote_info.store.default_currency_id, pdi.item.currency_id, currency_rate_list) | currency }}
						{{quote_info.quote.currency_id}}
					</div>

					<div class="col-6 col-md-2 order-2 my-2">
						<label class="d-md-none">Precio</label>
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text">$</span>
							</div>
							<input type="number" [name]="'original_unitary_price'+pdi.item.id+'-'+i" [ngModel]="pdi.quote_item.original_unitary_price"	(ngModelChange)="onPriceChange(pdi,$event)" class="form-control text-end" step="any" min="0" required>
						</div>
					</div>

					<div class="col-6 col-md-2 order-2 my-2">
						<label class="d-md-none">Cantidad</label>
						<input type="number" [name]="'qty'+pdi.item.id+'-'+i" [ngModel]="pdi.quote_item.qty" (ngModelChange)="onQtyChange(pdi,$event)" class="form-control text-end" [step]="pdi.item.partial_sale == 'NO'?'1':'any'" required>
					</div>

					<div class="col-8 col-md-2 order-2 my-2">
						<label class="d-md-none">Iva</label>
						<select class="form-control" [name]="'tax_included'+pdi.item.id+'-'+i" [ngModel]="pdi.quote_item.tax_included" (ngModelChange)="onTaxIncludedChange(pdi,$event)">
							<option value="YES">IVA Incluido</option>
							<option value="NO">Más IVA</option>
						</select>
					</div>
					<div class="col-6 d-md-none fw-bold order-3">Total</div>
					<div class="col-6 col-md-1 fw-bold order-3 my-2 text-end">
						{{pdi.quote_item.total | currency: '$'}}
					</div>

					<div class="col-2 my-2 col-md-1 fw-bold text-end	order-1 order-md-5">
						<button type="button" class="btn btn-primary" (click)="removeItem(pdi)">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M21.5 6a1 1 0 0 1-.883.993L20.5 7h-.845l-1.231 12.52A2.75 2.75 0 0 1 15.687 22H8.313a2.75 2.75 0 0 1-2.737-2.48L4.345 7H3.5a1 1 0 0 1 0-2h5a3.5 3.5 0 1 1 7 0h5a1 1 0 0 1 1 1zm-7.25 3.25a.75.75 0 0 0-.743.648L13.5 10v7l.007.102a.75.75 0 0 0 1.486 0L15 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zm-4.5 0a.75.75 0 0 0-.743.648L9 10v7l.007.102a.75.75 0 0 0 1.486 0L10.5 17v-7l-.007-.102a.75.75 0 0 0-.743-.648zM12 3.5A1.5 1.5 0 0 0 10.5 5h3A1.5 1.5 0 0 0 12 3.5z" fill="currentColor"/></g></svg>
						</button>
					</div>
				</div>
			</ng-container>

			<div class="row align-items-center border-bottom border-md-none pb-3 pb-md-1 mb-3 mb-md-1">
				<div class="col-10 fw-bold text-end">
					Total
				</div>
				<div class="col-2 fw-bold">
					{{this.total | currency: '$'}} {{quote_info.quote.currency_id}}
				</div>
			</div>

			<div class="text-end my-3">
				<button type="submit"	[disabled]="is_loading || quote_info.items.length == 0" class="btn btn-primary" [disabled]="is_loading">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
					Guardar
				</button>
			</div>
		</div>
	</form>
</div>
