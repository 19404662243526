import { Component, OnInit } from '@angular/core';
import {forkJoin, from, of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {OrderInfo,OrderItemInfo, PaymentInfo, ResponseInfo} from 'src/app/models/models';
import {Billing_Data, Currency_Rate, Form, Installment, Response, Store, User} from 'src/app/models/RestModels';
import {SearchObject} from 'src/app/services/Rest';
import {BaseComponent} from '../base/base.component';
import {environment} from 'src/environments/environment';
import { GetEmpty } from 'src/app/classes/Empties';
import { Utils } from 'src/app/classes/Utils';

type cstatus = 'PENDIENTE' | 'PAGADO' | 'VENCIDO';

interface CInstallment extends Installment
{
	due_status:cstatus;
}

interface CFormRespomse
{
	form:Form;
	response:Response[];
}

@Component({
	selector: 'app-view-order',
	templateUrl: './view-order.component.html',
	styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent extends BaseComponent implements OnInit
{

	sat_forma_pago:string = '';

	order_info:OrderInfo = GetEmpty.orderInfo(this.rest);
	show_agregar_marbetes:boolean = false;
	selected_order_item_info:OrderItemInfo | null = null;
	show_facturar:boolean = false;
	show_add_payment_to_order:boolean = false;
	billing_data_list:Billing_Data[] = [];
	payment_info_list: PaymentInfo[] = [];
	currency_rate_list:Currency_Rate[] = [];
	client_order_info_list:OrderInfo[] = [];
	cancel_user:User | null = null;
	fecha_pago:string = '';

	reenviar_factura_name:string = '';
	reenviar_factura_email:string = '';
	show_reenviar_factura:boolean = false;
	environment = environment;

	cinstallment_list:CInstallment[] = [];
	cformresponse_list:CFormRespomse[] = [];

	pago_factura_email:string = '';

	payment_type_dic:Record<string,string> =
	{
		'CASH': 'Efectivo',
		'CREDIT_CARD': 'Tarjeta Credito',
		'DEBIT_CARD' : 'Tarjeta Debito',
		'PAYPAL' : 'Paypal',
		'CHECK'		: 'Cheque',
		'DISCOUNT'	: 'Descuento',
		'RETURN_DISCOUNT':'Descuento por devolución',
		'TRANSFER'	: 'Transferencia',
	};
	show_refacturar: boolean;
	store: Store = GetEmpty.store();
	cancelar_factura_enabled: boolean = false;

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap(paramMap=>
			{
				this.setTitle('Orden #'+paramMap.get('id'));
				this.path = '/view-order/'+paramMap.get('id');
				this.is_loading = true;

				let cdate = new Date();
				cdate.setSeconds(0);

				let date = Utils.getLocalMysqlStringFromDate(cdate);
				this.fecha_pago = Utils.getLocalMysqlStringFromDate(cdate).replace(' ','T');

				let search:SearchObject<Billing_Data> = this.getEmptySearch();
				search.limit = 99999;

				console.log('Has offline',paramMap.get('offline'));

				return forkJoin
				({
					order_info: paramMap.has('offline')
						? from(this.rest.getOfflineOrderInfoBySyncId( paramMap.get('sync_id') ) )
						: this.rest.order_info.get( paramMap.get('id')),
					billing_data: paramMap.has('offline') ? of({total:0, data:[]}) : this.rest.getBillingData(false),
					payment:
						paramMap.has('offline')
						? from(this.rest.getPaymentInfoByOrderSyncId( paramMap.get('sync_id') ) )
						: this.rest.payment_info.search({search_extra:{order_id:paramMap.get('id')}}),
					price_type: this.rest.getPriceTypes(false),
					currency_rate: this.rest.getCurrencyRates(),
					store: this.rest.store.get(this.rest.current_user.store_id)
				});
			}),
			mergeMap( responses =>
			{
				this.store = responses.store;
				let installments_obs = responses.order_info.order.installment_months > 0
					? this.rest.installment.search({ eq: { order_id: responses.order_info.order.id, status: 'ACTIVE' }, limit: 9999})
					: of({total:0, data:[]});

				//check for the forms id in the order items who have item.form_id
				let form_ids = [];

				responses.order_info.items.forEach(item=>
				{
					if (item.item.form_id)
					{
						form_ids.push(item.item.form_id);
					}
				});

				let reponses_obs = form_ids.length > 0
					? this.rest.response_info.search({ csv: { form_id: form_ids }, eq:{user_id: responses.order_info.client.id}, limit: 9999})
					: of({total:0, data:[]});

				return forkJoin({
					order_info: of(responses.order_info),
					billing_data: of(responses.billing_data),
					payment: of(responses.payment),
					price_type: of(responses.price_type),
					currency_rate: of(responses.currency_rate),
					installments: installments_obs,
					response: reponses_obs
				});
			})
		)
		.subscribe(responses=>
		{
			console.log('LLego la informacion del orden info', responses.order_info);
			//console.log('cformResponse final', responses.response);
			let price_type	= responses.price_type.data.find((pt)=>pt.id == responses.order_info.order.price_type_id );

			let rango_para_cancelar = 60*60*24*1000; //En milisegundos

			responses.order_info.price_type = price_type;
			this.order_info = responses.order_info;

			//
			this.cancelar_factura_enabled = this.order_info.order.facturacion_timestamp != null &&
			(
				this.order_info.order.total < 1000 ||
				( Date.now() - this.order_info.order.facturacion_timestamp.getTime() ) < rango_para_cancelar
			);

			if (this.order_info.order.cancelled_by_user_id)
			{
				this.subs.sink = this.rest.user.get(this.order_info.order.cancelled_by_user_id).subscribe((cancel_user)=>
				{
					this.cancel_user = cancel_user;
				},
				(error)=>this.showError('Error obteniendo usuario que cancelo: ' + error));
			}
			this.billing_data_list = responses.billing_data.data;
			this.payment_info_list = responses.payment.data;
			this.reenviar_factura_email = this.order_info.order.sat_receptor_email;
			this.reenviar_factura_name	= this.order_info?.client?.name || this.order_info.order.client_name;
			this.currency_rate_list = responses.currency_rate.data;
			this.pago_factura_email = this.order_info.order.sat_receptor_email;

			if(this.order_info.order.client_user_id)
			{
				this.client_order_info_list.push(this.order_info);
			}

			if(responses.installments.total > 0)
			{
				this.cinstallment_list = responses.installments.data.map((installment:Installment)=>
				{
					let due_status:cstatus = 'PENDIENTE';

					if (!installment.paid_timestamp && new Date(installment.due_date) < new Date())
					{
						due_status = 'VENCIDO';
					}

					if (installment.paid_timestamp)
					{
						due_status = 'PAGADO';
					}

					return {...installment, due_status};
				});
			}

			if ( responses.response.total > 0 )
			{
				responses.response.data.forEach((response_info:ResponseInfo)=>
				{
					let form_response = this.cformresponse_list.find((fr)=>fr.form.id == response_info.form.id);
					if (!form_response)
					{
						form_response = {form:response_info.form, response:[]};
						this.cformresponse_list.push(form_response);
					}
					form_response.response.push(response_info.response);
				});
			}
			this.is_loading = false;
		},error=>this.showError(error));
	}

	showFacturar(_evt:Event)
	{
		this.show_facturar = true;
	}

	onTerminoFacturar(withSuccess:boolean)
	{
		if( withSuccess )
		{
			this.subs.sink = this.rest.order_info.get( this.order_info.order.id )
			.subscribe( (order_info)=>
			{
				this.order_info = order_info;
			})
		}
		this.show_facturar = false;
	}

	showPaymentForm()
	{
		this.show_add_payment_to_order = true;
	}

	onPayment(payment_info:PaymentInfo | null)
	{
		this.show_add_payment_to_order = false;

		if( payment_info != null )
		{
			this.router.navigateByUrl('/',{skipLocationChange: true})
			.then(()=>
			{
				this.router.navigate([this.path],{queryParams: {}});
			});
		}
	}
	//facturar(_evt:Event)
	//{
	//	this.subs.sink = this.rest
	//	.facturar(this.order_info.order.id)
	//	.subscribe(()=>
	//	{
	//		this.showSuccess('Facturación exitosa');
	//		this.show_facturar = false;
	//		this.subs.sink = this.rest.order_info.get( this.order_info.order.id ).subscribe((response)=>{
	//			this.order_info = response;
	//		});
	//	},(error)=>{this.showError(error)});
	//}

	showPdfFile(attachment_id:number)
	{
		window.location.href = this.rest.getFilePath(attachment_id);
	}

	cancelOrder(_evt:Event)
	{
		this.subs.sink = this.rest.cancelOrder( this.order_info.order.id )
		.subscribe(()=>
		{
			this.showSuccess('La orden se cancelo con exito');
			this.location.back();
		});
	}

	getPdfUrl(order_id:number)
	{
		return this.rest.getApiUrl()+'/getFacturaPdf.php?order_id='+order_id;
	}

	getPaymentPdfUrl(order_id:number)
	{
		return this.rest.getApiUrl()+'/getFacturaPdf.php?payment_id='+order_id;
	}

	resendFactura(evt:Event)
	{
		this.is_loading = true;
		let x:any = evt;

		this.subs.sink = this.rest.reenvia_factura.create
		({
			order_id: this.order_info.order.id,
			email: this.reenviar_factura_email,
			name: this.reenviar_factura_name
		})
		.subscribe
		({
			error:(error)=>this.showError(error),
			next:()=>
			{
				this.reenviar_factura_email = this.order_info.order.sat_receptor_email;
				this.reenviar_factura_name	= this.order_info?.client?.name || this.order_info.order.client_name;
				this.is_loading = false;
				x.target.reset();
				this.showSuccess('Se reenvío la factura correctamente');
				window.location.reload();
			}
		});
	}

	selected_payment:PaymentInfo | null = null;
	show_facturar_pago: boolean = false;

	showFacturarPago(p:PaymentInfo)
	{
		this.selected_payment = p;
		this.show_facturar_pago = true;
	}

	facturarElPago(evt:Event)
	{
		let d = Utils.getLocalDateFromMysqlString(this.fecha_pago);
		let fecha_de_pago = Utils.getMysqlStringFromLocalDate(d);
		fecha_de_pago = fecha_de_pago.replace(' ','T');

		this.subs.sink = this.rest
		.update('facturarPago',
		{
			payment_id: this.selected_payment.payment.id,
			email: this.pago_factura_email,
			fecha_de_pago
		})
		.subscribe
		({
			error:(error)=>this.showError(error),
			next:(result)=>
			{
				this.is_loading = false;
				this.show_facturar_pago = false;
				this.showSuccess('El pago se facturo correctamente');
				window.location.reload();
			}
		});
	}

	resetFacturacion(evt:Event)
	{
		evt.preventDefault();

		let payload = {
			order_id: this.order_info.order.id,
			sat_forma_pago: this.sat_forma_pago
		};

		this.subs.sink = this.rest
		.update('reiniciarFacturacion',payload)
		.subscribe((response)=>
		{
			this.showSuccess('Se reinicio correctamente')
		},(error)=>this.showError(error));
	}

	cancelarPago(payment_id:number)
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert
		(
			this.order_info.order.sat_xml_attachment_id,
			'Cancelar Pago',
			'¿Estas seguro de cancelar este pago?'
		)
		.pipe
		(
			filter(result=>result.accepted),
			mergeMap(()=>
			{
				this.is_loading = true;
				return this.rest.cancelPayment(payment_id);
			}),
		)
		.subscribe
		({
			next:(response)=>this.showSuccess('El pago se cancelo correctamente'),
			error:(error)=>this.showError(error)
		});
	}

	changeAddress(evt:any)
	{

	}

	cancelarFactura()
	{
		this.subs.sink = this.confirmation
		.showConfirmAlert
		(
			this.order_info.order.sat_xml_attachment_id,
			'Cancelar Factura',
			'¿Estas seguro de cancelar esta factura?'
		)
		.pipe
		(
			filter(result=>result.accepted),
			mergeMap(()=>
			{
				this.is_loading = true;
				let order_id = this.order_info.order.id;
				return this.rest.cancelar_factura.create({ order_id });
			}),
		)
		.subscribe
		({
			next:(response)=>this.showSuccess('La factura se cancelo correctamente'),
			error:(error)=>this.showError(error)
		});
	}
}
