<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-10">
			<h1 class="my-3">Ordenes Pendientes {{commanda?.name}}</h1>
		</div>
		<div class="col-2">
			<button type="button" class="btn btn-secondary" (click)="reloadInfo()">
				<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208s208-93.31 208-208S370.69 48 256 48Zm120 182.15a8.62 8.62 0 0 1-8.62 8.62h-59.54a8.61 8.61 0 0 1-6.09-14.71l22.17-22.17l-5.6-6.51a87.38 87.38 0 1 0-62.94 148a87.55 87.55 0 0 0 82.42-58.25A16 16 0 1 1 368 295.8a119.4 119.4 0 1 1-112.62-159.18a118.34 118.34 0 0 1 86.36 36.95l.56.62l4.31 5l14.68-14.68a8.44 8.44 0 0 1 6-2.54a8.61 8.61 0 0 1 8.68 8.63Z"/></svg>
			</button>
		</div>
	</div>
	<div class="w-100">
		<div class="d-flex flex-wrap justify-content-start w-100">
			<div class="card commanda_item" *ngFor="let order_info of order_info_list" [ngClass]="{opacity: order_info.is_ready }">
				<div>
					<button type="button" class="btn btn-primary w-75" *ngIf="!order_info.is_ready" (click)="markOrderPrepartionStatusAsReady(order_info)">
						Marcar Listo
					</button>
					<button type="button" class="btn btn-primary w-75" *ngIf="order_info.is_ready" (click)="undelivery(order_info)">
						Restaurar
					</button>
					<button type="button" class="btn btn-primary w-25" (click)="printAllItems(order_info,true)">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 17.5v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75v-1.251l-1.75.001A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18Zm-2.25-4h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75Zm.002-9H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75Z"/></svg>
					</button>
				</div>
				<div>Orden # {{order_info.order.id | number}}
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"><mask id="ipSPeople0"><path fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M24 20a7 7 0 1 0 0-14a7 7 0 0 0 0 14ZM6 40.8V42h36v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C35.92 28 33.68 28 29.2 28H18.8c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C6 34.08 6 36.32 6 40.8Z"/></mask><path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSPeople0)"/></svg>
					{{order_info.order.guests}}

					<span *ngIf="order_info.order.service_type == 'TOGO'">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7zM7 17c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1z"/><path fill="currentColor" d="M5 6h5v2H5zm14 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z"/></svg>
					</span>
					<span *ngIf="order_info.order.service_type == 'PICK_UP'">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M18.121 3.271c-.295-.256-1.906-1.731-2.207-1.991c-.299-.259-.756-.28-1.102-.28H5.188c-.345 0-.802.021-1.102.28c-.301.26-1.912 1.736-2.207 1.991c-.297.256-.543.643-.464 1.192c.079.551 1.89 13.661 1.937 13.973A.67.67 0 0 0 4 19h12a.67.67 0 0 0 .648-.565c.047-.311 1.858-13.422 1.938-13.973c.078-.548-.168-.935-.465-1.191zM10 11.973c-3.248 0-3.943-4.596-4.087-5.543H7.75c.276 1.381.904 3.744 2.25 3.744s1.975-2.363 2.25-3.744h1.838c-.145.947-.84 5.543-4.088 5.543zM3.17 4.006L5 2h10l1.83 2.006H3.17z"/></svg>
					</span>
					<span *ngIf="order_info.order.service_type == 'IN_PLACE'">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z"/></svg>
					</span>
					<span *ngIf="order_info.order.service_type == 'QUICK_SALE'">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m21.96 9.73l-1.43-5a.996.996 0 0 0-.96-.73H4.43c-.45 0-.84.3-.96.73l-1.43 5c-.18.63.3 1.27.96 1.27h2.2l-1.05 7.88a.989.989 0 1 0 1.96.26L6.67 15h10.67l.55 4.14a1 1 0 0 0 .98.86c.6 0 1.06-.53.98-1.12L18.8 11H21c.66 0 1.14-.64.96-1.27zM6.93 13l.27-2h9.6l.27 2H6.93z"/></svg>
					</span>
				</div>
				<div>Cliente# {{order_info?.order?.client_name}}</div>
			<div class="bg-primary text-white text-center">{{commanda.name}}</div>
				<ng-container *ngFor="let oii of order_info.structured_items;let i = index">
					<div class="order-item" *ngIf="commanda.order_display_preferences == 'ALL_ORDERS' || (oii.item.commanda_type_id == this.commanda.commanda_type_id )"
						[ngClass]="{'DISMISSED':oii.order_item.commanda_status=='DISMISSED','border-top':true, big_border:oii.order_item.has_separator=='YES' && oii.childs.length==0}"
						(click)="toggleCommandaStatus(oii)">
						<div>
							<span *ngIf="oii.order_item.item_option_id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							<b>{{oii.order_item.qty | number }}</b>-
							<span *ngIf="rest.local_preferences.display_categories_on_items=='YES' && oii.category?.name && !oii.order_item.item_option_id">
								{{oii.category.name | titlecase}}
							</span>
							{{oii.item.name | titlecase }}
							<ng-container *ngIf="oii.order_item.item_option_id == null">
								{{seconds[ oii.order_item.id ]/60 | number: '1.0-0' }}:{{seconds[ oii.order_item.id ]%60 | number: '2.0-0' }}
							</ng-container>
						</div>
						<div *ngIf="oii.order_item.note">
							<pre style="margin-bottom: 4px;">{{oii.order_item.note}}</pre>
						</div>
						<div *ngIf="oii.order_item.exceptions">
							<span class="fw-bold">Excep.</span>
							<pre>{{oii.order_item.exceptions}}</pre>
						</div>
					</div>
					<hr *ngIf="oii.order_item.exceptions" class="my-0">
					<ng-container	*ngFor="let c_oii of oii.childs;let i = index">
						<div class="order-item ps-3" *ngIf="commanda.order_display_preferences == 'ALL_ORDERS' ||(c_oii.item.on_sale == 'NO' && oii.item.commanda_type_id == this.commanda.commanda_type_id ) || c_oii.item.commanda_type_id == this.commanda.commanda_type_id"
							[ngClass]="{'DISMISSED':c_oii.order_item.commanda_status=='DISMISSED', big_border:oii.order_item.has_separator=='YES' && i == oii.childs.length}"
							(click)="toggleCommandaStatus(c_oii)">
							• {{c_oii.order_item.item_option_qty}} {{c_oii.item.name | titlecase }}
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</div>
