import { Component, OnInit } from '@angular/core';
import {Preferences} from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

	preferences:Preferences;
	return_url:string = '';

	ngOnInit()
	{
		this.preferences = this.rest.getPreferencesFromSession();

		this.rest.getPreferencesInfo()
		.then
		(
			()=>{
				console.log('Preferences loaded');
			},
			()=>{
				console.log('Preferences not loaded');
			}
		);

		this.getQueryParamObservable().subscribe((response)=>
		{
			let query_param_map = response[0];
			this.return_url = query_param_map.has('return_url') ? query_param_map.get('return_url') : '';

			if ( this.rest.getUserFromSession() != null)
			{
				if( this.return_url )
				{
					this.router.navigate([this.return_url]);
				}
				else
				{
					this.router.navigate(['/dashboard']);
				}
			}
		})
	}

	username: string = '';
	password: string = '';

	doLoginKeyboard(evt:KeyboardEvent)
	{
		if( evt.code == 'Enter' )
			this.doLogin();
	}

	doLogin() {
		this.is_loading = true;
		this.subs.sink = this.rest.doLogin(this.username, this.password).subscribe(
		()=>
		{
			this.is_loading = false;

			if( this.return_url )
			{
				this.router.navigate([this.return_url]);
			}
			else
			{
				this.router.navigate(['/dashboard']);
			}
		}, error=>this.showError(error ));
	}
}
