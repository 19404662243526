<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col">
			<h1 class="my-3 text-capitalize">
				Requisiciones <span *ngIf="type=='store'"> A Sucursal</span>
			</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="btn btn-secondary" [routerLink]="['/add-requisition/',type]">Agregar</button>
		</div>
	</div>

	<div class="card p-3 shadow">
		<div class="row">
				<div class="col-6 col-md-3 form-group">
					<label class="">Estatus</label>
					<select name="status" [(ngModel)]="requisition_search.eq.status" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option value="PENDING">Pendiente</option>
						<option value="CANCELLED">Cancelado</option>
						<option value="NOT_APPROVED">No aprobado</option>
						<option value="SHIPPED">Enviado</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Requerido en</label>
					<select name="required_by_store_id" [(ngModel)]="requisition_search.eq.required_by_store_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Solicitado a</label>
					<select name="requested_to_store_id" [(ngModel)]="requisition_search.eq.requested_to_store_id" class="form-control">
						<option [ngValue]="null">Todos</option>
						<option *ngFor="let c of store_list" [value]="c.id">{{c.name}}</option>
					</select>
				</div>
			<div class="col-6 col-md-3 form-group">
				<label class="">Fecha</label>
				<input type="date" name="date" [(ngModel)]="requisition_search.ge.date" class="form-control">
			</div>

			<div class="col-6 col-md-3">
				<label>&nbsp;</label>
				<div>
					<button type="button" class="w-100 btn btn-primary" (click)="search(requisition_search)">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<div	class="card pb-3 px-0 mt-3 shadow">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="row d-none d-md-flex border-bottom mb-3 bg-secondary text-white p-2 mx-0 rounded-top">
			<div class="col-2 fw-bold">Solicitado por</div>
			<div class="col-2 fw-bold">Solicitado a</div>
			<div class="col-2 fw-bold">Requerido en</div>
			<div class="col-2 fw-bold">Fecha</div>
			<div class="col-1 fw-bold">Estatus</div>
			<div class="col-3 fw-bold text-end">Acciones</div>
		</div>
		<div *ngFor="let ri of requisition_info_list" class="row border-bottom align-items-center m-1 mx-2">
			<div class="col-md-2">
				<a [routerLink]="['/edit-requisition',ri.requisition.id]">{{ri.requisition.id}} - {{ri.user.name | titlecase}}</a>
			</div>
			<div class="col-6 d-md-none fw-bold">Solicitado a</div>
			<div class="col-6 col-md-2">{{ri?.requested_to_store?.name |titlecase}}</div>
			<div class="col-6 d-md-none fw-bold">Requerido en</div>
			<div class="col-6 col-md-2">{{ri.required_by_store.name |titlecase}}</div>
			<div class="col-6 d-md-none fw-bold">Fecha</div>
			<div class="col-6 col-md-2">{{ri.requisition.required_by_timestamp| date: 'MMM d, y'}}</div>
			<div class="col-6 d-md-none fw-bold">Estatus</div>
			<div class="col-6 col-md-1">{{ ri.requisition.status == 'CANCELLED' ? status_string[ ri.requisition.status ] : status_string [ ri.requisition.approved_status ]}}</div>
			<div class="col-12 col-md-3 text-end">
				<button type="button" class="btn btn-primary m-1" (click)="showCancelar(ri)" *ngIf="ri.requisition.status=='PENDING' && !ri.shipping">
					<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"/></svg>
				</button>
				<button type="button" class="btn btn-primary m-1" (click)="confirmCopy(ri)" *ngIf="ri.requisition.status=='PENDING'">
					<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 36 36"><path fill="currentColor" d="M27 3.56A1.56 1.56 0 0 0 25.43 2H5.57A1.56 1.56 0 0 0 4 3.56v24.88A1.56 1.56 0 0 0 5.57 30h.52V4.07H27Z" class="clr-i-solid clr-i-solid-path-1"/><rect width="23" height="28" x="8" y="6" fill="currentColor" class="clr-i-solid clr-i-solid-path-2" rx="1.5" ry="1.5"/><path fill="none" d="M0 0h36v36H0z"/></svg>
				</button>
				<button type="button" class="btn btn-primary m-1" (click)="confirmApproved(ri)" *ngIf="ri.requisition.approved_status=='PENDING' && ri.requisition.status != 'CANCELLED' && !ri.shipping">
					<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z"/></svg>
					Aprobar
				</button>
				<a class="btn btn-primary m-1" [routerLink]="['/edit-requisition',ri.requisition.id]" *ngIf="ri.requisition.status!='CANCELLED' && !ri.shipping">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z" class=""></path></svg>
				</a>
				<a class="btn btn-primary m-1" [routerLink]="['/add-shipping-requisition',ri.requisition.id]" *ngIf="ri.requisition.status!='CANCELLED' && !ri.shipping && rest.current_permission.send_shipping && ri.requisition.approved_status == 'APPROVED'">
					<svg aria-hidden="true" width="1em" height="1em" focusable="false" data-prefix="fas" data-icon="location-arrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z" class=""></path></svg>
					Enviar
				</a>
				<a class="btn btn-primary m-1" [routerLink]="['/add-shipping-requisition',ri.requisition.id]" *ngIf="ri.requisition.status!='CANCELLED' && ri.shipping && rest.current_permission.send_shipping && rest.current_permission.receive_shipping">
					<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" fill="currentColor"/></svg>
					Ver
				</a>
				<a class="btn btn-primary m-1" *ngIf="ri.requisition.status == 'SHIPPED'" [routerLink]="['/list-shipping']" [queryParams]="{'eq.requisition_id':ri.requisition.id}">Envios</a>
			</div>
		</div>
		<div class="row" *ngIf="requisition_info_list == null || requisition_info_list.length == 0 && !is_loading">
			<div class="col-12">
				<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
			</div>
		</div>

		<!--div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Solicitado por</th>
						<th>Solicitado a</th>
						<th>Requerido en</th>
						<th>Fecha</th>
						<th>Estatus</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let ri of requisition_info_list">
						<td>
							<a [routerLink]="['/edit-requisition',ri.requisition.id]">{{ri.requisition.id}} - {{ri.user.name | titlecase}}</a>
						</td>
						<td>{{ri.requested_to_store.name |titlecase}}</td>
						<td>{{ri.required_by_store.name |titlecase}}</td>
						<td>{{ri.requisition.date| date: 'MMM d, y'}}</td>
						<td>{{status_string[ ri.requisition.status ] }}</td>
						<td>
							<button type="button" class="btn btn-primary mx-1" [routerLink]="['/add-shipping-requisition',ri.requisition.id]">Enviar</button>
							<button type="button" class="btn btn-primary mx-1" *ngIf="ri.requisition.status == 'SHIPPED'" [routerLink]="['/list-shipping']" [queryParams]="{'eq.requisition_id':ri.requisition.id}">Envios</button>
						</td>
					</tr>
					<tr *ngIf="requisition_info_list == null || requisition_info_list.length == 0 && !is_loading">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div-->
	</div>
	<app-pagination [path]="'/list-requisition/'+type" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
