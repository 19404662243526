<div class="container-fluid">

	<div class="row align-items-center mx-0">
		<div class="col">
			<h1 class="my-3 text-capitalize">Cotizaciones</h1>
		</div>
		<div class="col-auto">
			<!--button type="button" class="mr-2 btn btn-light lift" (click)="show_import=true">Importar</button>
			<button type="button" class="mr-2 btn btn-light lift" (click)="exportFile()">Exportar</button-->
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-quote']">Agregar Cotización</button>
		</div>
	</div>

	<div class="card p-3 mt-3">
		<div class="row aling-items-center">
			<div class="col-6 col-md-3 col-lg-2">
				<label for="name" class="form-label">Buscar por Nombre</label>
				<input type="text" name="name" class="form-control" [(ngModel)]="quote_search.lk.name" placeholder="Buscar por Nombre">
			</div>
			<div class="col-6 col-md-3 col-lg-2">
				<label for="fecha_inicial" class="form-label">fecha inicial</label>
				<input type="datetime-local" name="fecha_inicial" class="form-control" [(ngModel)]="fecha_inicial" (ngModelChange)="onDateChange($event,quote_search.ge,'created')" placeholder="Fecha inicial">
			</div>
			<div class="col-6 col-md-3 col-lg-2">
				<label for="fecha_final" class="form-label">fecha final</label>
				<input type="datetime-local" name="fecha_final" class="form-control" [(ngModel)]="fecha_final" (ngModelChange)="onDateChange($event,quote_search.le,'created')" placeholder="fecha final">
			</div>
			<div class="col-6 col-md-3 col-lg-2">
				<label for="vencimiento" class="form-label">fecha vencimiento</label>
				<input type="date" name="valid_until" class="form-control" [(ngModel)]="quote_search.eq.valid_until" placeholder="Vencimiento">
			</div>

			<div class="col-6 col-md-3 col-lg-2">
				<label class="form-label">&nbsp;</label>
				<div>
					<button type="button" class="btn btn-primary" (click)="search(quote_search)">Buscar</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="mb-0 w-100">
				<thead class="thead-light">
					<tr>
						<th>Id-Nombre</th>
						<th class="d-none d-md-none d-lg-table-cell">Teléfono</th>
						<th class="d-none d-md-none d-lg-table-cell">Correo</th>
						<th class="d-none d-md-none d-lg-table-cell">Fecha</th>
						<th class="">Expira</th>
						<th class="d-none d-lg-table-cell text-center">Estatus</th>
						<th class="text-end">Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let quote_info of quote_info_list" class="border">
						<td>
							<a [routerLink]="['/view-quote',quote_info.quote.id]">
								{{quote_info.quote.id}} - {{quote_info.quote.name | titlecase }}
							</a>
						</td>
						<td class="d-none d-md-none d-lg-table-cell">{{quote_info.quote.phone}}</td>
						<td class="d-none d-md-none d-lg-table-cell">{{quote_info.quote.email}}</td>
						<td class="d-none d-md-none d-lg-table-cell">{{quote_info.quote.created | date: 'MMM d, y' }}</td>
						<td class="">{{quote_info.quote.valid_until | date: 'MMM d, y' }}</td>

						<td class="d-none d-lg-table-cell text-center">
							<!-- {{approved_status_dict[ quote_info.quote.approved_status ]}} -->
							<div>
								<span *ngIf="quote_info.quote.approved_status == 'PENDING' && isExpired(quote_info.quote.valid_until)" title="Expirado">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-clock-fil text-danger" viewBox="0 0 16 16">
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
									</svg>
								</span>
							</div>
							<div>
								<span *ngIf="quote_info.quote.approved_status == 'PENDING' && !isExpired(quote_info.quote.valid_until)" title="Pendiente">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-clock-fil text-success" viewBox="0 0 16 16">
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
									</svg>
								</span>
							</div>
							<div>
								<span *ngIf="quote_info.quote.approved_status == 'APPROVED'"  title="Aprovado">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.50em" height="1.50em" fill="currentColor" class="bi bi-check2 text-success" viewBox="0 0 16 16">
										<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
									</svg>
								</span>
							</div>
							<div>
								<span *ngIf="quote_info.quote.approved_status == 'CANCELLED'" title="Cancelado">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-x-lg text-danger" viewBox="0 0 16 16">
										<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
									</svg>
								</span>
							</div>
							<div>
								<span *ngIf="quote_info.quote.approved_status == 'DECLINED'" title="Declinado">
									<svg xmlns="http://www.w3.org/2000/svg" width="1.50em" height="1.50em" class="bi bi-x-lg text-danger" viewBox="0 0 24 24">
										<path fill="currentColor" d="M15 14c2.67 0 8 1.33 8 4v2H7v-2c0-2.67 5.33-4 8-4m0-2a4 4 0 0 1-4-4a4 4 0 0 1 4-4a4 4 0 0 1 4 4a4 4 0 0 1-4 4M5 9.59l2.12-2.13l1.42 1.42L6.41 11l2.13 2.12l-1.42 1.42L5 12.41l-2.12 2.13l-1.42-1.42L3.59 11L1.46 8.88l1.42-1.42z"/>
									</svg>
								</span>
							</div>
						</td>
						<td class="text-end">
							<button class="btn btn-danger mt-1 me-1" *ngIf="quote_info.quote.approved_status != 'APPROVED' && quote_info.quote.approved_status != 'CANCELLED' && quote_info.quote.approved_status != 'DECLINED'" (click)="cancelQuote(quote_info)">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1.25em" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
									<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
								</svg>
								<span class="d-none d-lg-inline">Cancelar</span>
							</button>

							<button class="btn btn-primary mt-1 me-1" (click)="showCopyQuote(quote_info)">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M408 480H184a72 72 0 0 1-72-72V184a72 72 0 0 1 72-72h224a72 72 0 0 1 72 72v224a72 72 0 0 1-72 72z" fill="currentColor"/><path d="M160 80h235.88A72.12 72.12 0 0 0 328 32H104a72 72 0 0 0-72 72v224a72.12 72.12 0 0 0 48 67.88V160a80 80 0 0 1 80-80z" fill="currentColor"/></svg>
								<span class="d-none d-lg-inline">Copiar</span>
							</button>

							<a [routerLink]="['/edit-quote',quote_info.quote.id]" class="btn btn-primary mx-1 mt-1" *ngIf="!quote_info.order">
								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M4.42 20.579a1 1 0 0 1-.737-.326a.988.988 0 0 1-.263-.764l.245-2.694L14.983 5.481l3.537 3.536L7.205 20.33l-2.694.245a.95.95 0 0 1-.091.004zM19.226 8.31L15.69 4.774l2.121-2.121a1 1 0 0 1 1.415 0l2.121 2.121a1 1 0 0 1 0 1.415l-2.12 2.12l-.001.001z" fill="currentColor"/></g></svg>
								<span class="d-none d-lg-inline">Editar</span>
							</a>
						</td>
					</tr>
					<tr *ngIf="quote_info_list.length == 0 && !is_loading" colspan="6">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="path" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

<app-modal [(show)]="show_copy_quote">
	<div class="p-3" style="height: 600px;">
		<form (ngSubmit)="copyQuote()" ngNativeValidate>
			<h2>Copiar Cotización</h2>
			<div class="row">
				<div class="col-12 col-6 form-group">
					<label>Nombre</label>
					<app-search-users (user_selected)="onClientSelected($event)" [reset_on_search]="false" [type]="'CLIENT'"></app-search-users>
				</div>
				<div class="col-12 col-6 form-group">
					<label>Valido hasta:</label>
					<input type="date" name="valid_until" [(ngModel)]="new_valid_until" class="form-control" required>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-6">
					<button type="button" class="btn btn-danger w-100" (click)="show_copy_quote=false">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100" [disabled]="is_loading">Copiar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
