<div class="container-fluid">
	<div class="row align-items-center my-3">
		<div class="col">
			<h1 class="my-3">Listado de tomas de inventario</h1>
		</div>
		<div class="col-auto">
			<button class="btn btn-primary" (click)="show_new_stocktake = true">Nueva Toma de Inventario</button>
		</div>
	</div>
	<form (submit)="searchStockTakes()">
		<div class="card p-3">
			<div class="row mb-3">
				<div class="col-12 col-sm-6 col-md-3 form-group">
					<label>Sucursal</label>
					<select name="store_id" [(ngModel)]="store_id" class="form-control" required>
						<option value="0">Todas</option>
						<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
					</select>
				</div>
				<div class="col-12 col-sm-6 col-md-3 form-group">
					<label>&nbsp;</label>
					<button type="submit" class="btn btn-primary form-control">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128c0-70.7 57.2-128 128-128c70.7 0 128 57.2 128 128c0 70.7-57.2 128-128 128z" fill="currentColor"/></svg>
						&nbsp;Buscar
					</button>
				</div>
			</div>
		</div>
	</form>

	<div class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<ng-template [ngIf]="stocktake_list.length <= 0">
			<h3 class="text-center">No hay tomas de inventario</h3>
		</ng-template>
		<div *ngFor="let stocktake of stocktake_list" class="row align-items-center border-bottom">
			<div class="col-12 col-md-4">
				<a [routerLink]="['/edit-stocktake', stocktake.id]" class="fw-bold">
					{{stocktake.id}} - {{store_dictionary[stocktake.store_id].name}}
				</a>
			</div>
			<div class="col-12 col-md-4 text-lg-end">
				<span class="d-inline d-md-none fw-bold">WHAT</span>
				{{stocktake.created | shortDate: 'full'}}
			</div>
			<div class="col-12 col-lg-4 text-end py-1">
				<div class="row">
					<div class="col-4">
						<a [routerLink]="['/edit-stocktake',stocktake.id]" class="btn btn-primary w-100"  *ngIf="stocktake.status != 'CLOSED'">Editar</a>
					</div>
					<div class="col-4">
						<a [routerLink]="['/view-stocktake',stocktake.id]" class="btn btn-primary w-100">Ver</a>
					</div>
					<div class="col-4">
						<button type="button" class="btn btn-primary w-100" (click)="showResume(stocktake)">Resumen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal [(show)]="show_new_stocktake" [biggest_posible]="true">
	<div class="row mb-3 p-3 mx-0">
		<div class="col-12 col-sm-6 col-md-4">
			<label>Sucursal</label>
			<select name="store_id" [(ngModel)]="new_stocktake_store_id" class="form-control" required>
				<option value="0">Seleccionar</option>
				<option *ngFor="let store of store_list" [value]="store.id">{{store.name | titlecase}}</option>
			</select>
		</div>
		<div class="col-12 col-sm-6 col-md-4">
			<div class="form-group">
				<label>Guia o Titulo de Inventario</label>
				<input type="text" class="form-control" name="stock_take_name" [(ngModel)]="new_stocktake_name" placeholder="Inventario por control">
			</div>
		</div>
		<div class="col-12 col-sm-6 col-md-4">
			<div class="form-group">
				<label>&nbsp;</label>
				<button type="button" class="btn btn-primary w-100" (click)="createStockTake()" [disabled]="new_stocktake_store_id == 0">
					<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="M10 2a8 8 0 0 0-8 8c0 4.418 3.582 8 8 8 4.418 0 8-3.582 8-8 0-4.418-3.582-8-8-8zm0 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm-1-7h2V5h2v2h2v2h-2v2h-2V9H9z" clip-rule="evenodd"/></svg>
					&nbsp;Crear Inventario
				</button>
			</div>
		</div>
	</div>
</app-modal>


<app-modal [(show)]="show_resume" [closable]="true">
	<div class="p-3">
		<div class="row mb-3 p-3 mx-0">
			<div class="col-8 fw-bold">Articulos registrados</div>
			<div class="col-4 text-end">{{cresume.item_count | number}}</div>
			<div class="col-8 fw-bold" *ngIf="rest.current_permission.reports">Articulos totales</div>
			<div class="col-4 text-end" *ngIf="rest.current_permission.reports">{{cresume.db_qty_sum | number}}</div>
			<div class="col-8 fw-bold">Articulos Inventariados</div>
			<div class="col-4 text-end">{{cresume.stocktake_item_count | number}}</div>
			<div class="col-8 fw-bold">Total inventariados</div>
			<div class="col-4 text-end">{{cresume.real_qty_sum | number}}</div>
			<ng-container *ngIf="rest.current_permission.reports">
				<div class="col-8 fw-bold">Articulos con Sobrante</div>
				<div class="col-4 text-end">{{cresume.surplus_item_count | number}}</div>
				<div class="col-8 fw-bold">total Sobrantes</div>
				<div class="col-4 text-end">{{cresume.surplus_qty | number}}</div>
				<div class="col-8 fw-bold">Articulos con faltantes</div>
				<div class="col-4 text-end">{{cresume.missing_item_count | number}}</div>
				<div class="col-8 fw-bold">Total faltantes</div>
				<div class="col-4 text-end">{{cresume.missing_qty | number}}</div>
				<div class="col-8 fw-bold">Articulos con inventario correcto</div>
				<div class="col-4 text-end">{{cresume.ok_item_count | number}}</div>
				<div class="col-8 fw-bold">Total inventario correcto</div>
				<div class="col-4 text-end">{{cresume.ok_item_sum  | number}}</div>
			</ng-container>
		</div>
		<div class="row">
			<div class="col-6" *ngIf="selected_stocktake && rest.current_permission.add_stock && selected_stocktake.status != 'CLOSED' && cresume.ok_item_count != cresume.stocktake_item_count">
				<button type="button" class="btn btn-primary w-100" *ngIf="'ACTIVE' == selected_stocktake.status" (click)="applyStockDifferences()">Aplicar diferencias</button>
			</div>
			<div class="col-6" *ngIf="selected_stocktake && rest.current_permission.add_stock && selected_stocktake.status != 'CLOSED' && cresume.ok_item_count != cresume.stocktake_item_count">
				<button type="button" class="btn btn-primary w-100" *ngIf="'ACTIVE' == selected_stocktake.status" (click)="adjustStockValues()">Ajustar Inventarios</button>
			</div>
			<div class="col-12">
				<button type="button" class="btn btn-primary w-100 mt-3" (click)="show_resume=false">Cerrar</button>
			</div>
		</div>
	</div>
</app-modal>
