<div class="container-fluid py-3">
	<div class="row align-items-end mx-0">
		<div class="col">
			<h1 class="text-capitalize">Lista de Precios</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" [routerLink]="['/add-price-list']">Agregar</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="price_list_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Nombre</label>
					<input type="text" name="name" [(ngModel)]="price_list_search.lk.name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created</label>
					<input type="datetime-local" name="created" [(ngModel)]="price_list_search.eq.created" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated</label>
					<input type="datetime-local" name="updated" [(ngModel)]="price_list_search.eq.updated" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Created by user</label>
					<input type="number" name="created_by_user_id" [(ngModel)]="price_list_search.eq.created_by_user_id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Updated by user</label>
					<input type="number" name="updated_by_user_id" [(ngModel)]="price_list_search.eq.updated_by_user_id" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id - Nombre</th>
						<th class="d-none d-md-table-cell text-end">Actualizado</th>
						<th class="d-none d-md-table-cell text-end">Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let price_list of price_list_list" class="align-middle">
						<td>

							<a [routerLink]="['/list-price',price_list.id]">
								{{price_list.id}} - {{price_list.name}}
							</a>
						</td>
						<td class="d-none d-md-table-cell text-end">{{price_list.updated | date: 'MMM d, y,  h:mm a' }}</td>
						<td class="text-end">
							<!--button type="button" class="mx-1 btn btn-primary" (click)="showCopyPrices(price_list)">
								Copiar
							</button-->
							<a [routerLink]="['/list-price',price_list.id]" class="btn btn-primary mx-1">
								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M4.42 20.579a1 1 0 0 1-.737-.326a.988.988 0 0 1-.263-.764l.245-2.694L14.983 5.481l3.537 3.536L7.205 20.33l-2.694.245a.95.95 0 0 1-.091.004zM19.226 8.31L15.69 4.774l2.121-2.121a1 1 0 0 1 1.415 0l2.121 2.121a1 1 0 0 1 0 1.415l-2.12 2.12l-.001.001z" fill="currentColor"/></g></svg>
								<span class="d-none d-sm-inline">Editar</span>
							</a>
							<a [routerLink]="['/edit-price-list',price_list.id]" class="btn btn-primary mx-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 15.5A3.5 3.5 0 0 1 8.5 12A3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5a3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97c0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0 0 14 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1c0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64z"/></svg>
								<span class="d-none d-sm-inline">Configuración</span>
							</a>
						</td>
					</tr>
					<tr *ngIf="price_list_list == null || price_list_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-price-list'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

<!--app-modal [(show)]="show_copy_prices">
	<div class="p-3">
		<h1>Copiar Precios</h1>
		<p>La lista de precios</p>
		<div class="row">
			<div class="col-12 form-group">
				<label class="">Origen</label>
				<select class="form-control" [(ngModel)]="origen_price_list_id">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let price_list of price_list_list" [value]="price_list.id">
					{{price_list.id}} - {{price_list.name}}
					</option>
				</select>
			</div>

			<div class="col-12 form-group">
				<label class="">Destino</label>
				<select class="form-control" [(ngModel)]="destino_price_list_id">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let price_list of price_list_list" [value]="price_list.id">
						{{price_list.id}} - {{price_list.name}}
					</option>
				</select>
			</div>
			<div class="col-12 form-group">
				<label class="">Destino</label>
				<select class="form-control" [(ngModel)]="destino_price_list_id">
					<option [ngValue]="null">Seleccionar</option>
					<option *ngFor="let price_list of price_list_list" [value]="price_list.id">
						{{price_list.id}} - {{price_list.name}}
					</option>
				</select>
			</div>
		</div>
	</div>
</app-modal-->
