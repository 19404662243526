<div class="container-fluid">
	<ng-container *ngIf="quote_info">
		<div class="row border-bottom">
			<div class="col-3 align-items-center mb-3">
				<img [src]="rest.getImagePath(store?.ticket_image_id, rest.local_preferences.default_ticket_image_id)" style="width:100%;max-width:350px;">
			</div>
			<div class="col-6 align-items-center text-center pb-3">
				<h6 *ngIf="billing_data" class="fw-bold">{{billing_data?.razon_social | uppercase}}</h6>
				<div *ngIf="billing_data">
					<span>{{billing_data.address | uppercase}}</span>
					<ng-container *ngIf="store?.address && store?.city">, </ng-container>
					<span><br> {{billing_data.city | uppercase}}</span>
					<span *ngIf="billing_data.state">, {{billing_data?.state | uppercase}}</span>
					<span *ngIf="billing_data.zipcode">, CP:{{billing_data?.zipcode}}</span>
					<div *ngIf="store.name">
						Sucursal: {{store.name | titlecase}}
					</div>
				</div>
				<div *ngIf="store.phone">Teléfono: {{store.phone}}</div>
				<!--div *ngIf="order_info?.store?.email">Correo Electrónico: {{order_info?.store.email}}</div-->
				<!--div *ngIf="order_info?.store?.email">{{order_info?.store.email}}</div-->
			</div>
			<div class="col-3 align-items-center rounded">
				<div class="border">
					<div class="border-bottom px-2 py-1">
						Cotización:<br>
						<b>No.{{ quote_info.quote.id}}</b>
					</div>
					<div class="border-bottom px-2 py-1">
						Fecha de elaboración:<br>
						<b>{{quote_info.quote?.created | date: 'dd MMMM YYYY' }}</b>
					</div>
					<div class="px-2 py-1">
						Vigencia:<br>
						<b>{{quote_info.quote.valid_until | date: 'dd MMMM YYYY'}}</b>
					</div>
				</div>
			</div>
		</div>
		<div class="row align-items-end mx-0 my-3 d-print-none">
			<div class="col">
				<h1 class="text-capitalize">Cotización</h1>
			</div>
			<div class="col-auto d-print-none">
				<button type="button" class="btn btn-primary mx-1 m-3"	(click)="print($event)">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g _ngcontent-pvm-c245="" fill="none"><path _ngcontent-pvm-c245="" d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"></path></g></svg>
					<span class="d-none d-md-inline">Cotización</span>
				</button>
				<button (click)="startCreateOrderProcess()" class="btn btn-primary mx-1 my-3" *ngIf="!quote_info.order">
					<span style="position:relative; top:-2px;">
						<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/></svg>
					</span>
					<span class="d-none d-md-inline">Crear Orden</span>
				</button>
				<button (click)="showSendQuote()" class="btn btn-primary mx-1 my-3">
					<span style="position:relative; top:-2px;">
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="m7.172 11.334l2.83 1.935l2.728-1.882l6.115 6.033c-.161.052-.333.08-.512.08H1.667c-.22 0-.43-.043-.623-.12l6.128-6.046ZM20 6.376v9.457c0 .247-.054.481-.15.692l-5.994-5.914L20 6.376ZM0 6.429l6.042 4.132l-5.936 5.858A1.663 1.663 0 0 1 0 15.833V6.43ZM18.333 2.5c.92 0 1.667.746 1.667 1.667v.586L9.998 11.648L0 4.81v-.643C0 3.247.746 2.5 1.667 2.5h16.666Z"/></svg>
					</span>
					<span class="d-none d-md-inline">Enviar</span>
				</button>

				<a class="btn btn-primary mx-1 my-3" *ngIf="quote_info.order && quote_info?.order?.status != 'CLOSED'" [routerLink]="['/pos',quote_info.order.id]">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="currentColor"><path d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0a2.625 2.625 0 0 1-5.25 0Z"/><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z" clip-rule="evenodd"/></g></svg>
					Ver Orden
				</a>

				<a class="btn btn-primary mx-1 my-3" *ngIf="quote_info.order && quote_info?.order.status == 'CLOSED'" [routerLink]="['/view-order',quote_info.order.id]">
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="currentColor"><path d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0a2.625 2.625 0 0 1-5.25 0Z"/><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z" clip-rule="evenodd"/></g></svg>
					Ver Orden
				</a>
				<a class="btn btn-primary mx-1 my-3" *ngIf="quote_info?.order" [routerLink]="['/print-remission',quote_info?.order.id]">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g _ngcontent-pvm-c245="" fill="none"><path _ngcontent-pvm-c245="" d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"></path></g></svg>
					Remision
				</a>
			</div>
		</div>
		<div class="row align-items-center mx-0 my-3 border-bottom">
			<div class="col-6 py-3 px-1 px-md-3">
				<table>
					<tr>
						<td>Nombre:</td>
						<th>
							{{(billing_address?.name || quote_info?.client_user?.name || quote_info.quote.name) | uppercase}}
						</th>
					</tr>
					<tr>
						<td>Correo:</td>
						<th>{{quote_info.quote.email || billing_address?.email || quote_info.client_user?.email | lowercase }}</th>
					</tr>
					<tr>
						<td>Teléfono:</td>
						<th>{{quote_info.quote.phone || quote_info.client_user?.phone | lowercase }}</th>
					</tr>
					<tr *ngIf="billing_address">
						<td>RFC:</td>
						<th>{{billing_address.rfc | uppercase}}</th>
					</tr>
					<tr *ngIf="billing_address?.email">
						<td>Correo Electrónico:</td>
						<th>{{billing_address.email}}</th>
					</tr>
					<tr *ngIf="billing_address">
						<td>Dirección:</td>
						<th>
							{{billing_address.address}}
							{{billing_address.suburb ? (','+billing_address.suburb) : ''}}
							{{billing_address.city ? (', '+billing_address.city) : ''}}
							{{billing_address.state ? ('. '+billing_address.state) : ''}}
							{{billing_address.zipcode ? ('. CP:'+billing_address.zipcode) : ''}}
						</th>
					</tr>
				</table>
			</div>
			<div class="col-6 py-3 px-1 px-md-3" *ngIf="quote_info.quote.note">
				<table>
					<thead>
						<tr class="text-center">
							<th>Nota:</th>
						</tr>
					</thead>
					<tbody>
						<tr class="text-break">
							<td>{{quote_info.quote.note | uppercase}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="py-3 px-1 px-md-3">
			<table>
				<!--tr>
					<td>Nombre:</td>
					<th>{{billing_address?.name | uppercase}}</th>
				</tr>
				<tr>
					<td>RFC:</td>
					<th>{{billing_address?.rfc | uppercase}}</th>
				</tr>
				<tr>
					<td>Direccion:</td>
					<th>{{billing_address?.email}}</th>
				</tr>
				<tr>
					<td>Dirección:</td>
					<th>
						{{billing_address.address}}
						{{billing_address.suburb ? (','+billing_address.suburb) : ''}}
						{{billing_address.city ? (', '+billing_address.city) : ''}}
						{{billing_address.state ? ('. '+billing_address.state) : ''}}
						{{billing_address.zipcode ? ('. CP:'+billing_address.zipcode) : ''}}
					</th>
				</tr>
				<tr>
					<td>Nombre:</td>
					<th>
						{{quote_info.quote?.name | uppercase}}
					</th>
				</tr>
				<!-- tr *ngIf="order_info.order.address">
					<td>Dirección:</td>
					<td>{{order_info.order.address}}</td>
				</tr>
				<tr *ngIf="order_info.client?.phone">
					<td>Teléfono:</td>
					<th>{{order_info.client?.phone | lowercase }}</th>
				</tr -->
			</table>
		</div>
		<div class="card d-print-none">
			<div class="quote_items px-lg-3 py-3">
				<div class="row mx-0 mb-3 align-items-center d-print-flex d-none d-md-flex">
					<div class="col-6 fw-bold px-1 px-md-2">Artículo</div>
					<div class="col-2 fw-bold text-end px-1 px-md-2">Cantidad</div>
					<div class="col-2 fw-bold text-end px-1 px-md-2">Precio</div>
					<div class="col-2 fw-bold text-end px-1 px-md-2">Total</div>
				</div>
				<div class="row mx-0 py-2 align-items-center border-top" *ngFor="let qi of quote_info.items">
					<div class="col-12 col-md-6 fw-bold px-1 px-md-2">{{qi.item.name | titlecase}}</div>
					<div class="col-6 d-md-none fw-bold px-1 px-md-2">Cantidad</div>
					<div class="col-6 col-md-2 text-end px-1 px-md-2">{{qi.quote_item.qty | number}}</div>
					<div class="col-6 d-md-none fw-bold px-1 px-md-2">Precio</div>
					<div class="col-6 col-md-2 text-end px-1 px-md-2">{{qi.quote_item.unitary_price | currency: '$'}}</div>
					<div class="col-6 d-md-none fw-bold px-1 px-md-2">Total</div>
					<div class="col-6 col-md-2 text-end px-1 px-md-2">{{qi.quote_item.unitary_price * qi.quote_item.qty| currency: '$'}}</div>
				</div>
				<div class="quote_items row mx-0 align-items-center border-top">
					<div class="col-6 col-md-10 text-end">
						<span class="d-print-block fw-bold">Subtotal</span>
					</div>
					<div class="col-6 col-md-2 py-3 px-1 px-md-2 text-end fw-bold">{{subtotal | currency: '$'}} {{quote_info.quote.currency_id}}</div>
				</div>
				<div class="quote_items row mx-0 align-items-center border-top">
					<div class="col-6 col-md-10 text-end">
						<span class="d-print-block fw-bold">IVA</span>
					</div>
					<div class="col-6 col-md-2 py-3 px-1 px-md-2 text-end fw-bold">{{total_tax | currency: '$'}} {{quote_info.quote.currency_id}}</div>
				</div>

				<div class="quote_items row mx-0 align-items-center border-top">
					<div class="col-6 col-md-2 text-end fw-bold">
					</div>
					<div class="col-6 col-md-4 py-3 px-1 px-md-2 fw-bold"></div>
					<div class="col-6 col-md-4 text-end">
						<span class="d-print-block fw-bold">Total</span>
					</div>
					<div class="col-6 col-md-2 py-3 px-1 px-md-2 text-end fw-bold">{{total | currency: '$'}} {{quote_info.quote.currency_id}}</div>
					<div class="col-12 text-end fw-bold">{{total_letra}} {{quote_info.quote.currency_id}} {{currency.name | uppercase}}</div>
				</div>
			</div>
		</div>
		<table class="table d-none d-print-table">
			<thead class="thead-light">
				<tr class="table-secondary">
					<th>Artículo</th>
					<th>Cantidad</th>
					<th>Precio</th>
					<th class="text-end">Total</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let qi of quote_info.items">
					<td>{{qi.item.name | titlecase}}</td>
					<td>{{qi.quote_item.qty | number}}</td>
					<td>{{qi.quote_item.unitary_price | currency: '$'}}</td>
					<td class="text-end">{{qi.quote_item.subtotal| currency: '$'}}</td>
				</tr>
			</tbody>
			<tfoot class="thead-light">
				<tr class="table-secondary">
					<th colspan="2">Subtotal</th>
					<th colspan="2" class="text-end">{{subtotal| currency: '$'}} {{quote_info.quote.currency_id}}</th>
				</tr>
				<tr class="table-secondary">
					<th colspan="2">IVA</th>
					<th colspan="2" class="text-end">{{total_tax | currency: '$'}} {{quote_info.quote.currency_id}}</th>
				</tr>
				<tr class="table-secondary">
					<th colspan="2">Total</th>
					<th colspan="2" class="text-end">{{total | currency: '$'}} {{quote_info.quote.currency_id}}</th>
				</tr>
				<tr class="table-secondary">
					<th colspan="4" class="text-end">
						{{total_letra}} {{quote_info.quote.currency_id}} {{currency.name | uppercase}}
					</th>
				</tr>
			</tfoot>
		</table>
	</ng-container>
</div>
<app-modal [(show)]="show_resend_quote" [closable]="false">
	<div class="container-fluid">
		<h2>Reenviar cotización</h2>
		<form name="send_quote_form" (submit)="sendQuote($event)" ngNativeValidate>
			<app-loading [is_loading]="is_loading"></app-loading>
			<div class="row">
				<div class="col-12 col-md-6">
					<label>Correo Electrónico</label>
					<input type="text" name="quote_email" [(ngModel)]="resend_quote_email" class="form-control" required>
				</div>
			</div>
			<div class="row py-3">
				<div class="col-6">
					<button type="button" class="btn btn-primary w-100" (click)="show_resend_quote=false" [disabled]="is_loading">Cancelar</button>
				</div>
				<div class="col-6">
					<button type="submit" class="btn btn-primary w-100">Enviar</button>
				</div>
			</div>
		</form>
	</div>
</app-modal>
