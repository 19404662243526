<div class="container-fluid">
	<div class="row my-3 align-items-center">
		<div class="col-8 col-md-10">
			<h1 class="text-capitalize">Pagos - Egresos</h1>
		</div>
		<div class="col-4 col-md-2">
			<button type="button" (click)="exportExcel()" class="btn btn-secondary">Exportar</button>
		</div>
	</div>
	<div  class="card p-3 mt-3">
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th># Pagado Por </th>
						<th>Monto del pago</th>
						<th>Fecha</th>
						<th>Tipo</th>
						<th>Concepto</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pi of payment_info_list">
						<td>{{pi.payment.id}} - {{pi?.created_by_user?.name | titlecase}}</td>
						<td>{{pi.payment.payment_amount | currency :'$'}} {{pi.payment.currency_id}}</td>
						<td>{{pi.payment.created | date: 'd MMM y - h:mma' }}</td>
						<td>{{pi.payment.tag}}</td>
						<td>{{pi.payment.concept }}</td>
						<td class="text-end">
							<button (click)="onShowUpdatePayment(pi)" class="btn btn-primary me-sm-1 mb-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 15 15"><path fill="currentColor" d="M10.854.146a.5.5 0 0 0-.708 0L0 10.293V14.5a.5.5 0 0 0 .5.5h4.207L14.854 4.854a.5.5 0 0 0 0-.708z"/></svg>
								Editar
							</button>
							<button (click)="imprimir(pi,$event)" class="btn btn-primary">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 28 28"><g fill="none"><path d="M7.004 5.765V7H6a4 4 0 0 0-4 4v7a3 3 0 0 0 3 3h2v1a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h2a3 3 0 0 0 3-3v-7a4 4 0 0 0-4-4h-1V5.752a2.75 2.75 0 0 0-2.754-2.75l-8.496.013a2.75 2.75 0 0 0-2.746 2.75zM19.5 5.752V7H8.504V5.765c0-.69.558-1.249 1.248-1.25l8.496-.013a1.25 1.25 0 0 1 1.252 1.25zM10 15.5h8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 8.5 22v-5a1.5 1.5 0 0 1 1.5-1.5z" fill="currentColor"/></g></svg>
								Imprimir
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-payment-expenses'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>

<app-modal [(show)]="show_update_payment" [biggest_posible]="true">
	<div class="card p-3" *ngIf="selected_payment_info">
		<h2>Actualizar Pago</h2>
		<form (submit)="updatePaymentInfo($event)">
			<ng-container *ngFor="let mi of selected_payment_info.movements">
				<div class="row">
					<div class="row">
						<div class="col-12 col-sm mb-1">
							<h3>Movimiento #{{mi.bank_movement.id}}</h3>
						</div>
						<div class="col-12 col-sm-4 mb-1" *ngIf="mi.bank_movement.receipt_attachment_id">
							<button type="button" class="btn btn-primary" (click)="showPdfFile(mi.bank_movement.receipt_attachment_id)">Ver Recibo</button>
						</div>
						<div class="col-12 col-sm-4 mb-1" *ngIf="mi.bank_movement.invoice_attachment_id">
							<button type="button" class="btn btn-primary" (click)="showPdfFile(mi.bank_movement.invoice_attachment_id)">Ver Factura</button>
						</div>
					</div>
					<!-- <div class="col-12 col-md-4">
						<label>Cuenta Bancaria</label>
						<select class="form-select" [(ngModel)]="mi.bank_movement.id" name="bank_account_id">
							<option *ngFor="let ba of bank_account_list" [value]="ba.id">{{ba.name}} - {{ba.bank_name}}</option>
						</select>
					</div> -->
					<div class="col-12 col-sm-4">
						<label class="fw-bold">Tipo Pago</label>
						<select class="form-select" [(ngModel)]="mi.bank_movement.transaction_type" name="transaction_type">
							<option value="CASH">Efectivo</option>
							<option value="CREDIT_CARD">Tarjeta de Credito</option>
							<option value="DEBIT_CARD">Tarjeta de Debito</option>
							<option value="TRANSFER">Transferencia</option>
							<option value="CHECK">Cheque</option>
						</select>
					</div>
					<div class="col-12 col-sm-4" *ngIf="mi.bank_movement.transaction_type === 'CREDIT_CARD' || 'DEBIT_CARD'">
						<label class="fw-bold">Terminación Tarjeta</label>
						<input type="text" class="form-control" [(ngModel)]="mi.bank_movement.card_ending" name="card_ending" id="card_ending">
					</div>
					<div class="col-12 col-sm-4" *ngIf="mi.bank_movement.transaction_type === 'CREDIT_CARD' || 'DEBIT_CARD' || 'TRANSFER'">
						<label class="fw-bold">Banco</label>
						<input list="bank_list" class="form-control" type="text" name="origin_bank_name" [(ngModel)]="mi.bank_movement.origin_bank_name">
						<datalist id="bank_list">
							<option>AHORRO FAMSA</option>
							<option>AMERICAN EXPRESS</option>
							<option>BANAMEX</option>
							<option>BANCO AZTECA</option>
							<option>BANCO DE MEXICO (BANXICO)</option>
							<option>BANCO HSBC</option>
							<option>BANCO INBURSA</option>
							<option>BANCO INMOBILIARIO MEXICANO</option>
							<option>BANCOPPEL</option>
							<option>BANJERCITO</option>
							<option>BANK OF AMERICA</option>
							<option>BANORTE</option>
							<option>BBVA BANCOMER</option>
							<option>ICBC MEXICO</option>
							<option>INVESTA BANCO</option>
							<option>SCOTIABANK</option>
							<option>OXXO</option>
						</datalist>
					</div>
					<div class="col-12 col-sm-4">
						<label class="fw-bold">Referencia</label>
						<input type="text" class="form-control" [(ngModel)]="mi.bank_movement.reference" name="reference" id="reference">
					</div>
					<div class="col-6 col-sm-4">
						<app-attachment-uploader [(attachment_id)]="mi.bank_movement.receipt_attachment_id"
						[default_message]="'Actualizar recibo'"></app-attachment-uploader>
					</div>
					<div class="col-6 col-sm-4">
						<app-attachment-uploader [(attachment_id)]="mi.bank_movement.invoice_attachment_id"
						[default_message]="'Actualizar factura'"></app-attachment-uploader>
					</div>
					<ng-container *ngFor="let bmb of mi.bank_movement_bills">
						<div class="row border-top p-2">
							<div class="col-12 col-sm-4">
								<a *ngIf="bmb.bill.purchase_id" [routerLink]="['/purchase/edit', bmb.bill.purchase_id]">
									Pago a orden de compra #{{bmb.bill.purchase_id}} -
									<label class="text-end fw-bold">
										{{bmb.bank_movement_bill.amount | currency :'$'}} {{bmb.bank_movement_bill.currency_id}}
									</label>
								</a>
								<label *ngIf="!bmb.bill.purchase_id" class="text-end fw-bold">
									{{bmb.bank_movement_bill.amount | currency :'$'}} {{bmb.bank_movement_bill.currency_id}}
								</label>
							</div>
							<div class="col-12 col-sm-4" *ngIf="bmb.invoice_attachment">
								<button type="button" class="btn btn-primary w-100" (click)="showPdfFile(bmb.invoice_attachment.id)">
									Ver Factura
								</button>
							</div>
							<div class="col-12 col-sm-4" *ngIf="bmb.receipt_attachment">
								<button type="button" class="btn btn-primary w-100" (click)="showPdfFile(bmb.receipt_attachment.id)">
									Ver Recibo
								</button>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<div class="col-12 text-end">
				<button type="submit" class="btn btn-primary">Actualizar</button>
			</div>
		</form>
	</div>
</app-modal>
