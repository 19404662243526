<div class="container-fluid mt-2">
	<div class="row">
	<div class="col-4">
		<input type="date" name="start" [(ngModel)]="start" class="form-control">
	</div>
	<div class="col-4">
		<input type="date" name="end" [(ngModel)]="end"	class="form-control">
	</div>
	<div class="col-4">
		<button type="button" class="btn btn-primary" (click)="loadData()">Buscar</button>
	</div>
	</div>
	<div class="">
		<div class="row">
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Productos más vendidos</h3>
				<div class="card p-3">
					<div style="height:300px;">
						<ngx-charts-bar-vertical
								[scheme]="top_ten_items_graph.scheme"
								[results]="top_ten_items_graph.results"
								[gradient]="top_ten_items_graph.gradient"
								[xAxis]="top_ten_items_graph.xAxis"
								[yAxis]="top_ten_items_graph.yAxis"
								[legend]="top_ten_items_graph.legend"
								[showXAxisLabel]="top_ten_items_graph.showXAxisLabel"
								[showYAxisLabel]="top_ten_items_graph.showYAxisLabel"
								[xAxisLabel]="top_ten_items_graph.xAxisLabel"
								[yAxisLabel]="top_ten_items_graph.yAxisLabel">
						</ngx-charts-bar-vertical>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Tipos de ventas</h3>
				<div class="card p-3">
					<div style="height:300px;" >
						<!--ng-container *ngIf="!orders_by_type_graph?.results?.length"-->
							<ngx-charts-pie-chart
									[view]="orders_by_type_graph.view"
									[scheme]="orders_by_type_graph.scheme"
									[results]="orders_by_type_graph.results"
									[gradient]="orders_by_type_graph.gradient"
									[legend]="orders_by_type_graph.legend"
									[labels]="orders_by_type_graph.labels"
									[doughnut]="orders_by_type_graph.doughnut">
							</ngx-charts-pie-chart>
						<!--
									[legendPosition]="orders_by_type_graph.legendPosition"
						ng-container-->
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Ventas Por Hora</h3>
				<div class="card p-3">
					<div style="height:300px;">

						<ngx-charts-area-chart-stacked
								[scheme]="sales_by_hour_graph.scheme"
								[legend]="sales_by_hour_graph.legend"
								[showXAxisLabel]="sales_by_hour_graph.showXAxisLabel"
								[showYAxisLabel]="sales_by_hour_graph.showYAxisLabel"
								[xAxis]="sales_by_hour_graph.xAxis"
								[yAxis]="sales_by_hour_graph.yAxis"
								[xAxisLabel]="sales_by_hour_graph.xAxisLabel"
								[yAxisLabel]="sales_by_hour_graph.yAxisLabel"
								[timeline]="sales_by_hour_graph.timeline"
								[results]="sales_by_hour_graph.results">
						</ngx-charts-area-chart-stacked>

					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Ventas Por Cajero</h3>
				<div class="card p-3">
					<div style="height:300px;">
						<ngx-charts-bar-vertical
								[scheme]="sales_by_user_graph.scheme"
								[results]="sales_by_user_graph.results"
								[gradient]="sales_by_user_graph.gradient"
								[xAxis]="sales_by_user_graph.xAxis"
								[yAxis]="sales_by_user_graph.yAxis"
								[legend]="sales_by_user_graph.legend"
								[showXAxisLabel]="sales_by_user_graph.showXAxisLabel"
								[showYAxisLabel]="sales_by_user_graph.showYAxisLabel"
								[xAxisLabel]="sales_by_user_graph.xAxisLabel"
								[yAxisLabel]="sales_by_user_graph.yAxisLabel">
						</ngx-charts-bar-vertical>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Top Ten Merma</h3>
				<div class="card p-3">
					<div style="height:300px;">
						<ngx-charts-bar-vertical
								[scheme]="top_ten_merma_graph.scheme"
								[results]="top_ten_merma_graph.results"
								[gradient]="top_ten_merma_graph.gradient"
								[xAxis]="top_ten_merma_graph.xAxis"
								[yAxis]="top_ten_merma_graph.yAxis"
								[legend]="top_ten_merma_graph.legend"
								[showXAxisLabel]="top_ten_merma_graph.showXAxisLabel"
								[showYAxisLabel]="top_ten_merma_graph.showYAxisLabel"
								[xAxisLabel]="top_ten_merma_graph.xAxisLabel"
								[yAxisLabel]="top_ten_merma_graph.yAxisLabel">
						</ngx-charts-bar-vertical>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<h3 class="my-3">Tiempos de Preparación En Segundos</h3>
				<div class="card p-3">
					<div style="height:300px;">
						<ngx-charts-area-chart-stacked
								[scheme]="preparation_times_graph.scheme"
								[legend]="preparation_times_graph.legend"
								[showXAxisLabel]="preparation_times_graph.showXAxisLabel"
								[showYAxisLabel]="preparation_times_graph.showYAxisLabel"
								[xAxis]="preparation_times_graph.xAxis"
								[yAxis]="preparation_times_graph.yAxis"
								[xAxisLabel]="preparation_times_graph.xAxisLabel"
								[yAxisLabel]="preparation_times_graph.yAxisLabel"
								[timeline]="preparation_times_graph.timeline"
								[results]="preparation_times_graph.results">
						</ngx-charts-area-chart-stacked>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- button class="btn btn-primary" (click)="requestPermission($event)" i18n>Activar Notificaciones</button-->
<!--ngx-charts-bar-vertical-stackekkk
	[scheme]="totalBottlesByStore.scheme"
	[results]="totalBottlesByStore.results"
	[xAxis]="totalBottlesByStore.xAxis"
	[yAxis]="totalBottlesByStore.yAxis"
	[legend]="totalBottlesByStore.legend"
	[showXAxisLabel]="totalBottlesByStore.showXAxisLabel"
	[showYAxisLabel]="totalBottlesByStore.showYAxisLabel"
	[xAxisLabel]="totalBottlesByStore.xAxisLabel"
	[yAxisLabel]="totalBottlesByStore.yAxisLabel">
</ngx-charts-bar-vertical-stacked-->
