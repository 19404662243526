<div class="container-fluid">
	<h4 class="my-3">Editar Tipos de Cambio</h4>
	<div class="card p-3" *ngIf="store_currency_list">
		<app-loading [is_loading]="is_loading"></app-loading>
		<table class="table" border=1>
			<tbody>
				<ng-container *ngFor="let store of store_currency_list">
					<tr>
						<th class="border min-width-150">
							{{store.store.name}}
						</th>
						<ng-container *ngFor="let store_currency of store.currency_rate;let i=index">
							<td class="input-group">
								<input type="number" [name]="'exchange_rate_'+store_currency.currency_id+'_'+store.store.id" [(ngModel)]="store_currency.rate"  (ngModelChange)="updateCurrencyRate(store_currency.currency_id, store_currency.id, $event, store.store.id)" class="form-control" min="0.01" step="any">									
								<span class="input-group-text">{{store_currency.currency_id| uppercase}}</span>
							</td>
						</ng-container>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</div>
