import { Component, OnInit } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import { Utils } from 'src/app/classes/Utils';
import { SearchObject } from 'src/app/services/Rest';
import { BaseComponent } from '../base/base.component';

interface SearchAffiliates
{
	date_start:string|null;
	date_end:string|null;
	include_nulls:string;
}


interface SalesResume
{
	user_id:number;
	name: string;
	user_name:string;
	rows: Record<string,string|number>[];
	order_item_qty:number;
	item_total:number;
	show_resume:boolean;
	commission_fee:number;
}

@Component({
	selector: 'app-affiliate-sales',
	templateUrl: './affiliate-sales.component.html',
	styleUrls: ['./affiliate-sales.component.css']
})

export class AffiliateSalesComponent extends BaseComponent implements OnInit
{
	rows:Record<string,string|number>[] = [];
	date_start:string = '';
	date_end:string = '';
	resumes:SalesResume[] = [];

	something:any = {};
	csearch:SearchObject<SearchAffiliates> = this.getEmptySearch();

	ngOnInit(): void
	{
		this.path = '/affiliates-sales'

		this.subs.sink = this.getQueryParamObservable()
		.pipe
		(
			mergeMap((response)=>
			{
				this.setTitle('Reporte afiliados por categoría');
				let queryParam = response[0];
				let fields = ['date_start','date_end','agent_user_id'];
				let extras = [];
				this.csearch = this.getSearch(queryParam,fields,extras);

				let date_start = new Date();
				let date_end = new Date();

				if( this.csearch.eq.date_start )
				{
					date_start = Utils.getDateFromLocalMysqlString( this.csearch.eq.date_start );
				}
				else
				{
					let d = new Date();
					d.setDate(1);
					d.setHours(0,0,0,0);
					date_start = d;
				}

				if( this.csearch.eq.date_end )
				{
					date_end = Utils.getDateFromLocalMysqlString( this.csearch.eq.date_end );
					this.csearch.eq.date_end = Utils.getLocalMysqlStringFromDate( date_end ).substring(0,10);
				}
				else
				{
					let d = new Date();
					d.setDate(1);
					d.setHours(0,0,0,0);

					let end = new Date();
					end.setTime( d.getTime());
					end.setMonth(d.getMonth()+1);
					end.setDate( end.getDate() -1 );
					end.setHours( 23,59,59,99);

					this.csearch.eq.date_end = Utils.getLocalMysqlStringFromDate( end ).substring(0,10);
				}

				this.csearch.eq.date_start = Utils.getLocalMysqlStringFromDate( date_start ).substring(0,10);

				this.is_loading = true;
				let params = { date_start, date_end, include_nulls: 'NO'};
				return this.rest.getReporte('affiliatesSales',params );
			})
		)
		.subscribe((response:Record<string,string|number>[])=>
		{
			this.rows = response;
			let resumes = [];

			response.forEach((e) =>
			{
				let r:SalesResume | undefined = resumes.find((a)=>{
					return a.user_id == e.user_id;
				});

				let item_total = e.item_total as number;
				let commission = e.commission as number;

				if( r === undefined )
				{
					r={
						user_id : e.user_id as number,
						user_name: e.user_name as string,
						name: e.name as string,
						order_item_qty: 0,
						item_total: 0,
						rows: [],
						commission_fee: 0,
						show_resume: false
					};
					resumes.push( r );
				}

				r.order_item_qty+= e.order_item_qty as number;
				r.item_total += item_total;

				if( e.commission_type == 'NONE' )
				{
					e.commission_fee = 0;
				}
				else
				{
					let qty = e.order_item_qty as number;
					e.commission_fee =  e.commission_type == 'AMOUNT' ? commission*qty : r.item_total*(1+commission/100);
					console.log('Commision fee', e.commission_fee,e.commission_type );
					r.commission_fee += e.commission_fee;
				}
				r.rows.push( e );
			});

			this.resumes = resumes;

			this.is_loading = false;
		},(error)=>this.showError(error));
	}
}
