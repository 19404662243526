<!-- NAVBAR -->
<!-- style="position:fixed;top:0;left:0;right:0;bottom:0;" -->
<div [ngClass]="{main_container:true, menu_open:rest.show_menu}">
	<div [ngClass]="{page:true, is_test:rest.show_local_env}"><!-- [ngStyle]="{'background-image':'url(/assets/Eliminar/background.png)','background-repeat':'repeat'}"-->
		<header class="w-100 d-print-none">
			<app-header></app-header>
		</header>
		<!-- FIN NAVBAR -->
		<div [ngClass]="{page_content:true,menu_open:rest.show_menu}">
			<!-- CONTENIDO -->
			<main class="custom_scrollbar">
				<router-outlet></router-outlet>
			</main>
			<!-- FIN CONTENIDO -->
		</div>
	</div>
	<div [ngClass]="{menu_container:true, 'd-print-none':true, show_menu:rest.show_menu}">
		<app-menu></app-menu>
	</div>
</div>
