import { Component, OnInit, Output,EventEmitter, OnDestroy, Input } from '@angular/core';
import {User} from 'src/app/models/RestModels';
import {RestService} from 'src/app/services/rest.service';
import {SubSink} from 'subsink';

@Component
({
	selector: 'app-select-delivery-user',
	templateUrl: './select-delivery-user.component.html',
	styleUrls: ['./select-delivery-user.component.css']
})
export class SelectDeliveryUserComponent implements OnInit, OnDestroy {

	user_list:User[] = [];
	subsink = new SubSink();
	user_name:string = '';

	@Output() onUserSelected = new EventEmitter<User|null>();
	@Input() delivery_store_id = null;
	@Input() order_id = null;

	constructor(public rest:RestService)
	{

	}

	ngOnDestroy(): void
	{
		this.subsink.unsubscribe();
	}

	ngOnInit(): void
	{

	}

	onNameChange(name:string)
	{
		this.user_name = name;
		let limit:number = 6;
		this.subsink.sink = this.rest.user.search({ start:{ name }, limit, search_extra: {delivery_store_id:''+this.delivery_store_id}})
		.subscribe((response)=>
		{
			this.user_list = response.data;
		});
	}

	selectUser(user:User| null)
	{
		if( this.order_id )
		{
			this.subsink.sink = this.rest
			.assignDeliveryUserToOrder( user.id, this.order_id )
			.subscribe((response)=>
			{
				this.onUserSelected.emit( user );
			},(error)=>this.rest.showError(error));
		}
		else
		{
			this.onUserSelected.emit( user );
		}
	}
}
