<div *ngIf="item_info" class="main-container p-3">
	<h2>
		<span *ngIf="rest.local_preferences.display_categories_on_items == 'YES' && item_info.category">
			{{item_info.category.name}}  -
		</span>
		{{item_info.item.name}}
	</h2>
	<div>
		<input type="text" class="form-control" placeholder="Escribe un Código" [(ngModel)]="search_str" (ngModelChange)="filterItems($event)" > 
	</div>
	<div class="list-container">
		<cdk-virtual-scroll-viewport #option_view_port [itemSize]="serials_list.length" minBufferPx="80" maxBufferPx="200" class="w-100 h-100">
			<div *cdkVirtualFor="let si of serials_list;templateCacheSize:0;let index=index;"
				class="pointer item-option mx-0" [class]="{focus_item:index==row_index}" (click)="optionClicked(si)">
				<div class="row my-0">
					<div class="col-2">
						<div style="width:80px;height:80px;">
							<img *ngIf="si.images.length" [src]="rest.getImagePath(si.images[0].image_id)" style="max-width:100%;max-height:100%">
						</div>
					</div>
					<div class="col-9">
						<label>{{si.serial.serial_number}}</label>
						<div [innerHTML]="si.serial.description | marked"></div>
						<div [innerHTML]="si.serial.additional_data | marked"></div>
					</div>
					<div class="col-1">
						<span *ngIf="si.selected">✓</span>
					</div>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
<div class="row p-3" *ngIf="item_info">
	<div class="col-4"></div>
	<div class="col-4"></div>
	<div class="col-4">
		<button type="button" class="btn btn-success w-100" (click)="done()">Aceptar</button>
	</div>
</div>
