
export interface GraphData
{
	view?:[number,number]; //The dimensions of the graphic set undefined for fill container
	results?:any[]; //The chart data
	schemeType?:string; //'ordinal' or 'linear'
	scheme?:any; //ColorScheme
	roundEdges?:boolean; //Rounded edges
	legendPosition?:string;
	xAxis?:any;
	yAxis?:any;
	legend?:boolean;
	labels?:boolean; //Show o hide the labels;
	gradient?:boolean;
	legendTitle?:string;
	showXAxisLabel?:boolean;
	timeline?:boolean;
	xAxisLabel?:string;
	yAxisLabel?:string;
	showYAxisLabel?:boolean;
	doughnut?:boolean;
	select?:any;
	activate?:any;
	deactivate?:any;
}

export class GraphUtils
{
	//public static blueSchema:string[] = ['#A0D6D2','#44A8A1'];
	public static blueSchema:string[] = ['#648f00','#01633a'];
	public static brownSchema:string[] = ['#3A1302','#601205','#8A2B0D','#C75E24','#C79F59','#A4956A','#868569','#756F61','#617983','#617983'];
	public static defaultSchema: string[] = [ '#E41011','#D80093','#DC67CE','#9500E2','#A367DC','#8067DC','#6794DC', '#22BFD8', '#34E4FE', '#19808E','#608F18','#B8FF3D'];

	static getDefaultColorScheme():string[]
	{
		return this.defaultSchema;
	}
	static getEmptyPieData(): GraphData {
		let select = () => { console.log('select') };
		return {
			results: [{ name: 'a', value: 0 }, { name: 'b', value: 0 }, { name: 'c', value: 0 }, { name: 'd', value: 0 }],
			scheme: {
				domain: this.getDefaultColorScheme()
			},
			gradient: true,
			doughnut: false,
			legend: true,
			labels: true,
			legendPosition: 'below',
			select: select,
			activate: select,
			deactivate: select
		};
	}

	static getEmptyStackAreaChart():GraphData
	{
		return {
			scheme: { domain:this.getDefaultColorScheme() },
			results: [],//[]{ name: 'a', value: 0 }, { name: 'b', value: 0 }, { name: 'c', value: 0 }, { name: 'd', value: 0 }],
			xAxis: true,
			yAxis: true,
			xAxisLabel: '',
			yAxisLabel: '',
			showXAxisLabel: true,
			showYAxisLabel: true,
			timeline: false,
			legend: true,
			select: ()=>{}
		}
	}


  
	static getEmptyBarVertical2d():GraphData
	{
		let select = () => { console.log('select') };
		return {
			view: [400,400],
			results: [
  				{ 
					"name": "Germany",
    				"series": [
    			  		{ "name": "2010", "value": 7300000 },
    			  		{ "name": "2011", "value": 8940000 }
    				]
  				}
			],
			scheme: { domain: this.getDefaultColorScheme() },
			gradient: true,
			xAxis: true,
			yAxis: true,
  			legend : true,
			xAxisLabel: 'FOO',
			yAxisLabel: 'XXX',
			showXAxisLabel: true,
			showYAxisLabel: true,
//			roundEdges: true,
			legendTitle:'graph',
			select: select,
			activate: select,
			deactivate: select,
		};
	}

	static getEmptyBarChart(): GraphData {
		let select = () => { console.log('select') };
		return {
			results: [{ name: 'a', value: 0 }, { name: 'b', value: 0 }, { name: 'c', value: 0 }, { name: 'd', value: 0 }],
			scheme: { domain: this.getDefaultColorScheme() },
			gradient: true,
			xAxis: true,
			yAxis: true,
			xAxisLabel: '',
			yAxisLabel: '',
			roundEdges: true,
			select: select,
			activate: select,
			deactivate: select,
		};
	}

	static getEmptyLineChart():GraphData
	{
		let select = () => { console.log('select') };
		return {
			results: [
				{
					name: 'a', series:[
						{ value: 0, name: '2022-01-01' },
						{ value: 100, name: '2022-01-02' }
					]
				},
				{
					name: 'b',
					series:[
						{ value: 0, name: '2022-01-01' },
						{ value: 200, name: '2022-01-02' }
					]
				}
			],
			scheme: { domain: this.getDefaultColorScheme() },
			gradient: true,
			xAxis: true,
			yAxis: true,
			xAxisLabel: '',
			yAxisLabel: '',
			labels: true,
			roundEdges: true,
			select: select,
			activate: select,
			deactivate: select,
		};
	}

	static setColors(new_color :string| null):void
	{
		let a:string[] = [];
		if(new_color && new_color.indexOf(',') >= 0 )
		{
			let array = new_color.split(',');
			this.defaultSchema.splice(0,this.defaultSchema.length, ...array );
			return;
		}

		let color = new_color;

		//console.log("Setting", color );

		if(!(color !=null && /^#\w{6}/.test(color.trim()) && color.length == 7 ) )
			color = '#7C7C7C';

		let red		= parseInt(color.substring(1,3),16) as number;
		let green	= parseInt(color.substring(3,5),16) as number;
		let blue	= parseInt(color.substring(5),16) as number;

		let max = Math.max( 255-(red-10),(255-(green-10)),255-(blue-10));
		//let min = Math.min( 255-red,255-green,255-blue);

		let increments = Math.floor(max/10)

		//console.log('Color original', color);
		for(let i=0;i<10;i++)
		{
			let r= red+i*increments;
			let g= green+i*increments;
			let b= blue+i*increments;

			if( r > 255 )
				r = 255;

			if( g>255)
				g=255

			if( b>255)
				b=255

			//console.log(r,g,b);

			let rs = r.toString(16);
			let gs = g.toString(16);
			let bs = b.toString(16);

			if( rs.length < 2 )
				rs = '0'+rs;

			if( gs.length < 2 )
				gs = '0'+gs;

			if( bs.length < 2 )
				bs = '0'+bs;

			let c = '#'+rs+gs+bs;
			//console.log( c );
			a.push(c);
		}
		let b =a.filter((f,i)=>i<5);
		let c = b.filter(i=>true);
		c.reverse();

		let d = []
		/*
		for(let i=0;i<5;i++)
			d.push(a[i],c[i]);
		/*/
		d = [...b,...c];
		// */

		GraphUtils.defaultSchema.splice(0, GraphUtils.defaultSchema.length, ...d);
	}
}
