<div class="container-fluid">
	<div class="row align-items-center">
		<div class="col-8 col-lg-10">
			<h1 class="my-3">Formularios</h1>
		</div>
		<div class="col-4 col-lg-2">
			<a href="#" class="btn btn-primary w-100" routerLink="/add-form">Agregar</a>
		</div>
	</div>
	<div class="card p-1 p-md-3 my-3">
		<table class="w-100">
			<thead>
				<tr>
					<th>Id</th>
					<th>Nombre</th>
					<th>Fecha creación</th>
					<th>Fecha actualización</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let form of form_list">
					<td>
						<a [routerLink]="['/edit-form',form.id]">{{form.id}}</a>
					</td>
					<td>
						<a [routerLink]="['/edit-form',form.id]">{{form.title}}</a>
					</td>
					<td>
						{{form.created | shortDate: 'full'}}
					</td>
					<td>
						{{form.updated | shortDate: 'full'}}
					</td>
					<td class="text-end">
						<a type="button" class="btn btn-primary" [routerLink]="['/list-response', form.id]">Respuestas</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
