import { Component } from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import {PlatformStoreInfo} from 'src/app/models/models';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component({
	selector: 'app-client-change-store',
	templateUrl: './client-change-store.component.html',
	styleUrls: ['./client-change-store.component.css']
})
export class ClientChangeStoreComponent extends BaseComponent
{
	platform_store_list:PlatformStoreInfo[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((paramMap)=>
			{
				return this.rest.platform_store_info.search({limit:99999,eq:{status:'ACTIVE'}});
			})
		).subscribe((response)=>
		{
			this.platform_store_list = response.data;
		})
	}

	changeStore(psi:PlatformStoreInfo)
	{

		this.rest.setStore(psi).then(()=>
		{
			this.router.navigate(['/c-orders']);
		},(error)=>
		{
			this.showError(error);
		});
	}
}
