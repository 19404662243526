import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {Cash_Close, Store, User } from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';
import {SearchObject} from 'src/app/services/Rest';

interface CCashClose
{
	cash_close: Cash_Close,
	user:User | undefined;
	store: Store | undefined;
}


@Component({
	selector: 'app-list-cash-close',
	templateUrl: './list-cash-close.component.html',
	styleUrls: ['./list-cash-close.component.css']
})
export class ListCashCloseComponent	extends BaseComponent implements OnInit
{
	cash_close_search:SearchObject<Cash_Close> = this.getEmptySearch();
	cash_close_list:CCashClose[] = [];
	user_list: User[] = [];

	ngOnInit()
	{
		this.path = '/list-cash-close';

		this.subs.sink = this.getQueryParamObservable().pipe
		(
			mergeMap((param_maps)=>
			{
				this.cash_close_search = this.getCombinedSearch(param_maps[1],param_maps[0], ['id','user_id','created','since'],['page']);
				this.path = '/list-cash-close/'+param_maps[1].get('user_id');

				if( param_maps[1].has('user_id') )
					this.cash_close_search.eq.created_by_user_id = parseInt( param_maps[1].get('user_id'),10 ) as number;

				this.current_page = this.cash_close_search.page;
				this.cash_close_search.sort_order = ['id_DESC'];

				let user_search = { limit: 99999, type: 'USER', csv:{id:[]}};

				if( param_maps[1].has('user_id') )
				{
					user_search.csv.id = [param_maps[1].get('user_id')];
				}

				return forkJoin
				({
					user: this.rest.user.search(user_search),
					cash_close: this.rest.cash_close.search(this.cash_close_search),
					store: this.rest.store.search({limit:999999})
				})
			})
		)
		.subscribe((response)=>
		{
			this.cash_close_list = response.cash_close
			.data
			.map((cash_close)=>
			{
				let user =response.user.data.find((u)=>u.id == cash_close.created_by_user_id);
				let store = response.store.data.find((s)=>s.id == user.store_id);

				return { cash_close, user, store };
			});

			this.user_list = response.user.data
			this.setPages( this.cash_close_search.page, response.cash_close.total );
		});

		/*
		this.route.queryParamMap.subscribe((queryParamMap) =>
		{
			let fields = [ "id","created_by_user_id","updated","created","start","end" ];
			this.cash_close_search = this.getSearch(queryParamMap, fields );
			this.cash_close_search.sort_order = ['id_DESC'];
			this.titleService.setTitle('Corte de Caja');
			this.is_loading = true;
			this.current_page = this.cash_close_search.page;

			this.subs.sink = this.rest.cash_close_info
			.search(this.cash_close_search)
			.subscribe((response)=>
			{
				this.setPages( this.cash_close_search.page, response.total );
				this.cash_close_info_list = response.data;
				this.is_loading = false;
			},(error)=>this.showError(error));

		});
		*/
	}
}
