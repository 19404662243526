<div class="container-fluid">
	<h1 class="text-capitalize my-3">{{address.id ? 'Actualizar':'Agregar'}} Dirección para {{ user?.name }}</h1>
	<!--{{ city_suggestion }}- {{state_suggestion}}- {{country_suggestion}}- {{zipcode_suggestion }}-->
	<form ngNativeValidate (submit)="save()" *ngIf="user">
		<div class="card p-3">
			<div class="row p-3">

				<div class="col-12 col-md-4">
					<label class="">Tipo De Direccion:</label>
					<select name="type" [(ngModel)]="address.type" class="form-control">
						<option value="BILLING">FACTURACION</option>
						<option value="SHIPPING">De Envio</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="" *ngIf="address.type == 'SHIPPING'">Nombre</label>
					<label class="" *ngIf="address.type == 'BILLING'">Razón social</label>
					<input type="text" name="name" [(ngModel)]="address.name" class="form-control" list="user_suggestion" required>
					<datalist id="user_suggestion">
						<option>{{user.name}}</option>
					</datalist>
				</div>
				<div class="col-12 col-md-4" *ngIf="address.type == 'BILLING'">
					<label class="">Regimen Capital</label>
					<input type="text" name="sat_regimen_capital" [(ngModel)]="address.sat_regimen_capital" placeholder="SA de CV" class="form-control">
				</div>
				<div class="col-6 form-group" *ngIf="address.type == 'BILLING'">
					<label>Regimen Fiscal</label>
					<select class="form-control" name="sat_regimen_fiscal" [(ngModel)]="address.sat_regimen_fiscal" required>
						<option value="622">622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
						<option value="606">606 - Arrendamiento</option>
						<option value="609">609 - Consolidación</option>
						<option value="624">624 - Coordinados</option>
						<option value="629">629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
						<option value="608">608 - Demás ingresos</option>
						<option value="630">630 - Enajenación de acciones en bolsa de valores</option>
						<option value="601">601 - General de Ley Personas Morales</option>
						<option value="628">628 - Hidrocarburos</option>
						<option value="621">621 - Incorporación Fiscal</option>
						<option value="611">611 - Ingresos por Dividendos (socios y accionistas)</option>
						<option value="614">614 - Ingresos por intereses</option>
						<option value="623">623 - Opcional para Grupos de Sociedades</option>
						<option value="612">612 - Personas Físicas con Actividades Empresariales y Profesionales</option>
						<option value="603">603 - Personas Morales con Fines no Lucrativos</option>
						<option value="607">607 - Régimen de Enajenación o Adquisición de Bienes</option>
						<option value="625">625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
						<option value="615">615 - Régimen de los ingresos por obtención de premios</option>
						<option value="626">626 - Régimen Simplificado de Confianza</option>
						<option value="610">610 - Residentes en el Extranjero sin Establecimiento Permanente en México</option>
						<option value="616">616 - Sin obligaciones fiscales</option>
						<option value="620">620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
						<option value="605">605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
					</select>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Teléfono</label>
					<input type="text" name="phone" [(ngModel)]="address.phone" class="form-control">
				</div>

				<div class="col-12 col-md-4" *ngIf="address.type == 'BILLING'">
					<label class="">RFC</label>
					<input type="text" name="rfc" minlength="12" maxlength="14" [(ngModel)]="address.rfc" class="form-control" required>
				</div>

				<div [ngClass]="address.type =='BILLING'?['col-10','col-md-6']:['col-10','col-md-4']">
					<label class="">Dirección</label>
					<input type="text" name="address" [ngModel]="address.address" class="form-control" (ngModelChange)="onAddressChange($event)" [required]="address.type=='SHIPPING'">
					<datalist id="address_suggestion">
						<option>{{address_suggestion}}</option>
					</datalist>
				</div>
				<div class="col-2 col-md-2 px-0">
					<label class="">Mapa</label>
					<div>
						<button type="button" (click)="showAssignMapLocation()" class="btn btn-primary w-100">
							<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M10 0a7.65 7.65 0 0 0-8 8c0 2.52 2 5 3 6s5 6 5 6s4-5 5-6s3-3.48 3-6a7.65 7.65 0 0 0-8-8m0 11.25A3.25 3.25 0 1 1 13.25 8A3.25 3.25 0 0 1 10 11.25"/></svg>
						</button>
					</div>
				</div>
				<div class="col-12 col-md-4">
					<label class="">
						Código Postal <span *ngIf="address.type =='BILLING'">Domicilio Fiscal</span>
					</label>
					<input type="text" name="zipcode" [(ngModel)]="address.zipcode" class="form-control" required>
				</div>
				<!--div class="col-12 col-md-4">
					<label class="">Ciudad</label>
					<input type="text" name="city" [(ngModel)]="address.city" class="form-control" (ngModelChange)="onCityChange($event)" list="city_list">
					<datalist id="city_list">
						<option>{{city_suggestion}}</option>
					</datalist>
				</div>

				<div class="col-12 col-md-4">
					<label class="">Código Postal</label>
					<input type="text" name="CP" [(ngModel)]="address.zipcode" class="form-control">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Estado</label>
					<input type="text" name="state" [(ngModel)]="address.state" class="form-control">
					<datalist id="city_list">
						<option *ngFor="let state of states">{{state}}</option>
					</datalist>
				</div>

				<div class="col-12 col-md-4">
					<label class="">País</label>
					<input type="text" name="Pai" [(ngModel)]="address.country" list="country_suggestion" class="form-control">
					<datalist id="country_suggestion">
						<option>{{country_suggestion}}</option>
					</datalist>
				</div>
				<div class="col-12 col-md-4">
					<label class="">Colonia</label>
					<input type="text" name="suburb" [(ngModel)]="address.suburb" class="form-control" list="sublocalities">
					<datalist id="sublocalities">
						<option *ngFor="let s of sublocalities">{{s}}</option>
					</datalist>
				</div-->

				<div class="col-12 col-md-4">
					<label class="">Email</label>
					<input type="text" name="email" [(ngModel)]="address.email" class="form-control" [required]="address.type=='BILLING'">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Nota</label>
					<input type="text" name="note" [(ngModel)]="address.note" class="form-control">
				</div>

				<div class="col-12 col-md-4">
					<label class="">Uso CFDI</label>
					<select type="text" name="sat_uso_cfdi" [(ngModel)]="address.sat_uso_cfdi" class="form-control">
						<option value="G01">G01 - Adquisición de mercancías</option>
							<option value="G02">G02 - Devoluciones, descuentos o bonificaciones</option>
							<option value="G03">G03 - Gastos en general</option>
							<option value="I01">I01 - Construcciones</option>
							<option value="I02">I02 - Mobiliario y equipo de oficina por inversiones</option>
							<option value="I03">I03 - Equipo de transporte</option>
							<option value="I04">I04 - Equipo de cómputo y accesorios</option>
							<option value="I05">I05 - Dados, troqueles, moldes, matrices y herramental</option>
							<option value="I06">I06 - Comunicaciones telefónicas</option>
							<option value="I07">I07 - Comunicaciones satelitales</option>
							<option value="I08">I08 - Otra maquinaria y equipo</option>
							<option value="D01">D01 - Honorarios médicos, dentales y gastos hospitalarios.</option>
							<option value="D02">D02 - Gastos médicos por incapacidad o discapacidad</option>
							<option value="D03">D03 - Gastos funerales.</option>
							<option value="D04">D04 - Donativos</option>
							<option value="D05">D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).</option>
							<option value="D06">D06 - Aportaciones voluntarias al SAR.</option>
							<option value="D07">D07 - Primas por seguros de gastos médicos.</option>
							<option value="D08">D08 - Gastos de transportación escolar obligatoria.</option>
							<option value="D09">D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.</option>
							<option value="D10">D10 - Pagos por servicios educativos (colegiaturas)</option>
							<option value="P01">P01 - Por definir</option>
							<option value="S01">S01 - Sin efectos Fiscales</option>
					</select>
				</div>
			</div>
			<div class="row p-3">
				<div class="col-12 text-end">
					<button type="submit"  [disabled]="is_loading" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.3em" height="1.3em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z" fill="currentColor"/></svg>
						Guardar
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
<dialog #map_dialog>
	<div class="p3">
		<h2>Asignar ubicación</h2>
		<div class="dialog-map-location-content" style="height:400px;width:300px;" *ngIf="rest.is_maps_loaded">
			<google-map width="100%" height="100%" [center]="current_position"  (centerChanged)="centerChanged($event)">
				<map-marker [position]="center_position"></map-marker>
			</google-map>
		</div>
		<div class="row">
			<div class="col-6">
				<button type="button" class="btn btn-primary" (click)="hideMapDialog()">Cancelar</button>
			</div>
			<div class="col-6">
				<button type="button"	 class="btn btn-primary" (click)="addLocation()">Aceptar</button>
			</div>
		</div>
	</div>
</dialog>
