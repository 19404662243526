import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import {Utils} from 'src/app/classes/Utils';
import {Fund} from 'src/app/models/RestModels';
import {BaseComponent} from 'src/app/pages/base/base.component';

@Component
({
	selector: 'app-save-fund',
	templateUrl: './save-fund.component.html',
	styleUrls: ['./save-fund.component.css']
})

export class SaveFundComponent extends BaseComponent implements OnInit, OnChanges
{
	pesos:number|null = null;
	dolares:number|null = null;
	@Input() reload:boolean = false;
	@Output() fundSaved= new EventEmitter<boolean>();
	fund_list:Fund[] = [];
	error_string: string;

	ngOnInit(): void
	{
		this.pesos = null;
		this.dolares = null;
	}

	ngOnChanges(changes:SimpleChanges)
	{
		if( changes['reload'].currentValue )
		{
			this.subs.sink = this.rest.fund.search
			({
				search_extra:{ current_session: 1 }
			})
			.subscribe((response)=>
			{
				this.fund_list = response.data;
			});
		}
	}

	save(event:Event)
	{
		if( this.is_loading )
		{
			return;
		}

		this.is_loading = true;
		let funds:Partial<Fund>[] = [];

		let hour = Utils.getMysqlStringFromDate(new Date());
		if( this.pesos )
		{
			funds.push
			({
				amount: this.pesos,
				currency_id: 'MXN',
				cashier_hour: hour
			});
		}

		if( this.dolares )
		{
			funds.push
			({
				amount: this.dolares,
				currency_id: 'USD',
				cashier_hour: hour
			});
		}

		this.subs.sink = this.rest.fund
		.batchCreate(funds)
		.subscribe(
		{
			next:(response)=>
			{
				this.showSuccess('El fondo de caja se registró con éxito');
				this.fundSaved.emit(true);
				this.is_loading = false;
				this.pesos = null;
				this.dolares = null;
				this.reload = true;
			},
			error:(error)=>
			{
				this.error_string = Utils.getErrorString(error);
				this.showError(error)
			}
		});
		event.preventDefault();
	}

	close()
	{
		this.fundSaved.emit(false);
	}
}
