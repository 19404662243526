import { Component, OnInit } from '@angular/core';
import {ParamMap} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Store, Table} from 'src/app/models/RestModels';
import {BaseComponent} from '../base/base.component';

@Component({
	selector: 'app-save-table',
	templateUrl: './save-table.component.html',
	styleUrls: ['./save-table.component.css']
})

export class SaveTableComponent extends BaseComponent
{
	table:Table = this.getEmptyTable();
	store_list: Store[] = [];

	ngOnInit(): void
	{
		this.subs.sink = this.route.paramMap.pipe
		(
			mergeMap((params:ParamMap)=>
			{
				let obs:Observable<Table> =  params.has('id') ? this.rest.table.get( params.get('id') as string )
					: of( this.getEmptyTable() );

				return forkJoin
				({
					table: obs,
					stores: this.rest.store.search({ sort_order: ['name_ASC'] })
				});
			})
		)
		.subscribe((response)=>
		{
			this.table = response.table;
			this.store_list = response.stores.data;
		})
	}


	getEmptyTable():Table
	{
		return {
			id: 0,
			name: '',
			attended_by_user_id: null,
			capacity: 2,
			store_id: null,
			created: new Date(),
			updated: new Date(),
		} as Table;
	}

	save()
	{
		this.is_loading = true;

		if( this.table.id	)
		{
			this.subs.sink	= this.rest.table.update( this.table ).subscribe((table)=>{
				this.is_loading = false;
				this.showSuccess('Las mesa se actualizo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.table.create( this.table ).subscribe((table)=>{
				this.is_loading = false;
				this.showSuccess('La mesa se guardo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
	}
}
