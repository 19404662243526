import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { Post } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

@Component({
	selector: 'app-save-post',
	templateUrl: './save-post.component.html',
	styleUrls: ['./save-post.component.css']
})
export class SavePostComponent extends BaseComponent implements OnInit
{
	post:Post	= GetEmpty.post();
	image_links:string[] = [];
	image:number | null = null;
	domain:string = window.location.hostname;
	protocol: string = 'https://';

	ngOnInit()
	{
		this.route.paramMap.pipe
		(
			mergeMap((params)=>
			{
				this.is_loading = true;


				if( /127.0.0.(\d)+/.test(this.domain) )
				{
					this.protocol = 'http://';
					this.domain = window.location.hostname+'/PointOfSale';
				}
				else
				{
					this.protocol = 'https://';
					this.domain = window.location.hostname+'/api';
				}

				if( params.has('id') )
				{
					return this.rest.post.get( params.get('id') );
				}

				return of( GetEmpty.post() );
			})
		)
		.subscribe((post)=>
		{
			this.is_loading = false;
			this.post= post;
			this.image_links = post.images_ids ? post.images_ids.split(',') : [];
		},(error)=>this.showError( error ));
	}

	save()
	{
		this.is_loading = true;

		if( this.post.id	)
		{
			this.subs.sink	= this.rest.post
			.update( this.post )
			.subscribe((post)=>
			{
				this.is_loading = false;
				this.showSuccess('La publicación se actualizo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
		else
		{
			this.subs.sink	= this.rest.post.create( this.post ).subscribe((post)=>{
				this.is_loading = false;
				this.showSuccess('La publicación se guardo exitosamente');
				this.location.back();
			},(error)=>this.showError(error));
		}
	}

	addNewImage(image_id:number | null)
	{
		console.log("adding new image", image_id);
		if( image_id )
		{
			this.image_links.push( ''+image_id );

			if( this.post.images_ids )
				this.post.images_ids += ','+image_id;
			else
				this.post.images_ids = ''+image_id;

			this.post.post +=	'\n![]'+this.protocol+this.domain+'/image.php?id='+image_id+')\n';
		}
		this.image	= null;
	}
}
