import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { GetEmpty } from 'src/app/classes/Empties';
import { ItemInfo } from 'src/app/models/models';
import { Store } from 'src/app/models/RestModels';
import { BaseComponent } from '../base/base.component';

@Component
({
	selector: 'app-save-serial-batch',
	templateUrl: './save-serial-batch.component.html',
	styleUrls: ['./save-serial-batch.component.css']
})
export class SaveSerialBatchComponent extends BaseComponent implements OnInit
{
	store_list:Store[] = [];
	store_id: number | '';
    item_info: ItemInfo | null = null;
	serials : string = '';

	ngOnInit(): void
	{
		this.route.paramMap.pipe
		(
			mergeMap((param_map)=>
			{
				let item_obs = param_map.has('item_id')
					? this.rest.item_info.get( param_map.get('item_id') )
					: of(null);

				let store_obs = this.store_list.length > 0
					? of({data:this.store_list, total: this.store_list.length})
					: this.rest.store.search({limit:999999, sort_order:['name_ASC']});

				return forkJoin
				({
					item_info: item_obs,
					store: store_obs
				});
			})
		)
		.subscribe
		({
			next:(response)=>
			{
				this.item_info = response.item_info;
				this.store_list = response.store.data;
			},
			error:(error)=>this.showError(error)
		});
	}

	onItemSelected(item_info: ItemInfo | null)
	{
		if( item_info == null )
			return;

		this.item_info = item_info;
	}

	save()
	{
		if( this.item_info == null )
		{
			this.showError('No se ha seleccionado un articulo');
			return;
		}

		let serials = this.serials
			.split(/\s|\n|\r|,/g)
			.filter(s=>!!s.trim())
			.map(s=>
			{
				let serial_info = GetEmpty.serial_info();
				serial_info.serial.item_id = this.item_info.item.id;
				serial_info.serial.store_id = this.store_id as number;
				return serial_info;
			});


		this.subs.sink = this.rest.serial_info
		.batchCreate( serials )
		.subscribe
		({
			next:(_response)=>
			{
				this.showSuccess('Se agregaron los numeros de serie');
			},
			error:(error:any)=>this.showError(error)
		});
	}
}
