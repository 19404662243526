import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { forkJoin,of } from 'rxjs';

import {User,Store, User_Permission} from '../../models/RestModels';
import {USER_PERMISSION_KEY} from 'src/app/services/rest.service';
import { GetEmpty } from 'src/app/classes/Empties';


@Component({
	selector: 'app-save-user',
	templateUrl: './save-user.component.html',
	styleUrls: ['./save-user.component.css']
})

export class SaveUserComponent extends BaseComponent implements OnInit {

	user:Partial<User>	= {};

	user_permission:User_Permission = GetEmpty.user_permission();
	store_list:Store[] = [];
	password_type:'text'|'password' = 'password';

	ngOnInit()
	{
		this.subs.sink = this.route.paramMap.subscribe( params =>
		{
			this.is_loading = true;
			let defaultUser:Partial<User> = {
				name: '',
				store_id: undefined,
				type:'USER',
				credit_days: 0,
				credit_limit: 0,
				price_type_id: undefined,
			};

			this.subs.sink = forkJoin({
				user: params.has('id') ? this.rest.user.get(params.get('id')) : of(defaultUser),
				stores: this.rest.store.search({limit:99999,sort_order:['name_ASC']}),
				user_permission: params.has('id') ? this.rest.user_permission.get( params.get('id') ) : of(GetEmpty.user_permission()),
			}).subscribe((response)=>
			{
				this.is_loading = false;
				this.user = response.user;
				this.store_list = response.stores.data;
				this.user_permission = response.user_permission;
			});
		});
	}

	save()
	{
		this.is_loading = true;

		if( this.user.id	)
		{
			this.user.username = this.user.email;
			this.subs.sink	= forkJoin({
				user: this.rest.user.update( this.user ),
				permissions: this.rest.current_permission.add_user ? this.rest.user_permission.update( this.user_permission ) : of(this.rest.current_permission)
			})
			.subscribe((response)=>{
				if( this.rest.current_user.id == response.user.id )
				{
					this.rest.current_permission = response.permissions;
					localStorage.setItem(USER_PERMISSION_KEY, JSON.stringify(response.permissions));
				}

				this.is_loading = false;
				//this.router.navigate(['/list-user']);
				this.location.back();
				this.showSuccess('El usuario se actualizó exitosamente');
			},(error)=>this.showError(error));

		}
		else
		{
			//this.user.name;// = this.user.username;
			this.user.username = this.user.email;
			this.subs.sink	= this.rest.user.create( this.user ).subscribe((user)=>{
				this.user = user;
				this.user_permission.user_id = user.id;
				this.rest.user_permission.update(this.user_permission).subscribe((response)=>
				{
					if(response.user_id == this.rest.current_user.id )
					{
						this.rest.current_permission = response;
						console.log( response );
						setTimeout(()=>{
							localStorage.setItem('current_permission', JSON.stringify(response));
							this.rest.current_permission = response;
						},2000)
					}
				},(error)=>{
					this.showError(error);
				});
				this.showSuccess('El usuario se guardó exitosamente');
				this.is_loading = false;
				this.location.back();
				//this.router.navigate(['/list-user']);
			},(error)=>this.showError(error));
		}
	}

	toggleValue(key:string)
	{
		this.user_permission[key] = this.user_permission[key]?0:1;
	}

	//all:boolean
	
	
	toggleAll(all:string,permission:string[])
	{	
		let result = permission.reduce((acc,curr)=>acc && this.user_permission[curr],true);
		for(let i of permission)
		{
			this.user_permission[i] = result?0:1;
		}
	}
	

	togglePasswordType(evt:Event)
	{
		let check = evt.target as HTMLInputElement;
		this.password_type = check.checked ? 'text' : 'password';
	}
}
