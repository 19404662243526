<div class="container-fluid">
	<div class="row align-items-end mx-0 my-3">
		<div class="col">
			<h1 class="text-capitalize">Comanda</h1>
		</div>
		<div class="col-auto">
			<button type="button" class="ml-2 btn btn-secondary lift" routerLink="/add-commanda"> Agregar comanda</button>
		</div>
	</div>

	<!-- div>
		<div class="row">

				<div class="col-6 col-md-3 form-group">
					<label class="">Id</label>
					<input type="number" name="id" [(ngModel)]="commanda_search.eq.id" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Name</label>
					<input type="text" name="name" [(ngModel)]="commanda_search.lk.name" class="form-control">
				</div>
				<div class="col-6 col-md-3 form-group">
					<label class="">Store</label>
					<input type="number" name="store_id" [(ngModel)]="commanda_search.eq.store_id" class="form-control">
				</div>

			<div class="col-6 col-md-3">
				<button type="button" class="w-100 btn btn-primary" (click)="search()">Buscar</button>
			</div>
		</div>
	</div -->

	<div  class="card p-3 mt-3">
		<!-- div class="row">
				TEMPLATE_LIST_TABLE_HEADERS
		</div -->
		<app-loading [is_loading]="is_loading"></app-loading>
		<div class="table-responsive">
			<table class="table table-sm table-nowrap mb-0">
				<thead class="thead-light">
					<tr>
						<th>Id</th>
						<th>Nombre</th>
						<th>Sucursal</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let commanda of commanda_list">
						<td>
							<a [routerLink]="['/view-commanda',commanda.id]">{{commanda.id}}-{{commanda.name | titlecase}}</a>
						</td>
						<td>{{commanda.name}}</td>
						<td>{{store_dictionary[ commanda.store_id ].name | titlecase }}</td>
						<td>
							<a [routerLink]="['/edit-commanda',commanda.id]" class="btn btn-primary">Editar</a>
						</td>
					</tr>
					<tr *ngIf="commanda_list == null || commanda_list.length == 0 && !is_loading" colspan="TEMPLATE_HEADERS_SIZE">
						<div class="alert alert-light m-3" role="alert">No se encontraron elementos</div>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-pagination [path]="'/list-commanda'" [pages]="pages" [total_pages]="total_pages" [current_page]="current_page"></app-pagination>
</div>
